import { Injectable } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { Observable, combineLatest, of } from 'rxjs';
import {
  map,
  switchMap,
} from 'rxjs/operators';

import { NonTradableClassifications } from '../shared/models/trading.model';
import { type Instrument, isInstrument } from '../state-model/window.model';
import { getInstrumentFromSymbolLike } from '../util/sdk';
import { SdkRequestsService } from './sdk-requests.service';
import { SdkService } from './sdk.service';
import { TradingService, isConnected } from './trading.service';

export type IsTradableParams = Partial<{
  symbol: InfrontSDK.SymbolData;
  instrument: Instrument,
  feedHasTrading: boolean,
  classification: InfrontSDK.SymbolClassification,
  requireTradingConnection: boolean,
}>;

export type IsSymbolTradableParams = Partial<{
  symbol: InfrontSDK.SymbolData;
  classification: InfrontSDK.SymbolClassification,
  tradableFeeds: number[] | undefined,
}>;

export function isTradableSymbolClassification(symbolClassification: InfrontSDK.SymbolClassification): boolean {
  return !NonTradableClassifications.includes(symbolClassification);
}

export interface TradableMap {
  instrument: Instrument;
  isTradable: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class TradableService {
  constructor(
    private readonly sdkService: SdkService,
    private readonly sdkRequestsService: SdkRequestsService,
    private readonly tradingService: TradingService
  ) { }

  // provides list of tradable feeds from the currently connected gateway, or `undefined` if not connected
  tradableFeedsForCurrentGateway$ = this.tradingService.connectionState$.pipe(
    switchMap((connectionState) => {
      return combineLatest([
        of(connectionState),
        this.sdkService.getObject$<InfrontSDK.Trading.ConnectionOptions<InfrontSDK.Trading.TradingConnectionAction>>(InfrontSDK.Trading.connection, {
          action: InfrontSDK.Trading.TradingConnectionAction.Gateways
        }),
      ]);
    }),
    map(([connectionState, gateways]) => {
      if (connectionState.providerId != undefined && isConnected(connectionState)) {
        return (gateways as unknown as InfrontSDK.Trading.TradingGatewayInfo[])
          .find((gw) => gw.providerId === connectionState.providerId)?.tradableFeeds;
      }
      return undefined;
    }),
  );

  // Simplified, synchronous version of `isTradable` for a single symbol.
  // In an observable chain `tradableFeeds` can be retrieved from `tradableFeedsForCurrentGateway$`
  // and then be used (e.g. in a `map` of the symbols) as simple array-param for `isSymbolTradable`.
  isSymbolTradable(params: IsSymbolTradableParams): boolean {
    const { symbol, tradableFeeds } = params;
    const classification = params.classification ?? symbol?.get(InfrontSDK.SymbolField.SymbolClassification);
    const feed = symbol?.get(InfrontSDK.SymbolField.Feed);
    const feedHasTrading = !!((feed != undefined) && tradableFeeds?.includes(feed));

    if (feedHasTrading === false) {
      return false;
    }

    if (feedHasTrading === true && classification) {
      return isTradableSymbolClassification(classification);
    }

    return false;
  }

  private feedHasTrading$(feed: number): Observable<InfrontSDK.FeedInfo[]> {
    return this.sdkService.getArray$(InfrontSDK.feedInfo, {
      infoType: InfrontSDK.FeedInfoType.MetaData,
      feed: [feed],
    }) as Observable<InfrontSDK.FeedInfo[]>; // todo: should be type determined by SDK
  }

  /**
  * isTradable$ depends on the combination of:
  * 1. Is user logged in - depending on params.requireTradingConnection (by default true, needs to be explicitly disabled)
  * 2. FeedInfo - hasTrading property
  * 3. Tradable SymbolClassification
  *
  * @param params: IsTradableParams // send in symbol or instrument and if already known hasTrading (feed metadata) and symbol classification
  */
  isTradable$(params: IsTradableParams): Observable<boolean> {
    const { feedHasTrading, symbol, classification, requireTradingConnection: requireConnection } = params;
    let { instrument } = params;

    if (feedHasTrading === false) {
      return of(false);
    }

    if (feedHasTrading === true && classification) {
      return of(isTradableSymbolClassification(classification));
    }

    // We lack information, check if instrument is available since it's required for either feedHasTrading or classification
    if (!isInstrument(instrument)) {
      if (symbol) {
        instrument = getInstrumentFromSymbolLike(symbol);
      }
      if (!instrument) {
        return of(false);
      }
    }

    const connected$ = requireConnection === false ? of(true) : this.tradingService.tradingConnected$;

    return connected$.pipe(
      switchMap((connected) => {
        if (!connected) {
          return of(false);
        }

        const feedHasTrading$ = (feedHasTrading
          ? of(true)
          : this.feedHasTrading$(instrument.feed).pipe(
            map((feedInfo) => {
              return !!feedInfo?.[0]?.hasTrading;
            })
          )
        );

        return feedHasTrading$.pipe(
          switchMap((feedHasTrading) => {
            if (!feedHasTrading) {
              return of(false);
            }

            const classification$: Observable<InfrontSDK.SymbolClassification> = (classification
              ? of(classification)
              : this.sdkRequestsService.symbolInfoByFields$({ symbolEntity: instrument, fields: [InfrontSDK.SymbolField.SymbolClassification], subscribe: false }).pipe(
                map((symbolData) => symbolData.get(InfrontSDK.SymbolField.SymbolClassification))
              )
            );

            return classification$.pipe(map((classification) => isTradableSymbolClassification(classification)));
          })
        );
      }),
    );
  }

  areTradable(paramsList: IsTradableParams[]): Observable<TradableMap[]> {
    const areTradable$: Observable<TradableMap>[] = [];
    paramsList.forEach((params) => {
      const { symbol } = params;
      let { instrument } = params;
      if (!isInstrument(instrument)) {
        if (symbol) {
          instrument = getInstrumentFromSymbolLike(symbol);
        }
        if (!instrument) {
          return;
        }
      }

      areTradable$.push(
        this.isTradable$({ instrument }).pipe(
          map((isTradable) => ({ instrument, isTradable }))
        ) as Observable<TradableMap>
      );
    });

    return combineLatest(areTradable$);
  }

}

