import { DashboardFolderLevel, type DashboardFolderModel, type DashboardModel, DashboardType, KnownDashboardFolderIDs, type WidgetDataModel, type WidgetLocationModel, type WidgetModel, type WidgetStructureModel } from '@infront/ngx-dashboards-fx';
import type { InfrontSDK } from '@infront/sdk';
import { DashboardType as WTDashboardType } from '../../state-model/dashboard.model';
import type { Instrument } from '../../state-model/window.model';
import { omit } from '../../util/object';
import { createInstrumentDashboardConfig } from '../template';

export const INSTRUMENT_DASHBOARD_FOLDER_ID = '$$instrumentDashboards';

const InstrumentDashboardIndexOffset = 10000;

export const InstrumentDashboardsFolder: DashboardFolderModel = {
  id: INSTRUMENT_DASHBOARD_FOLDER_ID,
  type: DashboardType.FOLDER,
  name: 'Instrument Dashboards',
  index: 10000,
  parentId: KnownDashboardFolderIDs.ROOT,
  attributes: {
  },
  hidden: true,
  isLink: false,
  level: DashboardFolderLevel.OTHER,
  security: {
    canDelete: false,
    canEdit: false,
    canMove: false,
    canAddChildren: true,
    canDeleteChildren: true,
  },
  childrenLoadState: 'loading',
};

export function instrumentDashboardId(classification: InfrontSDK.SymbolClassification, instrument: Instrument) {
  return `$$instdash:${classification}:${instrument.feed}:${instrument.ticker}`;
}

export function createDashboardModel(classification: InfrontSDK.SymbolClassification, instrument: Instrument, index: number) {
  const dashboardId = instrumentDashboardId(classification, instrument);

  const instrumentDashboard: DashboardModel = {
    id: dashboardId,
    type: DashboardType.DASHBOARD,
    name: instrument.ticker,
    index: InstrumentDashboardIndexOffset + index,
    parentId: INSTRUMENT_DASHBOARD_FOLDER_ID,
    attributes: {
      wtDashboardType: WTDashboardType.instrument,
      instrument: { ...instrument },
      classification,
    },
    isLink: false,
    isDataDraft: false,
    sharing: {
      canShare: false,
      isShared: false,
      isSharedWithMe: false,
    },
    hidden: false,
    level: DashboardFolderLevel.OTHER,
    security: {
      canDelete: false,
      canEdit: false,
      canMove: false,
      canEditWidgets: false,
    },
    dataCreatedAt: new Date(),
  };

  return instrumentDashboard;
}

export function createInstrumentDashboardWidgets(dashboardId: string,
  instrument: Instrument,
  classification: InfrontSDK.SymbolClassification
): WidgetStructureModel {
  const wtStructure = createInstrumentDashboardConfig(dashboardId, instrument, classification);
  return {
    widgets: wtStructure.windows.map((window) => {
      // collect all properties that can not be mapped to WidgetModel in windowData

      const { name, id, x, y, cols, rows, ...windowData } = window;
      // WidgetLocationModel can be easily mapped
      const location: WidgetLocationModel = {
        x: x,
        y: y,
        width: cols,
        height: rows,
      };

      const widgets = wtStructure.widgets.filter((widget) => widget.windowId === id && widget.dashboardId === window.dashboardId) ?? [];
      const widgetIds = widgets.map((w) => w.id);
      const grids = wtStructure.grids.filter((grid) => widgetIds.includes(grid.parentId as string) && grid.dashboardId === window.dashboardId) ?? [];

      // convert the WT5 DashboardWindow (and widgets + grids) to a WidgetModel
      const widgetModel: WidgetModel = {
        type: name,
        id: id,
        serializationVersion: 1,
        data: {
          ...omit(windowData, 'dashboardId'),
          // Please never create a property "widgets" or "grids" in a WT5 DashboardWindow!
          widgets: widgets.map(widget => omit(widget, 'dashboardId')),
          grids: grids.map(grid => omit(grid, 'dashboardId')),
        } as unknown as WidgetDataModel,
        location,
        groups: {}, // FIXME not sure what to do here
        // theme?: string, // not required in WT5
      };

      return widgetModel;
    }),
  };
}
