import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild, inject, type OnDestroy } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import type { GridApi, GridOptions } from 'ag-grid-community';
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, distinctUntilKeyChanged, filter, map, shareReplay, switchMap, takeUntil, tap, throttleTime } from 'rxjs/operators';

import { LastValueSubject } from '../../../../../infront-ngx-dashboards-fx/utils';
import { StoreService } from '../../services/store.service';
import { TradingService } from '../../services/trading.service';
import type { UntranslatedNoRowsTemplate } from '../../shared/grid/grid.model';
import { ProgressService } from '../../shared/progress';
import type { NewsWidget } from '../../state-model/widget.model';
import type { NewsType } from '../../state-model/window.model';
import { arraysAreEqual, structuresAreEqual } from '../../util/equality';
import { deepFreeze } from '../../util/object';
import { filterUndefined } from '../../util/rxjs';
import { NewsChunkSize, overlayNoRowsTemplateOptions, type NewsHeadline, type Vm } from './news.model';
import { NewsService } from './news.service';

@Component({
  selector: 'wt-news',
  templateUrl: './news.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NewsService],
})
export class NewsComponent implements OnDestroy {
  private readonly newsService = inject(NewsService);
  // private readonly logService = inject(LogService);
  private readonly progress = inject(ProgressService);
  private readonly tradingService = inject(TradingService);
  private readonly storeService = inject(StoreService);

  @ViewChild('textFilterInput', { static: true }) set textFilterInput(textFilterInput: ElementRef<HTMLInputElement> | undefined) {
    this.textFilterInputAction.next(textFilterInput);
  }
  private readonly textFilterInputAction = new ReplaySubject<ElementRef<HTMLInputElement> | undefined>(1);
  readonly textFilterInput$ = this.textFilterInputAction.asObservable();

  @Input() set widget(widget: NewsWidget) {
    this.widgetAction.next(widget);
  }
  private readonly widgetAction = new ReplaySubject<NewsWidget>(1);
  readonly widget$ = this.widgetAction.asObservable();

  private readonly window$ = this.widget$.pipe(
    switchMap((widget) => this.storeService.windowByWidget$(widget)),
    shareReplay(1),
  );

  @Input() set isActive(isActive: boolean) {
    this.isActiveAction.next(isActive);
  }
  private readonly isActiveAction = new BehaviorSubject<boolean>(false);
  readonly isActive$ = this.isActiveAction.asObservable();

  private readonly textFilterAction = new BehaviorSubject<string>('');
  readonly textFilter$ = this.textFilterAction.pipe(
    distinctUntilChanged(),
    throttleTime(300),
  );

  readonly newsType$: Observable<NewsType> = this.window$.pipe(
    distinctUntilKeyChanged('settings', structuresAreEqual),
    map((window) => this.newsService.getNewsType(window))
  );

  private readonly showNewsAction = new ReplaySubject<boolean>(1);
  readonly showNews$ = this.showNewsAction.asObservable();

  private readonly showFlashNewsAction = new ReplaySubject<boolean>(1);
  readonly showFlashNews$ = this.showFlashNewsAction.asObservable();

  private readonly showResearchNewsAction = new ReplaySubject<boolean>(1);
  readonly showResearchNews$ = this.showResearchNewsAction.asObservable();

  readonly newsChunkSize = NewsChunkSize;

  readonly gridOptions: GridOptions<NewsHeadline> = deepFreeze({
    rowClassRules: {
      'cursor-pointer': (params) => {
        const hl = params.data as NewsHeadline;
        return !!(hl?.url || hl?.hasBody);
      },
      'cursor-not-allowed': (params) => {
        const hl = params.data as NewsHeadline;
        return !!hl?.isFlash;
      },
    },
  });

  private readonly gridApiAction = new ReplaySubject<GridApi<NewsHeadline> | undefined>();
  readonly gridApi$ = this.gridApiAction.asObservable();

  readonly tradingConnected$ = this.tradingService.tradingConnected$;

  private readonly deselectedFeedsAction = new LastValueSubject<number[] | undefined>();
  readonly deselectedFeeds$ = this.deselectedFeedsAction.pipe(
    distinctUntilChanged(arraysAreEqual),
    shareReplay(1),
  );

  readonly vm$: Observable<Vm> = combineLatest([
    this.widget$,
    this.isActive$,
    this.newsType$,
  ]).pipe(
    switchMap(([widget, isActive, newsType]) => {
      if (!(widget.lifespan !== 'window' || isActive)) {
        this.untranslatedNoRowsTemplateAction.next(overlayNoRowsTemplateOptions.inactiveWidget);
        return of({ headlines: [], columns: this.newsService.getColumns(newsType), newsType: 'Instrument' as NewsType });
      }
      this.untranslatedNoRowsTemplateAction.next(overlayNoRowsTemplateOptions.noData);
      return this.newsService.filteredHeadlines$(widget, newsType, this.untranslatedNoRowsTemplateAction, this.progress).pipe(
        map((headlines) => ({ headlines, columns: this.newsService.getColumns(newsType), newsType })),
      );
    }),
  );

  private readonly untranslatedNoRowsTemplateAction = new BehaviorSubject<UntranslatedNoRowsTemplate | undefined>(undefined);
  readonly untranslatedNoRowsTemplate$: Observable<UntranslatedNoRowsTemplate & { element: string; }> = this.untranslatedNoRowsTemplateAction.pipe(
    distinctUntilChanged(),
    map((opts) => ({ element: 'span', ...(opts ?? overlayNoRowsTemplateOptions.noData) })),
    shareReplay(1),
  );

  private readonly ngUnsubscribe = new Subject<void>();

  constructor() {
    this.newsService.resetScroll();

    // update textFilter by widget
    this.widget$.pipe(
      distinctUntilChanged((prev, next) => prev?.settings.textFilter === next?.settings.textFilter),
      tap((widget) => this.textFilterAction.next(widget?.settings.textFilter)),
      takeUntil(this.ngUnsubscribe),
    ).subscribe();

    // update widget by textFilter
    this.widget$.pipe(
      switchMap((widget) => this.textFilterAction.pipe(
        filter((textFilter) => widget.settings.textFilter !== textFilter),
        debounceTime(200),
        tap((textFilter) => this.updateWidgetTextFilter(widget, textFilter)),
      )),
      takeUntil(this.ngUnsubscribe),
    ).subscribe();

    // apply textFilter
    combineLatest([this.gridApi$, this.textFilter$.pipe(distinctUntilChanged())]).pipe(
      tap(([gridApi,]) => gridApi?.onFilterChanged()),
      takeUntil(this.ngUnsubscribe),
    ).subscribe();

    // update deselectedFeeds by widget
    this.widget$.pipe(
      tap((widget) => this.deselectedFeedsAction.next(widget.settings.deselectedFeeds)),
      takeUntil(this.ngUnsubscribe),
    ).subscribe();

    // apply deselectedFeeds
    combineLatest([this.gridApi$.pipe(filterUndefined()), this.deselectedFeeds$]).pipe(
      tap(([gridApi, deselectedFeeds]) => {
        void gridApi.setColumnFilterModel('source', deselectedFeeds ? { values: deselectedFeeds } : undefined).then(() => {
          gridApi.onFilterChanged();
        });
      }),
      takeUntil(this.ngUnsubscribe),
    ).subscribe();

    // update showNews by widget
    this.widget$.pipe(
      distinctUntilChanged((prev, next) => prev?.settings.showNews === next?.settings.showNews),
      tap((widget) => this.showNewsAction.next(widget?.settings.showNews)),
      takeUntil(this.ngUnsubscribe),
    ).subscribe();

    // update widget by showNews
    this.widget$.pipe(
      switchMap((widget) => this.showNewsAction.pipe(
        filter((showNews) => widget.settings.showNews !== showNews),
        debounceTime(200),
        tap((showNews) => this.updateWidgetShowNews(widget, showNews)),
      )),
      takeUntil(this.ngUnsubscribe),
    ).subscribe();

    // update showFlashNews by widget
    this.widget$.pipe(
      distinctUntilChanged((prev, next) => prev?.settings.showFlashNews === next?.settings.showFlashNews),
      tap((widget) => this.showFlashNewsAction.next(widget?.settings.showFlashNews)),
      takeUntil(this.ngUnsubscribe),
    ).subscribe();

    // update widget by showFlashNews
    this.widget$.pipe(
      switchMap((widget) => this.showFlashNewsAction.pipe(
        filter((showFlashNews) => widget.settings.showFlashNews !== showFlashNews),
        debounceTime(200),
        tap((showFlashNews) => this.updateWidgetShowFlashNews(widget, showFlashNews)),
      )),
      takeUntil(this.ngUnsubscribe),
    ).subscribe();

    // update showResearchNews by widget
    this.widget$.pipe(
      distinctUntilChanged((prev, next) => prev?.settings.showResearchNews === next?.settings.showResearchNews),
      tap((widget) => this.showResearchNewsAction.next(widget?.settings.showResearchNews)),
      takeUntil(this.ngUnsubscribe),
    ).subscribe();

    // update widget by showResearchNews
    this.widget$.pipe(
      switchMap((widget) => this.showResearchNewsAction.pipe(
        filter((showResearchNews) => widget.settings.showResearchNews !== showResearchNews),
        debounceTime(200),
        tap((showResearchNews) => this.updateWidgetShowResearchNews(widget, showResearchNews)),
      )),
      takeUntil(this.ngUnsubscribe),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.textFilterInputAction.complete();
    this.widgetAction.complete();
    this.isActiveAction.complete();
    this.showNewsAction.complete();
    this.showFlashNewsAction.complete();
    this.showResearchNewsAction.complete();
    this.gridApiAction.complete();
    this.untranslatedNoRowsTemplateAction.complete();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  openNewsStory(newsHeadline: NewsHeadline): void {
    this.newsService.openNewsStory(newsHeadline);
  }

  onShowMore(): void {
    this.newsService.showMore();
  }

  onShowNews(event: MatCheckboxChange): void {
    this.showNewsAction.next(event.checked);
  }

  onShowFlashNews(event: MatCheckboxChange): void {
    this.showFlashNewsAction.next(event.checked);
  }

  onShowResearchNews(event: MatCheckboxChange): void {
    this.showResearchNewsAction.next(event.checked);
  }

  onChangeTextFilter(textFilter: string): void {
    this.textFilterAction.next(textFilter);
  }

  updateWidgetShowNews(widget: NewsWidget, showNews: boolean): void {
    this.storeService.updateWidget(widget, { settings: { ...widget.settings, showNews } });
  }

  updateWidgetShowFlashNews(widget: NewsWidget, showFlashNews: boolean): void {
    this.storeService.updateWidget(widget, { settings: { ...widget.settings, showFlashNews } });
  }

  updateWidgetShowResearchNews(widget: NewsWidget, showResearchNews: boolean): void {
    this.storeService.updateWidget(widget, { settings: { ...widget.settings, showResearchNews } });
  }

  updateWidgetTextFilter(widget: NewsWidget, textFilter: string): void {
    this.storeService.updateWidget(widget, { settings: { ...widget.settings, textFilter } });
  }

  onGridApi(gridApi: GridApi<NewsHeadline>): void {
    this.gridApiAction.next(gridApi);
  }
}
