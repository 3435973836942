<ng-container class="watchlist">
  <!-- Widget Title Bar Left (__title) -->
  <div class="widget-title-bar__title gridster-item-content"
    [class.wt-draggable]="!dashboard.locked && window.dragEnabled !== false">
    <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle">
      <div class="wt-flex-column">
        <!-- Dropdown icon -->
        <wt-dropdown-watchlist
          [selectedWatchlistId]="window.settings.selectedWatchlist"
          (selectedWatchlistChange)="onWatchlistSelected($event)"></wt-dropdown-watchlist>
      </div>

      <div class="wt-flex-column wt-flex-shrink">
        <!-- Widget Title Bar Right (__buttons) -->
        <div class="widget-title-bar__buttons gridster-item-content">
          <ul class="wt-list-inline">
            <li class="gridster-item-content">
              <wt-link-channel-menu [window]="window" [dashboard]="dashboard"
                class="link-channel-menu gridster-item-content"></wt-link-channel-menu>
            </li>
            <li class="gridster-item-content">
              <wt-context-menu class="gridster-item-content" [context]="{ widget, dashboard }"></wt-context-menu>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-container>