import { Injectable, NgZone, inject } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  GridsterComponentInterface,
  type GridsterConfig,
  type GridsterItem,
  GridsterItemComponentInterface,
} from 'angular-gridster2';
import { BehaviorSubject, NEVER, Observable, Subject, merge, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { LogService } from '@vwd/ngx-logging';
import type { DashboardWindowToAdd } from '../directives/dashboard-window-controller.directive';
import { PortfolioDashboardService } from '../services/portfolio-dashboard.service';
import { SdkRequestsService } from '../services/sdk-requests.service';
import { StoreService } from '../services/store.service';
import { ToolkitService } from '../services/toolkit.service';
import { TradingOrderEntryService } from '../services/trading-order-entry.service';
import { TradingService } from '../services/trading.service';
import {
  ConfirmDialogComponent,
  type ConfirmDialogResult,
} from '../shared/confirm-dialog/confirm-dialog.component';
import { DashboardType } from '../state-model/dashboard.model';
import type { InstrumentHeaderWidget } from '../state-model/widget.model';
import { DashboardWindowDefaults, WindowDefaultsMap } from '../state-model/window.defaults';
import type {
  DashboardWindow,
  StrictGridsterItem,
  WindowName,
  WindowSettings,
} from '../state-model/window.model';
import { UserSettingsService } from './../services/user-settings.service';
import { PORTFOLIO_DASHBOARD_ID } from './providers/portfolio-dashboards';
import { InstrumentHeaderWindowName } from './template';
import { translator } from '../util/locale';

export const dashboardRaster = 30;  // IWT-634: 30px width x 30px height (26 after subtracted padding)
export const dashboardMargin = 2; // IWT-634: 2px padding for the main gridster container
// dashboardMargin is actually padding, and will subtract size from the raster. ie: 30 raster and 5 margin means raster is 20 (30 - 5 - 5)
export const dashboardWidth = 100; // * dashboardRaster - dashboardMargin = size in px = ca. 12000 px
export const dashboardHeight = 100; // * dashboardRaster - dashboardMargin = size in px = ca. 12000 px


@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private readonly storeService = inject(StoreService);
  private readonly tradingService = inject(TradingService);
  private readonly portfolioDashboardService = inject(PortfolioDashboardService);
  private readonly toolkitService = inject(ToolkitService);
  private readonly zone = inject(NgZone);
  private readonly userSettingsService = inject(UserSettingsService);
  private readonly sdkRequestsService = inject(SdkRequestsService);
  private readonly traingOrderEntryService = inject(TradingOrderEntryService);
  private readonly logger = inject(LogService).openLogger('services/dashboard');
  private readonly dialog = inject(MatDialog);

  private readonly deleteWindowAction = new Subject<DashboardWindow>();

  private readonly xlat = translator();


  // considered too small for own service at this point
  private readonly instumentDashboardWindows$ = this.storeService.currentWindowsAndDashboardType$.pipe(
    filter((result) => result.type === DashboardType.instrument),
    switchMap((result) => {
      const { windows } = result;
      const instrumentDashboardHeaderWindow = windows.find((w) => w.name === InstrumentHeaderWindowName);
      if (!instrumentDashboardHeaderWindow) {
        return NEVER;
      }
      this.storeService.closeAllModals();
      return this.storeService
        .selectedWidgetInWindow$<InstrumentHeaderWidget>(instrumentDashboardHeaderWindow)
        .pipe(
          map((widget) =>
            windows.filter((w) => w.tag === widget.settings.selectedTab || w.id === instrumentDashboardHeaderWindow.id || w.layerIndex === 2)
          )
        );
    }));

  readonly currentWindows$ = this.storeService.currentWindowsAndDashboardType$.pipe(switchMap((result) => {
    const { windows, type } = result;

    if (type === DashboardType.portfolio) {
      return this.tradingService.tradingConnected$.pipe(switchMap(isConnected => {
        return isConnected ? this.portfolioDashboardService.windows$ : of([]);
      }));
    }
    if (type === DashboardType.instrument) {
      return merge(of([]), this.instumentDashboardWindows$);
    }

    const instrumentDashboardHeaderWindow = windows.find((w) => w.name === InstrumentHeaderWindowName);
    if (!instrumentDashboardHeaderWindow) {
      return of(windows);
    }

    return this.storeService
      .selectedWidgetInWindow$<InstrumentHeaderWidget>(instrumentDashboardHeaderWindow)
      .pipe(
        map((widget) =>
          windows.filter((w) => w.tag === widget.settings.selectedTab || w.id === instrumentDashboardHeaderWindow.id || w.layerIndex === 2)
        )
      );

  }));

  private currentWindows: DashboardWindow[] = [];

  private readonly windowDragStartAction = new Subject<DashboardWindow>();
  readonly windowDragStart$ = this.windowDragStartAction.asObservable();

  private itemPrevValue: StrictGridsterItem | undefined = undefined;

  gridsterConfig = {
    // grid raster
    fixedColWidth: dashboardRaster - dashboardMargin,
    fixedRowHeight: dashboardRaster - dashboardMargin,
    margin: dashboardMargin,
    gridType: 'fixed',
    displayGrid: 'onDrag&Resize', // 'always' none

    // grid size config
    minCols: 0, // min size = minCols * fixedColWidth
    maxCols: dashboardWidth, // max size = maxCols * fixedColWidth
    minRows: 51, // todo: might want to toggle this and similar size settings through dashboard settings and apply when dashboard changes
    maxRows: dashboardHeight, // max size = maxRows * fixedRowHeight
    // items size config
    defaultItemCols: 12, // default width of an item in columns
    defaultItemRows: 12, // default height of an item in rows
    minItemCols: 6, // min item size = minItemCols * fixedColWidth
    maxItemCols: dashboardWidth, // max item size = maxItemCols * fixedColWidth
    minItemRows: 1, // min item size = minItemRows * fixedRowHeight
    maxItemRows: dashboardHeight, // max item size = minItemRows * fixedRowHeight
    minItemArea: 12, // min item area: cols * rows
    maxItemArea: dashboardWidth * dashboardHeight, // max item area: cols * rows
    // various config
    pushItems: false,
    pushResizeItems: true,
    scrollToNewItems: false,
    swap: false,
    mobileBreakpoint: 0,
    resizable: {
      enabled: true,
      handles: {
        s: true,
        e: true,
        n: false,
        w: true,
        se: true,
        ne: true,
        sw: true,
        nw: true,
      },
      stop: (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => {
        const itemNewValue = itemComponent.$item;
        const itemPrevValue = this.itemPrevValue;
        if (!itemPrevValue ||
          itemPrevValue.cols === itemNewValue.cols && itemPrevValue.rows === itemNewValue.rows && itemPrevValue.x === itemNewValue.x && itemPrevValue.y === itemNewValue.y) {
          // * item size did not change, no need to update
          return;
        }
        // gridster not giving correct collision info on push: true unless timeout applied
        setTimeout(() => this.updateWindowCoordinates(item as DashboardWindow, itemComponent), 1);
        // some material elements like tabs needs window resize event for them to update layout https://github.com/angular/components/issues/20340
        setTimeout(() => {
          this.resizeWindow.next(item as DashboardWindow);
          window.dispatchEvent(new Event('resize'));
        }, 1);
      },
      start: (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => {
        this.itemPrevValue = { ...item } as DashboardWindow;
      }
    },
    draggable: {
      enabled: true,
      dragHandleClass: 'wt-draggable',
      delayStart: 150, // milliseconds
      ignoreContent: true,
      dropOverItems: true,
      stop: (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => {
        const itemElm = itemComponent?.el;
        if (itemElm) {
          itemElm.classList.remove('dragging');
        }
        const itemNewValue = itemComponent.$item;
        const itemPrevValue = this.itemPrevValue;
        if (!itemPrevValue || itemPrevValue.x === itemNewValue.x && itemPrevValue.y === itemNewValue.y) {
          // * item position did not change, no need to update
          return;
        }
        this.zone.run(() => this.updateWindowCoordinates(item, itemComponent));
      },
      start: (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => {
        this.itemPrevValue = { ...item };
        this.windowDragStartAction.next(item as DashboardWindow);
        if (item.layerIndex == 2) {
          this.storeService.updateWindow(item as DashboardWindow, { layerIndex: 1 });
          this.toggleMultiLayer(false);
          return;
        }
        const itemElm = itemComponent?.el;
        if (itemElm) {
          itemElm.classList.add('dragging');
        }
      },
    },
    initCallback: (grid: GridsterComponentInterface) => {
      this.gridRef = grid;
    },
  } as GridsterConfig;

  private readonly gridsterConfigAction = new BehaviorSubject<GridsterConfig>(this.gridsterConfig);
  readonly gridsterConfig$ = this.gridsterConfigAction.asObservable();

  private gridRef: GridsterComponentInterface | undefined;

  private readonly newWindowPlacementAction = new Subject<GridsterItem>();
  readonly newWindowPlacement$ = this.newWindowPlacementAction.asObservable();

  private readonly resizeWindow = new Subject<DashboardWindow>();
  readonly resizeWindow$ = this.resizeWindow.asObservable();

  private readonly ngUnsubscribe = new Subject<void>();

  private scrollPosition: { x: number; y: number } | undefined;

  constructor() {
    this.currentWindows$.subscribe(currentWindows => {
      this.currentWindows = currentWindows;
    });
  }

  private getGridsterCenterOffsets(cols: number | undefined, rows: number | undefined): { left: number; top: number } {
    const viewPortHeight = (this.gridRef?.curHeight ?? 0) / dashboardRaster;
    const viewPortWidth = (this.gridRef?.curWidth ?? 0) / dashboardRaster;
    const yOffset = (this.scrollPosition?.y ?? 0) / dashboardRaster;
    const xOffset = (this.scrollPosition?.x ?? 0) / dashboardRaster;
    const top = Math.floor((viewPortHeight - (rows ?? 0)) / 2 + yOffset);
    const left = Math.floor((viewPortWidth - (cols ?? 0)) / 2 + xOffset);
    // console.log(
    //   'placement:(left, top, cols, rows, viewPortHeight, viewPortWidth, yOffset, xOffset)',
    //   left,
    //   top,
    //   cols,
    //   rows,
    //   viewPortHeight,
    //   viewPortWidth,
    //   yOffset,
    //   xOffset
    // ); // NOSONAR debug
    return {
      left,
      top,
    };
  }

  addWindow(name: WindowName, settings?: WindowSettings, windowToAdd?: DashboardWindowToAdd): void {
    this.logger.log('addWindow', { name, settings, windowToAdd });
    this.storeService.closeAllModals();

    if (!WindowDefaultsMap[name]) {
      throw new Error(`Missing defaults error for window:${name}`);
    }

    this.toggleMultiLayer(true);

    const defaults: Partial<DashboardWindow> = {
      ...DashboardWindowDefaults, // generic defaults
      ...WindowDefaultsMap[name], // specific defaults
    } as Partial<DashboardWindow>;

    const centerOffsets = this.getGridsterCenterOffsets(defaults.cols, defaults.rows);

    if (settings != undefined) {
      this.storeService.addWindow({ name, settings }, centerOffsets);
      return;
    }
    if (windowToAdd != undefined) {
      this.storeService.addWindow({ ...windowToAdd, name }, centerOffsets);
      return;
    }
    this.storeService.addWindow({ name }, centerOffsets);
  }

  cloneWindow(sourceWindow: DashboardWindow): void {
    this.logger.log('cloneWindow', { sourceWindow });
    this.storeService.closeAllModals();

    this.toggleMultiLayer(true);
    this.storeService.cloneWindow(sourceWindow, this.getGridsterCenterOffsets(sourceWindow.cols, sourceWindow.rows));
  }

  cancelNewWindow(window: DashboardWindow): void {
    this.logger.log('cancelNewWindow', { window });
    this.toggleMultiLayer(false);
    this.storeService.deleteWindow(window);
  }

  placeNewWindow(gridItem: GridsterItem, window: DashboardWindow): void {
    this.logger.log('placeNewWindow', { window });
    this.toggleMultiLayer(false);
    setTimeout(() => {
      // gridster not ready unless timeout applied and because of that we also need to notify when the window has been placed
      if (!this.gridsterConfig.api?.getNextPossiblePosition) {
        return;
      }

      // IWT-680: getNextPossiblePosition sets proper x/y coordinates of newItemValue.
      // IMPORTANT: Do not use gridster2 getFirstPossiblePosition() as it will fail!
      // getFirstPossiblePosition method creates a copy of the item so that the equal check in
      // findItemWithItem is always false (can not filter out the "window to be checked") and
      // as a result all grid coordinates will be flagged as occupied!
      const itemComponent = this.gridRef!.getItemComponent(gridItem);
      const newitemValue = itemComponent?.$item ?? ({} as GridsterItem);
      this.gridsterConfig.api.getNextPossiblePosition(newitemValue);

      const newItem = { ...window, ...newitemValue, layerIndex: 1, dashboardId: this.storeService.currentDashboard?.id ?? '' };
      this.storeService.placeWindow(window, newItem);
      this.newWindowPlacementAction.next(newItem);
    }, 1);
  }

  setScrollPosition(scrollPosition: { x: number; y: number }): void {
    this.scrollPosition = scrollPosition;
  }

  private updateWindowCoordinates = (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => {
    this.logger.log('update window coordinates', { item, itemComponent });
    const isCollision = this.currentWindows
      .filter((window) => window.id !== item.id && window.dashboardId === item.dashboardId)
      .some((window) => this.gridRef!.checkCollisionTwoItems(window, itemComponent.$item));

    const window = this.currentWindows.find(window => window.id === item.id)!;

    if (isCollision) {
      this.placeNewWindow(item, window);
      return;
    }

    this.storeService.placeWindow(window, { ...itemComponent.$item });
    //all other windows need to update their positions also because gridster can have moved other windows automatically
    // we need to always keep track of all window positions in order to save them for the user or use undo/redo
    this.gridRef!.grid
      .filter((i) => (i.item as DashboardWindow).id !== item.id)
      .forEach((i) => {
        this.storeService.updateWindowAsSideEffect(i.item as DashboardWindow, { ...i.$item });
      });
  };

  // multi layer is toggled to be able to display a new window like a modal in a layer above the other windows
  private toggleMultiLayer = (allowMultiLayer: boolean) => {
    this.gridsterConfig = { ...this.gridsterConfig, allowMultiLayer };
    if (this.gridsterConfig.api?.optionsChanged) {
      this.gridsterConfig.api.optionsChanged();
    }
    this.gridsterConfigAction.next(this.gridsterConfig);
  };

  private closeAllModalsSubscription = this.storeService.closeAllModals$.subscribe(() => {
    this.dialog.closeAll();
    this.toggleMultiLayer(false);
  });

  // match dashboards by id to check weather we are allowed to show them or not, needed by portfolioDashboard for now
  dashboardGuard$ = this.storeService.currentDashboard$.pipe(
    switchMap((db) => {
      const emptyResult$ = of({ result: '' });
      if (db?.id === PORTFOLIO_DASHBOARD_ID) {
        const notLoggedInResult$ = of({ result: this.xlat(`DASHBOARD.TRADING.NOT_LOGGED_IN`) });
        return this.tradingService.tradingConnected$.pipe(
          switchMap((isConnected) => {
            return this.sdkRequestsService.infrontUIObservable$<boolean>('isTradingLoginDialogVisible').pipe(
              switchMap((isDialogVisible: boolean) => {
                return !isDialogVisible && !isConnected ? notLoggedInResult$ : emptyResult$;
              })
            );
          })
        );
      }
      return emptyResult$;
    }));

  autoDeleteWindow(window: DashboardWindow): void {
    this.deleteWindowAction.next(window);
  }

  private deleteWindowSubscription = this.deleteWindowAction.pipe(switchMap(inWindow => {
    if (this.traingOrderEntryService.isOpen()) {
      this.traingOrderEntryService.closeOrderEntry();
      return of(undefined);
    }

    this.logger.log('autoDeleteWindow', { inWindow });
    const confirmDialog$ = (): Observable<ConfirmDialogResult> =>
      this.dialog
        .open(ConfirmDialogComponent, { data: { header: this.xlat(`DASHBOARD.DELETE_WINDOW_PROMPT.DELETE_WINDOW`), message: this.xlat(`DASHBOARD.DELETE_WINDOW_PROMPT.ARE_YOU_SURE`) } })
        .afterClosed().pipe(map(result => result as ConfirmDialogResult));
    const disableWindowDeletePromptSetting = this.userSettingsService.getValue('disableWindowDeletePrompt') ?? false;
    const obs = disableWindowDeletePromptSetting ? of({ shouldDelete: true, disableDeletePrompt: true }) : confirmDialog$();
    return obs.pipe(
      take(1),
      tap(result => {
        if (!result) { // cancelled out of window
          return;
        }
        if (result.shouldDelete) {
          this.logger.log('deleting window from autoDeleteWindow', { inWindow });
          this.storeService.deleteWindow(inWindow);
        }
        if (!disableWindowDeletePromptSetting && result.disableDeletePrompt) {
          this.userSettingsService.setValue('disableWindowDeletePrompt', true);
        }
      }));
  })).subscribe();

}
