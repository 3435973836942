import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { StaticColumnsGridModule } from '../../wrappers/grid-wrappers/static-columns-grid/static-columns-grid.module';
import { FeedListMenuModule } from '../../shared/feed-list-menu/feed-list-menu.module';
import { RankingListComponent } from './ranking-list/ranking-list.component';
import { RankingComponent } from './ranking.component';

@NgModule({
  declarations: [RankingComponent, RankingListComponent],
  imports: [CommonModule, MatIconModule, MatMenuModule, MatButtonModule, StaticColumnsGridModule, FeedListMenuModule],
})
export class RankingModule {}
