import type { InfrontSDK } from '@infront/sdk';

import type { Column } from '../../shared/grid/columns.model';
import type { NewsType } from '../../state-model/window.model';
import type { Observable, Subject } from 'rxjs';
import type { UntranslatedNoRowsTemplate } from '../../shared/grid/grid.model';
import { deepFreeze } from '../../util/object';
import type { NewsWidget } from '../../state-model/widget.model';
import { multiInstrumentColumns, singleInstrumentColumns } from './news.columns';

// returned items newsHeadlines$ are instances of Infront.HeadlineItem
// we should use that type/class in the future and just extend it with our special properties
// export interface WTHeadlineItem extends Infront.HeadlineItem {
//   feedLongName: string;
//   feedShortName: string;
// }

export type NewsSourceMap = {
  [Key in NewsType]: Key extends 'Portfolio'
  ? (
    untranslatedNoRowsTemplateAction: Subject<UntranslatedNoRowsTemplate | undefined>
  ) => Observable<NewsSource | undefined>
  : (
    widget: NewsWidget,
    untranslatedNoRowsTemplateAction: Subject<UntranslatedNoRowsTemplate | undefined>
  ) => Observable<NewsSource | undefined>
};

export const columnsByNewsTypeMap: { [Type in NewsType]: Column[]; } = deepFreeze({
  'Instrument': singleInstrumentColumns,
  'Watchlist': multiInstrumentColumns,
  'Portfolio': multiInstrumentColumns,
  'Country': multiInstrumentColumns
});

export interface Vm {
  headlines: NewsHeadline[];
  columns: Column[];
  newsType: NewsType;
}

export interface NewsHeadline {
  dateTime: Date;
  headline: string;
  cellHeadline: string;
  feedShortName: string;
  feedLongName: string;
  feed?: number;
  id: string;
  isResearchNews?: boolean;
  isFlash?: boolean;
  symbols: InfrontSDK.SymbolId[];
  url: string;
  hasBody: boolean;
}

export const NewsChunkSize = 100;

export interface NewsSource {
  source: InfrontSDK.SymbolId[] | number[];
  sourceType: NewsSourceType;
}

export type NewsSourceType = 'instrument' | 'instruments' | 'watchlist' | 'feed' | 'portfolio';

export const HeadlineIcons: Readonly<{ [headlineType: string]: string }> = Object.freeze({
  url: '<span class="material-icons mat-icon-headline">launch</span>',
} as const);

export const overlayNoRowsTemplateOptions = deepFreeze({
  noData: { content: 'NEWS_WIDGET.GRID.NO_ROWS.NO_DATA' }, // default
  noSource: { content: 'NEWS_WIDGET.GRID.NO_ROWS.NO_SOURCE' },
  noCountry: { content: 'NEWS_WIDGET.GRID.NO_ROWS.NO_COUNTRY' },
  noCountryItems: { content: 'NEWS_WIDGET.GRID.NO_ROWS.NO_COUNTRY_ITEMS' },
  noWatchlist: { content: 'NEWS_WIDGET.GRID.NO_ROWS.NO_WATCHLIST' },
  noWatchlistItems: { content: 'NEWS_WIDGET.GRID.NO_ROWS.NO_WATCHLIST_ITEMS' },
  noInstrument: { content: 'NEWS_WIDGET.GRID.NO_ROWS.NO_INSTRUMENT' },
  noPortfolio: { content: 'NEWS_WIDGET.GRID.NO_ROWS.NO_PORTFOLIO' },
  noPortfolioItems: { content: 'NEWS_WIDGET.GRID.NO_ROWS.NO_PORTFOLIO_ITEMS' },
  noSelectedFeed: { content: 'NEWS_WIDGET.GRID.NO_ROWS.NO_SELECTED_FEED' },
  noAllowedFeed: { content: 'NEWS_WIDGET.GRID.NO_ROWS.NO_ALLOWED_FEED' },
  noFilterPassItem: { content: 'NEWS_WIDGET.GRID.NO_ROWS.NO_FILTER_PASS_ITEM' },
  inactiveWidget: { content: 'NEWS_WIDGET.GRID.NO_ROWS.INACTIVE_WIDGET' },
} as const satisfies { [name: string]: UntranslatedNoRowsTemplate; });
