import type { BareWindowType } from '../../state-model/window.model';

interface MainMenuConfig {
  label: string;
  windowToAdd: BareWindowType;
  dataTestId?: string;
}

export const mainMenuConfig: MainMenuConfig[][] = [
  [
    {
      label: 'MAIN_MENU_COMPONENT.ITEMS.MAJOR_EQUITY_INDICES',
      windowToAdd: { name: 'MarketOverviewWindow', settings: { template: 'MajorEquityIndices' } },
    },
    {
      label: 'MAIN_MENU_COMPONENT.ITEMS.MAJOR_EQUITY_FUTURES',
      windowToAdd: { name: 'MarketOverviewWindow', settings: { template: 'MajorEquityFutures' } },
    },
    { label: 'MAIN_MENU_COMPONENT.ITEMS.GLOBAL_LISTS', windowToAdd: { name: 'MarketWindow', settings: { feed: 790 } } },
  ],
  [
    { label: 'MAIN_MENU_COMPONENT.ITEMS.WORLD_FOREX', windowToAdd: { name: 'MarketWindow', settings: { feed: 12 } } },
    { label: 'MAIN_MENU_COMPONENT.ITEMS.WORLD_COMMODITIES', windowToAdd: { name: 'MarketWindow', settings: { feed: 13 } } },
    {
      label: 'MAIN_MENU_COMPONENT.ITEMS.OIL_OVERVIEW',
      windowToAdd: { name: 'MarketOverviewWindow', settings: { template: 'Oil' } },
    },
  ],
  [
    { label: 'MAIN_MENU_COMPONENT.ITEMS.CALENDAR', windowToAdd: { name: 'CalendarWindow', settings: { calendarType: 'Country' } } },
    { label: 'MAIN_MENU_COMPONENT.ITEMS.NEWS', windowToAdd: { name: 'NewsWindow', settings: { newsType: 'Country' } }, dataTestId: 'main-menu-NEWS' },
    { label: 'MAIN_MENU_COMPONENT.ITEMS.WATCHLIST', windowToAdd: { name: 'WatchlistWindow' }, dataTestId: 'main-menu-WATCHLIST' },
    { label: 'MAIN_MENU_COMPONENT.ITEMS.USERLIST', windowToAdd: { name: 'UserlistWindow' } },
    { label: 'MAIN_MENU_COMPONENT.ITEMS.CHAINS', windowToAdd: { name: 'ChainsWindow' } },
  ],
];
