import { ChangeDetectionStrategy, Component, EventEmitter, Input, type OnDestroy, Output, inject } from '@angular/core';
import { InfrontUtil } from '@infront/sdk';
import { ResourceService } from '@vwd/ngx-i18n/translate';
import { EMPTY, ReplaySubject, merge } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { SdkRequestsService } from '../../services/sdk-requests.service';
import type { Widget } from '../../state-model/widget.model';
import type { Instrument, } from '../../state-model/window.model';
import { EventLabel, EventLabelPriority, type EventLabelType, fields } from './symbol-events.model';
import { structuresAreEqual } from '../../util/equality';

@Component({
  selector: 'wt-symbol-events',
  templateUrl: './symbol-events.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SymbolEventsComponent implements OnDestroy {
  private readonly sdkRequestsService = inject(SdkRequestsService);
  private readonly translate = inject(ResourceService);

  @Input() set widget(widget: Widget) {
    this.widgetAction$.next(widget);
  }
  @Input() set instrument(instrument: Instrument | undefined) {
    this.instrumentAction$.next(instrument);
  }
  @Input() hideSymbolEvents: EventLabelType[] = [];
  @Input() alternativeValue: string | undefined;

  /** true: symbol-events badge is visible, false: badge is not visible */
  @Output() displayEvents = new EventEmitter<boolean>();

  showEvent = false;
  private eventList: { eventLabel: EventLabelType; eventDetails: string; }[] = [];

  private readonly widgetAction$ = new ReplaySubject<Widget | undefined>(1);
  private readonly instrumentAction$ = new ReplaySubject<Instrument | undefined>(1);

  private readonly instrument$ = merge(
    this.instrumentAction$,
    this.widgetAction$.pipe(
      switchMap(widget => widget ? this.sdkRequestsService.windowInstrument$(widget) : EMPTY),
      shareReplay(1),
    ),
  );

  private readonly symbolInfo$ = this.instrument$.pipe(
    tap(() => {
      // clear display data on every instrument change!
      this.eventList = [];
    }),
    switchMap((instrument) => instrument
      ? this.sdkRequestsService.streamingSymbolData$({
        symbolEntity: instrument, fields, uuid: InfrontUtil.makeUUID()
      })
      : EMPTY
    ),
    distinctUntilChanged(structuresAreEqual),
  );

  readonly events$ = this.symbolInfo$.pipe(
    map((symbol) => {
      const eventLabel = EventLabel[symbol.CalendarEventDisplayLabel as keyof typeof EventLabel] ?? 'NA';
      this.showEvent = !this.hideSymbolEvents.includes(eventLabel);
      this.displayEvents.emit(this.showEvent);

      if (this.showEvent && symbol.CalendarEventDisplayLabel) {
        const eventDetails = `${this.translate.get('SYMBOL_EVENTS_COMPONENT.LABEL.' + eventLabel) as string}: ${symbol.CalendarEventDetails ?? ''}`;

        // collect all streamed events for the current instrument
        this.eventList.push({ eventLabel, eventDetails });

        // select label according to priority list
        const labels = this.eventList.map((item) => item.eventLabel);
        labels.sort((a, b) => EventLabelPriority.indexOf(a) - EventLabelPriority.indexOf(b));
        const label = labels[0];

        // concatenate and unique events in the order they got streamed in
        const details = this.eventList
          .map((item) => item.eventDetails)
          .filter((item, pos, arr) => arr.indexOf(item) === pos) // remove duplicates!
          .join('\n'); // tooltip with one line per event

        return { label, details };
      }

      return undefined;
    }),
  );

  ngOnDestroy(): void {
    this.widgetAction$.complete();
    this.instrumentAction$.complete();
  }

}
