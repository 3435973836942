import { Injectable, type OnDestroy } from '@angular/core';

/**
 * When provided to a component, provides a way
 * to register cleanup hooks for when the component
 * is destroyed.
 *
 * In Angular 16, this service is provided by default,
 * so this is a back-compat shim mostly.
 */
@Injectable()
export class DestroyRef implements OnDestroy {
  private readonly callbacks: Array<() => void> = [];

  onDestroy(callback: () => void): void {
    this.callbacks.push(callback);
    // Note: unlike Angular 16's DestroyRef, this
    // does not return a cleanup function to unregister
    // the callback.
  }

  ngOnDestroy(): void {
    this.callbacks.forEach(callback => callback());
    this.callbacks.length = 0;
  }
}
