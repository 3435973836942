import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  type AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  type OnDestroy,
  type OnInit,
  Output,
  ViewChild,
  inject
} from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { debounceTime, delay, map, takeUntil, tap } from 'rxjs/operators';

import { StoreService } from '../../../services/store.service';
import type { MapMarketToMarketData, MarketDataType, MarketOrderItem, Markets } from '../../../typings/models/marketData';
import { MarketDataService } from './dropdown-table.service';
import { MarketHeaderMetadataMap, type MarketOverviewDropdownType } from './market-overview-dropdown.model';
import { MarketOverviewDropdownService } from './market-overview-dropdown.service';
import { translator, translator$ } from '../../../util/locale';

@Component({
  selector: 'wt-market-overview-dropdown',
  templateUrl: './market-overview-dropdown.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketOverviewDropdownComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly marketOverviewDropdownService = inject(MarketOverviewDropdownService);
  private readonly storeService = inject(StoreService);
  private readonly marketDataService = inject(MarketDataService);

  @Input() type: MarketOverviewDropdownType = 'Market';
  @Input() menuClass = '';
  @Input() menuBackdropClass = '';
  @Input() title: string | undefined;
  @Input() preSelectedFeeds?: number[];
  @Input() useCheckboxes = true;
  @Output() multiSourceSelected = new EventEmitter<MarketDataType[]>();
  @Output() sourceSelected = new EventEmitter<MarketDataType>();
  @Output() menuOpened = new EventEmitter<boolean>();
  @Output() menuClosed = new EventEmitter<boolean>();
  @Input() keepOtherModals = false;

  readonly queryMatchMinLength = 2;

  panelOpenState = false;

  singleSourceTitle: string | undefined;
  allData!: MapMarketToMarketData;

  @ViewChild('inputElm') inputElm!: ElementRef<HTMLInputElement>;
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;

  readonly checkedAmount = new BehaviorSubject<string | undefined>(undefined);
  readonly checkedAmount$ = this.checkedAmount.asObservable();
  readonly searchQuery = new BehaviorSubject<string>('');
  readonly searchQuery$ = this.searchQuery.asObservable();

  marketHeaderMetadata: { title: string; className: string }[] = [];
  marketOverview$!: Observable<MapMarketToMarketData>;

  allSelectedState: { [key: string]: boolean } = {};

  isFirstLoad = true;

  private readonly xlat = translator();
  private readonly xlat$ = translator$();

  private readonly ngUnsubscribe = new Subject<void>();

  ngOnInit(): void {
    if (this.type) {
      this.marketHeaderMetadata = MarketHeaderMetadataMap[this.type] ?? [];
    }
    this.marketOverview$ = this.marketOverviewList$(this.type);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.marketOverviewDropdownService.setMarketOverviewState('close', this.keepOtherModals);
  }

  ngAfterViewInit(): void {
    if (this.type !== 'Standalone') {
      return;
    }
    this.marketOverviewDropdownService.state$.pipe(takeUntil(this.ngUnsubscribe), delay(0)).subscribe((state) => {
      if (state === 'open' && !this.trigger.menuOpen) {
        this.trigger.openMenu();
      }
      if (state === 'close' && this.trigger.menuOpen) {
        this.trigger?.closeMenu();
      }
    });
  }

  onMenuOpened(el: ElementRef<HTMLInputElement>): void {
    this.inputElm.nativeElement.value = '';
    if (!this.keepOtherModals) {
      this.storeService.closeAllModals();
    }
    el?.nativeElement.focus();
    this.menuOpened.emit(true);
    this.marketOverviewDropdownService.setMarketOverviewState('open', this.keepOtherModals);
  }

  onMenuClosed(): void {
    this.menuClosed.emit(true);
    this.marketOverviewDropdownService.setMarketOverviewState('close', this.keepOtherModals);
  }

  private updateLocalState(triggerSelectChange: boolean = false): void {
    const allSelectedState = {} as { [key in Markets]: boolean };
    Object.keys(this.allData).forEach((key) => {
      allSelectedState[key as Markets] = this.isAllChecked(key as Markets);
    });
    this.allSelectedState = allSelectedState;
    this.checkedAmount.next(this.checkedSourcesFromTotal());
    const allCheckedSources = Object.values(this.allData)
      .flat()
      .filter((item) => item.selected);
    if (triggerSelectChange) this.multiSourceSelected.next(allCheckedSources);

    // IWT-780 when single source is selected, show the news feed description
    this.singleSourceTitle = (allCheckedSources.length === 1 ? allCheckedSources[0].description : undefined);
  }

  private queryMatch(searchQuery: string, item: string | undefined, isStrict: boolean = false): string | undefined {
    if (searchQuery.length < this.queryMatchMinLength) {
      return;
    }

    if (isStrict) {
      // .scrollIntoView(true);
      return item === searchQuery.toUpperCase() ? `<mark>${item}</mark>` : undefined;
    }

    const newItem = item?.replace(new RegExp(searchQuery, "gi"), (match) => `<mark>${match}</mark>`);
    return newItem !== item ? newItem : undefined;
  }

  private marketOverviewList$ = (type: MarketOverviewDropdownType): Observable<MapMarketToMarketData> =>
    combineLatest([
      this.xlat$('SDK.MARKET_NAME'),
      this.searchQuery$, // user input for filtering data
      this.marketOverviewDropdownService.marketData$(type),
    ]).pipe(
      debounceTime(this.isFirstLoad ? 0 : 500),
      tap(() => (this.isFirstLoad = false)),
      map(([_translate, searchQuery, defaultData]) => {
        const marketContainingMarketData = this.marketDataService.convertInputToMapMarketToMarketData(defaultData);

        // create <mark> tags for template from user input filter
        // would be faster to apply to this.allData below, but then some MarkHtml might not be cleared!
        // INFO: this double-loop adds < 100ms on my PC
        Object.keys(marketContainingMarketData || {}).forEach((market) => {
          marketContainingMarketData[market as Markets].forEach((subItem) => {
            subItem.descriptionMarkHtml = this.queryMatch(searchQuery, subItem.description);
            subItem.sourceMarkHtml = this.queryMatch(searchQuery, subItem.source, true);
          });
        });

        const filteredMarketContainingMarketData = searchQuery
          ? this.marketDataService.filterMarketData(marketContainingMarketData, searchQuery)
          : marketContainingMarketData;

        this.allData = filteredMarketContainingMarketData;

        this.setPreSelected(this.preSelectedFeeds);
        if (this.useCheckboxes) {
          this.updateLocalState();
        }
        return filteredMarketContainingMarketData;
      }),
    );

  marketSatisfiesSearch = (data: Array<MarketDataType>): boolean => !!data.length && !!this.searchQuery.value.length;

  checkAll(checked: boolean, key: string): void {
    (this.allData[key as Markets] as MarketDataType[]).forEach((item: MarketDataType) => (item.selected = checked));
    this.updateLocalState(true);
  }

  onSingleItemChanged(item: MarketDataType): void {
    item.selected = !item.selected;
    this.updateLocalState(true);
  }

  private setPreSelected(preSelectedFeeds: number[] | undefined): void {
    if (!preSelectedFeeds?.length) return;
    Object.values(this.allData)
      .flat()
      .filter(item => item.feed && preSelectedFeeds.includes(item.feed))
      .forEach(item => item.selected = true);
  }

  private checkedSourcesFromTotal(): string {
    const str = `${Object.values(this.allData)
      .flat()
      .filter((item) => item.selected).length
      }/${Object.keys(this.allData).reduce((reducer, key) => (reducer += (this.allData[key as Markets] as Array<unknown>).length), 0)}`; // NOSONAR false positive?
    return str;
  }

  private isAllChecked(key: Markets): boolean {
    const allItemsInCategory = this.allData[key] as MarketDataType[];
    return allItemsInCategory.every((item) => item.selected);
  }

  isSomeChecked(key: string): boolean {
    const allItemsInCategory = this.allData[key as Markets] as MarketDataType[];
    return allItemsInCategory.some((item) => item.selected) && !allItemsInCategory.every((item) => item.selected);
  }

  onInputChange(event: Event): void {
    this.searchQuery.next((event.target as HTMLInputElement).value);
  }

  onRowClick(row: MarketDataType): void {
    this.sourceSelected.next(row);
    this.searchQuery.next('');
    this.inputElm.nativeElement.value = '';
    this.trigger.closeMenu();
  }

  dropdownKeyOrder = (a: MarketOrderItem, b: MarketOrderItem): number => {
    const aMarketName = a?.value?.[0]?.countryName;
    const bMarketName = b?.value?.[0]?.countryName;
    if ((aMarketName == undefined) || (bMarketName == undefined)) {
      return 0;
    }
    // IWT-769 in IWT5 "GLOBAL" should always be at the top of the list, meaning above Australia
    if (a.key === 'Global') {
      return -1;
    }
    if (b.key === 'Global') {
      return 1;
    }
    const aTranslated = this.xlat(`SDK.MARKET_NAME.${aMarketName}`);
    const bTranslated = this.xlat(`SDK.MARKET_NAME.${bMarketName}`);
    return aTranslated.localeCompare(bTranslated);
  };
}
