// Window Defaults
import { readonlyWindowParams } from '../dashboard/templates/templates.model';
import { PeriodService } from '../services/period.service';
import { DefaultPeriods } from '../shared/period/default-periods';
import { deepFreeze } from '../util/object';
import type {
  AlertLogWindow,
  CalendarWindow,
  ChainsWindow,
  ChartMiniWindow,
  ChartWindow,
  CompanyDataWindow,
  CompanyInformationWindow,
  DashboardWindow,
  FocusMiniWindow,
  FocusWindow,
  InstrumentHeaderWindow,
  InstrumentWindow,
  MarketOverviewWindow,
  MarketWindow,
  NewsWindow,
  OrderbookWindow,
  PortfolioHeaderWindow,
  PortfolioOrderSummaryWindow,
  PortfolioOrdersWindow,
  PortfolioTradesWindow,
  PositionsEventsWindow,
  PositionsExposureWindow,
  PositionsSummaryWindow,
  TopShareholdersWindow,
  UserlistWindow,
  WatchlistWindow,
  WindowName
} from './window.model';

const defaultInstrument = { feed: 18177, ticker: 'DNB' };

export const TOP_BAR_TOTAL_WIDTH = 50;

export const DashboardWindowDefaults: Readonly<Pick<DashboardWindow, 'cols' | 'rows' | 'linkChannel'>> = {
  cols: 12,
  rows: 12,
  linkChannel: 'None',
};

export type WindowDefaults<TWindow extends DashboardWindow> = Pick<TWindow, 'settings'> &
  Partial<Pick<TWindow,
    'cols'
    | 'rows'
    | 'maxItemRows'
    | 'minItemRows'
    | 'maxItemCols'
    | 'minItemCols'
    | 'canSetLinkedInstrument'
    | 'widgetSettingsOverrides'
  >>;

export const MarketWindowDefaults = deepFreeze({
  settings: { feed: 18177 },
  cols: 29,
  rows: 16,
  minItemCols: 12,
  minItemRows: 9,
  canSetLinkedInstrument: true,
} as const satisfies WindowDefaults<MarketWindow>);

export const MarketOverviewWindowDefaults = deepFreeze({
  settings: { template: 'Norway' },
  cols: 12,
  rows: 16,
  minItemCols: 11,
  minItemRows: 14,
  canSetLinkedInstrument: true,
} as const satisfies WindowDefaults<MarketOverviewWindow>);

export const WatchlistWindowDefaults = deepFreeze({
  settings: { selectedWatchlist: undefined },
  cols: 29,
  rows: 16,
  minItemCols: 14,
  minItemRows: 9,
  canSetLinkedInstrument: true,
} as const satisfies WindowDefaults<WatchlistWindow>);

export const UserlistWindowDefaults = deepFreeze({
  settings: {},
  cols: 29,
  rows: 16,
  minItemCols: 14,
  minItemRows: 5, // IWT-1651 reduced by from 9 -> 5
  canSetLinkedInstrument: true,
} as const satisfies WindowDefaults<UserlistWindow>);

export const OrderbookWindowDefaults = deepFreeze({
  settings: { instrument: { ...defaultInstrument } },
  cols: 12,
  rows: 8,
  minItemCols: 10,
  minItemRows: 5,
} as const satisfies WindowDefaults<OrderbookWindow>);

export const InstrumentWindowDefaults = deepFreeze({
  settings: { instrument: { ...defaultInstrument } },
  widgetSettingsOverrides: {
    Calendar: {
      selectedPeriod: PeriodService.getStoreablePeriod(DefaultPeriods.MINUS1W_PLUS1M),
    }
  },
  cols: 15,
  rows: 14,
  minItemCols: 10,
  minItemRows: 5,
  canSetLinkedInstrument: true,
} as const satisfies WindowDefaults<InstrumentWindow>);

export const NewsWindowDefaults = deepFreeze({
  settings: { instrument: { ...defaultInstrument }, newsType: 'Instrument' },
  cols: 16,
  rows: 14,
  minItemCols: 14,
  minItemRows: 6,
} as const satisfies WindowDefaults<NewsWindow>);

export const ChartWindowDefaults = deepFreeze({
  settings: { instrument: { ...defaultInstrument } },
  cols: 24,
  rows: 18,
  minItemCols: 13,
  minItemRows: 10,
} as const satisfies WindowDefaults<ChartWindow>);

export const ChartMiniWindowDefaults = deepFreeze({
  settings: { instrument: { ...defaultInstrument } },
  cols: 13,
  rows: 10,
  minItemCols: 10,
  minItemRows: 9,
} as const satisfies WindowDefaults<ChartMiniWindow>);

export const FocusWindowDefaults = deepFreeze({
  settings: { instrument: { ...defaultInstrument } },
  cols: 8,
  rows: 5,
  minItemCols: 8,
  minItemRows: 5,
} as const satisfies WindowDefaults<FocusWindow>);

export const FocusMiniWindowDefaults = deepFreeze({
  settings: { instrument: { ...defaultInstrument } },
  cols: 8,
  rows: 5,
  minItemCols: 8,
  minItemRows: 5,
} as const satisfies WindowDefaults<FocusMiniWindow>);

export const AlertLogWindowDefaults = deepFreeze({
  settings: {},
  cols: 9,
  rows: 14,
} as const satisfies WindowDefaults<AlertLogWindow>);

export const InstrumentHeaderWindowDefaults = deepFreeze({
  settings: {},
  cols: TOP_BAR_TOTAL_WIDTH,
  rows: 1,
  ...readonlyWindowParams,
} as const satisfies WindowDefaults<InstrumentHeaderWindow>);

export const CalendarWindowDefaults = deepFreeze({
  settings: {
    calendarType: 'Instrument',
  },
  cols: 16,
  rows: 14,
  minItemCols: 14,
  minItemRows: 6,
} as const satisfies WindowDefaults<CalendarWindow>);

export const PortfolioHeaderWindowDefaults = deepFreeze({
  settings: {},
  cols: TOP_BAR_TOTAL_WIDTH,
  rows: 1,
} as const satisfies WindowDefaults<PortfolioHeaderWindow>);

export const PositionsSummaryWindowDefaults = deepFreeze({
  settings: {},
} as const satisfies WindowDefaults<PositionsSummaryWindow>);

export const PortfolioPositionsWindowDefaults = deepFreeze({
  settings: {},
} as const satisfies WindowDefaults<PositionsSummaryWindow>);

export const PositionsEventsWindowDefaults = deepFreeze({
  settings: {},
} as const satisfies WindowDefaults<PositionsEventsWindow>);

export const PositionsExposureWindowDefaults = deepFreeze({
  settings: {},
} as const satisfies WindowDefaults<PositionsExposureWindow>);

export const PortfolioTradesWindowDefaults = deepFreeze({
  settings: {},
} as const satisfies WindowDefaults<PortfolioTradesWindow>);

export const PortfolioOrdersWindowDefaults = deepFreeze({
  settings: {},
} as const satisfies WindowDefaults<PortfolioOrdersWindow>);

export const PortfolioOrderSummaryWindowDefaults = deepFreeze({
  settings: {},
} as const satisfies WindowDefaults<PortfolioOrderSummaryWindow>);

export const CompanyDataWindowDefaults = deepFreeze({
  settings: {}
} as const satisfies WindowDefaults<CompanyDataWindow>);

export const CompanyInformationWindowDefaults = deepFreeze({
  settings: {
    instrument: { ...defaultInstrument },
  },
  cols: 16,
  rows: 14,
  minItemCols: 14,
  minItemRows: 6,
} as const satisfies WindowDefaults<CompanyInformationWindow>);

export const TopShareholdersWindowDefaults = deepFreeze({
  settings: {}
} as const satisfies WindowDefaults<TopShareholdersWindow>);

export const ChainsWindowDefaults = deepFreeze({
  settings: {},
  cols: 29,
  rows: 16,
  minItemCols: 14,
  minItemRows: 5, // IWT-1651 reduced by from 9 -> 5
  canSetLinkedInstrument: true,
} as const satisfies WindowDefaults<ChainsWindow>);

// Non creatable windows can be added in the array below
// examplatory usage [ 'CalendarWindow' | 'NewsWindow' ] will make those two non user creatable
type NonUserCreatableWindowName = [];
type UserCreatableWindowWrapper<ExcludedWindowName extends Array<WindowName>> = Exclude<WindowName, ExcludedWindowName[number]>;
// Creatable windows (use blacklist above to exclude certain windows)
export type UserCreatableWindowName = UserCreatableWindowWrapper<NonUserCreatableWindowName>;

export const WindowDefaultsMap: { [WindowName in UserCreatableWindowName]: WindowDefaults<DashboardWindow> } = {
  MarketWindow: MarketWindowDefaults,
  MarketOverviewWindow: MarketOverviewWindowDefaults,
  WatchlistWindow: WatchlistWindowDefaults,
  OrderbookWindow: OrderbookWindowDefaults,
  InstrumentWindow: InstrumentWindowDefaults,
  NewsWindow: NewsWindowDefaults,
  ChartWindow: ChartWindowDefaults,
  ChartMiniWindow: ChartMiniWindowDefaults,
  FocusWindow: FocusWindowDefaults,
  FocusMiniWindow: FocusMiniWindowDefaults,
  AlertLogWindow: AlertLogWindowDefaults,
  CalendarWindow: CalendarWindowDefaults,
  PortfolioPositionsWindow: PortfolioPositionsWindowDefaults,
  PortfolioOrdersWindow: PortfolioOrdersWindowDefaults,
  PortfolioOrderSummaryWindow: PortfolioOrderSummaryWindowDefaults,
  PortfolioTradesWindow: PortfolioTradesWindowDefaults,
  InstrumentHeaderWindow: InstrumentHeaderWindowDefaults,
  PortfolioHeaderWindow: PortfolioHeaderWindowDefaults,
  PositionsSummaryWindow: PositionsSummaryWindowDefaults,
  PositionsEventsWindow: PositionsEventsWindowDefaults,
  PositionsExposureWindow: PositionsExposureWindowDefaults,
  NetTradesWindow: { settings: {} },
  UserlistWindow: UserlistWindowDefaults,
  FundScreenerWindow: { settings: {} },
  CompanyDataWindow: CompanyDataWindowDefaults,
  CompanyInformationWindow: CompanyInformationWindowDefaults,
  TopShareholdersWindow: TopShareholdersWindowDefaults,
  NotFoundWindow: { settings: {} },
  ChainsWindow: ChainsWindowDefaults,
};
