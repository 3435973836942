import { type Observable, type UnaryFunction, combineLatest, map, of, switchMap } from 'rxjs';
import type { DashboardRef } from '../state-refs';

export function allModelChanges<T>(): UnaryFunction<Observable<ReadonlyArray<T>>, Observable<ReadonlyArray<T>>>;
export function allModelChanges<T>(): UnaryFunction<Observable<T[]>, Observable<T[]>>;
export function allModelChanges(): UnaryFunction<Observable<DashboardRef[]>, Observable<unknown>> {
  return switchMap((dashboards) => {
    if (dashboards.length === 0) {
      return of([]);
    }
    return combineLatest(dashboards.map((db) => db.model$.pipe(map(() => db))));
  });
}
