/* eslint-disable @typescript-eslint/no-explicit-any */
export type Comparer<T> = (x: T, y: T) => boolean;

/**
 * Compare two values for primitive equality (basically === with NaN handling)
 */
export function valuesAreEqual<T>(x: T, y: T): boolean {
  if (x === y) {
    return true;
  } else if (typeof x === 'number' && typeof y === 'number') { // special case for numbers
    return isNaN(x) && isNaN(y);
  } else {
    return false;
  }
}

/**
 * Compare two JSON-like values for equality
 */
export function structuresAreEqual<T>(x: T, y: T): boolean {
  if (x === y) {
    return true;
  } else if (x == undefined || y == undefined || typeof x !== typeof y) {
    return false;
  } else if (typeof x === 'object') {
    // array
    if (Array.isArray(x)) {
      return Array.isArray(y) && arraysAreEqual(x, y, structuresAreEqual);
    } else if (Array.isArray(y)) {
      return false;
    } else {
      return objectsAreEqual(x, y as any, structuresAreEqual);
    }
  } else if (typeof x === 'number') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return isNaN(x) && isNaN(y as any);
  }
  return false;
}

/**
 * Compares arrays for structural equality; by default the comparison is one level deep,
 * but you can specify `structuresAreEqual` to extend this.
 */
export function arraysAreEqual<T>(x: ReadonlyArray<T> | null | undefined, y: ReadonlyArray<T> | null | undefined, elementCompare: Comparer<T> = valuesAreEqual): boolean {
  if (x === y) {
    return true;
  } else if (!x || !y || x.length !== y.length) {
    return false;
  }

  for (let i = 0; i < x.length; i++) {
    if (!elementCompare(x[i], y[i])) {
      return false;
    }
  }

  return true;
}

/**
 * Compares object literals for equality; by default the comparison is one level deep,
 * but you can specify `structuresAreEqual` to extend this.
 *
 * Special cases `Date`.
 */
export function objectsAreEqual<T extends object>(x: T, y: T, elementCompare: Comparer<any> = valuesAreEqual): boolean {
  if (x === y) {
    return true;
  } else if (!x || !y) {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const protoX = Object.getPrototypeOf(x);

  // Check we're looking at the same type
  if (protoX !== Object.getPrototypeOf(y)) {
    return false;
  }

  // Special case for Date
  if (x instanceof Date) {
    return y instanceof Date && x.valueOf() === y.valueOf();
  } else if (y instanceof Date) {
    return false;
  }

  // Except for Date, only object literals
  if (protoX !== Object.prototype) {
    return false;
  }

  // Check props in x are in y and also equal
  for (const key in x) {
    if (Object.hasOwn(x, key)) {
      if (!Object.hasOwn(y, key) || !elementCompare(x[key], y[key])) {
        return false;
      }
    }
  }

  // check all props in y are in x (if so, they are already equal)
  for (const key in y) {
    if (Object.hasOwn(y, key)) {
      if (!Object.hasOwn(x, key)) {
        return false;
      }
    }
  }

  // no property mismatch, so OK
  return true;
}

/**
 * Creates a comparison function that compares two inputs by the value returned
 * by the `selector` function, optionally using the specified `compareFn`.
 *
 * Useful for `rxjs` `distinctUntilChanged`.
 */
export function compareBy<T, S>(selector: (input: T) => S, compareFn: (x: S, y: S) => boolean = valuesAreEqual): (x: T, y: T) => boolean {
  return (x, y) => compareFn(selector(x), selector(y));
}

/**
 * Creates a comparison function that compares two arrays by the value returned
 * by the `selector` function per element, optionally using the specified
 * `compareFn`.
 *
 * Useful for `rxjs` `distinctUntilChanged` on arrays.
 */
export function compareArraysBy<T, S>(selector: (input: T) => S, compareFn: (x: S, y: S) => boolean = valuesAreEqual): (x: readonly T[], y: readonly T[]) => boolean {
  return (x, y) => arraysAreEqual(x, y, compareBy(selector, compareFn));
}
