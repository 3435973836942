import { Component } from '@angular/core';
import type { ICellRendererParams } from 'ag-grid-community';

import type { PerformanceItem } from '../../../widgets/performance/performance.model';

@Component({
  selector: 'wt-performance-bar-cell',
  template: `
    <wt-performance-bar
      [ohlc]="{ open: item.open, high: item.high, low: item.low, close: item.close }"
      [time]="item.time"
      [settings]="{ direction: 'Horizontal', thickness: 10, markerHeight: 15, markerThickness: 2 }"
    ></wt-performance-bar>
  `,
})
export class PerformanceBarCellComponent {
  item!: PerformanceItem;
  agInit(params: ICellRendererParams): void {
    this.item = params.data as PerformanceItem;
  }
}
