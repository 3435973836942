import { inject, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { InfrontSDK } from '@infront/sdk';
import { PreferencesMap } from '@vwd/microfrontend-core';
import { BehaviorSubject, combineLatest, NEVER, Observable, of } from 'rxjs';
import { distinctUntilKeyChanged, map, switchMap, tap } from 'rxjs/operators';

import { AlertNewEditDialogComponent } from '../shared/alert-dialog/alert-dialog.component';
import type { AlertDialogParams } from '../shared/alert-dialog/alert-dialog.model';
import type { AlertEvent, ServerAlert } from '../shared/alerts-list/alerts-list.model';
import { arrToStringId } from '../util/array';
import { getByStartAndEndChar } from '../util/string';
import { NotificationService } from './notification.service';
import { SdkRequestsService } from './sdk-requests.service';
import { SdkService } from './sdk.service';
import { StorageService, type UserSettingsStorageData } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private readonly storageService = inject(StorageService);
  private readonly sdkService = inject(SdkService);
  private readonly sdkRequestsService = inject(SdkRequestsService);
  private readonly dialog = inject(MatDialog);
  private readonly notificationService = inject(NotificationService);

  readonly myAlerts$ = this.sdkService.getArray$(InfrontSDK.alertList).pipe(
    switchMap((alerts: InfrontSDK.ServerAlert[]) => {
      if (!alerts.length) {
        return of([]);
      }
      return this.sdkRequestsService
        .addSymbolDataToList$({
          entityList: alerts,
          fieldList: [InfrontSDK.SymbolField.CountryFlag, InfrontSDK.SymbolField.FeedExchange, InfrontSDK.SymbolField.Feed, InfrontSDK.SymbolField.Ticker],
          entityIdMatch: this.instrumentFromAlert
        }).pipe(
          map((alertsPreWatchlistCheck) => {
            const enhancedAlerts = this.addWatchlistProperty(alertsPreWatchlistCheck as ServerAlert[]);
            // group and sort
            const sortedInstrumentAlerts = enhancedAlerts
              .filter((alert) => !!alert.feedExchange && alert.enabled)
              .sort((a: ServerAlert, b: ServerAlert) => a.feedExchange.localeCompare(b.feedExchange));
            const enabledWatchlistAlerts = enhancedAlerts.filter((alert) => !alert.feedExchange && alert.enabled);
            const disabledAlerts = enhancedAlerts.filter((alert) => !alert.enabled);
            const allAlerts = [...sortedInstrumentAlerts, ...enabledWatchlistAlerts, ...disabledAlerts];
            return allAlerts;
          })
        );
    })
  );

  private addWatchlistProperty = <T extends { type: InfrontSDK.AlertType; description: string }>(alerts: T[]): T[] =>
    alerts.map((alert) => {
      const watchlist = alert.type === InfrontSDK.AlertType.List ? getByStartAndEndChar(alert.description, '"', '"') : '';
      const item = { ...alert, watchlist };
      return item;
    });

  readonly alertLogMarkAsViewed = new BehaviorSubject<string[]>([]);
  readonly alertLog$ = combineLatest([this.sdkService.getArray$(InfrontSDK.alertLog), this.alertLogMarkAsViewed]).pipe(
    switchMap(([alerts, alertLogMarkAsViewed]) => {
      if (!alerts.length) {
        return of([]);
      }
      return this.alertEventsWithExtraProps$(alerts as InfrontSDK.AlertEvent[], alertLogMarkAsViewed);
    })
  );


  private alertEventsWithExtraProps$ = (alerts: InfrontSDK.AlertEvent[], alertLogMarkAsViewed?: string[]): Observable<AlertEvent[]> => {
    return this.sdkRequestsService
      .addSymbolDataToList$({
        entityList: alerts,
        fieldList: [InfrontSDK.SymbolField.CountryFlag],
        entityIdMatch: (alert: InfrontSDK.AlertEvent) => ({
          feed: alert.feed, // NOSONAR feed is deprecated, but no alternative known!
          ticker: alert.ticker, // NOSONAR ticker is deprecated, but no alternative known!
        })
      }).pipe(
        map((alerts) => {
          const alertsWithKeyProp = alerts.map((al) => ({
            ...al,
            key: arrToStringId([al.eventId.toString(), al.id]),
          } as AlertEvent));
          const alertsWithNewProp = !alertLogMarkAsViewed?.length
            ? alertsWithKeyProp
            : (this.addIsNewProperty(
              alertLogMarkAsViewed.map((id) => ({ key: id })),
              alertsWithKeyProp.map((al) => ({ ...al, ...{ isNew: false } } as AlertEvent)),
              'key'
            ) as AlertEvent[]);
          const alertsWithWatchlistProp = this.addWatchlistProperty(alertsWithNewProp);
          alertsWithWatchlistProp.sort((a: AlertEvent, b: AlertEvent) => {
            if ((a.eventTriggered ?? 0) > (b.eventTriggered ?? 0)) {
              return -1;
            }
            if ((a.eventTriggered ?? 0) < (b.eventTriggered ?? 0)) {
              return 1;
            }
            return 0;
          });
          return alertsWithWatchlistProp;
        })
      );
  };

  private addIsNewProperty = <T>(prev: T[], curr: T[], key: string) =>
    curr.map((item) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
      !prev.map((itm) => (itm as any)[key]).includes((item as any)[key]) ? { ...item, isNew: true } : item
    );

  private instrumentFromAlert = (alert: InfrontSDK.ServerAlert): { feed: number | undefined; ticker: string | undefined } => {
    if (alert.rule?.mdValue?.feed) {
      return {
        feed: alert.rule?.mdValue?.feed,
        ticker: alert.rule?.mdValue?.ticker,
      };
    }
    if (alert.rule?.left?.mdValue?.feed) {
      return {
        feed: alert.rule?.left?.mdValue?.feed,
        ticker: alert.rule?.left?.mdValue?.ticker,
      };
    }
    return { feed: undefined, ticker: undefined };
  };

  markAsSeen = (newMarkAsSeen: AlertEvent[]) => {
    const alertLogMarkAsViewed = this.userSettingsStorage.get('alertLogMarkAsViewed') ?? [];
    const newMarkAsSeenIds = newMarkAsSeen.map((itm) => itm.key);
    void this.userSettingsStorage.set('alertLogMarkAsViewed', [...new Set([...alertLogMarkAsViewed, ...newMarkAsSeenIds])]);
  };

  addAlertData$ = (alert: InfrontSDK.AlertData): Observable<InfrontSDK.AlertsResponse> => {
    const alertOptions = {
      action: InfrontSDK.AlertUpdateAction.AddAlert,
      alert,
    };
    return this.sdkService.getObject$(InfrontSDK.alertUpdate, alertOptions);
  };

  modifyAlertData$ = (alert: InfrontSDK.AlertData, originalAlert: InfrontSDK.ServerAlert): Observable<InfrontSDK.AlertsResponse> => {
    const alertOptions = {
      action: InfrontSDK.AlertUpdateAction.ModifyAlert,
      alert,
      alertId: originalAlert.id,
      revisionIndex: originalAlert.revisionIndex,
    };
    return this.sdkService.getObject$(InfrontSDK.alertUpdate, alertOptions);
  };

  addServerAlert$ = (alert?: InfrontSDK.ServerAlert): Observable<InfrontSDK.AlertsResponse> => {
    const alertOptions = {
      action: InfrontSDK.AlertUpdateAction.AddAlert,
      alert,
    };
    return this.sdkService.getObject$(InfrontSDK.alertUpdate, alertOptions);
  };

  modifyServerAlert$ = (alert: InfrontSDK.ServerAlert): Observable<InfrontSDK.AlertsResponse> => {
    const alertOptions = {
      action: InfrontSDK.AlertUpdateAction.ModifyAlert,
      alert,
      alertId: alert.id,
      revisionIndex: alert.revisionIndex,
    };
    return this.sdkService.getObject$(InfrontSDK.alertUpdate, alertOptions);
  };

  deleteAlert({ id: alertId, revisionIndex }: ServerAlert): Observable<InfrontSDK.AlertsResponse> {
    const alertOptions = {
      action: InfrontSDK.AlertUpdateAction.DeleteAlert,
      alertId,
      revisionIndex,
    };
    return this.sdkService.getObject$(InfrontSDK.alertUpdate, alertOptions);
  }

  openAlertDialog = ({ alertType, instrument, alert, watchlist }: AlertDialogParams): void => {
    const heightMap = [
      { alertType: 'instrument', showSearch: false, height: '221px' },
      { alertType: 'instrument', showSearch: true, height: '258px' },
      { alertType: 'watchlist', showSearch: false, height: '169px' },
      { alertType: 'watchlist', showSearch: true, height: '176px' },
    ];
    const instr = instrument ?? (alert ? this.instrumentFromAlert(alert) : undefined);
    this.dialog.open(AlertNewEditDialogComponent, {
      id: 'alertNewEditDialog',
      data: {
        alert,
        watchlist,
        instrument: instr,
        alertType,
      },
      disableClose: true,
      width: '390px',
      height: heightMap.find((itm) => alertType === itm.alertType && itm.showSearch === (!alert && !instr && alertType === 'instrument'))?.height,
    });
  };

  private readonly userSettingsStorage = this.storageService.getUserSettingsStorage() as PreferencesMap<UserSettingsStorageData>;
  // no need to dispose userSettingsWatchDisposable in root service
  private userSettingsWatchDisposable = this.userSettingsStorage.watch('alertLogMarkAsViewed', (alertLogMarkAsViewed) => {
    this.alertLogMarkAsViewed.next(alertLogMarkAsViewed);
  });

  constructor() {
    this.sdkService
      .getObject$(InfrontSDK.subscribeAlerts)
      .pipe(
        switchMap((alertSubResponse) => {
          const alert = alertSubResponse.alertTriggered;
          if (!alert) {
            return NEVER;
          }
          return this.alertEventsWithExtraProps$([alert]).pipe(map((alerts) => alerts[0]));
        }),
        distinctUntilKeyChanged('key'),
        tap((alert) => {
          this.notificationService.alertNofication(alert);
          this.markAsSeen([alert]);
        })
      )
      .subscribe(); // app length subscription
  }
}
