import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';

import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';
import { SearchModule } from '../../search/search.module';
import { CountryFlagModule } from '../country-flag/country-flag.module';
import { InstrumentFocusModule } from '../instrument-focus/instrument-focus.module';
import { SymbolStatusModule } from '../symbol-status/symbol-status.module';
import { AlertNewEditDialogComponent } from './alert-dialog.component';
import { AlertFormComponent } from './alert-form/alert-form.component';

@NgModule({
  declarations: [AlertNewEditDialogComponent, AlertFormComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatBadgeModule,
    PipesModule,
    DirectivesModule,
    CountryFlagModule,
    MatTooltipModule,
    OverlayModule,
    PipesModule,
    MatButtonModule,
    SearchModule,
    InstrumentFocusModule,
    I18nPipesModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatOptionModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatOptionModule,
    MatInputModule,
    DirectivesModule,
    SymbolStatusModule,
    I18nPipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [AlertNewEditDialogComponent],
})
export class AlertNewEditDialogModule {}
