import { type AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, type OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';

import type { ContextMenuContext, ContextMenuItem } from '../../../../shared/models/context-menu.model';

@Component({
  selector: 'wt-menu-panel',
  templateUrl: './menu-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class MenuPanelComponent implements AfterViewInit, OnInit {
  @ViewChild('menu', { static: true }) menu!: MatMenu;
  @Input() items?: ContextMenuItem[];
  @Input() context?: ContextMenuContext;

  @Output() itemClick = new EventEmitter<ContextMenuItem>();
  @Input() overlayPanelClass: string | string[] | undefined;

  scopeId = '';

  ngOnInit(): void {
    this.scopeId = this.context?.widget?.windowId ?? this.context?.window?.id ?? this.context?.dashboard?.id ?? this.context?.id ?? '';
  }

  onItemClick(item: ContextMenuItem, $event: Event): void {
    if (!item.closeOnClick) {
      $event.stopPropagation();
    }
    this.itemClick.emit(item);
  }

  menuItemCompareFn(index: number, item: ContextMenuItem): string {
    return JSON.stringify({ id: item.id, checked: item.checked, switched: item.checked });
  }

  ngAfterViewInit(): void {
    if (this.overlayPanelClass != undefined) {
      this.menu.overlayPanelClass = this.overlayPanelClass;
    }
  }
}
