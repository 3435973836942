import { ChangeDetectionStrategy, ChangeDetectorRef, Component, type OnDestroy } from '@angular/core';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import type { GuardJSONCompatible } from '@vwd/microfrontend-core';
import { Observable, Subject, of } from 'rxjs';
import { takeUntil, take, filter, switchMap } from 'rxjs/operators';
import { DashboardRealmsService, type RealmInfo } from '../../dashboard/providers/dashboard-realms.service';
import { StoreService } from '../../services/store.service';
import { TenantSettingsService } from '../../services/tenant-settings.service';
import { GeneralSettingsService, type SettingsStorageData } from '../../shared/general-settings';
import { NewAdminItemModalComponent } from '../new-admin-item-modal/new-admin-item-modal.component';

@Component({
  selector: 'wt-realm-admin-panel',
  templateUrl: './realm-admin-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
  ]
})

export class RealmAdminPanelComponent implements OnDestroy {
  private ngUnsubscribe = new Subject<void>();

  selectedRealmInfo: RealmInfo | undefined;
  selectedRealmSettingsService: GeneralSettingsService = NoopRealmSettingsService;

  canAddRealm = this.dashboardRealmsService.canManageOtherRealms;

  constructor(
    private storeService: StoreService,
    private dialogRef: MatDialogRef<RealmAdminPanelComponent>,
    private dashboardRealmsService: DashboardRealmsService,
    private tenantSettings: TenantSettingsService,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
  ) {
    this.realms$.subscribe((realms) => {
      this.onRealmInfo(realms);
    });
  }

  realms$ = this.dashboardRealmsService.realms$;

  private closeAllModalsSubscription = this.storeService.closeAllModals$.subscribe(() => this.dialogRef.close());

  ngOnDestroy() {
    this.closeAllModalsSubscription.unsubscribe();
  }

  addRealmModal(event: Event): void {
    this.dialog.open(NewAdminItemModalComponent, {
      width: '260px',
      data: {
        input: '',
        icon: 'add',
        title: 'ADMIN_DIALOG.NEW_REALM_DIALOG.TITLE',
        closeLabel: 'ADMIN_DIALOG.NEW_REALM_DIALOG.CLOSE',
        submitLabel: 'ADMIN_DIALOG.NEW_REALM_DIALOG.SUBMIT',
        hasValidation: true,
      },
    }).afterClosed()
      .pipe(
        take(1),
        filter((result) => !!result),
        switchMap(async newRealm => {
          if (newRealm) {
            const realmInfo = await this.dashboardRealmsService.addRealm(newRealm as string);

            this.showRealmInfo(realmInfo);

            this.cdRef.detectChanges();
          }
        }),
        takeUntil(this.ngUnsubscribe),
      ).subscribe();
  }

  showRealmInfo(realmInfo?: RealmInfo): void {
    this.selectedRealmInfo = realmInfo;
    this.selectedRealmSettingsService = realmInfo ? new SelectedRealmSettingsService(realmInfo.realm, this.tenantSettings) : NoopRealmSettingsService;
  }

  onRealmInfo(realms: RealmInfo[]): void {
    if (realms.length) {
      queueMicrotask(() => {
        this.showRealmInfo(realms[0]);
      });
    }
  }

  async deleteRealm(realmInfo: RealmInfo): Promise<void> {
    await this.dashboardRealmsService.deleteRealm(realmInfo.realm);
    this.showRealmInfo();
  }
}

const NoopRealmSettingsService: GeneralSettingsService = {
  setValue<K extends keyof SettingsStorageData>(key: K, value: SettingsStorageData[K] | undefined): Observable<void> {
    throw new Error('Method not implemented.');
  },
  getValue$<K extends keyof SettingsStorageData>(key: K): Observable<SettingsStorageData[K]> {
    return of<SettingsStorageData[K]>(undefined!);
  },
};

class SelectedRealmSettingsService implements GeneralSettingsService {
  constructor(
    private readonly realm: string,
    private readonly tenantSettings: TenantSettingsService,
  ) { }

  getValue$<K extends keyof SettingsStorageData>(key: K): Observable<SettingsStorageData[K]> {
    return this.tenantSettings.getValue$(key, this.realm);
  }

  setValue<K extends keyof SettingsStorageData>(key: K, value: GuardJSONCompatible<SettingsStorageData[K]> | undefined): Observable<void> {
    return this.tenantSettings.setValue(key, value, this.realm);
  }
}
