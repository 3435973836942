import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { DashboardService } from '../dashboard/dashboard.service';
import type { DashboardWindow, PartialDashboardWindow, WindowName, BareWindowType } from '../state-model/window.model';

export const UpdateDashboardWindowEvent = 'updateDashboardWindow';

// extended version of WindowSettings, while being a subset of Window (no id should be provided)
export type DashboardWindowToAdd = BareWindowType | (Exclude<Partial<DashboardWindow>, 'id'> & { name: WindowName }) | undefined;

@Directive()
export abstract class DashboardWindowControllerDirective<ActionParams extends Array<unknown> | undefined = undefined> {
  @Input() dashboardWindowPreCallback?: (() => void)[] | (() => void);
  @Input() dashboardWindowProceedCallback?: (() => void)[] | (() => void);

  @Output() dashboardWindowOnPreCallback = new EventEmitter<void>();
  @Output() dashboardWindowOnProceedCallback = new EventEmitter<void>();

  actionParams?: ActionParams;
  action?: (ActionParams extends undefined
    ? (() => void) // No parameters
    : ((...args: Exclude<ActionParams, undefined>) => void) // Require parameters
  );

  constructor(protected elementRef: ElementRef<Element>, protected dashboardService: DashboardService) { }

  // fires the assigned action method
  @HostListener('click') onClick(): void {
    if (Array.isArray(this.dashboardWindowPreCallback)) {
      this.dashboardWindowPreCallback.forEach((preCb) => preCb?.());
    } else {
      this.dashboardWindowPreCallback?.();
    }
    this.dashboardWindowOnPreCallback.emit();
    this.actionParams && Array.isArray(this.actionParams) ? this.action?.(...this.actionParams as []) : this.action?.();
    if (Array.isArray(this.dashboardWindowProceedCallback)) {
      this.dashboardWindowProceedCallback?.forEach((prcdCb) => prcdCb?.());
    } else {
      this.dashboardWindowProceedCallback?.();
    }
    this.dashboardWindowOnProceedCallback.emit();
  }
}

@Directive({
  selector: '[addDashboardWindow]',
})
export class AddDashboardWindowDirective extends DashboardWindowControllerDirective {
  @Input() dashboardWindowToAdd: DashboardWindowToAdd;

  constructor(protected elementRef: ElementRef<Element>, protected dashboardService: DashboardService) {
    super(elementRef, dashboardService);
    this.action = () => {
      this.addDashboardWindow();
    };
  }

  private addDashboardWindow(): void {
    if (this?.dashboardWindowToAdd) {
      this.dashboardService.addWindow(this.dashboardWindowToAdd.name, undefined, this.dashboardWindowToAdd);
    }
  }
}

@Directive({
  selector: '[updateDashboardWindow]',
})
export class UpdateDashboardWindowDirective extends DashboardWindowControllerDirective {
  @Input({ required: true }) dashboardWindowPartial!: PartialDashboardWindow;

  constructor(protected elementRef: ElementRef<Element>, protected dashboardService: DashboardService) {
    super(elementRef, dashboardService);
    this.action = () => {
      this.updateDashboardWindow();
    };
  }

  /**
   * Updates the current dashboard window that the directive's child element is part of
   *
   * How to implement in parent Component:
   * // @HostListener(UpdateDashboardWindowEvent, ['$event']) onUpdate(event: CustomEvent<WindowSettings>) {
   *   // use event.detail to access the dispatched parameters
   * // }
   */
  private updateDashboardWindow(): void {
    const updateEvent: CustomEvent<PartialDashboardWindow> = new CustomEvent(UpdateDashboardWindowEvent, {
      bubbles: true,
      cancelable: true,
      detail: this.dashboardWindowPartial,
    });
    this.elementRef.nativeElement.dispatchEvent(updateEvent);
  }
}
