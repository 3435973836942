import { valuesAreEqual } from './equality';

/**
 * Checks whether the specified object is an empty object (i.e., {}).
 *
 * @param x The object to check
 */
export function isEmptyObject(x: any): boolean {
  if (typeof x !== 'object') {
    return false;
  } else if (x == null) {
    return true;
  }

  return Object.keys(x).length === 0;
}

interface IsArrayFn {
  <T>(obj: T[] | T | null | undefined): obj is T[];
  <T>(obj: readonly T[] | T | null | undefined): obj is readonly T[];
  (obj: unknown): obj is unknown[];
}

export const isArray: IsArrayFn = Array.isArray;

/**
 * Returns an object with all properties that are `undefined` removed.
 *
 * @param object
 * @returns An object without any `undefined` properties. Returns the original
 *   {@link object} if it didn't have any `undefined` properties to begin with.
 */
export function removeUndefinedProperties<T extends object>(object: T): NoUndefined<T> {
  if (!object) {
    return object;
  }

  let result: T | undefined;

  for (const prop in object) {
    if (object.hasOwnProperty(prop) && object[prop] === undefined) {
      result ??= { ...object };
      delete result[prop];
    }
  }

  return (result ?? object) as NoUndefined<T>;
}

/**
 * Calls `Object.freeze()` on the specified object and all its properties, recursively.
 *
 * @param object The object to freeze.
 * @returns The original input value, but frozen.
 */
export function deepFreeze<T>(object: T): DeepReadonly<T> {
  if (typeof object !== 'object' || !object) {
    return object as DeepReadonly<T>;
  }

  Object.freeze(object);

  for (const prop in object) {
    if ((object as any).hasOwnProperty(prop)) {
      deepFreeze(object[prop]);
    }
  }

  return object as DeepReadonly<T>;
}

/**
 * Merges two objects, and returns a new object with its properties overridden
 * by the properties in {@link changes}.
 *
 * @remarks
 *
 * * Returns the original object if nothing changed.
 * * Deletes properties on {@link object} that are `undefined` (but not missing)
 *   in {@link changes} its properties, recursively.
 *
 * @param object The object to extend.
 * @param changes The properties to change.
 * @returns A modified copy of {@link object}, or the original if there were no visible changes.
 */
export function mergeWith<T extends object>(object: T, changes: Partial<T> | undefined | null): T {
  if (!changes) {
    return object;
  }
  let result: T | undefined;

  if (!object) {
    object = {} as T;
  }

  for (const prop in changes) {
    if (changes.hasOwnProperty(prop)) {
      const oldValue = object[prop];
      const newValue = changes[prop];
      if (!valuesAreEqual(oldValue, newValue)) { // does not do deep equality!
        result ??= { ...object };
        if (newValue === undefined) {
          delete result[prop];
        } else {
          result[prop] = newValue as any; // cast needed to shut up TS
        }
      }
    }
  }

  return result ?? object;
}

/**
 * Merges two objects at the first level, and returns a new object with its properties overridden
 * by the properties in {@link changes}.
 *
 * @remarks
 *
 * * Returns the original object if nothing changed.
 * * Deletes properties on {@link object} that are `undefined` (but not missing)
 *   in {@link changes} its properties, recursively.
 *
 * @param object The object to extend.
 * @param changes The properties to change.
 * @returns A modified copy of {@link object}, or the original if there were no visible changes.
 */
export function mergeOneLevel<T extends object>(object: T, changes: { [P in keyof T]?: Partial<T[P]> }): T {
  let result: T | undefined;

  for (const prop in changes) {
    if (changes.hasOwnProperty(prop)) {
      const oldValue = object[prop] as any;
      const newValue = changes[prop] as any;
      const merged = (typeof oldValue === 'object' && oldValue && typeof newValue === 'object') ? mergeWith(oldValue, newValue) : newValue;
      if (merged !== oldValue) {
        result ??= { ...object };
        if (newValue === undefined) {
          delete result[prop];
        } else {
          result[prop] = merged;
        }
      }
    }
  }

  return result ?? object;
}

/**
 * Removes all `readonly` specifiers from {@link T}.
 * The reverse of {@link Readonly<T>}.
 */
export type Writable<T extends object> = {
  -readonly [P in keyof T]: T[P];
};

/**
 * Removes all `readonly` specifiers from {@link T},
 * and all of its properties.
 *
 * @remarks
 * The explicit primitives list helps TypeScript narrow the mapped type.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type DeepWritable<T> = T extends string | number | boolean | bigint | symbol | undefined | null | Function
  ? T
  : {
    -readonly [P in keyof T]: DeepWritable<T[P]>;
  };

/**
 * Like {@link Readonly<T>}, but also for nested properties.
 *
 * @remarks
 * The explicit primitives list helps TypeScript narrow the mapped type.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type DeepReadonly<T> = T extends string | number | boolean | bigint | symbol | undefined | null | Function
  ? T
  : {
    readonly [P in keyof T]: DeepReadonly<T[P]>;
  };

/**
 * Removes the `undefined` type and optional specifier from all properties.
 *
 * @remarks
 * The explicit primitives list helps TypeScript narrow the mapped type.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type NoUndefined<T extends object> = T extends string | number | boolean | bigint | symbol | null | Function
  ? T
  : T extends undefined
  ? never
  : {
    [P in keyof T]-?: Exclude<T[P], undefined>;
  };
