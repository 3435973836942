import { Observable, type OperatorFunction } from 'rxjs';
import { fromMap, type ArrayMap } from './array-map';

type PropertiesOfType<T, U> = {
  [K in keyof T]: T[K] extends U ? K : never
}[keyof T];

interface RefMapOptions<TInput, TRef> {
  key?: PropertiesOfType<TInput, string> | ReadonlyArray<PropertiesOfType<TInput, string>> | ((model: TInput) => string),
  updateRef: (ref: TRef, model: TInput) => boolean,
  destroyRef?: (ref: TRef) => void,
}

function defaultDestroy(ref: any): void {
  if (typeof ref['destroy'] === 'function') {
    ref.destroy();
  }
}

/**
 * Maps an incoming stream of items to a list of "refs" (state slices).
 *
 * @param createRef: Factory function to create a ref object
 * @param config: ref object management options
 */
export function mapToRefs<TInput, TRef>(createRef: (model: TInput) => TRef, config: RefMapOptions<TInput, TRef>)
  : OperatorFunction<readonly TInput[], ArrayMap<string, TRef>> {
  const updateRef = config.updateRef;
  const destroyRef = config.destroyRef ?? defaultDestroy;
  let inputToId: (input: TInput) => string;

  if (typeof config.key === 'function') {
    inputToId = config.key;
  } else if (Array.isArray(config.key)) {
    const keys: readonly string[] = config.key;
    inputToId = ((input: any) => keys.map(prop => input[prop]).join('\u200b|\u200b')) as (input: TInput) => string;
  } else {
    const prop = config.key ?? 'id';
    inputToId = ((input: any) => input[prop]) as (input: TInput) => string;
  }

  return (source: Observable<readonly TInput[]>) => {
    return new Observable<ArrayMap<string, TRef>>(subscriber => {
      const cache = new Map<string, TRef>();
      let isFirstNext = true;

      const subscription = source.subscribe({
        next: inputs => {
          const idsSeen = new Set<string>();
          let arrayMap: ArrayMap<string, TRef> | undefined;

          let hasChanged = isFirstNext;
          isFirstNext = false;

          for (const input of inputs) {
            const id = inputToId(input);
            idsSeen.add(id);
            let ref = cache.get(id);
            if (!ref) {
              ref = createRef(input);
              cache.set(id, ref);
              hasChanged = true;
              arrayMap = undefined;
            } else if (updateRef(ref, input)) {
              hasChanged = true;
            }
          }

          for (const [id, ref] of cache.entries()) {
            if (!idsSeen.has(id)) {
              cache.delete(id);
              destroyRef(ref);
              hasChanged = true;
              arrayMap = undefined;
            }
          }

          if (hasChanged) {
            arrayMap ??= fromMap(cache);
            subscriber.next(arrayMap);
          }
        },
        // don't propagate errors
        // eslint-disable-next-line no-console
        error: err => console.error(err),
        complete: () => {
          if (destroyRef !== defaultDestroy) {
            cache.forEach(c => destroyRef(c)); // NOSONAR
          }
          cache.clear();
          subscriber.complete();
        },
      });
      return () => {
        subscription.unsubscribe();
      };
    });
  };
}
