import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { FeedListMenuModule } from '../../shared/feed-list-menu/feed-list-menu.module';
import { SymbolsGridModule } from '../../wrappers/grid-wrappers/symbols-grid/symbols-grid.module';
import { ConstituentsComponent } from './constituents.component';

@NgModule({
  declarations: [ConstituentsComponent],
  imports: [CommonModule, MatIconModule, MatMenuModule, MatButtonModule, FeedListMenuModule, SymbolsGridModule],
})
export class ConstituentsModule {}
