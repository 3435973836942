import { InfrontSDK } from '@infront/sdk';

import type { Column } from '../shared/grid/columns.model';
import type { Grid } from '../state-model/grid.model';
import type { Instrument } from '../state-model/window.model';

export const convertSymbolsToInstrumentList = (symbolList: InfrontSDK.SymbolData[]): Instrument[] =>
  symbolList.map((symbol) => ({
    feed: symbol.get(InfrontSDK.SymbolField.Feed),
    ticker: symbol.get(InfrontSDK.SymbolField.Ticker),
  }));

export const useSort = (grid: Grid | undefined) => {
  if (!grid?.settings?.selectedColumns?.length) {
    return true;
  }
  const sort = Object.values(grid?.settings?.selectedColumns).some((col: Column) => !!col.sort);
  return sort;
};

export const sdkSort = (data: InfrontSDK.SymbolData[]): InfrontSDK.SymbolData[] => {
  data.sort((a: InfrontSDK.SymbolData, b: InfrontSDK.SymbolData) => {
    // SortNumber = undefined, null or 0 (zero) will be set to -10000 and treated as "all other"!
    const sortNumberA = a.get(InfrontSDK.SymbolField.SortNumber) || -10000;
    const sortNumberB = b.get(InfrontSDK.SymbolField.SortNumber) || -10000;
    const sortNumberCompare = sortNumberB - sortNumberA;
    if (sortNumberCompare !== 0) {
      return sortNumberCompare;
    }
    const tickerA = a.get(InfrontSDK.SymbolField.Ticker);
    const tickerB = b.get(InfrontSDK.SymbolField.Ticker);
    if (tickerA < tickerB) {
      return -1;
    }
    if (tickerA > tickerB) {
      return 1;
    }
    return 0;
  });
  return data;
};
