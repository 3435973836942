import { inject, Injectable } from '@angular/core';
import { Infront, InfrontSDK, InfrontUtil } from '@infront/sdk';
import { LogService } from '@vwd/ngx-logging';
import { Observable, Subscriber } from 'rxjs';
import { filter, finalize, map, shareReplay, switchMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ToolkitService } from './toolkit.service';

@Injectable({
  providedIn: 'root',
})
export class SdkService {
  private readonly toolkitService: ToolkitService = inject(ToolkitService);
  private readonly logService: LogService = inject(LogService);

  private readonly logger = this.logService.openLogger('services/sdk');

  sdk$ = this.toolkitService.infrontUI$.pipe(
    map((infrontUI) => infrontUI.sdk),
    filter((sdk) => !!sdk),
    shareReplay(1)
  );




  constructor() { }

  // get the sdk onData payload as an observable array with type inference
  getArray$<TOptions extends { subscribe?: boolean }>(
    sdkMethod: (options: TOptions) => InfrontSDK.DataRequest,
    customOpts: Partial<TOptions> = {},
    initialUpdate?: InfrontUtil.InitialUpdate,
    caller?: string // optional debug
  ): Observable<Array<TInArray<TOptions>>> {
    let unsubscribeSDKGet: InfrontSDK.Unsubscribe;
    let unsubscribeSDKObserve: InfrontSDK.Unsubscribe;
    // arrayChanged$
    return this.sdk$.pipe(
      switchMap((sdk) =>
        new Observable((obs: Subscriber<Array<TInArray<TOptions>>>) => {
          const defaultOps = {
            subscribe: true,
            onData: (data: Array<TInArray<TOptions>> | Infront.ObservableArray<TInArray<TOptions>>) => {
              if (environment.development && caller) {
                // console.timeEnd(caller);
              }
              if (data instanceof Infront.ObservableArray) {
                unsubscribeSDKObserve = data.observe({
                  itemAdded: () => {
                    obs.next(data.data);
                  },
                  itemRemoved: () => {
                    obs.next(data.data);
                  },
                  reInit: () => {
                    obs.next(data.data);
                  },
                  itemChanged: () => {
                    if (!customOpts.subscribe) {
                      return;
                    }
                    obs.next(data.data);
                  },
                  itemMoved: () => {
                    obs.next(data.data);
                  },
                }, initialUpdate);
                obs.next(data.data);
              } else {
                obs.next(data);
              }
            },
            onError: (error: InfrontSDK.ErrorBase) => {
              if (environment.development) {
                this.logger.error('SDK Error getArray$:', error, 'caller:', caller, 'options:', JSON.stringify(options));
                // throw new Error(`SDK error:${error.title} ${error.parameters?.validation?.[0]}, caller: ${caller}`);
              }
            },
          };

          const options = {
            ...defaultOps,
            ...customOpts,
          } as unknown as TOptions;
          // debug
          if (environment.development && caller) {
            // this.logger.log(caller, 'options:', JSON.stringify(options));
            // console.time(caller);
          }
          try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
            unsubscribeSDKGet = sdk.get(sdkMethod(options));
          } catch (error) {
            this.logger.error('SDK Error sdk.get$:', error, 'caller:', caller, 'options:', JSON.stringify(options));
          }
        }).pipe(
          finalize(() => {
            this.logger.log('Unsubscribe getArray$ caller:', caller, customOpts);
            unsubscribeSDKGet?.();
            unsubscribeSDKObserve?.();
          })
        )
      )
    );
  }

  // get the sdk onData payload as an observable object or primitive with type inference
  // TOptions should extends InfrontSDK.DataRequestOptions
  getObject$<TOptions>(
    sdkMethod: (options: TOptions) => InfrontSDK.DataRequest,
    customOpts: Partial<TOptions> = {},
    caller?: string
  ): Observable<TSingle<TOptions>> {
    let unsubscribe: InfrontSDK.Unsubscribe;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.sdk$.pipe(
      switchMap((sdk) =>
        new Observable((obs: Subscriber<TSingle<TOptions>>) => {
          const defaultOpts = {
            subscribe: false,
            onData: (data: TSingle<TOptions>) => {
              if (environment.development && caller) {
                // console.timeEnd(caller);
              }
              obs.next(data);
            },
            onError: (error: InfrontSDK.ErrorBase) => {
              if (environment.development) {
                this.logger.log('Error getObject$:', error, 'caller:', caller, 'options:', JSON.stringify(options));
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                throw new Error(`SDK error: ${error.title} - ${error.parameters?.code}: ${error.parameters?.msg}`);
              }
            },
          };
          const options = { ...defaultOpts, ...customOpts } as unknown as TOptions;

          if (environment.development && caller) {
            // this.logger.log(caller, JSON.stringify(options));
            // console.time(caller);
          }
          unsubscribe = sdk.get(sdkMethod({ ...options }));
        }).pipe(
          finalize(() => {
            //this.logger.log('Unsubscribe getObject$ caller:', caller, customOpts);
            unsubscribe?.();
          })
        )
      )
    );
  }


}

type TSingle<T> = T extends InfrontSDK.DataRequestOptions<infer RetType>
  ? RetType extends Infront.ObservableArray
  ? never
  : RetType extends Infront.SortedObservableArray
  ? never
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  : RetType extends Array<any>
  ? never
  : RetType
  : never;

type TInArray<T> = T extends InfrontSDK.DataRequestOptions<Array<infer RetType>>
  ? RetType
  : T extends InfrontSDK.DataRequestOptions<Infront.ObservableArray<infer RetType>>
  ? RetType
  : T extends InfrontSDK.DataRequestOptions<Infront.SortedObservableArray<infer RetType>>
  ? RetType
  : never;
