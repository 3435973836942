import { ColumnRegistry } from '../../shared/grid/column-registry';
import type { Column } from '../../shared/grid/columns.model';

const newsWidgetColumns: { [colName: string]: Column; } = {
  newsTime: { ...ColumnRegistry.newsTime, sort: 'desc', type: 'rightAligned', maxWidth: 70, minWidth: 70 },
  source: { ...ColumnRegistry.source, maxWidth: 70, minWidth: 70 },
  tickerList: { ...ColumnRegistry.tickerList, maxWidth: 65, minWidth: 65 }
};


export const singleInstrumentColumns: Column[] = [
  newsWidgetColumns.newsTime,
  ColumnRegistry.headline,
  newsWidgetColumns.source,
];

export const multiInstrumentColumns: Column[] = [
  newsWidgetColumns.newsTime,
  ColumnRegistry.headline,
  newsWidgetColumns.tickerList,
  newsWidgetColumns.source,
];
