import type { PortfolioOrderSummaryWidget, PortfolioOrdersWidget } from '../../state-model/widget.model';
import type { LinkChannel, PortfolioOrderSummaryWindow, PortfolioOrdersWindow } from '../../state-model/window.model';
import type { OrderCategory } from '../../widgets/portfolio-orders/portfolio-orders.model';
import { PORTFOLIO_DASHBOARD_ID } from '../providers/portfolio-dashboards';
import { readonlyWindowParams } from './templates.model';
import { PortfolioOrderSummaryWindowDefaults } from '../../state-model/window.defaults';
import { deepFreeze } from '../../util/object';

const headerHeight = 1;

// col 1
const ordersWidth = 38;
const ordersHeight = 16;

export const portfolioOrdersBaseWindow = deepFreeze({
  id: 'order-summary',
  linkChannel: 'Channel 1',
  name: 'PortfolioOrdersWindow',
  settings: {},
  dashboardId: PORTFOLIO_DASHBOARD_ID,
  selectedWidgetName: 'PortfolioOrders',
  tag: 'ORDERS',
  cols: ordersWidth,
  rows: ordersHeight,
  x: 0,
  y: headerHeight,
  ...readonlyWindowParams,
} as const satisfies Partial<PortfolioOrdersWindow>);

export const portfolioOrdersWindows = deepFreeze({
  Active: {
    label: 'Active',
  },
  Algo: {
    label: 'Algo',
  },
  StopLoss: {
    label: 'Stop-Limit', // stop loss is wrongly named from backend, there is only stop limit and stop market as stop orders which can be for both buy and sell orders while stop loss as a term is only for sell
  },
  Inactive: {
    label: 'Inactive',
  },
  Deleted: {
    label: 'Deleted',
  },
  ExecutedOrDeletedExecuted: {
    label: 'Executed',
  },
  Other: {
    label: 'Other',
  },
  Unmapped: {
    label: 'Unmapped',
  },
} as const satisfies { [Cat in OrderCategory]: Partial<PortfolioOrdersWindow> });

export const portfolioOrderSummaryWindowBase = deepFreeze({
  id: 'order-summary',
  name: 'PortfolioOrderSummaryWindow',
  dashboardId: PORTFOLIO_DASHBOARD_ID as string,
  selectedWidgetName: 'PortfolioOrderSummary',
  linkChannel: 'None' as LinkChannel,
  cols: 12,
  rows: ordersHeight,
  x: portfolioOrdersBaseWindow.x + portfolioOrdersBaseWindow.cols,
  tag: 'ORDERS',
  label: 'OrderSummary',
  ...PortfolioOrderSummaryWindowDefaults,
  ...readonlyWindowParams,
} as const satisfies Partial<PortfolioOrderSummaryWindow>);

export const portfolioOrderSummaryWidgetBase = deepFreeze({
  id: 'w-order-summary',
  name: 'PortfolioOrderSummary',
  settings: {}
} as const satisfies Partial<PortfolioOrderSummaryWidget>);

export const portfolioOrdersWidgets = deepFreeze({
  Active: {
    settings: { selectedGrid: 'portfolioOrdersActive' },
  },
  Algo: {
    settings: { selectedGrid: 'portfolioOrdersAlgo' },
  },
  StopLoss: {
    settings: { selectedGrid: 'portfolioOrdersStopLoss' },
  },
  Inactive: {
    settings: { selectedGrid: 'portfolioOrdersInactive' },
  },
  Deleted: {
    settings: { selectedGrid: 'portfolioOrdersDeleted' },
  },
  ExecutedOrDeletedExecuted: {
    settings: { selectedGrid: 'portfolioOrdersExecuted' },
  },
  Other: {
    settings: { selectedGrid: 'portfolioOrdersOther' },
  },
  Unmapped: {
    settings: { selectedGrid: 'portfolioOrdersUnmapped' },
  },
} as const satisfies { [Cat in OrderCategory]: Partial<PortfolioOrdersWidget>; });

export const portfolioOrdersGrids = deepFreeze({
  Active: [{ name: 'portfolioOrdersActive' }],
  Algo: [{ name: 'portfolioOrdersAlgo' }],
  StopLoss: [{ name: 'portfolioOrdersStopLoss' }],
  Inactive: [{ name: 'portfolioOrdersInactive' }],
  Deleted: [{ name: 'portfolioOrdersDeleted' }],
  ExecutedOrDeletedExecuted: [{ name: 'portfolioOrdersExecuted' }],
  Other: [{ name: 'portfolioOrdersOther' }],
  Unmapped: [{ name: 'portfolioOrdersUnmapped' }],
} as const satisfies { [Cat in OrderCategory]: { name: string; }[]; });
