import { isDevMode } from '@angular/core';
import type { Widget } from '../../state-model/widget.model';
import type { DashboardWindow } from '../../state-model/window.model';
import { dummyInstrument, readonlyWindowParams } from './templates.model';
import { validateTemplate } from './validation';
import { deepFreeze } from '../../util/object';

// DASHBOARD TEMPLATE: CHART

export const chartDashboardWindows: DashboardWindow[] = deepFreeze([
  {
    id: 'chart1',
    linkChannel: 'None',
    name: 'ChartWindow',
    settings: { instrument: { ...dummyInstrument } },
    dashboardId: '1',
    selectedWidgetName: 'Chart',
    cols: 50,
    rows: 40,
    y: 1,
    x: 0,
    ...readonlyWindowParams,
  },
]);

export const chartDashboardWidgets: Widget[] = deepFreeze([
  {
    id: 'w-chart1',
    dashboardId: '1',
    name: 'Chart',
    settings: {
      chartOptions: {
        defaultPeriod: '1Y',
      },
    },
    windowId: 'chart1',
  },
]);

if (isDevMode()) {
  validateTemplate('chart-template', chartDashboardWindows, chartDashboardWidgets);
}
