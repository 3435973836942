import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, ElementRef, ViewChild, type OnDestroy, inject } from '@angular/core';
import { ResourceService } from '@vwd/ngx-i18n/translate';
import { BehaviorSubject, ReplaySubject, Subject, distinctUntilChanged, map, shareReplay, startWith, switchMap, debounceTime } from 'rxjs';

import { MatDropdownType } from './mat-dropdown.model';
import { type MatDropdownItem, MatDropdownItemType, type MatDropdownColumn } from './mat-dropdown-item/mat-dropdown-item.model';
import { StoreService } from '../../../services/store.service';
import { arraysAreEqual } from '../../../util/equality';

@Component({
  selector: 'wt-mat-dropdown',
  templateUrl: './mat-dropdown.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatDropdownComponent<T extends object> implements OnDestroy {
  private readonly storeService = inject(StoreService);
  private readonly resourceService = inject(ResourceService);

  @Output() itemSelected = new EventEmitter<MatDropdownItem<T>>();
  @Output() folderSelected = new EventEmitter<MatDropdownItem<T>>(); // optional, only when using items with type: MatDropdownItemType.FOLDER
  @Output() menuClosed = new EventEmitter<void>();
  @Output() menuOpened = new EventEmitter<void>();

  @Input({ required: true }) matIcon!: string;
  @Input() isSvgIcon = false; // if false, fontIcon is used
  @Input() menuClass: string | undefined;
  @Input() menuLabel: string | undefined;
  @Input() translateMenuLabel = false;
  @Input() menuBackdropClass!: string;

  @Input() useInputFilter = false;
  @Input() filterTarget: keyof T | undefined;
  @Input() filterFlexSmallSize: number | undefined;

  @Input() height: number | undefined;

  // Model
  @Input() matDropdownType: MatDropdownType = MatDropdownType.SINGLE;

  @Input() set items(items: MatDropdownItem<T>[]) {
    this.itemsAction.next(items);
  }
  itemsAction = new ReplaySubject<MatDropdownItem<T>[]>(1);
  items$ = this.itemsAction.asObservable().pipe(
    distinctUntilChanged((prev, next) => arraysAreEqual(prev, next)),
    shareReplay(1),
  );

  @Input() columns: MatDropdownColumn<T>[] | undefined;

  @Input() set filter(filter: string) {
    this.filterAction.next(filter);
  }
  private filterAction = new BehaviorSubject<string>('');
  filter$ = this.filterAction.asObservable().pipe(
    debounceTime(200),
    distinctUntilChanged(),
    shareReplay(1),
  );
  filteredItems$ = this.items$.pipe(
    switchMap((items) => this.filter$.pipe(
      map((filter) => {
        if (filter) {
          filter = filter.toLocaleLowerCase();
        } else {
          return items;
        }
        return items.filter((item) => {
          let label = item.itemLabel ?? '';
          if (item.itemLabel && item.translateItemLabel) {
            label = this.resourceService.getIfExists(item.itemLabel) as string;
          }
          const compareValue = label.toLocaleLowerCase();
          return compareValue.includes(filter);
        });
      }),
    ))
  );

  @Input() showMultiSelectionAmount = false;
  multiSelectAmount$ = this.items$.pipe(
    map((items) => items.filter((item) => item?.type === MatDropdownItemType.SELECTABLE && item.selected).length),
    startWith(0),
  );

  @ViewChild('itemFilterInputElement') itemFilterInputElement!: ElementRef<HTMLInputElement>;

  MatDropdownItemType = MatDropdownItemType;

  private ngUnsubscribe = new Subject<void>();

  ngOnDestroy(): void {
    this.itemsAction.complete();
    this.filterAction.complete();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onMenuOpened(element: ElementRef<HTMLInputElement>): void {
    this.storeService.closeAllModals();
    element?.nativeElement.focus();
    this.menuOpened.emit();
  }

  onMenuClosed(): void {
    this.menuClosed.emit();
  }

  onItemClick(item: MatDropdownItem<T>): void {
    this.itemSelected.emit(item);
  }

  onFilterChange(input: string): void {
    this.filterAction.next(input);
  }
}
