import { Injectable, Injector, inject } from '@angular/core';
import { type DashboardFolderModel, type DashboardItemModel, type DashboardModel, type DashboardModelCreate, type DashboardModelUpdate, DashboardProvider } from '@infront/ngx-dashboards-fx';
import { filterUndefined } from '@infront/ngx-dashboards-fx/src/lib/internal/utils/observables';
import { LastValueSubject } from '@infront/ngx-dashboards-fx/utils';
import { InfrontSDK } from '@infront/sdk';
import { LogService } from '@vwd/ngx-logging';
import { BehaviorSubject, EMPTY, Observable, catchError, concat, finalize, map, of, shareReplay, startWith, switchMap, take, tap, toArray } from 'rxjs';
import { SdkRequestsService } from '../../services/sdk-requests.service';
import { UserSettingsService } from '../../services/user-settings.service';
import type { Instrument } from '../../state-model/window.model';
import { structuresAreEqual } from '../../util/equality';
import { INSTRUMENT_DASHBOARD_FOLDER_ID, InstrumentDashboardsFolder, createDashboardModel } from './instrument-dashboard-template';

const MaximumInstrumentDashboardCount = 4;

@Injectable({ providedIn: 'root' })
export class InstrumentDashboardProvider extends DashboardProvider {
  private readonly injector = inject(Injector);
  private readonly userSettingsService = inject(UserSettingsService);

  private readonly logger = inject(LogService).openLogger('services/dashboards/provider/instrument-dashboards');

  private _sdkRequestsService: SdkRequestsService | undefined;

  get sdkRequestsService(): SdkRequestsService {
    this._sdkRequestsService ??= this.injector.get(SdkRequestsService);
    return this._sdkRequestsService;
  }

  private readonly dashboardsSubject = new BehaviorSubject<DashboardModel[]>([InstrumentDashboardsFolder]);
  private readonly dashboards$ = this.userSettingsService.getValue$('dashboardInstrumentHistory').pipe(
    tap((result) => this.logger.debug('dashboardInstrumentHistory updated', result)),
    switchMap((instruments?: Instrument[]) => {
      const loadedRoot: DashboardFolderModel = { ...InstrumentDashboardsFolder, childrenLoadState: 'loaded' };

      if (instruments?.length) {
        const instrumentObs = instruments.map((instrument, index) => {
          // look up existing dashboards; unlikely the classification has changed
          const existing = this.dashboardsSubject.value?.find(dash => structuresAreEqual(dash.attributes.instrument as unknown as Instrument, instrument));
          if (existing) {
            const classification = existing.attributes.classification as InfrontSDK.SymbolClassification;
            return of(createDashboardModel(classification, instrument, index));
          }
          // if not found, we need to look up the classification before we know which template to use
          return this.sdkRequestsService.snapshotSymbolData$({ symbolEntity: instrument, fields: [InfrontSDK.SymbolField.SymbolClassification] }).pipe(
            take(1),
            catchError(error => {
              this.logger.error(`Cannot load symbol ${JSON.stringify(instrument)}.`, error);
              return of(undefined);
            }),
            map((symbol) => {
              if (symbol) {
                const classification = symbol.SymbolClassification;
                if (classification) {
                  return createDashboardModel(classification, instrument, index);
                } else {
                  this.logger.error(`Symbol for ${JSON.stringify(instrument)} does not have a SymbolClassification.`);
                }
              }
              return undefined;
            }),
            filterUndefined(),
          );
        });

        return concat(...instrumentObs).pipe(
          startWith(loadedRoot),
          toArray(),
        );
      }

      return of([loadedRoot]);
    }),
    tap((result) => this.logger.debug('Loaded dashboards', result)),
    shareReplay(1),
  );

  constructor() {
    super();
    this.dashboards$.subscribe(this.dashboardsSubject);
  }

  getDashboards(): Observable<DashboardModel[]> {
    return this.dashboards$;
  }

  create(parent: DashboardFolderModel, data: DashboardModelCreate
    & { attributes: { instrument: Instrument, classification?: InfrontSDK.SymbolClassification } }): Observable<DashboardModel> {
    if (parent.id !== INSTRUMENT_DASHBOARD_FOLDER_ID) {
      throw new Error(`Can only create instrument dashboards below ${INSTRUMENT_DASHBOARD_FOLDER_ID}.`);
    }
    if (!data.attributes?.instrument) {
      throw new Error(`Need an instrument attribute for instrument dashboard.`);
    }

    const instrument = data.attributes.instrument;
    const classification = data.attributes.classification;

    if (classification != undefined) {
      return of(this.createAndAddToState(instrument, classification));
    }

    const result = new LastValueSubject<DashboardModel>();

    this.sdkRequestsService.snapshotSymbolData$({ symbolEntity: instrument, fields: [InfrontSDK.SymbolField.SymbolClassification] }).pipe(
      take(1),
      map((symbol) => {
        const classification = symbol?.SymbolClassification;
        if (classification) {
          return this.createAndAddToState(instrument, classification);
        }
        throw new Error(`Cannot create instrument dashboard: instrument ${JSON.stringify(instrument)} does not have a SymbolClassification.`);
      }),
      finalize(() => {
        if (!result.value && !result.closed) {
          result.error(new Error(`Creation of instrument dashboard failed.`));
        }
      })
    ).subscribe(result);

    return result;
  }

  private createAndAddToState(instrument: Instrument, classification: InfrontSDK.SymbolClassification): DashboardItemModel {
    let model = createDashboardModel(classification, instrument, 0);

    const newArray = [...this.dashboardsSubject.value ?? []];

    const existingIndex = newArray.findIndex(m => m.id === model.id);
    let action = 'Created';

    if (existingIndex > 1) {
      model = newArray[existingIndex] as DashboardItemModel;
      // do not actually create, but move in order
      newArray.splice(existingIndex, 1);
      action = 'Updated';
    }

    // Was the dashboard already the top dashboard? Do nothing
    if (existingIndex !== 1) {
      // Insert the new dashboard as the second entry (first being the parent)
      newArray.splice(1, 0, model);
      this.dashboardsSubject.next(newArray);
    }

    // Notify adding to history
    queueMicrotask(() => this.addInstrumentToHistory(instrument));

    this.logger.success(`${action} dashboard ${model.id} - ${model.name}`, { model, all: this.dashboardsSubject.value });

    return model;
  }

  update(id: string, changes: DashboardModelUpdate): Observable<DashboardModel> {
    this.logger.warn('Do not update instrument dashboards', { id, changes });
    return EMPTY;
  }

  delete(id: string): Observable<void> {
    const current = this.dashboardsSubject.value?.find(d => d.id === id);
    if (current) {
      const instrument = current.attributes?.instrument as unknown as Instrument;
      if (instrument) {
        this.removeInstrumentFromHistory(instrument);
      }
    }
    return EMPTY;
  }

  addInstrumentToHistory(instrument: Instrument): void {
    let history = this.userSettingsService.getValue('dashboardInstrumentHistory') ?? [];
    const existingPos = history.findIndex(ins => structuresAreEqual(ins, instrument));
    if (existingPos > 0) {
      history = [...history];
      history.splice(existingPos, 1);
      history.unshift(instrument);
    } else if (existingPos !== 0) {
      history = [instrument, ...history];
      if (history.length > MaximumInstrumentDashboardCount) {
        history.splice(MaximumInstrumentDashboardCount, 1);
      }
    } else { // already top instrument
      return;
    }
    this.userSettingsService.setValue('dashboardInstrumentHistory', history);
  }

  removeInstrumentFromHistory(instrument: Instrument): void {
    let history = this.userSettingsService.getValue('dashboardInstrumentHistory') ?? [];
    const existingPos = history.findIndex(ins => structuresAreEqual(ins, instrument));
    if (existingPos !== -1) {
      history = [...history];
      history.splice(existingPos, 1);
      this.userSettingsService.setValue('dashboardInstrumentHistory', history);
    } else { // nothing to do
      return;
    }
  }
}
