<div class="widget-title-bar__title gridster-item-content"
  [class.wt-draggable]="!dashboard.locked && window.dragEnabled !== false">
  <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle" *ngIf="vm$ | async as vm">
    <div class="wt-flex-column wt-flex-shrink">
      <mat-icon
        class="gridster-item-content"
        fontIcon="link">
      </mat-icon>
    </div>

    <!-- name -->
    <div *ngIf="canEditName"
      class="wt-flex-column wt-flex-shrink wt-userlist__name wt-userlist__name-editable" (click)="onEdit()"
      (mouseenter)="isPreEditMode = true"
      (mouseleave)="isPreEditMode = false" (blur)="isPreEditMode = false">
      {{vm.title}}
    </div>

    <div *ngIf="!canEditName"
      class="wt-flex-column wt-flex-shrink wt-userlist__name">
      {{vm.title}}
    </div>

    <!-- edit icon -->
    <div *ngIf="canEditName"
      class="wt-flex-column wt-flex-shrink wt-userlist__edit" (click)="onEdit()" (mouseenter)="isPreEditMode = true"
      (mouseleave)="isPreEditMode = false" (blur)="isPreEditMode = false">
      <mat-icon class="mat-icon wt-userlist__edit-icon" [class.wt-userlist__edit-icon--mouseover]="!!isPreEditMode"
        fontIcon="edit"></mat-icon>
    </div>

    <div class="wt-flex-column"></div>

    <ng-container *ngIf="window.dragEnabled !== false || window.layerIndex === 2">
      <div class="wt-flex-column wt-flex-shrink">
        <!-- Widget Title Bar Right (__buttons) -->
        <div class="widget-title-bar__buttons gridster-item-content">
          <ul class="wt-list-inline">
            <li class="gridster-item-content">
              <wt-link-channel-menu [window]="window" [dashboard]="dashboard"></wt-link-channel-menu>
            </li>
            <li class="gridster-item-content">
              <wt-context-menu class="gridster-item-content" [context]="{ widget, dashboard }"></wt-context-menu>
            </li>
          </ul>
        </div>
      </div>
    </ng-container>
  </div>
</div>