import { GlobalPositionStrategy, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { KeycloakAuthService } from '@vwd/keycloak-auth-angular';
import { from } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { IframeOverlayContentComponent } from './iframe-overlay-content.component';

@Injectable({
  providedIn: 'root',
})
export class IframeOverlayService {
  myProfile$ = () => from(this.keycloak.getToken()).pipe(tap((token) => {
    if (token == undefined) {
      throw new Error('Received no authentication token, therefore can not display "myProfile" page!');
    }
    this.openOverlay(
      'PROFILE_TITLE',
      `${environment.profileUrl}?_iwttoken=${token}`
    );
  }));

  support$ = () => from(this.keycloak.getToken()).pipe(tap((token) => {
    if (token == undefined) {
      throw new Error('Received no authentication token, therefore can not display "support" page!');
    }
    this.openOverlay(
      'SUPPORT_TITLE',
      `${environment.profileUrl}?_iwttoken=${token}&__act=redir.7.Support`
    );
  }));

  // ... here you can add more pages / URLs that need to be opened in an overlay Iframe!

  // configure the centered overlay for displaying iframes
  private positionStrategy: GlobalPositionStrategy = this.overlay.position()
    .global()
    .centerHorizontally()
    .centerVertically();
  private overlayRef: OverlayRef = this.overlay.create({
    // width: 100, // set in CSS
    // height: 100, // set in cSS
    backdropClass: 'cdk-overlay-transparent-backdrop',
    panelClass: 'wt-main-context-menu-overlay',
    hasBackdrop: true,
    positionStrategy: this.positionStrategy,
  });
  private backdropClick$ = this.overlayRef.backdropClick().pipe(
    tap(() => this.closeOverlay()),
  );

  constructor(
    private readonly keycloak: KeycloakAuthService,
    private readonly overlay: Overlay,
    public dialog: MatDialog
  ) { }

  openOverlay(title: string, url: string): void {
    this.closeOverlay();
    const overlayContent = new ComponentPortal(IframeOverlayContentComponent);
    const cmpRef = this.overlayRef.attach(overlayContent);
    cmpRef.instance.backdropClick$ = this.backdropClick$;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    cmpRef.instance.closeOverlay = this.closeOverlay.bind(this);
    cmpRef.instance.title = title;
    cmpRef.instance.url = url;
  }

  closeOverlay(): void {
    this.overlayRef.detach();
  }
}

