import { ChangeDetectionStrategy, Component, Input, type OnDestroy, inject } from '@angular/core';
import { LastValueSubject } from '@infront/ngx-dashboards-fx/utils';
import { NEVER, combineLatest, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { StoreService } from '../../../services/store.service';
import type { Dashboard } from '../../../state-model/dashboard.model';
import type { UserlistWidget } from '../../../state-model/widget.model';
import type { UserlistWindow } from '../../../state-model/window.model';
import { translator } from '../../../util/locale';
import { newUniqueName } from '../../../util/utils';
import { UserlistService } from '../../../widgets/userlist/userlist.service';

@Component({
  selector: 'wt-userlist-header',
  templateUrl: './userlist-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserlistHeaderComponent implements OnDestroy {
  private readonly storeService = inject(StoreService);
  private readonly userlistService = inject(UserlistService);

  private readonly widgetAction = new LastValueSubject<UserlistWidget>();

  @Input({ required: true }) dashboard!: Dashboard;
  @Input({ required: true }) window!: UserlistWindow;

  @Input()
  get widget(): UserlistWidget {
    return this.widgetAction.value!;
  }
  set widget(widget: UserlistWidget) {
    this.widgetAction.next(widget);
  }

  get canEditName() { return !this.dashboard.locked && (this.widget?.settings?.adminMode ?? true); }

  isPreEditMode = false;

  private readonly xlat = translator();

  readonly name$ = this.widgetAction.pipe(switchMap(widget => {
    if (widget?.settings.name) {
      return of(widget.settings.name);
    }
    // update widget settings and get the name on a second emit when settings are updated
    return this.storeService.widgetsByName$('Userlist').pipe(take(1), map((widgets) => {
      const names = widgets.map((w) => w.settings.name);
      const name = newUniqueName(this.xlat('USERLIST_WIDGET.DEFAULT_NAME'), names);
      this.storeService.updateWidget(widget, { settings: { ...widget.settings, name } });
    }),
      switchMap(() => NEVER));
  }));

  readonly vm$ = combineLatest([this.name$, this.widgetAction]).pipe(map(([name, widget]) => ({ name, widget })));

  onEdit(): void {
    const name = this.widgetAction.getValue()?.settings.name;
    if (!name) {
      return;
    }
    this.userlistService.renameDialog$(name).pipe(take(1)).subscribe((name) => {
      const widget = this.widgetAction.getValue();
      if (name && widget) {
        this.storeService.updateWidget(widget, { settings: { ...widget.settings, name } });
      }
      this.isPreEditMode = false;
    });
  }

  ngOnDestroy(): void {
    this.widgetAction.complete();
  }
}
