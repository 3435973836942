<div *ngIf="window" class="widget-title-bar__title">
  <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle">
    <div class="wt-flex-column">
      <!-- Drop down icon -->
      <wt-market-overview-dropdown
        *ngIf="title$ | async as title"
        [type]="'Market'"
        [menuClass]="'wt-market-header'"
        [useCheckboxes]="false"
        (sourceSelected)="onSourceSelected($event)"
        class="gridster-item-content">
        <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle">
          <div class="wt-flex-column wt-flex-shrink">
            <mat-icon class="wt-dropdown-header-icon" fontIcon="arrow_drop_down"></mat-icon>
          </div>

          <div class="wt-flex-column">
            <span class="wt-dropdown-label">
              {{ $any(title) | alternativeValue }}
            </span>
          </div>
        </div>
      </wt-market-overview-dropdown>
    </div>

    <div class="wt-flex-column wt-flex-shrink">
      <!-- Widget Title Bar Right (__buttons)-->
      <div class="widget-title-bar__buttons gridster-item-content">
        <ul class="wt-list-inline">
          <li class="gridster-item-content">
            <wt-link-channel-menu [window]="window" [dashboard]="dashboard"
              class="link-channel-menu gridster-item-content"></wt-link-channel-menu>
          </li>
          <li class="gridster-item-content">
            <wt-context-menu class="gridster-item-content"
              [context]="{ widget, dashboard }"></wt-context-menu>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>