import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'wt-new-window-dialog',
  templateUrl: './new-window-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewWindowDialogComponent {
  private readonly dialogRef = inject(MatDialogRef) as MatDialogRef<NewWindowDialogComponent>;
  readonly windowTypes = inject(MAT_DIALOG_DATA) as string[];

  selectedType: string = this.windowTypes[0];

  onCancel(): void {
    this.dialogRef.close();
  }
}
