import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';

import { PortfolioOrderSummaryComponent } from './portfolio-order-summary.component';
import { DynamicBarModule } from '../../shared/dynamic-bar/dynamic-bar.module';
import { LabelListModule } from '../../shared/label-list/label-list.module';

@NgModule({
  imports: [
    CommonModule,
    DynamicBarModule,
    LabelListModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  declarations: [PortfolioOrderSummaryComponent],
})
export class PortfolioOrderSummaryModule { }
