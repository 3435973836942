import { type CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, Input, type OnDestroy, inject } from '@angular/core';
import { DashboardFolderLevel, type DashboardFolderRef, type DashboardItemRef, type DashboardModel, DashboardService, DashboardType, isDashboardFolderRef, isDashboardItemRef } from '@infront/ngx-dashboards-fx';
import { LastValueSubject } from '@infront/ngx-dashboards-fx/utils';
import { LogService } from '@vwd/ngx-logging';
import { combineLatest, map, of, switchMap, take, tap } from 'rxjs';
import { DefaultDashboardsService } from '../../dashboard/default-dashboards.service';
import type { ContextMenuItem } from '../../shared/models/context-menu.model';
import { arraysAreEqual } from '../../util/equality';
import { getDashboardIcon } from '../../shared/dashboard-icons';

@Component({
  selector: 'wt-default-dashboards-panel',
  templateUrl: './default-dashboards-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultDashboardsPanelComponent implements OnDestroy {
  @Input()
  get realm(): string {
    return this.realmSubject.value!;
  }
  set realm(value: string) {
    this.canEdit = this.defaultDashboardsService.canEdit(value);
    this.realmSubject.next(value);
  }

  private realmSubject = new LastValueSubject<string>();
  private dashboardService = inject(DashboardService);
  private defaultDashboardsService = inject(DefaultDashboardsService);
  private logger = inject(LogService).openLogger('components/default-dashboards-panel');

  canEdit = false;

  dashboards$ = this.realmSubject.pipe(
    switchMap((realm) => this.defaultDashboardsService.getDashboards(realm))
  );

  menuItems$ = combineLatest([this.dashboardService.root$, this.realmSubject]).pipe(
    map(([root, realm]) => {
      const emptyDashboardItem = this.defaultDashboardsService.emptyDashboardItem();

      return [
        ...this.buildMenu(root, realm),
        ({
          id: `addDashboard-menuItem-emptyDashboard`,
          closeOnClick: true,
          title: 'DASHBOARD.DEFAULT_DASHBOARDS.MENU_LABEL',
          translateTitle: true,
          icon: getDashboardIcon(emptyDashboardItem, 'picker'),
          onClick: () => this.addDashboard(emptyDashboardItem),
        }) as ContextMenuItem
      ];
    }),
    tap((menu) => this.logger.debug('Add menu items updated', menu)),
  );

  ngOnDestroy(): void {
    this.realmSubject.complete();
  }

  buildMenu(folder: DashboardFolderRef, realm: string): ContextMenuItem[] {
    const result = folder.children.filter((item) => this.canPickFrom(item.model, realm)).map(
      (item) => ({
        id: `addDashboard-menuItem-${item.model.id}`,
        closeOnClick: true,
        title: item.displayName,
        translateTitle: false,
        icon: getDashboardIcon(item, 'picker'),
        subItems: isDashboardFolderRef(item) ? this.buildMenu(item, realm) : undefined,
        onClick: isDashboardItemRef(item) ? () => this.addDashboard(item) : undefined,
      }) as ContextMenuItem,
    );
    if (!result.length) {
      result.push({
        id: `addDashboard-menuItem-${folder.model.id}-empty`,
        title: '(empty)',
        disabled: of(true),
      });
    }
    return result;
  }

  canPickFrom(item: DashboardModel, realm: string): boolean {
    if (item.hidden) {
      return false;
    }

    if (item.type === DashboardType.DASHBOARD) {
      return true;
    }

    if (item.type === DashboardType.FOLDER) {
      if (item.level === DashboardFolderLevel.GLOBAL as number
        || item.level === DashboardFolderLevel.COMPANY as number
        && item.owner?.realm === realm) {
        return true;
      }
    }

    return false;
  }

  iconFor = getDashboardIcon;

  addDashboard(dashboard: DashboardItemRef): void {
    this.logger.log('Add dashboard', dashboard.model);
    this.dashboards$.pipe(
      take(1),
    ).subscribe((dashboards) => {
      if (!dashboards.some((ref) => ref.model.id === dashboard.model.id)) {
        this.defaultDashboardsService.setDashboards(this.realm, [...dashboards, dashboard]);
      }
    });
  }

  removeDashboard(dashboard: DashboardItemRef): void {
    this.logger.log('Delete dashboard', dashboard.model);
    this.dashboards$.pipe(
      take(1),
    ).subscribe((dashboards) => {
      const newDashboards = dashboards.filter((ref) => ref.model.id !== dashboard.model.id);
      if (!arraysAreEqual(dashboards, newDashboards)) {
        this.defaultDashboardsService.setDashboards(this.realm, newDashboards);
      }
    });
  }

  drop(event: CdkDragDrop<unknown, unknown, DashboardItemRef>): void {
    this.logger.log('List drop', event);
    this.dashboards$.pipe(
      take(1),
    ).subscribe((dashboards) => {
      const newDashboardsList = [...dashboards];
      moveItemInArray(newDashboardsList, event.previousIndex, event.currentIndex);
      this.defaultDashboardsService.setDashboards(this.realm, newDashboardsList);
    });
  }

  onItemClick(item: ContextMenuItem): void {
    if (!item.onClick) return;
    item.onClick({
      item,
    });
  }
}
