import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';

import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';
import { EventCardComponent } from './event-card.component';
import { NewsCardComponent } from './news-card.component';
import { PositionsEventsComponent } from './positions-events.component';


@NgModule({
  declarations: [PositionsEventsComponent, EventCardComponent, NewsCardComponent],
  imports: [
    CommonModule,
    MatTabsModule,
    MatButtonModule,
    CommonModule,
    I18nPipesModule,
    PipesModule,
    DirectivesModule,
    MatTabsModule,
    MatTooltipModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ]
})
export class PositionsEventsModule { }
