import { BehaviorSubject, combineLatest, type Observable, of, Subject, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, distinctUntilKeyChanged, map, mergeMap, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { type LocaleIdProvider, translate } from '../i18n';
import { structuresAreEqual } from '../internal/utils/equality';
import { LastValueSubject } from '../internal/utils/last-value-subject';
import { mergeWith } from '../internal/utils/objects';
import { filterUndefined, mapToVoid } from '../internal/utils/observables';
import { publishOnce } from '../internal/utils/publish-once';
import { assertDashboardFolder, type DashboardChildrenLoadState, type DashboardCreate, type DashboardFolderModel, type DashboardLinkCreate, type DashboardModel, type DashboardModelUpdate, DashboardType, isDashboardFolder, type StringOrTranslation } from '../models';
import { assertDashboardNodeRef, type DashboardFolderRef, type DashboardItemRef, type DashboardNodeRef, type DashboardRef, type DashboardTemplateRef, isDashboardFolderRef, isDashboardNodeRef } from '../state-refs';
import type { DashboardServicesProvider } from './dashboard-services.provider';
import type { DashboardService } from './dashboard.service';
import { compareDashboards } from './utils/dashboard-utils';

const NO_CHILDREN_ARRAY: readonly DashboardRef[] = Object.freeze([]);
const NO_CHILDREN_OBSERVABLE = of(NO_CHILDREN_ARRAY);

/**
 * A concrete implementation of {@link DashboardRef}; also implements
 * {@link DashboardFolderRef}.
 */
export class DashboardRefImpl implements DashboardRef {
  private readonly ngUnsubscribe = new Subject<void>();
  private _parent$: Observable<DashboardFolderRef | undefined> | undefined;
  private _children$: LastValueSubject<readonly DashboardRef[]> | undefined;
  private _shouldLoadChildren?: LastValueSubject<boolean> | BehaviorSubject<boolean>;

  public readonly model$ = new LastValueSubject<DashboardModel>();
  public readonly displayName$ = new LastValueSubject<string>();

  constructor(
    private readonly dashboardService: DashboardService,
    localeIdProvider: LocaleIdProvider,
    public readonly provider: DashboardServicesProvider,
    initialModel: DashboardModel,
  ) {
    combineLatest([this.model$, localeIdProvider.localeId$]).pipe(
      map(([model, localeId]) => {
        const attr = model.attributes['i18n-names'] as StringOrTranslation;
        if (attr) {
          return translate(attr, localeId, model.name); // could we cache this? attr is frozen so this will not be optimized
        } else {
          return model.name;
        }
      })
    ).subscribe(this.displayName$);
    this.model$.next(initialModel);
  }

  public get model(): DashboardModel {
    return this.model$.value!;
  }

  public get displayName(): string {
    return this.displayName$.value!;
  }

  public syncModel(value: DashboardModel): boolean {
    const oldModel = this.model;
    const newModel = mergeWith(oldModel, value);
    if (newModel !== oldModel) {
      this.dashboardService.logger.debug(`Updating DashboardRef for ${value.name}`, value);
      this.model$.next(value);
      return true;
    }
    return false;
  }

  public get children(): readonly DashboardRef[] {
    if (this.model.type !== 'FOLDER') {
      return NO_CHILDREN_ARRAY;
    }
    return this.lazyChildren$().value ?? NO_CHILDREN_ARRAY;
  }

  public get children$(): Observable<readonly DashboardRef[]> {
    // Build the children observable/subject lazily, so we only
    // allocate & subscribe when really desired.
    if (!this._children$) {
      if (this.model.type !== 'FOLDER') {
        return NO_CHILDREN_OBSERVABLE;
      }
      return this.lazyChildren$();
    }
    return this._children$;
  }

  // TODO: memoize to not allocate every time?

  public get childNodes(): readonly DashboardNodeRef[] {
    return this.children.filter(isDashboardNodeRef);
  }

  public get childNodes$(): Observable<readonly DashboardNodeRef[]> {
    return this.children$.pipe(map(nodes => nodes.filter(isDashboardNodeRef)));
  }

  public get childFolders(): readonly DashboardFolderRef[] {
    return this.children.filter(isDashboardFolderRef);
  }

  public get childFolders$(): Observable<readonly DashboardFolderRef[]> {
    return this.children$.pipe(map(nodes => nodes.filter(isDashboardFolderRef)));
  }

  public get childrenLoadState(): DashboardChildrenLoadState {
    return (this.model as DashboardFolderModel).childrenLoadState;
  }

  public get childrenLoadState$(): Observable<DashboardChildrenLoadState> {
    return (this.model$ as Observable<DashboardFolderModel>).pipe(
      map(model => model.childrenLoadState),
      distinctUntilChanged(),
    );
  }

  public get isLink(): boolean { return this.model.isLink; }

  public get link(): DashboardRef | undefined {
    if (this.model.isLink && this.model.linksTo) {
      return this.dashboardService.getRef(this.model.linksTo);
    }
    return undefined;
  }

  public get link$(): Observable<DashboardRef | undefined> {
    return this.model$.pipe(distinctUntilKeyChanged('linksTo'), map(() => this.link));
  }

  public loadChildren(): Observable<readonly DashboardRef[]> {
    assertDashboardFolder(this.model);
    if (this.model.childrenLoadState === 'on-demand') {
      this.dashboardService.logger.log(`On-demand loading of children requested for folder ${this.model.name}.`);
      if (this._shouldLoadChildren == null) {
        this._shouldLoadChildren = new BehaviorSubject<boolean>(true);
      } else if (!this._shouldLoadChildren.value) {
        this._shouldLoadChildren.next(true);
      }
    }
    return this.childNodes$;
  }

  // TODO: loading children of the RECENT_DASHBOARDS folder should return
  //       the MRU list's dashboards, so not based on `parentId`.
  // ^^^ probably means this method should consult the `provider`.
  private lazyChildren$(): LastValueSubject<readonly DashboardRef[]> {
    assertDashboardFolder(this.model);
    if (!this._children$) {
      this._children$ = new LastValueSubject<readonly DashboardRef[]>();

      if (this.model.childrenLoadState === 'on-demand') {
        this._shouldLoadChildren ??= new LastValueSubject<boolean>();
      }

      // Call the provider first (e.g., for lazy subtenant loading, or recent dashboards folder contents)
      const children$ = this._shouldLoadChildren == null
        ? this.loadChildrenImpl()
        : this._shouldLoadChildren.pipe(
          // wait until we get a `loadChildren` signal
          tap({ next: data => this.dashboardService.logger.log(`On-demand loading of children triggered for ${this.model.name}`, data) }),
          mergeMap(() => this.loadChildrenImpl()),
          startWith(NO_CHILDREN_ARRAY), // start out with an empty list, as we're still 'pending', and the user should still opt-in
        );

      children$.pipe(
        tap({ next: data => this.dashboardService.logger.log(`Children changed for ${this.model.name}`, data) }),
        takeUntil(this.ngUnsubscribe),
      ).subscribe({ next: (value) => this._children$!.next(value) });
    }
    return this._children$;
  }

  private loadChildrenImpl(): Observable<readonly DashboardRef[]> {
    return this.provider.dashboards.getChildren({
      dashboardService: this.dashboardService,
      node: this.model as DashboardFolderModel,
      nodeRef: this as DashboardFolderRef,
      models$: this.dashboardService.models$,
      comparer: compareDashboards,
    }).pipe(
      tap({ next: data => this.dashboardService.logger.log(`Loading of children done for ${this.model.name}`, data) }),
      map(models => Object.freeze(models.map(model => this.dashboardService.getRef(model.id)!))),
    );
  }

  // TODO: handle KnownDashboardFolderIDs.ROOT as implicit parent?
  public get parent(): DashboardFolderRef | undefined {
    if (!this.model.parentId) {
      return undefined;
    }

    return this.dashboardService.getRef(this.model.parentId) as DashboardFolderRef;
  }

  public get parent$(): Observable<DashboardFolderRef | undefined> {
    this._parent$ ??= this.dashboardService.models$.pipe(
      map(() => this.parent),
      distinctUntilChanged(),
      takeUntil(this.ngUnsubscribe),
    );
    return this._parent$;
  }

  public get monitorHierarchyChanges$(): Observable<DashboardFolderRef> {
    const result = this as DashboardFolderRef;

    if (this.model.type === DashboardType.FOLDER) {
      return this.children$.pipe(
        switchMap((children) => {
          if (!children.length) {
            return this.model$;
          }

          return combineLatest([
            this.model$,
            ...children.map((child) => isDashboardFolderRef(child) ? child.monitorHierarchyChanges$ : child.model$)
          ]);
        }),
        map(() => result),
      );
    }

    return this.model$.pipe(map(() => result));
  }

  public createChild(data: DashboardCreate & { type: DashboardType.FOLDER }): Observable<DashboardFolderRef>;
  public createChild(data: DashboardCreate & { type: DashboardType.TEMPLATE }): Observable<DashboardTemplateRef>;
  public createChild(data: DashboardCreate & { type: DashboardType.DASHBOARD }): Observable<DashboardItemRef>;
  public createChild(data: DashboardCreate): Observable<DashboardRef>;
  public createChild(data: DashboardCreate): Observable<DashboardRef> {
    const model = this.model;

    if (!isDashboardFolder(model)) {
      throw new Error('Cannot call createChild on non-folder dashboard.');
    }

    if (!model.security.canAddChildren) {
      throw new Error(`Cannot add children to folder ${this.displayName}.`);
    }

    if (data.copyWidgetsFrom && data.widgets) {
      throw new TypeError('Cannot combine widgets and copyWidgetsFrom.');
    }

    if (data.copyWidgetsFrom) {
      assertDashboardNodeRef(data.copyWidgetsFrom);
    }

    const loadedFolderObservable = this.childrenLoadState === 'on-demand'
      ? this.loadChildren().pipe(take(1), map(() => model))
      : of(model);

    let createdObservable = loadedFolderObservable.pipe(
      switchMap(parent =>
        this.provider.dashboards.create(parent, data).pipe(
          catchError(error => {
            error.stage = 'save-dashboard';
            return throwError(() => error);
          }),
          switchMap(createdDashboardModel =>
            this.dashboardService.dashboards$.pipe(
              map(dashboards => dashboards.get(createdDashboardModel.id)),
              filterUndefined(), // this should not hang!
            )
          ),
        ),
      ),
      take(1),
    );

    const widgetsToSaveObservable = data.copyWidgetsFrom
      ? this.provider.widgets.loadByDashboard(data.copyWidgetsFrom.model).pipe(
        take(1),
        catchError(error => {
          error.stage = 'load-widgets';
          return throwError(() => error);
        }),
      )
      : (data.widgets ? of(data.widgets) : undefined); // NOSONAR nested ternary accepted

    if (widgetsToSaveObservable) {
      createdObservable = combineLatest([createdObservable, widgetsToSaveObservable]).pipe(
        switchMap(([result, widgets]) =>
          this.provider.widgets.save({
            dashboard: result.model,
            markAsDraft: false,
            widgets: widgets,
          }).pipe(
            map(() => result),
            catchError(error => {
              error.stage = 'save-widgets';
              return throwError(() => error);
            }),
          ),
        )
      );
    }

    return createdObservable.pipe(publishOnce());
  }

  public createLink(data: DashboardLinkCreate<DashboardTemplateRef>): Observable<DashboardTemplateRef>;
  public createLink(data: DashboardLinkCreate<DashboardItemRef>): Observable<DashboardItemRef>;
  public createLink(data: DashboardLinkCreate): Observable<DashboardRef>;
  public createLink(data: DashboardLinkCreate): Observable<DashboardRef> {
    const model = this.model;
    if (!isDashboardFolder(model)) {
      throw new Error('Cannot call createLink on non-folder dashboard.');
    }

    if (!model.security.canAddChildren) {
      throw new Error(`Cannot add children to folder ${this.displayName}.`);
    }

    const modelObservable = this.childrenLoadState === 'on-demand'
      ? this.loadChildren().pipe(take(1), map(() => model))
      : of(model);

    return modelObservable.pipe(
      mergeMap(parent => this.provider.dashboards.createLink(parent, data)),
      mergeMap(result => this.dashboardService.dashboards$.pipe(
        map(dashboards => dashboards.get(result.id)),
      )),
      filterUndefined(), // this should not hang!
      publishOnce(),
    );
  }

  public canLinkTo(other: DashboardRef): boolean {
    return isDashboardFolder(this.model) && this.provider.dashboards.canLinkTo(this.model, other.model);
  }

  public delete(): Observable<void> {
    if (!this.model.security.canDelete) {
      throw new Error(`Cannot delete dashboard ${this.displayName} because it is read-only.`);
    }

    return this.provider.dashboards.delete(this.model.id);
  }

  public update(changes: DashboardModelUpdate): Observable<void> {
    if (!this.model.security.canEdit) {
      throw new Error(`Cannot update dashboard ${this.displayName} because it is read-only.`);
    }

    // Check whether model has changes
    const newModel = {
      ...this.model,
      ...changes,
      attributes: { ...this.model.attributes, ...changes.attributes },
    };

    if (structuresAreEqual(newModel, this.model)) {
      this.dashboardService.logger.info(`No changes updating dashboard ${this.model.id}`, { changes, current: this.model });
      return of();
    }

    if (changes.parentId && changes.parentId !== this.model.parentId) {

      return this.dashboardService.getFolder(changes.parentId).pipe(
        mergeMap(newParent => {
          if (!newParent.acceptsAsChild(this)) {
            return throwError(() => new Error(`Cannot add ${this.displayName} to folder ${newParent.displayName}.`));
          }
          if (newParent.childrenLoadState === 'on-demand') {
            newParent.loadChildren().pipe(
              mergeMap(() => this.provider.dashboards.update(this.model.id, changes)),
            );
          }
          return this.provider.dashboards.update(this.model.id, changes);
        }),
        mapToVoid(),
        publishOnce(),
      );

    }
    return this.provider.dashboards.update(this.model.id, changes) as Observable<unknown> as Observable<void>;
  }

  public acceptsAsChild(other: DashboardRef): boolean {
    if (other instanceof DashboardRefImpl && isDashboardFolder(this.model)) {
      if (this.provider === other.provider && this.model.security.canAddChildren && other.model.security.canMove) {
        return this.provider.dashboards.canMoveTo(other.model, this.model);
      }
    }
    return false;
  }

  public destroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.model$.complete();
    this._children$?.complete();
  }
}
