import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
@Component({
  selector: 'wt-new-dialog',
  templateUrl: './new-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class NewDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<NewDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string,
      input: string,
      icon: 'add-box',
      submitLabel: 'Submit',
      closeLabel: 'Close',
    },
  ) { }

  onCancel(): void {
    this.dialogRef.close();
  }
}
