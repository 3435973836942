import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { PricePipesModule } from '@vwd/ngx-price-pipes';

import { PipesModule } from '../../../pipes/pipes.module';
import { DropdownWatchlistComponent } from './dropdown-watchlist.component';
import { DropdownModule } from '../dropdown.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
  declarations: [DropdownWatchlistComponent],
  imports: [
    MatButtonModule,
    CommonModule,
    DropdownModule,
    MatIconModule,
    PricePipesModule,
    PipesModule,
    I18nPipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [DropdownWatchlistComponent]
})
export class DropdownWatchlistModule { }
