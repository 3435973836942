import { animate, style, transition, trigger } from '@angular/animations';
import { Dialog } from '@angular/cdk/dialog';
import { Component, ElementRef, HostListener, type OnDestroy, QueryList, ViewChild, ViewChildren, inject } from '@angular/core';
import { InfrontUtil } from '@infront/sdk';

import { type GridsterItem, GridsterItemComponent } from 'angular-gridster2';
import { BehaviorSubject, Subject, Subscription, combineLatest, fromEvent } from 'rxjs';
import { auditTime, filter, map, shareReplay, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { StoreToRemoteService } from '../services/store-to-remote.service';
import { StoreService } from '../services/store.service';
import { UserSettingsService } from '../services/user-settings.service';
import { DashboardType } from '../state-model/dashboard.model';
import { type DashboardWindow, PortfolioDashboardWindows, type PortfolioPositionsWindow } from '../state-model/window.model';
import { LOCALE_ID$ } from '../util/locale';
import { filterUndefined } from '../util/rxjs';
import { DashboardService } from './dashboard.service';

interface GridItemWrapper {
  window: DashboardWindow;
  gridItem: GridsterItem;
  layerIndex?: number;
  locked: boolean;
}

@Component({
  selector: 'wt-dashboard',
  templateUrl: './dashboard.component.html',
  animations: [
    trigger('opacityAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('0.3s linear', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('0.6s linear', style({ opacity: 0 }))]),
    ]),
  ],
})
export class DashboardComponent implements OnDestroy {
  private readonly dashboardService = inject(DashboardService);
  private readonly storeService = inject(StoreService);
  private readonly userSettingsService = inject(UserSettingsService);
  private readonly storeToRemoteService = inject(StoreToRemoteService); // needed or dashboards won't be loaded!
  private readonly dialog = inject(Dialog);

  // reloadAction and localeId$ are used for globally reloading windows and components on locale change!
  readonly reloadAction = new BehaviorSubject<boolean>(false);
  readonly localeId$ = inject(LOCALE_ID$).pipe(
    skip(1),
    tap((_locale: string) => {
      this.reloadAction.next(true);
      InfrontUtil.setZeroTimeout(() => this.reloadAction.next(false));
    }),
  );

  private _gridster!: ElementRef<HTMLElement>;
  private _gridsterNativeElement?: HTMLElement;
  private _gridsterNativeElementSubscription?: Subscription;

  @ViewChildren('gridsterItem') gridsterItemRefs!: QueryList<GridsterItemComponent>;

  @ViewChild('gridster', { read: ElementRef })
  get gridster(): ElementRef<HTMLElement> {
    return this._gridster;
  }
  set gridster(value: ElementRef<HTMLElement>) {
    this._gridster = value;
    const gridsterNativeElement = value?.nativeElement;
    if (gridsterNativeElement === this._gridsterNativeElement) {
      return;
    }

    this._gridsterNativeElement = gridsterNativeElement;

    if (this._gridsterNativeElementSubscription) {
      this._gridsterNativeElementSubscription.unsubscribe();
      this._gridsterNativeElement = undefined;
      this._gridsterNativeElementSubscription = undefined;
    }

    if (gridsterNativeElement) {
      this._gridsterNativeElementSubscription = fromEvent(gridsterNativeElement, 'scroll')
        .pipe(auditTime(100), takeUntil(this.ngUnsubscribe))
        .subscribe((event) => {
          const target = event.target as HTMLElement;
          const y: number = target.scrollTop;
          const x: number = target.scrollLeft;
          this.dashboardService.setScrollPosition({ y, x });
        });
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeyboardEscape() {
    const dialogsWereOpen = !!this.dialog.openDialogs.length;
    // check if we are in a regular (user) dashboard first
    this.storeService.currentDashboard$.pipe(take(1), filter(db => db?.type === DashboardType.dashboard),
      switchMap(() => {
        return this.currentWindows$
          .pipe(
            take(1),
            tap((windows) => {
              const windowsInAddNewMode = windows.filter((w) => w.layerIndex === 2);
              if (windowsInAddNewMode.length) {
                this.dashboardService.cancelNewWindow(windowsInAddNewMode[0]);
                return;
              }
              if (dialogsWereOpen) {
                // do not act if there are other dialogs open
                return;
              }

              const regularWindows = windows.filter((w) => w.layerIndex !== 2 && !!w.created);
              const maxValue = Math.max(...regularWindows.map(w => w.created!));
              const lastAddedWindow = regularWindows.find(w => w.created === maxValue);
              if (lastAddedWindow) {
                this.dashboardService.autoDeleteWindow(lastAddedWindow);
              }
            }));
      }),
      take(1)
    )
      .subscribe();
  }

  // if addWindow dialog present we need to supress scroll // todo: handle differently, know if we are in add window mode other than through refreshing currentWindows, leads to unintended bug when currentWindows observable unecessarily emits on wheelscroll
  // @HostListener('wheel', ['$event'])
  // onWheelScroll(event: WheelEvent) {
  //   this.currentWindows$
  //     .pipe(
  //       map((windows) => windows.filter((w) => w.layerIndex === 2)),
  //       tap((windows) => {
  //         if (windows.length) {
  //           event.stopImmediatePropagation();
  //           event.preventDefault();
  //         }
  //       }),
  //       take(1)
  //     )
  //     .subscribe();
  // }

  readonly currentDashboard$ = this.storeService.currentDashboard$.pipe(shareReplay(1));
  readonly currentWindows$ = this.dashboardService.currentWindows$.pipe(shareReplay(1));

  // Wrap windows as { window, gridItem, layerIndex } objects, so gridster
  // cannot mutate our (read-only) state object. We cache the objects so we
  // do not constantly trigger gridster's [item] input.
  readonly gridItemCache = new WeakMap<DashboardWindow, GridItemWrapper>();
  readonly gridItems$ = combineLatest([this.currentWindows$, this.currentDashboard$.pipe(filterUndefined())]).pipe(
    map(([windows, dashboard]) => {

      if (dashboard.type === DashboardType.portfolio && windows.some(w => w.dashboardId !== dashboard.id)) {
        // for portfolio we need a full match, otherwise we will get a flicker of non current dashboard windows until currentWindows observable emits again
        return []; // clear the dashboard of old windows ASAP until currentWindows observable emits again
      }
      const refreshedWindows = windows.map((window) => {
        let gridItem = this.gridItemCache.get(window);
        if (gridItem && gridItem.locked === dashboard.locked) {
          return gridItem;
        }

        gridItem = {
          window,
          gridItem: {
            ...window,
            resizeEnabled: window.resizeEnabled && !dashboard.locked,
            dragEnabled: window.dragEnabled && !dashboard.locked,
          },
          layerIndex: window.layerIndex,
          locked: dashboard.locked,
        };

        this.gridItemCache.set(window, gridItem);
        return gridItem;
      });
      return refreshedWindows;
    }),
  );

  readonly gridsterConfig$ = this.dashboardService.gridsterConfig$;
  readonly dashboardGuardMessage$ = this.dashboardService.dashboardGuard$;

  readonly sidebarIsOpen$ = this.userSettingsService.getValue$('sidebar').pipe(
    map((sidebar) => sidebar?.isOpen)
  );

  private readonly ngUnsubscribe = new Subject<void>();

  //private pendingScrollCoordinates: [number, number] | undefined;

  windowCompareFn(index: number, { window, locked }: { window: DashboardWindow, locked: boolean }): string {
    if (PortfolioDashboardWindows.includes(window.name)) {
      return (window as PortfolioPositionsWindow).renderId;
    }
    return window.dashboardId + '>' + window.id + '|' + String(locked);
  }

  onCancel(window: DashboardWindow): void {
    this.dashboardService.cancelNewWindow(window);
  }

  onAddToWorkspace(item: GridItemWrapper): void {
    this.dashboardService.newWindowPlacement$.pipe(take(1)).subscribe(() => {
      this.autoScroll();
    });
    this.dashboardService.placeNewWindow(item.gridItem, item.window);
  }

  private autoScroll(): void {
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const dashboardElm = this.gridster.nativeElement;
      const newDashboardWindowElm = this.gridsterItemRefs.last?.el;
      const scrollTopValue =
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        newDashboardWindowElm && newDashboardWindowElm.getBoundingClientRect().bottom > dashboardElm.clientHeight
          ? newDashboardWindowElm.getBoundingClientRect().bottom
          : 0;
      const scrollLeftValue =
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        newDashboardWindowElm && newDashboardWindowElm.getBoundingClientRect().right > dashboardElm.clientWidth
          ? newDashboardWindowElm.getBoundingClientRect().left
          : 0;
      //this.pendingScrollCoordinates = [scrollLeftValue, scrollTopValue];
      setTimeout(() => {
        this.gridster.nativeElement.scrollLeft = scrollLeftValue;
      }, 0);
      setTimeout(() => {
        this.gridster.nativeElement.scrollTop = scrollTopValue;
      }, 700);
    }, 800); // we need enough time for gridster to animate placement of new window for us to find out where that new window is
  }

  ngOnDestroy(): void {
    this.reloadAction.complete();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
