import type { Logger } from '@vwd/ngx-logging';
import type { JSONObject, JSONValue } from '../../models/json.models';
/**
 * Tries to parse a JSON string; warns about parsing errors, and returns `undefined` in that case.
 */
export function tryParseJson(json: string, logger?: Logger): JSONValue | undefined {
  if (!json) {
    return undefined;
  }
  try {
    return JSON.parse(json);
  } catch (e) {
    (logger ?? console).warn(`Cannot parse JSON value ${json}`, e);
    return undefined;
  }
}

/**
 * Tries to parse a JSON string as an object (so no primitives or arrays);
 * warns about parsing errors, and returns `undefined` in that case.
 */
export function tryParseJsonObject(json: string, logger?: Logger): JSONObject | null | undefined {
  const jsonObj = tryParseJson(json, logger);
  if (jsonObj == null) {
    return jsonObj;
  }

  if (typeof jsonObj === 'object' && !Array.isArray(jsonObj)) {
    return jsonObj;
  }

  (logger ?? console).warn(`JSON value ${json} is not an object`);
  return undefined;
}
