import type { CellClassParams } from 'ag-grid-community';
import { isToday, startOfDay } from 'date-fns';

import type { InfrontSDK } from '@infront/sdk';
import { ColumnRegistry } from '../../shared/grid/column-registry';
import type { Column } from '../../shared/grid/columns.model';
import { daysDiff } from '../../util/date';
import { OutdatedClassModifier, RightAlignClassModifier, TodayClassModifier } from './calendar.model';

const calendarWidgetColumns: { [colName: string]: Column; } = {
  calendarDate: {
    ...ColumnRegistry.calendarDate,
    sort: 'asc',
    type: 'rightAligned',
    maxWidth: 70,
    minWidth: 70,
    cellClass: (params: CellClassParams<InfrontSDK.CalendarEvent, Date>) => {
      const classList: string[] = [];

      if (params.data) {
        let { dateTime } = params.data;
        if (dateTime != undefined) {
          dateTime = dateTime instanceof Date ? dateTime : new Date(dateTime);
          if (!isNaN(dateTime.getTime())) {
            if (isToday(dateTime)) {
              // IWT-1383 highlight CalendarEvents from today (yellowish)
              classList.push(TodayClassModifier);
            }
            if (daysDiff(startOfDay(Date.now()), dateTime) > 0) {
              // IWT-1326 grey out deprecated CalendarEvents (older than today)
              classList.push(OutdatedClassModifier);
            }
          }
        }
      }

      if (params?.colDef?.headerClass) {
        classList.push(RightAlignClassModifier);
      }

      return classList;
    },
  },
  source: { ...ColumnRegistry.source, maxWidth: 70, minWidth: 70 },
  tickerList: { ...ColumnRegistry.tickerList, maxWidth: 65, minWidth: 65 }
};

export const singleInstrumentColumns: Column[] = [
  calendarWidgetColumns.calendarDate,
  ColumnRegistry.calendarEventDescription,
  calendarWidgetColumns.source,
];

// for pf, watchlist
export const multiInstrumentColumns = [
  calendarWidgetColumns.calendarDate,
  ColumnRegistry.calendarEventDescription,
  calendarWidgetColumns.tickerList,
  calendarWidgetColumns.source,
];
