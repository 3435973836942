import type { FormattingService } from '@vwd/ngx-i18n';
import type { ResourceService } from '@vwd/ngx-i18n/translate';
import type { GetRowIdParams } from 'ag-grid-community';
import type { UserSettingsService } from '../../services/user-settings.service';

// default "no value" replacement for the (grid-)formatters!
export const na = '-';

export type GetRowIdParamsExt = GetRowIdParams<unknown, unknown> & { data: { index?: string } };

export interface UntranslatedNoRowsTemplate {
  content: string;
  element?: string;
}

export interface TranslatedNoRowsTemplate {
  translatedContent: string;
  element?: string;
}

export const noRowsDefaults = Object.freeze({
  content: 'GRID.NO_ROWS',
  element: 'span',
});

export type GridContext = {
  translate: ResourceService;
  xlat: (key: string, args?: unknown) => string;
  format: FormattingService;
  userSettingsService: UserSettingsService;
  [key: string]: unknown;
};
