import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';

import { SymbolEventsComponent } from './symbol-events.component';

@NgModule({
  declarations: [SymbolEventsComponent],
  imports: [
    CommonModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [SymbolEventsComponent],
})

export class SymbolEventsModule { }
