import { ColumnRegistry } from '../../shared/grid/column-registry';
import type { Column } from '../../shared/grid/columns.model';

export const ExposureTopHoldingsColumns: Column[] = [
  { ...ColumnRegistry.countryFlag, resizable: false },
  { ...ColumnRegistry.distributionBar, minWidth: 70, resizable: false },
  { ...ColumnRegistry.exposureWeight, minWidth: 75, maxWidth: 75, resizable: false },
  { ...ColumnRegistry.listsPctChange, minWidth: 56, maxWidth: 70, resizable: false },
];

export const ExposureTopRegionsColumns: Column[] = [
  { ...ColumnRegistry.countryFlag, resizable: false },
  { ...ColumnRegistry.distributionBar, minWidth: 70, resizable: false },
  { ...ColumnRegistry.exposureWeight, minWidth: 60, maxWidth: 70, resizable: false },
];

export const ExposureTopSectorColumns: Column[] = [
  { ...ColumnRegistry.distributionBar, minWidth: 70, resizable: false },
  { ...ColumnRegistry.exposureWeight, minWidth: 60, maxWidth: 70, resizable: false },
];

export const ExposureTopCurrenciesColumns: Column[] = [
  { ...ColumnRegistry.countryFlag, resizable: false },
  { ...ColumnRegistry.distributionBar, minWidth: 70, resizable: false },
  { ...ColumnRegistry.exposureWeight, minWidth: 60, maxWidth: 70, resizable: false },
];
