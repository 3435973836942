

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { DirectivesModule } from '../../directives/directives.module';
import { SearchModule } from '../../search/search.module';
import { AutoCompleteComponent } from './auto-complete.component';

@NgModule({
  declarations: [AutoCompleteComponent],
  imports: [CommonModule, SearchModule, DirectivesModule, MatButtonModule, MatMenuModule, MatIconModule, OverlayModule, MatAutocompleteModule, MatInputModule, MatOptionModule, ReactiveFormsModule, FormsModule, ScrollingModule],
  exports: [AutoCompleteComponent],
})
export class AutoCompleteModule { }
