import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, type OnDestroy, QueryList, ViewChild, ViewChildren, inject } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject, merge } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take, tap, timeout } from 'rxjs/operators';

import { SdkRequestsService } from '../../services/sdk-requests.service';
import { TradingOrderEntryService } from '../../services/trading-order-entry.service';
import { TradingService } from '../../services/trading.service';
import type { OrderbookWidget, Widget } from '../../state-model/widget.model';
import type { Instrument } from '../../state-model/window.model';
import { instrumentsAreEqual } from '../../util/sdk';
import { ProgressService } from '../progress';
import type { Level, OrderbookUIData } from './orderbook-view.model';
import { OrderbookViewService } from './orderbook-view.service';
import { filterUndefined } from '../../util/rxjs';


@Component({
  selector: 'wt-orderbook-view',
  templateUrl: './orderbook-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderbookViewComponent implements OnDestroy {
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly sdkRequestsService = inject(SdkRequestsService);
  private readonly tradingService = inject(TradingService);
  private readonly tradingOrderEntryService = inject(TradingOrderEntryService);
  private readonly orderbookViewService = inject(OrderbookViewService);
  private readonly progress = inject(ProgressService, { optional: true });

  // Instrument can be either set by binding the widget or instrument-objects!
  @Input() set widget(widget: Widget) {
    this.widgetAction.next(widget);
  }
  @Input() set instrument(instrument: Instrument) {
    this.instrumentAction.next(instrument);
  }
  @Input({ required: true }) settings!: OrderbookWidget['settings'];
  // isCompact = false: wide: show spread and trade date, small: only show trade date
  // isCompact = true: wide: show spread and trade date, small: only show spread
  @Input() isCompact = false;

  @ViewChild('container') set container(container: ElementRef<HTMLElement>) {
    this.resizeObserver.disconnect();
    if (container) {
      this.resizeObserver.observe(container.nativeElement);
    }
  }

  @ViewChild('topBarLeftSide') topBarLeftSide!: ElementRef<HTMLElement>;
  @ViewChildren('topBarRightSide') topBarRightSide!: ElementRef<HTMLElement>;

  @ViewChildren('topBarRightSideElm') topBarRightSideElms!: QueryList<ElementRef>;


  private readonly instrumentAction = new ReplaySubject<Instrument | undefined>(1);
  private readonly widgetAction = new ReplaySubject<Widget | undefined>(1);
  readonly instrument$ = merge(
    this.instrumentAction,
    this.widgetAction.pipe(
      switchMap(widget => widget ? this.sdkRequestsService.windowInstrument$(widget) : EMPTY),
    )
  ).pipe(distinctUntilChanged(instrumentsAreEqual));

  readonly symbolInfo$ = this.instrument$.pipe(
    switchMap((instrument) => instrument ? this.orderbookViewService.symbolInfo$(instrument) : EMPTY),
  );
  readonly orderbook$: Observable<OrderbookUIData> = this.instrument$.pipe(
    switchMap((instrument) => instrument ? this.orderbookViewService.orderbook$(instrument, this.progress) : EMPTY),
    map((orderbook) => {
      if (orderbook?.levels) {
        orderbook.levels = orderbook.levels.filter((level) => !!level.askLevel || !!level.bidLevel);
      }
      return orderbook;
    })
  );
  readonly tradingConnected$ = this.tradingService.tradingConnected$;

  readonly levelsCompareFn = (index: number, level: Level): string => {
    const askLevel = level.askLevel?.level.toString() ?? '';
    const bidLevel = level.bidLevel?.level.toString() ?? '';
    return askLevel + bidLevel;
  };

  numberOfHiddenTopBarElementsAction = new BehaviorSubject<{ value: number }>({ value: 0 });
  numberOfHiddenTopBarElements$ = this.numberOfHiddenTopBarElementsAction.asObservable().pipe(distinctUntilChanged());

  hideBottomBarElement = false;

  private readonly resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      const value = this.getNumberOfHiddenTopBarElements(entry);
      this.numberOfHiddenTopBarElementsAction.next({ value });
      this.hideBottomBarElement = (entry.contentRect.width <= 320);
    }
  });


  private lastTotalWidth = 0;
  private lastHiddenElmWidth = 0; // we keep track of this to see if it will fit back in once its gone


  private getNumberOfHiddenTopBarElements(entry: ResizeObserverEntry): number {
    const totalWidth = entry.contentRect.width;
    const hiddenIndex = this.numberOfHiddenTopBarElementsAction.getValue()?.value;
    const topBarLeftSideWidth = this.topBarLeftSide?.nativeElement.offsetWidth;
    if (!topBarLeftSideWidth) {
      return hiddenIndex;
    }
    const margin = 15;
    const availableWidth = totalWidth - (topBarLeftSideWidth + margin);
    const rightBarChildren = this.topBarRightSideElms?.toArray();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return
    const requestedWidth = rightBarChildren.reduce((acc, elm) => acc + (elm?.nativeElement?.offsetWidth ?? 0), 0);

    if (!this.lastTotalWidth || this.lastTotalWidth > totalWidth) { // we are shrinking, only check to increase hiddenIndex, otherwise flicker
      if (requestedWidth + margin > availableWidth) {
        // hide 1 or 2 right away, depending on how fast the available space shrunk
        const withDiff = requestedWidth + margin - availableWidth;
        const hideDiff = withDiff > rightBarChildren[0].nativeElement.offsetWidth ? 2 : 1;
        this.lastHiddenElmWidth = rightBarChildren[hiddenIndex]?.nativeElement?.offsetWidth as number;
        return hiddenIndex < rightBarChildren.length ? hiddenIndex + hideDiff : hiddenIndex;
      }
    }
    if (this.lastTotalWidth < totalWidth) { // we are growing, only check to decrease hiddenIndex, otherwise flicker
      if ((requestedWidth + margin + this.lastHiddenElmWidth) <= availableWidth) {
        return hiddenIndex > 0 ? hiddenIndex - 1 : hiddenIndex;
      }
    }
    this.lastTotalWidth = totalWidth;
    return hiddenIndex;
  }


  ngOnDestroy(): void {
    this.resizeObserver?.disconnect();
    this.instrumentAction?.complete();
    this.widgetAction?.complete();
  }

  openOrderEntry(initialPrice: number | undefined) {
    this.instrument$.pipe(
      filterUndefined(),
      // force close this one-shoot pipe after 2s to prevent corner-case chances for memory leak!
      timeout(2000),
      take(1),
      tap((instrument: Instrument) => {
        this.tradingOrderEntryService.openOrderEntry({ instrument, initialPrice });
      })
    ).subscribe();
  }

}
