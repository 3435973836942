import { type AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { BehaviorSubject } from 'rxjs';

// combo-box designed to work with reactive forms
@Component({
  selector: 'wt-combo-box',
  template: `
  <div class="wt-combo-box" *ngIf="showClear$ | async as showClear">
  <input type="text"
      (input)="onInput($event)"
      (keydown)="onKeydown($event)"
      focusOnShow
      [placeholder]="placeholder"
      [matAutocomplete]="auto"
      #matAutocompleteInput
      [ngClass]="!showClear.show ? 'wt-combo-box-input' : 'wt-combo-box-input--short wt-combo-box-input'"

      >
    <mat-icon class="wt-combo-box-icon" fontIcon="expand_more" (click)="onToggle()"></ mat-icon>
    <mat-icon [hidden]="!showClear.show" class="wt-combo-box-icon wt-combo-box-icon-clear" fontIcon="close" (click)="onClear()"></ mat-icon>

    <mat-autocomplete #auto="matAutocomplete">
        <mat-option  class="wt-combo-box__option wt-truncate"
        *ngFor="let item of filteredOptions"
          [value]="item" (click)="onItemSelected(item)">
          <div class="wt-flex-row">
            <div class="wt-truncate">
            {{item}}
            </div>
          </div>
        </mat-option>
    </mat-autocomplete>
</div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboBoxComponent),
      multi: true
    }
  ]
})
export class ComboBoxComponent implements AfterViewInit, ControlValueAccessor {


  @ViewChild('matAutocompleteInput') matAutocompleteInput!: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger!: MatAutocompleteTrigger;

  @Input() placeholder = '';
  @Input() options: string[] = [];
  @Input() value = '';

  @Output() itemSelected = new EventEmitter<string>();

  private readonly showClear = new BehaviorSubject<{ show: boolean }>({ show: false });
  showClear$ = this.showClear.asObservable();

  filteredOptions: string[] = [];


  ngAfterViewInit(): void {
    // set initial value after matAutocompleteInput is available)
    if (this.value && this.matAutocompleteInput?.nativeElement) {
      this.showClear.next({ show: !!this.value.length });
      this.matAutocompleteInput.nativeElement.value = this.value;
    }
  }

  onItemSelected(item: string): void {
    this.showClear.next({ show: true });
    this.value = item;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.onChange(this.value);
    this.itemSelected.emit(item);
  }

  onInput(event: Event): void {
    const inputValue = this.matAutocompleteInput.nativeElement.value;
    if (inputValue.length === 0) {
      setTimeout(() => {
        this.clearValues();
        this.autocompleteTrigger.closePanel();
      }, 0); // trigger angular change detection, otherwise an annoying delay when deleting the last char
    }
    this.showClear.next({ show: !!inputValue.length });
    this.filteredOptions = this.options.filter((option) => option?.toLowerCase().includes(inputValue.toLowerCase())) || [];
  }

  onKeydown(event: KeyboardEvent): void {
    if (['Enter', 'Tab'].includes(event.key)) {
      event.preventDefault();
      event.stopPropagation();
      this.matAutocompleteInput.nativeElement.blur();
    }
  }

  onToggle(): void {
    const inputValue = this.matAutocompleteInput.nativeElement.value;
    if (inputValue.length === 0) {
      this.filteredOptions = this.options;
    } else {
      this.filteredOptions = this.options.filter((option) => option.toLowerCase().includes(inputValue.toLowerCase())) || [];
    }

    if (this.autocompleteTrigger.panelOpen) {
      this.autocompleteTrigger.closePanel();
      return;
    }
    setTimeout(() => {
      if (!this.autocompleteTrigger.panelOpen) {
        this.autocompleteTrigger.openPanel();
      }
    }, 0);
  }


  setValue(): void {
    if (this.filteredOptions?.length) {
      this.onItemSelected(this.filteredOptions[0]);
      this.value = this.filteredOptions[0];
      this.writeValue(this.value);
      this.autocompleteTrigger.closePanel();
      return;
    }
    this.clearValues();
    this.autocompleteTrigger.closePanel();
  }

  clearValues(): void {
    this.value = '';
    this.writeValue('');
    this.filteredOptions = [];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.onChange('');
  }


  onClear() {
    this.showClear.next({ show: false });
    this.clearValues();
  }

  // ControlValueAccessor methods - needed for the control to work with autoupdating Angular rective forms

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: any = () => { };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onTouch: any = () => { };


  writeValue(value: string): void {
    this.value = value;
    if (this.matAutocompleteInput?.nativeElement && value !== this.matAutocompleteInput.nativeElement.value) {
      this.matAutocompleteInput.nativeElement.value = value;
    }
  }

  registerOnChange(fn: unknown): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: unknown): void {
    this.onTouch = fn;
  }

}
