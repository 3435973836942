import { NgZone, inject } from '@angular/core';
import { type Observable, isObservable, of, shareReplay, tap } from 'rxjs';
import { isPromiseLike } from './types';

// browsers should support this
declare class WeakRef<T extends object> {
  constructor(value: T);
  deref(): T | undefined;
}

/** A wrapped, caching function. */
export type CacheFn<TArgs extends unknown[], T> = ((...args: TArgs) => T) & {
  get cache(): CacheFnView<TArgs, T>;
  continueWith<U>(fn: (value: T, args: TArgs) => U): (...args: TArgs) => U;
  destroy(): void;
};

/** A {@link CacheFn} cache view */
export interface CacheFnView<TArgs extends unknown[], TValue> {
  [Symbol.iterator](): IterableIterator<[TArgs, TValue, { expires: number }]>;
  readonly size: number;
  entries(): IterableIterator<[TArgs, TValue, { expires: number }]>,
  keys(): IterableIterator<TArgs>;
  values(): IterableIterator<TValue>;
  get(...args: TArgs): TValue | undefined;
  has(...args: TArgs): boolean;
}

/**
 * Wraps a function to cache its results based on its input parameters.
 *
 * Can only be called inside an injection context (e.g., service/component
 * constructor or field initializers).
 *
 * @param duration The caching duration.
 * @param factory The function to call for non-cached results.
 * @returns A function with the same signature as {@link factory} that
 * performs caching, with the additional {@link CacheFn} API surface.
 */
export function cached<TArgs extends unknown[], T>(duration: number, factory: (...args: TArgs) => T): CacheFn<TArgs, T> {
  const cache = new CacheMap<TArgs, T>();

  const fn = (...args: TArgs) => {
    const key = keyFor(args);
    const now = Date.now();
    const [expires, cached] = cache.get(key) ?? (NO_ENTRY as [number, T]);

    if (cached && expires != undefined && expires > now) {
      return cached;
    }

    let fetch = factory(...args);

    if (isObservable(fetch)) {
      // Observables are auto-removed on error, and append shareReplay(1)
      fetch = wrapObservable(fetch, cache, key);
    } else if (isPromiseLike(fetch)) {
      // Promises are auto-removed on rejection
      fetch = wrapPromise(fetch, cache, key);
    }

    cache.set(key, [now + duration, fetch, args]);
    return fetch;
  };

  // Add the extra CacheFn API.
  Object.defineProperties(fn, {
    cache: { get: () => createCacheView?.(cache) },
    continueWith: {
      value: function (callbackfn: (arg: T, args: TArgs) => unknown) {
        return (...args: TArgs) => {
          const result = fn(...args);
          return callbackfn(result, args);
        };
      },
      writable: false
    },
    destroy: { value: () => cache.destroy(), writable: false },
  });

  return fn as CacheFn<TArgs, T>;
}

const DESTROY_NOOP = () => { };
const NO_ENTRY = [] as unknown as [number, unknown];
const EMPTY_ARRAY_SYMBOL = Symbol('[]');
const OBSERVED_SYMBOL = Symbol('observed');

class CacheMap<TArgs extends unknown[], T> extends Map<unknown, [number, T, TArgs]> {
  private static readonly activeCaches: Array<WeakRef<CacheMap<unknown[], unknown>>> = [];
  private static readonly purgeInterval = 60_000; // purge stale entries every minute
  private static purgeTimerID: ReturnType<typeof setInterval> | undefined;

  private activated = false;
  private readonly zone = inject(NgZone);

  set(key: unknown, value: [number, T, TArgs]) {
    if (!this.activated) {
      CacheMap.activeCaches.push(new WeakRef(this));
      this.activated = true;
      // first entry in activeCaches sets up timer
      if (CacheMap.activeCaches.length === 1) {
        CacheMap.purgeTimerID = this.zone.runOutsideAngular(() => setInterval(() => this.purgeCacheEntries(), CacheMap.purgeInterval));
      }
    }
    return super.set(key, value);
  }

  get destroyed(): boolean { return this.destroy === DESTROY_NOOP; }

  destroy(): void {
    this.destroy = DESTROY_NOOP;
    this.activated = false;
    this.clear();
    this.set = this.get = this.clear = this.delete = () => { throw new Error('Cannot use cache after it has been destroyed'); };

    const activeCacheList = CacheMap.activeCaches;

    for (let i = 0; i < activeCacheList.length; i++) {
      const ref = activeCacheList[i];
      if (ref.deref() === this) {
        activeCacheList.splice(i, 1);
        if (activeCacheList.length === 0) {
          clearInterval(CacheMap.purgeTimerID);
          CacheMap.purgeTimerID = undefined;
        }
        return;
      }
    }
  }

  private purgeCacheEntries(): void {
    const now = Date.now();
    const activeCacheList = CacheMap.activeCaches;
    for (let i = activeCacheList.length - 1; i >= 0; i--) {
      const ref = activeCacheList[i];
      const purgeMap = ref.deref();
      if (!purgeMap || purgeMap.destroyed) {
        activeCacheList.splice(i, 1);
      } else {
        for (const [key, [expires]] of purgeMap) {
          if (expires < now) {
            purgeMap.delete(key);
          }
        }
      }
    }
    if (activeCacheList.length === 0) {
      clearInterval(CacheMap.purgeTimerID);
      CacheMap.purgeTimerID = undefined;
    }
  }
}

function wrapObservable<TArgs extends unknown[], T>(fetch: T & Observable<unknown>, cache: CacheMap<TArgs, T>, key: unknown): T {
  // assign to fetch, so we can do a cache check to remove the extended observable
  fetch = fetch.pipe(
    tap({
      next: (value) => {
        if (!cache.destroyed) {
          // Make sure we store the emitted value, so we can be sure the
          // observable is not completed (and never emits again), or is
          // re-executed.
          const entry = cache.get(key);
          if (entry?.[1] === fetch) {
            const replaced = of(value) as T;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (replaced as any)[OBSERVED_SYMBOL] = value;
            entry[1] = replaced;
          }
        }
      },
      error: () => {
        if (!cache.destroyed) {
          const [, cached] = cache.get(key) ?? (NO_ENTRY as [number, T]);
          if (cached === fetch) {
            cache.delete(key);
          }
        }
      }
    }),
    shareReplay(1)
  ) as typeof fetch;
  return fetch;
}

function wrapPromise<TArgs extends unknown[], T>(fetch: T & PromiseLike<unknown>, cache: CacheMap<TArgs, T>, key: unknown): T {
  fetch.then(
    value => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (fetch as any)[OBSERVED_SYMBOL] = value;
    },
    () => {
      if (!cache.destroyed) {
        const [, cached] = cache.get(key) ?? (NO_ENTRY as [number, T]);
        if (cached === fetch) {
          cache.delete(key);
        }
      }
    });
  return fetch;
}

function keyFor(array: ReadonlyArray<unknown>): unknown {
  if (array.length === 0) {
    return EMPTY_ARRAY_SYMBOL;
  } else if (array.length > 1) {
    return '\x1ba:' + array.map(primitiveKeyFor).join('\x1f');
  } else {
    return primitiveKeyFor(array[0]);
  }
}

function primitiveKeyFor(value: unknown): unknown {
  switch (typeof value) {
    case 'object':
      if (!value) {
        return value;
      } else {
        return '\x1bo:' + JSON.stringify(value); // does not handle undefined, reordered keys, etc.
      }
    case 'string':
      if (value.startsWith('\x1b')) {
        return '\x1bs:' + value;
      }
      return value;
    case 'function':
      throw new TypeError('Cannot use function as a cache key.');
    default:
      return value;
  }
}

function createCacheView<TArgs extends unknown[], T>(map: CacheMap<TArgs, T>): CacheFnView<TArgs, T> {
  return Object.freeze({
    [Symbol.iterator](): IterableIterator<[TArgs, T, { expires: number, observed?: unknown }]> {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
      return this.entries();
    },
    get size(): number {
      return map.size;
    },
    *entries(): IterableIterator<[TArgs, T, { expires: number, observed?: unknown }]> {
      for (const [, [expires, value, args]] of map.entries()) {
        const extra = value && typeof value === 'object' && OBSERVED_SYMBOL in value ?
          { expires, observed: value[OBSERVED_SYMBOL] } : { expires };
        yield [args, value, extra];
      }
    },
    *keys(): IterableIterator<TArgs> {
      for (const [, [, , args]] of map.entries()) {
        yield args;
      }
    },
    *values(): IterableIterator<T> {
      for (const [, [, value]] of map.entries()) {
        yield value;
      }
    },
    has(...args: TArgs) { return map.has(keyFor(args)); },
    get(...args: TArgs) { return map.get(keyFor(args))?.[1]; },
  });
}
