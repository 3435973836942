import { type CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import type { SelectableColumn } from '../grid/columns.model';

@Component({
  selector: 'wt-column-picked-list',
  templateUrl: './column-picked-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnPickedListComponent {
  @Input({ required: true }) columns!: Array<SelectableColumn>;

  // currently only order can be changed, in the future this might involve filter settings
  @Output() columnsChanged = new EventEmitter<Array<SelectableColumn>>();

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
    this.columnsChanged.emit(this.columns);
  }

  onDelete(column: SelectableColumn): void {
    this.columnsChanged.emit(this.columns.filter((col) => col.colId !== column.colId));
  }
}
