import { Pipe, type PipeTransform } from '@angular/core';

import type { ColDef } from 'ag-grid-community';
import { isColumn } from '../shared/grid/columns.model';

@Pipe({ name: 'longHeaderName' })
export class LongHeaderNamePipe implements PipeTransform {
  transform(col: ColDef): string {
    let name: string | undefined;
    if (isColumn(col)) {
      name = col.longHeaderName;
    }
    name ??= col.headerName ?? '';
    return name;
  }
}
