import { ChangeDetectionStrategy, Component, type OnDestroy, inject } from '@angular/core';

import { FormBuilder, FormGroup } from '@angular/forms';
import { InfrontSDK } from '@infront/sdk';
import { ResourceService } from '@vwd/ngx-i18n/translate';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { map, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { UserSettingsService } from '../../services/user-settings.service';
import { FundScreenerService } from './fund-screener.service';
import { type FeedInfoShort, type FieldValuesObject, type FundFormGroup, type FundSearchState, PerformancePeriodsMap } from './screener.model';

interface Vm {
  searchState: FundSearchState;
  settingsState: FundSearchState;
  feeds: FeedInfoShort[];
  segments: string[];
  autoCompleteSearchResult: { [key: string]: string[] };
  regions: string[];
  fundTypes: string[];
  fundStyles: string[];
  isInfrontFeed: boolean;
}

@Component({
  selector: 'wt-fund-screener-inputs',
  templateUrl: './fund-screener-inputs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundScreenerInputsComponent implements OnDestroy {
  private readonly fundScreenerService = inject(FundScreenerService);
  private readonly fb = inject(FormBuilder);
  private readonly userSettingsService = inject(UserSettingsService);
  private readonly translate = inject(ResourceService);

  readonly InfrontSDK = InfrontSDK; // for template;
  categories = ['Morningstar Funds', 'Infront Mututal Funds'];
  performancePeriods: string[] = Object.keys(PerformancePeriodsMap);

  selectedRatings = [];
  selectedRiskLevels = [];

  formGroup!: FormGroup;

  readonly autoCompleteSearchResultAction = new BehaviorSubject<{ [key: string]: string[] }>({});

  private readonly ngUnsubscribe = new Subject<void>();

  private defaultSource: FeedInfoShort | undefined;

  feedCompareFn = (o1: FeedInfoShort, o2: FeedInfoShort) => o1?.feed === o2?.feed;


  vm$ = combineLatest([
    this.fundScreenerService.searchState$,
    this.userSettingsService.getValue$('fundScreenerSearchState').pipe(
      take(1),
    ),
    this.fundScreenerService.feeds$.pipe(map(feeds => feeds.map(f => ({ description: f.description, feed: f.feed, feedCode: f.feedCode })))),
    this.fundScreenerService.sourceByField$(InfrontSDK.SymbolField.PrimarySegment).pipe(
      startWith([])),
    this.autoCompleteSearchResultAction.asObservable(),
    this.fundScreenerService.sourceByField$(InfrontSDK.SymbolField.FundType).pipe(
      startWith([])),
    this.fundScreenerService.sourceByField$(InfrontSDK.SymbolField.FundRegionExposure).pipe(
      startWith([])),
    this.fundScreenerService.fundStyles$,
    this.fundScreenerService.isInfrontFeed$
  ]).pipe(
    map(([searchState, settingsState, feeds, segments, autoCompleteSearchResult, fundTypes, regions, fundStyles, isInfrontFeed]) => ({ searchState, settingsState, feeds, segments, autoCompleteSearchResult, fundTypes, regions, fundStyles, isInfrontFeed })),
    tap(vm => {
      if (!this.formGroup) {
        const defaultSource = this.getDefaultSource(vm.feeds);
        this.setFormGroup(vm, defaultSource);
        this.setInitialState(vm, defaultSource);
        this.defaultSource = defaultSource;
      }
    })
  );

  private setFormGroup(vm: Vm, defaultSource: FeedInfoShort) {

    const formGroup = vm.settingsState ? this.formValuesFromSettingsState(vm) : this.formGroupDefaults(defaultSource);
    this.formGroup = this.fb.group(formGroup);

    // reactive form change detection
    this.formGroup.valueChanges.pipe(tap((formValues: FundFormGroup) => {
      const searchState = this.fieldsFromFormValues(formValues);
      this.fundScreenerService.updateSearchFields(searchState);
    }), takeUntil(this.ngUnsubscribe)).subscribe();
  }

  private setInitialState(vm: Vm, defaultSource: FeedInfoShort) {
    const defaultState = { fields: { Feed: [defaultSource.feed] }, selectedColumnCategoryIndex: 0 };
    this.fundScreenerService.updateSearchState(vm.settingsState || defaultState);
  }

  private getDefaultSource(feeds: FeedInfoShort[]): FeedInfoShort {
    const defaultFeedPriority = ['MOFU', 'IFMF'];
    const feedInfo = feeds?.find(feed => defaultFeedPriority.includes(feed.feedCode)) || feeds[0];
    return feedInfo;
  }

  private formGroupDefaults(defaultSource: FeedInfoShort) {
    return {
      // first row
      fullName: [''],
      ISIN: [''],
      feed: defaultSource,
      // second row
      fundType: [''],
      regionExposure: [[]],
      primarySegment: [''],
      portfolioManager: [''],
      issuer: [''],
      // third row
      performancePeriod: this.performancePeriods[0],
      performanceMin: [undefined],
      performanceMax: [undefined],
      riskLevel: [[]],
      topHolding: [''],
      fundStyle: [''],
      fundStarRating: [[]],
    };
  }

  fieldsFromFormValues(formValues: FundFormGroup): FieldValuesObject {
    const searchState = {
      // first row
      SearchFreeText: formValues.fullName,
      ISIN: formValues.ISIN,
      Feed: formValues.feed?.feed,
      // second row
      FundType: formValues.fundType,
      FundRegionExposure: formValues.regionExposure,
      PrimarySegment: formValues.primarySegment,
      FundPortfolioManager: formValues.portfolioManager,
      Issuer: formValues.issuer,
      // third row
      SearchRange: PerformancePeriodsMap[formValues.performancePeriod ?? this.performancePeriods[0]],
      SearchRangeLowerLimit: formValues.performanceMin,
      SearchRangeUpperLimit: formValues.performanceMax,
      FundTopHolding: formValues.topHolding,
      FundInvestmentStyle: formValues.fundStyle,
      FundRiskLevel: formValues.riskLevel,
      FundStarRating: formValues.fundStarRating,
    };
    return searchState;
  }

  formValuesFromSettingsState(vm: Vm): FundFormGroup {
    const fields = vm.settingsState.fields;

    const formValues = {
      // first row
      fullName: [fields?.SearchFreeText ?? ''],
      ISIN: [fields?.ISIN ?? ''],
      feed: vm.feeds.find(feed => feed.feed === fields?.Feed),
      // second row
      fundType: [fields?.FundType ?? ''],
      regionExposure: [fields?.FundRegionExposure ?? []],
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      primarySegment: [fields?.PrimarySegment ?? ''],
      portfolioManager: [fields?.FundPortfolioManager ?? ''],
      issuer: [fields?.Issuer ?? ''],
      // third row
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      performancePeriod: Object.entries(PerformancePeriodsMap).find(([_, searchRange]) => searchRange === fields?.SearchRange as any)?.[0] ?? this.performancePeriods[0],
      performanceMin: [fields?.SearchRangeLowerLimit ?? undefined],
      performanceMax: [fields?.SearchRangeUpperLimit ?? undefined],
      riskLevel: [fields?.FundRiskLevel ?? []],
      topHolding: [fields?.FundTopHolding ?? ''],
      fundStyle: [fields?.FundInvestmentStyle ?? ''],
      fundStarRating: [fields?.FundStarRating ?? []],
    };
    return formValues as unknown as FundFormGroup; // todo: fix type
  }

  // note: multiple fields can be used but more than one is not performing well in testing
  onAutoCompleteSearch(searchString: string, fields: (InfrontSDK.SearchField | InfrontSDK.BasicField)[]) {
    const resultFields = fields.length > 1 ? fields : undefined;
    this.fundScreenerService.freeTextSearch$(searchString, fields, resultFields).pipe(
      take(1),
      tap(result => {
        this.autoCompleteSearchResultAction.next({ [fields[0] as string]: result });
      }),
      takeUntil(this.ngUnsubscribe)).subscribe();
  }

  onResetAll() {
    this.formGroup.patchValue({
      // first row
      fullName: '',
      ISIN: '',
      feed: this.defaultSource,
      // second row
      fundType: '',
      regionExposure: [],
      primarySegment: '',
      portfolioManager: '',
      issuer: '',
      // third row
      performancePeriod: this.performancePeriods[0],
      performanceMin: undefined,
      performanceMax: undefined,
      riskLevel: [],
      topHolding: '',
      fundStyle: '',
      fundStarRating: [],
    });
    this.fundScreenerService.updateSearchFields({ Feed: this.defaultSource?.feed });
  }

  onClearField(field: string, event: MouseEvent) {
    const arrayFields = ['regionExposure', 'riskLevel', 'fundStarRating'];
    this.formGroup?.get(field)?.patchValue(arrayFields.includes(field) ? [] : '');
    event.stopPropagation();
  }

  ngOnDestroy(): void {
    this.autoCompleteSearchResultAction.complete();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
