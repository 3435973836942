import { ChangeDetectionStrategy, Component, Input, type OnDestroy, type OnInit, inject } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { Observable, Subject, merge, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { SdkService } from '../../../services/sdk.service';
import { StoreService } from '../../../services/store.service';
import type { Dashboard } from '../../../state-model/dashboard.model';
import type { Widget } from '../../../state-model/widget.model';
import type { MarketWindow } from '../../../state-model/window.model';
import type { MarketDataType } from '../../../typings/models/marketData';

@Component({
  selector: 'wt-market-header',
  templateUrl: './market-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketHeaderComponent implements OnInit, OnDestroy {
  private readonly storeService = inject(StoreService);
  private readonly sdkService = inject(SdkService);

  @Input({ required: true }) dashboard!: Dashboard;
  @Input({ required: true }) window!: MarketWindow;
  @Input({ required: true }) widget!: Widget;
  title$!: Observable<string>;
  private readonly feed = new Subject<number>();

  ngOnInit(): void {
    this.title$ = merge(this.feed, of(this.window.settings?.feed)).pipe(
      switchMap((feed) =>
        this.sdkService.getArray$(InfrontSDK.feedInfo, {
          infoType: InfrontSDK.FeedInfoType.MetaData,
          feed,
        })
      ),
      map((response) => (response[0] ? (response[0] as InfrontSDK.FeedInfo).description + ' - Market' : ''))
    );
  }

  ngOnDestroy(): void {
    this.feed.complete();
  }

  onSourceSelected(source: MarketDataType): void {
    if (!source.feed || (typeof source.feed !== 'number')) {
      return;
    }
    const settings = { ...this.window.settings, feed: source.feed };
    this.storeService.updateWindow(this.window, { settings });
    this.feed.next(source.feed);
  }
}
