import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { filter, map, take } from 'rxjs/operators';

import { InstrumentDashboardService } from '../dashboard/instrument-dashboard.service';
import { NewDashboardDialogComponent } from '../dashboard/new-dashboard-dialog/new-dashboard-dialog.component';
import { StoreService } from '../services/store.service';
import type { Instrument } from '../state-model/window.model';
import { newUniqueName } from '../util/utils';
import { translator } from '../util/locale';

@Directive({
  selector: '[addDashboard]',
})
export class AddDashboardDirective {
  private readonly xlat = translator();

  @Input() addDashboardInstrument?: Instrument;

  @Input() addDashboardPreCallback?: (() => void)[] | (() => void);
  @Input() addDashboardProceedCallback?: (() => void)[] | (() => void);

  @Output() addDashboardOnPreCallback = new EventEmitter<void>();
  @Output() addDashboardOnProceedCallback = new EventEmitter<void>();

  constructor(
    private instrumentDashboardService: InstrumentDashboardService,
    private storeService: StoreService,
    private dialog: MatDialog
  ) { }

  @HostListener('click') onClick(): void {
    if (this.addDashboardInstrument) {
      if (Array.isArray(this.addDashboardPreCallback)) {
        this.addDashboardPreCallback.forEach((preCb) => preCb?.());
      } else {
        this.addDashboardPreCallback?.();
      }
      this.addDashboardOnPreCallback.emit();
      this.instrumentDashboardService.addInstrumentDashboardAsync(this.addDashboardInstrument);
      if (Array.isArray(this.addDashboardProceedCallback)) {
        this.addDashboardProceedCallback.forEach((prcdCb) => prcdCb?.());
      } else {
        this.addDashboardProceedCallback?.();
      }
      this.addDashboardOnProceedCallback.emit();
    } else {
      this.storeService.dashboards$
        .pipe(
          map((dashboards) => dashboards.map((d) => d.name)),
          take(1)
        )
        .subscribe((nameList) => {
          const name = newUniqueName(this.xlat('DASHBOARD.DEFAULT_NAME'), nameList);
          this.dialog
            .open(NewDashboardDialogComponent, {
              width: '300px',
              data: { name },
            })
            .afterClosed()
            .pipe(filter((result: string) => !!result))
            .subscribe((result: string) => {
              this.storeService.addDashboard(result);
            });
        });
    }
  }
}
