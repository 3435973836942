import { ChangeDetectionStrategy, Component, Input, type OnChanges, type OnInit, type SimpleChanges } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { Observable } from 'rxjs';

import { DashboardService } from '../../../dashboard/dashboard.service';
import { CompactSearchContainerService } from '../../../search/compact-search/compact-search-container/compact-search-container.service';
import { StoreService } from '../../../services/store.service';
import { TradingService } from '../../../services/trading.service';
import { WatchlistService } from '../../../services/watchlist.service';
import type { Dashboard } from '../../../state-model/dashboard.model';
import type { Widget } from '../../../state-model/widget.model';
import { type Instrument, isInstrumentSettings, type NewsType, type NewsWindow } from '../../../state-model/window.model';
import type { MarketDataType } from '../../../typings/models/marketData';
import { instrumentsAreEqual } from '../../../util/sdk';
import { InstrumentHeaderService } from '../instrument-header/instrument-header.service';


@Component({
  selector: 'wt-news-header',
  templateUrl: './news-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsHeaderComponent implements OnInit, OnChanges {
  @Input({ required: true }) dashboard!: Dashboard;
  @Input({ required: true }) window!: NewsWindow;
  @Input({ required: true }) widget!: Widget;

  activeInstrument: Instrument | undefined;
  symbolInfo$!: Observable<{
    feedExchange: string;
    preDisplayTicker: string;
    feedIsHidden: boolean;
  }>;

  selectedWatchlist$!: Observable<InfrontSDK.Watchlist | undefined>;
  selectedPortfolio$ = this.tradingService.selectedPortfolio$;

  constructor(
    private readonly storeService: StoreService,
    private readonly instrumentHeaderService: InstrumentHeaderService,
    private readonly compactSearchContainerService: CompactSearchContainerService,
    private readonly dashboardService: DashboardService,
    private readonly watchlistService: WatchlistService,
    private readonly tradingService: TradingService,
  ) { }

  ngOnInit(): void {
    this.symbolInfo$ = this.instrumentHeaderService.symbolInfo$(this.widget);
    this.setActiveInstrument(this.window.settings.newsType);
    this.selectedWatchlist$ = this.watchlistService.selectedWatchlistInWindow$(this.watchlistService.commonWatchlistWindowByWindow$(this.window));
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.setActiveInstrument(this.window.settings.newsType);
  }

  setActiveInstrument(newsType: NewsType): void {
    if (newsType !== 'Instrument') {
      if (this.activeInstrument) {
        this.activeInstrument = undefined;
      }
      return;
    }

    if (!instrumentsAreEqual(this.activeInstrument, this.window.settings.instrument)) {
      this.activeInstrument = this.window.settings.instrument;
      return; // NOSONAR accept redundant jump to emphasis that we must leave method here
    }
  }

  onSearchClick(): void {
    this.compactSearchContainerService.onSearchClick(this.window);
  }

  onSpawnMarketWindow(event: MouseEvent): void {
    event.stopPropagation();
    const feed = isInstrumentSettings(this.window.settings) ? this.window.settings.instrument.feed : undefined;
    if (feed) {
      this.dashboardService.addWindow('MarketWindow', { feed });
    }
  }

  onMultiSourceSelected(sources: MarketDataType[]): void {
    const feeds = sources.reduce<number[]>((a, s) => {
      if (s.feed) a.push(s.feed);
      return a;
    }, []);

    this.storeService.updateWindow(this.window, { settings: { ...this.window.settings, newsType: 'Country', feeds: feeds } });
  }

  onWatchlistSelected(selectedWatchlist: InfrontSDK.Watchlist | undefined): void {
    this.watchlistService.onSelectedWatchlistChange({ window: this.window }, selectedWatchlist);
  }
}
