import { type DashboardRef, NoUserPersistable, type UserPersistables, type UserPersistablesRegistry, type WidgetDataModel, type WidgetUserDataModel } from '@infront/ngx-dashboards-fx';
import { structuresAreEqual } from '../util/equality';
import { isEmptyObject, omit, omitUndefinedProperties, pick } from '../util/object';
import { DashboardType as WTDashboardType } from './dashboard.model';
import type { Grid } from './grid.model';
import type { BiggestMoversWidget, CalendarWidget, ChainsWidget, HistoryWidget, ListsWidget, NewsWidget, RankingWidget, UserlistWidget, Widget, WidgetName } from './widget.model';
import type { UserCreatableWindowName } from './window.defaults';
import { type CalendarWindow, type ChainsWindow, type DashboardWindow, type InstrumentWindow, type MarketWindow, type UserlistWindow, isInstrumentSettings, isWindowSettings } from './window.model';

/** A persistable that only handles widget grids */
const OnlyGridsUserPersistable: UserPersistables = wtWindowPersistable({ extract: window => undefined }); // grids come for free!

/** A persistable that only handles settings.instrument, but only for non-instrument dashboards. */
const InstrumentUserPersistable: UserPersistables = wtWindowPersistable({
  extract: (window: DashboardWindow, dashboard: DashboardRef) => {
    // Special-case instrument dashboards: do not persist instrument
    if (dashboard.model.attributes.wtDashboardType !== WTDashboardType.instrument) {
      // Only persist settings if it contains an instrument
      if (isWindowSettings(window) && isInstrumentSettings(window.settings)) {
        return { settings: { instrument: window.settings.instrument } };
      }
    }
    return undefined;
  }
}); // grids come for free!

/** Map of all windows and their user persistable definitions */
export const WindowUserPersistablesMap: UserPersistablesRegistry & Readonly<Record<UserCreatableWindowName, UserPersistables>> = {
  MarketOverviewWindow: OnlyGridsUserPersistable,
  MarketWindow: wtWindowPersistable({
    extract: (window: MarketWindow) => ({ selectedWidgetName: window.selectedWidgetName, settings: pick(window.settings, 'feed') }),
    widgets: {
      Lists: {
        extract: (widget: ListsWidget) => ({ settings: pick(widget.settings, 'selectedSourceName') }),
      },
      Ranking: {
        extract: (widget: RankingWidget) => ({ settings: pick(widget.settings, 'selectedSourceName', 'selectedTimePeriod') }),
      },
      BiggestMovers: {
        extract: (widget: BiggestMoversWidget) => ({ settings: pick(widget.settings, 'selectedSourceName', 'selectedTimePeriod') }),
      },
    }
  }),
  UserlistWindow: wtWindowPersistable({
    extract: (window: UserlistWindow) => ({ selectedWidgetName: window.selectedWidgetName }),
    widgets: {
      Userlist: {
        extract: (widget: UserlistWidget) => ({ settings: pick(widget.settings, 'instruments', 'name') }),
      },
    },
  }),
  WatchlistWindow: OnlyGridsUserPersistable,
  OrderbookWindow: InstrumentUserPersistable,
  InstrumentWindow: wtWindowPersistable({
    extract: (window: InstrumentWindow) => ({ selectedWidgetName: window.selectedWidgetName, settings: pick(window.settings, 'instrument') }),
    widgets: {
      History: {
        extract: (widget: HistoryWidget) => ({ settings: pick(widget.settings, 'selectedGrid') }),
      },
      News: {
        extract: (widget: NewsWidget) => ({ settings: pick(widget.settings, 'showFlashNews', 'showNews', 'showResearchNews') }),
      },
    },
  }),
  NewsWindow: NoUserPersistable,
  ChartWindow: InstrumentUserPersistable,
  ChartMiniWindow: InstrumentUserPersistable,
  FocusWindow: InstrumentUserPersistable,
  FocusMiniWindow: InstrumentUserPersistable,
  AlertLogWindow: NoUserPersistable,
  // CalendarWindow: NoUserPersistable,
  CalendarWindow: wtWindowPersistable({
    extract: (window: CalendarWindow) => ({ selectedWidgetName: window.selectedWidgetName }),
    widgets: {
      Calendar: {
        extract: (widget: CalendarWidget) => ({ settings: pick(widget.settings, 'selectedPeriod', 'deselectedFeeds', 'headlineFilter') })
      }
    }
  }),
  PortfolioPositionsWindow: OnlyGridsUserPersistable,
  PortfolioOrdersWindow: OnlyGridsUserPersistable,
  PortfolioOrderSummaryWindow: NoUserPersistable,
  PortfolioTradesWindow: NoUserPersistable,
  InstrumentHeaderWindow: NoUserPersistable,
  PortfolioHeaderWindow: NoUserPersistable,
  PositionsSummaryWindow: NoUserPersistable,
  PositionsEventsWindow: NoUserPersistable,
  PositionsExposureWindow: NoUserPersistable,
  NetTradesWindow: NoUserPersistable,
  FundScreenerWindow: NoUserPersistable,
  CompanyDataWindow: InstrumentUserPersistable,
  CompanyInformationWindow: InstrumentUserPersistable,
  TopShareholdersWindow: InstrumentUserPersistable,
  NotFoundWindow: NoUserPersistable,
  ChainsWindow: wtWindowPersistable({
    extract: (window: ChainsWindow) => ({ settings: pick(window.settings, 'feed') }),
    widgets: {
      Chains: {
        extract: (widget: ChainsWidget) => ({ settings: pick(widget.settings, 'selectedItem', 'selectedSourceName') }),
      }
    }
  }),
};


/**
 * Builds a {@link UserPersistables} for a specific window type, with automatic
 * grid and opt-in widget persistence support.
 */
function wtWindowPersistable<TWindow extends DashboardWindow, TData extends object>(definition: WTWindowUserPersister<TWindow, TData>): UserPersistables {
  return {
    merge(widgetData: WidgetDataModel, userData: WidgetUserDataModel, dashboardRef: DashboardRef): WidgetDataModel {

      const mergedWidgets = mergeWidgets(definition.widgets, widgetData, userData);

      const originalGrids = widgetData.grids as unknown as Grid[] | undefined;
      const userDataGrids = userData.grids as unknown as Grid[] | undefined;

      const mergedGrids = mergeGrids(originalGrids, userDataGrids);

      const originalWindow = omit(widgetData, 'widgets', 'grids') as unknown as TWindow;
      const userDataWindow = omit(userData, 'widgets', 'grids') as unknown as TData;

      let mergedWindow: TWindow;
      if (definition.merge) {
        mergedWindow = definition.merge(originalWindow, userDataWindow, dashboardRef);
      } else {
        mergedWindow = { ...originalWindow, ...userDataWindow };
        if ('settings' in userDataWindow && userDataWindow.settings) {
          mergedWindow.settings = { ...mergedWindow.settings, ...userDataWindow.settings };
        }
      }

      const mergedData = omitUndefinedProperties({
        ...mergedWindow,
        widgets: mergedWidgets,
        grids: mergedGrids
      } as unknown as WidgetDataModel);

      if (!structuresAreEqual(widgetData, mergedData)) {
        return mergedData;
      }

      return widgetData;
    },

    unmerge(widgetData: WidgetDataModel, dashboardRef: DashboardRef): WidgetUserDataModel | undefined {
      const widgetDefinitions = definition.widgets;
      const originalWindow = omit(widgetData, 'widgets', 'grids') as unknown as TWindow;

      const unmergedWindow = omitUndefinedProperties(definition.extract(originalWindow, dashboardRef));

      const unmergedWidgets = widgetDefinitions ? ((widgetData.widgets ?? []) as unknown as Widget[]).map((widget: Widget) => {
        const widgetDefinition = widgetDefinitions[widget.name];
        if (widgetDefinition) {
          const extracted = widgetDefinition.extract(widget);
          if (extracted) {
            return { id: widget.id, ...extracted };
          }
        }
        return undefined;
      }).map(omitUndefinedProperties).filter(w => !!w) : [];

      const unmergedGrids = ((widgetData.grids ?? []) as unknown as Grid[]).filter(isNonEmptyGridSettings);

      if (!isEmptyObject(unmergedWindow) && unmergedWidgets.length > 0 || unmergedGrids.length > 0) {
        return omitUndefinedProperties({
          ...unmergedWindow as WidgetDataModel,
          widgets: unmergedWidgets.length ? unmergedWidgets : undefined,
          grids: unmergedGrids.length ? unmergedGrids : undefined,
        }) as unknown as WidgetUserDataModel;
      }

      return undefined;
    },

    clean(userData: WidgetUserDataModel, dashboardRef: DashboardRef): WidgetUserDataModel {
      return userData; // not supported in this setup
    }
  };
}

type WTWidgetUserPersisters = {
  [name in WidgetName]?: WTWidgetUserPersister<Widget, object>;
};

interface WTWindowUserPersister<TWindow extends DashboardWindow, TData extends object> {
  extract(window: TWindow, dashboardRef: DashboardRef): TData | undefined;
  merge?(window: TWindow, data: TData, dashboardRef: DashboardRef): TWindow;
  widgets?: WTWidgetUserPersisters;
}

interface WTWidgetUserPersister<TWidget extends Widget, TData extends object> {
  extract(widget: TWidget): TData | undefined;
  merge?(widget: TWidget, data: TData): TWidget;
}

function mergeWidgets(widgetDefinitions: WTWidgetUserPersisters | undefined, widgetData: WidgetDataModel, userData: WidgetUserDataModel): Widget[] {
  const originalWidgets = widgetData.widgets as unknown as Widget[];
  const userDataWidgets = userData.widgets as unknown as Partial<Widget>[];

  if (Array.isArray(userDataWidgets) && Array.isArray(originalWidgets) && widgetDefinitions) {
    return originalWidgets.map(widget => {
      const widgetDefinition = widgetDefinitions[widget.name];
      const widgetUserData = userDataWidgets.find(w => w.id === widget.id);

      if (widgetDefinition && widgetUserData) {
        let mergedWidget: Widget;
        if (widgetDefinition.merge) {
          mergedWidget = widgetDefinition.merge(widget, widgetUserData);
        } else {

          mergedWidget = { ...widget, ...widgetUserData };
          if (widgetUserData.settings) {
            mergedWidget.settings = { ...widget.settings, ...widgetUserData.settings };
          }
          if (widgetUserData.toolkitSettings) {
            mergedWidget.toolkitSettings = { ...widget.toolkitSettings, ...widgetUserData.toolkitSettings };
          }

        }

        if (!structuresAreEqual(mergedWidget, widget)) {
          return mergedWidget;
        }
      }

      return widget;
    });
  }
  return originalWidgets;
}

function mergeGrids(originalGrids: Grid[] | undefined, userDataGrids: Grid[] | undefined): Grid[] | undefined {
  let mergedGrids: Grid[] | undefined;

  if (Array.isArray(originalGrids)) {
    mergedGrids = originalGrids.map(grid => {
      const gridUserData = userDataGrids?.find(gridUserData => grid.id === gridUserData.id);

      if (gridUserData) {
        const mergedGrid = { ...grid, ...gridUserData } as Grid;
        if (!structuresAreEqual(mergedGrid, grid)) {
          return mergedGrid;
        }
      }
      return grid;
    });
  }

  if (Array.isArray(userDataGrids)) {
    for (const gridUserData of userDataGrids) {
      if (!mergedGrids?.some(grid => grid.id === gridUserData.id)) {
        mergedGrids ??= [];
        mergedGrids.push(gridUserData);
      }
    }
  }

  return mergedGrids;
}

function isNonEmptyGridSettings(grid: Grid): boolean {
  for (const key of Object.keys(grid)) {
    switch (key) {
      case 'id':
      case 'name':
      case 'parentId':
      case 'dashboardId':
        break;
      // settings is only interesting if there's something set
      case 'settings':
        if (!isEmptyObject(grid.settings)) {
          return true;
        }
        break;
      // any property other than id/name/parentId/dashboardId is a setting to restore
      default:
        return true;
    }
  }
  return false;
}
