import { addDays, addHours, addMinutes, addMonths, addQuarters, addSeconds, addWeeks, addYears, subDays, subHours, subMinutes, subMonths, subQuarters, subSeconds, subWeeks, subYears } from 'date-fns';

import { arrayOfAll } from '../../util/array';
import { isLeapYear } from '../../util/date';
import type { PickByType, UnionObjectsToIntersection } from '../../util/types';

export enum PeriodDateType {
  FROM = 'from', // start date
  TO = 'to', // end date
}

export enum PeriodType {
  // MILLISECONDS = 1,
  SECOND = 1,
  MINUTE,
  HOUR,
  DAY,
  WEEK,
  MONTH,
  QUARTER,
  YEAR,
}

export enum PeriodDirection {
  PAST = 1, // DEFAULT for 'from'
  FUTURE, // DEFAULT for 'to'
}

// @TODO implement, not in actual use right now
export enum RelativeStartType {
  START_OF_DAY = 1,
  START_OF_WEEK,
  START_OF_MONTH,
  START_OF_QUARTER,
  START_OF_YEAR,
  END_OF_DAY,
  END_OF_WEEK,
  END_OF_MONTH,
  END_OF_QUARTER,
  END_OF_YEAR,
}


// @TODO implement, not in actual use right now
export enum RelativeEndType {
  START_OF_DAY = 1,
  START_OF_WEEK,
  START_OF_MONTH,
  START_OF_QUARTER,
  START_OF_YEAR,
  END_OF_DAY,
  END_OF_WEEK,
  END_OF_MONTH,
  END_OF_QUARTER,
  END_OF_YEAR,
}

// Easier to extend than startByFrom: boolean
// export enum ToStartType {
//   NOW = 1,
//   FROM,
// }

// Easier to extend than startByTo: boolean
// export enum FromStartType {
//   NOW = 1,
//   TO,
// }

// union helps preventing misconfigurations - not 100% safe, but helps!
// export type Period = (
//   // 'from' + 'to' as Date
//   | { from: Date; to: Date; }
//   | { from: Date; to?: Date; }
//   | { from?: Date; to: Date; }
//   // 'from' + 'to' as 1 Date and 1 object config
//   | { from: Date; to: { periodType?: PeriodType; periodCount?: number; periodDirection?: PeriodDirection.FUTURE; startByFrom?: true; } }
//   | { from: Date; to: { periodType?: PeriodType; periodCount?: number; periodDirection?: PeriodDirection; startByFrom?: false; } }
//   | { from: { periodType?: PeriodType; periodCount?: number; periodDirection?: PeriodDirection.PAST; startByTo?: true; }; to: Date }
//   | { from: { periodType?: PeriodType; periodCount?: number; periodDirection?: PeriodDirection; startByTo?: false; }; to: Date }
//   // 'from' + 'to' as object config
//   | {
//     from: { periodType?: PeriodType; periodCount?: number; periodDirection?: PeriodDirection.PAST; startByTo?: true; }
//     to: { periodType?: PeriodType; periodCount?: number; periodDirection?: PeriodDirection; startByFrom?: false; }
//   }
//   | {
//     from: { periodType?: PeriodType; periodCount?: number; periodDirection?: PeriodDirection; startByTo?: false; }
//     to: { periodType?: PeriodType; periodCount?: number; periodDirection?: PeriodDirection.FUTURE; startByFrom?: true; }
//   }
// );

export interface FromPeriod {
  periodType?: PeriodType;
  periodCount?: number;
  periodDirection?: PeriodDirection;
  startByTo?: boolean;
  relativeEndType?: RelativeEndType;
}

export interface ToPeriod {
  periodType?: PeriodType;
  periodCount?: number;
  periodDirection?: PeriodDirection;
  startByFrom?: boolean;
  relativeEndType?: RelativeEndType;
}

export type Period = { [PeriodDateType.FROM]: Date | FromPeriod; } | { [PeriodDateType.TO]: Date | ToPeriod; } | { [PeriodDateType.FROM]: Date | FromPeriod;[PeriodDateType.TO]: Date | ToPeriod; };

/** A resolved period becomes a PeriodDate (two Dates) */
export type PeriodDates = { from: Date; to: Date; };

/** STORAGE */
export type StoreablePeriod = (
  | { [PeriodDateType.FROM]: number | FromPeriod; }
  | { [PeriodDateType.TO]: number | ToPeriod; }
  | { [PeriodDateType.FROM]: number | FromPeriod;[PeriodDateType.TO]: number | ToPeriod; }
);
export type PeriodDateKey = keyof PickByType<UnionObjectsToIntersection<Period>, Date>;
const arrayOfAllPeriodDateKeys = arrayOfAll<PeriodDateKey>();
export const PeriodDateKeys = arrayOfAllPeriodDateKeys(
  [PeriodDateType.FROM, PeriodDateType.TO]
);

/** Some useful standard dropdown selection items by PeriodType */
export const RegularPeriodTypeCounts: { [Type in PeriodType]: number[]; } = {
  // [PeriodType.MILLISECONDS]: [...Array(1001).keys()],
  [PeriodType.SECOND]: [...Array(61).keys()],
  [PeriodType.MINUTE]: [...Array(61).keys()],
  [PeriodType.HOUR]: [...Array(25).keys()],
  [PeriodType.DAY]: [...(isLeapYear(new Date().getFullYear())) ? Array(366).keys() : Array(367).keys()],
  [PeriodType.WEEK]: [...Array(53).keys()],
  [PeriodType.MONTH]: [...Array(13).keys()],
  [PeriodType.QUARTER]: [...Array(5).keys()],
  [PeriodType.YEAR]: [...Array(6).keys()],
};

/** PeriodType to date-fns map -> returns the to be used date-fns function */
export const PeriodDateFnsSelectorMap: { [Type in PeriodType]: { [Direction in PeriodDirection]: (date: Date, amount: number) => Date; }; } = {
  // [PeriodType.MILLISECONDS]: {
  //   [PeriodDirection.PAST]: subMilliseconds,
  //   [PeriodDirection.FUTURE]: addMilliseconds,
  // },
  [PeriodType.SECOND]: {
    [PeriodDirection.PAST]: subSeconds,
    [PeriodDirection.FUTURE]: addSeconds,
  },
  [PeriodType.MINUTE]: {
    [PeriodDirection.PAST]: subMinutes,
    [PeriodDirection.FUTURE]: addMinutes,
  },
  [PeriodType.HOUR]: {
    [PeriodDirection.PAST]: subHours,
    [PeriodDirection.FUTURE]: addHours,
  },
  [PeriodType.DAY]: {
    [PeriodDirection.PAST]: subDays,
    [PeriodDirection.FUTURE]: addDays,
  },
  [PeriodType.WEEK]: {
    [PeriodDirection.PAST]: subWeeks,
    [PeriodDirection.FUTURE]: addWeeks,
  },
  [PeriodType.MONTH]: {
    [PeriodDirection.PAST]: subMonths,
    [PeriodDirection.FUTURE]: addMonths,
  },
  [PeriodType.QUARTER]: {
    [PeriodDirection.PAST]: subQuarters,
    [PeriodDirection.FUTURE]: addQuarters,
  },
  [PeriodType.YEAR]: {
    [PeriodDirection.PAST]: subYears,
    [PeriodDirection.FUTURE]: addYears,
  },
};

/** Defaults and undefined fallbacks */
export const PeriodDefaults = {
  periodDirection: {
    [PeriodDateType.FROM]: PeriodDirection.PAST,
    [PeriodDateType.TO]: PeriodDirection.FUTURE,
  },
};

/** Uses the PeriodType to date-fns map to resolve the Date */
export function resolvePeriod(date: Date, period: FromPeriod | ToPeriod, periodDateType: PeriodDateType): Date {
  // @TODO implement RelativeStartType below

  const periodTypeDateFn = PeriodDateFnsSelectorMap[period.periodType!]?.[period.periodDirection ?? PeriodDefaults.periodDirection[periodDateType]];
  if (typeof periodTypeDateFn === 'function') {
    date = periodTypeDateFn(date, period.periodCount ?? 1);
  }

  // @TODO implement RelativeEndType below
  return date;
}
