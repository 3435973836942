import { Component, HostListener } from '@angular/core';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { take, tap } from 'rxjs';
import { MarketOverviewDropdownService } from '../../dashboard-window/ui/market-overview-dropdown/market-overview-dropdown.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { StoreService } from '../../services/store.service';
import type { MarketDataType } from '../../typings/models/marketData';
import { ScreenerDialogComponent } from '../screener/screener-dialog.component';
import { mainMenuConfig } from './main-menu.config';

@Component({
  selector: 'wt-main-menu',
  templateUrl: './main-menu.component.html'
})
export class MainMenuComponent {
  mainMenuConfig = mainMenuConfig;
  // hiddenMainMenu is used to hide main-menu if market-overview-dropdown is opened.
  // setting showMainMenu to false can not be used, as it will close market-overview-dropdown, too!
  hiddenMainMenu = false;
  showMainMenu = false;

  @HostListener('document:keydown.control.shift.m', ['$event']) keydownHandler(event: KeyboardEvent) {
    event.preventDefault();
    this.marketOverviewDropdownService.state$.pipe(take(1), tap(state => {
      if (state === 'open') {
        this.marketOverviewDropdownService.setMarketOverviewState('close');
        return;
      }
      this.showMainMenu = true;
      this.hiddenMainMenu = true;
      this.marketOverviewDropdownService.setMarketOverviewState('open');
    })).subscribe();
  }

  constructor(private dashboardService: DashboardService, private marketOverviewDropdownService: MarketOverviewDropdownService, public dialog: MatDialog, private storeService: StoreService) { }

  closeDropdown(): void {
    this.hiddenMainMenu = false;
    this.showMainMenu = false;
  }

  onSourceSelected(marketDataType: MarketDataType): void {
    this.closeDropdown();
    const feed = marketDataType?.feed;
    if (feed) {
      this.dashboardService.addWindow('MarketWindow', { feed });
    }
  }

  openFundScreener(): void {
    this.dialog.open(ScreenerDialogComponent, {
      disableClose: false,
      width: '1200px',
      minWidth: '1010px',
      height: '800px',
    });
    this.hiddenMainMenu = true;
  }
}
