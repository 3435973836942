import type { ContextMenuItem } from '../../../../shared/models/context-menu.model';
import { type DashboardWindow, isInstrument } from '../../../../state-model/window.model';
import type { WindowItemTypeOptions } from '../context-menu.model';
import type { TradingOrderEntryService } from '../../../../services/trading-order-entry.service';

export const buySellItem = (window: DashboardWindow, options: WindowItemTypeOptions, tradingOrderEntryService: TradingOrderEntryService): ContextMenuItem => {
  // const instrument = options.instrument;
  return {
    id: 'buySell',
    title: 'CONTEXT_MENU_COMPONENT.TRADING.BUY_SELL',
    icon: 'import_export',
    closeOnClick: true,
    onClick: (params) => {
      if (!isInstrument(params.instrument) || !window) {
        return;
      }
      tradingOrderEntryService.openOrderEntry({ instrument: params.instrument });
    },
  };
};
