
import type { ChartWidgetOptions } from '@infront/wtk/widgets/chart';
import type { Instrument } from '../../state-model/window.model';
import type { TableSymbolData } from '../../util/symbol';
import * as Templates from './templates/';

// add new names here...
export type MarketOverviewTemplateName =
  | 'Norway'
  | 'Sweden'
  | 'Denmark'
  | 'Finland'
  | 'France'
  | 'Germany'
  | 'UK'
  | 'Italy'
  | 'Austria'
  | 'Belgium'
  | 'Switzerland'
  | 'Netherlands'
  | 'Romania'
  | 'SouthAfrica'
  | 'MajorEquityIndices'
  | 'MajorEquityFutures'
  | 'Oil';
export const MarketOverviewTemplateName = [
  'Norway',
  'Sweden',
  'Denmark',
  'Finland',
  'France',
  'Germany',
  'UK',
  'Italy',
  'Austria',
  'Belgium',
  'Switzerland',
  'Netherlands',
  'Romania',
  'SouthAfrica',
  'MajorEquityIndices',
  'MajorEquityFutures',
  'Oil',
];
// add new tabs here...
export type MarketOverviewTab = '' | 'Indices' | 'Forex' | 'FixedIncome' | 'Commodities' | 'World' | 'Americas' | 'US' | 'Europe' | 'AsiaAfrica';

export const marketOverviewTemplateList: MarketOverviewTemplateList = MarketOverviewTemplateName.reduce(
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any */
  (obj, item) => ({ ...obj, [item]: (Templates as any)[`Template${item}`] }),
  {}
);

export interface VmData {
  tabs: MarketOverviewTab[];
  data: TableSymbolData[];
}

export interface Vm extends VmData {
  chartOptions: ChartWidgetOptions;
}

export interface MarketOverviewTemplate {
  tab: MarketOverviewTab;
  instrumentList: Instrument[];
  // slightly redundant information, bu we need the current template name in service data$
  // and do not want to call window-from-widget all the time or store the current template somewhere!
  templateName?: MarketOverviewTemplateName;
}

export interface MarketOverviewTemplateList {
  [name: string]: MarketOverviewTemplate[];
}
