import { Injectable, type Provider, inject } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { BehaviorSubject, Observable, Subscription, isObservable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';

import { StoreService } from '../../services/store.service';
import type { Column } from '../../shared/grid/columns.model';
import type { Grid, PartialGrid } from '../../state-model/grid.model';
import { type Widget, WidgetInstanceRef } from '../../state-model/widget.model';
import { DestroyRef } from '../../util/destroy-ref';
import { structuresAreEqual } from '../../util/equality';
import { fullSelectedColumnsByGrid, getColumnSettingsFromColDef } from '../../util/grid';

/**
 * A handle on a {@link Grid} and its streaming updates,
 * and also its columns.
 *
 * Used primarily by the symbols-grid component, either by
 * setting it manually via `[gridRef]="..."` or by inheriting
 * it implicitly from a widget as a {@link GridRef} service
 * dependency.
 *
 * You can create
 */
@Injectable()
export abstract class GridRef {

  abstract get grid$(): Observable<Grid | undefined>;

  private _selectedColumns$: Observable<Column[]> | undefined;
  private _selectedFields$: Observable<InfrontSDK.SymbolField[]> | undefined;

  //maps whats stored in settings with the default column defintions that includes more data than the settings defintion

  get selectedColumns$() {
    this._selectedColumns$ ??= this.grid$.pipe(
      map(grid => grid ? fullSelectedColumnsByGrid(grid) : []),
      shareReplay(1)
    );
    return this._selectedColumns$;
  }

  get selectedFields$(): Observable<InfrontSDK.SymbolField[]> {
    this._selectedFields$ ??= this.selectedColumns$.pipe(
      map((columns: Column[]) => columns.map((col) => col.field as InfrontSDK.SymbolField)),
      map((selectedFields: InfrontSDK.SymbolField[]) => {
        const fields = [...selectedFields, InfrontSDK.SymbolField.Ticker, InfrontSDK.SymbolField.Feed] as InfrontSDK.SymbolField[];
        return [...new Set(fields.filter((item) => !!item))]; // remove if duplicates of Ticker or Field
      }),
      shareReplay(1)
    );
    return this._selectedFields$;
  }

  get staticGrid$(): Observable<Grid | undefined> {
    return this.grid$.pipe(take(1));
  }

  abstract onColumnsChanged(grid: Grid): void;

  // Official destroy (ref.ngOnDestroy() just looks silly)
  destroy() {
    this.onDestroy();
  }

  // override to handle releasing a GridRef, to release subscriptions, for example
  protected onDestroy() { }

}

class ObservableGridRef extends GridRef {
  readonly grid$: BehaviorSubject<Grid | undefined>;
  readonly sourceSubscription: Subscription | undefined;

  constructor(source: Grid | Observable<Grid | undefined>, private readonly onUpdate?: (grid: Grid, update: Partial<Grid>) => void) {
    super();
    if (isObservable(source)) {
      this.grid$ = new BehaviorSubject<Grid | undefined>(undefined);
      this.sourceSubscription = source.subscribe(grid => {
        if (grid !== this.grid$.value) {
          this.grid$.next(grid);
        }
      });
    } else {
      this.grid$ = new BehaviorSubject<Grid | undefined>(source);
    }
    // console.log(`[GridRef] created for ${this.grid?.id}`);
  }

  get grid(): Grid | undefined { return this.grid$.value; }

  onColumnsChanged(grid: Grid): void {
    const selectedColumns = grid.settings?.selectedColumns;
    if (!selectedColumns) {
      return;
    }

    const currentGrid = this.grid;
    if (currentGrid) {
      const oldSelectedColumns = getColumnSettingsFromColDef(currentGrid.settings?.selectedColumns ?? []);
      const newSelectedColumns = getColumnSettingsFromColDef(selectedColumns);

      if (!structuresAreEqual(newSelectedColumns, oldSelectedColumns)) {
        // Ensure we pass-on the sanitized grid settings (keeping any other
        // grid data we need).
        const updatedGrid = { settings: { ...grid.settings, selectedColumns } };
        this.onUpdate?.(currentGrid, updatedGrid);
      }
    }
  }

  protected onDestroy(): void {
    // console.log(`[GridRef] destroyed for ${this.grid?.id}`);
    this.sourceSubscription?.unsubscribe();
  }
}

/** Creates a {@link GridRef} for the specified grid */
export function createGridRef(grid: Grid | Observable<Grid>, onUpdate?: (grid: Grid, update: PartialGrid) => void): GridRef {
  return new ObservableGridRef(grid, onUpdate);
}

/** Creates a {@link GridRef} for a widget */
export function createWidgetGridRef(storeService: StoreService, widget: Widget): GridRef {
  return new ObservableGridRef(storeService.gridByWidget$(widget), (grid, update) => storeService.updateGrid(grid, update));
}

/** Provides {@link GridRef} as a service; used in a widget's `providers: [...]` declaration */
export function provideWidgetGridRef(): Provider[] {
  return [
    DestroyRef,
    {
      provide: GridRef,
      useFactory: () => {
        const gridRef = createWidgetGridRef(inject(StoreService), inject(WidgetInstanceRef).instance);
        inject(DestroyRef).onDestroy(() => gridRef.destroy());
        return gridRef;
      },
    }
  ];
}
