import { Injectable } from '@angular/core';
import { InfrontSDK, InfrontUtil } from '@infront/sdk';
import { NEVER } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { getInstrumentFromSymbolLike } from '../util/sdk';
import { OrderCategories, type OrderCategory } from '../widgets/portfolio-orders/portfolio-orders.model';
import { SdkRequestsService } from './../services/sdk-requests.service';
import { TradingService } from './../services/trading.service';

const ActiveOrderStates = [
  InfrontSDK.Trading.OrderState.Active,
  InfrontSDK.Trading.OrderState.Offline,
  InfrontSDK.Trading.OrderState.PendingInsert,
  InfrontSDK.Trading.OrderState.PendingModify,
];
const StopLossOrderStates = [
  InfrontSDK.Trading.OrderState.Active,
  InfrontSDK.Trading.OrderState.Offline,
  InfrontSDK.Trading.OrderState.Monitor,
  InfrontSDK.Trading.OrderState.PendingInsert,
];
const StopLossOrderTypes = [
  InfrontSDK.Trading.OrderType.StopLimit,
  InfrontSDK.Trading.OrderType.StopLoss,
  InfrontSDK.Trading.OrderType.Strategy,
];
const StopLossAlgoIds = [
  'InfrontCondor_TrailTick',
  'InfrontCondor_TrailTick2',
  'InfrontCondor_TrailPct',
  'InfrontCondor_StopLmt',
  'InfrontCondor_Stop',
];

const AlgoOrderTypes = [
  InfrontSDK.Trading.OrderType.Strategy,
];

// We must provide all OrderStates that we want to get from portfolioData.orders(OrderStateFilter),
// as else defaultOrderStates = ["Exchange", "Deleted"] is used!
const OrderStateFilter = [
  InfrontSDK.Trading.OrderState.Exchange, // NOSONAR although deprecated by 'Active', this is still an important OrderState!
  InfrontSDK.Trading.OrderState.Active,
  InfrontSDK.Trading.OrderState.Executed,
  InfrontSDK.Trading.OrderState.DeletedExecuted,
  InfrontSDK.Trading.OrderState.Inactive,
  InfrontSDK.Trading.OrderState.Deleted,
  InfrontSDK.Trading.OrderState.Monitor,
  InfrontSDK.Trading.OrderState.Offline,
  InfrontSDK.Trading.OrderState.PendingInsert,
  InfrontSDK.Trading.OrderState.PendingModify,
];

@Injectable({
  providedIn: 'root',
})
export class TradingOrdersService {
  constructor(private sdkRequestsService: SdkRequestsService, private tradingService: TradingService) { }

  // group the orders by their categories
  // category means some combinations of order-type and order-status
  ordersByCategory$ = () =>
    this.portfolioOrders$().pipe(
      map((symbols) => {
        if (!symbols.length) {
          return []; // empty will display "no data" in Active window!
        }
        const mappedSymbols = symbols.map((s) => ({ ...s, orderCategory: this.getOrderCategory(s) }));
        const unmappedSymbols = symbols.filter((s) => !s.symbolId).map((s) => ({ ...s, orderCategory: 'Unmapped' }));
        return [...mappedSymbols, ...unmappedSymbols] as (InfrontSDK.Trading.PortfolioItem & { orderCategory: OrderCategory })[];
      }),
      map((symbols: (InfrontSDK.Trading.PortfolioItem & { orderCategory: OrderCategory })[]) => {
        const grouped = symbols.reduce((acc, symbol) => {
          let orderCategory = symbol.orderCategory ? symbol.orderCategory : 'Unmapped';
          if (![...OrderCategories, 'Unmapped'].includes(orderCategory)) {
            orderCategory = 'Other';
          }
          acc[orderCategory] ??= [];
          acc[orderCategory].push(symbol);
          return acc;
        }, {} as { [key in OrderCategory]: InfrontSDK.Trading.PortfolioItem[] });
        return grouped;
      }));

  // get latest portfolio orders from tradingService data-action
  portfolioOrders$ = (orderStateFilter = OrderStateFilter) =>
    this.tradingService.portfolioData$.pipe(
      switchMap((portfolioData: InfrontSDK.Trading.PortfolioData | undefined) => {
        if (!portfolioData) {
          return NEVER;
        }
        const obsArray = portfolioData.orders(orderStateFilter) as InfrontUtil.ObservableArray<InfrontSDK.Trading.PortfolioItem>; // correct return type lacking in SDK
        // obsArray.data.forEach((item) => console.log('portfolio.orders item inspect:', (item.get as any)(null), 'metadata:', item.metadata())); // debug

        return this.sdkRequestsService.sdkObservableArrayAdapter$<InfrontSDK.Trading.PortfolioItem>(obsArray, 100);
      })
    );

  portfolioOrder$ = (portfolio: string, orderId: number, orderStateFilter?: InfrontSDK.Trading.OrderState[]) =>
    this.portfolioOrders$(orderStateFilter).pipe(
      map((orders) => orders?.find((order) =>
        order.get(InfrontSDK.TradingField.OrderId) === orderId
        && order.get(InfrontSDK.TradingField.Portfolio) === portfolio
      )
      )
    );

  portfolioOrderInstrument$ = (portfolio: string, orderId: number, orderStateFilter?: InfrontSDK.Trading.OrderState[]) =>
    this.portfolioOrder$(portfolio, orderId, orderStateFilter).pipe(
      map((order) => order ? getInstrumentFromSymbolLike(order) : undefined)
    );

  /**
   * @param portfolio The portfolio to derive the instruments from, if NULLISH it will use ALL orders
   * @param orderStateFilter The OrderStateFilter to apply onto the PortfolioOrders
   * @returns The instruments of the provided portfolio or (if NULLISH portfolio) the instruments of all portfolios
   */
  portfolioOrdersInstruments$ = (portfolio?: string, orderStateFilter?: InfrontSDK.Trading.OrderState[]) =>
    this.portfolioOrders$(orderStateFilter).pipe(
      map((orders) => {
        const portfolioOrders = portfolio != undefined ? orders.filter((order: InfrontSDK.Trading.PortfolioItem) => order.get(InfrontSDK.TradingField.Portfolio) === portfolio) : orders;
        return portfolioOrders.map((order: InfrontSDK.Trading.PortfolioItem) => getInstrumentFromSymbolLike(order));
      })
    );

  private getOrderCategory = (item: InfrontSDK.Trading.PortfolioItem): OrderCategory => {
    const orderType = item.get(InfrontSDK.TradingField.OrderType) as InfrontSDK.Trading.OrderType;
    const orderStatus = item.get(InfrontSDK.TradingField.OrderStatus) as InfrontSDK.Trading.OrderState;
    const algoId = item.get(InfrontSDK.TradingField.AlgoId) as string;

    // DEBUG
    // const orderId = item.get(InfrontSDK.TradingField.OrderId) as number;
    // console.log("getOrderCategory", orderId, orderType, orderStatus, algoId)


    if (orderStatus === InfrontSDK.Trading.OrderState.DeletedExecuted) {
      return 'ExecutedOrDeletedExecuted';
    }
    if (orderStatus === InfrontSDK.Trading.OrderState.Executed) {
      return 'ExecutedOrDeletedExecuted';
    }
    if (orderStatus === InfrontSDK.Trading.OrderState.Inactive) {
      return 'Inactive';
    }
    if (orderStatus === InfrontSDK.Trading.OrderState.Deleted) {
      return 'Deleted';
    }

    if ((AlgoOrderTypes.includes(orderType) && algoId && !StopLossAlgoIds.includes(algoId))) {
      // not sure if we need OrderState.Working, Offline, ... checks, too!
      return 'Algo';
    }
    if (StopLossOrderTypes.includes(orderType) && StopLossOrderStates.includes(orderStatus)) {
      return 'StopLoss';
    }
    if (!StopLossOrderTypes.includes(orderType) && ActiveOrderStates.includes(orderStatus)) {
      return 'Active';
    }
    return 'Other';
  };

}
