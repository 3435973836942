import { Component, Input } from '@angular/core';

import type { Instrument } from '../../state-model/window.model';

@Component({
  selector: 'wt-trading-order-flyout',
  templateUrl: './trading-order-flyout.component.html',
})
export class TradingOrderFlyoutComponent {
  @Input() instrument: Instrument | undefined;

  orderbookSettings = {
    showTopBar: false,
    yellowTopLevel: false,
    boldTopLevel: true,
    showOrderAmount: false,
  };

  constructor() { }

}
