import { ChangeDetectionStrategy, Component, Input, type OnDestroy, inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { InfrontSDK } from '@infront/sdk';
import { TradingService } from '../../services/trading.service';

@Component({
  selector: 'wt-trading-login',
  templateUrl: './trading-login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TradingLoginComponent implements OnDestroy {
  private readonly tradingService = inject(TradingService);

  @Input() hasLogoutButton = false;

  readonly hasTradingFeatureObj$: Observable<{ hasTradingFeature: boolean; }> = this.tradingService.hasTradingFeature$.pipe(
    map((hasTradingFeature) => ({ hasTradingFeature }))
  );
  readonly tradingConnected$ = this.tradingService.tradingConnected$;

  readonly TradingConnectionState = InfrontSDK.Trading.TradingConnectionState;

  private readonly ngUnsubscribe = new Subject<void>();

  login(): void {
    this.tradingService.isTradingLoggedIn = true;

    this.tradingService.openTradingLoginDialog$.pipe(
      take(1),
      takeUntil(this.ngUnsubscribe),
    ).subscribe();
  }

  logout(): void {
    this.tradingService.tradingLogout$.pipe(
      take(1),
      takeUntil(this.ngUnsubscribe)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
