import { Injectable, type OnDestroy, Optional, SkipSelf, inject } from '@angular/core';
import { LogService } from '@vwd/ngx-logging';
import { BehaviorSubject, Observable, distinctUntilChanged, identity, map, of, switchMap, timer } from 'rxjs';
import { DestroyRef } from '../util/destroy-ref';

@Injectable()
export class ProgressService implements OnDestroy {
  private readonly logger = inject(LogService).openLogger(this.label);
  private readonly progresRefs: ProgressRef[] = [];
  private readonly inProgressSubject = new BehaviorSubject(false);

  protected get label() { return 'services/progress'; }

  readonly inProgress$ = this.inProgressSubject.pipe(
    switchMap(value => value ? timer(10).pipe(map(() => value)) : of(value)),
    distinctUntilChanged(),
  );

  constructor(@Optional() destroyRef: DestroyRef) {
    destroyRef?.onDestroy(() => this.ngOnDestroy());
  }

  get inProgress() { return !!this.progresRefs.length; }

  start(label: string): ProgressRef {
    let completed = false;
    const result: ProgressRef = {
      label: label,
      complete: () => {
        if (!completed) {
          completed = true;
          this.logger.info(`complete(${JSON.stringify(label)})`, this);
          const index = this.progresRefs.indexOf(result);
          if (index !== -1) {
            this.progresRefs.splice(index, 1);
            this.updateProgress();
          } else {
            this.logger.warn(`cannot unbind ${label}`);
          }
        }
      }
    };
    this.progresRefs.push(result);
    this.logger.info(`start(${JSON.stringify(label)})`, this);
    this.updateProgress();
    return result;
  }

  protected updateProgress(): void {
    const inProgress = !!this.progresRefs.length;
    if (inProgress !== this.inProgressSubject.value) {
      this.inProgressSubject.next(inProgress);
    }
  }

  ngOnDestroy(): void {
    if (this.progresRefs.length) {
      for (const progress of [...this.progresRefs]) {
        progress.complete();
      }
      this.progresRefs.length = 0; // just in case
    }
    this.updateProgress();
    this.inProgressSubject.complete();
    this.logger.debug('destroyed');
  }
}

@Injectable()
export class NestedProgressService extends ProgressService implements OnDestroy {

  protected get label() { return 'services/progress/nested'; }

  private parentProgressRef?: ProgressRef;

  constructor(
    @SkipSelf()
    private readonly parent: ProgressService,
    destroyRef: DestroyRef,
  ) {
    super(destroyRef);
  }

  protected updateProgress(): void {
    super.updateProgress();
    if (this.inProgress) {
      if (!this.parentProgressRef) {
        this.parentProgressRef = this.parent.start('nested progress');
      }
    } else if (this.parentProgressRef) {
      this.parentProgressRef.complete();
      this.parentProgressRef = undefined;
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.parentProgressRef?.complete();
  }
}

export interface ProgressRef {
  label: string;
  complete(): void;
}

export interface ProgressOperatorOptions {
  label: string;
  progress?: ProgressService | null;
  optional?: boolean;
}

export function trackProgress<T>(label: string, progress?: ProgressService | undefined | null): (input: Observable<T>) => Observable<T>;
export function trackProgress<T>(options: ProgressOperatorOptions): (input: Observable<T>) => Observable<T>;
export function trackProgress<T>(labelOrOptions: string | ProgressOperatorOptions, progressInstance?: ProgressService | undefined | null): (input: Observable<T>) => Observable<T> {
  let label: string;
  let optional: boolean;
  let progressService: ProgressService | undefined | null;

  if (typeof labelOrOptions === 'string') {
    label = labelOrOptions;
    optional = false;
    progressService = progressInstance;
  } else {
    label = labelOrOptions.label;
    optional = !!labelOrOptions.optional;
    progressService = labelOrOptions.progress;
  }

  if (!progressService) {
    if (!optional) {
      progressService = inject(ProgressService);
    } else {
      try {
        progressService = inject(ProgressService, { optional: true }) ?? undefined;
      } catch (e) {
        // do nothing;
      }

      if (!progressService) {
        return identity;
      }
    }
  }

  return source => new Observable<T>(subscriber => {
    const progressRef = progressService.start(label);

    const subscription = source.subscribe({
      next: value => {
        subscriber.next(value);
        progressRef.complete();
      },
      error: error => subscriber.error(error),
      complete: () => {
        subscriber.complete();
        progressRef.complete();
      }
    });
    // Add teardown in case unsubscribe happens before source completes
    subscription.add(() => progressRef.complete());
    return subscription;
  });
}
