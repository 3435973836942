import type { UntranslatedNoRowsTemplate } from '../../shared/grid/grid.model';
import type { WidgetGridsMap } from '../../state-model/grid.model';
import { deepFreeze } from '../../util/object';

export const RepresentedOrderCategories = ['Active', 'Algo', 'StopLoss', 'Inactive', 'Deleted', 'ExecutedOrDeletedExecuted'] as const;
export const OrderCategories = [...RepresentedOrderCategories, 'Other', 'Unmapped'] as const;

export type OrderCategory = typeof OrderCategories[number];

export type PortfolioGridType = typeof WidgetGridsMap['portfolioOrdersGrid'][number];

// export const PortfolioPositionsTabs = ['Default', 'Performance'] as const;

export const overlayNoRowsTemplateOptions = deepFreeze({
  noData: { content: 'PORTFOLIO_ORDERS_WIDGET.GRID.NO_DATA' },
  noOrders: { content: 'PORTFOLIO_ORDERS_WIDGET.GRID.NO_ORDERS' }
} as const satisfies { [name: string]: UntranslatedNoRowsTemplate; });
