import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'wt-iframe-overlay-content',
  templateUrl: './iframe-overlay-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IframeOverlayContentComponent {
  @Input({ required: true }) backdropClick$!: Observable<MouseEvent>;
  @Input({ required: true }) closeOverlay!: () => void;
  @Input({ required: true }) title!: string;
  @Input({ required: true }) url!: string;
}
