
import type { StoreService } from '../../../../services/store.service';
import type { ContextMenuItem } from '../../../../shared/models/context-menu.model';
import type { UserlistWidget } from '../../../../state-model/widget.model';
import type { WatchlistWindow } from '../../../../state-model/window.model';
import { isSameInstrument } from '../../../../util/symbol';
import type { WindowItemTypeOptions } from '../context-menu.model';

export const removeFromUserlistItem = (window: WatchlistWindow, options: WindowItemTypeOptions, storeService: StoreService): ContextMenuItem => {

  return {
    id: 'removeFromUserlist',
    title: 'CONTEXT_MENU_COMPONENT.USERLIST.REMOVE_FROM_USERLIST',
    icon: 'delete',
    isSVGIcon: true,
    onClick: (params) => {
      if (!params.instrument || !params.widget) {
        return;
      }
      const widget = params.widget as UserlistWidget;
      const instrument = params.instrument;
      const instruments = [...widget.settings.instruments.filter(item => !isSameInstrument(item, instrument))];
      storeService.updateWidget(widget, { settings: { ...widget.settings, instruments } });
    },
  };
};
