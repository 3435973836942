import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { MissingTranslationHandler, type MissingTranslationHandlerParams } from '@vwd/ngx-i18n/translate';
import { FormattingService } from '@vwd/ngx-i18n';
import { LogService } from '@vwd/ngx-logging';

// fallback to the input inner-most translation key, in case no translation is defined!
// This option set to true to as it is required in WT5 for being able to provide only some translations
// for all available symbol feed-ticker pairs!
// Also setting this option to true results in similar behaviour like for WTK translations!
const FALLBACK_TO_INNERMOST_TRANSLATION_KEY = true;
// debug missing translations
// default is, to not show errors for missing translations,
// as we expect translation to be unset in WT5, e.g. for symbol feed-ticker pairs!
const SHOW_ERROR_FOR_MISSING_TRANSLATION = false;

@Injectable({ providedIn: 'root' })
export class DefaultMissingTranslationHandler implements MissingTranslationHandler {
  private readonly logger = this.logService.openLogger('shared/default-missing-translation');

  constructor(private readonly logService: LogService, private formatting: FormattingService) { }

  handle(params: MissingTranslationHandlerParams): string {
    if (params.interpolateParams?.['default']) {
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
      const newInterpolateParams = { ...params.interpolateParams };
      delete newInterpolateParams['default'];

      /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
      const defaultTranslation = params.resourceService.get(params.interpolateParams['default'], newInterpolateParams);
      if (defaultTranslation != undefined) {
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
        return params.resourceService.get(defaultTranslation);
      }
    }

    const fallback = params.interpolateParams?.['fallback'] as string;

    // If we get a sentence, treat this as a pre-translated value
    if (FALLBACK_TO_INNERMOST_TRANSLATION_KEY && !fallback && /\s/.test(params.key)) {
      return params.key;
    }

    const fallbackToTranslationKey = FALLBACK_TO_INNERMOST_TRANSLATION_KEY ? (params.key?.split('.')?.pop() ?? undefined) : undefined;
    if (environment.production) {
      return fallback
        ?? fallbackToTranslationKey
        ?? params.resourceService.get('GLOBAL.NOT_AVAILABLE');
    } else {
      if (SHOW_ERROR_FOR_MISSING_TRANSLATION) {
        this.logger.error(`Translation not available for key ['${params.key}'] in language ['${this.formatting.localeId}']`);
      }
      return fallback
        ?? fallbackToTranslationKey
        ?? `Translation not available for key ['${params.key}'] in language ['${this.formatting.localeId}']`;
    }
  }
}
