import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import type { ICellRendererParams } from 'ag-grid-community';

import type { FlagSize } from '@infront/wtk/utils/country-flags';
import { getCountryForSymbol, getCountryName } from '../../../util/country';
import { type TableSymbolData, isSymbolData } from '../../../util/symbol';

@Component({
  selector: 'wt-country-flag-cell',
  templateUrl: './county-flag-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryFlagCellComponent {
  @Input() size: FlagSize = 16;
  country: string | number | undefined;
  countryName: string | undefined;
  ticker: string | undefined;
  fullName: string | undefined;
  noFlag = false;
  noClickable = false;

  agInit(params: ICellRendererParams): void {
    this.ticker = undefined;
    this.fullName = undefined;
    this.noFlag = !!(
      (params.data?.symbol as TableSymbolData)?.noFlag // first priority
      ?? (params.data as TableSymbolData)?.noFlag // second priority
    );
    if (typeof params.value === 'string') {
      this.countryName = getCountryName(params.value);
      this.country = params.value;
      return;
    }
    this.noClickable = !!(
      (params.data?.symbol as TableSymbolData)?.noClickable // first priority
      ?? (params.data as TableSymbolData)?.noClickable // second priority
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (params.value?.name && typeof params.value.name === 'string' && params.value.name.toUpperCase() === 'CASH') {
      this.country = 'CASH';
      this.countryName = 'Cash';
      return;
    }
    // NOTE: this logic may need some adjustment in the future!
    if (isSymbolData(params.data?.symbol as InfrontSDK.SymbolData)) {
      // if params.data.symbol is valid SymbolData, it has priority for getting the country!
      // use case: fund has symbol data in params.data, but we need the country for the fund-company
      // so we add the company's instruments symbolData as params.data.symbol, too.
      this.setData(params.data.symbol as InfrontSDK.SymbolData, params.data?.symbol.translation as string);
    } else if (isSymbolData(params.data as InfrontSDK.SymbolData)) {
      // try params.data as SymbolData
      this.setData(params.data as InfrontSDK.SymbolData, params.data?.translation as string);
    } else {
      this.country = (params.data.country as string | number) ?? '';
    }
    this.countryName = getCountryName(this.country as string);
  }

  setData(data: InfrontSDK.SymbolData, translation?: string): void {
    this.country = getCountryForSymbol(data) ?? '';
  }
}
