import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Infront } from '@infront/sdk';
import { PortfolioSingleValueWidget, PortfolioSingleValueWidgetOptions } from '@infront/wtk/widgets/portfolio-single-value';
import type { WTKWidgetConstructorWithOptions } from '../../../wrappers/wtk-widget-wrapper/wtk-widget-wrapper.model';
import { PortfolioValueMap, PortfolioValueTranslationLabels } from './portfolio-single-value.model';
@Component({
  selector: 'wt-portfolio-single-value',
  templateUrl: './portfolio-single-value.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortfolioSingleValueComponent {
  private _portfolioValue!: Infront.PortfolioValue;
  @Input({ required: true }) set portfolioValue(portfolioValue: Infront.PortfolioValue) { // Strings that are mapped to Infront.PortfolioValue
    const valueName: string | undefined = PortfolioValueMap[portfolioValue];
    if (!valueName) {
      throw new Error('PortfolioSingleValueComponent no mapped PortfolioValue field found.');
    }
    this._portfolioValue = portfolioValue;
    this.portfolioSingleValueWidget.options!.valueName = valueName;
    this.triggerOptionsChangeDetection();
  }
  get portfolioValue(): Infront.PortfolioValue {
    return this._portfolioValue;
  }
  @Input() set showCurrency(showCurrency: PortfolioSingleValueWidgetOptions['showCurrency']) {
    this.portfolioSingleValueWidget.options!.showCurrency = showCurrency;
    this.triggerOptionsChangeDetection();
  }
  get showCurrency(): PortfolioSingleValueWidgetOptions['showCurrency'] {
    return this.portfolioSingleValueWidget?.options!.showCurrency;
  }
  private _widgetOptions = new PortfolioSingleValueWidgetOptions();
  @Input() set widgetOptions(widgetOptions: PortfolioSingleValueWidgetOptions) {
    this._widgetOptions = widgetOptions;
    this.portfolioSingleValueWidget.options = widgetOptions;
    this.triggerOptionsChangeDetection();
  }
  get widgetOptions(): PortfolioSingleValueWidgetOptions {
    return this._widgetOptions;
  }
  @Input() widgetClassList?: string[];
  @Input() customLabel?: string;
  @Input() showLabel?: boolean;
  @Input() labelClassList?: string[];

  // @ViewChild('wtkWidget', { static: true }) wtkWidget: ElementRef<WTKWidgetWrapperComponent<Infront.PortfolioSingleValueWidget>>;

  widgetClass = PortfolioSingleValueWidget;

  portfolioSingleValueWidget: WTKWidgetConstructorWithOptions<PortfolioSingleValueWidget, PortfolioSingleValueWidgetOptions> = {
    widgetConstructor: PortfolioSingleValueWidget,
    options: this.widgetOptions,
  };

  PortfolioValueTranslationLabels = PortfolioValueTranslationLabels;
  // private readonly logger = inject(LogService).openLogger('shared/trading/portfolio-single-value'); // Not in use atm

  constructor(private cdRef: ChangeDetectorRef) { }

  private triggerOptionsChangeDetection(): void {
    const widgetConstructor = this.portfolioSingleValueWidget.widgetConstructor;
    const options = this.portfolioSingleValueWidget.options;
    this.portfolioSingleValueWidget = { widgetConstructor, options }; // need to update reference in order to trigger change detection
    this.cdRef.markForCheck();
  }

}
