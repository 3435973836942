import { Component } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { I18nTranslateModule, provideResources } from '@vwd/ngx-i18n/translate';

@Component({
  templateUrl: './pwa-install-prompt.component.html',
  standalone: true,
  imports: [
    MatDialogModule,
    MatIconModule,
    I18nTranslateModule,
  ],
  providers: [
    provideResources({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      load: (localeId: string) => require(`./i18n/${localeId}.json`),
      supportedLocales: ['en']
    }),
  ]
})
export class PwaInstallPromptComponent {
  constructor(
  ) { }
}
