import { Component, inject } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import type { ICellRendererAngularComp } from 'ag-grid-angular';

import { LogService } from '@vwd/ngx-logging';
import type { TradingOrderMetaData, TradingPositionMetaData } from '../../../dashboard-window/ui/context-menu/context-menu.model';
import { ContextMenuService } from '../../../dashboard-window/ui/context-menu/context-menu.service';
import type { ContextMenuContext } from '../../../shared/models/context-menu.model';
import { type Instrument, isInstrument } from '../../../state-model/window.model';
import type { CustomCellRendererParams } from '../columns.model';

type TradingSymbolData = InfrontSDK.SymbolData & { get: <T>(field: InfrontSDK.TradingField) => T, metadata(): object; };
type SymbolDataWithTrading = InfrontSDK.SymbolData & { tradingSymbol: TradingSymbolData; };

@Component({
  selector: 'wt-row-menu',
  template: `
    <wt-context-menu
      *ngIf="showContextMenu"
      [context]="context"
    ></wt-context-menu>
  `,
  providers: [ContextMenuService],
})
export class RowMenuComponent implements ICellRendererAngularComp {

  context!: ContextMenuContext;
  showContextMenu = false;
  // inject log service
  private readonly logger = inject(LogService).openLogger('component/row-menu');

  agInit(params: CustomCellRendererParams): void {
    const instrument = this.getInstrument(params);
    if (instrument || this.getTradingOrder(params) || this.getTradingPosition(params)) {
      this.showContextMenu = true;
    } else {
      this.logger.error('RowMenuComponent needs access to `ticker` and `feed` or `getTradingOrder()` or `getTradingPosition()` to generate a context menu');
      this.showContextMenu = false;
    }
    this.context = {
      ...params.context as ContextMenuContext,
      instrument,
      tradingOrderGetter: () => this.getTradingOrder(params)!,
      tradingPositionGetter: () => this.getTradingPosition(params)!,
    };
  }

  refresh(): boolean {
    return false;
  }

  private getInstrument(params: CustomCellRendererParams): Instrument | undefined {
    if (params?.data.get != undefined) {
      return { ticker: params?.data.get(InfrontSDK.SymbolField.Ticker), feed: params?.data.get(InfrontSDK.SymbolField.Feed) };
    }
    if (isInstrument(params.data)) {
      return { ticker: params.data.ticker, feed: params.data.feed };
    }
    if ('Ticker' in params.data && params.data['Ticker'] && 'Feed' in params.data && params.data['Feed']) {
      return { ticker: params.data['Ticker'] as string, feed: params.data['Feed'] as number };
    }
    return undefined;
  }

  private getTradingOrder(params: CustomCellRendererParams): TradingOrderMetaData | undefined {
    const tradingSymbol = (params.data?.symbol as SymbolDataWithTrading)?.tradingSymbol;
    if (!tradingSymbol) {
      return undefined;
    }

    const OrderId = tradingSymbol.get<number>(InfrontSDK.TradingField.OrderId);
    const OrderPortfolio = tradingSymbol.get<string>(InfrontSDK.TradingField.Portfolio);
    const OrderStatus = tradingSymbol.get<InfrontSDK.Trading.OrderState>(InfrontSDK.TradingField.OrderStatus);
    const Metadata = tradingSymbol?.metadata?.() ?? {};

    return (OrderId && OrderPortfolio && OrderStatus) ? { OrderId, OrderPortfolio, OrderStatus, Metadata } : undefined;
  }

  private getTradingPosition(params: CustomCellRendererParams): TradingPositionMetaData | undefined {
    const tradingSymbol = (params.data?.symbol as SymbolDataWithTrading)?.tradingSymbol;
    if (!tradingSymbol) {
      return undefined;
    }

    const FullNameDebug = tradingSymbol.get<string>(InfrontSDK.TradingField.FullNameDebug);
    const SymbolMappingDebug = tradingSymbol.get<string | undefined>(InfrontSDK.TradingField.SymbolMappingDebug); // can be undefined and this is fine!

    return { FullNameDebug, SymbolMappingDebug };
  }
}
