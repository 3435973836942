import { Pipe, type PipeTransform } from '@angular/core';

import { removeByStartAndEndChar, removeFirstWord, replaceAll } from '../util/string';

@Pipe({
  name: 'alertDescription',
})
export class AlertDescriptionPipe implements PipeTransform {
  // this pipe is custom made to handle multiple rules for altering the backend generated strings we get in alert.description, resulting in multiple loops, regex checks etc
  // a regex fluent dev could probably shorten this A LOT

  transform(value: string, alert: { countryFlag: string; ticker: string }): unknown {
    if (!value) {
      return value;
    }
    value = replaceAll(' is', '', value);
    value = replaceAll(' in', '', value);
    value = replaceAll('%Change', 'Change', value);
    value = removeByStartAndEndChar(value, '', '(', ' ');
    value = value.replace(alert.ticker, '');
    value = removeByStartAndEndChar(value, '', '"', '"', 0);
    if (!value.startsWith('(') && !!alert.countryFlag) {
      value = removeFirstWord(value);
    }

    // the match-regex had to be build more complicate than just /[\d,./-]+%/g
    // as it has been reported to be "vulnerable to super-linear runtime due to backtracking"
    const wordsWithPercentageSign = value.match(/((?=([\d,./+-]+))\2)%/g) ?? [''];
    const colorWords = wordsWithPercentageSign.filter((word) => word.length && !word.trim().startsWith('%')).map((word) => word.trim());

    const colorWordMatches: string[] = [];
    if (colorWords?.length) {
      value = colorWords.reduce(
        (acc: string, colorWord: string) =>
          acc.replace(colorWord, (match: string) => {
            const cls = colorWord.startsWith('-') ? 'grid__cell--negative' : colorWord.startsWith('0') ? 'wt-my-alerts--neutral-value' : 'grid__cell--positive'; // NOSONAR
            colorWordMatches.push(`<span class='${cls}'>${match}</span>`);
            return `colorWordMatch`;
          }),
        value
      );
    }

    const numberWords = value.match(/[\d,.]+/g)?.map((word) => word.trim());

    const resultWithNumbers = numberWords?.reduce(
      (acc: string, numberWord: string) =>
        acc.replace(numberWord, (match: string) => {
          return `<span class='wt-my-alerts--neutral-value'>${match}</span>`;
        }),
      value
    );

    const resultWithColorMatch = colorWordMatches.reduce(
      (acc: string, colorWordMatch: string) =>
        acc.replace('colorWordMatch', (_match: string) => {
          return colorWordMatch;
        }),
      resultWithNumbers ?? value
    );

    return resultWithColorMatch;
  }
}
