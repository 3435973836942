import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';

import type { FlagSize } from '@infront/wtk/utils/country-flags';
import { getCountryForSymbol } from '../../../util/country';

@Component({
  selector: 'wt-country-display-name-cell',
  templateUrl: './country-display-name-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryDisplayNameCellComponent {
  @Input() size: FlagSize = 16;
  country!: string | number;
  displayName!: string;

  agInit(params: { data: { symbol: InfrontSDK.SymbolData } }): void {
    this.country = getCountryForSymbol(params.data.symbol) ?? '';
    this.displayName = params.data.symbol?.get?.(InfrontSDK.SymbolField.FullName) ?? '';
  }
}
