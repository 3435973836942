
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, type OnDestroy, ViewChild } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { LastValueSubject } from '@infront/ngx-dashboards-fx/utils';
import type { ICellRenderer, ICellRendererParams } from 'ag-grid-community';
import { BehaviorSubject, map, shareReplay } from 'rxjs';

@Component({
  selector: 'wt-cell-symbol-status',
  template: `<span #cellSpan [class]="cellSpanCSSClass$ | async" [title]="preSymbolStatusText$ | async">{{ displaySymbolStatus$ | async }}</span>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'class': 'wt-symbol-status'
  },
  imports: [CommonModule],
  standalone: true
})
export class CellSymbolStatusComponent implements ICellRenderer, OnDestroy {
  @ViewChild('cellSpan') readonly cellSpanElement!: ElementRef<HTMLSpanElement>;

  private readonly displaySymbolStatusAction = new BehaviorSubject<string | undefined>(undefined);
  readonly displaySymbolStatus$ = this.displaySymbolStatusAction.pipe(
    map((value) => value ?? '-'),
    shareReplay(1),
  );

  private readonly preSymbolStatusTextAction = new LastValueSubject<string | undefined>();
  readonly preSymbolStatusText$ = this.preSymbolStatusTextAction.pipe(
    map((value) => value ?? ''),
    shareReplay(1),
  );

  readonly cellSpanCSSClass$ = this.displaySymbolStatusAction.pipe(
    map((displaySymbolStatus) => displaySymbolStatus
      ? `wt-symbol-status__label wt-symbol-status__label--${displaySymbolStatus.toLowerCase()}`
      : `wt-symbol-status__label`
    ),
    shareReplay(1),
  );

  ngOnDestroy(): void {
    this.displaySymbolStatusAction.complete();
    this.preSymbolStatusTextAction.complete();
  }

  agInit(params: ICellRendererParams): void {
    this.displaySymbolStatusAction.next(params.value as string | undefined);
    this.preSymbolStatusTextAction.next((params.data.symbol as InfrontSDK.SymbolData)?.get(InfrontSDK.SymbolField.PreSymbolStatusText));
  }

  refresh(params: ICellRendererParams): boolean {
    this.displaySymbolStatusAction.next(params.value as string | undefined);
    this.preSymbolStatusTextAction.next((params.data.symbol as InfrontSDK.SymbolData)?.get(InfrontSDK.SymbolField.PreSymbolStatusText));
    return true;
  }
}
