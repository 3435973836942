import type { SymbolDataItem } from '../../shared/models/symbol-data.model';

export function isFeedSource(item: SourceListItem | undefined): item is FeedSource {
  return item?.sourceType === 'Feed';
}
export function isChainSource(item: SourceListItem | undefined): item is ChainSource {
  return item?.sourceType === 'Chain' && !('nodes' in item);
}
export function isChainSourceCategory(item: SourceListItem | undefined): item is ChainSourceCategory {
  return item?.sourceType === 'Chain' && 'nodes' in item;
}

export const LISTS_MAX_SELECTABLE_ROWS = 10;

export type SourceListItem = ChainSourceListItem | FeedSource;

export type ChainSourceListItem = ChainSourceCategory | ChainSource;

export type SelectableSource = ChainSource | FeedSource;
export interface ChainSourceCategory {
  label: string;
  nodes: ChainSource[];
  sortField: number;
  sourceType: 'Chain';
}

export interface ChainSource {
  label: string;
  chain: Chain;
  sortField?: number;
  sourceType: 'Chain';
}

export interface FeedSource {
  label: string;
  feed: number;
  sourceType: 'Feed';
}

export interface Chain {
  feed: number;
  name: string;
  description: string;
  type: string;
  providerId: number;
  label: string;
  fullPath: string;
  sortField: number;
}

export const allInstruments = 'All instruments';

export interface Vm {
  sources: SourceListItem[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  symbols: SymbolDataItem[];
  selectedSource: SelectableSource;
}
