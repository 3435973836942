import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

export interface TradingOrderInfoData {
  metadata: object | undefined;
}

type OrderInfoData = { key: string, value: unknown, type: string };

// TODO: depending on feedback be PMs, implement sorting and white- or blacklist if required!

@Component({
  selector: 'wt-trading-order-info',
  templateUrl: './trading-order-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class TradingOrderInfoComponent {
  orderInfoData: OrderInfoData[];

  constructor(
    public dialogRef: MatDialogRef<TradingOrderInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TradingOrderInfoData,
  ) {
    // we start always with undefined `orderInfoData`, no need to clean on close!
    this.orderInfoData = this.getOrderInfoData(data);
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  private getOrderInfoData(data: TradingOrderInfoData): OrderInfoData[] {
    if (!data?.metadata) {
      return [];
    }

    const orderInfoData: OrderInfoData[] = Object.keys(data.metadata).map((key) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const value = (data.metadata as any)[key] as unknown;
      const checkType = typeof value;
      let type = checkType as string;
      if ((checkType === 'object') && (value instanceof Date)) {
        type = 'date';
      }
      // add more rules here, if more type-strings are required
      return { key, value, type };
    });

    return orderInfoData;
  }

}
