import { ChangeDetectionStrategy, Component, Input, type OnDestroy, inject } from '@angular/core';
import { LastValueSubject } from '@infront/ngx-dashboards-fx/utils';
import { InfrontSDK, InfrontUtil } from '@infront/sdk';
import type { CellClickedEvent } from 'ag-grid-community';
import { Observable, Subject, distinctUntilChanged, filter, of, shareReplay, switchMap, tap } from 'rxjs';

import { UserSettingsService } from '../../../../../services/user-settings.service';
import { WatchlistService } from '../../../../../services/watchlist.service';
import type { GridSettings } from '../../../../../shared/grid/columns.model';
import { GridService } from '../../../../../shared/grid/grid.service';
import type { SymbolDataItem } from '../../../../../shared/models/symbol-data.model';
import { createUserSettingsGridRef } from '../../../../../wrappers/grid-wrappers/grid-ref-user-settings';
import { GridRef } from '../../../../../wrappers/grid-wrappers/gridref';
import { WatchlistDefaultGrid, WatchlistPerformanceGrid, WatchlistSubTabConfigList } from '../../sidebar-content.defaults';
import { type WatchlistSelectedSubTabs, WatchlistSubTab } from '../../sidebar-content.model';
import { SidebarContentService } from '../../sidebar-content.service';

@Component({
  selector: 'wt-watchlist-tab',
  templateUrl: './watchlist-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SidebarContentService]
})
export class WatchlistTabComponent implements OnDestroy {
  private readonly contentService = inject(SidebarContentService);
  private readonly watchlistService = inject(WatchlistService);
  private readonly userSettingsService = inject(UserSettingsService);
  private readonly gridService = inject(GridService);

  @Input() set selectedWatchlistId(selectedWatchlist: InfrontSDK.Watchlist['id'] | undefined) {
    this.selectedWatchlistIdAction.next(selectedWatchlist);
  }
  get selectedWatchlistId(): InfrontSDK.Watchlist['id'] | undefined {
    return this.selectedWatchlistIdAction.getValue();
  }
  private readonly selectedWatchlistIdAction = new LastValueSubject<InfrontSDK.Watchlist['id'] | undefined>();
  readonly selectedWatchlistId$ = this.selectedWatchlistIdAction.pipe(
    distinctUntilChanged(),
    shareReplay(1),
  );
  readonly watchlistSymbolData$: Observable<SymbolDataItem[]> = this.selectedWatchlistId$.pipe(
    switchMap((wlId) => wlId ? this.contentService.watchlistSymbolData$(wlId) : of([])),
    shareReplay(1),
  );

  readonly gridSettings: GridSettings = { rowContextMenu: false };

  watchlistSelectedSubTabs: WatchlistSelectedSubTabs | undefined;

  readonly watchlistSelectedSubTabs$ = this.userSettingsService.getValue$('watchlistTabSelectedSubTabs').pipe(
    filter((watchlistSelectedSubTabs) => {
      if (!watchlistSelectedSubTabs) {
        this.userSettingsService.setValue('watchlistTabSelectedSubTabs', {});
        return false;
      }
      return true;
    }),
    tap((watchlistSelectedSubTabs) => {
      this.watchlistSelectedSubTabs = watchlistSelectedSubTabs;
    }),
  );

  /** Cleans the 'watchlistTabSelectedSubTabs' userSettingsService key off outdated watchlist grid storage entries */
  readonly deleteDeprecatedWatchlistSelectedSubTabs$ = this.watchlistService.watchlists$.pipe(
    tap(({ watchlists }) => {
      let needUpdate = false;
      const watchlistSelectedSubTabsWithoutDeprecates = InfrontUtil.deepCopy(this.watchlistSelectedSubTabs) as WatchlistSelectedSubTabs;
      Object.keys(watchlistSelectedSubTabsWithoutDeprecates).forEach((watchlistId) => {
        if (!watchlists.some((wl) => wl.id === watchlistId)) {
          if (!needUpdate) {
            needUpdate = true;
          }
          delete watchlistSelectedSubTabsWithoutDeprecates[watchlistId];
        }
      });
      if (needUpdate) {
        this.userSettingsService.setValue('watchlistTabSelectedSubTabs', watchlistSelectedSubTabsWithoutDeprecates);
      }
    }),
  );

  readonly watchlistSubTabConfigList = WatchlistSubTabConfigList;
  private readonly watchlistDefaultGrid = createUserSettingsGridRef(this.userSettingsService, WatchlistDefaultGrid);
  private readonly watchlistPerformanceGrid = createUserSettingsGridRef(this.userSettingsService, WatchlistPerformanceGrid);
  watchlistGrids: { gridRef: GridRef; subTab: WatchlistSubTab }[] = [
    { gridRef: this.watchlistDefaultGrid, subTab: WatchlistSubTab.DEFAULT },
    { gridRef: this.watchlistPerformanceGrid, subTab: WatchlistSubTab.PERFORMANCE },
  ];

  readonly watchlistSubTabEnum = WatchlistSubTab;

  private readonly ngUnsubscribe = new Subject<void>();

  selectWatchlistSubTab(watchlistSubTab: WatchlistSubTab): void {
    const selectedWatchlistId = this.selectedWatchlistIdAction.getValue();
    if (!selectedWatchlistId || !this.watchlistSelectedSubTabs || watchlistSubTab === this.watchlistSelectedSubTabs[selectedWatchlistId]) {
      return;
    }
    this.userSettingsService.setValue(
      'watchlistTabSelectedSubTabs',
      { ...this.watchlistSelectedSubTabs, [selectedWatchlistId]: watchlistSubTab }
    );
  }

  onWatchlistSelected(selectedWatchlist: InfrontSDK.Watchlist | undefined): void {
    const selectedWatchlistId = selectedWatchlist?.id;
    if (selectedWatchlistId === this.selectedWatchlistIdAction.getValue()) {
      return;
    }
    const watchlistSelectedSubTabs = this.userSettingsService.getValue('watchlistTabSelectedSubTabs');
    if (selectedWatchlistId && !watchlistSelectedSubTabs[selectedWatchlistId]) {
      this.userSettingsService.setValue(
        'watchlistTabSelectedSubTabs',
        { ...watchlistSelectedSubTabs, [selectedWatchlistId]: WatchlistSubTab.DEFAULT }
      );
    }
    this.userSettingsService.setValue('sidebar', { ...this.userSettingsService.getValue('sidebar'), selectedWatchlist: selectedWatchlistId });
  }

  onGridCellClick(event: CellClickedEvent): void {
    this.gridService.handleGridCellClick(event);
  }

  ngOnDestroy(): void {
    this.watchlistDefaultGrid.destroy();
    this.watchlistPerformanceGrid.destroy();
    this.selectedWatchlistIdAction.complete();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
