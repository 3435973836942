import { Injectable } from '@angular/core';
import { InfrontSDK, InfrontUtil } from '@infront/sdk';
import { Observable, Subscriber } from 'rxjs';
import { finalize, map, switchMap, take, tap } from 'rxjs/operators';

import { SdkRequestsService } from '../../services/sdk-requests.service';
import { SdkService } from '../../services/sdk.service';
import type { Instrument } from '../../state-model/window.model';
import { getDecimals } from '../../util/symbol';
import { ProgressService, trackProgress } from '../progress';
import { OrderbookFields, type OrderbookUIData } from './orderbook-view.model';

@Injectable({
  providedIn: 'any', // with lazy loading any will create the service as a local singleton in the lazy module
})
export class OrderbookViewService {
  symbolInfo$ = (instrument: Instrument) => this.sdkRequestsService.streamingSymbolData$({
    symbolEntity: instrument,
    fields: OrderbookFields,
    uuid: InfrontUtil.makeUUID(),
  }).pipe(
    // workaround for streamingSymbolData$ delivering reference to Date object,
    // and Angular can not detect changes on it.
    // TODO: fix in streamingSymbolData$
    tap(symbol => symbol.PreDisplayTime = (InfrontUtil.isDate(symbol.PreDisplayTime) ? new Date(symbol.PreDisplayTime) : symbol.PreDisplayTime)),
  );

  orderbook$ = (instrument: Instrument, progress?: ProgressService | null): Observable<OrderbookUIData> => {
    // there are two infront observables as properties of the InfrontSDK.Orderbook return value
    // this can't be handled by the sdkservice.getArray$ so we have to use sdk.get directly
    // todo: PR suggestion to SDK
    let unsubscribe: InfrontSDK.Unsubscribe;
    let bidLevelsUnsubscribe: () => void;
    let askLevelsUnsubscribe: () => void;
    return this.sdkService.sdk$.pipe(
      switchMap((sdk) => this.symbolInfo$(instrument).pipe(take(1), map((symbol) => ({ sdk, symbol })))),
      switchMap(({ sdk, symbol }) =>
        new Observable((obs: Subscriber<OrderbookUIData>) => {
          const decimals = getDecimals(symbol);
          const opts = {
            id: instrument,
            subscribe: true,
            onData: (orderbook: InfrontSDK.Orderbook) => {
              bidLevelsUnsubscribe?.();
              askLevelsUnsubscribe?.();
              bidLevelsUnsubscribe = orderbook.bidLevels.observe(this.levelsToObs(obs, orderbook, decimals));
              askLevelsUnsubscribe = orderbook.askLevels.observe(this.levelsToObs(obs, orderbook, decimals));
            },
          };
          unsubscribe = sdk.get(InfrontSDK.orderbook(opts));
        }).pipe(
          trackProgress({ label: 'orderbook$', optional: true, progress }),
          finalize(() => {
            bidLevelsUnsubscribe?.();
            askLevelsUnsubscribe?.();
            unsubscribe();
          })
        )
      )
    );
  };

  constructor(
    private readonly sdkService: SdkService,
    private readonly sdkRequestsService: SdkRequestsService
  ) { }

  private levelsToObs(obs: Subscriber<OrderbookUIData>, orderbook: InfrontSDK.Orderbook, decimals: number) {
    return {
      reInit: () => {
        obs.next(this.levels(orderbook, decimals));
      },
      itemAdded: () => {
        obs.next(this.levels(orderbook, decimals));
      },
      itemRemoved: () => {
        obs.next(this.levels(orderbook, decimals));
      },
      itemChanged: () => obs.next(this.levels(orderbook, decimals)),
    };
  }

  private levels(orderbook: InfrontSDK.Orderbook, decimals: number): OrderbookUIData {
    const levels = [];
    let highestVolume = 0;
    let askLevel: InfrontSDK.OrderbookLevel | undefined;
    let bidLevel: InfrontSDK.OrderbookLevel | undefined;
    let i = 0;
    let totalAsk = 0;
    let totalBid = 0;
    // old fashioned loop in order to just loop once
    while (orderbook.askLevels.data.length > i || orderbook.bidLevels.data.length > i) {
      if (orderbook.askLevels.data.length > i) {
        askLevel = orderbook.askLevels.data[i];
        highestVolume = Math.max(highestVolume, askLevel?.volume ?? 0);
        totalAsk += askLevel?.volume ?? 0;
      } else {
        askLevel = undefined;
      }
      if (orderbook.bidLevels.data.length > i) {
        bidLevel = orderbook.bidLevels.data[i];
        highestVolume = Math.max(highestVolume, bidLevel?.volume ?? 0);
        totalBid += bidLevel?.volume ?? 0;
      } else {
        bidLevel = undefined;
      }
      levels.push({ askLevel, bidLevel });
      i++;
    }

    let spread = undefined;
    if (levels.length && levels[0].askLevel?.price && levels[0].bidLevel?.price) {
      const unitChange = levels[0].askLevel?.price - levels[0].bidLevel.price;
      spread = (unitChange * 100) / levels[0].askLevel?.price;
    }

    return { levels, highestVolume, bidAskRatio: (totalBid * 100) / (totalBid + totalAsk), spread, decimals };
  }
}
