import { Infront, InfrontSDK } from '@infront/sdk';
import type { CellClassParams, ITooltipParams, ValueGetterParams } from 'ag-grid-community';
import { ADV_PERCENT_HIGHLIGHT_LEVEL, advPercentTooltip, downColor, getAdvPercent, getUnderlyingData, highlightByADVpercent, isSubHeaderRow, symbolStatusColor, symbolStatusTooltip, tickerClickableCellClass, timeOrDateOutdated, upDownColor } from '../../shared/grid/column-registry';
import type { Column } from '../../shared/grid/columns.model';
import type { SymbolDataItem } from '../../shared/models/symbol-data.model';
import type { ColumnSetting } from '../../state-model/grid.model';
import { couponsPerYearFromFrequency } from '../../util/bonds';
import { dateFromYYYYMMDD, daysDiffFromYYYYMMDD } from '../../util/date';
import { advPercent, highlightAboveValue, sharpeValueGetter } from '../../util/grid';
import type { translator } from '../../util/locale';
import { addCSV } from '../../util/string';
import type { AvailableColumns } from '../../wrappers/grid-wrappers/grid-wrappers.model';

const ColumnDefs = {
  ticker: {
    colId: 'ticker',
    headerName: 'Symbol',
    field: InfrontSDK.SymbolField.Ticker,
    cellClass: tickerClickableCellClass,
    width: 94,
  },
  // tempSort: {
  //   // debug
  //   colId: 'tempSort',
  //   headerName: 'temp sort',
  //   field: InfrontSDK.SymbolField.SortNumber,
  //   //type: 'leftAligned',
  // },
  fullName: {
    colId: 'fullName',
    headerName: 'Name',
    field: InfrontSDK.SymbolField.FullName,
    width: 191,
  },
  timeSeries30days: {
    colId: 'timeSeries30days', // todo: can be dynamic later
    headerName: '30 days',
    sortable: false,
    resizable: false,
    field: InfrontSDK.SymbolField.PreLastTradedAt,
    cellRenderer: 'timeSeriesComponent',
    width: 130,
  },
  lastValid: {
    colId: 'lastValid',
    headerName: 'Last',
    field: InfrontSDK.SymbolField.PreLastTradedAt,
    type: 'rightAligned',
    valueFormatter: 'sdkDecimals',
    width: 76,
    cellRenderer: 'cellFlashComponent',
    tooltipValueGetter: (params: ITooltipParams) => {
      return symbolStatusTooltip(params, InfrontSDK.SymbolField.DisplaySymbolStatusCode);
    },
    cellClass: (params: CellClassParams) => {
      const defaultClassList = ['ag-right-aligned-cell'];
      if (params?.context?.tradingState?.isConnected && params?.data?.symbol?.isTradable) {
        defaultClassList.push('wt-grid--clickable');
      }
      const preDisplayTime = (params?.data as SymbolDataItem)?.symbol?.get(InfrontSDK.SymbolField.PreDisplayTime) as Date;
      if (!preDisplayTime) {
        return defaultClassList;
      }
      const data = {
        value: preDisplayTime,
        isNotTodayClassList: ['grid__cell--outdated'],
      };
      return [symbolStatusColor(params, InfrontSDK.SymbolField.DisplaySymbolStatusCode), ...timeOrDateOutdated(data), ...defaultClassList];
    },
  },
  listsVWAP: {
    colId: 'listsVWAP',
    headerName: 'VWAP',
    field: InfrontSDK.SymbolField.VWAP,
    type: 'rightAligned',
    valueFormatter: 'sdkDecimals',
    width: 76,
  },
  listsChange: {
    colId: 'listsChange',
    headerName: '±',
    field: InfrontSDK.SymbolField.Change,
    type: 'rightAligned',
    valueFormatter: 'sdkDecimals',
    cellClass: (params: { value: number; }) => {
      return [...upDownColor(params), 'ag-right-aligned-cell'];
    },
    width: 70,
  },
  listsPctChange: {
    colId: 'listsPctChange',
    headerName: '%',
    field: InfrontSDK.SymbolField.ChangePercent,
    type: 'rightAligned',
    valueFormatter: 'twoDecimalsPercent',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 72,
  },
  listsUnderlyingPctChange: {
    colId: 'listsUnderlyingPctChange',
    headerName: '%',
    valueGetter: (params: ValueGetterParams) => getUnderlyingData(params, InfrontSDK.SymbolField.ChangePercent),
    type: 'rightAligned',
    valueFormatter: 'twoDecimalsPercent',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 72,
  },
  listsHigh: {
    colId: 'listsHigh',
    headerName: 'High',
    field: InfrontSDK.SymbolField.High,
    type: 'rightAligned',
    valueFormatter: 'sdkDecimals',
    width: 76,
  },
  listsLow: {
    colId: 'listsLow',
    headerName: 'Low',
    field: InfrontSDK.SymbolField.Low,
    type: 'rightAligned',
    valueFormatter: 'sdkDecimals',
    width: 76,
  },
  listsBid: {
    colId: 'listsBid',
    headerName: 'Bid',
    field: InfrontSDK.SymbolField.Bid,
    type: 'rightAligned',
    valueFormatter: 'sdkDecimals',
    width: 76,
    cellRenderer: 'cellFlashComponent',
    tooltipValueGetter: (params: ITooltipParams) => {
      return symbolStatusTooltip(params, InfrontSDK.SymbolField.DisplaySymbolStatusCode);
    },
    cellClass: (params: CellClassParams) => {
      const defaultClassList = ['ag-right-aligned-cell'];
      if (params?.context?.tradingState?.isConnected && params?.data?.symbol?.isTradable) {
        defaultClassList.push('wt-grid--clickable');
      }
      return [symbolStatusColor(params, InfrontSDK.SymbolField.DisplaySymbolStatusCode), ...defaultClassList];
    },
  },
  listsAsk: {
    colId: 'listsAsk',
    headerName: 'Ask',
    field: InfrontSDK.SymbolField.Ask,
    type: 'rightAligned',
    valueFormatter: 'sdkDecimals',
    width: 76,
    cellRenderer: 'cellFlashComponent',
    tooltipValueGetter: (params: ITooltipParams) => {
      return symbolStatusTooltip(params, InfrontSDK.SymbolField.DisplaySymbolStatusCode);
    },
    cellClass: (params: CellClassParams) => {
      const defaultClassList = ['ag-right-aligned-cell'];
      if (params?.context?.tradingState?.isConnected && params?.data?.symbol?.isTradable) {
        defaultClassList.push('wt-grid--clickable');
      }
      return [symbolStatusColor(params, InfrontSDK.SymbolField.DisplaySymbolStatusCode), ...defaultClassList];
    },
  },
  listsVolume: {
    colId: 'listsVolume',
    headerName: 'Volume',
    field: InfrontSDK.SymbolField.AccumulatedVolume,
    type: 'rightAligned',
    cellRendererParams: {
      classGetter: highlightByADVpercent
    },
    cellRenderer: 'bigNumberCellComponent',
    width: 84,
    tooltipValueGetter: advPercentTooltip,

  },
  listsTurnover: {
    colId: 'listsTurnover',
    headerName: 'Turnover',
    field: InfrontSDK.SymbolField.Turnover,
    type: 'rightAligned',
    cellRenderer: 'bigNumberCellComponent',
    cellRendererParams: {
      classGetter: highlightByADVpercent
    },
    tooltipValueGetter: advPercentTooltip,
    width: 89,
  },
  listsOpen: {
    colId: 'listsOpen',
    headerName: 'Open',
    field: InfrontSDK.SymbolField.Open,
    type: 'rightAligned',
    valueFormatter: 'sdkDecimals',
    width: 76,
  },
  time: {
    colId: 'time',
    headerName: 'Time',
    field: InfrontSDK.SymbolField.PreDisplayTime,
    cellClass: (params: { value: Date; }) => [
      ...timeOrDateOutdated({ value: params.value, isNotTodayClassList: ['grid__cell--outdated'] }),
      'ag-right-aligned-cell',
    ],
    // type used only for cell-header when using custom cellClass
    type: 'rightAligned',
    valueFormatter: 'timeOrDateFormatter',
    width: 76,
  },
  date: {
    colId: 'date',
    headerName: 'Date',
    field: InfrontSDK.SymbolField.Date,
    cellClass: (params: { value: Date; }) => [
      ...timeOrDateOutdated({ value: params.value, isNotTodayClassList: ['grid__cell--outdated'] }),
      'ag-right-aligned-cell',
    ],
    type: 'rightAligned',
    valueFormatter: 'dateFormatter',
    width: 76,
  },
  prvClose: {
    colId: 'prvClose',
    headerName: 'Prv Close',
    field: InfrontSDK.SymbolField.YesterdayClose,
    type: 'rightAligned',
    valueFormatter: 'sdkDecimals',
    width: 76,
  },
  shareCap: {
    colId: 'shareCap',
    headerName: 'Share Cap',
    field: InfrontSDK.SymbolField.ShareCap,
    type: 'rightAligned',
    cellRenderer: 'bigNumberCellComponent',
    width: 98,
  },
  shareCapChange: {
    colId: 'shareCapChange',
    headerName: 'Share Cap %',
    field: InfrontSDK.SymbolField.ShareCapChange,
    type: 'rightAligned',
    cellRenderer: 'bigNumberCellComponent',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 107,
  },
  flagAndFullName: {
    colId: 'flagAndFullName',
    headerName: 'Name',
    field: InfrontSDK.SymbolField.FullName,
    headerTooltip: 'Flag + Ticker',
    width: 194,
    cellRenderer: 'countryDisplayNameCellComponent',
    longHeaderName: 'Flag + Name',
  },
  countryFlagTicker: {
    colId: 'countryFlagTicker',
    headerName: 'Symbol',
    cellClass: tickerClickableCellClass,
    //field: InfrontSDK.SymbolField.CountryOfIncorporation,
    // do not use "field: something" here, else sorting will not work!
    //valueGetter: (params: ValueGetterParams) => getData(params, InfrontSDK.SymbolField.Ticker),

    cellRenderer: 'countryFlagTickerCellComponent',
    suppressMovable: false,
    resizable: true,
    sortable: true,
    wrapText: false,
    width: 96,
    longHeaderName: 'Flag + Symbol',
  },
  //lists static

  ISIN: {
    colId: 'ISIN',
    headerName: 'ISIN',
    field: InfrontSDK.SymbolField.ISIN,
    width: 120,
  },
  listsCurrency: {
    colId: 'listsCurrency',
    headerName: 'Ccy',
    field: InfrontSDK.SymbolField.Currency,
    type: 'rightAligned',
    width: 89,
    longHeaderName: 'Currency',
  },
  infrontSector: {
    colId: 'infrontSector',
    headerName: 'Sector',
    field: InfrontSDK.SymbolField.InfrontSectorName, // todo: right field?
    width: 269,
  },
  listsType: {
    colId: 'listsType',
    headerName: 'Type',
    width: 68,
    valueFormatter: 'translate',
    valueGetter: (params: ValueGetterParams<SymbolDataItem, unknown>) => {
      const symbol = params.data?.symbol;
      if (!symbol) {
        return undefined;
      }

      const symbolType = symbol.get(InfrontSDK.SymbolField.SymbolType);

      if (symbolType) {
        return `SDK.SYMBOL_TYPE.${symbolType}`;
      }

      return undefined;
    },
  },
  listsSubType: {
    colId: 'listsSubType',
    headerName: 'SubType',
    width: 113,
    valueFormatter: 'translate',
    valueGetter: (params: ValueGetterParams<SymbolDataItem, unknown>) => {
      const symbol = params.data?.symbol;
      if (!symbol) {
        return undefined;
      }

      const symbolSubType = symbol.get(InfrontSDK.SymbolField.SymbolSubType);

      if (symbolSubType) {
        return `SDK.SYMBOL_SUB_TYPE.${symbolSubType}`;
      }

      return undefined;
    },
  },
  ESMAType: {
    colId: 'ESMAType',
    headerName: 'ESMA',
    field: InfrontSDK.SymbolField.ESMAType,
    width: 96,
  },
  feed: {
    colId: 'listsFeed',
    headerName: 'Feed#',
    field: InfrontSDK.SymbolField.Feed,
    width: 54,
  },
  feedCode: {
    colId: 'listsFeedCode',
    headerName: 'Feed',
    field: InfrontSDK.SymbolField.FeedExchange,
    width: 54,
  },
  feedName: {
    colId: 'listsFeedName',
    headerName: 'Feed Name',
    field: InfrontSDK.SymbolField.FeedDesc,
    width: 84,
    tooltipValueGetter: (params) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return params.value;
    },
  },
  symClass: {
    colId: 'listsSymClass',
    headerName: 'SymClass',
    field: InfrontSDK.SymbolField.SymbolClassification,
    width: 76,
    //headerTooltip: 'Symbol Classification',
    tooltipValueGetter: (params) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const symbol: InfrontSDK.SymbolData = params.data?.symbol;
      if (!symbol) {
        return '';
      }
      const symbolType = symbol.get(InfrontSDK.SymbolField.SymbolType);
      const symbolSubType = symbol.get(InfrontSDK.SymbolField.SymbolSubType);
      const access = symbol.get(InfrontSDK.SymbolField.FeedAccess);
      const accessDesc = symbol.get(InfrontSDK.SymbolField.FeedAccessDesc);
      return `${symbolType}${addCSV(symbolSubType)}${addCSV(access)}${addCSV(accessDesc)}`;
    },
    longHeaderName: 'Symbol Classification',
  },
  access: {
    colId: 'access',
    headerName: 'Access',
    field: InfrontSDK.SymbolField.FeedAccess,
    width: 84,
  },
  displaySymbolStatus: {
    colId: 'displaySymbolStatus',
    headerName: 'Status',
    field: InfrontSDK.SymbolField.DisplaySymbolStatus,
    type: 'rightAligned',
    cellRenderer: 'cellSymbolStatusComponent',
    width: 84,
    longHeaderName: 'Symbol Status',
  },
  advancedSymbolStatus: {
    colId: 'advancedSymbolStatus',
    headerName: 'Status Adv',
    field: InfrontSDK.SymbolField.PreSymbolStatusText,
    width: 84,
    longHeaderName: 'Advanced Symbol Status',
  },

  // lists Performance

  change1W: {
    colId: 'change1W',
    headerName: 'Change 1W',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreChange1W,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 102,
    // sortable: false,
  },
  change1M: {
    colId: 'change1M',
    headerName: 'Change 1M',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreChange1M,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 102,
  },
  change3M: {
    colId: 'change3M',
    headerName: 'Change 3M',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreChange3M,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 102,
  },
  change6M: {
    colId: 'change6M',
    headerName: 'Change 6M',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreChange6M,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 102,
  },
  changeYTD: {
    colId: 'changeYTD',
    headerName: 'Change YTD',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreChangeYearToDate,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 107,
  },
  change1Y: {
    colId: 'change1Y',
    headerName: 'Change 1Y',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreChange1Y,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 99,
  },
  change2Y: {
    colId: 'change2Y',
    headerName: 'Change 2Y',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreChange2Y,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 99,
  },
  change3Y: {
    colId: 'change3Y',
    headerName: 'Change 3Y',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreChange3Y,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 99,
  },
  change5Y: {
    colId: 'change5Y',
    headerName: 'Change 5Y',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreChange5Y,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 99,
  },

  pctChange1W: {
    colId: 'pctChange1W',
    headerName: '1W %',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreChangePercent1W,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 79,
  },
  pctChange1M: {
    colId: 'pctChange1M',
    headerName: '1M %',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreChangePercent1M,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 79,
  },
  pctChange3M: {
    colId: 'pctChange3M',
    headerName: '3M %',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreChangePercent3M,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 79,
  },
  pctChange6M: {
    colId: 'pctChange6M',
    headerName: '6M %',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreChangePercent6M,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 82,
  },
  pctChangeYTD: {
    colId: 'pctChangeYTD',
    headerName: 'YTD %',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreChangePercentYearToDate,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 82,
  },
  pctChange1Y: {
    colId: 'pctChange1Y',
    headerName: '1Y %',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreChangePercent1Y,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 82,
  },
  pctChange2Y: {
    colId: 'pctChange2Y',
    headerName: '2Y %',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreChangePercent2Y,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 82,
  },
  pctChange3Y: {
    colId: 'pctChange3Y',
    headerName: '3Y %',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreChangePercent3Y,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 82,
  },
  pctChange5Y: {
    colId: 'pctChange5Y',
    headerName: '5Y %',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreChangePercent5Y,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 82,
  },

  totalReturnChange1W: {
    colId: 'totalReturnChange1W',
    headerName: 'TR 1W',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreTotalReturnChange1W,
    headerTooltip: 'Total return columns include dividends.',
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 125,
    longHeaderName: 'Total Return 1W',
  },
  totalReturnChange1M: {
    colId: 'totalReturnChange1M',
    headerName: 'TR 1M',
    valueFormatter: 'twoDecimals',
    headerTooltip: 'Total return columns include dividends.',
    field: InfrontSDK.SymbolField.PreTotalReturnChange1M,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 125,
    longHeaderName: 'Total Return 1M',
  },
  totalReturnChange3M: {
    colId: 'totalReturnChange3M',
    headerName: 'TR 3M',
    valueFormatter: 'twoDecimals',
    headerTooltip: 'Total return columns include dividends.',
    field: InfrontSDK.SymbolField.PreTotalReturnChange3M,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 125,
    longHeaderName: 'Total Return 3M',
  },
  totalReturnChange6M: {
    colId: 'totalReturnChange6M',
    headerName: 'TR 6M',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreTotalReturnChange6M,
    headerTooltip: 'Total return columns include dividends.',
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 125,
    longHeaderName: 'Total Return 6M',
  },
  totalReturnChangeYTD: {
    colId: 'totalReturnChangeYTD',
    headerName: 'TR YTD',
    valueFormatter: 'twoDecimals',
    headerTooltip: 'Total return columns include dividends.',
    field: InfrontSDK.SymbolField.PreTotalReturnChangeYTD,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 130,
    longHeaderName: 'Total Return YTD',
  },
  totalReturnChange1Y: {
    colId: 'totalReturnChange1Y',
    headerName: 'TR 1Y',
    valueFormatter: 'twoDecimals',
    headerTooltip: 'Total return columns include dividends.',
    field: InfrontSDK.SymbolField.PreTotalReturnChange1Y,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 122,
    longHeaderName: 'Total Return 1Y',
  },
  totalReturnChange2Y: {
    colId: 'totalReturnChange2Y',
    headerName: 'TR 2Y',
    valueFormatter: 'twoDecimals',
    headerTooltip: 'Total return columns include dividends.',
    field: InfrontSDK.SymbolField.PreTotalReturnChange2Y,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 122,
    longHeaderName: 'Total Return 2Y',
  },
  totalReturnChange3Y: {
    colId: 'totalReturnChange3Y',
    headerName: 'TR 3Y',
    valueFormatter: 'twoDecimals',
    headerTooltip: 'Total return columns include dividends.',
    field: InfrontSDK.SymbolField.PreTotalReturnChange3Y,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 122,
    longHeaderName: 'Total Return 3Y',
  },
  totalReturnChange5Y: {
    colId: 'totalReturnChange5Y',
    headerName: 'TR 5Y',
    valueFormatter: 'twoDecimals',
    headerTooltip: 'Total return columns include dividends.',
    field: InfrontSDK.SymbolField.PreTotalReturnChange5Y,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 122,
    longHeaderName: 'Total Return 5Y',
  },
  totalReturnPctChange1W: {
    colId: 'totalReturnPctChange1W',
    headerName: 'TR 1W %',
    valueFormatter: 'twoDecimalsPercent',
    headerTooltip: 'Total return columns include dividends.',
    field: InfrontSDK.SymbolField.PreTotalReturnChangePct1W,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 136,
    longHeaderName: 'Total Return 1W %',
  },
  totalReturnPctChange1M: {
    colId: 'totalReturnPctChange1M',
    headerName: 'TR 1M %',
    valueFormatter: 'twoDecimalsPercent',
    headerTooltip: 'Total return columns include dividends.',
    field: InfrontSDK.SymbolField.PreTotalReturnChangePct1M,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 136,
    longHeaderName: 'Total Return 1M %',
  },
  totalReturnPctChange3M: {
    colId: 'totalReturnPctChange3M',
    headerName: 'TR 3M %',
    valueFormatter: 'twoDecimalsPercent',
    headerTooltip: 'Total return columns include dividends.',
    field: InfrontSDK.SymbolField.PreTotalReturnChangePct3M,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 136,
    longHeaderName: 'Total Return 3M %',
  },
  totalReturnPctChange6M: {
    colId: 'totalReturnPctChange6M',
    headerName: 'TR 6M %',
    valueFormatter: 'twoDecimalsPercent',
    headerTooltip: 'Total return columns include dividends.',
    field: InfrontSDK.SymbolField.PreTotalReturnChangePct6M,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 136,
    longHeaderName: 'Total Return 6M %',
  },
  totalReturnPctChangeYTD: {
    colId: 'totalReturnPctChangeYTD',
    headerName: 'TR YTD %',
    valueFormatter: 'twoDecimalsPercent',
    headerTooltip: 'Total return columns include dividends.',
    field: InfrontSDK.SymbolField.PreTotalReturnChangePctYTD,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 141,
    longHeaderName: 'Total Return YTD %',
  },
  totalReturnPctChange1Y: {
    colId: 'totalReturnPctChange1Y',
    headerName: 'TR 1Y %',
    valueFormatter: 'twoDecimalsPercent',
    headerTooltip: 'Total return columns include dividends.',
    field: InfrontSDK.SymbolField.PreTotalReturnChangePct1Y,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 133,
    longHeaderName: 'Total Return 1Y %',
  },
  totalReturnPctChange2Y: {
    colId: 'totalReturnPctChange2Y',
    headerName: 'TR 2Y %',
    valueFormatter: 'twoDecimalsPercent',
    headerTooltip: 'Total return columns include dividends.',
    field: InfrontSDK.SymbolField.PreTotalReturnChangePct2Y,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 133,
    longHeaderName: 'Total Return 2Y %',
  },
  totalReturnPctChange3Y: {
    colId: 'totalReturnPctChange3Y',
    headerName: 'TR 3Y %',
    valueFormatter: 'twoDecimalsPercent',
    headerTooltip: 'Total return columns include dividends.',
    field: InfrontSDK.SymbolField.PreTotalReturnChangePct3Y,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 133,
    longHeaderName: 'Total Return 3Y %',
  },
  totalReturnPctChange5Y: {
    colId: 'totalReturnPctChange5Y',
    headerName: 'TR 5Y %',
    valueFormatter: 'twoDecimalsPercent',
    headerTooltip: 'Total return columns include dividends.',
    field: InfrontSDK.SymbolField.PreTotalReturnChangePct5Y,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 133,
    longHeaderName: 'Total Return 5Y %',
  },
  fiveMin: {
    colId: 'fiveMin',
    headerName: '5 Min',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.Last5MinsAgo,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...upDownColor(params, 0), 'ag-right-aligned-cell'],
    width: 80,
  },
  fiveMinPct: {
    colId: 'fiveMinPct',
    headerName: '5 Min %',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.Last5MinsAgo,
    type: 'rightAligned',
    valueGetter: (params: ValueGetterParams) => {
      if (!params.data?.Last5MinsAgo) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
      const last = params.data.PreLastTradedAt ?? params.data.symbol.get(InfrontSDK.SymbolField.PreLastTradedAt);
      const last5Percent = ((params.data.Last5MinsAgo - last) / last) * 100;
      return last5Percent;
    },
    cellClass: (params: { value: number; }) => [...upDownColor(params, 0), 'ag-right-aligned-cell'],
    width: 80,
  },
  listsSpread: {
    colId: 'listsSpread',
    headerName: 'Spread',
    headerTooltip: 'Formula: Ask - Bid',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.PreSpread,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...downColor(params, 0), 'ag-right-aligned-cell'],
    width: 80,
  },
  listsSpreadPct: {
    colId: 'listsSpreadPct',
    headerName: 'Spread %',
    valueFormatter: 'twoDecimalsPercent',
    headerTooltip: 'Formula (Ask – Bid) / Bid',
    field: InfrontSDK.SymbolField.PreSpreadPct,
    type: 'rightAligned',
    cellClass: (params: { value: number; }) => [...downColor(params), 'ag-right-aligned-cell'],
    width: 91,
  },
  gap: {
    colId: 'gap',
    headerName: 'Gap',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.PreGap,
    type: 'rightAligned',
    width: 70,
    headerTooltip: 'Formula: Open - PrevClose',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
  },

  gapPct: {
    colId: 'gapPct',
    headerName: 'Gap %',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreGapPct,
    type: 'rightAligned',
    width: 76,
    headerTooltip: 'Formula: (Open – PrevClose) / PrevClose',
    cellClass: (params: { value: number; }) => [...upDownColor(params), 'ag-right-aligned-cell'],
  },
  offHighPct: {
    colId: 'offHighPct',
    headerName: 'Off High %',
    headerTooltip: 'Formula: (High – Last) / Last',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreOffHighPct,
    type: 'rightAligned',
    width: 98,
  },
  offLowPct: {
    colId: 'offLowPct',
    headerName: 'Off Low %',
    valueFormatter: 'twoDecimalsPercent',
    headerTooltip: 'Formula: (Last – Low) / Last',
    field: InfrontSDK.SymbolField.PreOffLowPct,
    type: 'rightAligned',
    width: 95,
  },

  range: {
    colId: 'range',
    headerName: 'Range',
    valueFormatter: 'sdkDecimals',
    headerTooltip: 'Formula: High - Low',
    field: InfrontSDK.SymbolField.PreRange,
    type: 'rightAligned',
    width: 76,
  },
  rangePct: {
    colId: 'rangePct',
    headerName: 'Range %',
    headerTooltip: 'Formula: (High – Low) / Low',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreRangePct,
    type: 'rightAligned',
    width: 87,
  },
  ATR30: {
    colId: 'ATR30',
    headerName: 'ATR30',
    headerTooltip: 'Average True Range 30 Days',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.AverageTrueRange,
    type: 'rightAligned',
    width: 77,
    longHeaderName: 'Average True Range 30 Days',
  },
  averageDailyVolume: {
    colId: 'averageDailyVolume',
    headerName: 'ADV',
    headerTooltip: 'ADV shows the average daily volume for the instrument for the last 30 days.',
    field: InfrontSDK.SymbolField.AverageDailyVolume,
    type: 'rightAligned',
    width: 50,
    cellRendererParams: {
      classGetter: highlightByADVpercent
    },
    cellRenderer: 'bigNumberCellComponent',
    longHeaderName: 'Average Daily Volume',
  },
  averageDailyVolumeMean: {
    colId: 'averageDailyVolumeMean',
    headerName: 'ADV 30D',
    headerTooltip: 'ADV shows the average daily volume for the instrument for the last 30 days.',
    field: InfrontSDK.SymbolField.AverageDailyVolumeMean,
    type: 'rightAligned',
    width: 71,
    cellRenderer: 'bigNumberCellComponent',
  },
  averageDailyVolumeFactor: {
    colId: 'averageDailyVolumeFactor',
    headerName: 'ADV',
    headerTooltip: 'ADV Factor compares the intraday volume today against the average daily volume.',
    field: InfrontSDK.SymbolField.AverageDailyVolume,
    type: 'rightAligned',
    width: 50,
    valueGetter: (params: ValueGetterParams) => {
      const accVolume = (params?.data as SymbolDataItem)?.symbol?.get(InfrontSDK.SymbolField.AccumulatedVolume);
      const adv = params.data?.[InfrontSDK.SymbolField.AverageDailyVolume] as number;
      if (!accVolume || !adv) {
        return undefined;
      }
      return advPercent(accVolume, adv);
    },
    valueFormatter: 'twoDecimalsOrIntegerPercent',
    cellClass: (params: { value: number; }) => [highlightAboveValue(params.value, ADV_PERCENT_HIGHLIGHT_LEVEL), 'ag-right-aligned-cell'],
    longHeaderName: 'ADV Factor',
  },


  realTimeAverageDailyVolumeFactor: {
    colId: 'realTimeAverageDailyVolumeFactor',
    headerName: 'RT ADV',
    headerTooltip:
      'Realtime ADV Factor compares the intraday volume today with the Realtime ADV. If over 1.0x it means there is higher volume than normal based on the last 30 days.',
    field: InfrontSDK.SymbolField.RealtimeAverageDailyVolume,
    type: 'rightAligned',
    width: 157,
    valueFormatter: 'twoDecimalsOrIntegerPercent',
    valueGetter: getAdvPercent,
    cellClass: (params: { value: number; }) => [highlightAboveValue(params.value, ADV_PERCENT_HIGHLIGHT_LEVEL), 'ag-right-aligned-cell'],
    longHeaderName: 'Realtime ADV Factor',
  },

  //Esg
  esgTotalScore: {
    colId: 'esgTotalScore',
    headerName: 'ESG',
    headerTooltip: 'ESG Total Score',
    valueFormatter: 'integer',
    field: InfrontSDK.SymbolField.EsgTotalScore,
    type: 'rightAligned',
    width: 75,
    longHeaderName: 'ESG Total Score',
  },
  esgEnvironmentalScore: {
    colId: 'esgEnvironmentalScore',
    headerName: 'Env.',
    valueFormatter: 'integer',
    headerTooltip: 'Environmental Score',
    field: InfrontSDK.SymbolField.EsgEnvironmentalScore,
    type: 'rightAligned',
    width: 75,
    longHeaderName: 'Environmental Score',
  },
  esgSocialScore: {
    colId: 'esgSocialScore',
    headerName: 'Soc.',
    valueFormatter: 'integer',
    headerTooltip: 'Social Score',
    field: InfrontSDK.SymbolField.EsgSocialScore,
    type: 'rightAligned',
    width: 75,
    longHeaderName: 'Social Score',
  },
  esgCorporateGovernanceScore: {
    colId: 'esgCorporateGovernanceScore',
    headerName: 'Gov.',
    headerTooltip: 'Corporate Governance Score',
    valueFormatter: 'integer',
    field: InfrontSDK.SymbolField.EsgCorporateGovernanceScore,
    type: 'rightAligned',
    width: 75,
    longHeaderName: 'Corporate Governance Score',
  },


  //Technicals
  movingAverage50: {
    colId: 'movingAverage50',
    headerName: 'MA 50',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.MovingAverage50,
    type: 'rightAligned',
    width: 77,
  },
  movingAverage100: {
    colId: 'movingAverage100',
    headerName: 'MA 100',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.MovingAverage100,
    type: 'rightAligned',
    width: 84,
  },
  movingAverage200: {
    colId: 'movingAverage200',
    headerName: 'MA 200',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.MovingAverage200,
    type: 'rightAligned',
    width: 84,
  },
  rsi14: {
    colId: 'rsi14',
    headerName: 'RSI 14',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.RSI14,
    type: 'rightAligned',
    width: 77,
  },
  VWAP30: {
    colId: 'VWAP30',
    headerName: 'VWAP30',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.VolumeWeightedAveragePrice30,
    type: 'rightAligned',
    width: 88,
  },
  VWAP60: {
    colId: 'VWAP60',
    headerName: 'VWAP60',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.VolumeWeightedAveragePrice60,
    type: 'rightAligned',
    width: 88,
  },
  VWAP90: {
    colId: 'VWAP90',
    headerName: 'VWAP90',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.VolumeWeightedAveragePrice90,
    type: 'rightAligned',
    width: 88,
  },

  //Derivatives
  expireDate: {
    colId: 'expireDate',
    headerName: 'Expire Date',
    valueFormatter: 'dateFormatter',
    field: InfrontSDK.SymbolField.Expiry,
    type: 'rightAligned',
    width: 102,
  },
  strikePrice: {
    colId: 'strikePrice',
    headerName: 'Strike Price',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.StrikePrice,
    type: 'rightAligned',
    width: 103,
  },


  // funds

  sharpeRatio1Y: {
    colId: 'sharpeRatio1Y',
    headerName: 'Sharpe 1Y',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.FundSharpes,
    type: 'rightAligned',
    width: 103,
    valueGetter: sharpeValueGetter(InfrontSDK.SymbolField.FundSharpeRatio1Y),
    longHeaderName: 'Sharpe ratio 1Y',
  },
  sharpeRatio3Y: {
    colId: 'sharpeRatio3Y',
    headerName: 'Sharpe 3Y',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.FundSharpeRatio3Y,
    type: 'rightAligned',
    width: 103,
    valueGetter: sharpeValueGetter(InfrontSDK.SymbolField.FundSharpeRatio3Y),
    longHeaderName: 'Sharpe ratio 3Y',
  },
  sharpeRatio5Y: {
    colId: 'sharpeRatio5Y',
    headerName: 'Sharpe 5Y',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.FundSharpeRatio5Y,
    type: 'rightAligned',
    width: 103,
    valueGetter: sharpeValueGetter(InfrontSDK.SymbolField.FundSharpeRatio5Y),
    longHeaderName: 'Sharpe ratio 5Y',
  },
  stdDev1y: {
    colId: 'stdDev1y',
    headerName: 'Std dev 1Y',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.FundStdDev1Year,
    type: 'rightAligned',
    width: 103,
  },
  stdDev3y: {
    colId: 'stdDev3y',
    headerName: 'Std dev 3Y',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.FundStdDev3Years,
    type: 'rightAligned',
    width: 103,
  },
  stdDev5y: {
    colId: 'stdDev5y',
    headerName: 'Std dev 5Y',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.FundStdDev5Years,
    type: 'rightAligned',
    width: 103,
  },
  trackingError12M: {
    colId: 'trackingError12M',
    headerName: 'Tracking 1Y',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.FundTrackingError12M,
    type: 'rightAligned',
    width: 103,
    longHeaderName: 'Tracking Error 1Y',
  },
  alpha12M: {
    colId: 'alpha12M',
    headerName: 'Alpha 1Y',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.FundAlpha12M,
    type: 'rightAligned',
    width: 103,
  },
  beta12M: {
    colId: 'beta12M',
    headerName: 'Beta 1Y',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.FundBeta12M,
    type: 'rightAligned',
    width: 103,
  },
  informationRatio12M: {
    colId: 'informationRatio12M',
    headerName: 'Info ratio 1Y',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.FundInformationRatio12M,
    type: 'rightAligned',
    width: 103,
  },
  treynorRatio12M: {
    colId: 'treynorRatio12M',
    headerName: 'Treynor Ratio 1Y',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.FundTreynorRatio12M,
    type: 'rightAligned',
    width: 103,
  },
  // todo suffix with currency shortcode
  minInitInvestment: {
    colId: 'minInitInvestment',
    headerName: 'Min inv',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.FundMinInitInvestment,
    type: 'rightAligned',
    width: 103,
    longHeaderName: 'Min init investment',
  },
  totalAssets: {
    colId: 'totalAssets',
    headerName: 'Total Assets',
    cellRenderer: 'bigNumberCellComponent',
    field: InfrontSDK.SymbolField.FundTotalAssets,
    type: 'rightAligned',
    width: 103,
  },
  totalExpense: {
    colId: 'totalExpense',
    headerName: 'Total Expense',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.FundTotalExpense,
    type: 'rightAligned',
    width: 90,
  },
  ongoingCharge: {
    colId: 'ongoingCharge',
    headerName: 'Ongoing Charge',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.FundOngoingCharge,
    type: 'rightAligned',
    width: 90,
  },
  morningstarRating: {
    colId: 'fundStarRating',
    headerName: 'Morningstar Rating',
    field: InfrontSDK.SearchResultField.FundStarRating,
    width: 103,
    cellRenderer: 'starRatingComponent'
  },
  riskRating: {
    colId: 'riskRating',
    headerName: 'Risk Rating',
    valueFormatter: 'integer',
    field: InfrontSDK.SymbolField.FundRiskLevel,
    type: 'rightAligned',
    width: 103,
  },
  investmentStyle: {
    colId: 'investmentStyle',
    headerName: 'Investment Style',
    field: InfrontSDK.SymbolField.FundInvestmentStyle,
    type: 'rightAligned',
    width: 103,
  },
  primarySegment: {
    colId: 'primarySegment',
    headerName: 'Primary Segment',
    field: InfrontSDK.SymbolField.PrimarySegment,
    type: 'rightAligned',
    width: 103,
  },
  fundType: {
    colId: 'fundType',
    headerName: 'Fund Type',
    field: InfrontSDK.SymbolField.FundType,
    type: 'rightAligned',
    width: 103,
  },
  topHoldingDate: {
    colId: 'topHoldingDate',
    headerName: 'Top Holding Date',
    valueFormatter: 'dateFormatter',
    field: InfrontSDK.SymbolField.FundTopHoldingDate,
    type: 'rightAligned',
    width: 103,
    valueGetter: (params: ValueGetterParams) => {
      if (!params.data?.FundTopHoldingDate) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
      const date = dateFromYYYYMMDD(params?.data?.FundTopHoldingDate);
      return date;
    },
    longHeaderName: 'Top H Date',
  },
  topHoldingDays: {
    colId: 'topHoldingDays',
    headerName: 'Top Holding Days',
    valueFormatter: 'integer',
    field: InfrontSDK.SymbolField.FundTopHoldingDate,
    type: 'rightAligned',
    width: 103,
    valueGetter: (params: ValueGetterParams) => {
      if (!params.data?.FundTopHoldingDate) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
      const daysDiff = daysDiffFromYYYYMMDD(params?.data?.FundTopHoldingDate);
      return daysDiff;
    },
    cellClass: (params: { value: number; }) => [params.value > 99 ? 'grid__cell--negative' : 'grid__cell', 'ag-right-aligned-cell'],
    longHeaderName: 'Top H Days',
  },
  fundPriceEarnings: {
    colId: 'fundPriceEarnings',
    headerName: 'P/E',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.FundPriceEarnings,
    type: 'rightAligned',
    width: 103,
    longHeaderName: 'Fund P/E',
  },
  fundPriceBook: {
    colId: 'fundPriceBook',
    headerName: 'P/B',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.FundPriceBook,
    type: 'rightAligned',
    width: 103,
    longHeaderName: 'Fund P/B',
  },
  prospectiveDividendYield: {
    colId: 'prospectiveDividendYield',
    headerName: 'Div. Yield',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.FundProspectiveDividendYield,
    type: 'rightAligned',
    width: 103,
    longHeaderName: 'Fund Div. Yield',
  },
  startDate: {
    colId: 'startDate',
    headerName: 'Start Date',
    valueFormatter: 'dateFormatter',
    field: InfrontSDK.SymbolField.StartDate,
    type: 'rightAligned',
    width: 103,
    valueGetter: (params: ValueGetterParams) => {
      if (!params.data?.StartDate) {
        return;
      }
      const startDateAsString = params?.data?.StartDate as string;
      const cleanDateNumber = +startDateAsString.replace(/-/g, '');
      if (isNaN(cleanDateNumber)) {
        return;
      }
      const startDate = dateFromYYYYMMDD(cleanDateNumber);
      return startDate;
    },
  },

  // bonds

  issueDate: {
    colId: 'issueDate',
    headerName: 'IssDate',
    longHeaderName: 'Issue Date',
    valueFormatter: 'dateFormatter',
    field: InfrontSDK.SymbolField.IssueDate,
    type: 'rightAligned',
    width: 102,
  },
  maturityDate: {
    colId: 'maturityDate',
    headerName: 'MatDate',
    longHeaderName: 'Maturity Date',
    valueFormatter: 'dateFormatter',
    field: InfrontSDK.SymbolField.MaturityDate,
    type: 'rightAligned',
    width: 102,
  },
  yield: {
    colId: 'yield',
    headerName: 'Yield',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.Yield,
    type: 'rightAligned',
    width: 90,
  },
  couponRate: {
    colId: 'couponRate',
    headerName: 'Cpn',
    longHeaderName: 'Coupon Rate',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.CouponRate,
    type: 'rightAligned',
    width: 90,
  },
  couponsPerYear: {
    colId: 'couponsPerYear',
    headerName: 'Cpn P/Y',
    longHeaderName: 'Coupons per year',
    valueFormatter: 'integer',
    field: InfrontSDK.SymbolField.CouponsPerYear,
    type: 'rightAligned',
    width: 90,
    valueGetter: (params: ValueGetterParams<SymbolDataItem, unknown>) => {
      const symbol = params.data?.symbol;
      const couponsPerYear = symbol?.get(InfrontSDK.SymbolField.CouponsPerYear);
      return couponsPerYearFromFrequency(couponsPerYear);
    },
  },
  duration: {
    colId: 'duration',
    headerName: 'Duration',
    valueFormatter: 'threeDecimals',
    field: InfrontSDK.SymbolField.Duration,
    type: 'rightAligned',
    width: 90,
  },
  outstandingPrincipal: {
    colId: 'outstandingPrincipal',
    headerName: 'OutstPrincipal',
    longHeaderName: 'Outstanding Principal',
    valueFormatter: 'bigDecimal',
    field: InfrontSDK.SymbolField.bondOutstandingAmountFaceValue,
    type: 'rightAligned',
    width: 90,
  },
  amortizationType: {
    colId: 'amortizationType',
    headerName: 'Amortization',
    field: InfrontSDK.SymbolField.AmortizationType,
    type: 'leftAligned',
    width: 90,
  },


  // functional / special

  // "invisible" column required for adding subHeaders (via column definition)
  // must only be used once per table and always be first column!
  subHeaders: {
    colId: 'subHeaders',
    headerName: '',
    suppressMovable: true,
    resizable: false,
    sortable: false,
    wrapText: false,
    maxWidth: 0,
    minWidth: 0,
    width: 0,
    valueGetter: (params: ValueGetterParams) => {
      if (isSubHeaderRow(params)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
        return params.data.translation ?? params.data.value ?? '';
      }
      return '';
    },
    colSpan: (params) => {
      if (isSubHeaderRow(params)) {
        const colCount = params.api.getColumnDefs()?.length ?? 1;
        return colCount;
      }
      return 1;
    },
  },
} as const satisfies AvailableColumns;

export const ListsColumnDefs: {
  [K in keyof typeof ColumnDefs]: Readonly<Column>;
} = ColumnDefs;

const oneWeekAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;

export const WatchListColumnDefs = {
  dateAdded: {
    colId: 'dateAdded',
    headerName: 'Date Added',
    valueFormatter: 'dateFormatter',
    tooltipValueGetter: (params: ITooltipParams<unknown, Date | ''>) => {
      const xlat = params.context?.xlat as ReturnType<typeof translator>;
      const dateAdded = params.value instanceof Date ? params.value.getTime() : undefined;
      return dateAdded && dateAdded > oneWeekAgo && xlat ? xlat('GRID.WATCHLIST.DATE_ADDED.TITLE') : undefined;
    },
    valueGetter: (params: ValueGetterParams<unknown, Date | ''>) => {
      // get the date added value from the watchlist item
      const { Feed, Ticker } = params.data as { Feed: number; Ticker: string; };
      const watchlist = params.context?.watchlist as InfrontSDK.Watchlist;
      const selectedItem = watchlist?.items?.find(item => item.feed === Feed && item.ticker === Ticker);
      return selectedItem?.extra?.[Infront.StorageXmlTags['DateAdded']] ? new Date(selectedItem.extra[Infront.StorageXmlTags['DateAdded']]) : '';
    },
    cellClass: (params: CellClassParams<unknown, Date | ''>) => {
      // Highlight the text if the date is less than a week old
      const dateAdded = params.value instanceof Date ? params.value.getTime() : undefined;
      return dateAdded && dateAdded > oneWeekAgo ? 'ag-right-aligned-cell wt-lists__content--highlight' : 'ag-right-aligned-cell';
    },
    type: 'rightAligned',
    width: 103,
  },
} as const satisfies { [column: string]: Column; };

export const ListsColumns = Object.values(ListsColumnDefs);

export const ListsCategories = [
  {
    name: 'General',
    columns: [
      ListsColumnDefs.ticker,
      ListsColumnDefs.fullName,
      ListsColumnDefs.timeSeries30days,
      ListsColumnDefs.lastValid,
      ListsColumnDefs.listsVWAP,
      ListsColumnDefs.listsChange,
      ListsColumnDefs.listsPctChange,
      ListsColumnDefs.listsHigh,
      ListsColumnDefs.listsLow,
      ListsColumnDefs.listsBid,
      ListsColumnDefs.listsAsk,
      ListsColumnDefs.listsVolume,
      ListsColumnDefs.listsTurnover,
      ListsColumnDefs.listsOpen,
      ListsColumnDefs.time,
      ListsColumnDefs.date,
      ListsColumnDefs.prvClose,
      ListsColumnDefs.shareCap,
      ListsColumnDefs.shareCapChange,
      ListsColumnDefs.flagAndFullName,
      ListsColumnDefs.countryFlagTicker,
      ListsColumnDefs.displaySymbolStatus,
      ListsColumnDefs.advancedSymbolStatus,
    ],
  },
  {
    name: 'Static',
    columns: [
      ListsColumnDefs.ISIN,
      ListsColumnDefs.listsCurrency,
      ListsColumnDefs.infrontSector,
      ListsColumnDefs.listsType,
      ListsColumnDefs.listsSubType,
      ListsColumnDefs.ESMAType,
      ListsColumnDefs.feed,
      ListsColumnDefs.feedCode,
      ListsColumnDefs.feedName,
      ListsColumnDefs.symClass,
      ListsColumnDefs.access
    ],
  },
  {
    name: 'Performance',
    columns: [
      ListsColumnDefs.change1W,
      ListsColumnDefs.change1M,
      ListsColumnDefs.change3M,
      ListsColumnDefs.change6M,
      ListsColumnDefs.changeYTD,
      ListsColumnDefs.change1Y,
      ListsColumnDefs.change2Y,
      ListsColumnDefs.change3Y,
      ListsColumnDefs.change5Y,
      ListsColumnDefs.pctChange1W,
      ListsColumnDefs.pctChange1M,
      ListsColumnDefs.pctChange3M,
      ListsColumnDefs.pctChange6M,
      ListsColumnDefs.pctChangeYTD,
      ListsColumnDefs.pctChange1Y,
      ListsColumnDefs.pctChange2Y,
      ListsColumnDefs.pctChange3Y,
      ListsColumnDefs.pctChange5Y,
      ListsColumnDefs.totalReturnChange1W,
      ListsColumnDefs.totalReturnChange1M,
      ListsColumnDefs.totalReturnChange3M,
      ListsColumnDefs.totalReturnChange6M,
      ListsColumnDefs.totalReturnChangeYTD,
      ListsColumnDefs.totalReturnChange1Y,
      ListsColumnDefs.totalReturnChange2Y,
      ListsColumnDefs.totalReturnChange3Y,
      ListsColumnDefs.totalReturnChange5Y,
      ListsColumnDefs.totalReturnPctChange1W,
      ListsColumnDefs.totalReturnPctChange1M,
      ListsColumnDefs.totalReturnPctChange3M,
      ListsColumnDefs.totalReturnPctChange6M,
      ListsColumnDefs.totalReturnPctChangeYTD,
      ListsColumnDefs.totalReturnPctChange1Y,
      ListsColumnDefs.totalReturnPctChange2Y,
      ListsColumnDefs.totalReturnPctChange3Y,
      ListsColumnDefs.totalReturnPctChange5Y,
    ],
  },
  {
    name: 'Realtime fields',
    columns: [
      ListsColumnDefs.fiveMinPct,
      ListsColumnDefs.listsSpread,
      ListsColumnDefs.listsSpreadPct,
      ListsColumnDefs.gap,
      ListsColumnDefs.gapPct,
      ListsColumnDefs.offHighPct,
      ListsColumnDefs.offLowPct,
      ListsColumnDefs.range,
      ListsColumnDefs.rangePct,
      ListsColumnDefs.ATR30,
      ListsColumnDefs.averageDailyVolume,
      ListsColumnDefs.averageDailyVolumeFactor,
      ListsColumnDefs.realTimeAverageDailyVolumeFactor,
      ListsColumnDefs.averageDailyVolumeMean
    ],
  },
  {
    name: 'ESG',
    columns: [
      ListsColumnDefs.esgTotalScore,
      ListsColumnDefs.esgSocialScore,
      ListsColumnDefs.esgEnvironmentalScore,
      ListsColumnDefs.esgCorporateGovernanceScore,
    ],
  },
  {
    name: 'Technicals',
    columns: [
      ListsColumnDefs.movingAverage50,
      ListsColumnDefs.movingAverage100,
      ListsColumnDefs.movingAverage200,
      ListsColumnDefs.rsi14,
      ListsColumnDefs.VWAP30,
      ListsColumnDefs.VWAP60,
      ListsColumnDefs.VWAP90,
    ],
  },
  {
    name: 'Funds',
    columns: [
      ListsColumnDefs.sharpeRatio1Y,
      ListsColumnDefs.sharpeRatio3Y,
      ListsColumnDefs.sharpeRatio5Y,
      ListsColumnDefs.stdDev1y,
      ListsColumnDefs.stdDev3y,
      ListsColumnDefs.stdDev5y,
      ListsColumnDefs.trackingError12M,
      ListsColumnDefs.alpha12M,
      ListsColumnDefs.beta12M,
      ListsColumnDefs.informationRatio12M,
      ListsColumnDefs.treynorRatio12M,
      ListsColumnDefs.minInitInvestment,
      ListsColumnDefs.totalAssets,
      ListsColumnDefs.totalExpense,
      ListsColumnDefs.ongoingCharge,
      ListsColumnDefs.fundPriceBook,
      ListsColumnDefs.fundPriceEarnings,
      ListsColumnDefs.morningstarRating,
      ListsColumnDefs.riskRating,
      ListsColumnDefs.investmentStyle,
      ListsColumnDefs.primarySegment,
      ListsColumnDefs.fundType,
      ListsColumnDefs.topHoldingDate,
      ListsColumnDefs.topHoldingDays,
      ListsColumnDefs.startDate,
    ],
  },
  {
    name: 'Derivatives',
    columns: [ListsColumnDefs.expireDate, ListsColumnDefs.strikePrice],
  },
  {
    name: 'Bonds',
    columns: [
      ListsColumnDefs.issueDate,
      ListsColumnDefs.maturityDate,
      ListsColumnDefs.yield,
      ListsColumnDefs.couponRate,
      ListsColumnDefs.couponsPerYear,
      ListsColumnDefs.duration,
      ListsColumnDefs.outstandingPrincipal,
      ListsColumnDefs.amortizationType,
    ],
  },
];

export const SelectedListColumns: ColumnSetting[] = [
  { colId: ListsColumnDefs.ticker.colId }, // we should only store colId, width, sort as user specific setting, default is then only colId
  //{ colId: ListsColumnDefs.tempSort.colId }, // for sort debugging
  { colId: ListsColumnDefs.fullName.colId },
  { colId: ListsColumnDefs.lastValid.colId },
  { colId: ListsColumnDefs.listsChange.colId },
  { colId: ListsColumnDefs.listsPctChange.colId },
  { colId: ListsColumnDefs.listsBid.colId },
  { colId: ListsColumnDefs.listsAsk.colId },
  { colId: ListsColumnDefs.listsTurnover.colId },
  { colId: ListsColumnDefs.time.colId },
];

export const SelectedListBondColumns: ColumnSetting[] = [
  { colId: ListsColumnDefs.ticker.colId },
  { colId: ListsColumnDefs.ISIN.colId },
  { colId: ListsColumnDefs.fullName.colId },
  { colId: ListsColumnDefs.amortizationType.colId },
  { colId: ListsColumnDefs.issueDate.colId },
  { colId: ListsColumnDefs.maturityDate.colId },
  { colId: ListsColumnDefs.duration.colId },
  { colId: ListsColumnDefs.outstandingPrincipal.colId },
  { colId: ListsColumnDefs.couponsPerYear.colId },
  { colId: ListsColumnDefs.yield.colId },
  { colId: ListsColumnDefs.listsBid.colId },
  { colId: ListsColumnDefs.listsAsk.colId },
  { colId: ListsColumnDefs.lastValid.colId },
  { colId: ListsColumnDefs.listsChange.colId },
  { colId: ListsColumnDefs.listsTurnover.colId },
  { colId: ListsColumnDefs.time.colId },
];

export const SelectedListBondCSBColumns: ColumnSetting[] = [
  { colId: ListsColumnDefs.ticker.colId },
  { colId: ListsColumnDefs.ISIN.colId },
  { colId: ListsColumnDefs.fullName.colId },
  { colId: ListsColumnDefs.amortizationType.colId },
  { colId: ListsColumnDefs.issueDate.colId },
  { colId: ListsColumnDefs.maturityDate.colId },
  { colId: ListsColumnDefs.duration.colId },
  { colId: ListsColumnDefs.outstandingPrincipal.colId },
  { colId: ListsColumnDefs.couponsPerYear.colId },
  { colId: ListsColumnDefs.yield.colId },
  { colId: ListsColumnDefs.listsBid.colId },
  { colId: ListsColumnDefs.listsAsk.colId },
  { colId: ListsColumnDefs.lastValid.colId },
  { colId: ListsColumnDefs.listsChange.colId },
  { colId: ListsColumnDefs.listsTurnover.colId },
  { colId: ListsColumnDefs.time.colId },
];

export const SelectedListBondMMOTColumns: ColumnSetting[] = [
  { colId: ListsColumnDefs.ISIN.colId },
  { colId: ListsColumnDefs.fullName.colId },
  { colId: ListsColumnDefs.maturityDate.colId },
  { colId: ListsColumnDefs.listsBid.colId },
  { colId: ListsColumnDefs.listsAsk.colId },
  { colId: ListsColumnDefs.lastValid.colId },
  { colId: ListsColumnDefs.listsChange.colId },
  { colId: ListsColumnDefs.time.colId },
  { colId: ListsColumnDefs.listsPctChange.colId },
  { colId: ListsColumnDefs.listsVolume.colId },
  { colId: ListsColumnDefs.listsTurnover.colId },
  { colId: ListsColumnDefs.yield.colId },
  { colId: ListsColumnDefs.issueDate.colId },
  { colId: ListsColumnDefs.duration.colId },
];

export const ListsViews = {
  Bonds: SelectedListBondColumns,
  BondsCSB: SelectedListBondCSBColumns,
  BondsMMOT: SelectedListBondMMOTColumns,
} as const;

export const ListsFeedColumnsMap: {
  readonly [feedNumber: number]: string;
} = {
  17666: 'BondsCSB',
  5925: 'BondsMMOT',
};
