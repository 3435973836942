import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, computed, effect, inject, input, signal, type OnDestroy } from '@angular/core';

import { InfrontUtil } from '@infront/sdk';
import { Subject } from 'rxjs';
import { distinctUntilChanged, take, takeUntil, tap } from 'rxjs/operators';
import { SdkRequestsService } from '../../services/sdk-requests.service';
import type { Widget } from '../../state-model/widget.model';
import type { Instrument, } from '../../state-model/window.model';
import { structuresAreEqual } from '../../util/equality';
import { DisplayStatusMap, fields, type DisplayStatus, type StatusSymbol } from './symbol-status.model';

@Component({
  selector: 'wt-symbol-status',
  templateUrl: './symbol-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SymbolStatusComponent implements OnDestroy {
  private readonly sdkRequestsService = inject(SdkRequestsService);

  private _instrument = signal<Instrument | undefined>(undefined);
  @Input() set instrument(instrument: Instrument | undefined) {
    this._instrument.set(instrument);
  }

  @Input() set widget(widget: Widget | undefined) {
    if (widget) {
      this.sdkRequestsService.windowInstrument$(widget).pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
        tap(instrument => this._instrument.set(instrument))
      ).subscribe();
    }
  }

  readonly hideSymbolStatus = input<DisplayStatus[]>([]);
  readonly alternativeValue = input<string | undefined>(undefined);

  readonly DisplayStatusMap = DisplayStatusMap;

  /** true: symbol-status badge is visible, false: badge is not visible */
  @Output() displayStatus = new EventEmitter<boolean>();

  readonly symbol = signal<StatusSymbol | undefined>(undefined);

  // writes to symbol by reading instrument and wrapping the streamingSymbolData$ observable
  readonly symbolsEffect = effect(() => {
    const instrument = this._instrument();
    if (!instrument) {
      return;
    }
    this.sdkRequestsService.streamingSymbolData$({
      symbolEntity: instrument,
      fields,
      uuid: InfrontUtil.makeUUID()
    }).pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged(structuresAreEqual), tap(symbol => this.symbol.set(symbol))).subscribe();
  }, { allowSignalWrites: true });

  readonly showStatus = computed(() => {
    const symbol = this.symbol();
    if (!symbol) {
      return false;
    }
    const symbolStatus = DisplayStatusMap[symbol.DisplaySymbolStatusCode] as DisplayStatus;
    const showStatus = !this.hideSymbolStatus().includes(symbolStatus);
    this.displayStatus.emit(showStatus);
    return showStatus;
  });

  private readonly ngUnsubscribe = new Subject<void>();

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
