import { Injectable, type OnDestroy, inject } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BehaviorSubject, Observable, Subscription, firstValueFrom, switchMap } from 'rxjs';
import { UserSettingsService } from '../services/user-settings.service';
import { PwaInstallPromptComponent } from './pwa-install-prompt.component';

export interface InstallPromptResult {
  outcome: 'accepted' | 'dismissed';
}

interface BeforeInstallPromptEvent extends Event {
  userChoice: Promise<InstallPromptResult>;
  prompt(): Promise<InstallPromptResult>;
}

declare global {
  interface WindowEventHandlersEventMap {
    'beforeinstallprompt': BeforeInstallPromptEvent;
  }
}

const globalCanInstallSubject = new BehaviorSubject<boolean>(false);
let globalInstallPrompt: () => Promise<InstallPromptResult>;


// init; if this event is not called, the feature is not supported
window.addEventListener('beforeinstallprompt', (event) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any)['infrontApp'] ??= {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any)['infrontApp'].installPrompt = globalInstallPrompt = () => event.prompt();
  event.preventDefault();
  if (!globalCanInstallSubject.value) {
    globalCanInstallSubject.next(true);
  }
});


@Injectable({ providedIn: 'root' })
export class PwaInstallService implements OnDestroy {
  private readonly dialog = inject(MatDialog);
  private readonly userSettings = inject(UserSettingsService);

  private readonly canInstallSubject = new BehaviorSubject<boolean>(false);
  private readonly beforeinstallpromptSubscription: Subscription;

  constructor() {
    this.beforeinstallpromptSubscription = globalCanInstallSubject.subscribe({
      next: value => this.canInstallSubject.next(value),
    });
  }

  get canInstall$(): Observable<boolean> { return this.canInstallSubject; }

  isSupported(): boolean {
    return !!globalInstallPrompt;
  }

  async showPromptIfNotInstalled(): Promise<void> {
    if (!globalInstallPrompt) {
      return;
    }

    if (this.canInstallSubject.value) {

      if (await firstValueFrom(this.userSettings.getValue$('dismissedPwaInstall'))) {
        return;
      }

      return firstValueFrom(
        this.dialog.open(PwaInstallPromptComponent, {
          width: '400px',
          hasBackdrop: true,
          position: {},
          autoFocus: true,
        }).afterClosed().pipe(
          switchMap(async (result) => {
            this.userSettings.setValue('dismissedPwaInstall', true);
            if (result) {
              await globalInstallPrompt();
            }
          }),
        )
      );
    }
  }

  async promptForPwaInstall(): Promise<InstallPromptResult> {
    if (!globalInstallPrompt) {
      throw new Error('PWA install is not supported on this browser.');
    }

    return globalInstallPrompt();
  }

  ngOnDestroy(): void {
    this.beforeinstallpromptSubscription.unsubscribe();
    this.canInstallSubject.complete();
  }
}
