import type { TradingOrderEntryService } from '../../../../services/trading-order-entry.service';
import type { ContextMenuItem } from '../../../../shared/models/context-menu.model';

export const modifyOrderItem: (tradingOrderEntryService: TradingOrderEntryService) => ContextMenuItem = (tradingOrderEntryService) => {
  return {
    id: 'modifyOrder',
    title: 'CONTEXT_MENU_COMPONENT.TRADING.MODIFY_ORDER',
    icon: 'edit',
    closeOnClick: true,
    onClick: (params) => {
      const order = params?.tradingOrderGetter?.();
      if (!order?.OrderId || !order?.OrderPortfolio) {
        return;
      }
      tradingOrderEntryService.openOrderEntry({ modifyOrderId: order.OrderId, modifyPortfolio: order.OrderPortfolio });
    },
  };
};
