import { Injectable, inject } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import type { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { RenameUserlistDialogComponent } from '../../dashboard-window/headers/userlist-header/rename-userlist-dialog/rename-userlist-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class UserlistService {
  private readonly dialog = inject(MatDialog);

  renameDialog$(name: string): Observable<string> {
    return this.dialog.open(RenameUserlistDialogComponent, { maxWidth: '400px', data: { name } }).afterClosed().pipe(take(1));
  }

}
