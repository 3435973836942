<ng-container *ngIf="dashboards$ | async as dashboards">
  <div class="wt-flex-row">
    <div class="wt-flex-column wt-flex-large-5">
      <ng-container *ngIf="dashboards.length > 0">
        <h3 *ngIf="dashboards.length > 0">{{ 'ADMIN_DIALOG.DEFAULT_DASHBOARDS.TITLE' | translate }}</h3>

        <div class="wt-default-dashboards-panel__list"
          cdkDropList
          cdkDropListOrientation="vertical"
          [cdkDropListSortingDisabled]="!canEdit"
          (cdkDropListDropped)="drop($event)">
          <ul>
            <li *ngFor="let dashboard of dashboards"
              class="wt-default-dashboards-panel__item"
              cdkDrag
              [cdkDragData]="dashboard"
              cdkDragLogAxis="y">
              <span cdkDragHandle>
                <mat-icon class="wt-default-dashboards-panel__item-icon"
                  [fontIcon]="iconFor(dashboard, 'picker')"></mat-icon>
                <span class="wt-default-dashboards-panel__item-label">
                  {{ dashboard.displayName }}
                </span>
              </span>

              <mat-icon *ngIf="canEdit" class="wt-default-dashboards-panel__item-remove-button" fontIcon="close"
                (click)="removeDashboard(dashboard)"></mat-icon>
            </li>
          </ul>
        </div>
      </ng-container>

      <ng-container *ngIf="dashboards.length === 0">
        <p class="wt-default-dashboards-panel-empty-message">No dashboards selected.</p>
      </ng-container>
    </div>
  </div>
</ng-container>

<div class="wt-default-dashboards-panel__actions" *ngIf="canEdit">
  <button class="wt-button  wt-default-dashboards-panel__actions-button" [matMenuTriggerFor]="panel.menu">
    {{ 'ADMIN_DIALOG.DEFAULT_DASHBOARDS.ADD_DEFAULT_DASHBOARD' | translate }}

    <mat-icon class="wt-default-dashboards-panel__actions-button-icon" fontIcon="add"></mat-icon>
  </button>
</div>

<wt-menu-panel
  #panel
  [items]="(menuItems$ | async) ?? []"
  (itemClick)="onItemClick($event)">
</wt-menu-panel>