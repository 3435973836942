import { ChangeDetectionStrategy, Component, Input, type OnInit } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { Observable, merge } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { DashboardService } from '../../../dashboard/dashboard.service';
import { StoreService } from '../../../services/store.service';
import type { DashboardWindow, InstrumentSearchableWindowType } from '../../../state-model/window.model';
import { SdkSearchResultSymbolItemFields, type SearchConfig, getDefaultSearchConfig } from './../../search.model';
import { CompactSearchContainerService } from './compact-search-container.service';

@Component({
  selector: 'wt-search-compact-container',
  templateUrl: './compact-search-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompactSearchContainerComponent implements OnInit {
  @Input({ required: true }) window!: InstrumentSearchableWindowType;
  @Input({ required: true }) placeholder!: string;
  @Input() searchConfig: SearchConfig = getDefaultSearchConfig();
  visibilityChangeRequest$!: Observable<{ visible: boolean } | undefined>;

  SearchType = InfrontSDK.SearchType;

  ngOnInit(): void {
    this.visibilityChangeRequest$ = merge(
      this.compactSearchContainerService.searchClick$.pipe(
        filter((window) => this.window.id === window.id),
        map((window) => ({
          id: 'searchClick',
          window,
        }))
      ),
      this.dashboardService.windowDragStart$.pipe(
        map((window) => ({
          id: 'dragStart',
          window,
        }))
      )
    ).pipe(
      map((item) => {
        return item.id === 'dragStart' ? { visible: false } : undefined;
      })
    );
  }

  constructor(
    private storeService: StoreService,
    private compactSearchContainerService: CompactSearchContainerService,
    private dashboardService: DashboardService
  ) { }

  onVisibilityChanged(visibility: { visible: boolean }): void {
    // do nothing for now, keeping it for future use
  }

  onClose(): void {
    this.compactSearchContainerService.onSearchClick(this.window);
  }

  onSelectedOption(window: DashboardWindow, item: typeof SdkSearchResultSymbolItemFields): void {
    this.storeService.updateWindow(window, {
      settings: { ...this.window.settings, ...{ instrument: { feed: item.Feed, ticker: item.Ticker } } },
    });
  }
}
