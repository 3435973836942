import { ChangeDetectionStrategy, Component, type OnDestroy, ViewChild, inject } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { DashboardFolderLevel, type DashboardFolderRef, type DashboardItemRef, type DashboardNodeRef, type DashboardRef, DashboardService, DashboardType, KnownDashboardFolderIDs, isDashboardFolderRef, isDashboardNodeRef } from '@infront/ngx-dashboards-fx';
import { LogService } from '@vwd/ngx-logging';
import { Observable, Subject, type UnaryFunction, auditTime, combineLatest, defer, filter, map, mergeMap, of, pipe, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs';
import { StoreService } from '../../services/store.service';
import { TenantSettingsService } from '../../services/tenant-settings.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { ConfirmDialogComponent, type ConfirmDialogResult } from '../../shared/confirm-dialog/confirm-dialog.component';
import type { ContextMenuItem } from '../../shared/models/context-menu.model';
import { translator } from '../../util/locale';
import { filterUndefined } from '../../util/rxjs';
import { newUniqueName } from '../../util/utils';
import { NewDashboardDialogComponent, type NewDashboardDialogData } from '../new-dashboard-dialog/new-dashboard-dialog.component';
import { NewDashboardFolderDialogComponent, type NewDashboardFolderDialogData } from '../new-dashboard-folder-dialog/new-dashboard-folder-dialog.component';
import { DashboardTabsService } from '../providers/dashboard-tabs.provider';
import { RenameDashboardDialogComponent, type RenameDashboardDialogData } from '../rename-dashboard-dialog/rename-dashboard-dialog.component';
import { RemoteStorageService } from './../../services/remote-storage.service';

import { getDashboardChildIcon, getDashboardIcon } from '../../shared/dashboard-icons';

@Component({
  selector: 'wt-new-dashboard-context-menu',
  templateUrl: './new-dashboard-context-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewDashboardContextMenuComponent implements OnDestroy {
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;

  private readonly storeService = inject(StoreService);
  private readonly remoteStorageService = inject(RemoteStorageService);
  private readonly dashboardService = inject(DashboardService);
  private readonly dialog = inject(MatDialog);
  private readonly userSettings = inject(UserSettingsService);
  private readonly tenantSettings = inject(TenantSettingsService);
  private readonly dashboardTabsService = inject(DashboardTabsService);
  private readonly logger = inject(LogService).openLogger('components/wt-new-dashboard-context-menu');

  readonly canAddDashboard$ = this.remoteStorageService.canAddDashboard$;

  private readonly xlat = translator();

  private readonly ngUnsubscribe = new Subject<void>();


  readonly globalDashboards$: Observable<ContextMenuItem[]> = defer(() =>
    this.dashboardService.getFolder$(KnownDashboardFolderIDs.GLOBAL).pipe(
      this.mapFolderRefToContextMenuItem(true, true),
      tap((values) => this.logger.debug('Global menu items emitted', values)),
    )
  );

  readonly companyDashboards$: Observable<ContextMenuItem[]> = defer(() =>
    this.dashboardService.getFolder$(KnownDashboardFolderIDs.ROOT).pipe(
      switchMap((root) => root.monitorHierarchyChanges$),
      map((root) => root.childFolders),
      map((folders) => folders.filter((folder) => folder.model.level === (DashboardFolderLevel.COMPANY as number)
        && folder.model.owner?.realm == this.tenantSettings.realm)),
      switchMap((folders) => combineLatest(
        folders.map((folder) => of(folder).pipe(this.mapFolderRefToContextMenuItem(false, true)))
      ).pipe(
        map((contextMenuItems) => contextMenuItems.flat()),
      ),
      ),
      tap((values) => this.logger.debug('Company menu items emitted', values)),
    )
  );

  readonly userDashboards$: Observable<ContextMenuItem[]> = defer(() =>
    this.dashboardService.getFolder$(KnownDashboardFolderIDs.PERSONAL).pipe(
      switchMap((personal) => personal.monitorHierarchyChanges$),
      switchMap((personal) => this.storeService.currentDashboard$.pipe(map(() => personal))),
      map((personal) => [this.mapChildFolder(personal, true, false, this.xlat('DASHBOARD.MENU.USER_FOLDER'))]),
      tap((values) => this.logger.debug('User menu items emitted', values)),
    ),
  );

  readonly isAdmin$ = this.dashboardService.root$.pipe(
    switchMap((root) => root.childFolders$),
    map((folders) => folders.some((folder) =>
      (folder.model.level === (DashboardFolderLevel.COMPANY as number) || folder.model.level === (DashboardFolderLevel.GLOBAL) as number)
      && folder.model.security.canAddChildren)
    ),
    shareReplay(1),
  );

  readonly editingEnabled$ = this.userSettings.getValue$('enableDashboardFolderEditing').pipe(map(value => !!value));

  showInfrontDashboards$(realm: string | undefined): Observable<boolean> {
    if (!realm) {
      return of(false);
    }
    return this.tenantSettings.getValue$('showInfrontDashboards', realm).pipe(map(value => value !== false));
  }

  get userFolder() { return this.dashboardService.getFolderRef(KnownDashboardFolderIDs.PERSONAL)!; }

  readonly adminItems$: Observable<ContextMenuItem[]> = defer(() =>
    combineLatest([
      this.editingEnabled$,
      this.dashboardService.root$.pipe(
        switchMap((root) => root.monitorHierarchyChanges$),
        map((root) => root.childFolders),
        map((folders) => folders.some((folder) =>
          (folder.model.level === (DashboardFolderLevel.COMPANY as number) || folder.model.level === (DashboardFolderLevel.GLOBAL) as number)
          && folder.model.security.canAddChildren)
        ),
      ),
      this.dashboardService.root$.pipe(
        switchMap((root) => root.childFolders$)
      ),
      this.showInfrontDashboards$(this.tenantSettings.realm),
      this.storeService.currentDashboard$,
    ]).pipe(
      mergeMap(([editingEnabled, isAdmin, folders, showInfrontDashboards]) => combineLatest([
        ...folders.filter(() => isAdmin).map((folder) => this.getTopLevelFolderActions(folder, editingEnabled, showInfrontDashboards)),
        of([{
          closeOnClick: true,
          id: 'factoryDefault',
          title: this.xlat('DASHBOARD.MENU.RESET'),
          icon: 'refresh',
          onClick: (params) => {
            this.resetToDefaults();
          },
        }]),
        of([{
          closeOnClick: false,
          id: 'toggleAdminMode',
          title: this.xlat('DASHBOARD.MENU.TOGGLE_ADMIN_MODE'),
          checked: editingEnabled,
          icon: 'manage_accounts',
          type: 'switch',
          onClick: () => {
            this.userSettings.setValue('enableDashboardFolderEditing', !editingEnabled);
          },
        }].filter(() => isAdmin)),
      ] as Observable<ContextMenuItem[]>[])),
      map((menuItems) => menuItems.flat()),
    ),
  );

  readonly menuItems$: Observable<ContextMenuItem[]> =
    combineLatest([
      this.userDashboards$,
      this.globalDashboards$,
      this.companyDashboards$,
      this.adminItems$,
    ]).pipe(
      auditTime(50), // do not emit immediately (several observables can emit in short succession)
      map(([userItems, globalItems, companyItems, adminItems]) => [...userItems, ...globalItems, ...companyItems, ...adminItems]),
      tap((items) => console.log('Menu items changed', items))
    );

  onItemClick(item: ContextMenuItem): void {
    if (!item.onClick) return;
    item.onClick({ item });
  }

  newEmptyDashboard(parent?: DashboardFolderRef): void {
    const names$ = (parent ?? this.userFolder).children$.pipe(map((dashboards) => dashboards.map((dashboard) => dashboard.model.name)))
      ?? this.storeService.dashboards$.pipe(map((dashboards) => dashboards.map((d) => d.name)));

    names$.pipe(take(1))
      .subscribe((nameList) => {
        const name = newUniqueName(this.xlat('DASHBOARD.DEFAULT_NAME'), nameList);
        this.dialog
          .open(NewDashboardDialogComponent, {
            width: '300px',
            data: {
              name,
              parent: parent ?? this.userFolder,
              icon: getDashboardChildIcon(parent ?? this.userFolder, DashboardType.DASHBOARD),
            } satisfies NewDashboardDialogData,
          })
          .afterClosed()
          .pipe(filter((result: string) => !!result),
            switchMap((result) => {
              if (parent) {
                return parent.createChild({ name: result }).pipe(switchMap(dashboardRef => {
                  this.dashboardTabsService.addTab(dashboardRef.model.id);
                  return this.storeService.openDashboard(dashboardRef.model.id);
                }));
              } else {
                return this.storeService.addDashboard(result);
              }
            })).subscribe();
      });
  }

  createLink(parent: DashboardFolderRef, currentDashboard: DashboardRef): void {
    const names$ = parent.children$.pipe(map((dashboards) => dashboards.map((dashboard) => dashboard.model.name)));

    names$.pipe(take(1))
      .subscribe((nameList) => {
        const name = newUniqueName(currentDashboard.displayName, nameList);
        this.dialog
          .open(NewDashboardDialogComponent, {
            width: '300px',
            data: {
              name,
              parent,
              icon: getDashboardIcon(currentDashboard, 'new-dashboard-menu'),
            } satisfies NewDashboardDialogData,
          })
          .afterClosed()
          .pipe(filter((result: string) => !!result),
            switchMap((result) => {
              return parent.createLink({ name: result, target: currentDashboard });
            })).subscribe();
      });
  }

  cloneDashboard(parent: DashboardFolderRef, currentDashboard: DashboardRef): void {
    const names$ = parent.children$.pipe(map((dashboards) => dashboards.map((dashboard) => dashboard.model.name)));

    names$.pipe(take(1))
      .subscribe((nameList) => {
        const name = newUniqueName(currentDashboard.displayName + ' - copy', nameList);
        this.dialog
          .open(NewDashboardDialogComponent, {
            width: '300px',
            data: {
              name,
              parent,
              icon: getDashboardChildIcon(parent, currentDashboard.model.type),
            } satisfies NewDashboardDialogData,
          })
          .afterClosed()
          .pipe(filter((result: string) => !!result),
            switchMap((result) => {
              return parent.createChild({ name: result, copyWidgetsFrom: currentDashboard as DashboardItemRef });
            })).subscribe();
      });
  }

  newFolder(parent: DashboardFolderRef): void {
    const name = newUniqueName(this.xlat('DASHBOARD.DEFAULT_FOLDER_NAME'), parent.childFolders.map(f => f.displayName));
    this.dialog
      .open(NewDashboardFolderDialogComponent, {
        width: '300px',
        data: {
          name,
          parent,
          icon: getDashboardChildIcon(parent, DashboardType.FOLDER),
        } satisfies NewDashboardFolderDialogData,
      })
      .afterClosed()
      .pipe(filter((result: string) => !!result))
      .subscribe((result: string) => {
        parent.createChild({ name: result, type: DashboardType.FOLDER });
      });
  }

  renameDashboard(dashboard: DashboardRef): void {
    this.dialog
      .open(RenameDashboardDialogComponent, {
        width: '300px',
        data: {
          name: dashboard.model.name,
          dashboard,
          icon: getDashboardIcon(dashboard, 'new-dashboard-menu'),
        } satisfies RenameDashboardDialogData,
      })
      .afterClosed()
      .pipe(filterUndefined<string>())
      .subscribe({
        next: (newName: string) => {
          dashboard.update({ name: newName });
        },
      });
  }

  deleteDashboard(dashboard: DashboardRef): void {
    const confirmDialog$ = () =>
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            header: this.xlat(`DASHBOARD.DELETE_DASHBOARD_PROMPT.DELETE_FOLDER`),
            message: this.xlat(`DASHBOARD.DELETE_DASHBOARD_PROMPT.ARE_YOU_SURE`),
            icon: getDashboardIcon(dashboard, 'new-dashboard-menu'),
          }
        })
        .afterClosed()
        .pipe(
          map((result) => result as ConfirmDialogResult),
          filter((result) => !!result)
        );

    const disableDashboardDeletePromptSetting = this.userSettings.getValue('disableDashboardDeletePrompt') ?? false;
    const obs = disableDashboardDeletePromptSetting ? of({ shouldDelete: true, disableDeletePrompt: true }) : confirmDialog$();
    obs.subscribe(result => {
      if (result.shouldDelete) {
        dashboard.delete();
      }
      if (!disableDashboardDeletePromptSetting && result.disableDeletePrompt) {
        this.userSettings.setValue('disableDashboardDeletePrompt', true);
      }
    });
  }

  deleteDashboardFolder(dashboard: DashboardFolderRef): void {
    const confirmDialog$ = () =>
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            header: this.xlat(`DASHBOARD.DELETE_DASHBOARD_FOLDER_PROMPT.DELETE_FOLDER`),
            message: this.xlat(`DASHBOARD.DELETE_DASHBOARD_FOLDER_PROMPT.ARE_YOU_SURE`),
            icon: getDashboardIcon(dashboard, 'new-dashboard-menu'),
          }
        })
        .afterClosed()
        .pipe(
          map((result) => result as ConfirmDialogResult),
          filter((result) => !!result)
        );

    const disableDashboardDeletePromptSetting = this.userSettings.getValue('disableDashboardDeletePrompt') ?? false;
    const obs = disableDashboardDeletePromptSetting ? of({ shouldDelete: true, disableDeletePrompt: true }) : confirmDialog$();
    obs.subscribe(result => {
      if (result.shouldDelete) {
        dashboard.delete();
      }
      if (!disableDashboardDeletePromptSetting && result.disableDeletePrompt) {
        this.userSettings.setValue('disableDashboardDeletePrompt', true);
      }
    });
  }

  resetToDefaults(): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          header: this.xlat('DASHBOARD.RESET_DASHBOARDS_PROMPT.TITLE'),
          message: this.xlat('DASHBOARD.RESET_DASHBOARDS_PROMPT.ARE_YOU_SURE'),
          icon: 'refresh',
          okButtonLabel: this.xlat('DASHBOARD.RESET_DASHBOARDS_PROMPT.OK'),
          cancelButtonLabel: this.xlat('DASHBOARD.RESET_DASHBOARDS_PROMPT.CANCEL'),
          showDisablePrompt: false,
        }
      })
      .afterClosed()
      .pipe(
        map((result) => result as ConfirmDialogResult),
        filter((result) => !!result),
      ).subscribe((result) => {
        if (result.shouldDelete) {
          this.storeService.resetDashboards();
        }
      });
  }

  private mapFolderRefToContextMenuItem(isGlobal: boolean, isTopLevel: boolean): UnaryFunction<Observable<DashboardFolderRef>, Observable<ContextMenuItem[]>> {
    return pipe(
      switchMap((rootFolder: DashboardFolderRef) => combineLatest([
        rootFolder.monitorHierarchyChanges$,
        isTopLevel ? of(false) : this.editingEnabled$,
        isGlobal ? this.showInfrontDashboards$(this.tenantSettings.realm) : of(false),
        this.isAdmin$,
        this.storeService.currentDashboard$,
      ])),
      tap(([rootFolder, editingEnabled, showInfrontDashboards, isAdmin]) => this.logger.debug(`mapFolderRefToContextMenuItem`, {
        name: rootFolder.displayName,
        rootFolder,
        editingEnabled,
        showInfrontDashboards,
        isAdmin,
      })),
      switchMap(([rootFolder, editingEnabled, showInfrontDashboards, isAdmin]) => {
        if (rootFolder.childrenLoadState === 'on-demand') {
          rootFolder.loadChildren();
        }

        const folders = rootFolder.children.filter(isDashboardFolderRef);

        const foldersToProcess = !showInfrontDashboards && rootFolder.model.id === KnownDashboardFolderIDs.GLOBAL as string
          ? []
          : folders.filter((parent) => !parent.model.hidden).map((parent) => parent.children$.pipe(
            map((childNodes) => ({
              parent,
              children: childNodes.filter(node => !node.model.hidden)
            })),
          ));

        const childDashboardItems = !showInfrontDashboards && rootFolder.model.id === KnownDashboardFolderIDs.GLOBAL as string
          ? []
          : rootFolder.children.filter(isDashboardNodeRef).filter((node) => !node.model.hidden).map((child: DashboardNodeRef) => this.mapChildDashboard(child, editingEnabled));

        return ((foldersToProcess.length) ? combineLatest(foldersToProcess) : of([])).pipe(
          tap((values) => this.logger.debug(`Items to map`, {
            name: rootFolder.displayName,
            values,
            showInfrontDashboards
          })),
          map((folderInfos) => {
            return folderInfos.map(({ parent, children }) => ({
              id: `childFolder-${parent.model.id}`,
              title: parent.displayName,
              icon: getDashboardIcon(parent, 'new-dashboard-menu'),
              subItems: [
                ...children.filter((node) => !node.model.hidden && isDashboardFolderRef(node)).map((child) => this.mapChildFolder(child as DashboardFolderRef, editingEnabled, showInfrontDashboards)),
                ...children.filter((node) => !node.model.hidden && !isDashboardFolderRef(node)).map((child) => this.mapChildDashboard(child as DashboardNodeRef, editingEnabled)),
                ...(children.some((child) => !child.model.hidden) ? [] : [{
                  id: `childFolderEmpty-${parent.model.id}`,
                  title: this.xlat('DASHBOARD.MENU.FOLDER_IS_EMPTY'),
                  disabled: of(true),
                } as ContextMenuItem]),
                ...(isAdmin ? this.getFolderActions(parent, editingEnabled, showInfrontDashboards) : []),
              ],
            }));
          }),
          map((folderItems) => [...folderItems, ...childDashboardItems]),
        );
      })
    );
  }

  private *getFolderActions(folder: DashboardFolderRef, editingEnabled: boolean, showInfrontDashboards: boolean): Iterable<ContextMenuItem> {
    if (!editingEnabled) {
      return;
    }
    if (folder.model.security.canEdit)
      yield {
        closeOnClick: true,
        id: `renameDashboardFolder-${folder.model.id}`,
        title: this.xlat('DASHBOARD.MENU.RENAME_FOLDER'),
        icon: 'edit',
        isSVGIcon: true,
        onClick: (params) => this.renameDashboard(folder),
      };
    if (folder.model.security.canDelete && !folder.children.length)
      yield {
        closeOnClick: true,
        id: `deleteDashboardFolder-${folder.model.id}`,
        title: this.xlat('DASHBOARD.MENU.DELETE_FOLDER'),
        icon: 'close',
        onClick: (params) => this.deleteDashboardFolder(folder),
      };
    if (folder.model.security.canAddChildren) {
      yield {
        closeOnClick: true,
        id: `newChildFolder-for-${folder.model.id}`,
        title: this.xlat('DASHBOARD.MENU.CREATE_CHILD_FOLDER'),
        icon: 'create_new_folder',
        onClick: (params) => this.newFolder(folder),
      };
      yield {
        closeOnClick: true,
        id: `newEmptyDashboard-for-${folder.model.id}`,
        title: this.xlat('DASHBOARD.MENU.CREATE_EMPTY_DASHBOARD'),
        icon: 'add',
        onClick: (params) => this.newEmptyDashboard(folder),
      };

      const currentDashboardState = this.storeService.currentDashboard;
      if (currentDashboardState) {
        const currentDashboard = this.dashboardService.getRef(currentDashboardState.id);

        if (currentDashboard) {
          if (folder.canLinkTo(currentDashboard)
            && currentDashboard.model.level === (DashboardFolderLevel.GLOBAL as number)) {
            yield {
              closeOnClick: true,
              id: `linkToCurrent-for-${folder.model.id}`,
              title: this.xlat('DASHBOARD.MENU.LINK_TO_CURRENT'),
              icon: 'link',
              onClick: (params) => {
                this.createLink(folder, currentDashboard);
              },
            };
          }

          yield {
            closeOnClick: true,
            id: `clone-for-${folder.model.id}`,
            title: this.xlat('DASHBOARD.MENU.CLONE_DASHBOARD_INTO'),
            icon: 'save',
            onClick: (params) => {
              this.cloneDashboard(folder, currentDashboard);
            },
          };
        }
      }

      const realm = folder.model.owner?.realm;
      const canEditRealm = realm && this.tenantSettings.canEdit(realm);

      if (folder.model.level === (DashboardFolderLevel.COMPANY as number)
        && folder.parent?.model.id === (KnownDashboardFolderIDs.ROOT as string)) {
        yield {
          closeOnClick: false,
          id: 'toggleShowInfrontFolders',
          title: this.xlat('DASHBOARD.MENU.SHOW_GLOBAL_FOLDERS'),
          disabled: of(!canEditRealm),
          type: 'switch',
          icon: 'language',
          checked: showInfrontDashboards,
          onClick: (params) => {
            this.tenantSettings.setValue('showInfrontDashboards', !showInfrontDashboards, realm);
          },
        };
      }
    }
  }

  private getTopLevelFolderActions(folder: DashboardFolderRef, editingEnabled: boolean, showInfrontDashboards: boolean): Observable<ContextMenuItem[]> {
    if (editingEnabled) {
      if (folder.childrenLoadState === 'on-demand') {
        folder.loadChildren();
      }

      if (folder.model.id === (KnownDashboardFolderIDs.GLOBAL as string)) {
        return of([this.mapChildFolder(folder, editingEnabled, showInfrontDashboards, this.xlat('DASHBOARD.MENU.INFRONT_DASHBOARDS_ADMIN'))]);
      } else if (folder.model.level === (DashboardFolderLevel.COMPANY as number) || folder.model.level === (DashboardFolderLevel.SUBTENANT as number)) {
        // listen to child folder changes so we can rebuild child folder menus
        return folder.children$.pipe(map(() => [
          this.mapChildFolder(folder, editingEnabled, showInfrontDashboards, this.xlat('DASHBOARD.MENU.TENANT_DASHBOARDS_ADMIN_TEMPLATE', { name: folder.displayName })),
        ]));
      }
    }
    return of([]);
  }

  private *getDashboardActions(child: DashboardNodeRef): Iterable<ContextMenuItem> {
    if (child.model.security.canEdit)
      yield {
        closeOnClick: true,
        id: `rename-dashboard-${child.model.id}`,
        title: this.xlat('DASHBOARD.MENU.RENAME_DASHBOARD'),
        icon: 'edit',
        isSVGIcon: true,
        onClick: (params) => this.renameDashboard(child),
      };

    if (child.model.security.canDelete)
      yield {
        closeOnClick: true,
        id: `delete-dashboard-${child.model.id}`,
        title: this.xlat('DASHBOARD.MENU.DELETE_DASHBOARD'),
        icon: 'delete',
        isSVGIcon: true,
        onClick: (params) => this.deleteDashboard(child),
      };
  }

  private mapChildFolder(folder: DashboardFolderRef, editingEnabled: boolean, showInfrontDashboards: boolean, nameOverride?: string): ContextMenuItem {
    return {
      closeOnClick: false,
      id: `childFolder-${folder.model.id}`,
      title: nameOverride ?? folder.displayName,
      icon: getDashboardIcon(folder, 'new-dashboard-menu'),
      onClick: (params) => { },
      subItems: [
        ...folder.childFolders.filter((childFolder) => !childFolder.model.hidden).map((childFolder) => this.mapChildFolder(childFolder, editingEnabled, showInfrontDashboards)),
        ...folder.childNodes.filter((child) => !child.model.hidden).map((child) => this.mapChildDashboard(child, editingEnabled)),
        ...(folder.children.some((child) => !child.model.hidden) ? [] : [{
          id: `childFolderEmpty-${folder.model.id}`,
          title: this.xlat('DASHBOARD.MENU.FOLDER_IS_EMPTY'),
          disabled: of(true),
        } as ContextMenuItem]),
        ...this.getFolderActions(folder, editingEnabled, showInfrontDashboards),
      ]
    };
  }

  private mapChildDashboard(child: DashboardNodeRef, editingEnabled: boolean): ContextMenuItem {
    return {
      id: `open-dashboard-${child.model.id}`,
      closeOnClick: true,
      title: child.model.isLink && editingEnabled ? this.xlat('DASHBOARD.MENU.LINK_NAME', { name: child.displayName }) : child.displayName,
      icon: getDashboardIcon(child, 'new-dashboard-menu'),
      onClick: () => {
        this.dashboardTabsService.addTab(child.model.id);
        this.storeService.openDashboard(child.model.id).pipe(take(1), takeUntil(this.ngUnsubscribe)).subscribe();
      },
      actions: editingEnabled ? [...this.getDashboardActions(child)] : [],
    };
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
