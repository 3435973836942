import { ChangeDetectionStrategy, Component } from '@angular/core';
import type { ICellRendererParams } from 'ag-grid-community';
import { Observable } from 'rxjs';

import type { NewsWidget } from '../../../state-model/widget.model';
import type { NewsHeadline } from '../news.model';

@Component({
  selector: 'wt-headline-cell',
  template: `
    <div class="wt-headline-cell">
      <div [hidden]="!isResearchNews" class="wt-research-label">RSRCH</div>
      <div [class.wt-headline-cell__text--flash]="isFlash" class="wt-headline-cell__text">
        <span [innerHTML]="cellValue | textMarker: filterText"></span>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadlineCellComponent {
  cellValue!: string;
  isResearchNews = false;
  isFlash = false;
  filterText = '';
  userTextFilter$!: Observable<{ filter: string }>;

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.setValues(params);
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams): void {
    this.setValues(params);
  }

  private setValues(params: ICellRendererParams) {
    this.cellValue = params.value as string;
    this.isResearchNews = (params.data as NewsHeadline).isResearchNews as boolean;
    this.isFlash = (params.data as NewsHeadline).isFlash as boolean;
    this.filterText = (params.context.widget as NewsWidget).settings.textFilter;
  }
}
