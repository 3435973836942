import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({ name: 'numberToColor' })
export class NumberToColorPipe implements PipeTransform {
  transform(value: number): string {
    if (value > 0) {
      return '#00ca5e';
    }
    if (value < 0) {
      return '#ff6868';
    }
    return '#aaaaaa';
  }
}
