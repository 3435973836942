import { InjectionToken, type Provider, inject } from '@angular/core';
import { type Writable, isEmptyObject } from '../internal/utils/objects';
import type { UserPersistables, WidgetDataModel, WidgetStructureModel, WidgetUserDataModel, WidgetUserDataStructureModel } from '../models/widget.models';
import type { DashboardRef } from '../state-refs';

export const NoUserPersistable: UserPersistables = {
  merge: widgetData => widgetData,
  unmerge: () => undefined,
  clean: () => undefined,
};

export function createUserPropertiesList(...properties: readonly string[]): UserPropertiesList {
  return properties.map(propName => ({ propName, propList: propName.split('.') }));
}

/**
 * Creates a {@link UserPersistables} that provides the appropriate `merge` and
 * `unmerge` methods.
 *
 * You can specify dotted nested properties, such as `settings.feedId`.
 *
 * @param properties The properties to make user-persistable; allows dotted,
 * nested properties.
 */
export function createUserPersistables(...properties: ReadonlyArray<string>): UserPersistables {
  const propertiesList: UserPropertiesList = createUserPropertiesList(...properties);

  if (!propertiesList.length) {
    return NoUserPersistable;
  }

  return {
    merge: (widgetData, userData) => mergeUserProperties(propertiesList, widgetData, userData),
    unmerge: (widgetData) => unmergeUserProperties(propertiesList, widgetData),
    clean: (userData) => cleanUserProperties(propertiesList, userData),
  };
}

export type UserPersistablesRegistry = Record<string, UserPersistables>;

export const USER_PERSISTABLES_REGISTRY_ENTRY = new InjectionToken<ReadonlyArray<UserPersistablesRegistry>>('USER_PERSISTABLES_REGISTRY_ENTRY');

export const USER_PERSISTABLES_REGISTRY = new InjectionToken<UserPersistablesRegistry>('USER_PERSISTABLES_REGISTRY_ENTRY', {
  providedIn: 'root',
  factory(): UserPersistablesRegistry {
    return combineRegistries(inject(USER_PERSISTABLES_REGISTRY_ENTRY, { optional: true }) ?? []);
  },
});

export function provideUserPersistables(registry: Record<string, UserPersistables>): Provider[] {
  return [
    { provide: USER_PERSISTABLES_REGISTRY_ENTRY, useValue: registry, multi: true },
  ];
}

export function combineRegistries(registries: ReadonlyArray<UserPersistablesRegistry>): UserPersistablesRegistry {
  if (!registries) {
    return {};
  }
  return registries.reduce((registry, combined) => ({ ...combined, ...registry }), {});
}

export type UserPropertiesList = ReadonlyArray<{
  propName: string;
  propList: ReadonlyArray<string>;
}>;

/**
 * Merges widget data and the previously extracted user properties.
 *
 * If there is something to merge, a new object is returned. If there are no
 * user properties to merge, the original `data` object is returned.
 */
export function mergeUserProperties(userProperties: UserPropertiesList, widgetData: WidgetDataModel, userData: WidgetUserDataModel): WidgetDataModel {
  if (!userProperties.length || !userData) {
    return widgetData;
  }

  let resultWidgetData: WidgetDataModel | undefined;

  for (const { propName, propList } of userProperties) {
    const value = userData[propName];
    if (value === undefined) {
      continue;
    }

    resultWidgetData ??= { ...widgetData };

    let owner: any = resultWidgetData;

    for (let i = 0; i < propList.length - 1; i++) {
      const propName = propList[i];
      const obj = owner[propName];
      owner = owner[propName] = typeof obj !== 'object' || owner == null ? {} : { ...obj };
    }

    owner[propList[propList.length - 1]] = value;
  }

  return resultWidgetData ?? widgetData;
}

/**
 * Splits the user properties from the base widget data, and returns the user data
 * (leaving the widget data as is, as this will not be persisted).
 *
 * Returns `undefined` if there's nothing to persist.
 */
export function unmergeUserProperties(userProperties: UserPropertiesList, widgetData: WidgetDataModel): WidgetUserDataModel | undefined {
  if (!userProperties.length) {
    return undefined;
  }

  let resultUserProperties: Writable<WidgetUserDataModel> | undefined;

  for (const { propName, propList } of userProperties) {
    let owner: any = widgetData;

    // take the owner of the specified property
    for (let i = 0; i < propList.length - 1; i++) {
      const obj = owner[propList[i]];
      if (typeof obj !== 'object' || obj == null) {
        owner = undefined;
        break;
      } else {
        owner = obj;
      }
    }

    if (owner) {
      const lastPropName = propList[propList.length - 1];
      const value = owner[lastPropName];

      if (value !== undefined) {
        if (!resultUserProperties) {
          resultUserProperties = {};
        }
        resultUserProperties[propName] = value;
      }
    }
  }

  return resultUserProperties;
}

/**
 * Indicates whether the user properties bag contains properties that no longer
 * exist in the properties definition. In that case, you can consider forcing
 * an update
 */
export function cleanUserProperties(userProperties: UserPropertiesList, userData: WidgetUserDataModel): WidgetUserDataModel | undefined {
  if (!userData) {
    return undefined;
  }

  let result: Writable<WidgetUserDataModel> | undefined;
  let hasProperties = false;

  for (const key of Object.keys(userData)) {
    let found = false;
    for (const { propName } of userProperties) {
      if (propName === key) {
        found = hasProperties = true;
        break;
      }
    }
    if (!found) {
      result ??= { ...userData };
      delete result[key];
    }
  }

  if (!hasProperties) {
    return undefined;
  }

  return result ?? userData;
}

/**
 * Returns user data overrides for the specified widgets, or `undefined` if
 * there are no widgets that support user data.
 */
export function unmergeUserPropertiesForWidgets(widgets: WidgetStructureModel, registry: UserPersistablesRegistry, dashboardRef: DashboardRef): WidgetUserDataStructureModel | undefined {
  return widgets.widgets.map(widget => ({
    id: widget.id,
    userData: registry[widget.type]?.unmerge(widget.data, dashboardRef),
  })
  ).reduce((obj, { id, userData }) => {
    if (userData) {
      return { ...obj, [id]: userData };
    }
    return obj;
  }, undefined as Record<string, WidgetUserDataModel> | undefined);
}

/**
 * Merges user data overrides with the specified widgets.
 */
export function mergeUserPropertiesForWidgets(widgets: WidgetStructureModel, userData: WidgetUserDataStructureModel, registry: UserPersistablesRegistry, dashboardRef: DashboardRef): WidgetStructureModel {
  if (!userData) {
    return widgets;
  }
  return {
    ...widgets,
    widgets: widgets.widgets.map(widget => {
      const persistables = registry[widget.type];
      const data = userData[widget.id];

      if (!persistables || !data) {
        return widget;
      }

      const merged = persistables.merge(widget.data, data, dashboardRef);
      if (merged !== widget.data) {
        return { ...widget, data: merged };
      }
      return widget;
    })
  };
}

/**
 * Cleans up user widget data from properties no longer tracked, and widgets no
 * longer present.
 */
export function cleanUserPropertiesForWidgets(widgets: WidgetStructureModel, userData: WidgetUserDataStructureModel, registry: UserPersistablesRegistry, dashboardRef: DashboardRef): WidgetUserDataStructureModel | undefined {
  if (!userData) {
    return undefined;
  }
  let hasData = false;
  let cleanedUserData: Writable<WidgetUserDataStructureModel> | undefined;

  for (const [widgetId, widgetUserData] of Object.entries(userData)) {
    const widget = widgets.widgets.find(w => w.id === widgetId);
    if (!widget) {
      cleanedUserData ??= { ...userData };
      delete cleanedUserData[widgetId];
      hasData = !isEmptyObject(cleanedUserData);
    } else {
      const cleanedWidgetData = registry[widget.type]?.clean(widgetUserData, dashboardRef);
      if (cleanedWidgetData !== widgetUserData) {
        cleanedUserData ??= { ...userData };
        if (!cleanedWidgetData) {
          delete cleanedUserData[widgetId];
          hasData = !isEmptyObject(cleanedUserData);
        } else {
          cleanedUserData[widgetId] = cleanedWidgetData;
          hasData = true;
        }
      } else {
        hasData = true;
      }
    }
  }

  if (!hasData) {
    return undefined;
  }

  return cleanedUserData ?? userData;
}
