import { ColumnRegistry } from '../../shared/grid/column-registry';
import type { Column } from '../../shared/grid/columns.model';

export const MarketOverviewColumns: Column[] = [
  ColumnRegistry.subHeaders,
  { ...ColumnRegistry.countryFlagTicker, colId: 'marketOverviewCountryFlagTicker', cellClass: '' },
  { ...ColumnRegistry.lastValid, maxWidth: 120, minWidth: 100 },
  { ...ColumnRegistry.listsPctChange, maxWidth: 80 },
  { ...ColumnRegistry.time, maxWidth: 76, minWidth: 76 },
];
