import { Inject, Injectable, inject, NgZone, type OnDestroy } from '@angular/core';
import { type Logger, LogService } from '@vwd/ngx-logging';
import { combineLatest, type Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, retry, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { LocaleIdProvider } from '../i18n';
import { LastValueSubject } from '../internal/utils/last-value-subject';
import { runInZone } from '../internal/utils/observables';
import type { DashboardModel } from '../models';
import { type DashboardFolderRef, type DashboardRef, assertDashboardFolderRef, isDashboardFolderRef } from '../state-refs';
import { KnownDashboardFolderIDs } from './constants';
import { DashboardServicesProvider } from './dashboard-services.provider';
import { DashboardRefImpl } from './internal';
import { mapToRefs } from './utils/ref-cache';
import type { ReadonlyArrayMap } from './utils/array-map';

/**
 * Central service to retrieve {@link DashboardRef} objects from.
 */
@Injectable({
  providedIn: 'root'
})
export class DashboardService implements OnDestroy {
  private readonly ngUnsubscribe = new Subject<void>();
  private readonly dashboardsSubject = new LastValueSubject<ReadonlyArrayMap<string, DashboardRef>>();

  /** @internal */
  readonly logger: Logger = inject(LogService).openLogger('dashboards/services/dashboards');

  /** Retrieves the root dashboard, which will enable traversing the dashboards tree. */
  public readonly root$: Observable<DashboardFolderRef>;

  /** Retrieves a flat, unordered list of dashboards currently loaded at any level. */
  public readonly dashboards$: Observable<ReadonlyArrayMap<string, DashboardRef>> = this.dashboardsSubject;

  /** Retrieves a flat, unordered list of dashboard models currently loaded at any level. */
  public readonly models$: Observable<ReadonlyArrayMap<string, DashboardModel>>;

  public readonly zone = inject(NgZone);
  public readonly localeIdProvider = inject(LocaleIdProvider);

  constructor(
    @Inject(DashboardServicesProvider)
    private readonly providers: readonly DashboardServicesProvider[],
  ) {
    if (!providers || providers.length === 0) {
      throw new TypeError('No dashboard providers available.');
    }

    combineLatest(
      providers.map(provider => {
        this.logger.debug('Loading provider', provider);
        return provider.dashboards.getDashboards().pipe(
          retry(1), // Is this necessary? Or should the provider contract state that the `getDashboards()` method should never fail?
          catchError(err => {
            this.logger.error(`Provider ${provider.constructor.name} failed to load.`, err);
            return of([] as readonly DashboardModel[]);
          }),
          map(models => ({ provider, models })),
          tap(data => {
            this.logger.debug('Received latest dashboard from', data);
          }),
        );
      })
    ).pipe(
      takeUntil(this.ngUnsubscribe),
      map(data => data.flatMap(({ provider, models }) => models.map(model => ({ provider, model })))),
      tap(data => {
        this.logger.debug('Received latest dashboards', data);
      }),
      mapToRefs(
        input => new DashboardRefImpl(this, this.localeIdProvider, input.provider, input.model), {
        destroyRef: ref => ref.destroy(),
        updateRef: (ref, input) => ref.syncModel(input.model),
        key: input => input.model.id,
      }),
      tap(data => {
        this.logger.debug('Dashboards updated', data);
      }),
      runInZone(this.zone),
    ).subscribe(this.dashboardsSubject); // subscribe to make hot

    this.models$ = this.dashboardsSubject.pipe(
      map(refs => refs.map(ref => ref.model)),
    );


    this.root$ = this.dashboardsSubject.pipe(
      map(refs => refs.get(KnownDashboardFolderIDs.ROOT) as DashboardFolderRef),
      filter(m => !!m),
      distinctUntilChanged(),
      shareReplay(1),
      runInZone(this.zone),
    );

    (window as any).infrontApp ??= {};
    (window as any).infrontApp.dashboards = this;
  }

  /**
   * Retrieves a {@link DashboardRef}; returned observable will error if the
   * specified ID cannot be found.
   *
   * The observable will only emit once, any changes to the dashboard or its
   * children will not cause a new {@link DashboardFolderRef} to be emitted.
   */
  public get(id: string): Observable<DashboardRef> {
    return this.dashboards$.pipe(
      filter(m => !!m.length),
      take(1),
      map(dashboards => {
        const result = dashboards.get(id);
        if (!result) {
          // TODO: handle lazy loaded folders
          throw new Error(`Cannot find dashboard with ID "${id}".`);
        }
        return result;
      }),
    );
  }

  /**
   * Retrieves a {@link DashboardRef}; returned observable will error if
   * the specified ID cannot be found.
   *
   * The observable will emit whenever the dashboard updates, or any of
   * its children.
   */
  public get$(id: string): Observable<DashboardRef> {
    return this.get(id).pipe(
      switchMap(item => isDashboardFolderRef(item)
        ? item.monitorHierarchyChanges$ : item.model$.pipe(map(() => item))
      )
    );
  }

  /**
   * Retrieves a {@link DashboardFolderRef}; returned observable will error if
   * the specified ID cannot be found.
   *
   * The observable will only emit once, any changes to the folder or its
   * children will not cause a new {@link DashboardFolderRef} to be emitted.
   */
  public getFolder(id: string): Observable<DashboardFolderRef> {
    return this.dashboards$.pipe(
      filter(m => !!m.length),
      take(1),
      map(dashboards => {
        const result = dashboards.get(id);
        if (!result) {
          // TODO: handle lazy loaded folders
          throw new Error(`Cannot find dashboard with ID "${id}".`);
        }
        if (!isDashboardFolderRef(result)) {
          throw new Error(`Dashboard ID "${id}" does not refer to a folder.`);
        }
        return result;
      }),
    );
  }

  /**
   * Retrieves a {@link DashboardFolderRef}; returned observable will error if
   * the specified ID cannot be found.
   *
   * The observable will emit whenever the folder updates or any of its children.
   */
  public getFolder$(id: string): Observable<DashboardFolderRef> {
    return this.getFolder(id).pipe(switchMap(folder => folder.monitorHierarchyChanges$));
  }

  public refresh(): void {
    this.providers.forEach(p => p.dashboards.refresh());
  }

  /** @internal */
  getRef(id: string): DashboardRef | undefined {
    return this.dashboardsSubject.value?.get(id);
  }

  /** @internal */
  getFolderRef(id: string): DashboardFolderRef | undefined {
    const ref = this.dashboardsSubject.value?.get(id);
    if (!ref) {
      return undefined;
    }
    assertDashboardFolderRef(ref);
    return ref;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
