import { type Observable, of } from 'rxjs';

import type { ContextMenuItem } from '../../shared/models/context-menu.model';

export type MainContextItems =
  'AddNewWindow'
  | 'Undo'
  | 'Redo'
  | 'SetLocaleEn'
  | 'SetLocaleNo'
  | 'SetLocaleEnNo'
  | 'SetLocaleDe'
  | 'LogUserSettings'
  | 'LogSearchStorage'
  | 'LogRemoteState'
  | 'LogLocalState'
  | 'LogRemoteWatchlists'
  | 'TriggerChangeDetection'
  | 'DeleteUserSettings'
  | 'DeleteSearchStorage'
  | 'DeleteAllUserSettings'
  | 'ResetToEmptyState'
  | 'ToggleDashboardFolderEditing'
  | 'ShowAdminDialog'
  ;


export type MainContextMenuItem = ContextMenuItem<string, MainContextItems>;

export const menuItems$: Observable<MainContextMenuItem[]> = of([
  {
    closeOnClick: true,
    id: 'AddNewWindow',
    title: 'ADMIN_PANEL_COMPONENT.ITEMS.ADD_NEW_WINDOW',
    icon: 'add',
  },
  {
    closeOnClick: true,
    id: 'ToggleDashboardFolderEditing',
    title: 'ADMIN_PANEL_COMPONENT.ITEMS.TOGGLE_DASHBOARD_FOLDER_EDITING',
    icon: 'add',
  },
  {
    closeOnClick: true,
    id: 'ShowAdminDialog',
    title: 'ADMIN_PANEL_COMPONENT.ITEMS.SHOW_ADMIN_DIALOG',
    icon: 'admin_panel_settings',
  },
  {
    closeOnClick: true,
    id: 'Undo',
    title: 'ADMIN_PANEL_COMPONENT.ITEMS.UNDO',
    icon: 'undo',
  },
  {
    closeOnClick: true,
    id: 'Redo',
    title: 'ADMIN_PANEL_COMPONENT.ITEMS.REDO',
    icon: 'redo',
  },
  {
    triggerChangeDetection: false,
    id: 'TriggerChangeDetection' as MainContextItems,
    title: 'ADMIN_PANEL_COMPONENT.ITEMS.TRIGGER_CHANGE_DETECTION',
    icon: 'refresh',
  },
  {
    closeOnClick: false,
    id: 'SetLocaleEn',
    title: 'ADMIN_PANEL_COMPONENT.ITEMS.SET_LOCALE_EN',
    icon: 'flag',
  },
  {
    closeOnClick: false,
    id: 'SetLocaleNo',
    title: 'ADMIN_PANEL_COMPONENT.ITEMS.SET_LOCALE_NO',
    icon: 'flag',
  },
  {
    closeOnClick: false,
    id: 'SetLocaleEnNo',
    title: 'ADMIN_PANEL_COMPONENT.ITEMS.SET_LOCALE_EN_NO',
    icon: 'flag',
  },
  {
    closeOnClick: false,
    id: 'SetLocaleDe',
    title: 'ADMIN_PANEL_COMPONENT.ITEMS.SET_LOCALE_DE',
    icon: 'flag',
  },
  {
    closeOnClick: false,
    id: 'LogRemoteState',
    title: 'ADMIN_PANEL_COMPONENT.ITEMS.LOG_REMOTE_STATE',
    icon: 'article',
  },
  {
    closeOnClick: false,
    id: 'LogLocalState',
    title: 'ADMIN_PANEL_COMPONENT.ITEMS.LOG_LOCAL_STATE',
    icon: 'article',
  },
  {
    closeOnClick: false,
    id: 'LogUserSettings',
    title: 'ADMIN_PANEL_COMPONENT.ITEMS.LOG_USER_SETTINGS',
    icon: 'article',
  },
  {
    closeOnClick: false,
    id: 'LogSearchStorage',
    title: 'ADMIN_PANEL_COMPONENT.ITEMS.LOG_SEARCH_STORAGE',
    icon: 'article',
  },
  {
    closeOnClick: false,
    id: 'LogRemoteWatchlists',
    title: 'ADMIN_PANEL_COMPONENT.ITEMS.LOG_REMOTE_WATCHLISTS',
    icon: 'article',
  },
  {
    closeOnClick: false,
    id: 'DeleteUserSettings',
    title: 'ADMIN_PANEL_COMPONENT.ITEMS.DELETE_USER_SETTINGS',
    icon: 'warning',
  },
  {
    closeOnClick: false,
    id: 'DeleteSearchStorage',
    title: 'ADMIN_PANEL_COMPONENT.ITEMS.DELETE_SEARCH_STORAGE',
    icon: 'warning',
  },
  {
    closeOnClick: false,
    id: 'DeleteAllUserSettings',
    title: 'ADMIN_PANEL_COMPONENT.ITEMS.DELETE_ALL_USER_SETTINGS',
    icon: 'warning',
  },
  {
    closeOnClick: true,
    id: 'ResetToEmptyState',
    title: 'ADMIN_PANEL_COMPONENT.ITEMS.RESET_TO_EMPTY_STATE',
    icon: 'warning',
  },
]);
