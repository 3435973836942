<ng-container *ngIf="data$ | async as data">
  <div class="wt-symbol-header-info__top">
    <ul class="wt-list-inline">
      <ng-container *ngIf="showDetails">
        <li class="wt-symbol-header-info-description" title="{{ data.fullName | alternativeValue }}">
          {{ data.fullName | alternativeValue }}
        </li>

        <li class="wt-symbol-header-info-currency">
          {{ data.currency | alternativeValue }}
        </li>
      </ng-container>

      <ng-container *ngIf="instrument$ | async as instrument">
        <li class="wt-symbol-header-info-status">
          <wt-symbol-status
            [instrument]="$any(instrument$ | async)"
            [hideSymbolStatus]="['undefined', 'open']"
            (displayStatus)="onDisplayStatus($event)">
          </wt-symbol-status>
        </li>
      </ng-container>

      <ng-container *ngIf="instrument$ | async as instrument">
        <li class="wt-symbol-header-info-events">
          <wt-symbol-events [instrument]="$any(instrument$ | async)"></wt-symbol-events>
        </li>
      </ng-container>

      <ng-container *ngIf="data.fundRating">
        <li class="wt-symbol-header-info-rating">
          <wt-fund-rating [rating]="data.fundRating" [isMorningStarFund]="data.isMorningStarFund"></wt-fund-rating>
        </li>
      </ng-container>
    </ul>
  </div>

  <div class="wt-symbol-header-info__bottom">
    <div class="wt-flex-row">
      <div class="wt-flex-column wt-flex-shrink">
        <ng-container [ngSwitch]="data.symbolClassification" *ngIf="!isCompact">
          <ng-container *ngSwitchCase="SymbolClassification.Forex">
            <wt-country-flag [country]="data.country1" [size]="32"></wt-country-flag>
            <wt-country-flag [country]="data.country2" [size]="32"></wt-country-flag>
          </ng-container>
          <wt-country-flag *ngSwitchDefault [country]="data.country" [size]="32"></wt-country-flag>
        </ng-container>
      </div>

      <div class="wt-flex-column wt-flex-collapse">
        <div class="wt-symbol-header-info-ticker"
          [ngClass]="{ 'wt-symbol-header-info-ticker--clickable': linkInstrumentDashboard }"
          (click)="linkInstrumentDashboard && onTickerClicked($any($event), data)"
          (keypress)="linkInstrumentDashboard && onTickerClicked($event, data)"
          [attr.tabindex]="linkInstrumentDashboard && '0'">
          {{ data.symbolClassification === SymbolClassification.Fund ? data.iSIN : (data.ticker | alternativeValue) }}
        </div>
      </div>
    </div>
  </div>
</ng-container>