<ng-container *ngIf="selectedColumns$ | async as selectedColumns">
  <ng-container *ngIf="context$ | async as context">
    <ng-container *ngIf="data$ | async as data">
      <wt-grid #grid
        class="wt-grid"
        [settings]="gridSettings"
        [gridOptions]="gridOptions"
        [columns]="selectedColumns"
        [data]="data"
        [rowBuffer]="rowBuffer"
        [context]="context"
        [autosizeColumnsAllowed]="autosizeColumnsAllowed"
        [suppressHorizontalScroll]="suppressHorizontalScroll"
        [translatedNoRowsTemplate]="translatedNoRowsTemplate"
        (columnsChanged)="onColumnsChanged($event)"
        (rowsChanged)="rowsChanged.emit($event)"
        (showMore)="showMore.emit()"
        (api)="onGridApi($event)"
        (rowClickedEvent)="rowClickedEvent.emit($event)"
        (rowSelected)="rowSelectedEvent.emit($event)"
        (cellClickedEvent)="cellClickedEvent.emit($event)"
        (selectionChanged)="onSelectionChanged($event)"></wt-grid>
    </ng-container>
    <ng-container *ngIf="streamRequest$ | async"></ng-container>
    <ng-container *ngIf="columnsChangedListener$ | async"></ng-container>
    <ng-container *ngIf="pickedInstruments$ | async"></ng-container>
  </ng-container>
</ng-container>