import type { InfrontSDK } from '@infront/sdk';
import type { DropdownItem } from '../dropdown.model';

export interface WatchlistItem extends InfrontSDK.Watchlist {
  isInEdit: boolean;
  provider?: number;
  providerName?: string;
  subItems?: WatchlistItem[];
}

export type WatchlistDropdownItem = DropdownItem<WatchlistItem>;

export const DROPDOWN_WATCHLIST_CLASS_MOD = '--watchlist';
