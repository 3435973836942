import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, type OnDestroy, type OnInit, inject } from '@angular/core';
import { InfrontSDK, InfrontUtil } from '@infront/sdk';
import { EMPTY, Observable, ReplaySubject, merge, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

import { SdkRequestsService } from '../../services/sdk-requests.service';
import type { Widget } from '../../state-model/widget.model';
import type { Instrument } from '../../state-model/window.model';
import type { SymbolHeaderData } from './symbol-header-info.model';
import { SymbolHeaderInfoService } from './symbol-header-info.service';
import { InstrumentDashboardService } from '../../dashboard/instrument-dashboard.service';


@Component({
  selector: 'wt-symbol-header-info',
  templateUrl: './symbol-header-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SymbolHeaderInfoComponent implements OnInit, OnDestroy {
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly sdkRequestsService = inject(SdkRequestsService);
  private readonly symbolHeaderInfoService = inject(SymbolHeaderInfoService);
  private readonly instrumentDashboardService = inject(InstrumentDashboardService);

  // Instrument can be either set by binding the widget or instrument-objects!
  @Input() set widget(widget: Widget) {
    this.widgetAction.next(widget);
  }
  @Input() set instrument(instrument: Instrument) {
    this.instrumentAction.next(instrument);
  }
  // Data can be provided or component gets it from SDK.
  @Input() set data(data: SymbolHeaderData) {
    this.dataAction.next(data);
  }
  // isCompact = false: show flag(s), show symbol-status behind full-name + currency
  // isCompact = true: hide flag(s), show symbol-status instead of full-name + currency
  @Input() isCompact = false;
  // Wether or not a link to the instrument-dashboard should be set on the "ticker"
  @Input() linkInstrumentDashboard = false;
  showDetails = true;

  private readonly dataAction = new ReplaySubject<SymbolHeaderData | undefined>(1);
  private readonly instrumentAction = new ReplaySubject<Instrument | undefined>(1);
  private readonly widgetAction = new ReplaySubject<Widget | undefined>(1);

  data$!: Observable<SymbolHeaderData>;

  readonly instrument$ = merge(
    this.instrumentAction,
    this.widgetAction.pipe(
      switchMap(widget => widget ? this.sdkRequestsService.windowInstrument$(widget) : EMPTY),
    )
  );

  readonly SymbolClassification = InfrontSDK.SymbolClassification;

  ngOnInit(): void {
    this.data$ = this.dataAction.pipe(
      // components do not need to provide the data, but we need to start anyway!
      startWith(undefined),
      switchMap(data => {
        if (data) {
          return of(data);
        }
        return this.instrument$.pipe(
          switchMap(instrument => instrument ? this.symbolHeaderInfoService.data$(instrument) : EMPTY),
        );
      }),
    );
  }

  ngOnDestroy(): void {
    this.dataAction?.complete();
    this.instrumentAction?.complete();
    this.widgetAction?.complete();
  }

  onDisplayStatus(isVisibleBadge: boolean): void {
    this.showDetails = !this.isCompact || !isVisibleBadge;
    // requires markForCheck with zone-shift, without it is even not working with Subject nor Observable!
    InfrontUtil.setZeroTimeout(() => this.cdRef.markForCheck());
  }

  onTickerClicked(event: PointerEvent | KeyboardEvent, data: SymbolHeaderData): void {
    if ((event.type === 'keypress') && !['Space', 'Enter'].includes((event as KeyboardEvent).code)) {
      return;
    }
    if (data?.ticker && data.feed) {
      const instrument = data as unknown as Instrument;
      this.instrumentDashboardService.addInstrumentDashboardAsync(instrument);
    }
  }

}
