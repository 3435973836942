import { ColumnRegistry } from '../../shared/grid/column-registry';
import type { Column } from '../../shared/grid/columns.model';
import type { ColumnSetting } from '../../state-model/grid.model';

export const HistoryTradesColumns: Column[] = [
  { ...ColumnRegistry.date, sort: 'desc' },
  { ...ColumnRegistry.close, width: 81 },
  { ...ColumnRegistry.volume, width: 79 },
  { ...ColumnRegistry.historyChange, width: 65 },
  { ...ColumnRegistry.historyChangePct, width: 65 },
  { ...ColumnRegistry.turnover, width: 79 },
  ColumnRegistry.open,
  ColumnRegistry.high,
  ColumnRegistry.low,
  ColumnRegistry.bid,
  ColumnRegistry.ask,
  ColumnRegistry.mid,
  ColumnRegistry.VWAP,
  ColumnRegistry.onExchTurnover,
  ColumnRegistry.onFloorVolume,
];

export const HistorySplitsColumns: Column[] = [
  { ...ColumnRegistry.date, sort: 'desc' },
  { ...ColumnRegistry.factor, width: 62 },
];

export const HistoryDividendsColumns: Column[] = [
  { ...ColumnRegistry.date, sort: 'desc' },
  { ...ColumnRegistry.amount, width: 77 },
  { ...ColumnRegistry.currency, width: 62 },
];

export const SelectedHistoryTradesColumns: ColumnSetting[] = [
  { colId: ColumnRegistry.date.colId },
  { colId: ColumnRegistry.close.colId },
  { colId: ColumnRegistry.historyChange.colId },
  { colId: ColumnRegistry.historyChangePct.colId },
  { colId: ColumnRegistry.volume.colId, width: 79 },
  { colId: ColumnRegistry.turnover.colId, width: 79 },
];

export const SelectedHistorySplitsColumns: ColumnSetting[] = [
  { colId: ColumnRegistry.date.colId },
  { colId: ColumnRegistry.factor.colId },
];

export const SelectedHistoryDividendsColumns: ColumnSetting[] = [
  { colId: ColumnRegistry.date.colId },
  { colId: ColumnRegistry.amount.colId },
  { colId: ColumnRegistry.currency.colId },
];