import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { Widget } from '../../state-model/widget.model';
import type { DashboardWindow } from '../../state-model/window.model';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
  <div class="wt-dashboard-window__not-found">
    Cannot instantiate {{ window.name }}-{{ widget.name }}.
  </div>`
})
export class NotFoundComponent {
  @Input({ required: true }) widget!: Widget;
  @Input({ required: true }) window!: DashboardWindow;
}
