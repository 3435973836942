import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';

import { PipesModule } from '../../pipes/pipes.module';
import { MultiSelectComponent } from './multi-select.component';

@NgModule({
  declarations: [MultiSelectComponent],
  imports: [CommonModule, MatListModule, PipesModule],
  exports: [MultiSelectComponent],
})
export class MultiSelectModule {}
