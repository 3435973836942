import type { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import type { DashboardFolderRef, DashboardRef } from '@infront/ngx-dashboards-fx';

export function duplicateName(folder: DashboardFolderRef, self?: DashboardRef): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const name = control.value as string;
    const duplicate = folder.children.some(c => c.model.name === name && c !== self);
    // eslint-disable-next-line no-null/no-null, no-restricted-syntax
    return duplicate ? { duplicateName: { value: name } } : null;
  };
}

export function forbiddenName(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const name = control.value as string;
    const forbidden = /(^\s)|(\s$)/.test(name);
    // eslint-disable-next-line no-null/no-null, no-restricted-syntax
    return forbidden ? { forbiddenName: { value: name } } : null;
  };
}

export const MAX_DASHBOARD_NAME_LENGTH = 100;
