import { Injectable, inject } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { map } from 'rxjs/operators';
import { Observable, of, take } from 'rxjs';

import { ConfirmDialogComponent, type ConfirmDialogData, type ConfirmDialogResult } from '../shared/confirm-dialog/confirm-dialog.component';
import { UserSettingsService } from './user-settings.service';

@Injectable({
  providedIn: 'root',
})
export class ConfirmDialogService {
  private readonly dialog = inject(MatDialog);
  private readonly userSettingsService = inject(UserSettingsService);

  confirmDialog$(data: ConfirmDialogData): Observable<boolean> {
    const { disablePromptSettingKey } = data;
    if (disablePromptSettingKey) {
      const disablePromptSetting = this.userSettingsService.getValue(disablePromptSettingKey);
      if (disablePromptSetting) {
        return of(true);
      }
    }

    return this.dialog.open(ConfirmDialogComponent, { maxWidth: '400px', data }).afterClosed().pipe(take(1),
      map((result: ConfirmDialogResult) => {
        if (!!disablePromptSettingKey && result.disableDeletePrompt) {
          this.userSettingsService.setValue(disablePromptSettingKey, true);
        }
        return result.shouldDelete;
      }));
  }
}


