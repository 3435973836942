<div class="wt-dialog">
  <div class="wt-flex-row wt-flex-align-middle wt-flex-collapse wt-dialog__title">
    <div class="wt-flex-column wt-dialog-icon">
      <mat-icon fontIcon="open_in_new"></mat-icon>
    </div>

    <div class="wt-flex-column">
      <h1 class="wt-h1">New Window</h1>
    </div>
  </div>

  <div mat-dialog-content>
    <mat-form-field floatLabel="never">
      <mat-select class="wt-dialog__select" [(ngModel)]="selectedType" panelClass="wt-dialog__select-panel">
        <mat-option *ngFor="let type of windowTypes" [value]="type">
          {{ type }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="wt-action-buttons">
    <div class="wt-flex-row wt-flex-collapse wt-flex-align-right">
      <div class="wt-flex-column wt-flex-shrink">
        <button class="wt-button wt-button--cancel" type="button" (click)="onCancel()">
          {{ 'GLOBAL.BUTTON.CANCEL' | translate }}
        </button>
      </div>

      <div class="wt-flex-column wt-flex-shrink">
        <button class="wt-button wt-button--primary" type="button" [mat-dialog-close]="selectedType">
          {{ 'GLOBAL.BUTTON.OK' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>