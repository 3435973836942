<ng-container *ngIf="hasTradingFeatureObj$ | async as hasTradingFeatureObj">
  <div *ngIf="{ isConnected: tradingConnected$ | async } as tradingConnectedObj"
    class="wt-flex-column wt-trading-login"
    [hidden]="tradingConnectedObj.isConnected && !hasLogoutButton">
    <ng-container *ngIf="hasTradingFeatureObj.hasTradingFeature">
      <div class="wt-flex-row">
        <div *ngIf="!tradingConnectedObj.isConnected"
          class="wt-flex-column wt-text-center">
          {{ "TRADING_LOGIN_COMPONENT.NOT_LOGGED_IN" | translate }}
        </div>
      </div>

      <div class="wt-flex-row">
        <div class="wt-flex wt-flex-column wt-flex-align-center">
          <button type="button" *ngIf="!tradingConnectedObj.isConnected"
            class="wt-button wt-button--primary" (click)="login()">
            {{ "TRADING_LOGIN_COMPONENT.BUTTON.LOGIN" | translate }}
          </button>

          <button type="button" *ngIf="hasLogoutButton && tradingConnectedObj.isConnected"
            class="wt-button wt-button--primary" (click)="logout()">
            {{ "TRADING_LOGIN_COMPONENT.BUTTON.LOGOUT" | translate }}
          </button>
        </div>
      </div>
    </ng-container>

    <div class="wt-flex-row" *ngIf="!hasTradingFeatureObj.hasTradingFeature">
      <div class="wt-flex-column wt-text-center">
        {{ "TRADING_LOGIN_COMPONENT.NO_TRADING_FEATURE" | translate }}
      </div>
    </div>
  </div>
</ng-container>