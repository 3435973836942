import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';

import { DropdownModule } from '../dropdown/dropdown.module';
import { ContextMenuModule } from '../../dashboard-window/ui/context-menu/context-menu.module';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';
import { AdminPanelComponent } from './admin-panel.component';

@NgModule({
  declarations: [AdminPanelComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    DropdownModule,
    ContextMenuModule,
    DirectivesModule,
    PipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [AdminPanelComponent],
})
export class AdminPanelModule { }
