import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import type { ICellRendererParams } from 'ag-grid-community';

import type { TimePeriod } from '../../../widgets/ranking/ranking.model';
import { TimeSeriesService } from './time-series.service';

@Component({
  selector: 'wt-time-series',
  templateUrl: './time-series.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSeriesComponent {
  private width = 120;
  private height = 40;
  @ViewChild('canvas', { static: true }) canvas!: { nativeElement: HTMLCanvasElement };
  constructor(private timeSeriesService: TimeSeriesService) { }

  agInit(params: ICellRendererParams): void {
    type Data = { symbol: InfrontSDK.SymbolData };
    const timeSeriesMetadata = {
      [InfrontSDK.SymbolField.Ticker]: (params?.data as Data)?.symbol?.get(InfrontSDK.SymbolField.Ticker),
      [InfrontSDK.SymbolField.Feed]: (params?.data as Data)?.symbol?.get(InfrontSDK.SymbolField.Feed),
      [InfrontSDK.SymbolField.PreLastTradedAt]: params.value as number,
      timePeriod: '1 Month' as TimePeriod, // todo: dynamic later
    };
    this.timeSeriesService.initTimeSeries(timeSeriesMetadata, this.canvas.nativeElement, this.width, this.height);
  }
}
