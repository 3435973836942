import { Injectable } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { SdkRequestsService } from '../../services/sdk-requests.service';
import type { Instrument } from '../../state-model/window.model';
import { getCountryForCurrency } from '../../util/country';
import { objectKeysToLowerCaseFirstLetter } from '../../util/object';
import type { ResolvedSymbolValues } from '../../widgets/lists/observe-symbols.service';
import { SnapShotFields, type SymbolHeaderData } from './symbol-header-info.model';

@Injectable({
  providedIn: 'any',
})
export class SymbolHeaderInfoService {
  data$ = (instrument: Instrument): Observable<SymbolHeaderData> =>
    this.sdkRequestsService.snapshotSymbolData$({
      symbolEntity: instrument,
      fields: [
        InfrontSDK.SymbolField.SymbolClassification,
        InfrontSDK.SymbolField.Ticker,
        InfrontSDK.SymbolField.Feed,
      ]
    }).pipe(
      switchMap((preData) => {
        const snapshotFields$ = this.sdkRequestsService.snapshotSymbolData$({ symbolEntity: instrument, fields: SnapShotFields });
        const metaData$ = this.sdkRequestsService.metaDataByFeed$(preData.Feed);
        return combineLatest([snapshotFields$, metaData$]).pipe(
          map(([resolvedSnapshotFields, metaData]) => {
            const extraData = this.extraData(resolvedSnapshotFields, metaData);
            const dataUpdate = objectKeysToLowerCaseFirstLetter(resolvedSnapshotFields);
            return {
              ...dataUpdate,
              ...extraData,
            } as unknown as SymbolHeaderData;
          })
        );
      })
    );

  constructor(private readonly sdkRequestsService: SdkRequestsService) { }

  // extra derived data from Symbol Fields going out to the template
  private extraData(resolvedFields: ResolvedSymbolValues, metaData: InfrontSDK.FeedInfo) {
    return {
      isMorningStarFund: !!metaData?.additionalInfo?.MorningStarFunds,
      country: resolvedFields.CountryOfIncorporation ?? resolvedFields.Country ?? resolvedFields.FeedCountry,
      fundRating: Array.isArray(resolvedFields.FundStarRating) ? resolvedFields.FundStarRating[0] : resolvedFields.FundStarRating,
      country1: getCountryForCurrency(resolvedFields.FxCurrency1),
      country2: getCountryForCurrency(resolvedFields.FxCurrency2),
      hasIntradaySupport: metaData?.minDelaySecs !== 86400,
    };
  }

}
