import { Injectable, inject } from '@angular/core';
import { Infront, InfrontSDK, InfrontUtil } from '@infront/sdk';
import { LogService } from '@vwd/ngx-logging';
import { BehaviorSubject, EMPTY, NEVER, Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import { PORTFOLIO_DASHBOARD_ID } from '../dashboard/providers/portfolio-dashboards';
import { structuresAreEqual } from '../util/equality';
import { SdkRequestsService } from './sdk-requests.service';
import { SdkService } from './sdk.service';
import { ToolkitService } from './toolkit.service';
import { UserSettingsService } from './user-settings.service';

export const isConnected = (state: InfrontSDK.Trading.ConnectionStateInfo): boolean => {
  return state.state === InfrontSDK.Trading.TradingConnectionState.TradingServerConnected;
};

const DisconnectedState = {
  state: InfrontSDK.Trading.TradingConnectionState.TradingServerDisconnected,
  providerId: undefined,
  name: '',
  gateway: undefined,
} as unknown as InfrontSDK.Trading.ConnectionStateInfo;

export enum TradingLoginDialogEvent {
  CANCELED,
  CONNECTED
}

@Injectable({
  providedIn: 'root',
})
export class TradingService {
  private readonly logService = inject(LogService);
  private readonly sdkRequestsService = inject(SdkRequestsService);
  private readonly toolkitService = inject(ToolkitService);
  private readonly sdkService = inject(SdkService);
  private readonly userSettingsService = inject(UserSettingsService);

  // Fix for manual trading login tracking. Needs to be removed once login logic will be fixed.
  isTradingLoggedIn = false;

  private readonly connectionStateAction = new BehaviorSubject<InfrontSDK.Trading.ConnectionStateInfo>(DisconnectedState);
  readonly connectionState$ = this.connectionStateAction.pipe(
    // Fixes disconnectedState emitting multiple times when failing trading login dialog
    // IWT-1221
    distinctUntilChanged(structuresAreEqual),
    shareReplay(1)
  );
  readonly tradingConnected$ = this.connectionState$.pipe(
    map((connectionState) => connectionState.providerId != undefined && isConnected(connectionState)),
  );

  readonly hasTradingFeature$ = this.sdkRequestsService
    .loginData$({
      flags: {
        Features: true,
        // LoginDetails: true, // FIXME: not yet sure if we need also the user login details!
      },
    })
    .pipe(
      take(1),
      map((loginData) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return !!(loginData?.features as any)?.['HasTrading'];
      }),
      shareReplay(1),
    );

  private isTradingLoginDialogVisible: InfrontUtil.Observable<boolean> | undefined;
  private readonly tradingLoginDialogVisibilityAction = new ReplaySubject<boolean>(1);
  readonly tradingLoginDialogVisibility$ = this.tradingLoginDialogVisibilityAction.asObservable();

  private readonly tradingLoginDialogEventAction = new BehaviorSubject<TradingLoginDialogEvent | undefined>(undefined);
  readonly tradingLoginDialogEvent$ = this.tradingLoginDialogEventAction.asObservable();

  private readonly logger = this.logService.openLogger('services/trading');

  constructor() {
    // Various tradingLoginDialog related observations
    this.toolkitService.infrontUI$.pipe(
      tap((ui) => {
        ui.registerEventObserver(Infront.TradingLoginCanceledEvent.kEventName, () => this.tradingLoginDialogEventAction.next(TradingLoginDialogEvent.CANCELED));
        ui.registerEventObserver(Infront.TradingConnectedEvent.kEventName, () => this.tradingLoginDialogEventAction.next(TradingLoginDialogEvent.CONNECTED));
        this.isTradingLoginDialogVisible?.unbindAll();
        this.tradingLoginDialogVisibilityAction.next(false); // reset on InfrontUI change
        this.isTradingLoginDialogVisible = ui.isTradingLoginDialogVisible as InfrontUtil.Observable<boolean>;
        this.isTradingLoginDialogVisible.observe({
          valueUpdated: (visibility: boolean) => this.tradingLoginDialogVisibilityAction.next(visibility)
        });
      })
    ).subscribe();

    // Close TradingLoginDialog when connection is suddenly established
    combineLatest([this.tradingLoginDialogVisibility$, this.tradingConnected$]).pipe(
      switchMap(([visible, connected]) => visible && connected ? this.hideTradingLoginDialog$ : EMPTY)
    ).subscribe();

    // Global trading connection observation
    this.sdkService
      .getObject$<InfrontSDK.Trading.ConnectionOptions<InfrontSDK.Trading.TradingConnectionAction>>(InfrontSDK.Trading.connection, {
        action: 'Observe' as InfrontSDK.Trading.TradingConnectionAction.Observe,
      }, 'tradingService const InfrontSDK.Trading.connection')
      .pipe(
        tap((connectionState) => {
          this.connectionStateAction.next(connectionState as InfrontSDK.Trading.ConnectionStateInfo);
        })
      ).subscribe();

    // Prompt trading login any time the portfolio dashboard is selected and trading is not connected
    this.userSettingsService
      .getValue$('dashboardSelectedDashboardId')
      .pipe(
        switchMap((id) => {
          this.isTradingLoggedIn = true;

          return this.tradingConnected$.pipe(
            filter((connected) => !connected),
            switchMap(() => id == PORTFOLIO_DASHBOARD_ID && this.isTradingLoggedIn ? this.openTradingLoginDialog$ : this.hideTradingLoginDialog$)
          );
        })
      ).subscribe();

    // Broadcast selected portfolio to WTK widgets
    combineLatest([this.toolkitService.infrontUI$, this.activePortfolioId$])
      .pipe(
        tap(([ui, portfolioName]) => {
          if (portfolioName) {
            ui.getModel().setActivePortfolio(portfolioName);
          }
        })
      ).subscribe();
  }

  readonly toggleTradingLoginDialog$ = (rememberCanceledLogin: boolean) => this.tradingConnected$.pipe(
    // tap((connected) => console.log('toggleTradingLoginDialog$ connected', connected)),
    switchMap((connected) => connected
      ? EMPTY
      : this.toolkitService.infrontUI$.pipe(
        take(1),
        tap((infrontUI) => {
          infrontUI.ensureTradingLoggedIn(rememberCanceledLogin);
        })
      )
    ),
  );

  readonly openTradingLoginDialog$ =
    this.tradingConnected$.pipe(
      switchMap((connected) => connected ? EMPTY : this.toolkitService.infrontUI$),
      tap((infrontUI) => {
        if (this.isTradingLoggedIn) {
          this.isTradingLoggedIn = false;
          infrontUI.showTradingDialog();
        }
      }),
    );

  readonly hideTradingLoginDialog$ = this.toolkitService.infrontUI$.pipe(
    take(1),
    tap((infrontUI) => {
      infrontUI.hideTradingDialog();
    })
  );

  private sdkPortfolioList$(providerId: number): Observable<InfrontSDK.Trading.Portfolio[]> {
    return this.sdkService.getObject$(InfrontSDK.Trading.portfolioList, {
      providerId,
      includeTradingPower: true,
    }, 'sdkPortfolioList$');
  }

  readonly portfolioList$ = (
    this.tradingConnected$.pipe(
      // use debounceTime to fix login racing condition
      // we use withLatestFrom instead of possible combineLatest to only subscribe to one Observable
      debounceTime(0),
      withLatestFrom(this.connectionState$),
      switchMap(([connected, connectionState]) => {
        if (!connected || !connectionState?.providerId) {
          return of(undefined);
        }
        return this.sdkPortfolioList$(connectionState.providerId);
      }),
      shareReplay(1)
    ));

  readonly selectedPortfolio$ = combineLatest([this.userSettingsService.getValue$('tradingSelectedPortfolioId'), this.portfolioList$]).pipe(
    debounceTime(0), // race condition handling when both update (at best synchronously)
    map(([pfId, pfList]) => pfId ? pfList?.find((pf) => pf.name === pfId) : pfList?.[0]),
    shareReplay(1),
  );

  readonly selectedPortfolioObj$ = this.selectedPortfolio$.pipe(
    map((selectedPortfolio) => ({ portfolio: selectedPortfolio }))
  );

  readonly portfolioData$ = this.connectionState$.pipe(
    switchMap((connection) => {
      if (!isConnected(connection)) {
        return NEVER;
      }
      const getPortfolio$ = (portfolioName: string) => {
        const opts = { providerId: connection.providerId, portfolioName, subscribe: true };
        return this.sdkService.getObject$(InfrontSDK.Trading.portfolio, opts);
      };

      return combineLatest([this.portfolioList$, this.activePortfolioId$]).pipe(
        switchMap(([list, portfolioId]) => {
          if (!list?.length) {
            return NEVER;
          }
          const listNames = list.map(portfolio => portfolio.name);
          if (!!portfolioId && listNames.includes(portfolioId)) {
            return getPortfolio$(portfolioId);
          }
          return getPortfolio$(listNames[0]);
        })
      );
    })
  );

  readonly tradingLogout$ = this.connectionState$.pipe(
    take(1),
    //tap((connected) => console.log('tradingLogout$ connected', connected)),
    switchMap((connectionState) => {
      this.isTradingLoggedIn = true;

      if (isConnected(connectionState)) {
        const opts: Partial<InfrontSDK.Trading.ConnectionOptions<InfrontSDK.Trading.TradingConnectionAction.Logout>> = {
          action: InfrontSDK.Trading.TradingConnectionAction.Logout,
          providerId: connectionState.providerId,
        };
        return this.sdkService.getObject$(InfrontSDK.Trading.connection, opts).pipe(
          tap(() => {
            this.connectionStateAction.next(DisconnectedState);
          })
        );
      }
      return EMPTY; // already disconnected
    }),
    switchMap(() => {
      const selectedDashboardId = this.userSettingsService.getValue('dashboardSelectedDashboardId');
      if (selectedDashboardId === PORTFOLIO_DASHBOARD_ID) {
        return this.openTradingLoginDialog$;
      }
      return EMPTY;
    })
  );

  readonly activePortfolio$ = combineLatest([this.portfolioList$, this.userSettingsService.getValue$('tradingSelectedPortfolioId')]).pipe(
    map(([portfolios, activePortfolioId]) => portfolios?.find((p) => p.name === activePortfolioId)),
  );

  readonly activePortfolioId$ = this.activePortfolio$.pipe(
    map((portfolio) => portfolio?.name)
  );


  // gateways for debug

  // gateways$ = of(undefined).pipe(
  //   switchMap(() => {
  //     return this.sdkService.getObject$(InfrontSDK.Trading.connection, {
  //       action: InfrontSDK.Trading.TradingConnectionAction.Gateways,
  //     });
  //   })
  // ).subscribe(result => {
  //   result;
  // });

  // setTradingChannelObserver needed if we implement tradingChannels

  // private setTradingChannelObserver$ = this.connectionState$.pipe(
  //   switchMap((connection) => {
  //     if (!isConnected(connection)) {
  //       return of(false);
  //     }
  //     const portfolioId = this.userSettingsService.getValue('tradingSelectedPortfolioId');
  //     const opts = {
  //       providerId: connection.providerId,
  //       channelId: 'channel-1',
  //       subscribe: true,
  //       portfolioId,
  //     };
  //     let unbind: InfrontSDK.Unbind;
  //     let channelRef: InfrontSDK.Trading.TradingChannelData;
  //     return this.sdkService.getObject$(InfrontSDK.Trading.channel, opts).pipe(
  //       tap((channel) => {
  //         const channelObserver: InfrontSDK.Trading.ChannelObserver = {
  //           onActivePortfolioReady: (data: InfrontSDK.Trading.PortfolioData) => {
  //             this.portfolioDataAction.next(data);
  //           },
  //           onActivePortfolioChanged: (data: InfrontSDK.Trading.PortfolioData) => {
  //             this.portfolioDataAction.next(data);
  //           },
  //         };
  //         unbind = channel.observe(channelObserver);
  //         channel.setActivePortfolio(portfolioId);
  //         channelRef = channel;
  //       }),
  //       map(() => true),
  //       finalize(() => {
  //         unbind();
  //         channelRef.done();
  //       })
  //     );
  //   })
  // );
}
