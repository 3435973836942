import { ChangeDetectionStrategy, Component, EventEmitter, Input, type OnChanges, type OnDestroy, Output, type SimpleChanges, inject } from '@angular/core';
import { InfrontUtil } from '@infront/sdk';
import { FormattingService } from '@vwd/ngx-i18n';
import { ResourceService } from '@vwd/ngx-i18n/translate';
import type {
  Column as AgColumn,
  BodyScrollEvent,
  CellClickedEvent,
  ColDef,
  FirstDataRenderedEvent,
  GetRowIdParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  GridSizeChangedEvent,
  IRowNode,
  ModelUpdatedEvent,
  RowClickedEvent,
  RowSelectedEvent,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { ReplaySubject, Subject, combineLatest, distinctUntilChanged, map, startWith } from 'rxjs';

import { ToolkitThrottlingTime } from '../../services/toolkit.service';
import type { SymbolDataItem } from '../../shared/models/symbol-data.model';
import { getDecimals } from '../../util/symbol';
import { FeedFilterComponent } from '../../widgets/news/feed-filter/feed-filter.component';
import { HeadlineCellComponent } from '../../widgets/news/headline-cell/headline-cell.component';
import { BigNumberComponent } from '../big-number/big-number.component';
import { StarRatingComponent } from '../star-rating/star-rating.component';
import { CellFlashComponent } from './cell-flash/cell-flash.component';
import { CellSymbolStatusComponent } from './cell-symbol-status/cell-symbol-status.component';
import { type Column, type GridSettings, type RowSelection, contextMenuColumn, matCheckboxColumn, settingsGeneratedColumns } from './columns.model';
import { CountryDisplayNameCellComponent } from './country-flag-cell/country-display-name-cell.component';
import { CountryFlagCellComponent } from './country-flag-cell/country-flag-cell.component';
import { CountryFlagTickerCellComponent } from './country-flag-cell/country-flag-ticker-cell.component';
import { DistributionBarCellComponent } from './distribution-bar-cell/distribution-bar-cell.component';
import { type GetRowIdParamsExt, type GridContext, noRowsDefaults, na, type TranslatedNoRowsTemplate } from './grid.model';
import { MatCheckboxComponent } from './mat-checkbox/mat-checkbox';
import { PerformanceBarCellComponent } from './performance-bar-cell/performance-bar-cell.component';
import { PerformanceBarTooltipComponent } from './performance-bar-tooltip/performance-bar-tooltip.component';
import { PositionsExposureBarCellComponent } from './positions-exposure-bar-cell/positions-exposure-bar-cell.component';
import { PriceArrowComponent } from './price-arrow/price-arrow.component';
import { RankingCellComponent } from './ranking-cell/ranking-cell.component';
import { RowMenuComponent } from './row-menu/row-menu.component';
import { TimeSeriesRequestComponent } from './time-series-request/time-series-request.component';
import { TimeSeriesComponent } from './time-series/time-series.component';
import { LOCALE_ID$, translator } from '../../util/locale';
import { structuresAreEqual } from '../../util/equality';
import { UserSettingsService } from '../../services/user-settings.service';

export const DEFAULT_ROW_BUFFER_SIZE = 10;

@Component({
  selector: 'wt-grid',
  templateUrl: './grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent implements OnDestroy, OnChanges {
  private readonly format = inject(FormattingService);
  private readonly translate = inject(ResourceService);
  private readonly localeId$ = inject(LOCALE_ID$);
  private readonly userSettingsService = inject(UserSettingsService);
  private readonly xlat = translator();

  private _data: unknown[] | undefined;
  @Input() set data(dataValues: unknown[] | undefined) {
    this._data = dataValues;
    // console.log('gridInput', dataValues); // NOSONAR debugging
  }
  get data(): unknown[] | undefined {
    return this._data;
  }

  @Input() gridOptions: GridOptions = {};

  @Input() settings: GridSettings = {};

  private _columns: Column[] | undefined;
  @Input() set columns(cols: Column[] | undefined) {
    this._columns = cols;
    if (cols?.length === 0) {
      return;
    }
    if (!this.columnDefs) {
      this.columnDefs = this.getColumnDefs(cols);
      return;
    }
    // this.gridApi?.setGridOption([]); // ag grid wont update already defined columns, need to delete and reset columns to trigger update of every column
    this.gridApi?.setGridOption('columnDefs', this.getColumnDefs(cols));
  }
  get columns(): Column[] | undefined {
    return this._columns;
  }

  private _baseContext: GridContext = Object.freeze({
    translate: this.translate,
    format: this.format,
    userSettingsService: this.userSettingsService,
    xlat: this.xlat,
  });

  gridContext: GridContext = this._baseContext;

  private _context: object | undefined;

  @Input()
  get context(): object | undefined {
    return this._context;
  }
  set context(value: object | undefined) {
    this._context = value;
    if (value == undefined) {
      this.gridContext = this._baseContext;
    } else if (typeof value === 'object') {
      this.gridContext = { ...this._baseContext, ...value };
    } else {
      throw new TypeError('Context must be an object or null/undefined.');
    }
  }

  @Input() rowBuffer = DEFAULT_ROW_BUFFER_SIZE;
  @Input() autosizeColumnsAllowed = true;
  @Input() suppressHorizontalScroll = false;
  @Input() set translatedNoRowsTemplate(translatedNoRowsTemplate: TranslatedNoRowsTemplate | undefined) {
    this.translatedNoRowsTemplateAction.next(translatedNoRowsTemplate);
  }
  private readonly translatedNoRowsTemplateAction = new ReplaySubject<TranslatedNoRowsTemplate | undefined>(1);
  readonly overlayNoRowsTemplate$ = combineLatest([
    this.translatedNoRowsTemplateAction.pipe(
      startWith(undefined),
      distinctUntilChanged(structuresAreEqual)
    ),
    this.localeId$,
  ]).pipe(
    map(([translatedOpts]) => {
      const content = translatedOpts?.translatedContent ?? this.xlat(noRowsDefaults.content);
      const element = translatedOpts?.element ?? noRowsDefaults.element;
      const overlayNoRowsTemplate = `<${element}>${content}</${element}>`;
      this.gridApi?.setGridOption('overlayNoRowsTemplate', overlayNoRowsTemplate);
      this.gridApi?.showNoRowsOverlay();
      return overlayNoRowsTemplate;
    }),
  );
  @Output() readonly rowClickedEvent = new EventEmitter<RowClickedEvent>();
  @Output() readonly cellClickedEvent = new EventEmitter<CellClickedEvent>();
  @Output() readonly selectionChanged = new EventEmitter<SelectionChangedEvent>();
  @Output() readonly rowSelected = new EventEmitter<RowSelectedEvent>();
  @Output() readonly modelUpdated = new EventEmitter<ModelUpdatedEvent>();
  @Output() readonly columnsChanged = new EventEmitter<Array<Column>>();
  @Output() readonly rowsChanged = new EventEmitter<unknown[]>();
  @Output() readonly showMore = new EventEmitter<void>();
  @Output() readonly api = new EventEmitter<GridApi>();
  rowData: Array<unknown> | undefined;
  columnDefs: Array<ColDef> | undefined;
  defaultColumn = {
    // resizable is `true` by default since v30, we keep the default setting here for explicity!
    resizable: true,
    // sortable is `true` by default since v30, we keep the default setting here for explicity!
    sortable: true,
    // cellDataType is `true` by default since v30, we need to disable it for defaultColumn!
    // If set to `true`, all cell values have automatically a `valueFormatted` property set!
    // But we need undefined `valueFormatted` until we explicit set a formatter by ourselves!
    cellDataType: false,
  };
  frameworkComponents = {
    rowMenuComponent: RowMenuComponent,
    headlineCellComponent: HeadlineCellComponent,
    feedFilterComponent: FeedFilterComponent,
    timeSeriesComponent: TimeSeriesComponent,
    timeSeriesRequestComponent: TimeSeriesRequestComponent,
    rankingCellComponent: RankingCellComponent,
    priceArrowComponent: PriceArrowComponent,
    performanceBarCellComponent: PerformanceBarCellComponent,
    performanceBarTooltipComponent: PerformanceBarTooltipComponent,
    countryFlagCellComponent: CountryFlagCellComponent,
    countryFlagTickerCellComponent: CountryFlagTickerCellComponent,
    countryDisplayNameCellComponent: CountryDisplayNameCellComponent,
    distributionBarCellComponent: DistributionBarCellComponent,
    positionsExposureBarCellComponent: PositionsExposureBarCellComponent,
    bigNumberCellComponent: BigNumberComponent,
    cellFlashComponent: CellFlashComponent,
    starRatingComponent: StarRatingComponent,
    matCheckboxComponent: MatCheckboxComponent,
    cellSymbolStatusComponent: CellSymbolStatusComponent,
  }; // todo: perhaps optimize by supplying needed components dynamically

  rowSelection: RowSelection = undefined;

  readonly throttlingTime = ToolkitThrottlingTime;

  private gridApi: GridApi | undefined;
  // private lastColumnState: ReturnType<typeof this.gridColumnApi.getColumnState>;
  private readonly ngUnsubscribe = new Subject<void>();

  ngOnDestroy(): void {
    this.gridApi?.flushAsyncTransactions();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.frameworkComponents as any) = undefined;
    if (!this.gridApi?.isDestroyed()) {
      this.gridApi?.destroy();
    }
    this.translatedNoRowsTemplateAction.complete();
    // to prevent memory leak
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onGridReady(params: GridReadyEvent): void {
    if (!params.api) {
      return;
    }
    this.gridApi = params.api;
    this.api.emit(params.api);
    this.applySettings(params.api);
  }

  onFirstDataRendered(event: FirstDataRenderedEvent) {
    if (
      this.autosizeColumnsAllowed &&
      this.settings?.autoSizeColumns &&
      !this.columnDefs?.filter((col) => col.colId !== 'contextMenu').some((col) => !!col.width) // if any col has a width set we don't autoSize
    ) {
      // todo: don't we want to do this only if not column width is set?
      this.autoSizeAllColumns(event.api);
      this.updateColumns({ api: this.gridApi! });
    }
  }

  onGridSizeChanged(event: GridSizeChangedEvent): void {
    this.applySettings(event.api);
    event.api.resetRowHeights();
  }

  onDragStopped(event: { api: GridApi; }): void {
    this.updateColumns(event);
    if (!this.settings?.rowDrag) {
      return;
    }
    const nodes: IRowNode[] = [];
    this.gridApi!.forEachNode((node) => {
      nodes.push(node);
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    const reOrderedRows = nodes.map((node: IRowNode) => this.data?.find((item: any) => item.index === node.data.index));
    this.rowsChanged.emit(reOrderedRows);
  }

  onBodyScroll(event: BodyScrollEvent): void {
    if (event.direction !== 'vertical') {
      return;
    }
    if (this.data && this.gridApi) {
      if (this.data.length - 1 === this.gridApi.getLastDisplayedRow()) {
        this.showMore.emit();
      }
    }

  }

  getRowId = (params: GetRowIdParams<unknown>): string => { // because `data` is `unknown[]`, ag-grid expects the getRowId fn to have an unknown params too
    const index = (params as GetRowIdParamsExt).data?.index;
    if (!index) {
      throw new Error('ag-grid: getRowId can not find index in params. This need to be fixed!');
    }
    return String(index);
  };

  select(nodeId: string | GetRowIdParamsExt): void {
    if (nodeId && typeof nodeId === 'object') {
      nodeId = this.getRowId(nodeId);
    }
    if (nodeId) {
      if (this.gridOptions.rowSelection === 'single') {
        this.gridApi?.getRowNode(nodeId)?.setSelected(true, true);
      } else {
        this.gridApi?.getRowNode(nodeId)?.setSelected(true, false);
      }
    } else {
      this.gridApi?.deselectAll();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // we have columns that are added or removed by settings so we need to check if columns should be updated when settings change
    const currentSettings = changes?.settings?.currentValue as GridSettings;
    if (currentSettings?.matCheckbox !== undefined || currentSettings?.rowDrag !== undefined) {
      this.gridApi?.setGridOption('columnDefs', this.getColumnDefs(this.columns, currentSettings));
    }
  }

  get selectedRow(): unknown {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.gridApi?.getSelectedRows()?.[0];
  }

  get selectedRows(): unknown[] {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.gridApi?.getSelectedRows() ?? [];
  }

  onCellClicked(event: CellClickedEvent) {
    const pointerEvent = event.event as PointerEvent;
    if (pointerEvent.ctrlKey || pointerEvent.shiftKey) {
      if (pointerEvent.ctrlKey) {
        event.node?.setSelected(!event.node?.isSelected());
        this.cellClickedEvent.next(event);
        return;
      }
      // implement multiple row selection through shift key - this is default behavior in ag grid that had to be turned off by suppressRowClickSelection because it interfered with other row selection requirements
      if (pointerEvent.shiftKey) {
        const selectedNodes = this.gridApi!.getSelectedNodes();
        const lastSelectedRowIndex = selectedNodes[selectedNodes.length - 1]?.rowIndex;
        const currentRowIndex = event.node.rowIndex;
        if (lastSelectedRowIndex != undefined && currentRowIndex != undefined && lastSelectedRowIndex !== currentRowIndex) {
          let [start, end] = [Math.min(lastSelectedRowIndex, currentRowIndex), Math.max(lastSelectedRowIndex, currentRowIndex)];
          if (this.settings?.maxSelectableRows && selectedNodes.length + (end - start - 1) > this.settings.maxSelectableRows) {
            const difference = selectedNodes.length + (end - start - 1) - this.settings.maxSelectableRows;
            if (start < currentRowIndex) {
              end -= difference;
            } else {
              start += difference;
            }
          }
          for (let i = start; i <= end; i++) {
            this.gridApi!.getDisplayedRowAtIndex(i)?.setSelected(true);
          }
          return;
        }
        event.node?.setSelected(!event.node?.isSelected());
        this.cellClickedEvent.next(event);
        return;
      }
    }

    const matCheckboxExists = this.gridApi?.getColumnDefs()?.find((col) => (col as ColDef).colId === 'matCheckbox');
    if (!matCheckboxExists && this.rowSelection === 'multiple') {
      this.gridApi?.deselectAll();
    }
    this.cellClickedEvent.next(event);
  }

  onRowSelected(event: RowSelectedEvent) {
    // Do not trigger onRowSelected when a row is *deselected*
    // in single select mode
    if (this.settings?.rowSelection === 'single' && !event.node.isSelected()) {
      return;
    }
    if (this.settings?.maxSelectableRows) {
      const selectedRows = this.gridApi!.getSelectedRows();
      if (selectedRows.length > this.settings.maxSelectableRows) {
        event?.node?.setSelected(false);
        return;
      }
    }
    this.rowSelected.next(event);
  }

  private getColumnDefs(columns = this.columns, settings = this.settings): Array<ColDef> {
    let colDefs = columns?.map((col) => removeNonAgGridProperties({
      ...col,
      valueFormatter: typeof col.valueFormatter === 'string' ? this.formatters[col.valueFormatter] : col.valueFormatter,
    })) || [];

    if (settings?.rowDrag) {
      colDefs[0].rowDrag = true;
    }
    if (settings?.checkboxSelection) {
      colDefs[0].checkboxSelection = true;
    }
    if (settings?.disableSorting) {
      for (const colDef of colDefs) {
        colDef.sortable = false;
      }
    }
    if (settings?.rowContextMenu) {
      colDefs = [contextMenuColumn, ...colDefs.filter((colDef) => colDef.colId !== contextMenuColumn.colId)];
    }
    if (settings?.matCheckbox
    ) {
      colDefs = [matCheckboxColumn, ...colDefs.filter((colDef) => colDef.colId !== matCheckboxColumn.colId)];
    }
    return colDefs;
  }

  updateColumns(event: { api: GridApi; }) {
    const selectedColumns = event.api
      .getAllDisplayedColumns()
      .map((agCol: AgColumn) => {
        return { colId: agCol.getColId(), sort: agCol.getSort(), width: agCol.getActualWidth() } as Column;
      })
      .filter((column) => !settingsGeneratedColumns.includes(column.colId));

    this.columnsChanged.emit(selectedColumns);

    this.gridApi?.redrawRows();
  }

  onModelUpdated() {
    if (this.settings?.sizeColumnsToFit) {
      this.gridApi?.sizeColumnsToFit();
    }
  }

  private autoSizeAllColumns(api = this.gridApi!) {
    const skipHeader = false;
    const allColumnIds: string[] = [];
    api.getColumns()?.forEach((column) => allColumnIds.push(column.getId()));
    api.autoSizeColumns(allColumnIds, skipHeader);
  }

  private applySettings = (api = this.gridApi, settings = this.settings) => {
    if (settings?.sizeColumnsToFit) {
      api?.sizeColumnsToFit();
    }
    if (settings?.rowSelection) {
      this.rowSelection = settings.rowSelection;
      if (settings.rowSelection === 'multiple') {
        api?.setGridOption('suppressRowClickSelection', true);
      }
    }
  };

  private formatters: { [key: string]: (params: { value: string; data: SymbolDataItem }) => string; } = {
    dateFormatter: (params: { value: string; }): string => {
      // 2-digit year
      return params.value ? this.format.formatDateTime(new Date(params.value), 'shortestDate') : na;
    },
    timeFormatter: (params: { value: string; }): string => {
      return params.value ? this.format.formatDateTime(new Date(params.value), 'HH:mm:ss') : na;
    },
    timeOrDateFormatter: (params: { value: string; }): string => {
      // time in HH:mm:ss format and date with 2-digit year
      return params.value ? this.format.formatDateTime(new Date(params.value), 'timeOrShortestDate') : na;
    },
    dateOrTodayFormatter: (params: { value: string; }): string => {
      if (!params.value) {
        return na;
      }
      const date = new Date(params.value);
      return !InfrontUtil.isToday(date) ? this.format.formatDateTime(date, 'shortestDate') : this.translate.get('GLOBAL.TODAY') as string;
    },
    sdkDecimals: (params: { value: string, data: SymbolDataItem; }): string => {
      // format prices (last, high, low, open, close, ...) to exact the amount of decimals delivered by SDK field "Decimals".
      const decimals = getDecimals(params?.data);
      return params.value != undefined ? this.format.formatNumber(+params.value, `1.${decimals}`) : na;
    },
    sdkDecimalsFlex: (params: { value: string, data: SymbolDataItem; }): string => {
      // format numbers to minimum amount of real decimals the number has
      // and amount of decimals delivered by SDK field "Decimals".
      const decimals = getDecimals(params?.data);
      return params.value != undefined ? this.format.formatNumber(+params.value, `1.0-${decimals}`) : na;
    },
    sdkDecimalsCurrency: (params: { value: string, data: SymbolDataItem; }): string => {
      // expect the params.value in format "[value: number | null, currency: string]"
      if (params.value == undefined) {
        return na;
      }
      const decimals = getDecimals(params?.data);
      const [value, currency] = JSON.parse(params.value) as [number, string];
      return (value != undefined ? this.format.formatNumber(+value, `1.${decimals}`) : na)
        + (currency ? ` ${currency}` : '');
    },
    sdkDecimalsCurrencyFlex: (params: { value: string, data: SymbolDataItem; }): string => {
      // expect the params.value in format "[value: number | null, currency: string]"
      // format numbers to minimum amount of real decimals the number has
      // and amount of decimals delivered by SDK field "Decimals".
      if (params.value == undefined) {
        return na;
      }
      const decimals = getDecimals(params?.data);
      const [value, currency] = JSON.parse(params.value) as [number, string];
      return (value != undefined ? this.format.formatNumber(+value, `1.0-${decimals}`) : na)
        + (currency ? ` ${currency}` : '');
    },
    twoDecimals: (params: { value: string; }): string => {
      return params.value != undefined ? this.format.formatNumber(+params.value, '1.2') : na;
    },
    twoDecimalsPercent: (params: { value: string; }): string => {
      return params.value != undefined ? `${this.format.formatNumber(+params.value, '1.2')}%` : na;
    },
    // integer on values over 100%, two decimals below
    twoDecimalsOrIntegerPercent: (params: { value: string; }): string => {
      return params.value != undefined ? `${this.format.formatNumber(+params.value, +params.value >= 100 ? '1.0' : '1.2')}%` : na; // NOSONAR false positive: the ternary is not nested!
    },
    twoDecimalsCurrency: (params: { value: string; }): string => {
      // expect the params.value in format [value: number | null, currency: string]
      if (params.value == undefined) {
        return na;
      }
      const [value, currency] = JSON.parse(params.value) as [number, string];
      return (value != undefined ? `${this.format.formatNumber(+value, '1.2')}` : na)
        + (currency ? ` ${currency}` : '');
    },
    threeDecimals: (params: { value: string; }): string => {
      return params.value != undefined ? this.format.formatNumber(+params.value, '1.3') : na;
    },
    oneDecimalX: (params: { value: string; }): string => {
      return params.value != undefined ? `${this.format.formatNumber(+params.value, '1.1')}x` : na;
    },
    integer: (params: { value: string | number | undefined; }): string => {
      return params.value != undefined ? `${this.format.formatNumber(+params.value, '1.0')}` : na;
    },
    // use cellRenderer: 'bigNumberCellComponent' instead
    // bigNumber: (params: { value: string }): string => {
    //   // TEST: check if we can reproduce the format from InfrontUtil.formatAndShorten(params.value, 0)
    //   const value = +params.value;
    //   const options = BigNumberComponent.formatOptions(value);
    //   return params.value != undefined ? this.format.formatBigNumber(value, options) : na;
    //   // Locale handling of InfrontUtil not compatible to the rest!
    //   // return params.value != undefined ? InfrontUtil.formatAndShorten(params.value, 0) : na;
    // },
    translate: (params: { value: string }): string => {
      return params.value ? this.translate.get(params.value) as string : na;
    },
  };
}

function removeNonAgGridProperties(colDef: Column): ColDef {
  delete colDef['longHeaderName'];
  return colDef;
}
