import { Injectable, inject } from '@angular/core';
import type { DashboardServicesProvider } from '../dashboard-services.provider';
import { BuiltInWidgetDataProvider } from './builtin-widget-data.provider';
import { BuiltInDashboardProvider } from './builtin-dashboard.provider';

@Injectable({ providedIn: 'root' })
export class BuiltInDashboardServicesProvider implements DashboardServicesProvider {
  public readonly dashboards = inject(BuiltInDashboardProvider);
  public readonly widgets = inject(BuiltInWidgetDataProvider);
}
