import { ChangeDetectionStrategy, Component, EventEmitter, Input, type OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { auditTime } from 'rxjs/operators';

import { alertNewAnimation, alertNewAnimationTime } from '../alerts-list.animations';
import type { AlertEvent } from '../alerts-list.model';

@Component({
  selector: 'wt-alert-log-list', // the name alert-log is reserved for the widget component that also uses this shared component
  templateUrl: './alert-log-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [alertNewAnimation],
})
export class AlertLogListComponent implements OnDestroy {
  @Input({ required: true }) alertLog!: AlertEvent[];
  @Output() triggerClose = new EventEmitter<void>();

  readonly markAsSeenAction = new Subject<AlertEvent[]>();

  // the timeout (auditTime) is a workaround to both not abort animations prematurely and not invoke
  // ExpressionChangedAfterItHasBeenCheckedError when closing window before animation
  @Output() markAsSeen = this.markAsSeenAction.asObservable().pipe(auditTime(alertNewAnimationTime + 100));

  ngOnDestroy(): void {
    this.markAsSeenAction.complete();
  }

  readonly alertIdentifier = (index: number, item: AlertEvent) => JSON.stringify({ key: item.key, isNew: item.isNew });
}
