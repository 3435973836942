import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { MFE } from '@vwd/microfrontend-core';
import { LogService } from '@vwd/ngx-logging';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UserSettingsService } from '../../services/user-settings.service';
import { LOCALE_ID$ } from '../../util/locale';

type Locale = 'en-DE' | 'en-NO' | 'en-GB' | 'no-NO' | 'de-DE';

@Component({
  selector: 'wt-settings-menu-content',
  templateUrl: './settings-menu-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsMenuContentComponent {
  private readonly logger = inject(LogService).openLogger('settings-menu');
  private readonly mfe = inject(MFE);
  readonly userSettingsService = inject(UserSettingsService);
  readonly localeId$ = inject(LOCALE_ID$).pipe(
    // set language from mfe-locale
    tap((locale) => {
      this.logger.info('Current locale from MFE:', locale);
      this.selectedLocale = locale as Locale;
    }),
  );

  @Input({ required: true }) backdropClick$!: Observable<MouseEvent>;
  @Input({ required: true }) closeOverlay!: () => void;

  selectedLocale: Locale = 'en-GB';
  localeOptions = [
    { value: 'en-DE', viewValue: 'EN_DE' },
    { value: 'en-NO', viewValue: 'EN_NO' },
    { value: 'en-GB', viewValue: 'EN' },
    { value: 'no-NO', viewValue: 'NO' },
    { value: 'de-DE', viewValue: 'DE' },
  ];

  constructor() {
  }

  changeLocale(locale: Locale): void {
    void this.mfe.setUserLanguage(locale);
  }

}
