import { Injectable, inject } from '@angular/core';
import { InfrontSDK, InfrontUtil } from '@infront/sdk';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { PeriodService } from '../../services/period.service';
import { SdkRequestsService } from '../../services/sdk-requests.service';
import { SdkService } from '../../services/sdk.service';
import { StoreService } from '../../services/store.service';
import { TradingPositionsService } from '../../services/trading-positions.service';
import { TradingService } from '../../services/trading.service';
import type { Column } from '../../shared/grid/columns.model';
import type { Period } from '../../shared/period/period.model';
import { ProgressService, trackProgress } from '../../shared/progress';
import type { FeedFilterItem } from '../../typings/models/feed-filterable';
import type { UntranslatedNoRowsTemplate } from '../../shared/grid/grid.model';
import { columnsByCalendarTypeMap, overlayNoRowsTemplateOptions, type CalendarEvent, type CalendarSource, type CalendarSourceMap } from './calendar.model';
import type { CalendarWidget } from '../../state-model/widget.model';
import { isCalendarTypeWindow, isCountrySearchableWindow, isWatchlistWindow, type CalendarType, type DashboardWindow } from '../../state-model/window.model';

const avaialbleCalendarFeeds = Object.freeze({
  morningStarCorporateActions: 5172,
  SnPCalendarCompany: 5171,
  infrontNordicCalendar: 1047,
} satisfies { [feedName: string]: number; });

const availableCalendarFeedValues = Object.freeze(Object.values(avaialbleCalendarFeeds));

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  private readonly sdkService = inject(SdkService);
  private readonly sdkRequestsService = inject(SdkRequestsService);
  private readonly storeService = inject(StoreService);
  private readonly tradingPositionsService = inject(TradingPositionsService);
  private readonly tradingService = inject(TradingService);

  private calendarEventCache: { [countryId: string]: { from: number; to: number; calendarEvents: InfrontSDK.CalendarEvent[]; } } = {}; // @WIP, caches CalendarEvents by request

  private readonly feedFilterItemsAction = new BehaviorSubject<FeedFilterItem[]>([]);
  readonly feedFilterItems$ = this.feedFilterItemsAction.asObservable();

  private readonly windowInstrumentSource$ = (
    widget: CalendarWidget,
    untranslatedNoRowsTemplateAction: Subject<UntranslatedNoRowsTemplate | undefined>,
  ): Observable<CalendarSource | undefined> =>
    this.sdkRequestsService.windowInstrument$(widget, { filterInvalid: false }).pipe(
      // catch invalid instrument?
      tap((source) => source == undefined && untranslatedNoRowsTemplateAction.next(overlayNoRowsTemplateOptions.noInstrument))
    );

  private readonly watchlistSource$ = (
    widget: CalendarWidget,
    untranslatedNoRowsTemplateAction: Subject<UntranslatedNoRowsTemplate | undefined>,
  ): Observable<CalendarSource | undefined> =>
    this.sdkRequestsService.selectedWatchlist$(widget).pipe(
      map((wl) => {
        if (wl?.title == undefined) {
          untranslatedNoRowsTemplateAction.next(overlayNoRowsTemplateOptions.noWatchlist);
          return undefined;
        }
        if (!wl.items?.length) {
          untranslatedNoRowsTemplateAction.next(overlayNoRowsTemplateOptions.noWatchlistItems);
          return undefined;
        }
        return wl.items;
      })
    );

  private portfolioSource$(
    untranslatedNoRowsTemplateAction: Subject<UntranslatedNoRowsTemplate | undefined>
  ): Observable<CalendarSource | undefined> {
    return this.tradingService.tradingConnected$.pipe(
      switchMap((connected) => {
        untranslatedNoRowsTemplateAction.next(overlayNoRowsTemplateOptions.noPortfolio);
        if (connected) {
          return this.tradingPositionsService.portfolioInstruments$.pipe(
            map((pfInstruments) => {
              if (!pfInstruments.length) {
                untranslatedNoRowsTemplateAction.next(overlayNoRowsTemplateOptions.noPortfolioItems);
                return undefined;
              }
              return pfInstruments;
            })
          );
        }
        return of(undefined);
      }),
    );
  }

  private countrySource$(
    widget: CalendarWidget,
    untranslatedNoRowsTemplateAction: Subject<UntranslatedNoRowsTemplate | undefined>,
  ): Observable<CalendarSource | undefined> {
    return this.storeService.windowByWidget$(widget).pipe(
      map((window) => {
        let countrySources: string[] | undefined;
        if (isCountrySearchableWindow(window)) {
          countrySources = window.settings.countries;
        }
        if (!countrySources?.length) {
          untranslatedNoRowsTemplateAction.next(overlayNoRowsTemplateOptions.noCountry);
          return undefined;
        }
        return countrySources;
      })
    );
  }

  private readonly calendarSourceMap$: CalendarSourceMap = {
    'Instrument': (widget, untranslatedNoRowsTemplateAction) => this.windowInstrumentSource$(widget, untranslatedNoRowsTemplateAction),
    'Watchlist': (widget, untranslatedNoRowsTemplateAction) => this.watchlistSource$(widget, untranslatedNoRowsTemplateAction),
    'Country': (widget, untranslatedNoRowsTemplateAction) => this.countrySource$(widget, untranslatedNoRowsTemplateAction),
    'Portfolio': (untranslatedNoRowsTemplateAction) => this.portfolioSource$(untranslatedNoRowsTemplateAction),
  };

  /**
   * Defines the allowed CalendarFeeds (SnP, Infront, Morningstar)
   * Logic defined in IWT-1081, but still not 100% clear
   */
  private readonly allowedFeeds$ = this.sdkRequestsService.feedList$.pipe(map((userfeeds: number[]) => {
    const calendarFeeds: number[] = [];
    const distinctCalendarFeeds: { [calendarFeed: number]: boolean; } = {};

    for (const userfeed of userfeeds) {
      let hasAllAvailableFeeds = false;
      for (const availableFeed of availableCalendarFeedValues) {
        if (userfeed === availableFeed && !distinctCalendarFeeds[availableFeed]) {
          calendarFeeds.push(availableFeed);
          distinctCalendarFeeds[availableFeed] = true;
          if (calendarFeeds.length === availableCalendarFeedValues.length) {
            hasAllAvailableFeeds = true;
            break;
          }
        }
      }
      if (hasAllAvailableFeeds) {
        break;
      }
    }

    return calendarFeeds;
  }), shareReplay(1));


  /* Logic from IWT-1081, with only allowing MorningStar and either SnP or Infront CalendarFeeds
  private allowedFeeds$ = this.sdkRequestsService.feedList$.pipe(map((userfeeds: number[]) => {
    const calendarFeeds: number[] = [];
    const distinctCalendarFeeds: { [calendarFeed: number]: number; } = {}; // value as the index
    const iterations = (userfeeds || []).length - 1;

    for (const userfeed of userfeeds) {
      const userfeed = userfeeds[i];

      if (userfeed === MorningstarCalendarCorporateActions && distinctCalendarFeeds[MorningstarCalendarCorporateActions] == undefined) {
        calendarFeeds.push(userfeed);
        distinctCalendarFeeds[userfeed] = calendarFeeds.length - 1;
        if (distinctCalendarFeeds[SnPCalendarCompany] != undefined) {
          break;
        }
      }

      if (userfeed === SnPCalendarCompany && distinctCalendarFeeds[SnPCalendarCompany] == undefined) {
        const infrontNordicCalendarIndex = distinctCalendarFeeds[InfrontNordicCalendar];
        if (infrontNordicCalendarIndex != undefined) {
          calendarFeeds.splice(infrontNordicCalendarIndex, 1);
        }
        calendarFeeds.push(SnPCalendarCompany);
        distinctCalendarFeeds[SnPCalendarCompany] = calendarFeeds.length - 1;
        if (distinctCalendarFeeds[MorningstarCalendarCorporateActions] != undefined) {
          // early return
          break;
        }
      }
      if (userfeed === InfrontNordicCalendar && distinctCalendarFeeds[SnPCalendarCompany] == undefined) {
        calendarFeeds.push(InfrontNordicCalendar);
        distinctCalendarFeeds[InfrontNordicCalendar] = calendarFeeds.length - 1;
      }
    }

    return calendarFeeds;
  }), shareReplay(1));
  */

  private getCalendarSource$(
    widget: CalendarWidget,
    calendarType: CalendarType,
    untranslatedNoRowsTemplateAction: Subject<UntranslatedNoRowsTemplate | undefined>
  ): Observable<CalendarSource | undefined> {
    let calendarSource$: Observable<CalendarSource | undefined>;
    if (calendarType === 'Portfolio') {
      calendarSource$ = this.calendarSourceMap$[calendarType](untranslatedNoRowsTemplateAction);
    } else {
      calendarSource$ = this.calendarSourceMap$[calendarType](widget, untranslatedNoRowsTemplateAction);
    }
    return calendarSource$;
  }

  private calendarEventsBySource$(
    widget: CalendarWidget,
    calendarType: CalendarType,
    selectedPeriod: Period | undefined,
    progress: ProgressService,
    untranslatedNoRowsTemplateAction: Subject<UntranslatedNoRowsTemplate | undefined>,
  ): Observable<InfrontSDK.CalendarEvent[]> {
    if (!selectedPeriod) {
      untranslatedNoRowsTemplateAction.next(overlayNoRowsTemplateOptions.noPeriod);
      return of([]);
    }
    const { from, to } = PeriodService.getPeriodDates(selectedPeriod) || {};
    // ignore default by InfrontSDK.financialCalendar
    if (!from || !to) {
      untranslatedNoRowsTemplateAction.next(overlayNoRowsTemplateOptions.noValidPeriod);
      return of([]);
    }

    return this.getCalendarSource$(widget, calendarType, untranslatedNoRowsTemplateAction).pipe(
      switchMap((source) => {
        // IWT-1256 early empty return, to avoid sending financialCalender search with empty source,
        // like in instrument-window >> calendar widget, with no watchlist-sources!
        if (!source) {
          return of([]);
        } else if (Array.isArray(source) && !source.length) {
          untranslatedNoRowsTemplateAction.next(overlayNoRowsTemplateOptions.noSource);
          return of([]);
        }

        const opts: Omit<InfrontSDK.FinancialCalendarOptions, 'onData'> = {
          source,
          from,
          to,
          limit: 100,
        };

        return combineLatest([this.sdkService.getArray$(InfrontSDK.financialCalendar, opts, InfrontUtil.InitialUpdate.None), this.allowedFeeds$]).pipe(
          trackProgress({ label: 'calEvents$', progress }),
          map(([calendarEvents, allowedFeeds]) => {
            if (!calendarEvents?.length) {
              if (calendarType === 'Country') {
                untranslatedNoRowsTemplateAction.next(overlayNoRowsTemplateOptions.noCountryItems);
              } else {
                untranslatedNoRowsTemplateAction.next(overlayNoRowsTemplateOptions.noData);
              }
              return [];
            }

            const filteredEvents: InfrontSDK.CalendarEvent[] = [];

            if (allowedFeeds.length) {
              // @TODO why is allowedFeeds handled in frontend?! This should be a backend responsibility
              calendarEvents?.forEach((event) => {
                if (allowedFeeds.includes(event.feed)) {
                  filteredEvents.push(event);
                }
              });
            } else {
              untranslatedNoRowsTemplateAction.next(overlayNoRowsTemplateOptions.noAllowedFeed);
            }

            return filteredEvents;
          }),
        );
      })
    );
  }

  getCalendarType(window: DashboardWindow): CalendarType {
    let type: CalendarType;
    if (isCalendarTypeWindow(window)) {
      type = window.settings.calendarType;
    } else if (isWatchlistWindow(window)) {
      type = 'Watchlist';
    } else {
      type = 'Instrument';
    }
    return type;
  }

  calendarEvents$(
    widget: CalendarWidget,
    calendarType: CalendarType,
    selectedPeriod: Period | undefined,
    progress: ProgressService,
    untranslatedNoRowsTemplateAction: Subject<UntranslatedNoRowsTemplate | undefined>,
  ): Observable<CalendarEvent[]> {
    return this.calendarEventsBySource$(widget, calendarType, selectedPeriod, progress, untranslatedNoRowsTemplateAction).pipe(
      switchMap((events) => {
        return this.sdkService
          .getArray$(InfrontSDK.feedInfo, {
            infoType: InfrontSDK.FeedInfoType.MetaData,
            feed: events.map((item) => item.feed),
          })
          .pipe(
            trackProgress({ label: 'calendarEvents$', progress }),
            map((feedMetadataList) => {
              const eventsWithFeedInfo = events.map((event, i) => ({
                feedShortName: (feedMetadataList[i] as InfrontSDK.FeedInfo).feedCode,
                feedLongName: (feedMetadataList[i] as InfrontSDK.FeedInfo).description,
                symbols: [event.symbolId],
                ...event,
                index: `${i}~${InfrontUtil.makeUUID()}`,
              }));
              this.feedFilterItemsAction.next(this.uniqueEvents(eventsWithFeedInfo));
              return eventsWithFeedInfo;
            })
          );
      })
    );
  }

  getColumns(calendarType: CalendarType): Column[] {
    return columnsByCalendarTypeMap[calendarType];
  }

  private uniqueEvents(events: CalendarEvent[]): FeedFilterItem[] {
    const filtered = [...new Set(events.map((event) => event.feed))].map((feed) => ({
      feed,
      shortName: events.find((item) => item.feed === feed)?.feedShortName ?? '',
      longName: events.find((item) => item.feed === feed)?.feedLongName ?? '',
      active: true,
    }));
    return filtered;
  }

}
