
import type { Infront } from '@infront/sdk';


import type { InfrontWidget, UI, WidgetOptions } from '@infront/wtk';
import type { SvelteWidgetOptions } from "@infront/wtk/utils/svelte";
import type { Constructor } from '../../util/types';


export type WTKWidget = InfrontWidget;
export type WTKWidgetOptions = WidgetOptions | SvelteWidgetOptions;

export type WTKWidgetConstructorWithoutModel<Widget extends WTKWidget = WTKWidget, Options extends WTKWidgetOptions = WTKWidgetOptions> = (
  Constructor<
    Widget,
    [HTMLElement, UI, Options]
  >
);

export type WTKWidgetConstructorWithModel<Widget extends WTKWidget = WTKWidget, Options extends WTKWidgetOptions = WTKWidgetOptions> = (
  Constructor<
    Widget,
    [HTMLElement, Infront.Model, UI, Options]
  >
);

export type WTKWidgetConstructor<Widget extends WTKWidget = WTKWidget, Options extends WTKWidgetOptions = WTKWidgetOptions> =
  WTKWidgetConstructorWithoutModel<Widget, Options> | WTKWidgetConstructorWithModel<Widget, Options>;

// options are unfortunately private, maybe todo for Toolkit?
// export type WTKWidgetConstructorWithOptions<Widget extends WTKWidget = WTKWidget> = { widgetConstructor: WTKWidgetConstructor<Widget>; widgetOptions: Widget['options']; };
export type WTKWidgetConstructorWithOptions<
  Widget extends WTKWidget = WTKWidget,
  Options extends WTKWidgetOptions = WTKWidgetOptions
> = { widgetConstructor: WTKWidgetConstructor<Widget, Options>, options: Options | undefined };

export const WTK_CSS_ENTRY_CLASS = 'infront-toolkit';

export const DEFAULT_WTK_WIDGET_OPTIONS_DEBOUNCE_TIME = 100; // miliseconds
