import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import type { DashboardFolderRef } from '@infront/ngx-dashboards-fx';
import { MAX_DASHBOARD_NAME_LENGTH, duplicateName, forbiddenName } from '../validators';

export interface NewDashboardDialogData {
  name: string;
  parent: DashboardFolderRef;
  icon: string;
}

@Component({
  selector: 'wt-new-dashboard-dialog',
  templateUrl: './new-dashboard-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
  ]
})
export class NewDashboardDialogComponent {
  readonly form: FormGroup;
  readonly icon: string;

  get name() { return this.form.get('name')!; }

  MAX_DASHBOARD_NAME_LENGTH = MAX_DASHBOARD_NAME_LENGTH;

  constructor(
    public dialogRef: MatDialogRef<NewDashboardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewDashboardDialogData
  ) {
    this.icon = data.icon;
    this.form = new FormGroup({
      name: new FormControl(data.name, [
        Validators.required,
        Validators.maxLength(MAX_DASHBOARD_NAME_LENGTH),
        forbiddenName(),
        duplicateName(data.parent),
      ]),
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
