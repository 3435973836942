import { ComponentRef, Directive, EventEmitter, Injector, Input, type OnChanges, type OnDestroy, type SimpleChanges, type StaticProvider, Type, ViewContainerRef, reflectComponentType } from '@angular/core';
import { Subscription } from 'rxjs';
import { DestroyRef } from '../../util/destroy-ref';
import { InfrontUtil } from '@infront/sdk';

@Directive({
  selector: '[wtLazyComp]',
})
export class LazyCompDirective implements OnChanges, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _lazyComp!: Type<any>;
  private _providers: StaticProvider[] | undefined;
  private _inputs: { [key: string]: unknown } = {};
  private _outputs: { [key: string]: unknown } = {};
  private subscription = new Subscription();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input({ alias: 'wtLazyComp', required: true }) set comp(lazyComp: Type<any>) {
    if (!lazyComp) {
      return;
    }
    this._lazyComp = lazyComp;
  }

  @Input() set inputs(data: { [key: string]: unknown }) {
    if (this.compRef) {
      this.refreshInputs(data);
    } else {
      this._inputs = data;
    }
  }

  @Input() set outputs(data: { [key: string]: unknown }) {
    this._outputs = data;
  }

  @Input() set providers(data: StaticProvider[]) {
    this._providers = data;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private compRef: ComponentRef<any> | undefined;

  constructor(private viewContainerRef: ViewContainerRef) { }

  private refreshInputs(inputs: { [key: string]: unknown }) {
    const compRef = this.compRef;
    if (!inputs || !compRef) {
      return;
    }
    Object.keys(inputs).forEach((inputName) => {
      const type = reflectComponentType(compRef.componentType);
      if ((inputName === 'widget' || inputName === 'window') && type?.inputs.some((input) => input.propName === inputName)) {
        compRef.setInput(inputName, InfrontUtil.deepCopy(inputs[inputName])); // TODO: clarify with Ruben, if this is ok!!
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // do not recreate a component
    if (this.compRef) {
      if (changes.comp) {
        this.compRef.destroy();
      } else {
        return;
      }
    }

    const destroyRef = new DestroyRef();

    const injector = Injector.create({
      providers: [...(this._providers ?? []), { provide: DestroyRef, useValue: destroyRef }],
      parent: this.viewContainerRef.injector,
    });

    this.compRef = this.viewContainerRef.createComponent(this._lazyComp, {
      injector: injector,
    });

    this.compRef.onDestroy(() => destroyRef.ngOnDestroy());

    this.refreshInputs(this._inputs);
    if (!this._outputs) {
      return;
    }

    Object.keys(this._outputs).forEach((output) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.subscription.add((this.compRef?.instance[output] as EventEmitter<any>).subscribe(this._outputs[output]));
    });
  }

  ngOnDestroy() {
    this.compRef = undefined;
    this.subscription.unsubscribe();
  }
}
