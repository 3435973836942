import { Injectable, inject, type OnDestroy } from '@angular/core';
import { type Logger, LogService } from '@vwd/ngx-logging';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, concatMap, map, takeUntil, tap } from 'rxjs/operators';
import { type DashboardDataEntity, type DashboardDataPost, DashboardDatasService } from '../../internal/api/dashboards';
import { mapToVoid } from '../../internal/utils/observables';
import type { DashboardModel, WidgetStructureModel } from '../../models';
import { WidgetDataProvider, type WidgetProviderLoadHistoryOptions, type WidgetProviderSaveOptions } from '../widget-data.provider';
import { throwIfHttpDashboardFolderModel, type HttpWidgetHistoryModel, isHttpWidgetHistoryModel } from './http-dashboard.models';
import { HttpDashboardProvider } from './http-dashboard.provider';
import { type HttpWidgetStructureStorage, storageToStructureModel, storeToHistoryModel, structureToStorage } from './store.models';

const HISTORY_FIELDS = Object.freeze(['id', 'create_timestamp']) as Array<keyof DashboardDataEntity>;

@Injectable({ providedIn: 'root' })
export class HttpWidgetDataProvider extends WidgetDataProvider implements OnDestroy {

  private readonly ngUnsubscribe = new Subject<void>();
  private readonly operationQueue = new Subject<() => Observable<any>>();

  private readonly logger: Logger = inject(LogService).openLogger('dashboards/services/http/widget-data');
  private readonly datasService = inject(DashboardDatasService);
  private readonly httpDashboardProvider = inject(HttpDashboardProvider);

  constructor() {
    super();

    this.operationQueue.pipe(
      concatMap(operation => operation()),
      takeUntil(this.ngUnsubscribe),
    ).subscribe();
  }

  public loadHistory(dashboard: DashboardModel): Observable<readonly HttpWidgetHistoryModel[]> {
    throwIfHttpDashboardFolderModel(dashboard, `Cannot load widget history for dashboard folder ${dashboard.name} (${dashboard.id}).`);

    this.logger.debug(`Loading dashboard widget history for dashboard ${dashboard.name} (${dashboard.id}).`);

    const resolvedDashboardId = dashboard.meta.linksTo ?? dashboard.id;

    return this.datasService.dashboardDatasGet(resolvedDashboardId, HISTORY_FIELDS)
      .pipe(
        map(dashboardDatas => dashboardDatas._data?.map(({ dashboard_data }) => storeToHistoryModel(resolvedDashboardId, dashboard_data)) ?? []),
        tap({
          error: err => this.logger.error(`Error fetching widget history for dashboard ${dashboard.name} (${dashboard.id}).`, err)
        }),
        takeUntil(this.ngUnsubscribe),
      );
  }

  public loadByDashboard(dashboard: DashboardModel): Observable<WidgetStructureModel> {
    throwIfHttpDashboardFolderModel(dashboard, `Cannot load widgets for dashboard folder ${dashboard.name} (${dashboard.id}).`);

    this.logger.debug(`Loading dashboard widgets for dashboard ${dashboard.name} (${dashboard.id}).`);

    const resolvedDashboardId = dashboard.meta.linksTo ?? dashboard.id;

    return this.datasService.dashboardsIdDatasCurrentGet(resolvedDashboardId, ['data']).pipe(
      map(response => {
        const data = response.dashboard_data?.data;
        try {
          const structure: HttpWidgetStructureStorage = JSON.parse(data!);
          return storageToStructureModel(structure);
        } catch (e) {
          this.logger.warn(`Widgets data for dashboard ${dashboard.name} (${dashboard.id}) did not contain valid JSON.`, { error: e, data });
          return { version: 1, widgets: [] };
        }
      }),
      tap({
        error: err => this.logger.error(`Error fetching widgets for dashboard ${dashboard.name} (${dashboard.id}).`, err)
      }),
    );
  }

  public loadByHistory(options: WidgetProviderLoadHistoryOptions): Observable<WidgetStructureModel> {
    const entry = options.historyEntry;

    if (!isHttpWidgetHistoryModel(entry)) {
      throw new TypeError('History is missing proper meta information.');
    }

    return this.datasService.dashboardDatasIdGet(entry.meta.id, ['data']).pipe(
      map(response => {
        const data = response.dashboard_data?.data;
        try {
          const structure: HttpWidgetStructureStorage = JSON.parse(data!);
          return storageToStructureModel(structure);
        } catch (e) {
          this.logger.warn(`Dashboard history entry ${entry.meta.id} for dashboard ${entry.meta.dashboardId} did not contain valid JSON.`, { error: e, data });
          return { version: 1, widgets: [] };
        }
      }),
      tap({
        error: err => this.logger.error(`Error fetching widget history for dashboard ${entry.meta.dashboardId}`, err)
      }),
    );
  }

  public save({ dashboard, widgets, markAsDraft }: WidgetProviderSaveOptions): Observable<void> {
    throwIfHttpDashboardFolderModel(dashboard, `Cannot save widgets to a dashboard folder ${dashboard.name} (${dashboard.id}).`);

    if (!dashboard.security.canEditWidgets) {
      throw new Error(`Cannot save widgets for read-only dashboard ${dashboard.name} (${dashboard.id}).`);
    }

    this.logger.info(`Saving dashboard widgets for dashboard ${dashboard.name} (${dashboard.id}).`);

    const postModel: DashboardDataPost = {
      dashboard_id: dashboard.id,
      is_draft: markAsDraft,
      data: JSON.stringify(structureToStorage(widgets)),
    };

    return this.run(() =>
      this.datasService.dashboardDatasPost(postModel).pipe(
        tap({
          next: () => this.logger.success(`Saved widgets for dashboard ${dashboard.name} (${dashboard.id})`),
          error: err => this.logger.fail(`Error saving widgets for dashboard ${dashboard.name} (${dashboard.id})`, err)
        }),
        mapToVoid(),
      )
    );
  }

  public revertDraft(dashboard: DashboardModel): Observable<void> {
    throwIfHttpDashboardFolderModel(dashboard, `Cannot save widgets to a dashboard folder ${dashboard.name} (${dashboard.id}).`);

    if (!dashboard.security.canEditWidgets) {
      throw new Error(`Cannot save widgets for read-only dashboard ${dashboard.name} (${dashboard.id}).`);
    }

    this.logger.info(`Reverting dashboard widgets for dashboard ${dashboard.name} (${dashboard.id}).`);

    return this.run(() =>
      this.datasService.dashboardsIdDatasDraftDelete(dashboard.id).pipe(
        tap({
          next: () => {
            this.logger.success(`Reverted widgets for dashboard ${dashboard.name} (${dashboard.id})`);
            this.httpDashboardProvider.notifyNotDraft(dashboard);
          },
          error: err => this.logger.fail(`Error reverting widgets for dashboard ${dashboard.name} (${dashboard.id})`, err)
        }),
        mapToVoid(),
      )
    );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Runs a callback after the results of previous callbacks have completed.
   */
  private run<T>(operation: () => Observable<T>): Observable<T> {
    return new Observable(subscriber => {
      this.operationQueue.next(() => operation().pipe(
        tap(subscriber),
        // Make sure we don't kill the main queue
        catchError(err => {
          this.logger.error('Sequential operation failed?');
          return EMPTY;
        }),
      ));
    });
  }
}
