import { Injectable, inject } from '@angular/core';
import { Infront, InfrontSDK, InfrontUtil } from '@infront/sdk';
import { Observable, Subscriber, type UnaryFunction, iif, of, pipe } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';


import { UI } from '@infront/wtk';
import { LogService } from '@vwd/ngx-logging';
import type { SymbolDataItem } from '../shared/models/symbol-data.model';
import { ProgressService, trackProgress } from '../shared/progress';
import type { Widget } from '../state-model/widget.model';
import { type Instrument, type InstrumentSettings, type WatchlistWindow, isInstrument, isInstrumentSettings } from '../state-model/window.model';
import { filterDuplicates } from '../util/array';
import { structuresAreEqual } from '../util/equality';
import { filterUndefined } from '../util/rxjs';
import { toLowerCaseFirstLetter } from '../util/string';
import { addToErrorSymbols, isSameInstrument } from '../util/symbol';
import { type AdditionalSymbolFields, ObserveSymbolsService } from '../widgets/lists/observe-symbols.service';
import { GridRef } from '../wrappers/grid-wrappers/gridref';
import { SdkService } from './sdk.service';
import { StoreService } from './store.service';
import { ToolkitService } from './toolkit.service';
import { WatchlistService } from './watchlist.service';

// for use in combination with sdkService.getObject$ & getArray$
// allows for type safety by only excluding onData property as this payload is emitted by the sdkService
type GenericDataRequestOptions<T> = Omit<T & InfrontSDK.DataRequestOptions, 'onData'>;

export type FeedContentsOptions = GenericDataRequestOptions<InfrontSDK.FeedContentsOptions>;
export type LoginDataOptions = GenericDataRequestOptions<InfrontSDK.LoginDataOptions>;
export type SymbolInfoFieldsByClassification = Partial<{ [key in InfrontSDK.SymbolClassification]: InfrontSDK.SymbolField[] }>;

export type SdkSymbolDataResult<T extends InfrontSDK.SymbolField> = ({ [K in T]: InfrontSDK.SymbolFieldTypeBase[K] } & AdditionalSymbolFields);

export type SdkSymbolDataStreamingRequest = <T extends InfrontSDK.SymbolField>(params: {
  symbolEntity: Widget | Instrument, // the entity that provides the symbol that we want to have fields data from
  fields: T[], // the fields we want to have data for - will be return with mapped resolved values
  uuid?: string,
  progress?: ProgressService,
}) => Observable<SdkSymbolDataResult<T>>;

export type SdkMultipleSymbolsDataStreamingRequest = <T extends InfrontSDK.SymbolField>(params: {
  instruments: Instrument[], // the entity that provides the symbol that we want to have fields data from
  fields: T[], // the fields we want to have data for - will be return with mapped resolved values
  uuid: string,
  separateObserveField?: T,
  progress?: ProgressService,
}) => Observable<(SdkSymbolDataResult<T>)[]>;

export type SdkSymbolDataRequest = <T extends InfrontSDK.SymbolField>(params: {
  // @FIXME use SymbolEntity from util/sdk
  symbolEntity: Widget | Instrument; // the entity that provides the symbol that we want to have fields data from
  fields: T[]; // the fields we want to have data for - will be return with mapped resolved values
  progress?: ProgressService;
  progressLabel?: string;
}) => Observable<SdkSymbolDataResult<T>>;

export type SdkMultiSymbolDataStreamingRequest = <T extends InfrontSDK.SymbolField>(params: {
  symbols: SymbolDataItem[],
  widget: Widget
}) => Observable<(SdkSymbolDataResult<T>)[]>;

@Injectable({
  providedIn: 'root',
})
// the idea behind this service is to gather common requests from Webtrader to the SdkService and StoreService for reuse,
// compared to the SDK service that is only an adapter that generically translates SDK observables into RxJs observable
export class SdkRequestsService {
  private readonly logger = inject(LogService).openLogger('services/sdk-requests');
  private readonly sdkService = inject(SdkService);
  private readonly storeService = inject(StoreService);
  private readonly watchlistService = inject(WatchlistService);
  private readonly observeSymbolsService = inject(ObserveSymbolsService);
  private readonly toolkitService = inject(ToolkitService);

  symbolInfoByFields$ = ({ symbolEntity, fields = [], subscribe = true }: {
    symbolEntity: Widget | Instrument,
    fields?: InfrontSDK.SymbolField[],
    subscribe?: boolean;
  }): Observable<InfrontSDK.SymbolData> =>
    (isInstrument(symbolEntity) ? of(symbolEntity) : this.windowInstrument$(symbolEntity)).pipe(
      switchMap((instrument) => {
        const opts: Partial<InfrontSDK.SymbolDataOptions<InfrontSDK.SymbolId>> = {
          id: instrument,
          fields,
          subscribe,
        };
        return this.sdkService.getObject$(InfrontSDK.symbolData, opts, 'SdkRequestsService.symbolInfoByFields');
      })
    );

  // for new features it is better to use streamingSymbolData$ or snapshotSymbolData$ to get an already mapped object of resolved datavalues back,  with or without streaming, unless the fields are determined at runtime, then use symbolInfo$ with fields by classification
  // if neither fields or fieldsByClassification is supplied the requestwill only use basic content

  //  deprecated - use symbolInfoByFields$ instead
  symbolInfo$ = ({ symbolEntity, fieldsByClassification, subscribe = true }: {
    symbolEntity: Widget | Instrument,
    fieldsByClassification?: SymbolInfoFieldsByClassification,
    subscribe?: boolean,
  }): Observable<InfrontSDK.SymbolData> => {
    return isInstrument(symbolEntity)
      ? this.symbolInfoByInstrument$(symbolEntity, fieldsByClassification, subscribe)
      : this.windowInstrument$(symbolEntity).pipe(
        switchMap((id) => {
          return this.symbolInfoByInstrument$(id, fieldsByClassification, subscribe);
        })
      );
  };

  // todo: we speed things up if we always request all fields in advance, always using the fields option instead of first doin a pre request, then getting classificatoin and then determining content based on classification. If we just send in what fields we want the we avoid that
  // for lists we still do a new requests everytime the fields collection changes regardless
  private symbolInfoByInstrument$ = (
    id: Instrument,
    fieldsByClassification?: SymbolInfoFieldsByClassification,
    subscribe = true
  ): Observable<InfrontSDK.SymbolData> =>
    this.sdkService
      .getObject$(
        InfrontSDK.symbolData,
        {
          id,
          content: { Basic: true }
        } as Partial<InfrontSDK.SymbolDataOptions>,
        'SdkRequestsService.symbolInfoByInstrument$'
      )
      .pipe(
        switchMap((symbolData) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
          const classification = symbolData.get(InfrontSDK.SymbolField.SymbolClassification);
          const fields = fieldsByClassification ? fieldsByClassification[classification] : undefined;
          const opts: Partial<InfrontSDK.SymbolDataOptions> = {
            id,
            subscribe,
            content: {
              Basic: true,
              HistoricalPerformance: true,
              CompanyMetaData: this.needCompanyMetaData(classification),
              FundDetails: this.needFundDetails(classification),
              CalculatedHist: this.needCalculatedHist(classification),
            },
            fields,
          };
          return this.sdkService.getObject$(InfrontSDK.symbolData, opts);
        })
      );

  needCompanyMetaData = (classification: InfrontSDK.SymbolClassification): boolean => classification === InfrontSDK.SymbolClassification.Stock;

  needFundDetails = (classification: InfrontSDK.SymbolClassification): boolean => classification === InfrontSDK.SymbolClassification.Fund;

  needCalculatedHist = (classification: InfrontSDK.SymbolClassification): boolean =>
    [
      InfrontSDK.SymbolClassification.Stock,
      InfrontSDK.SymbolClassification.Index,
      InfrontSDK.SymbolClassification.Forex,
      InfrontSDK.SymbolClassification.Future,
    ].includes(classification);

  windowInstrument$ = (widget: Widget, opts = { filterInvalid: true }): Observable<Instrument> =>
    this.storeService.windowByWidget$(widget).pipe(
      debounceTime(50),
      filter((window) => !opts.filterInvalid || isInstrumentSettings(window.settings)),
      map((window) => (window.settings as InstrumentSettings).instrument),
      distinctUntilChanged((prev, next) => structuresAreEqual(prev, next))
    );

  selectedWatchlist$ = (widget: Widget): Observable<InfrontSDK.Watchlist | undefined> =>
    this.storeService.windowByWidget$(widget).pipe(
      map(window => (window as WatchlistWindow).settings.selectedWatchlist),
      switchMap((watchlistId) => {
        if (!watchlistId) {
          return of(undefined);
        }
        return this.watchlistService.getWatchlistById$(watchlistId);
      }),
      distinctUntilChanged((prev, next) => structuresAreEqual(prev, next))
    );

  feedContent$ = (feedContentsOptions: FeedContentsOptions): Observable<InfrontSDK.ChainContent> => {
    return this.sdkService.getObject$(InfrontSDK.feedContents, feedContentsOptions, 'SdkRequestsService.feedContent$');
  };

  loginData$ = (loginDataOptions: LoginDataOptions): Observable<InfrontSDK.LoginData> => {
    return this.sdkService.getObject$(InfrontSDK.loginData, loginDataOptions, 'SdkRequestsService.loginData$');
  };

  metaData$ = (widget: Widget, progress?: ProgressService): Observable<InfrontSDK.FeedInfo> =>
    this.symbolInfo$({ symbolEntity: widget }).pipe(
      map((symbolData: InfrontSDK.SymbolData) => symbolData.get(InfrontSDK.SymbolField.Feed)),
      filter((feed) => !!feed),
      distinctUntilChanged((prev, next) => prev === next),
      switchMap((feed) =>
        this.sdkService.getArray$(
          InfrontSDK.feedInfo,
          {
            infoType: InfrontSDK.FeedInfoType.MetaData,
            feed,
          },
          undefined,
          'SdkRequestsService.metaData$'
        ).pipe(trackProgress({ label: 'metaData$', optional: true, progress }))
      ),
      filter((feedInfo) => !!feedInfo.length),
      map((feedInfo) => feedInfo[0] as InfrontSDK.FeedInfo),
    );

  metaDataByFeed$(feed: number): Observable<InfrontSDK.FeedInfo> {
    return this.sdkService
      .getArray$(
        InfrontSDK.feedInfo,
        {
          infoType: InfrontSDK.FeedInfoType.MetaData,
          feed,
        },
        undefined,
        'SdkRequestsService.metaDataByFeed$'
      )
      .pipe(
        filter((feedInfo) => !!feedInfo.length),
        map((feedInfo) => feedInfo[0] as InfrontSDK.FeedInfo),
      );
  }

  // use when a list of items identifiable by feed and ticker need extra symbolData
  // The method does the sdk call and the necessary mapping for adding the extra symboldata to the list items
  addSymbolDataToList$ = <T>({ entityList, fieldList, entityIdMatch }: {
    entityList: T[], // a list with items identifiable by ticker and feed
    fieldList: InfrontSDK.SymbolField[], // the fields that should be fetched from SDK and attached as new properties with values to each entityList item
    entityIdMatch: (entity: T) => { ticker?: string; feed?: number } // how to find each entityList items ticker and feed, might not be root properties, use arrow function
  }): Observable<T[]> => {
    const ids = entityList.map((entity) => entityIdMatch(entity));
    const uniqueIds = filterDuplicates(
      ids,
      (a: InfrontSDK.SymbolId, b: InfrontSDK.SymbolId) => !!a?.feed && !!b?.ticker && a.feed === b.feed && a.ticker === b.ticker
    );
    if (!uniqueIds.length) {
      return of([]);
    }
    const opts: Partial<InfrontSDK.SymbolDataOptions<InfrontSDK.SymbolId[]>> = {
      id: uniqueIds,
      subscribe: false,
      content: {
        Basic: true,
      },
    };
    return this.sdkService.getArray$(InfrontSDK.symbolData, opts).pipe(
      filter((symbols) => symbols.length === uniqueIds.length), // todo: errorhandling?
      map((symbols) => {
        const symbolDataMap = symbols.reduce((acc, symbol) => {
          const key = `${symbol.get(InfrontSDK.SymbolField.Ticker)}#${symbol.get(InfrontSDK.SymbolField.Feed)}`;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return
          (acc as any)[key] = fieldList.map((field) => symbol.get(field));
          return acc;
        }, {});

        const enhancedEntityList = entityList.map((entity) => {
          const entitySymbolId = entityIdMatch(entity);
          if (!entitySymbolId.ticker || !entitySymbolId.feed) {
            return entity;
          }
          const key = `${entitySymbolId.ticker}#${entitySymbolId.feed}`;
          const enhancedEntity: T = fieldList.reduce((acc, fieldName, i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
            (acc as any)[toLowerCaseFirstLetter(fieldName)] = (symbolDataMap as any)[key]?.[i];
            return acc;
          }, entity);
          return enhancedEntity;
        });
        return enhancedEntityList;
      })
    );
  };

  streamingSymbolData$: SdkSymbolDataStreamingRequest = ({ symbolEntity, fields, uuid = (symbolEntity as Widget).id }) => {
    return this.toInstrument$(symbolEntity).pipe(
      switchMap((instrument) => {
        return this.symbolInfoByFields$({ symbolEntity: instrument, fields }).pipe(
          switchMap((symbolData) => {
            return this.observeSymbolsService.observeSymbols$({ symbols: [symbolData], fields, uuid, initialUpdate: InfrontSDK.InitialUpdate.Always });
          })
        );
      })
    );
  };

  streamingMultipleSymbolsData$: SdkMultipleSymbolsDataStreamingRequest = ({ instruments, fields, uuid, separateObserveField }) =>
    of(instruments).pipe(
      this.symbolsFromIds({ fields }),
      switchMap((symbols) => {
        return this.observeSymbolsService.observeSymbolsEmitList$({ symbols, fields, uuid, initialUpdate: InfrontSDK.InitialUpdate.Always, separateObserveField });
      })
    );

  // get mapped data snapshot - ASAP - that means not all fields might get resolved, will just to a get on each field, ready or not
  snapshotSymbolData$: SdkSymbolDataRequest = ({ symbolEntity, fields, progress, progressLabel }) =>
    this.toInstrument$(symbolEntity).pipe(
      switchMap((instrument) =>
        this.symbolInfoByFields$({ symbolEntity: instrument, fields, subscribe: false }).pipe(
          // use symbolGetValueListResolveAll$ when SDK is apply to give callback on all fields on a InfrontSDK.Symbols request with property fields supplied instead of content, currently not working
          switchMap((symbol) => this.observeSymbolsService.symbolGetValueList$([symbol], fields).pipe(
            map((res) => res[0]),
            trackProgress({ label: progressLabel ?? 'symbolGetValueList$', progress, optional: true }),
          ))
        )
      )
    );

  symbolByWidget$({ widget, fields }: { widget: Widget, fields: InfrontSDK.SymbolField[] }): Observable<InfrontSDK.SymbolData> {
    return this.toInstrument$(widget).pipe(
      map((instrument) => [instrument]),
      this.symbolsFromIds({ fields }),
      map((result) => (result.length ? result[0] : undefined)),
      filterUndefined()
    );
  }

  readonly toInstrument$ = (symbolEntity: Widget | Instrument): Observable<Instrument> => isInstrument(symbolEntity) ? of(symbolEntity) : this.windowInstrument$(symbolEntity);


  // emits a new full array whenever an event on it happens
  sdkObservableArrayAdapter$ = <T>(obsArray: Infront.ObservableArray<T>, delay = 0, initialUpdate?: InfrontUtil.InitialUpdate): Observable<T[]> => {
    const unbinds: InfrontSDK.Unbind[] = [];
    return new Observable((obs: Subscriber<T[]>) => {
      const binding = {
        reInit: () => {
          obs.next(obsArray.data);
        },
        itemAdded: () => {
          setTimeout(() => {
            obs.next(obsArray.data);
          }, delay);
        },
        itemRemoved: () => {
          setTimeout(() => {
            obs.next(obsArray.data);
          }, delay);
        },
        itemMoved: () => {
          setTimeout(() => {
            obs.next(obsArray.data);
          }, delay);
        },
        itemChanged: () => {
          setTimeout(() => {
            obs.next(obsArray.data);
          }, delay); obs.next(obsArray.data);
        },
      };
      unbinds.push(obsArray.observe(binding, initialUpdate));
      obs.next(obsArray.data);

      return () => unbinds.forEach((unbind) => unbind());
    });
  };

  private columnPickedFieldsFromWidget = pipe(
    switchMap((gridRef: GridRef) => gridRef.selectedFields$),
    map((fields) => fields.filter((field) => field in InfrontSDK.SymbolField))
  );

  // fields are sent to the SDK which determines what data content servers to request from by checking the fields
  symbolsFromIds(options: { gridRef: GridRef; maintainOrder?: boolean; fields?: InfrontSDK.SymbolField[]; progress?: ProgressService | null; returnAfterAllSymbolsResolve?: boolean; }): UnaryFunction<Observable<InfrontSDK.SymbolId[]>, Observable<InfrontSDK.SymbolData[]>>;
  symbolsFromIds(options: { fields: InfrontSDK.SymbolField[]; maintainOrder?: boolean; progress?: ProgressService | null; }): UnaryFunction<Observable<InfrontSDK.SymbolId[]>, Observable<InfrontSDK.SymbolData[]>>;
  symbolsFromIds({ gridRef, maintainOrder, fields, progress, returnAfterAllSymbolsResolve }:
    { gridRef?: GridRef; maintainOrder?: boolean; fields?: InfrontSDK.SymbolField[]; progress?: ProgressService | null; returnAfterAllSymbolsResolve?: boolean; }
  ): UnaryFunction<Observable<InfrontSDK.SymbolId[]>, Observable<InfrontSDK.SymbolData[]>> {
    return pipe(
      map((symbolIds: InfrontSDK.SymbolId[]) => symbolIds.filter((id) => !!id?.ticker && !!id?.feed)),
      switchMap((symbolIds) =>
        iif(() => !!fields, of(fields), gridRef ? of(gridRef).pipe(this.columnPickedFieldsFromWidget) : of([])).pipe(
          switchMap((fields) => {
            if (!symbolIds.length) {
              return of([]);
            }
            const itemCount = symbolIds.length;
            const opts: Partial<InfrontSDK.SymbolDataOptions<InfrontSDK.SymbolId[]>> = {
              id: symbolIds,
              subscribe: true,
              fields,
              onError: (error: InfrontSDK.ErrorBase<{ feed?: number; ticker?: string }>) => addToErrorSymbols(error, errorSymbols),
            };
            const errorSymbols: SymbolDataItem[] = [];
            const symbolData$ = this.sdkService.getArray$(InfrontSDK.symbolData, opts, undefined, 'SDKRequestsService.symbolsFromIds');
            return symbolData$.pipe(
              map((data) => [...data, ...errorSymbols]),
              tap((symbols) => {
                // debug
                if (returnAfterAllSymbolsResolve === false) {
                  const missingSymbols = this.findMissingSymbols(symbolIds, symbols);
                  this.logger.debug('unresolved symbols', { missingSymbols });
                }
              }),
              filter((data) => {
                if (returnAfterAllSymbolsResolve === false) {
                  return true;
                }
                return data.length === itemCount;
              }),
              map((data) => maintainOrder ? this.sortByEntryIndex(symbolIds, data) : data),
              trackProgress({ label: 'symbolFromIds', progress, optional: true }),
            );
          })
        )
      )
    );
  }

  private findMissingSymbols(symbolIds: InfrontSDK.SymbolId[], symbols: InfrontSDK.SymbolData[]): InfrontSDK.SymbolId[] {
    return symbolIds.filter((symbolId) => {
      const found = symbols.some((data) => {
        const feed = data.get(InfrontSDK.SymbolField.Feed);
        const ticker = data.get(InfrontSDK.SymbolField.Ticker);
        return feed === symbolId.feed && ticker === symbolId.ticker;
      });
      return !found;
    });
  }

  private sortByEntryIndex(symbolIds: InfrontSDK.SymbolId[], symbolData: InfrontSDK.SymbolData[]): InfrontSDK.SymbolData[] {
    const symbolIdsWithIndex = symbolIds.map((symbolId, index) => ({ ...symbolId, index }));

    const compareItems = (itemA: InfrontSDK.SymbolData, itemB: InfrontSDK.SymbolData) => {
      const indexA = symbolIdsWithIndex.findIndex((symbolId) => isSameInstrument(symbolId, itemA));
      const indexB = symbolIdsWithIndex.findIndex((symbolId) => isSameInstrument(symbolId, itemB));
      if (indexA === undefined || indexB === undefined) {
        return 0;
      }
      return indexA - indexB;
    };

    return symbolData.sort(compareItems);
  }

  filterUndefinedResolvedSymbolIds: UnaryFunction<Observable<InfrontSDK.SymbolData[]>, Observable<InfrontSDK.SymbolData[]>> =
    pipe(
      map((symbols: InfrontSDK.SymbolData[]) => symbols.filter((s) => !!s.get(InfrontSDK.SymbolField.Ticker) && !!s.get(InfrontSDK.SymbolField.Feed))),
    );


  infrontUIObservable$<T>(
    observableKey: keyof UI,
  ): Observable<T> {
    return this.toolkitService.infrontUI$.pipe(take(1),
      switchMap(infrontUI => new Observable((subscriber: Subscriber<T>) => {
        const binding = InfrontUtil.BindingFactory.createInlineBinding((val: T) => {
          subscriber.next(val);
        });
        const uiObs = infrontUI[observableKey] as InfrontUtil.Observable;
        uiObs?.observe(binding);
        return () => uiObs?.unbind(binding);
      })));
  }

  feedList$ = this.sdkService.getObject$<InfrontSDK.FeedListOptions<true>>(InfrontSDK.feedList).pipe(shareReplay(1));

  hasFeedAccess$ = (feed: number): Observable<boolean> => this.feedList$.pipe(map((feedList: number[]) => feedList.includes(feed)));
}
