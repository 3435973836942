import { InfrontUtil } from '@infront/sdk';
import { identity, type Observable, type OperatorFunction, pipe, type UnaryFunction } from 'rxjs';
import { filter, map, pairwise, startWith, tap } from 'rxjs/operators';

export const filterUndefined = <T>(): UnaryFunction<Observable<T | undefined>, Observable<T>> =>
  pipe(
    filter((value: T | undefined) => value != undefined),
    map((value) => value as T)
  );

export const filterUndefinedArray = <T>(): UnaryFunction<Observable<(T | undefined)[]>, Observable<T[]>> =>
  pipe(
    map((arr: (T | undefined)[]) => arr.filter((item) => !!item)),
    map((value) => value as T[])
  );

export const guard = <T extends object, U extends T>(
  typeGuard: (U extends T ? (value: T) => value is U : never),
  errorMsg?: string,
): UnaryFunction<Observable<T>, Observable<U>> =>
  map((val: T) => {
    if (typeGuard(val)) {
      return val as U;
    }
    throw new TypeError(typeof errorMsg === 'string'
      ? errorMsg
      : `Custom Rxjs operator guard() has failed
        \nEmitted value from piped Observable did not pass typeGuard
        \nValue: ${JSON.stringify(val)}`
    );
  });

export const doOnEmitCount = <T>(emitCount: number, predicate: (val: T) => void) => {
  let emitCounter = 1;
  return tap((val: T) => {
    if (emitCounter === emitCount) {
      emitCounter++;
      predicate(val);
    }
    if (emitCounter < emitCount) {
      emitCounter++;
    }
  });
};

export const doOnFirstEmit = <T>(predicate: (val: T) => void) => {
  return doOnEmitCount(1, predicate);
};

// shortcut operator to compare with previous item in a single step instead of three
export const withPreviousItem = <T>(): OperatorFunction<
  T,
  {
    prev?: T;
    curr: T;
  }
> => {
  return pipe(
    startWith(undefined),
    pairwise(),
    map(([prev, curr]) => ({
      prev,
      curr: curr!,
    }))
  );
};

export const addIndex = <T>(): UnaryFunction<Observable<T[]>, Observable<T[]>> =>
  map((items) => items.map((item, index) => ({ ...item, index: `${index}~${InfrontUtil.makeUUID()}` })));

export const addIndexOrdinal = <T>(): UnaryFunction<Observable<T[]>, Observable<T[]>> =>
  map((items) => items.map((item, index) => ({ ...item, index })));

export function mapToVoid<T>(): OperatorFunction<T, void> {
  return identity as UnaryFunction<Observable<T>, Observable<void>>;
}
