import { Injectable, inject } from '@angular/core';
import type { DashboardServicesProvider } from '../dashboard-services.provider';
import { HttpDashboardProvider } from './http-dashboard.provider';
import { HttpWidgetDataProvider } from './http-widget-data.provider';

@Injectable({ providedIn: 'root' })
export class HttpDashboardServicesProvider implements DashboardServicesProvider {
  public readonly dashboards = inject(HttpDashboardProvider);
  public readonly widgets = inject(HttpWidgetDataProvider);
}
