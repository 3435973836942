import { InfrontSDK } from '@infront/sdk';

export type SymbolInfoItem = {
  symbolField: InfrontSDK.SymbolField;
  subscribe?: boolean;
};

export type SymbolInfoMap = {
  [name in keyof SymbolInfo]: SymbolInfoItem;
};

export const SymbolFieldsMap: Readonly<Partial<SymbolInfoMap>> = {
  ticker: { symbolField: InfrontSDK.SymbolField.Ticker },
  feed: { symbolField: InfrontSDK.SymbolField.Feed },
  decimals: { symbolField: InfrontSDK.SymbolField.Decimals },
  countryFlag: { symbolField: InfrontSDK.SymbolField.Country },

  symbolClassification: { symbolField: InfrontSDK.SymbolField.SymbolClassification },
  preDisplayTime: { symbolField: InfrontSDK.SymbolField.PreDisplayTime, subscribe: true },
  preDisplayTicker: { symbolField: InfrontSDK.SymbolField.PreDisplayTicker },
  preLastTradedAt: { symbolField: InfrontSDK.SymbolField.PreLastTradedAt, subscribe: true },
  changePercent: { symbolField: InfrontSDK.SymbolField.ChangePercent, subscribe: true },
} as const;

export interface SymbolInfo {
  ticker: string;
  feed: number;
  decimals: number;
  countryFlag: string;

  symbolClassification: InfrontSDK.SymbolClassification;
  preDisplayTicker: string;
  preDisplayTime: Date;
  preLastTradedAt: number;
  changePercent: number;
}

type ObservableCacheObj = { [prop in keyof SymbolInfo]: InfrontSDK.SymbolField };

export const ObservableCacheSubscribeFields: Readonly<ObservableCacheObj> = Object.entries(SymbolFieldsMap).reduce(
  (acc: ObservableCacheObj, curr: [string, SymbolInfoItem], index: number) => {
    if (curr[1].subscribe) {
      acc[curr[0] as keyof ObservableCacheObj] = curr[1].symbolField;
    }
    return acc;
  }, {} as ObservableCacheObj
);
