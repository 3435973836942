import { type AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, type OnDestroy, ViewChild, inject } from '@angular/core';
import { Infront, InfrontSDK, InfrontUtil } from '@infront/sdk';
import { LastValueSubject } from '@infront/ngx-dashboards-fx/utils';
import { LogService } from '@vwd/ngx-logging';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { AlertService } from '../services/alert.service';
import { TradableService } from '../services/tradable.service';
import { TradingOrderEntryService } from '../services/trading-order-entry.service';
import { TradingService } from '../services/trading.service';
import { UserSettingsService } from '../services/user-settings.service';
import { WatchlistService } from '../services/watchlist.service';
import { type Instrument, isInstrument } from '../state-model/window.model';
import { canAddWindowForClassification } from '../util/symbol';
import { isDefined } from '../util/types';
import { DefaultSearchRequestDebounceTime } from './compact-search/compact-search.model';
import {
  DefaultFeedScoreFactorItems,
  type FullSearchMarketResultGroup,
  type FullSearchResultGroup,
  type FullSearchResultItem,
  type FullSearchSymbolResultGroup,
  type FullSearchSymbolSearchResultItem,
  type FullSearchWindowResultGroup,
  type FullSearchWindowResultItem,
  HistoryType,
  MaxFullSearchResultGroupItemLimit,
  SearchResultItemType,
  type SdkMarketSearchResultItem,
  type SdkSearchResultItem,
  type SdkSymbolSearchResultItem,
  type SearchConfig,
  SearchDataSource,
  type SearchResultSymbolWatchlist,
  ToggleShowMoreSearchResultsThreshold,
  findSearchResultGroup,
  getFullSearchResultGroups,
  getHistoryFullSearchResultGroup,
  isSdkMarketSearchResultItem,
  isSdkSymbolSearchResultItem,
  listAnimation,
  updateHighestSearchScore,
} from './search.model';
import { SdkSearchService } from './services/sdk-search.service';

@Component({
  selector: 'wt-app-search',
  templateUrl: './search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [listAnimation],
})

export class SearchComponent implements AfterViewInit, OnDestroy {
  private readonly tradingService = inject(TradingService);
  private readonly tradingOrderEntryService = inject(TradingOrderEntryService);
  private readonly sdkSearchService = inject(SdkSearchService);
  private readonly watchlistService = inject(WatchlistService);
  private readonly alertService = inject(AlertService);
  private readonly tradableService = inject(TradableService);
  readonly userSettings = inject(UserSettingsService);

  private readonly logger = inject(LogService).openLogger('search');

  private readonly ngUnsubscribe = new Subject<void>();

  @ViewChild('searchElm', { static: false }) private readonly searchElm!: ElementRef<HTMLInputElement>;

  private readonly searchConfig: SearchConfig = {
    feedScoreFactorItems: DefaultFeedScoreFactorItems,
    searchType: {
      symbol: true,
      market: true,
    },
    history: {
      historyType: HistoryType.COMPONENT,
    },
  };

  searchDataSource: SearchDataSource | undefined;

  readonly searchInputAction = new BehaviorSubject<string>('');
  readonly searchInput$ = this.searchInputAction.asObservable().pipe(
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly showDropdownAction = new BehaviorSubject<boolean>(false);
  readonly showDropdown$ = this.showDropdownAction.asObservable().pipe(
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly hideSubmarkets$ = this.userSettings.getValue$('searchHideSubmarkets').pipe(
    distinctUntilChanged(),
    shareReplay(1),
  );

  private cachedUnfilteredResultsByQuery: { [searchQuery: string]: FullSearchResultGroup[]; } = {};

  // @TODO use returnType Observable<FullSearchResultGroup[]>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly resultGroups$: Observable<any[] | undefined> = combineLatest([this.searchInput$, this.hideSubmarkets$, this.showDropdown$]).pipe(
    debounceTime(DefaultSearchRequestDebounceTime),
    switchMap(([searchQuery, hideSubmarkets, showDropdown]) => {
      // Dropdown closed, early return with undefined
      if (!showDropdown) {
        return of(undefined);
      }

      // Check if cache contains results for the searchQuery
      const cachedUnfilteredResults = this.cachedUnfilteredResultsByQuery[searchQuery];
      if (cachedUnfilteredResults && this.searchDataSource === SearchDataSource.SEARCH) {
        // Cache entry for searchQuery exists, early return cached result groups
        return of(this.filterSubmarkets(InfrontUtil.deepCopy(cachedUnfilteredResults) as FullSearchResultGroup[]));
      }

      // SDK Search starting...
      return this.getSearchInputResults$(searchQuery).pipe(
        map(({ results, source }) => {
          // Update search data source
          this.searchDataSource = source;

          if (!results?.length) {
            // Update cache -> no results for the given query
            this.cachedUnfilteredResultsByQuery[searchQuery] = [];
            return [];
          }

          // Build result groups
          let resultGroups = this.getSearchResultGroups(results, this.searchDataSource);
          // Update cache with unfiltered items
          this.cachedUnfilteredResultsByQuery[searchQuery] = InfrontUtil.deepCopy(resultGroups) as FullSearchResultGroup[];
          // Apply hideSubmarkets filter
          if (source === SearchDataSource.SEARCH && hideSubmarkets) {
            resultGroups = this.filterSubmarkets(resultGroups);
          }
          return resultGroups;
        })
      );
    }),
    shareReplay(1),
  );

  readonly currentExpandedItemAction = new BehaviorSubject<Exclude<FullSearchResultItem, FullSearchWindowResultItem> | undefined>(undefined);
  readonly currentExpandedItem$ = this.currentExpandedItemAction.asObservable();

  readonly rawWatchlists$ = combineLatest([this.watchlistService.watchlists$, this.currentExpandedItem$]).pipe(
    tap(([{ watchlists }, currentExpandedItem]) => {
      const extendedWatchlists = watchlists.reduce<SearchResultSymbolWatchlist[]>((extendedWls, wl) => {
        const extendedWl: SearchResultSymbolWatchlist = {
          ...wl,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          hasInstrument: wl.items?.some((item) => item.ticker === (currentExpandedItem as any)?.['Ticker'] && item.feed === currentExpandedItem!.Feed),
          hide: this.watchlistService.shouldDisableWriteAccessForWatchlist$(wl),
        };
        extendedWls.push(extendedWl);
        return extendedWls;
      }, []);
      this.watchlistsAction.next(extendedWatchlists);
    }),
    shareReplay(1)
  );
  readonly watchlistsAction = new LastValueSubject<SearchResultSymbolWatchlist[]>();
  readonly watchlists$ = this.watchlistsAction.asObservable();

  isTradable = false;

  // class properties for usage in template
  readonly SearchResultItemType = SearchResultItemType;
  readonly SymbolClassification = InfrontSDK.SymbolClassification;
  readonly SearchDataSource = SearchDataSource;
  readonly ToggleShowMoreSearchResultsThreshold: Readonly<number> = ToggleShowMoreSearchResultsThreshold;
  readonly MaxFullSearchResultGroupItemLimit: Readonly<number> = MaxFullSearchResultGroupItemLimit;
  readonly canAddWindowForClassification = canAddWindowForClassification;

  ngAfterViewInit(): void {
    this.searchElm.nativeElement.addEventListener('keyup', (event) => {
      if (event.key === 'Escape') {
        this.closeDropdown();
      }
    });
  }

  ngOnDestroy(): void {
    this.searchInputAction.complete();
    this.showDropdownAction.complete();
    this.currentExpandedItemAction.complete();
    this.watchlistsAction.complete();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * toggles the expanded state of the item
   * and unexpands the previously expanded item
   * if the toggle item is expanded it will
   * be set as the new expandedItem
   * @param item
   */
  onToggleItemExpand(item: Exclude<FullSearchResultItem, FullSearchWindowResultItem>): void {
    item.expanded = !item.expanded;

    const expandedItem = this.currentExpandedItemAction.getValue();

    if (item !== expandedItem) {
      // item.expanded === true
      if (expandedItem != undefined) {
        expandedItem.expanded = false;
      }
      this.currentExpandedItemAction.next(item);
    } else {
      // item.expanded === false
      this.currentExpandedItemAction.next(undefined);
    }

    if (item.expanded && item.itemType === InfrontSDK.SearchResultItemType.Symbol) {
      this.tradableService.isTradable$({ feedHasTrading: item.IsTradable, instrument: item, classification: item.SymbolClassification }).pipe(take(1), takeUntil(this.ngUnsubscribe), tap((isTradable) => this.isTradable = isTradable)).subscribe();
    }
  }

  private getSearchInputResults$(query: string | undefined): Observable<{ results: SdkSearchResultItem[]; source: SearchDataSource; }> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.sdkSearchService.search$(query, this.searchConfig, this).pipe(
      map(({ results, source }) => {
        return {
          results: results as SdkSearchResultItem[],
          source,
        };
      }),
      catchError((e) => of(e).pipe(
        tap((e) => this.logger.error(e))
      ))
    );
  }

  private getSearchResultGroups(searchResults: (SdkSearchResultItem | FullSearchWindowResultItem)[], searchDataSource: SearchDataSource): FullSearchResultGroup[] {
    const resultGroups =
      searchDataSource === SearchDataSource.SEARCH
        ? this.insertSearchResultsIntoGroups(searchResults, getFullSearchResultGroups())
        : searchDataSource === SearchDataSource.HISTORY // NOSONAR nested ternary accepted
          ? this.insertSearchResultsIntoHistoryGroup(searchResults, [getHistoryFullSearchResultGroup()])
          : [];
    return resultGroups;
  }

  private insertSearchResultsIntoGroups(searchResults: (SdkSearchResultItem | FullSearchWindowResultItem)[], resultGroups: FullSearchResultGroup[]): FullSearchResultGroup[] {
    searchResults.forEach((item) => {
      switch (item.itemType) {
        case this.SearchResultItemType.Symbol: {
          const symbolGroupingItem = findSearchResultGroup(item, resultGroups) as FullSearchSymbolResultGroup;
          if (
            isDefined<FullSearchSymbolResultGroup>(symbolGroupingItem) &&
            !symbolGroupingItem.symbolClassificationList.includes(InfrontSDK.SymbolClassification.Unknown)
          ) {
            updateHighestSearchScore(symbolGroupingItem, item);
            symbolGroupingItem.items.push({ ...item, expanded: false, expandedTab: 'windows' });
          }
          break;
        }
        case this.SearchResultItemType.Market: {
          const marketGroupItem = findSearchResultGroup(item, resultGroups) as FullSearchMarketResultGroup;
          if (isDefined<FullSearchMarketResultGroup>(marketGroupItem)) {
            updateHighestSearchScore(marketGroupItem, item);
            marketGroupItem.items.push({ ...item, expanded: false, expandedTab: 'windows' });
          }
          break;
        }
        case 'Window': {
          const windowGroupItem = findSearchResultGroup(item, resultGroups) as FullSearchWindowResultGroup;

          if (isDefined<FullSearchWindowResultGroup>(windowGroupItem)) {
            updateHighestSearchScore(windowGroupItem, item);
            windowGroupItem.items.push({ ...item });
          }
          break;
        }
      }
    });

    resultGroups.sort((a, b) => (a.highestSearchScore < b.highestSearchScore ? 1 : -1));

    return resultGroups;
  }

  private insertSearchResultsIntoHistoryGroup(searchResults: (SdkSearchResultItem | FullSearchWindowResultItem)[], resultGroups: FullSearchResultGroup[]): FullSearchResultGroup[] {
    searchResults = searchResults.filter(item => item.itemType !== 'Window');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
    searchResults.forEach((item) => (resultGroups[0].items as any).push({ ...item, expanded: false, expandedTab: 'windows' }));
    return resultGroups;
  }

  private filterSubmarkets(resultGroups: FullSearchResultGroup[]): FullSearchResultGroup[] {
    resultGroups.forEach((group) => {
      if (group.itemType === InfrontSDK.SearchResultItemType.Symbol) {
        group.items = group.items?.filter((item) => !item.FeedIsMTFSubMarket);
      }
    });

    return resultGroups;
  }

  closeDropdown(): void {
    this.showDropdownAction.next(false);
    this.unexpandItem();
    this.clearSearchInput();
  }

  clearSearchInput(): void {
    this.searchElm.nativeElement.blur();
    this.searchInputAction.next('');
  }

  unexpandItem(): void {
    const expandedItem = this.currentExpandedItemAction.getValue();
    if (expandedItem?.expanded) {
      expandedItem.expanded = false;
      this.currentExpandedItemAction.next(undefined);
    }
  }

  onClickToggleWatchlistInstrument(wl: SearchResultSymbolWatchlist): void {
    const expandedItem = this.currentExpandedItemAction.getValue();
    if (expandedItem != undefined && isSdkSymbolSearchResultItem(expandedItem)) {
      this.toggleWatchlistInstrument(wl, expandedItem);
      this.sdkSearchService.addItemToSearchHistory(expandedItem, this.searchConfig.history, this);
    }
  }

  addSymbolItemToHistory(item: SdkSymbolSearchResultItem): void {
    this.sdkSearchService.addItemToSearchHistory(item, this.searchConfig.history, this);
  }

  addMarketItemToHistory(item: SdkSearchResultItem): void {
    if (isSdkMarketSearchResultItem(item)) {
      this.sdkSearchService.addItemToSearchHistory(item, this.searchConfig.history, this);
    } else if (isSdkSymbolSearchResultItem(item)) {
      this.sdkSearchService.addItemToSearchHistory({ feed: item.feed } as SdkMarketSearchResultItem, this.searchConfig.history, this);
    }
  }

  private toggleWatchlistInstrument(wl: SearchResultSymbolWatchlist, item: FullSearchSymbolSearchResultItem): void {
    this.watchlistService
      .updateWatchlist(wl.title, { ticker: item.Ticker, feed: item.Feed }, wl.provider, !wl.hasInstrument);

  }

  toggleShowMoreResult(toggleGroupOpts: {
    resultGroup: Exclude<FullSearchResultGroup, FullSearchWindowResultGroup>;
    collapse?: boolean;
    resultGroups?: FullSearchResultGroup[];
    collapseRest?: boolean;
  }): void {
    if (toggleGroupOpts.resultGroups?.length) {
      const toggleExpand = (result: Exclude<FullSearchResultGroup, FullSearchWindowResultGroup>) => result.items.forEach((item) => (item.expanded = false));
      const resultCallback = toggleGroupOpts.collapseRest
        ? (result: Exclude<FullSearchResultGroup, FullSearchWindowResultGroup>) => {
          result.collapse = false;
          toggleExpand(result);
        }
        : (result: Exclude<FullSearchResultGroup, FullSearchWindowResultGroup>) => toggleExpand(result);
      const expandAndMaybeCollapseRest = (resultCallback: (result: Exclude<FullSearchResultGroup, FullSearchWindowResultGroup>) => void) =>
        toggleGroupOpts.resultGroups?.forEach((result) => resultCallback(result as Exclude<FullSearchResultGroup, FullSearchWindowResultGroup>));

      expandAndMaybeCollapseRest(resultCallback);
    }

    const toggle = (toggleGroupOpts.resultGroup.showMore = !toggleGroupOpts.resultGroup.showMore);
    if (toggleGroupOpts.collapse != undefined) {
      toggleGroupOpts.resultGroup.collapse = toggle;
    }
  }

  addAlert = (instrument: Instrument) => {
    this.alertService.openAlertDialog({ alertType: 'instrument', instrument });
    this.closeDropdown();
  };

  openOrderEntry(item: Instrument) {
    const instrument = isInstrument(item) ? new Infront.Instrument(item.feed, item.ticker) : undefined;
    this.tradingOrderEntryService.openOrderEntry({ instrument });
    this.closeDropdown();
  }
}
