<div class="wt-news-story-dialog">
  <div class="wt-flex-row wt-flex-align-middle wt-news-story-dialog__header">
    <div class="wt-flex-column wt-flex-shrink wt-flex-collapse">
      <mat-icon class="wt-news-story-dialog__header-icon" fontIcon="newspaper"></mat-icon>
    </div>

    <div class="wt-flex-column">
      <h2 class="wt-news-story-dialog__header-headline">{{ data.newsHeadline.headline }}</h2>
    </div>

    <div class="wt-flex-column wt-flex-shrink wt-flex-collapse">
      <mat-icon mat-dialog-close class="wt-news-story-dialog__header-icon-close" fontIcon="close"></mat-icon>
    </div>
  </div>

  <div class="wt-news-story-dialog__body" [innerHTML]="data.body"></div>
</div>