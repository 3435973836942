import { Injectable } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { Observable, of } from 'rxjs';
import { map, mergeAll, switchMap } from 'rxjs/operators';

import { SdkService } from '../../../services/sdk.service';
import type { Instrument } from '../../../state-model/window.model';
import { getCountryForSymbol } from '../../../util/country';
import { ObservablesCache } from '../../../wrappers/grid-wrappers/observables-cache';
import { ObservableCacheSubscribeFields, SymbolFieldsMap, type SymbolInfo } from './instrument-dock-item.model';

@Injectable({
  providedIn: 'any',
})
export class InstrumentDockItemService {
  private observablesCache = new ObservablesCache<SymbolInfo>();

  symbolData$ = (id: Instrument): Observable<InfrontSDK.SymbolData> => {
    const opts: Partial<InfrontSDK.SymbolDataOptions> = {
      id,
      subscribe: true,
      content: {
        Basic: true,
      },
    };
    return this.sdkService.getObject$(InfrontSDK.symbolData, opts);
  };

  symbolInfo$ = (instrument: Instrument, uuid: string): Observable<SymbolInfo> =>
    this.symbolData$(instrument).pipe(
      switchMap((symbolData: InfrontSDK.SymbolData) =>
        this.symbolInfoMap$(symbolData).pipe(
          switchMap((symbolInfo: SymbolInfo) => {
            const symbolInfoUpdate$: Observable<Partial<SymbolInfo>> = this.observablesCache
              .observeList$([symbolData], InfrontSDK.SymbolField.PreLastTradedAt, ObservableCacheSubscribeFields, uuid)
              .pipe(mergeAll());

            return symbolInfoUpdate$.pipe(map((symbolInfoUpdate) => ({ ...symbolInfo, ...symbolInfoUpdate })));
          })
        )
      )
    );

  constructor(private sdkService: SdkService) { }

  private symbolInfoMap$(symbolData: InfrontSDK.SymbolData): Observable<SymbolInfo> {
    const symbolInfo = {} as SymbolInfo;

    Object.entries(SymbolFieldsMap).forEach(([fieldName, symbolInfoItem]) => {
      if (fieldName === 'countryFlag') {
        symbolInfo[fieldName] = getCountryForSymbol(symbolData);
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (symbolInfo as any)[fieldName] = symbolData.get(symbolInfoItem.symbolField) as unknown;
    });

    return of(symbolInfo);
  }
}
