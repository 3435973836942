import { Injectable } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { take } from 'rxjs';
import { map } from 'rxjs/operators';
import { SdkRequestsService } from '../services/sdk-requests.service';
import { StoreService } from '../services/store.service';
import type { Instrument } from '../state-model/window.model';

@Injectable({
  providedIn: 'root',
})
export class InstrumentDashboardService {

  constructor(
    private readonly storeService: StoreService,
    private sdkRequestsService: SdkRequestsService,
  ) { }

  instrumentDashboard$ = this.storeService.instrumentDashboard$;

  /**
  * generate a new instrument dashboard for the given instrument
  * @param instrument the instrument to generate the dashboard for
  */
  addInstrumentDashboardAsync(instrument: Instrument): void {
    if (!instrument) {
      return;
    }
    this.sdkRequestsService
      .snapshotSymbolData$({ symbolEntity: instrument, fields: [InfrontSDK.SymbolField.SymbolClassification] })
      .pipe(take(1),
        map((symbol) => {
          const classification = symbol?.SymbolClassification;
          if (classification) {
            return this.storeService.addInstrumentDashboardByClassification(instrument, classification);
          }
          return undefined;
        }))
      .subscribe();
  }
}
