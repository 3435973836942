import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { MatDropdownType } from '../mat-dropdown.model';
import type { CountryMatDropdownItem } from './mat-dropdown-country.model';

@Component({
  selector: 'wt-mat-dropdown-country',
  templateUrl: './mat-dropdown-country.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatDropdownCountryComponent {
  @Output() selectCountryItem = new EventEmitter<CountryMatDropdownItem>();
  @Input({ required: true }) countryDropdownItems!: CountryMatDropdownItem[];

  MatDropdownType = MatDropdownType;
}
