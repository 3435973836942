import {
  DashboardFolderLevel,
  type DashboardModel,
  DashboardType as FxDashboardType,
  KnownDashboardFolderIDs,
} from '@infront/ngx-dashboards-fx';

import { DashboardType } from '../../state-model/dashboard.model';
import { deepFreeze } from '../../util/object';

export const PORTFOLIO_FOLDER_ID = '$portfolios-folder';
export const PORTFOLIO_DASHBOARD_ID = '$portfolio';

export const PORTFOLIO_DASHBOARDS = deepFreeze([
  {
    id: PORTFOLIO_FOLDER_ID,
    name: 'Portfolio Dashboards',
    type: FxDashboardType.FOLDER,
    index: 1001,
    attributes: {},
    hidden: true,
    security: {
      canAddChildren: false,
      canDeleteChildren: false,
      canEdit: false,
      canMove: false,
      canDelete: false,
    },
    childrenLoadState: 'loaded',
    parentId: KnownDashboardFolderIDs.ROOT,
    level: DashboardFolderLevel.CUSTOM,
  },
  {
    id: PORTFOLIO_DASHBOARD_ID,
    name: 'DASHBOARD.TEMPLATE.PORTFOLIO',
    type: FxDashboardType.DASHBOARD,
    index: 1001,
    attributes: {
      wtDashboardType: DashboardType.portfolio, // FIXME required?
    },
    hidden: false,
    security: {
      canEdit: false,
      canMove: false,
      canDelete: false,
    },
    sharing: {
      canShare: false,
      isShared: false,
      isSharedWithMe: false,
    },
    parentId: PORTFOLIO_FOLDER_ID,
    level: DashboardFolderLevel.CUSTOM,
    isDataDraft: false,
  },
] as DashboardModel[]);
