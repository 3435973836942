import { ChangeDetectionStrategy, Component, Input, type OnDestroy, type OnInit, inject } from '@angular/core';
import { InfrontUtil } from '@infront/sdk';
import { EMPTY, Observable, ReplaySubject, merge, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

import { SdkRequestsService } from '../../services/sdk-requests.service';
import type { Widget } from '../../state-model/widget.model';
import type { Instrument } from '../../state-model/window.model';
import type { InstrumentFocusData } from './instrument-focus.model';
import { InstrumentFocusService } from './instrument-focus.service';

@Component({
  selector: 'wt-instrument-focus',
  templateUrl: './instrument-focus.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstrumentFocusComponent implements OnInit, OnDestroy {
  private readonly sdkRequestsService = inject(SdkRequestsService);
  private readonly instrumentFocusService = inject(InstrumentFocusService);

  // Instrument can be either set by binding the widget or instrument-objects!
  @Input() set widget(widget: Widget) {
    this.widgetAction.next(widget);
  }
  @Input() set instrument(instrument: Instrument) {
    this.instrumentAction.next(instrument);
  }
  // Data can be provided or component gets it from SDK.
  @Input() set data(data: InstrumentFocusData) {
    // IWT-742 get rid of "time" Date-object reference for change detection to update the new date/time in view!
    const time = InfrontUtil.isDate(data?.time) ? new Date(data?.time) : undefined;
    this.dataAction.next({ ...data, time });
  }

  private readonly dataAction = new ReplaySubject<InstrumentFocusData | undefined>(1);
  private readonly instrumentAction = new ReplaySubject<Instrument | undefined>(1);
  private readonly widgetAction = new ReplaySubject<Widget | undefined>(1);

  data$!: Observable<InstrumentFocusData>;

  readonly instrument$ = merge(
    this.instrumentAction,
    this.widgetAction.pipe(
      switchMap((widget) => widget ? this.sdkRequestsService.windowInstrument$(widget) : EMPTY),
    ),
  );

  ngOnInit(): void {
    this.data$ = this.dataAction.pipe(
      // components do not need to provide the data, but we need to start anyway!
      startWith(undefined),
      switchMap((data) => {
        if (data) {
          return of(data);
        }
        return this.instrument$.pipe(
          switchMap((instrument) => instrument ? this.instrumentFocusService.data$(instrument) : EMPTY),
        );
      }),
    );
  }

  ngOnDestroy(): void {
    this.dataAction?.complete();
    this.instrumentAction?.complete();
    this.widgetAction?.complete();
  }

}
