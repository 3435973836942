import type { InfrontSDK } from '@infront/sdk';

import type { Column } from '../../../shared/grid/columns.model';
import type { SymbolDataItem } from '../../../shared/models/symbol-data.model';
import type { SidebarTab } from '../sidebar.model';

export interface GlobalTabInstrumentList {
  name: string;
  instruments: [];
}

export interface GlobalTabSymbolDataList {
  name: string;
  symbolData: SymbolDataItem[];
}

export const DEBOUNCE_IN_MS = 150;

export enum WatchlistSubTab {
  DEFAULT = 1,
  PERFORMANCE,
}

export interface GridTab {
  [subTab: string]: GridSubTab;
}

export interface GridSubTab {
  selectedColumns: readonly Column[];
}

export type GridTabs = Record<SidebarTab, GridTab>; // to be deprecated and removed

export type WatchlistSelectedSubTabs = {
  [watchlistId: InfrontSDK.Watchlist['id']]: WatchlistSubTab;
};

export type Sidebar = {
  isOpen?: boolean;
  selectedTab?: SidebarTab;
  selectedWatchlist?: string;
};

export type SubTabColumnsConfig = Record<string | WatchlistSubTab, GridSubTab>;
export type TabColumnsConfig = Record<SidebarTab, SubTabColumnsConfig>;
