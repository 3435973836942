
import { InfrontSDK } from '@infront/sdk';
import type { SortDirection } from 'ag-grid-community';
import { ListsColumnDefs } from '../../widgets/lists/lists.columns';
import { upDownColor } from '../grid/column-registry';
import type { Column } from '../grid/columns.model';

export const Default: Column[] = [
  { ...ListsColumnDefs.ISIN, width: 110 },
  { ...ListsColumnDefs.listsCurrency, width: 52 },
  { ...ListsColumnDefs.fullName, width: 240, headerName: 'Description' },
  {
    colId: 'fundType',
    headerName: 'Type',
    field: InfrontSDK.BasicField.FundType,
    width: 94,
  },
  {
    colId: 'segment',
    headerName: 'Segment',
    field: InfrontSDK.BasicField.PrimarySegment,
    width: 170,
  },
  // { // not yet available from backend
  //   colId: 'ongoingCharge',
  //   headerName: 'Ongoing Charge',
  //   field: InfrontSDK.SearchResultField.FundOngoingCharge,
  // },
  {
    colId: 'performanceYTD',
    headerName: 'YTD %',
    field: InfrontSDK.SearchResultField.PerformanceYTD,
    width: 65,
    type: 'rightAligned',
    valueFormatter: 'twoDecimalsPercent',
    // eslint-disable-next-line no-null/no-null, no-restricted-syntax
    sortingOrder: ['desc', 'asc', null] as SortDirection[],
    cellClass: (params: { value: number }) => [...upDownColor(params, 0), 'ag-right-aligned-cell'],
  },
  {
    colId: 'performance1Y',
    headerName: '1Y %',
    field: InfrontSDK.SearchResultField.Performance1Y,
    width: 65,
    type: 'rightAligned',
    valueFormatter: 'twoDecimalsPercent',
    // eslint-disable-next-line no-null/no-null, no-restricted-syntax
    sortingOrder: ['desc', 'asc', null] as SortDirection[],
    cellClass: (params: { value: number }) => [...upDownColor(params, 0), 'ag-right-aligned-cell'],
  },
  {
    colId: 'performance3Y',
    headerName: '3Y % ann.',
    field: InfrontSDK.SearchResultField.Performance3Y,
    width: 82,
    type: 'rightAligned',
    valueFormatter: 'twoDecimalsPercent',
    // eslint-disable-next-line no-null/no-null, no-restricted-syntax
    sortingOrder: ['desc', 'asc', null] as SortDirection[],
    cellClass: (params: { value: number }) => [...upDownColor(params, 0), 'ag-right-aligned-cell'],
  },
  {
    colId: 'performance5Y',
    headerName: '5Y % ann.',
    field: InfrontSDK.SearchResultField.Performance5Y,
    width: 82,
    type: 'rightAligned',
    valueFormatter: 'twoDecimalsPercent',
    // eslint-disable-next-line no-null/no-null, no-restricted-syntax
    sortingOrder: ['desc', 'asc', null] as SortDirection[],
    cellClass: (params: { value: number }) => [...upDownColor(params, 0), 'ag-right-aligned-cell'],
  },
  // { // available and can be used if we decice
  //   colId: 'performance10Y',
  //   headerName: '10Y %',
  //   field: InfrontSDK.SearchResultField.Performance10Y,
  //   width: 94,
  // },
  {
    colId: 'fundRiskLevel',
    headerName: 'Risk',
    field: InfrontSDK.SearchResultField.FundRiskLevel,
    type: 'rightAligned',
    // eslint-disable-next-line no-null/no-null, no-restricted-syntax
    sortingOrder: ['desc', 'asc', null] as SortDirection[],
    width: 60,
  },
  {
    colId: 'fundStarRating',
    headerName: 'Rating',
    field: InfrontSDK.SearchResultField.FundStarRating,
    width: 90,
    type: 'rightAligned',
    // eslint-disable-next-line no-null/no-null, no-restricted-syntax
    sortingOrder: ['desc', 'asc', null] as SortDirection[],
    cellRenderer: 'starRatingComponent'
  },

].map(c => ({ ...c, resizable: false, suppressMovable: true })); // all cols are static except for sorting

// not included in first version
export const Performance: Column[] = [];
export const Info: Column[] = [];

export const MorningStarOnlyColumns = ['fundStarRating'];

export const ColumnCategories = { Default: Default, Performance: Performance, Info: Info } as const;


