import { ChangeDetectionStrategy, Component, Injectable, Input, type OnDestroy, inject } from '@angular/core';
import { LastValueSubject } from '@infront/ngx-dashboards-fx/utils';
import { Observable, map, switchMap, take } from 'rxjs';
import type { UserSettingsStorageData } from '../../services/storage.service';
import {
  GRID_OPEN_INSTRUMENT_DASHBOARD_DEFAULT,
  HIGHLIGHT_UP_DOWN_CHANGES_DEFAULT,
  ORDER_ENTRY_WIDGET_AUTO_FILL_PRICE_DEFAULT,
  ORDER_ENTRY_WIDGET_CONFIRMATION_DEFAULT,
  ORDER_ENTRY_WIDGET_REMEMBER_VOLUME_DEFAULT,
  TRADING_TAB_HIDE_INACTIVE_DELETED_ORDERS_DEFAULT,
} from '../../shared/models/settings.model';
import type { OrderEntryWidgetOptions } from '../../shared/models/trading.model';
import { GridService } from '../grid/grid.service';

type OrderEntrySettingsKey = keyof OrderEntryWidgetOptions;
type BooleanSettingsKey = 'tradingTabHideInactiveDeletedOrders' | 'highlightUpDownChanges' | 'enableGridOpenInstrumentDashboard';

export type SettingsStorageData = Pick<UserSettingsStorageData, 'orderEntryWidgetOptions' | 'tradingTabHideInactiveDeletedOrders' | 'highlightUpDownChanges' | 'enableGridOpenInstrumentDashboard'>;

@Injectable()
export abstract class GeneralSettingsService {
  abstract setValue<K extends keyof SettingsStorageData>(key: K, value: SettingsStorageData[K] | undefined): Observable<void>;
  abstract getValue$<K extends keyof SettingsStorageData>(key: K): Observable<SettingsStorageData[K]>;
}

@Component({
  selector: 'wt-general-settings',
  templateUrl: './general-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralSettingsComponent implements OnDestroy {
  private readonly gridService = inject(GridService);

  @Input()
  get userSettingsService(): GeneralSettingsService { return this.userSettingsServiceAction.value!; }
  set userSettingsService(value: GeneralSettingsService) { this.userSettingsServiceAction.next(value); }

  private readonly userSettingsServiceAction = new LastValueSubject<GeneralSettingsService>();

  readonly orderEntrySettings$: Observable<OrderEntryWidgetOptions> = this.getValue$('orderEntryWidgetOptions').pipe(
    map(orderEntryWidgetOptions => ({
      confirmation: orderEntryWidgetOptions?.confirmation ?? ORDER_ENTRY_WIDGET_CONFIRMATION_DEFAULT,
      autoFillPrice: orderEntryWidgetOptions?.autoFillPrice ?? ORDER_ENTRY_WIDGET_AUTO_FILL_PRICE_DEFAULT,
      rememberVolume: orderEntryWidgetOptions?.rememberVolume ?? ORDER_ENTRY_WIDGET_REMEMBER_VOLUME_DEFAULT,
    })
    )
  );

  readonly tradingTabHideInactiveDeletedOrders$ = this.getValue$('tradingTabHideInactiveDeletedOrders').pipe(
    map(tradingTabHideInactiveDeletedOrders => tradingTabHideInactiveDeletedOrders ?? TRADING_TAB_HIDE_INACTIVE_DELETED_ORDERS_DEFAULT)
  );

  readonly highlightUpDownChanges$ = this.getValue$('highlightUpDownChanges').pipe(
    map(highlightUpDownChanges => highlightUpDownChanges ?? HIGHLIGHT_UP_DOWN_CHANGES_DEFAULT),
  );

  readonly enableGridOpenInstrumentDashboard$ = this.getValue$('enableGridOpenInstrumentDashboard').pipe(
    map(enableGridOpenInstrumentDashboard => enableGridOpenInstrumentDashboard ?? GRID_OPEN_INSTRUMENT_DASHBOARD_DEFAULT),
  );

  ngOnDestroy(): void {
    this.userSettingsServiceAction.complete();
  }

  private getValue$<K extends keyof SettingsStorageData>(key: K): Observable<SettingsStorageData[K]> {
    return this.userSettingsServiceAction.pipe(
      switchMap((userSettingsService) => userSettingsService.getValue$(key))
    );
  }

  changeOrderEntrySetting(key: OrderEntrySettingsKey, checked: boolean): void {
    this.userSettingsService.getValue$('orderEntryWidgetOptions').pipe(
      take(1),
    ).subscribe((orderEntryWidgetOptions) => {
      const settings = { ...orderEntryWidgetOptions ?? {}, [key]: checked };
      this.userSettingsService.setValue('orderEntryWidgetOptions', settings);
    });
  }

  changeBooleanSetting(key: BooleanSettingsKey, checked: boolean): void {
    this.userSettingsService.setValue(key, checked);

    if (['enableGridOpenInstrumentDashboard'].includes(key)) {
      // for grid-change we need to refresh cells, e.g. to make new CSS classes active!
      this.gridService.requestGridCellRefresh$.next(true);
    }
  }

}
