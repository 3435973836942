import type { StoreService } from '../../../../services/store.service';
import type { ContextMenuClickHandler, ContextMenuItem } from '../../../../shared/models/context-menu.model';
import type { NewsType, NewsWindow } from '../../../../state-model/window.model';

export type NewsTypeContextMenuSubItem = ContextMenuItem<NewsType, string>;
export type NewsTypeContextMenuItem = ContextMenuItem<string, string, NewsTypeContextMenuSubItem>;

export const newsTypeItem = (window: NewsWindow, storeService: StoreService): NewsTypeContextMenuItem => {
  const getClickHandler = (newsType: NewsType): ContextMenuClickHandler => (context): void => {
    if (!context.window) {
      return;
    }
    const newWindow = { ...context.window, settings: { ...context.window.settings, newsType } };
    storeService.updateWindow(context.window, newWindow);
  };

  return {
    id: 'newsType',
    title: `News type: ${window.settings.newsType as string}`,
    icon: 'playlist_add',
    subItems: [
      {
        id: 'country',
        title: 'Country',
        icon: 'flag',
        type: 'radio',
        isSVGIcon: true,
        checked: window.settings.newsType === 'Country',
        closeOnClick: true,
        onClick: getClickHandler('Country'),
      },
      {
        id: 'watchlist',
        title: 'Watchlist',
        icon: 'visibility',
        type: 'radio',
        isSVGIcon: true,
        checked: window.settings.newsType === 'Watchlist',
        closeOnClick: true,
        onClick: getClickHandler('Watchlist'),
      },
      {
        id: 'instrument',
        title: 'Instrument',
        icon: 'table_rows',
        type: 'radio',
        isSVGIcon: true,
        checked: window.settings.newsType === 'Instrument',
        closeOnClick: true,
        onClick: getClickHandler('Instrument'),
      },
      {
        id: 'portfolio',
        title: 'Portfolio',
        icon: 'account_balance',
        type: 'radio',
        isSVGIcon: false,
        checked: window.settings.newsType === 'Portfolio',
        closeOnClick: true,
        onClick: getClickHandler('Portfolio'),
      },
    ],
  };
};
