import { Injectable } from '@angular/core';
import { InfrontSDK, InfrontUtil } from '@infront/sdk';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';


import { arrayDifference } from '../util/array';
import { filterUndefined } from '../util/rxjs';
import { SdkRequestsService } from './../services/sdk-requests.service';
import { TradingService } from './../services/trading.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class TradingTradesService {
  constructor(private sdkRequestsService: SdkRequestsService, private tradingService: TradingService, private notificationService: NotificationService) { }

  // trades are typed as portfolioItems in SDK
  private lastPortfolioItems: InfrontSDK.Trading.PortfolioItem[] | undefined = undefined;

  private lastTradeId: string | undefined = undefined;

  trades$: Observable<InfrontSDK.Trading.PortfolioItem[]> = this.tradingService.portfolioData$.pipe(
    filterUndefined(),
    tap(() => this.lastPortfolioItems = undefined), // reset lastPortfolioItems on each call so that the first load can be filtered out to not be used as diff
    switchMap((portfolioData: InfrontSDK.Trading.PortfolioData) => {
      const obsArray = portfolioData.trades({ showTodaysTrades: true, showHistoricTrades: true }) as InfrontUtil.ObservableArray<InfrontSDK.Trading.PortfolioItem>;
      //obsArray.data.forEach((item) => console.log('trades!!! item inspect:', (item.get as any)(null), 'metadata:', item.metadata())); // debug
      return this.sdkRequestsService.sdkObservableArrayAdapter$<InfrontSDK.Trading.PortfolioItem>(obsArray);
    }),
    map((symbols) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const portfolioItems = symbols.map(s => ({ ...s, feed: s.symbolId?.feed, ticker: s.symbolId?.ticker, tradeId: s.get(InfrontSDK.TradingField.TradeId) }));
      if (this.lastPortfolioItems) {
        const diff = arrayDifference(portfolioItems, this.lastPortfolioItems, ['tradeId']);
        const item = diff[0] as InfrontSDK.Trading.PortfolioItem & { tradeId: string };
        // do not send notification if the trade diff is the same as the last one (when multiple emits of same values)
        if (diff.length && (this.lastTradeId !== item['tradeId'])) {
          this.notificationService.tradeNotification(item);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          this.lastTradeId = item['tradeId'];
        }
      }
      return portfolioItems;
    }),
    tap((portfolioItems) => this.lastPortfolioItems = portfolioItems),
  );

  private tradesSubscription = this.trades$.subscribe(); // we need to self subscribe in order to trigger trade toast messages from anywhere in the UI
}





