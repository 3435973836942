import { Component, Input } from '@angular/core';

import type { AlertLogWindow } from '../../../state-model/window.model';
import type { Dashboard } from '../../../state-model/dashboard.model';

@Component({
  selector: 'wt-alert-log-header',
  templateUrl: './alert-log-header.component.html',
})
export class AlertLogHeaderComponent {
  @Input({ required: true }) dashboard!: Dashboard;
  @Input({ required: true }) window!: AlertLogWindow;
}
