import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import type { NewsHeadline } from '../../widgets/news/news.model';

@Component({
  selector: 'wt-news-story-dialog',
  templateUrl: './news-story-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsStoryDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      newsHeadline: NewsHeadline;
      body: string;
    }
  ) { }

}
