import { ChangeDetectionStrategy, Component, Input, type OnDestroy, inject } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { Observable, ReplaySubject, Subject, combineLatest, distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, take, takeUntil, tap, throttleTime } from 'rxjs';

import { DashboardService } from '../../../dashboard/dashboard.service';
import { StoreService } from '../../../services/store.service';
import { TradingService } from '../../../services/trading.service';
import { WatchlistService } from '../../../services/watchlist.service';
import type { Dashboard } from '../../../state-model/dashboard.model';
import type { Widget } from '../../../state-model/widget.model';
import { type CalendarWindow, isInstrumentSettings } from '../../../state-model/window.model';
import { arraysAreEqual, structuresAreEqual } from '../../../util/equality';
import { instrumentsAreEqual } from '../../../util/sdk';
import type { CountryMatDropdownItem } from '../../ui/mat-dropdown/mat-dropdown-country/mat-dropdown-country.model';
import { MatDropdownType } from '../../ui/mat-dropdown/mat-dropdown.model';
import { CompactSearchContainerService } from './../../../search/compact-search/compact-search-container/compact-search-container.service';
import { MatDropdownItemType } from './../../ui/mat-dropdown/mat-dropdown-item/mat-dropdown-item.model';
import type { CalendarHeaderVm } from './calendar-header.model';
import { CalendarHeaderService } from './calendar-header.service';
import { filterUndefined } from '../../../util/rxjs';

@Component({
  selector: 'wt-calendar-header',
  templateUrl: './calendar-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarHeaderComponent implements OnDestroy {
  private readonly storeService = inject(StoreService);
  private readonly calendarHeaderService = inject(CalendarHeaderService);
  private readonly dashboardService = inject(DashboardService);
  private readonly watchlistService = inject(WatchlistService);
  private readonly tradingService = inject(TradingService);
  private readonly compactSearchContainerService = inject(CompactSearchContainerService);

  // Dashboard
  @Input() set dashboard(dashboard: Dashboard) {
    this.dashboardAction.next(dashboard);
  }
  dashboardAction = new ReplaySubject<Dashboard>(1);
  dashboard$ = this.dashboardAction.asObservable();

  // Window
  @Input() set window(window: CalendarWindow) {
    this.windowAction.next(window);
  }
  private windowAction = new ReplaySubject<CalendarWindow>(1);
  window$ = this.windowAction.asObservable();

  // Widget
  @Input() set widget(widget: Widget) {
    this.widgetAction.next(widget);
  }
  private widgetAction = new ReplaySubject<Widget>(1);
  widget$ = this.widgetAction.asObservable();

  // Instrument (retrieved by window)
  instrument$ = this.window$.pipe(
    map((window) => window.settings.instrument),
    distinctUntilChanged((prev, next) => instrumentsAreEqual(prev, next)),
    shareReplay(1),
  );

  // Watchlist (retrieved by window)
  private selectedWatchlistAction = new ReplaySubject<InfrontSDK.Watchlist | undefined>(1);
  selectedWatchlist$: Observable<InfrontSDK.Watchlist | undefined> = this.selectedWatchlistAction.asObservable().pipe(
    distinctUntilChanged((prev, next) => structuresAreEqual(prev, next)),
    shareReplay(1),
  );
  selectedPortfolio$ = this.tradingService.selectedPortfolio$;

  // Countries
  countryDropdownItems$: Observable<CountryMatDropdownItem[]> = this.calendarHeaderService.calendarFeedCountries$.pipe(
    map((countries) => countries.map((country) => (
      { country, itemLabel: `MAT_DROPDOWN.ITEM_LABELS.${country.toUpperCase()}`, translateItemLabel: true, type: MatDropdownItemType.SELECTABLE } as CountryMatDropdownItem
    ))),
    switchMap((countryItems) => this.selectedCountries$.pipe(
      map((selectedCountries) => countryItems.map((countryItem) => {
        if (countryItem.type === MatDropdownItemType.SELECTABLE) { // || countryItem.type === MatDropdownItemType.SELECTABLE_FOLDER
          countryItem.selected = selectedCountries.includes(countryItem.country);
        }

        return { ...countryItem };
      })),
    )),
    shareReplay(1),
  );

  private selectedCountriesAction = new ReplaySubject<string[]>(1);
  selectedCountries$ = this.selectedCountriesAction.asObservable().pipe(
    distinctUntilChanged((prev, next) => arraysAreEqual(prev, next)),
    tap((selectedCountries) => this.selectedCountriesQueueAction.next(selectedCountries)),
    shareReplay(1),
  );

  private selectedCountriesQueueAction = new ReplaySubject<string[]>();
  selectedCountriesQueue$ = this.selectedCountriesQueueAction.asObservable().pipe(
    distinctUntilChanged((prev, next) => arraysAreEqual(prev, next)),
    shareReplay(1),
  );

  // SymbolInfo
  symbolInfo$ = this.instrument$.pipe(
    switchMap((instrument) => this.calendarHeaderService.symbolInfo$(instrument)),
    shareReplay(1),
  );

  // ViewModel
  vm$: Observable<CalendarHeaderVm> = combineLatest([
    this.instrument$,
    this.selectedWatchlist$,
    this.symbolInfo$
  ]).pipe(
    map(([instrument, selectedWatchlist, symbolInfo]) => (
      { instrument, selectedWatchlist, symbolInfo }
    )),
    shareReplay(1),
  );

  MatDropdownType = MatDropdownType;

  private ngUnsubscribe = new Subject<void>();

  constructor() {
    // update selectedWatchlist by window
    this.watchlistService.selectedWatchlistInWindow$(this.window$).pipe(
      tap((watchlist) => {
        this.selectedWatchlistAction.next(watchlist);
      }),
      takeUntil(this.ngUnsubscribe),
    ).subscribe();

    // update window.settings.selectedWatchlist by selectedWatchlist
    this.selectedWatchlist$.pipe(
      switchMap((selectedWatchlist) => this.window$.pipe(
        filter((window) => window.settings.selectedWatchlist !== selectedWatchlist?.id),
        tap((window) => {
          this.storeService.updateWindow(window, { settings: { ...window.settings, selectedWatchlist: selectedWatchlist?.id } });
        }),
      )),
      takeUntil(this.ngUnsubscribe),
    ).subscribe();

    // update selectedCountries by window
    this.window$.pipe(
      map((window) => window.settings.countries),
      filterUndefined(),
      distinctUntilChanged((prev, next) => arraysAreEqual(prev, next)),
      startWith<string[]>([]),
      shareReplay(1),
      takeUntil(this.ngUnsubscribe),
    ).subscribe(this.selectedCountriesAction);

    // update window.settings.countries by selectedCountries
    this.selectedCountriesQueue$.pipe(
      throttleTime(300),
      switchMap((selectedCountries) => this.window$.pipe(
        filter((window) => !arraysAreEqual(window.settings.countries, selectedCountries)),
        tap((window) => this.storeService.updateWindow(window, { settings: { ...window.settings, countries: selectedCountries } })),
        take(1),
      )),
      takeUntil(this.ngUnsubscribe),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.dashboardAction.complete();
    this.windowAction.complete();
    this.widgetAction.complete();
    this.selectedWatchlistAction.complete();
    this.selectedCountriesAction.complete();
    this.selectedCountriesQueueAction.complete();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSearchClick(window: CalendarWindow): void {
    this.compactSearchContainerService.onSearchClick(window);
  }

  onSpawnMarketWindow(event: MouseEvent): void {
    event.stopPropagation();
    const feed = isInstrumentSettings(this.window.settings) ? this.window.settings.instrument.feed : undefined;
    if (feed) {
      this.dashboardService.addWindow('MarketWindow', { feed });
    }
  }

  onWatchlistSelected(selectedWatchlist: InfrontSDK.Watchlist | undefined): void {
    this.selectedWatchlistAction.next(selectedWatchlist);
  }

  onCountryItemSelected(item: CountryMatDropdownItem): void {
    if (item.type !== MatDropdownItemType.SELECTABLE) {
      return;
    }

    this.selectedCountriesQueue$.pipe(
      tap((selectedCountries) => {
        selectedCountries = [...selectedCountries];
        const countryIndex = selectedCountries.findIndex((country) => country === item.country);

        if (item.selected) {
          if (countryIndex === -1) {
            selectedCountries.push(item.country);
            this.selectedCountriesQueueAction.next(selectedCountries);
          }
        } else if (countryIndex > -1) {
          selectedCountries.splice(countryIndex, 1);
          this.selectedCountriesQueueAction.next(selectedCountries);
        }
      }),
      take(1),
      takeUntil(this.ngUnsubscribe),
    ).subscribe();
  }
}
