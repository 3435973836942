import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'wt-rename-userlist-dialog',
  templateUrl: './rename-userlist-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
  ]
})
export class RenameUserlistDialogComponent {
  readonly form: FormGroup;

  get name() { return this.form.get('name')!; }


  MAX_USERLIST_NAME_LENGTH = 100;

  constructor(
    public dialogRef: MatDialogRef<RenameUserlistDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { name: string }
  ) {
    this.form = new FormGroup({
      name: new FormControl(data.name, [
        Validators.required
      ]),
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
