import { ChangeDetectionStrategy, Component, Input, type OnDestroy, type OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LastValueSubject } from '@infront/ngx-dashboards-fx/utils';
import { Subject, map, shareReplay, switchMap, takeUntil } from 'rxjs';

import type { BrandingData } from '../../services/storage.service';
import { TenantSettingsService } from '../../services/tenant-settings.service';
import { injectWindowTitleComposer } from '../../util/branding';

@Component({
  selector: 'wt-branding-panel',
  templateUrl: './branding-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandingPanelComponent implements OnInit, OnDestroy {
  private readonly tenantSettings = inject(TenantSettingsService);
  private readonly realmSubject = new LastValueSubject<string>();
  private readonly titlePrefixSubject = new LastValueSubject<string | undefined>();
  private readonly ngUnsubscribe = new Subject<void>();

  @Input()
  get realm(): string { return this.realmSubject.value!; }
  set realm(value: string) {
    if (this.realm !== value) {
      this.canEdit = this.tenantSettings.canEdit(value);
      this.realmSubject.next(value);
    }
  }

  formData$ = this.realmSubject.pipe(
    switchMap((realm) => {
      return this.tenantSettings.getValue$('branding', realm).pipe(
        map((branding) => ({ ...branding })),
        shareReplay(1),
      );
    }),
    takeUntil(this.ngUnsubscribe),
  );

  canEdit = false;

  tabTitlePreview$ = injectWindowTitleComposer(this.titlePrefixSubject);

  readonly MAX_EMAIL_LENGTH = 255;
  readonly MAX_PHONE_LENGTH = 32;
  readonly MAX_WEBSITE_LENGTH = 255;
  readonly MAX_TITLE_PREFIX_LENGTH = 64;
  readonly MAX_MENU_ITEM_LENGTH = 100;
  readonly MAX_DIALOG_TITLE_LENGTH = 100;

  readonly email = new FormControl<string>('', [
    Validators.maxLength(this.MAX_EMAIL_LENGTH),
    Validators.email,
  ]);

  readonly phone = new FormControl<string>('', [
    Validators.maxLength(this.MAX_PHONE_LENGTH),
  ]);

  readonly website = new FormControl<string>('', [
    Validators.maxLength(this.MAX_WEBSITE_LENGTH),
  ]);

  readonly titlePrefix = new FormControl<string>('', [
    Validators.maxLength(this.MAX_TITLE_PREFIX_LENGTH),
  ]);

  readonly supportMenuTitle = new FormControl<string>('', [
    Validators.maxLength(this.MAX_MENU_ITEM_LENGTH),
  ]);

  readonly supportPageTitle = new FormControl<string>('', [
    Validators.maxLength(this.MAX_DIALOG_TITLE_LENGTH),
  ]);

  readonly form: FormGroup = new FormGroup({
    email: this.email,
    phone: this.phone,
    website: this.website,
    titlePrefix: this.titlePrefix,
    supportMenuTitle: this.supportMenuTitle,
    supportPageTitle: this.supportPageTitle,
  });

  ngOnInit(): void {
    this.formData$.subscribe(branding => {
      this.setFormData(branding);
      this.onTitlePrefixUpdate(branding.titlePrefix);
    });
  }

  ngOnDestroy(): void {
    this.realmSubject.complete();
    this.titlePrefixSubject.complete();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private getFormData(): BrandingData {
    /* BEGIN-NOSONAR */
    return {
      email: this.email.value || '',
      phone: this.phone.value || '',
      website: this.website.value || '',
      titlePrefix: this.titlePrefix.value || '',
      supportMenuTitle: this.supportMenuTitle.value || '',
      supportPageTitle: this.supportPageTitle.value || '',
    };
    /* END-NOSONAR */
  }

  private setFormData(formData: BrandingData) {
    /* BEGIN-NOSONAR */
    /* eslint-disable no-null/no-null, no-restricted-syntax */
    this.form.setValue({
      email: formData.email || null,
      phone: formData.phone || null,
      website: formData.website || null,
      titlePrefix: formData.titlePrefix || null,
      supportMenuTitle: formData.supportMenuTitle || null,
      supportPageTitle: formData.supportPageTitle || null,
    });
    /* eslint-enable no-null/no-null, no-restricted-syntax */
    /* END-NOSONAR */
  }

  onTitlePrefixUpdate(value: string | null | undefined) {
    this.titlePrefixSubject.next(value ?? '');
  }

  submit() {
    if (this.form.valid && this.canEdit) {
      this.tenantSettings.setValue('branding', this.getFormData(), this.realm);
    }
  }
}
