import { type ModuleWithProviders, NgModule } from '@angular/core';
import { BuiltInDashboardProvider } from './services/built-in/builtin-dashboard.provider';

@NgModule({
  imports: [
  ],
  providers: [
  ]
})
export class DashboardsModule {
  static forRoot(): ModuleWithProviders<DashboardsModule> {
    return {
      ngModule: DashboardsModule,
      providers: [
        BuiltInDashboardProvider,
      ],
    }
  }
}
