<mat-accordion>
  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title class="white">{{ columnCategory.name }}</mat-panel-title>

      <mat-panel-description class="white">{{ numberSelected?.value }}/{{ columnCategory.columns.length
        }}</mat-panel-description>
    </mat-expansion-panel-header>

    <mat-selection-list #list (selectionChange)="onSelectionChanged({ list: list.options, event: $event })">
      <mat-list-option class="white" *ngFor="let column of columnCategory.columns" [value]="column"
        [selected]="column.selected"
        [disabled]="column.selected && isDisabled">
        <!-- sending the whole column as value in case we add filter settings to it later -->
        {{ column | longHeaderName }}
      </mat-list-option>
    </mat-selection-list>
  </mat-expansion-panel>
</mat-accordion>