import { InfrontSDK } from '@infront/sdk';
import type { Instrument } from '../../state-model/window.model';

export interface OrderEntryParams {
  instrument?: Instrument;
  initialPrice?: number;
  initialVolume?: number;
  modifyOrderId?: number;
  modifyPortfolio?: string;
}

export interface OrderEntryWidgetOptions {
  autoFillPrice?: boolean;
  rememberVolume?: boolean;
  confirmation?: boolean;
}

export interface OrderDeleteParams {
  orderId: number;
  portfolio: string;
}

export const NonTradableClassifications = [InfrontSDK.SymbolClassification.Index, InfrontSDK.SymbolClassification.Forex, InfrontSDK.SymbolClassification.Fund];

export const ClassificationMap: Partial<{ [Key in InfrontSDK.SymbolClassification]: string; }> = {
  Stock: 'Equities',
  ETF: 'ETFs',
  Fund: 'Mutual Funds',
  Derivative: 'Derivates',
  Future: 'Futures',
  Bond: 'Bonds',
  Certificate: 'Certificates',
  Unknown: 'Unknown',
};

