import { EnvironmentInjector, Inject, Injectable, Optional, inject } from '@angular/core';
import { LogService } from '@vwd/ngx-logging';
import type { Observable } from 'rxjs';
import { EMPTY } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { chainedInterceptor } from '../internal/utils/interceptors';
import type { WidgetStructureModel, WidgetsHistoryEntry } from '../models';
import type { DashboardRef } from '../state-refs';
import { WidgetLoadHistoryInterceptor, type WidgetLoadHistoryInterceptorFn, WidgetLoadInterceptor, type WidgetLoadInterceptorFn, WidgetSaveInterceptor, type WidgetSaveInterceptorFn } from './widget-data.interceptor';
import { publishOnce } from '../internal/utils/publish-once';

export interface WidgetSaveOptions {
  dashboard: DashboardRef;
  widgets: WidgetStructureModel;
  markAsDraft: boolean;
}

/**
 * Base widget interaction API. This API is used by low-level consumers, such as
 * the {@link DashboardContext} or legacy state management.
 *
 * This API does not provide auto-updating data streams, and all state mutations
 * must be handled by the consumer, before pushing it back to the service.
 *
 * Regular state interaction is intended to be performed via the various
 * state-ref APIs.
 */
@Injectable({ providedIn: 'root' })
export class WidgetDataService {

  private readonly logger = inject(LogService).openLogger('dashboards/services/widgets');
  private readonly injector = inject(EnvironmentInjector);

  constructor(
    @Optional() @Inject(WidgetSaveInterceptor)
    private readonly saveInterceptors: WidgetSaveInterceptorFn[],
    @Optional() @Inject(WidgetLoadInterceptor)
    private readonly loadInterceptors: WidgetLoadInterceptorFn[],
    @Optional() @Inject(WidgetLoadHistoryInterceptor)
    private readonly loadHistoryInterceptors: WidgetLoadHistoryInterceptorFn[],
  ) {
    (window as any).infrontApp ??= {};
    (window as any).infrontApp.widgetData = this;
  }

  /** Returns the widget history checkpoints. */
  public getHistory(dashboard: DashboardRef): Observable<readonly WidgetsHistoryEntry[]> {
    return dashboard.provider.widgets.loadHistory(dashboard.model).pipe(
      map(models => models.map(model => ({
        provider: dashboard.provider.widgets,
        version: model.version,
        model: model,
        dashboard: dashboard,
      }))),
    );
  }

  /** Returns the widgets state (once). */
  public loadByDashboard(dashboard: DashboardRef): Observable<WidgetStructureModel> {
    return chainedInterceptor(
      this.loadInterceptors,
      dashboard => dashboard.provider.widgets.loadByDashboard(dashboard.model),
      this.injector
    )(dashboard);
  }

  /** Returns the widgets state for a specific checkpoint (once). */
  public loadByHistory(entry: WidgetsHistoryEntry): Observable<WidgetStructureModel> {
    return chainedInterceptor(
      this.loadHistoryInterceptors,
      entry => entry.provider.loadByHistory({
        dashboard: entry.dashboard.model,
        historyEntry: entry.model
      }),
      this.injector
    )(entry);
  }

  /** Replaces the stored widgets with a different set. */
  public save(options: WidgetSaveOptions): Observable<void> {
    return chainedInterceptor(
      this.saveInterceptors,
      options => {

        if (!options.dashboard.model.security.canEdit) {
          this.logger.error(`Cannot save widgets for read-only dashboard ${options.dashboard.model.name} (${options.dashboard.model.id}).`);
          return EMPTY;
        }

        return options.dashboard.provider.widgets.save({
          dashboard: options.dashboard.model,
          widgets: fixupStructure(options.widgets),
          markAsDraft: options.markAsDraft,
        })
      },
      this.injector
    )(options).pipe(
      publishOnce({ allowEmpty: true }),
    );
  }

  /** Reverts a draft dashboard to the last checkpoint. */
  public revertDraft(dashboard: DashboardRef): Observable<WidgetStructureModel> {
    if (!dashboard.model.security.canEdit) {
      throw new TypeError(`Cannot save widgets for read-only dashboard ${dashboard.model.name} (${dashboard.model.id}).`);
    }
    return dashboard.provider.widgets.revertDraft(dashboard.model).pipe(
      mergeMap(() => this.loadByDashboard(dashboard)),
      publishOnce(),
    );
  }
}


function fixupStructure(structure: WidgetStructureModel): WidgetStructureModel {
  // TODO: last minute fixups, sort widgets by location
  return structure;
}
