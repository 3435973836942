import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { CustomDatePickerHeaderModule } from '../../shared/custom-date-picker-header/custom-date-picker-header.module';
import { SymbolsGridModule } from '../../wrappers/grid-wrappers/symbols-grid/symbols-grid.module';
import { TradesComponent } from './trades.component';
@NgModule({
  declarations: [TradesComponent],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatDialogModule,
    MatNativeDateModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    CustomDatePickerHeaderModule,
    SymbolsGridModule,
  ],
})
export class TradesModule { }
