import type { StoreService } from '../../../../services/store.service';
import type { ContextMenuClickHandler, ContextMenuItem } from '../../../../shared/models/context-menu.model';
import type { CalendarType, CalendarWindow } from '../../../../state-model/window.model';

export type CalendarTypeContextMenuSubItem = ContextMenuItem<CalendarType, string>;
export type CalendarTypeContextMenuItem = ContextMenuItem<string, string, CalendarTypeContextMenuSubItem>;

export const calendarTypeItem = (window: CalendarWindow, storeService: StoreService): CalendarTypeContextMenuItem => {
  const getClickHandler = (calendarType: CalendarType): ContextMenuClickHandler => (context): void => {
    if (!context.window) {
      return;
    }
    const newWindow = { ...context.window, settings: { ...context.window.settings, calendarType } };
    storeService.updateWindow(context.window, newWindow);
  };

  return {
    id: 'calendarType',
    title: `Calendar type: ${window.settings.calendarType}`,
    icon: 'playlist_add',
    subItems: [
      {
        id: 'country',
        title: 'Country',
        icon: 'flag',
        type: 'radio',
        isSVGIcon: true,
        checked: window.settings.calendarType === 'Country',
        closeOnClick: true,
        onClick: getClickHandler('Country'),
      },
      {
        id: 'watchlist',
        title: 'Watchlist',
        icon: 'visibility',
        type: 'radio',
        isSVGIcon: true,
        checked: window.settings.calendarType === 'Watchlist',
        closeOnClick: true,
        onClick: getClickHandler('Watchlist'),
      },
      {
        id: 'instrument',
        title: 'Instrument',
        icon: 'table_rows',
        type: 'radio',
        isSVGIcon: true,
        checked: window.settings.calendarType === 'Instrument',
        closeOnClick: true,
        onClick: getClickHandler('Instrument'),
      },
      {
        id: 'portfolio',
        title: 'Portfolio',
        icon: 'account_balance',
        type: 'radio',
        isSVGIcon: false,
        checked: window.settings.calendarType === 'Portfolio',
        closeOnClick: true,
        onClick: getClickHandler('Portfolio'),
      },
    ],
  };
};
