import { ChangeDetectionStrategy, Component, inject, Input, type OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LastValueSubject } from '@infront/ngx-dashboards-fx/utils';
import { InfrontUtil } from '@infront/sdk';
import { MFEApp } from '@vwd/microfrontend-core';
import { neutralLocale } from '@vwd/ngx-i18n';
import { ResourceService } from '@vwd/ngx-i18n/translate';
import { LogService } from '@vwd/ngx-logging';
import { filter, map, shareReplay, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import type { SidebarListsStorageData } from '../../services/storage.service';
import { TenantSettingsService } from '../../services/tenant-settings.service';
import { NewAdminItemModalComponent } from '../new-admin-item-modal/new-admin-item-modal.component';

@Component({
  selector: 'wt-sidebar-instrument-lists-panel',
  templateUrl: './sidebar-instrument-lists-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SidebarListEditPanelComponent implements OnDestroy {
  private readonly logger = inject(LogService).openLogger('components/sidebar-list-edit-panel');
  private readonly tenantSettings = inject(TenantSettingsService);
  private readonly realmSubject = new LastValueSubject<string>();
  private readonly app = inject(MFEApp);
  private readonly translations = inject(ResourceService);
  private readonly ngUnsubscribe = new Subject<void>();

  readonly supportedLanguages = new Set(this.app.supportedLanguages.map(neutralLocale));

  readonly languages = ['en', 'de', 'fr', 'it', 'no', 'da', 'fi', 'sv', 'nl']
    // only show entries the application supports
    .filter((code) => this.supportedLanguages.has(code))
    .map((code) => ({ code, label: this.translations.get(`GLOBAL.LANGUAGE_NAME.${code.toUpperCase()}`) as string }));

  @Input()
  get realm(): string { return this.realmSubject.value!; }
  set realm(value: string) {
    if (this.realm !== value) {
      this.canEdit = this.tenantSettings.canEdit(value);
      this.realmSubject.next(value);
    }
  }

  canEdit = false;

  items$ = this.realmSubject.pipe(
    switchMap((realm) => {
      return this.tenantSettings.getValue$('sidebarLists', realm).pipe(
        map((globalLists) => globalLists?.map(cloneSidebarList) ?? []),
        shareReplay(1),
      );
    }),
  );

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnDestroy(): void {
    this.realmSubject.complete();
  }

  updateList(index: number, item: SidebarListsStorageData) {
    this.logger.log(`Update list ${index}`, item);

    if (!item.name) {
      return;
    }

    this.items$.pipe(
      take(1),
    ).subscribe((globalLists) => {
      const newGlobalLists = [...globalLists];
      newGlobalLists[index] = item;
      this.tenantSettings.setValue('sidebarLists', newGlobalLists, this.realm);
    });
  }

  addListModal(event: Event): void {
    this.dialog.open(NewAdminItemModalComponent, {
      width: '260px',
      data: {
        input: '',
        icon: 'add',
        title: 'ADMIN_DIALOG.NEW_LIST_DIALOG.TITLE',
        closeLabel: 'ADMIN_DIALOG.NEW_LIST_DIALOG.CLOSE',
        submitLabel: 'ADMIN_DIALOG.NEW_LIST_DIALOG.SUBMIT',
        hasValidation: false,
      },
    })
      .afterClosed()
      .pipe(
        take(1),
        filter((result) => !!result),
        tap((newItem) => {
          if (newItem) {
            this.addList(newItem as string);
          }
        }),
        takeUntil(this.ngUnsubscribe),
      ).subscribe();
  }

  addList(newItem: string): void {
    this.logger.log('Add list');

    if (!newItem) {
      return;
    }

    this.items$.pipe(
      take(1),
    ).subscribe((globalLists) => {
      const newLocal = {
        id: InfrontUtil.makeUUID(),
        name: { en: newItem },
        symbols: [],
      };
      const newGlobalLists = [...globalLists, newLocal];
      this.tenantSettings.setValue('sidebarLists', newGlobalLists, this.realm);
    });
  }

  removeList(index: number, item: SidebarListsStorageData): void {
    this.logger.log(`Remove list ${index}`, item);

    this.items$.pipe(
      take(1),
    ).subscribe((globalLists) => {
      const newGlobalLists = globalLists.slice();
      newGlobalLists.splice(index, 1);
      this.tenantSettings.setValue('sidebarLists', newGlobalLists, this.realm);
    });
  }

  trackById(index: number, item: SidebarListsStorageData): string {
    return item.id;
  }
}



function cloneSidebarList(list: SidebarListsStorageData): SidebarListsStorageData {
  return ({
    ...list,
    name: typeof list.name === 'string' ? { en: list.name } : { ...list.name },
    symbols: list.symbols?.slice() ?? [],
    columns: list.columns?.slice() ?? [],
  });
}
