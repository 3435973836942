<ng-container *ngIf="data$ | async as data">

  <div class="wt-symbol-header-info-top">
    <ng-container *ngIf="showDetails">
      <div class="wt-symbol-header-info-description" title="{{ data.fullName | alternativeValue }}">
        {{ data.fullName | alternativeValue }}
      </div>
      <div class="wt-symbol-header-info-currency">
        {{ data.currency | alternativeValue }}
      </div>
    </ng-container>

    <wt-symbol-status class="wt-symbol-header-info-symbol-status"
      [instrument]="$any(instrument$ | async)"
      [hideSymbolStatus]="['undefined', 'open']"
      (displayStatus)="onDisplayStatus($event)">
    </wt-symbol-status>

    <wt-symbol-events class="wt-symbol-header-info-symbol-events"
      [instrument]="$any(instrument$ | async)">
    </wt-symbol-events>

    <wt-fund-rating class="mr-1"
      [rating]="data.fundRating"
      [isMorningStarFund]="data.isMorningStarFund">
    </wt-fund-rating>
  </div>

  <div class="wt-symbol-header-info-bottom">
    <ng-container [ngSwitch]="data.symbolClassification" *ngIf="!isCompact">
      <ng-container *ngSwitchCase="SymbolClassification.Forex">
        <wt-country-flag class="mr-1" [country]="data.country1" [size]="32"></wt-country-flag>
        <wt-country-flag class="mr-1" [country]="data.country2" [size]="32"></wt-country-flag>
      </ng-container>
      <wt-country-flag *ngSwitchDefault class="mr-1" [country]="data.country" [size]="32"></wt-country-flag>
    </ng-container>

    <div class="mr-1 wt-symbol-header-info-ticker"
      [ngClass]="{ 'wt-symbol-header-info-ticker--clickable': linkInstrumentDashboard }"
      (click)="linkInstrumentDashboard && onTickerClicked($any($event), data)"
      (keypress)="linkInstrumentDashboard && onTickerClicked($event, data)"
      [attr.tabindex]="linkInstrumentDashboard && '0'">
      {{ data.symbolClassification === SymbolClassification.Fund ? data.iSIN : (data.ticker | alternativeValue) }}
    </div>
  </div>
</ng-container>