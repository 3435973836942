import { ChangeDetectionStrategy, Component } from '@angular/core';
import type { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wt-price-arrow',
  template: `
    <div class="arrow">
      <mat-icon *ngIf="direction === 'down'" class="arrow__icon" fontIcon="arrow_drop_down"></mat-icon>
      <mat-icon *ngIf="direction === 'up'" class="arrow__icon" fontIcon="arrow_drop_up"></mat-icon>
      <mat-icon *ngIf="!direction" class="arrow__icon" fontIcon="arrow_right"></mat-icon>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceArrowComponent {
  direction: 'up' | 'down' | undefined;
  agInit(params: ICellRendererParams): void {
    this.direction = params.data['change'] > 0 ? 'up' : params.data['change'] < 0 ? 'down' : undefined; // NOSONAR nested ternary accepted
  }
}
