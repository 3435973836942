<div *ngIf="vm$ | async as vm; else widgetUndefined"
  class="wt-dashboard-window dashboard-window-type--title"
  [class.wt-dashboard-window-type--none]="headerType === 'none'"
  [class.wt-dashboard-window-type--title-and-menu-only]="PortfolioDashboardWindows.includes(window.name)"
  [class.wt-dashboard-window-type--locked]="dashboard.locked"
  [class.wt-dashboard-window--link-channel]="!['None', 'Unlink'].includes(window.linkChannel)"
  [attr.data-type]="window.name">

  <ng-container *ngIf="GenericHeaders.includes($any(headerType)); else switchHeaderType">
    <div *ngIf="['titleWithBottomBorder', 'title', 'titleAndContextMenu'].includes(headerType)"
      class="widget-title-bar-wrapper">
      <div class="widget-title-bar">
        <wt-title-only-header *ngIf="['titleWithBottomBorder', 'title'].includes(headerType)"
          [window]="window"
          [dashboard]="dashboard"
          [class.widget-title-bar-wrapper-bottomless]="headerType === 'title'"
          [class.wt-draggable]="!dashboard.locked && window.dragEnabled !== false"
          class="1">
        </wt-title-only-header>

        <wt-title-and-context-menu-only-header *ngIf="headerType === 'titleAndContextMenu'"
          [window]="window"
          [widget]="vm.widget"
          [dashboard]="dashboard"
          [class.wt-draggable]="!dashboard.locked && window.dragEnabled !== false"
          class="2">
        </wt-title-and-context-menu-only-header>

        <mat-icon
          *ngIf="window?.dragEnabled !== false && !dashboard?.locked || window.layerIndex === 2"
          class="widget-title-bar__close"
          (mousedown)="$event.stopPropagation()"
          (click)="onDelete()" fontIcon="close"></mat-icon>
      </div>
    </div>
  </ng-container>

  <ng-template #switchHeaderType>
    <ng-container *ngIf="!!vm.widget">
      <!-- TODO: class name widget-title-bar should be renamed to window-title-bar. Also fix proper BEM naming -->
      <div *ngIf="linkChannelHover$ | async as linkChannelHover"
        class="widget-title-bar-wrapper"
        [ngClass]="
          linkChannelHover.linkChannel === window.linkChannel && linkChannelHover.linkChannel !== undefined
            ? 'widget-title-bar--channel-' + (linkChannelHover.linkChannel | linkDisplay)
            : ''
        "
        [class.widget-title-bar-wrapper-bottomless]="PortfolioDashboardWindows.includes(window.name)"
        [ngSwitch]="headerType">
        <wt-instrument-header
          *ngSwitchCase="'instrument'"
          [window]="window | cast"
          [widget]="vm.widget"
          [dashboard]="dashboard"
          [classification]="vm.classification"
          [class.wt-draggable]="!dashboard.locked && window.dragEnabled !== false"
          class="widget-title-bar"></wt-instrument-header>
        <wt-market-header
          *ngSwitchCase="'market'"
          [window]="window | cast"
          [widget]="vm.widget"
          [dashboard]="dashboard"
          [class.wt-draggable]="!dashboard.locked && window.dragEnabled !== false"
          class="widget-title-bar"></wt-market-header>
        <wt-market-overview-header
          *ngSwitchCase="'marketOverview'"
          [window]="window | cast"
          [widget]="vm.widget"
          [dashboard]="dashboard"
          [class.wt-draggable]="!dashboard.locked && window.dragEnabled !== false"
          class="widget-title-bar"></wt-market-overview-header>
        <wt-watchlist-header
          *ngSwitchCase="'watchlist'"
          [window]="window | cast"
          [widget]="vm.widget"
          [dashboard]="dashboard"
          [class.wt-draggable]="!dashboard.locked && window.dragEnabled !== false"
          class="widget-title-bar"></wt-watchlist-header>
        <wt-userlist-header
          *ngSwitchCase="'userlist'"
          [window]="window | cast"
          [widget]="vm.widget | cast"
          [dashboard]="dashboard"
          [class.wt-draggable]="!dashboard.locked && window.dragEnabled !== false"
          class="widget-title-bar"></wt-userlist-header>
        <wt-news-header *ngSwitchCase="'news'" [window]="window | cast" [widget]="vm.widget"
          [dashboard]="dashboard"
          [class.wt-draggable]="!dashboard.locked && window.dragEnabled !== false"
          class="widget-title-bar"></wt-news-header>
        <wt-calendar-header *ngSwitchCase="'calendar'" [window]="window | cast" [widget]="vm.widget"
          [dashboard]="dashboard"
          [class.wt-draggable]="!dashboard.locked && window.dragEnabled !== false"
          class="widget-title-bar"></wt-calendar-header>
        <wt-alert-log-header *ngSwitchCase="'alertLog'" [window]="window | cast"
          [dashboard]="dashboard"
          [class.wt-draggable]="!dashboard.locked && window.dragEnabled !== false"
          class="widget-title-bar"></wt-alert-log-header>
        <wt-chains-header
          *ngSwitchCase="'chains'"
          [window]="window | cast"
          [widget]="vm.widget | cast"
          [dashboard]="dashboard"
          [class.wt-draggable]="!dashboard.locked && window.dragEnabled !== false"
          class="widget-title-bar"></wt-chains-header>

        <div *ngSwitchDefault class="widget-title-bar">
          <wt-title-only-header
            [window]="window"
            [dashboard]="dashboard"
            [class.wt-draggable]="!dashboard.locked && window.dragEnabled !== false"
            class="1">
          </wt-title-only-header>
        </div>

        <div class="widget-title-bar__close"
          *ngIf="window?.dragEnabled !== false && !dashboard?.locked || window.layerIndex === 2">
          <button type="button" class="wt-button wt-button--icon" (click)="onDelete()"
            (mousedown)="$event.stopPropagation()">
            <mat-icon
              fontIcon="close"></mat-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <!-- {{ widget.id }} -->
  <!-- {{ widget.name | categoryName }} -->


  <div class="wt-dashboard-window__placeholder">
    <mat-icon fontIcon="zoom_out_map"></mat-icon>
  </div>

  <div class="wt-dashboard-window__payload"
    [ngClass]="{ 'wt-dashboard-window__payload--with-tabs': vm.tabs.length > 1 && window.dragEnabled !== false}">
    <mat-progress-bar *ngIf="inProgress$ | async" [mode]="'indeterminate'"></mat-progress-bar>

    <mat-tab-group
      class="wt-dashboard-window__tabs"
      *ngIf="window?.dragEnabled !== false && vm.tabs.length > 1"
      (selectedTabChange)="onSelectedTabChange(vm.tabs, $event.index)"
      [selectedIndex]="defaultSelectedIndex"
      animationDuration="0ms">
      <mat-tab *ngFor="let tab of vm.tabs; trackBy: tabsCompareFn">
        <ng-template matTabLabel>{{ "DASHBOARD_WINDOW.WIDGET_LABEL." + (tab | widgetLabel : vm.classification) |
          translate }}</ng-template>
      </mat-tab>
    </mat-tab-group>

    <!-- tab content is moved out of the tab system because mat tab removes content from DOM which is not what we want in case of window lifespan tabs -->
    <ng-container *ngIf="vm.tabs.length > 1">
      <div *ngFor="let tab of vm.tabs; trackBy: tabsCompareFn"
        class="wt-dashboard-window__content"
        [ngClass]="{ 'wt-dashboard-window__content--active': tab === vm.widget.name }">

        <ng-container *ngIf="vm.widget.lifespan !== 'window' && tab === vm.widget.name">
          <!-- [ngClass]="(vm.tabs.length > 1 && window.dragEnabled !== false) ? 'wt-dashboard-window-tab-container' : 'wt-dashboard-window-wrapper'"> -->
          <ng-container *ngIf="vm.component" [wtLazyComp]="vm.component"
            [inputs]="{ widget: vm.widget, window }"
            [providers]="vm.providers"></ng-container>
        </ng-container>

        <ng-container *ngIf="filterWidgetToTab$ | async as filterWidgetToTab">
          <div layout-fill flex *ngIf="isTabViewed(tab, vm.widget, vm.component)"
            [hidden]="tab !== vm.widget.name"
            class="wt-dashboard-window-wrapper">
            <ng-container *ngIf="windowLifespanComponent$ | async as windowLifespanComponent">
              <ng-container [wtLazyComp]="windowLifespanComponent"
                [inputs]="{ widget: filterWidgetToTab.widget, isActive: true, window }"
                [providers]="filterWidgetToTab.providers"></ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="vm.tabs.length < 2">
      <ng-container *ngIf="vm.component" [wtLazyComp]="vm.component"
        [inputs]="{ widget: vm.widget, window }"
        [providers]="vm.providers"></ng-container>
    </ng-container>

    <div class="wt-dashboard-window__instrument-search"
      *ngIf="instrumentSearchableWindowNames.includes(window.name)">
      <wt-search-compact-container
        [window]="window | cast"
        [placeholder]="'DASHBOARD_WINDOW.HEADER.COMPACT_SEARCH.INPUT_PLACEHOLDER' | translate">
      </wt-search-compact-container>
    </div>
  </div>
</div>

<ng-template #widgetUndefined>
  <div class="widget-title-bar-wrapper wt-flex-row undefined-widget-header">
    <div class="wt-flex-column"></div>
    <div class="wt-flex-shrink wt-flex-column wt-flex-collapse"></div>
  </div>
  <!-- todo: switch this for loading indicator or dummy template in production -->
</ng-template>
<!-- lazycomp directive is used to enable templated inputs and outputs in lazy loaded components, syntax differs from regular components input output template definition -->