import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { TradingOrderInfoComponent } from './trading-order-info.component';

@Injectable({
  providedIn: 'root',
})
export class TradingOrderInfoService {

  constructor(private readonly dialog: MatDialog) {
  }

  showOrderInfo(metadata: object | undefined): void {
    this.dialog.open(TradingOrderInfoComponent, {
      width: '340px',
      data: { metadata },
    });
  }

}