import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatIconModule } from '@angular/material/icon';
import { FundRatingComponent } from './fund-rating.component';

@NgModule({
  declarations: [FundRatingComponent],
  imports: [CommonModule, MatListModule, MatIconModule],
  exports: [FundRatingComponent],
})
export class FundRatingModule { }
