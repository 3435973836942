import { APP_INITIALIZER, type Provider, inject } from '@angular/core';
import { type JSONValue, RootPreferencesMap } from '@vwd/microfrontend-core';
import { LogService } from '@vwd/ngx-logging';
import { EMPTY, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { WidgetStructureModel, WidgetUserDataStructureModel } from '../models';
import type { DashboardRef } from '../state-refs';
import { USER_PERSISTABLES_REGISTRY, cleanUserPropertiesForWidgets, mergeUserPropertiesForWidgets, unmergeUserPropertiesForWidgets } from './user-persistable';
import { type WidgetLoadHandler, WidgetLoadInterceptor, type WidgetSaveHandler, WidgetSaveInterceptor } from './widget-data.interceptor';
import type { WidgetSaveOptions } from './widget-data.service';

const MILLIS_PER_DAY = 86400000;
const DAYS_TO_LIVE = 30;

interface PersistenceStorage {
  ts: number;
  data: WidgetUserDataStructureModel;
}

export function provideMfeWidgetUserDataStorage(): Provider[] {
  return [
    { provide: WidgetSaveInterceptor, useValue: saveUserSettingsToMFE, multi: true, },
    { provide: WidgetLoadInterceptor, useValue: loadUserSettingsFromMFE, multi: true, },
    { provide: APP_INITIALIZER, useFactory: () => createCleanupUserSettingsFromMFETask(), multi: true },
    // no history interceptor
  ];
}

function saveUserSettingsToMFE(options: WidgetSaveOptions, next: WidgetSaveHandler): Observable<void> {
  // TODO: We need extra context to know whether we're in edit mode or view mode?
  //       This way we can differentiate between editing a template and viewing a template?
  //       Similar for history compare (again, latest should not use overrides)

  if (options.dashboard.model.security.canEdit) {
    return next(options);
  }

  const registry = inject(USER_PERSISTABLES_REGISTRY);
  const prefs = inject(RootPreferencesMap).user.scope('dashboards:useroverrides:');
  const logger = inject(LogService).openLogger('dashboards/user-persistence/mfe');

  const dataToSave = unmergeUserPropertiesForWidgets(options.widgets, registry, options.dashboard);

  const preferenceKey = fixupKey(options.dashboard.model.id);

  if (dataToSave) {
    logger.info(`Persisting user overrides for dashboard ${options.dashboard.model.id}.`);
    const data: PersistenceStorage = { ts: daysSinceEpoch(), data: dataToSave };
    prefs.set(preferenceKey, data as unknown as JSONValue);
  } else if (prefs.has(preferenceKey)) {
    logger.info(`Deleting user overrides for dashboard ${options.dashboard.model.id}.`);
    prefs.delete(preferenceKey);
  }
  return EMPTY;
}

function loadUserSettingsFromMFE(dashboard: DashboardRef, next: WidgetLoadHandler): Observable<WidgetStructureModel> {
  // TODO: We need extra context to know whether we're in edit mode or view mode?
  //       This way we can differentiate between editing a template and viewing a template?
  //       Similar for history compare (again, latest should not use overrides)

  if (dashboard.model.security.canEdit) {
    return next(dashboard);
  }

  const registry = inject(USER_PERSISTABLES_REGISTRY);
  const prefs = inject(RootPreferencesMap).user.scope('dashboards:useroverrides:');
  const logger = inject(LogService).openLogger('dashboards/user-persistence/mfe');

  const preferenceKey = fixupKey(dashboard.model.id);
  const persistedData = prefs.get(preferenceKey) as PersistenceStorage;
  const dayNumber = daysSinceEpoch();

  if (!persistedData?.data || isNaN(persistedData.ts) || persistedData.ts + DAYS_TO_LIVE < dayNumber) {
    return next(dashboard);
  }

  const dataToMerge = persistedData.data;

  return next(dashboard).pipe(
    map(structure => {
      const cleanedUp = cleanUserPropertiesForWidgets(structure, dataToMerge, registry, dashboard);
      if (cleanedUp) {
        // Persist if cleaned data has changed (e.g., when properties used to
        // be persisted but are no longer), or tag the data with a new last used
        // timestamp (once a day only).
        if (cleanedUp !== dataToMerge || persistedData.ts < dayNumber) {
          logger.info(`Saving cleaned up user overrides for dashboard ${dashboard.model.id}.`);
          const data: PersistenceStorage = { ts: daysSinceEpoch(), data: cleanedUp };
          prefs.set(preferenceKey, data as unknown as JSONValue);
        }
        logger.info(`Merging user data for dashboard ${dashboard.model.id}.`);
        return mergeUserPropertiesForWidgets(structure, cleanedUp, registry, dashboard);
      } else {
        logger.info(`Deleting user overrides for dashboard ${dashboard.model.id}.`);
        prefs.delete(preferenceKey);
      }
      return structure;
    }),
  )
}

export function createCleanupUserSettingsFromMFETask(): () => Promise<void> {
  const prefs = inject(RootPreferencesMap).user.scope('dashboards:useroverrides:');
  const logger = inject(LogService).openLogger('dashboards/user-persistence/mfe');

  return async () => {
    const cutoffDate = daysSinceEpoch();
    for (const [key, val] of Array.from(prefs.entries())) {
      if (isValidKey(key)) {
        const persistedData = val as PersistenceStorage | undefined;
        if (!persistedData?.data || !persistedData.ts || persistedData.ts + DAYS_TO_LIVE < cutoffDate) {
          logger.log(`Deleting ${key} due to bad structure or expiration (${persistedData?.ts}).`);
          await prefs.delete(key);
        }
      }
    }
  }
}

const INVALID_PROPERTY_PATH_CHAR = /[^\w\-:]/;

// HACK: Dashboard IDs can contain invalid characters, which will bite us when
//       we try a delete on the key. With this workaround, we use only safe
//       characters.
function fixupKey(key: string): string {
  return key.replace(INVALID_PROPERTY_PATH_CHAR, escapeChar);

  // We escape unsafe chars as `__u<unicode_hex>`.
  // So, a space char becomes `__u0020`.
  function escapeChar(char: string): string {
    const hex = char.charCodeAt(0).toString(16);
    switch (hex.length) {
      case 0: return '__u0000';
      case 1: return '__u000' + hex;
      case 2: return '__u00' + hex;
      case 3: return '__u0' + hex;
      default: return '__u' + hex;
    }
  }
}

function isValidKey(key: string): boolean {
  return !INVALID_PROPERTY_PATH_CHAR.test(key);
}

function daysSinceEpoch(): number {
  return Math.floor(Date.now() / MILLIS_PER_DAY);
}
