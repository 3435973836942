import { ChangeDetectionStrategy, Component, Input, type OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import type { OrderbookWidget, Widget } from '../../state-model/widget.model';
import { OrderbookService } from './orderbook.service';

@Component({
  selector: 'wt-orderbook',
  templateUrl: './orderbook.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderbookComponent implements OnInit {
  @Input({ required: true }) widget!: Widget; // setting instrument

  settings$!: Observable<OrderbookWidget['settings']>;

  constructor(
    private orderbookService: OrderbookService,
  ) { }

  ngOnInit(): void {
    this.settings$ = this.orderbookService.settings$(this.widget);
  }

}
