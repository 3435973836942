import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';
import { ToastrService } from 'ngx-toastr';

import { InfrontSDK } from '@infront/sdk';
import { TradeToastComponent } from '../shared/trade-toast/trade-toast.component';
import { AlertToastComponent } from './../shared/alert-toast/alert-toast.component';
import type { AlertEvent } from './../shared/alerts-list/alerts-list.model';


const defaultToastProps = {
  positionClass: 'toast-bottom-right',
  timeOut: 8000,
  closeButton: false,
  tapToDismiss: false,
  easeTime: 500,
  disableTimeOut: 'extendedTimeOut' as const,
};

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  _config: MatSnackBarConfig;
  _action = 'OK';

  constructor(public snackBar: MatSnackBar, private toastr: ToastrService) {
    this._config = new MatSnackBarConfig();
    this._config.duration = 5000;
  }

  showSuccess(message: string): void {
    this.snackBar.open(message, this._action, this._config);
  }

  showError(message: string): void {
    this.snackBar.open(message, this._action, { ...this._config, panelClass: ['error'], horizontalPosition: 'right', verticalPosition: 'top' });
  }

  alertNofication = (alert?: AlertEvent) => {
    const activeToast = this.toastr.show(undefined, undefined, {
      ...defaultToastProps,
      toastComponent: AlertToastComponent
    });

    activeToast.toastRef.componentInstance.alert = alert;
  };

  tradeNotification(trade: InfrontSDK.Trading.PortfolioItem) {
    const activeToast = this.toastr.show(undefined, undefined, {
      ...defaultToastProps,
      toastComponent: TradeToastComponent,
    });
    activeToast.toastRef.componentInstance.trade = trade;
  }
}
