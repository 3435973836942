import { ChangeDetectorRef, Component, Injector } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MFE, mfe, PreferencesMap } from '@vwd/microfrontend-core';
import { LogService } from '@vwd/ngx-logging';
import { combineLatest } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { AdminDialogComponent } from '../../admin/admin-dialog/admin-dialog.component';
import { NewWindowDialogComponent } from '../../dashboard-window/new-window-dialog/new-window-dialog.component';
import { DashboardService } from '../../dashboard/dashboard.service';
import { NotificationService } from '../../services/notification.service';
import { RemoteStorageService } from '../../services/remote-storage.service';
import { emptyState, StateService } from '../../services/state.service';
import { type SearchStorageData, StorageService, type UserSettingsStorageData } from '../../services/storage.service';
import { StoreService } from '../../services/store.service';
import { TradingOrderEntryService } from '../../services/trading-order-entry.service';
import { WatchlistService } from '../../services/watchlist.service';
import type { ContextMenuItem } from '../../shared/models/context-menu.model';
import { WindowDefaultsMap } from '../../state-model/window.defaults';
import { PortfolioDashboardWindowsWithHeader } from '../../state-model/window.model';
import { UserSettingsService } from './../../services/user-settings.service';
import { type MainContextItems, menuItems$ } from './admin-panel.config';

@Component({
  selector: 'wt-admin-panel',
  templateUrl: './admin-panel.component.html'
})
export class AdminPanelComponent {
  menuItems$ = menuItems$;

  private readonly userSettingsStorage = this.storageService.getUserSettingsStorage() as PreferencesMap<UserSettingsStorageData>;
  private readonly searchStorage = this.storageService.getSearchStorage() as PreferencesMap<SearchStorageData>;
  private readonly logger = this.logService.openLogger('admin-panel');

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly logService: LogService,
    private readonly stateService: StateService,
    private readonly storageService: StorageService,
    private readonly remoteStorageService: RemoteStorageService,
    private readonly dashboardService: DashboardService,
    private readonly userSettingsService: UserSettingsService,
    private readonly watchlistService: WatchlistService,
    private readonly tradingOrderEntryService: TradingOrderEntryService,
    private readonly storeService: StoreService,
    private readonly injector: Injector,
    private readonly mfe: MFE,
    public dialog: MatDialog,
  ) { }

  onItemSelected(item: ContextMenuItem): void {
    switch (item.id as MainContextItems) {
      case 'AddNewWindow': {
        this.addNewWindow();
        return;
      }
      // disabled undo/redo
      // case 'Undo': {
      //   this.undo();
      //   return;
      // }
      // case 'Redo': {
      //   this.redo();
      //   return;
      // }
      case 'TriggerChangeDetection': {
        this.cdRef.detectChanges();
        return;
      }
      case 'SetLocaleEn': {
        void this.mfe.setUserLanguage('en-GB');
        return;
      }
      case 'SetLocaleNo': {
        void this.mfe.setUserLanguage('no-NO');
        return;
      }
      case 'SetLocaleEnNo': {
        void this.mfe.setUserLanguage('en-NO');
        return;
      }
      case 'SetLocaleDe': {
        void this.mfe.setUserLanguage('de-DE');
        return;
      }
      case 'LogUserSettings': {
        this.logCurrentUserSettings();
        return;
      }
      case 'LogSearchStorage': {
        this.logCurrentSearchStorage();
        return;
      }
      case 'LogRemoteState': {
        this.logCurrentRemoteState();
        return;
      }
      case 'LogLocalState': {
        this.logCurrentLocalState();
        return;
      }
      case 'LogRemoteWatchlists': {
        this.logRemoteWatchlists();
        return;
      }
      case 'DeleteUserSettings': {
        this.deleteCurrentUserSettings();
        return;
      }
      case 'DeleteSearchStorage': {
        this.deleteCurrentSearchStorage();
        return;
      }
      case 'DeleteAllUserSettings': {
        this.deleteAllUserSettings();
        return;
      }
      case 'ResetToEmptyState': {
        this.resetToEmptyState();
        return;
      }
      case 'ToggleDashboardFolderEditing': {
        this.toggleDashboardFolderEditing();
        return;
      }
      case 'ShowAdminDialog': {
        this.showAdminDialog();
        return;
      }
      default:
        break;
    }
  }

  addNewWindow(): void {
    this.storeService.closeAllModals();
    this.dialog
      .open(NewWindowDialogComponent, {
        width: '300px',
        // add any window not supposed to be added to regular dashboard in this exception list
        data: Object.keys(WindowDefaultsMap).filter((w) => ![
          ...PortfolioDashboardWindowsWithHeader,
          'InstrumentHeaderWindow',
          'FundScreenerWindow',
          'NotFoundWindow'
        ].includes(w)),
      })
      .afterClosed()
      .subscribe((result) => {
        if (!result) return;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.dashboardService.addWindow(result);
      });
  }

  // undo(): void {
  //   this.stateService.undo();
  // }

  // redo(): void {
  //   this.stateService.redo();
  // }

  logCurrentUserSettings(): void {
    const userSettings = this.userSettingsService.getAllCurrentValues();
    this.logger.info('userSettings', userSettings);
  }

  deleteCurrentUserSettings(): void {
    void this.userSettingsStorage.clear();
  }

  logCurrentSearchStorage(): void {
    const searchStorage = {} as SearchStorageData;
    ['history'].forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
      (searchStorage as any)[key] = this.searchStorage.get(key);
    });
    this.logger.info('searchStorage', searchStorage);
  }

  deleteCurrentSearchStorage(): void {
    void this.searchStorage.clear();
  }

  logCurrentRemoteState(): void {
    combineLatest([
      this.remoteStorageService.getFxDashboards().pipe(map((refs) => refs?.map((d) => d.model))),
      this.remoteStorageService.getDashboards(),
      this.remoteStorageService.getDashboardsData(),
    ])
      .pipe(take(1))
      .subscribe(([fxDashboards, dashboards, data]) => this.log({ fxDashboards, dashboards, data }));
  }

  logCurrentLocalState(): void {
    this.stateService.state$.pipe(take(1)).subscribe((state) => this.log(state));
  }

  logRemoteWatchlists(): void {
    this.watchlistService.watchlists$.subscribe(({ watchlists }) => this.log(watchlists));
  }

  // logCurrentSidebarGridTabStorage(): void {
  //   this.logger.info('SidebarGridTabStorage', this.userSettingsStorage.get('sidebar'));
  // }

  // deleteCurrentSidebarGridTabStorage(): void {
  //   void this.userSettingsStorage.delete('sidebar');
  // }

  deleteAllUserSettings(): void {
    void mfe.preferences?.user?.clear(); // NOSONAR promise must be awaited ...
  }

  resetToEmptyState(): void {
    this.stateService.setState(emptyState, 'User');

    this.remoteStorageService
      .getDashboards()
      .pipe(
        tap((dashboards) => {
          dashboards.map((dashboard) => this.remoteStorageService.deleteDashboard(dashboard, true).pipe(take(1)).subscribe());
        })
      )
      .pipe(take(1))
      .subscribe();
  }

  toggleDashboardFolderEditing(): void {
    const enabled = this.userSettingsService.getValue('enableDashboardFolderEditing');
    this.userSettingsService.setValue('enableDashboardFolderEditing', enabled ? undefined : true);
  }

  showAdminDialog(): void {
    this.dialog.open(AdminDialogComponent, {
      disableClose: false,
      width: '800px',
      height: '600px',
      autoFocus: false,
    });
  }

  private log(entity: unknown, showToast = false): void {
    this.logger.info(entity);
    if (showToast) {
      const notifier = this.injector.get(NotificationService);
      notifier.showSuccess(JSON.stringify(entity));
    }
  }

}
