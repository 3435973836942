import { Injectable, Type, inject } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { Observable, combineLatest, from, of } from 'rxjs';
import { filter, map, share, switchMap } from 'rxjs/operators';

import { LogService } from '@vwd/ngx-logging';
import { SdkRequestsService } from '../services/sdk-requests.service';
import { SdkService } from '../services/sdk.service';
import { StoreService } from '../services/store.service';
import type { Widget } from '../state-model/widget.model';
import {
  type DashboardWindow,
  type InstrumentWindow,
  type MarketWindow,
  WindowNameComponentMap,
  WindowWidgetsMap,
} from '../state-model/window.model';
import { filterUndefined } from '../util/rxjs';
import { NotFoundComponent } from '../widgets/not-found/not-found.component';
import { instrumentWindowTabs, marketWindowTabs } from './tabs-config/';


const EsgAccessFeed = 60001;

export interface TabsInfo {
  tabs: string[];
  widget: Widget;
  component: Type<unknown>;
  classification: InfrontSDK.SymbolClassification;
}

@Injectable({
  providedIn: 'root',
})
export class DashboardWindowService {
  private logger = inject(LogService).openLogger('services/dashboard-window');

  private window$ = (inWindow: DashboardWindow) =>
    this.storeService.window$(inWindow).pipe(
      filter((window) => !!window),
      share()
    );

  private selectedWidget$ = (inWindow: DashboardWindow): Observable<Widget> =>
    this.window$(inWindow).pipe(
      filter((window) => !!window),
      switchMap((window) => {
        const widgetName = !window.selectedWidgetName ? WindowWidgetsMap[window.name][0] : window.selectedWidgetName;
        return this.storeService.widgetByWindowIdAndWidgetName$(window, widgetName);
      })
    );

  private selectedComponent$ = (window: DashboardWindow): Observable<Type<unknown>> =>
    this.selectedWidget$(window).pipe(
      filterUndefined<Widget>(),
      switchMap((widget) => from(this.loadWidgetComponent(widget)).pipe(filterUndefined<Type<unknown>>()))
    );

  private displayTabs$ = (inWindow: DashboardWindow): Observable<string[]> =>
    this.window$(inWindow).pipe(
      switchMap((window) => {
        switch (window.name) {
          case 'InstrumentWindow':
            return combineLatest([this.classification$(inWindow), this.sdkRequestsService.hasFeedAccess$(EsgAccessFeed)]).pipe(map((result) => instrumentWindowTabs(...result)));
          case 'MarketWindow':
            return this.sdkService
              .getObject$(InfrontSDK.feedInfo, { feed: (window as MarketWindow).settings?.feed, infoType: InfrontSDK.FeedInfoType.MetaData })
              .pipe(map((feedInfo) => marketWindowTabs(feedInfo[0])));
          default:
            break;
        }
        return of(WindowWidgetsMap[window.name] ?? WindowWidgetsMap.NotFoundWindow);
      })
    );

  private classification$ = (inWindow: DashboardWindow): Observable<InfrontSDK.SymbolClassification> =>
    this.window$(inWindow).pipe(
      switchMap((window) => {
        if ((window as InstrumentWindow).settings?.instrument) {
          return this.sdkService
            .getObject$<InfrontSDK.SymbolDataOptions>(InfrontSDK.symbolData, {
              id: (window as InstrumentWindow).settings?.instrument,
              content: { Basic: true }
            })
            .pipe(map((symbolData: InfrontSDK.SymbolData) => symbolData.get(InfrontSDK.SymbolField.SymbolClassification)));
        }
        return of(InfrontSDK.SymbolClassification.Unknown);
      })
    );

  tabsInfo$ = (inWindow: DashboardWindow): Observable<TabsInfo> =>
    combineLatest([this.selectedWidget$(inWindow), this.displayTabs$(inWindow), this.classification$(inWindow)]).pipe(
      switchMap(([widget, tabs, classification]) =>
        this.selectedComponent$(inWindow).pipe(map((component) => ({ component, widget, tabs, classification })))
      )
    );



  constructor(private sdkService: SdkService, private storeService: StoreService, private sdkRequestsService: SdkRequestsService) { }

  private async loadWidgetComponent(widget: Widget): Promise<Type<unknown>> {
    if (!widget?.name) {
      throw new Error(`Error loading widget. Name missing`);
    }
    const widgetName = (WindowNameComponentMap[widget.name as keyof typeof WindowNameComponentMap] ?? widget.name) as unknown as string; // typescript can't infer string union types as strings
    const key = `${widgetName.charAt(0).toUpperCase() + widgetName.substring(1)}Component`;
    let kebabCasedComponentName = widgetName.replace(/([A-Z])/g, '-$1').toLowerCase();
    kebabCasedComponentName = kebabCasedComponentName.startsWith('-') ? kebabCasedComponentName.substring(1) : kebabCasedComponentName;

    // dynamic imports of widgets from metadata has to rely on a name and path convention to the source code files, see the console.error message below for the expected format

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const dynamicImport = await this.importSafe(kebabCasedComponentName);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const widgetComponent = dynamicImport?.[key];
    if (!widgetComponent) {
      this.logger.fail(
        `Expected a widget named '${widgetName}' in the widget metadata to have a corresponding component named '${kebabCasedComponentName}' at the path: 'src/app/widgets/${kebabCasedComponentName}'.`
      );
    }
    return widgetComponent as Type<unknown> ?? NotFoundComponent;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async importSafe(kebabCasedComponentName: string): Promise<any> {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return await import(`./../widgets/${kebabCasedComponentName.toLowerCase()}/${kebabCasedComponentName.toLowerCase()}.component`);
    } catch (e) {
      this.logger.fail(`Cannot load widget ${kebabCasedComponentName}.`, e);
      return undefined;
    }
  }
}
