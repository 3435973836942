import { InfrontSDK } from '@infront/sdk';

// Mapping to event-types from SDK results, as defined in transport/calendar/Calendar.ts getEventDisplayLabel and IWT-1590
export enum EventLabel {
  'Ex Div' = 'EX_DIV',
  'Result' = 'RESULT',
  'Event' = 'EVENT',
  'Ex Div 1D' = 'EX_DIV_1D',
  'Result 1D' = 'RESULT_1D',
  'Event 1D' = 'EVENT_1D',
}
// EventLabelType = 'EX_DIV' | 'RESULT' | 'EVENT' | 'EX_DIV_1D' | 'RESULT_1D' | 'EVENT_1D';
export type EventLabelType = `${EventLabel}`;

// Priority for displaying only one Label in case multiple events occur, as defined in IWT-1590
export const EventLabelPriority: EventLabelType[] = ['EX_DIV', 'RESULT', 'EVENT', 'EX_DIV_1D', 'RESULT_1D', 'EVENT_1D'];

export const fields = [
  InfrontSDK.SymbolField.Ticker, // required, else no data retrieved with only Calendar* fields!
  InfrontSDK.SymbolField.CalendarEventDetails,
  InfrontSDK.SymbolField.CalendarEventDescription,
  InfrontSDK.SymbolField.CalendarEventDisplayLabel,
];
