<div class="wt-news">
  <div class="wt-flex-row wt-flex-align-middle">
    <div class="wt-flex-column wt-flex-shrink">
      <input
        type="text"
        class="wt-news__form-field"
        [ngModel]="textFilter$ | async"
        (ngModelChange)="onChangeTextFilter($event)"
        #textFilterInput
        placeholder="{{ 'GLOBAL.SEARCH' | translate }}" />
    </div>

    <div class="wt-flex-column wt-flex-shrink">
      <section class="wt-news__filter-boxes">
        <mat-checkbox class="wt-news__filter-boxes__checkbox"
          (change)="onShowNews($event)"
          [checked]="showNews$ | async">News</mat-checkbox>
        <mat-checkbox class="wt-news__filter-boxes__checkbox"
          (change)="onShowFlashNews($event)"
          [checked]="showFlashNews$ | async">Flash News</mat-checkbox>
        <mat-checkbox class="wt-news__filter-boxes__checkbox"
          (change)="onShowResearchNews($event)"
          [checked]="showResearchNews$ | async">Research</mat-checkbox>
      </section>
    </div>
  </div>

  <div *ngIf="vm$ | async as vm" class="wt-flex-row wt-news__content">
    <div *ngIf="{ isConnected: tradingConnected$ | async } as tradingConnectedObj"
      class="wt-flex-column wt-flex-collapse"
      [ngSwitch]="vm.newsType">
      <ng-container *ngIf="untranslatedNoRowsTemplate$ | async as overlayNoRowsTemplateOpts">
        <!-- NOTE we need to keep those four grids separate, else the stored settings get confused! -->
        <wt-static-columns-grid
          *ngSwitchCase="'Instrument'"
          [flushTransactions]="false"
          [data]="vm.headlines"
          [columns]="vm.columns"
          [gridSettings]="{}"
          [gridOptions]="gridOptions"
          [context]="{ widget: widget$ | async }"
          [translatedNoRowsTemplate]="{
            translatedContent: (overlayNoRowsTemplateOpts.content | translate),
            element: overlayNoRowsTemplateOpts.element
          }"
          (gridApi)="onGridApi($event)"
          (rowClickedEvent)="openNewsStory($event.data)"
          (showMore)="onShowMore()"></wt-static-columns-grid>
        <wt-static-columns-grid
          *ngSwitchCase="'Watchlist'"
          [flushTransactions]="false"
          [data]="vm.headlines"
          [columns]="vm.columns"
          [gridSettings]="{}"
          [gridOptions]="gridOptions"
          [context]="{ widget: widget$ | async }"
          [translatedNoRowsTemplate]="{
            translatedContent: (overlayNoRowsTemplateOpts.content | translate),
            element: overlayNoRowsTemplateOpts.element
          }"
          (gridApi)="onGridApi($event)"
          (rowClickedEvent)="openNewsStory($event.data)"
          (showMore)="onShowMore()"></wt-static-columns-grid>
        <wt-static-columns-grid
          *ngSwitchCase="'Country'"
          [flushTransactions]="false"
          [data]="vm.headlines"
          [columns]="vm.columns"
          [gridSettings]="{}"
          [gridOptions]="gridOptions"
          [context]="{ widget: widget$ | async }"
          [translatedNoRowsTemplate]="{
            translatedContent: (overlayNoRowsTemplateOpts.content | translate),
            element: overlayNoRowsTemplateOpts.element
          }"
          (gridApi)="onGridApi($event)"
          (rowClickedEvent)="openNewsStory($event.data)"
          (showMore)="onShowMore()"></wt-static-columns-grid>
        <ng-container *ngSwitchCase="'Portfolio'">
          <wt-trading-login *ngIf="tradingConnectedObj.isConnected !== true"
            class="wt-flex wt-full-height wt-flex-align-middle"></wt-trading-login>
          <wt-static-columns-grid
            *ngIf="tradingConnectedObj.isConnected"
            [flushTransactions]="false"
            [data]="vm.headlines"
            [columns]="vm.columns"
            [gridSettings]="{}"
            [gridOptions]="gridOptions"
            [context]="{ widget: widget$ | async }"
            [translatedNoRowsTemplate]="{
              translatedContent: (overlayNoRowsTemplateOpts.content | translate),
              element: overlayNoRowsTemplateOpts.element
            }"
            (gridApi)="onGridApi($event)"
            (rowClickedEvent)="openNewsStory($event.data)"
            (showMore)="onShowMore()"></wt-static-columns-grid>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
