import type { MarketOverviewTemplate } from '../market-overview.model';

export const TemplateSwitzerland: MarketOverviewTemplate[] = [
  {
    tab: 'Indices',
    instrumentList: [
      { feed: 2096, ticker: 'SMI' },
      { feed: 2096, ticker: 'SLI' },
      { feed: 2096, ticker: 'SPIX' },
      { feed: 2096, ticker: 'SMIM' },
      { feed: 2096, ticker: 'SLIFE' },
      { feed: 2096, ticker: 'SBR14T' },
      { feed: 2088, ticker: 'SPE350' },
      { feed: 2098, ticker: 'DAX' },
      { feed: 2302, ticker: 'BUK100P' },
      { feed: 2089, ticker: 'PX1' },
      { feed: 2161, ticker: 'SX5E' },
      { feed: 20, ticker: 'DJI' },
      { feed: 2087, ticker: 'COMP' },
      { feed: 2087, ticker: 'NDX' },
      { feed: 2088, ticker: 'SP500' },
      { feed: 21, ticker: 'NQFUT1' },
      { feed: 21, ticker: 'ESFUT1' },
      { feed: 6330, ticker: 'VIX' },
      { feed: 2089, ticker: 'N100' },
      { feed: 6150, ticker: 'N225' },
      { feed: 2198, ticker: 'HSI' },
      { feed: 2088, ticker: 'SPASIA50' },
    ],
  },
  {
    tab: 'Forex',
    instrumentList: [
      { feed: 12, ticker: 'EURCHF' },
      { feed: 12, ticker: 'USDCHF' },
      { feed: 12, ticker: 'GBPCHF' },
      { feed: 12, ticker: 'CHFJPY' },
      { feed: 12, ticker: 'CHFAUD' },
      { feed: 12, ticker: 'CHFBRL' },
      { feed: 12, ticker: 'CHFHUF' },
      { feed: 12, ticker: 'EURUSD' },
      { feed: 12, ticker: 'GBPUSD' },
    ],
  },
  {
    tab: 'FixedIncome',
    instrumentList: [
      { feed: 2096, ticker: 'SBIGY7' },
      { feed: 2096, ticker: 'SG10Y' },
      { feed: 2227, ticker: 'TMBMKDE-30Y' },
      { feed: 2158, ticker: 'BMDE-10Y' },
      { feed: 2158, ticker: 'BMDE-30Y' },
      { feed: 2158, ticker: 'BMUS-10Y' },
      { feed: 2158, ticker: 'BMUS-30Y' },
      { feed: 2158, ticker: 'BMGB-10Y' },
    ],
  },
  {
    tab: 'Commodities',
    instrumentList: [
      { feed: 2229, ticker: 'BRT' },
      { feed: 2229, ticker: 'WTI' },
      { feed: 2148, ticker: 'AH3M' },
      { feed: 2148, ticker: 'CA3M' },
      { feed: 2148, ticker: 'ZS3M' },
      { feed: 2159, ticker: 'SLVSPOT' },
      { feed: 2159, ticker: 'GLDSPOT' },
    ],
  }
];
