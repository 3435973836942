import { Injectable } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SdkService } from '../../../services/sdk.service';
import type { MarketDataType } from '../../../typings/models/marketData';

import { StoreService } from '../../../services/store.service';
import { FilterMap, type MarketOverviewDropdownType } from './market-overview-dropdown.model';

@Injectable({
  providedIn: 'root',
})
export class MarketOverviewDropdownService {

  private stateAction = new BehaviorSubject<'open' | 'close'>('close');
  state$ = this.stateAction.asObservable();

  setMarketOverviewState(newState: 'open' | 'close', keepOtherModals = false): void {
    if (this.stateAction.value === newState) {
      return;
    }
    if (newState === 'open') {
      if (!keepOtherModals) {
        this.storeService.closeAllModals(); // there's no notification for angular materials "closeAll" and no way to make an exception, so on "open" we have to make sure any closing is done in good time before opening the "market selector", since the market selector is an angular material modal included in the closeAllModals
      }
      setTimeout(() => {
        this.stateAction.next(newState);
      }, 200);
    } else {
      this.stateAction.next(newState);
    }
  }

  constructor(private sdkService: SdkService, private storeService: StoreService) { }

  marketData$ = (type: MarketOverviewDropdownType): Observable<Array<MarketDataType>> =>
    this.sdkService.getArray$(InfrontSDK.feedList, {
      serviceTypes: { Markets: true, News: true },
      feedInfo: true,
    } as InfrontSDK.FeedListOptions).pipe(
      switchMap((feeds: InfrontSDK.FeedInfo[]) => of(FilterMap(feeds)[type])),
      map((data) => {
        const reduceMarketsToCountryName = data?.reduce((reducer: MarketDataType[], items: InfrontSDK.FeedInfo, index: number) => {
          if (items.access?.toLowerCase() !== 'no access') {
            reducer.push(this.createNewMarketInfoObject(index, items));
          }
          return reducer;
        }, []);
        return reduceMarketsToCountryName;
      })
    );

  private createNewMarketInfoObject(index: number, items: InfrontSDK.FeedInfo): MarketDataType {
    return {
      id: index,
      countryName: items.countryName,
      source: items.feedCode,
      access: items.access,
      description: items.description,
      trading: items.hasTrading ? 'Yes' : 'No',
      dataTypes: items.dataTypes,
      feed: items.feed,
      descriptionMarkHtml: undefined,
      sourceMarkHtml: undefined,
    };
  }
}
