export type ValueOf<T> = T[keyof T];

export type CommonKeys<T extends object> = keyof T; // Creates a union string of all shared union properties

export type AllKeys<T> = T extends T ? keyof T : never; // Creates a string union of all union properties

export const isDefined = <T>(val: T | undefined | null): val is T => {
  return val != undefined;
};

export const filterUndefined = <T>(arr: (T | undefined)[]): T[] => {
  return arr.filter((item) => !!item) as T[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const castTo = <T>(entity: any): T => {
  return entity as T;
};

export type RequiredKeys<T> = { [K in keyof T as (undefined extends T[K] ? never : K)]: T[K]; };

export type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

export type MakeKeysRequired<Type extends object, KeysToMakeReq extends keyof Type> = Type & { [Key in KeysToMakeReq]-?: Type[Key]; };

export type ExclusivePick<T, K extends keyof T> = { [P in K]: T[P] } & { [P in Exclude<keyof T, K>]?: never };

export type ItemOrItemArray<T> = T | T[];

export type TupleFromObject<T> = T extends { [K in keyof T]: infer V; }
  ? V extends unknown[]
  ? V
  : never
  : never;

export function tupleFromObject<T>(obj: T): TupleFromObject<T> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
  const values = Object.values(obj as any);
  if (values.every((value) => Array.isArray(value))) {
    return values as TupleFromObject<T>;
  }
  throw new Error('Object values are not of the same type.');
}

/**
 * Typed constructor of classes.
 * @param Instance represents the constructor ReturnType or also known as the class instance
 * @param ConstructorParams are the constructor arguments/params,
 * just like any function params are just an array, with the addition of having them typed.
 *
 * Can be used to restrict allowed classes in an interface or api.
 * E.g. all WTK widgets share the common constructor of
 * constructor(targetElement: HTMLElement, ui: Infront.UI, options: WTKWidgetOptions) => WTKWidget
 */
export type Constructor<Instance extends object, ConstructorParams extends Array<unknown>> = new (...args: ConstructorParams) => Instance;

/**
 * Equivalent to 'typeof ArrayType[number]'
 */
export type UnionTypeFromArray<A extends Array<unknown>> = A[number];

/**
 * Takes an object with properties and separates each property in union typed objects that contain only each single property
 *
 * Example
 * Input:
 * type Foo = { window: string; widgetId: string; widget: Widget; };
 * type UnionFoo = UnionTypeFromObjectProperties<Foo>;
 * Output:
 * UnionFoo = { window: string; } | { widgetId: string; } | { widget: Widget; };
 */
export type UnionTypeFromObjectProperties<Obj extends object> = { [Key in keyof Obj]: { [Key2 in Key]: Obj[Key2]; } }[keyof Obj];

export type TypedGetFunctionReturnType<BaseReturnType, TypeGuardReturnType extends BaseReturnType | undefined = undefined> = (
  TypeGuardReturnType extends BaseReturnType ? TypeGuardReturnType : BaseReturnType
) | undefined;

export type TypedGetFunctionParameter<
  GetParameters extends object,
  BaseReturnType,
  TypeGuardReturnType extends BaseReturnType | undefined = undefined,
> = UnionTypeFromObjectProperties<GetParameters>
  & (TypeGuardReturnType extends BaseReturnType ? { typeGuard: (value: BaseReturnType) => value is TypeGuardReturnType } : object);

export type TypedGetFunction<
  GetParameters extends object,
  BaseReturnType,
  TypeGuardReturnType extends BaseReturnType | undefined = undefined,
> = (
  getParameter: TypedGetFunctionParameter<GetParameters, BaseReturnType, TypeGuardReturnType>,
  ...args: unknown[]
) => (TypeGuardReturnType extends BaseReturnType ? TypeGuardReturnType : BaseReturnType) | undefined;

/**
 * Removes all `readonly` specifiers from {@link T}.
 * The reverse of {@link Readonly<T>}.
 */
export type Writable<T extends object> = {
  -readonly [P in keyof T]: T[P];
};

/**
 * Checks whether or not a provided string type is a string literal (true/false)
 *
 * A string literal is any subset of string, but not the whole set of string itself.
 * valid string literals:
 * - 'hello'
 * - 'another string literal'
 * - '' (empty string)
 *
 * invalid:
 * - string (the whole type),
 * - any other non string type
 */
export type isStringLiteral<T extends string> = string extends T ? false : true;
/**
 * Checks whether or not a provided string type is an empty string e.g. ''
 *
 * CAUTION: If the type string is directly provided it will return TRUE.
 * isEmptyString<string> => true
 * Use alongside isStringLiteral if you want to prevent this potential mistake.
 */
export type isEmptyString<T extends string> = T extends '' ? true : false;

/**
 * Ensures that both the object {@link T} keys and it's object values property {@link ProjectedProp} share the same value.
 * In case of differing object key and property value, never will be expected.
 */
export type ObjectKeysAsValueProperty<T extends object = object, ProjectedProperty extends string = string> = (
  isStringLiteral<ProjectedProperty> extends false // Prevent non string literal => never
  ? never
  : isEmptyString<ProjectedProperty> extends true // Prevent empty string as ProjectedProperty => never
  ? never
  : {
    [objKey in keyof T]: {
      [pKey in ProjectedProperty]: objKey; // The ProjectedProperty needs to have the same value as objKey
    }
  } & {
    [objKey in keyof T]: {
      [props: string]: unknown; // Workaround to allow further optional properties
    }
  }
);

/**
 * Converts a union type of objects/interfaces to a single object (intersection)
 */
export type UnionObjectsToIntersection<T> = {
  [Key in AllKeys<T>]: Extract<T, { [K in Key]: unknown }>[Key]
};

export type UnionToIntersection<U> = (
  U extends unknown ? (arg: U) => 0 : never
) extends (arg: infer I) => 0
  ? I
  : never;

export type LastInUnion<U> = UnionToIntersection<
  U extends unknown ? (x: U) => 0 : never
> extends (x: infer L) => 0
  ? L
  : never;

// UnionToTuple<A, B> = [A, B]
export type UnionToTuple<T, Last = LastInUnion<T>> = [T] extends [never]
  ? []
  : [Last, ...UnionToTuple<Exclude<T, Last>>];


/**
 * The object to pick from.
 * The type to look for.
 *
 * Returns a new object with the properties of the looked up type.
 */
export type PickByType<T extends object, U> = { [P in keyof T as T[P] extends never ? never : U extends T[P] ? P : never]: T[P] };

/**
 * Transforms an object to a union type (of all object properties)
 * Each property of the object is projected into a new, single type
 */
export type ObjectToUnion<T extends object> = {
  [K in keyof T]: {
    [K2 in K]: T[K]
  }
}[keyof T];

export function isPromiseLike<T>(obj: unknown): obj is PromiseLike<T> {
  return typeof obj === 'object' && typeof (obj as PromiseLike<T>)?.['then'] === 'function';
}

export type PromisedValueOf<O> = O extends PromiseLike<infer T> ? T : never;
