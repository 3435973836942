import type { MarketOverviewTemplate } from '../market-overview.model';

export const TemplateMajorEquityIndices: MarketOverviewTemplate[] = [
  {
    // tab: 'World',
    tab: '',
    templateName: 'MajorEquityIndices',
    instrumentList: [
      { feed: 6880, ticker: '990100P' },
      { feed: 20, ticker: 'W1DOW' },
      { feed: 2161, ticker: '0X7Y' },
  //   ],
  // },
  // {
  //   tab: 'Americas',
  //   instrumentList: [
      { feed: 20, ticker: 'DJI' },
      { feed: 2088, ticker: 'SP500' },
      { feed: 6330, ticker: 'VIX' },
      { feed: 2087, ticker: 'COMP' },
      { feed: 2087, ticker: 'NDX' },
      { feed: 2086, ticker: 'NYA' },
      { feed: 2225, ticker: '0000' },
      // TODO: Brazil Bovespa
  //   ],
  // },
  // {
  //   tab: 'Europe',
  //   instrumentList: [
      { feed: 2161, ticker: 'SX5P' },
      { feed: 2161, ticker: 'SX5E' },
      { feed: 2302, ticker: 'BEZ50N' },
      { feed: 2274, ticker: 'SV2TX' },
      { feed: 2018, ticker: 'UKX' },
      { feed: 2098, ticker: 'DAX' },
      { feed: 2097, ticker: 'FTSEMIB' },
      { feed: 2089, ticker: 'PX1' },
      { feed: 2162, ticker: 'RTSI' },
      { feed: 17921, ticker: 'OMXS30' },
      { feed: 100, ticker: 'OMXH25' },
      { feed: 2089, ticker: 'AEX' },
      { feed: 2089, ticker: 'BEL20' },
      { feed: 2089, ticker: 'PSI20' },
      { feed: 2096, ticker: 'SMI' },
      { feed: 18177, ticker: 'OBX' },
      { feed: 17665, ticker: 'OMXC25' },
  //   ],
  // },
  // {
  //   tab: 'AsiaAfrica',
  //   instrumentList: [
      { feed: 6150, ticker: 'N225' },
      { feed: 2198, ticker: 'HSI' },
      { feed: 6720, ticker: 'KO_COMP' },
      { feed: 6720, ticker: 'XAT' },
      { feed: 6700, ticker: '000300' },
      { feed: 6460, ticker: 'J2OU' },
      { feed: 6640, ticker: 'SENSEX' },
    ],
  },
];
