import { type Observer, type PartialObserver, Subject, type Subscription } from 'rxjs';

/**
 * LastValueSubject is like a BehaviorSubject that does not have an initial
 * value. Or a ReplaySubject with a count of 1.
 *
 * It basically ensures that when you subscribe, and a value is available,
 * you'll get an update of the last known value. Otherwise, you'll get the
 * next value later.
 */
export class LastValueSubject<T> extends Subject<T> {
  private _value: T | undefined;
  private _valueSet = false;
  private _thrownError?: { error: any };

  constructor() {
    super();
  }

  public get value(): T | undefined {
    return this.getValue();
  }

  public getValue(): T | undefined {
    if (this.closed) {
      throw new Error('LastValueSubject is closed.');
    }
    if (this._thrownError) {
      throw this._thrownError.error;
    }

    return this._value;
  }

  override subscribe(observer?: PartialObserver<T>): Subscription;
  /** @deprecated Use an observer instead of a complete callback */
  override subscribe(next: null | undefined, error: null | undefined, complete: () => void): Subscription;
  /** @deprecated Use an observer instead of an error callback */
  override subscribe(next: null | undefined, error: (error: any) => void, complete?: () => void): Subscription;
  /** @deprecated Use an observer instead of a complete callback */
  override subscribe(next: (value: T) => void, error: null | undefined, complete: () => void): Subscription;
  override subscribe(next?: (value: T) => void, error?: (error: any) => void, complete?: () => void): Subscription;
  override subscribe(observerOrNext?: Partial<Observer<T>> | ((value: T) => void) | null | undefined): Subscription {
    // `_subscribe` is internal, but it's the only way to actually
    // override subscription behavior
    // eslint-disable-next-line prefer-rest-params
    const subscription = super.subscribe(...arguments);
    if (!subscription.closed && this._valueSet) {
      if (typeof observerOrNext === 'function') {
        observerOrNext(this._value!);
      } else {
        observerOrNext?.next?.(this._value!);
      }
    }
    return subscription;
  }

  override next(value: T): void {
    this._valueSet = true;
    this._value = value;
    super.next(value);
  }

  override error(error: any): void {
    this._thrownError = { error };
    super.error(error);
  }
}
