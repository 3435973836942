import type { InfrontSDK } from '@infront/sdk';
import { noop } from 'rxjs';

export const marketWindowTabs = (feedInfo: InfrontSDK.FeedInfo): string[] => {
  if (!feedInfo) {
    return [];
  }
  const marketTabs = ['Lists', 'Ranking'];
  const pushIfConditionMet = (cond: boolean, tab: string) => (cond ? marketTabs.push(tab) : noop);
  pushIfConditionMet(
    feedInfo.dataTypes?.some((dataType) => ['usoptions', 'eurooption'].includes(dataType.toLowerCase())) ?? false,
    'Put Call'
  );
  // todo: need more info regarding dynamic tabs in market window, keep comented code for now
  //pushIfConditionMet(feedInfo.feedProperties.includes('Chains'), 'Chains');
  //pushIfConditionMet(feedInfo.fullFeed, 'All Quotes');
  //pushIfConditionMet(feedInfo.dataTypes?.includes('Indices'), 'Indices');
  pushIfConditionMet(feedInfo.dataTypes?.includes('STOCKS'), 'BiggestMovers');

  return marketTabs;
};
