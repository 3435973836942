import type { UntranslatedNoRowsTemplate } from '../../shared/grid/grid.model';
import type { SymbolWithTradingSymbol } from '../../shared/models/symbol-data.model';
import type { WidgetGridsMap } from '../../state-model/grid.model';
import type { PortfolioPositionsWidget } from '../../state-model/widget.model';
import { deepFreeze } from '../../util/object';

export const RepresentedClassifications = ['Stock', 'ETF', 'Fund', 'Future', 'Derivative', 'Bond', 'Certificate'] as const;
export const TradingClassifications = [...RepresentedClassifications, 'Other', 'Unmapped'] as const;

export type TradingClassification = typeof TradingClassifications[number];

export type PortfolioGridType = typeof WidgetGridsMap['portfolioPositionsGrid'][number];

export const PortfolioPositionsTabs = ['Default', 'Performance'] as const;

export const overlayNoRowsTemplateOptions = deepFreeze({
  noData: { content: 'PORTFOLIO_POSITIONS_WIDGET.GRID.NO_DATA' },
  noPositions: { content: 'PORTFOLIO_POSITIONS_WIDGET.GRID.NO_POSITIONS' },
} as const satisfies { [name: string]: UntranslatedNoRowsTemplate; });

export interface Vm {
  symbols: SymbolWithTradingSymbol[]; // todo: type
  selectedGrid: PortfolioGridType;
  baseCurrency: string;
  widget: PortfolioPositionsWidget;
}


export interface PortfolioPositionsTab {
  title: string;
  grid: PortfolioGridType;
}
