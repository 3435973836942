import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { GridsterModule } from 'angular-gridster2';

import { DashboardWindowModule } from '../dashboard-window/dashboard-window.module';
import { NewWindowDialogComponent } from '../dashboard-window/new-window-dialog/new-window-dialog.component';
import { ContextMenuModule } from '../dashboard-window/ui/context-menu/context-menu.module';
import { DirectivesModule } from '../directives/directives.module';
import { TempSupressUnusedImportsWarningsModule } from '../widgets/tempSupressUnusedImportsWarnings.module';
import { DashboardContextMenuComponent } from './dashboard-context-menu/dashboard-context-menu.component';
import { DashboardRoutingModule } from './dashboard-routing.module';

import { DashboardTabsComponent } from './dashboard-tabs/dashboard-tabs.component';
import { DashboardComponent } from './dashboard.component';
import { NewDashboardContextMenuComponent } from './new-dashboard-context-menu/new-dashboard-context-menu.component';
import { NewDashboardDialogComponent } from './new-dashboard-dialog/new-dashboard-dialog.component';
import { NewDashboardFolderDialogComponent } from './new-dashboard-folder-dialog/new-dashboard-folder-dialog.component';
import { OtherDashboardsContextMenuComponent } from './other-dashboards-context-menu/other-dashboards-context-menu.component';
import { RenameDashboardDialogComponent } from './rename-dashboard-dialog/rename-dashboard-dialog.component';
import { SidebarModule } from './sidebar/sidebar.module';


@NgModule({
  imports: [
    CommonModule,
    TempSupressUnusedImportsWarningsModule,
    DashboardRoutingModule,
    GridsterModule,
    DashboardWindowModule,
    DragDropModule,
    MatIconModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    ContextMenuModule,
    DirectivesModule,
    I18nPipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
    SidebarModule,
  ],
  declarations: [
    DashboardComponent,
    DashboardTabsComponent,
    NewDashboardContextMenuComponent,
    OtherDashboardsContextMenuComponent,
    NewDashboardDialogComponent,
    NewDashboardFolderDialogComponent,
    RenameDashboardDialogComponent,
    NewWindowDialogComponent,
    DashboardContextMenuComponent,
  ],
})
export class DashboardModule { }
