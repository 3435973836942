import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { AgGridModule } from 'ag-grid-angular';
import { ContextMenuModule } from '../../dashboard-window/ui/context-menu/context-menu.module';
import { DirectivesModule } from '../../directives/directives.module';
import { StaticColumnsGridModule } from '../../wrappers/grid-wrappers/static-columns-grid/static-columns-grid.module';
import { SymbolsGridModule } from '../../wrappers/grid-wrappers/symbols-grid/symbols-grid.module';
import { AutoCompleteModule } from '../auto-complete/auto-complete.module';
import { ComboBoxModule } from '../combo-box/combo-box.module';
import { FundScreenerInputsComponent } from './fund-screener-inputs.component';
import { FundScreenerResultComponent } from './fund-screener-result.component';
import { FundScreenerComponent } from './fund-screener.component';
import { ScreenerDialogComponent } from './screener-dialog.component';
import { ScreenerComponent } from './screener.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatTabsModule,
    MatOptionModule,
    MatSelectModule,
    AgGridModule,
    I18nPipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en'],
    }),
    SymbolsGridModule,
    AutoCompleteModule,
    ComboBoxModule,
    DirectivesModule,
    StaticColumnsGridModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatButtonModule,
    MatMenuModule,
    ContextMenuModule,
    MatTooltipModule,
  ],
  declarations: [
    ScreenerComponent,
    ScreenerDialogComponent,
    FundScreenerComponent,
    FundScreenerInputsComponent,
    FundScreenerResultComponent
  ],
  exports: [
  ]
})
export class ScreenerModule { }
