import { type EnvironmentInjector, runInInjectionContext } from '@angular/core';

export type InterceptorFn<TRequest, TResult> = (req: TRequest, next: FinalInterceptorFn<TRequest, TResult>) => TResult;
export type FinalInterceptorFn<TRequest, TResult> = (req: TRequest) => TResult;

/**
 * Chains the specified functional interceptors and intercepted callback, and
 * returns a single function that will call the interceptors in reverse order.
 *
 * The interceptors need to have a signature `(arg, next) => T` where the
 * interceptor should call `next` to move through the chain. An interceptor can
 * also decide not to continue the chain and terminate it by not calling `next`
 * and returning an appropriate result type. There will be no `this`.
 *
 * The interceptors can call the `inject(Service)` method to get dependencies,
 * but only in non-async methods, and non-async callbacks. So do not call this
 * method in rxjs operators or promise callbacks, but initialize services
 * before.
 */
export function chainedInterceptor<TRequest, TResult>(
  interceptors: ReadonlyArray<InterceptorFn<TRequest, TResult>>,
  finalFn: FinalInterceptorFn<TRequest, TResult>,
  injector: EnvironmentInjector
): FinalInterceptorFn<TRequest, TResult> {
  if (!interceptors?.length) {
    return finalFn;
  }

  return interceptors.reduceRight<FinalInterceptorFn<TRequest, TResult>>(
    (next, interceptorFn) => req => runInInjectionContext(injector, () => interceptorFn(req, next)),
    finalFn
  );
}

/**
 * Sa,e as {@link chainedInterceptor}, but also calls it immediately
 * with `req`.
 */
export function applyInterceptors<TRequest, TResult>(
  req: TRequest,
  interceptors: ReadonlyArray<InterceptorFn<TRequest, TResult>>,
  finalFn: FinalInterceptorFn<TRequest, TResult>,
  injector: EnvironmentInjector
): TResult {
  return chainedInterceptor(interceptors, finalFn, injector)(req);
}
