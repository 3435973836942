import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';

import { PipesModule } from '../../pipes/pipes.module';
import { MultiSelectExpansionComponent } from './multi-select-expansion.component';

@NgModule({
  declarations: [MultiSelectExpansionComponent],
  imports: [CommonModule, MatExpansionModule, MatListModule, PipesModule],
  exports: [MultiSelectExpansionComponent],
})
export class MultiSelectExpansionModule {}
