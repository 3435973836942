<div class="wt-mat-dropdown" [ngClass]="menuClass">
  <div class="wt-mat-dropdown__wrapper" [matMenuTriggerFor]="afterMenu"
    (menuOpened)="onMenuOpened(itemFilterInputElement)" (menuClosed)="onMenuClosed()">
    <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle">
      <div class="wt-flex-column wt-flex-shrink">
        <mat-icon class="wt-dropdown-header-icon" [svgIcon]="matIcon" *ngIf="isSvgIcon"></mat-icon>
        <mat-icon class="wt-dropdown-header-icon" [fontIcon]="matIcon" *ngIf="!isSvgIcon"></mat-icon>
      </div>

      <div class="wt-flex-column">
        <span class="wt-dropdown-label">
          {{ translateMenuLabel ? (menuLabel! | translate) : menuLabel }}
          <ng-container *ngIf="items$ | async as items">
            {{ showMultiSelectionAmount ? '(' + (multiSelectAmount$ | async) + '/' + items.length + ')' : '' }}
          </ng-container>
        </span>
      </div>
    </div>
  </div>

  <mat-menu
    #afterMenu="matMenu"
    xPosition="after"
    class="wt-mat-dropdown {{ menuClass }}"
    [hasBackdrop]="true"
    [backdropClass]="menuBackdropClass">
    <mat-accordion (click)="$event.stopPropagation()" multi>
      <div class="wt-mat-dropdown__header">
        <div class="wt-flex-row wt-flex-collapse">
          <div *ngIf="useInputFilter"
            class="wt-flex-column wt-full-width wt-mat-dropdown__header-column">
            <div class="wt-flex wt-flex-align-middle wt-mat-dropdown__header-filter">
              <input type="text" class="wt-mat-dropdown__header-filter-input"
                #inputElm
                [placeholder]="'MAT_DROPDOWN.FILTER.COUNTRY' | translate"
                [ngModel]="filter$ | async"
                (ngModelChange)="onFilterChange($event)" />
              <mat-icon class="wt-mat-dropdown__header-filter-icon" fontIcon="search"></mat-icon>
            </div>
          </div>

          <div class="wt-flex-column" *ngFor="let col of columns">
            <div [class]="col.size != undefined ? 'wt-flex-small-{{ col-size }}'  : ''" [ngClass]="col.className">
              <div class="wt-truncate"
                title="{{ col.title ? (col.translateTitle ? ( col.title | translate) : col.title) : '' }}">
                {{ col.label ? (col.translateLabel ? (col.label | translate) : '') : '' }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="wt-mat-dropdown__list" *ngIf="filteredItems$ | async as items">
        <wt-mat-dropdown-item *ngFor="let item of items"
          [item]="item"
          [columns]="columns"
          (itemSelected)="itemSelected.emit($event)">
        </wt-mat-dropdown-item>
      </div>
    </mat-accordion>
  </mat-menu>
</div>