import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { PricePipesModule } from '@vwd/ngx-price-pipes';

import { PerformanceBarModule } from '../../shared/performance-bar/performance-bar.module';
import { PerformancePeriodsModule } from '../../shared/performance-periods/performance-periods.module';
import { DynamicBarModule } from '../../shared/dynamic-bar/dynamic-bar.module';
import { SymbolStatusModule } from '../../shared/symbol-status/symbol-status.module';
import { DirectivesModule } from '../../directives/directives.module';
import { FocusMiniComponent } from './focus-mini.component';

@NgModule({
  declarations: [FocusMiniComponent],
  providers: [DatePipe],
  imports: [
    CommonModule,
    MatIconModule,
    PerformanceBarModule,
    PerformancePeriodsModule,
    DynamicBarModule,
    DirectivesModule,
    PricePipesModule,
    SymbolStatusModule,
    I18nPipesModule,
  ],
  exports: [FocusMiniComponent]
})
export class FocusMiniModule { }
