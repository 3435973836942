import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { merge, of } from 'rxjs';
import { delay, take, tap } from 'rxjs/operators';

import { ToolkitService } from './../../services/toolkit.service';

const splashFadeAnimationTime = 300;

@Component({
  selector: 'wt-main-layout',
  templateUrl: './main-layout.component.html',
  animations: [
    trigger('showSplash', [
      state('true', style({ opacity: '1' })),
      state('false', style({ opacity: '0' })),
      transition('0 <=> 1', animate(`${splashFadeAnimationTime}ms ease`)),
    ]),
  ],
})
export class MainLayoutComponent {
  showSplash = true;
  isSplash = true;

  constructor(public dialog: MatDialog, private toolkitService: ToolkitService) {
    merge(this.toolkitService.infrontUI$, of(undefined)) // would be better UX if we condition the removal of the splash screen to a finnished loading state, but not current requirement
      .pipe(
        delay(2000),
        take(2),
        tap(() => (this.showSplash = false)),
        delay(splashFadeAnimationTime)
      )
      .subscribe(() => {
        this.isSplash = false;
      });
  }
}
