import { isDevMode } from '@angular/core';
import type { Grid } from '../../state-model/grid.model';
import { HistoryWidgetDefaults, TradesWidgetDefaults } from '../../state-model/widget.defaults';
import type { HistoryWidget, TradesWidget, Widget } from '../../state-model/widget.model';
import type { DashboardWindow } from '../../state-model/window.model';
import { dummyInstrument, readonlyWindowParams } from './templates.model';
import { validateTemplate } from './validation';
import { deepFreeze } from '../../util/object';

// DASHBOARD TEMPLATE: INDEX
const firstColumnWidth = 15;
const secondColumnWidth = 20;
const thirdColumnWidth = 15;

const headerHeight = 1;

//col1
const instrumentOverviewHeight = 13;
const tradesHeight = 17;

//col2
const chartHeight = 13;
const constituentsHeight = 17;

//col3
const performanceHeight = 11;
const historyheight = 19;

export const overviewDashboardWindows: DashboardWindow[] = deepFreeze([
  // first column
  {
    id: 'col1-overview',
    linkChannel: 'None',
    name: 'InstrumentWindow',
    settings: { instrument: { ...dummyInstrument } },
    dashboardId: '0',
    selectedWidgetName: 'InstrumentOverview',
    cols: firstColumnWidth,
    rows: instrumentOverviewHeight,
    y: headerHeight,
    x: 0,
    ...readonlyWindowParams,
  },
  {
    id: 'col1-trades',
    linkChannel: 'None',
    name: 'InstrumentWindow',
    settings: { instrument: { ...dummyInstrument } },
    dashboardId: '0',
    selectedWidgetName: 'Trades',
    cols: firstColumnWidth,
    rows: tradesHeight,
    y: headerHeight + instrumentOverviewHeight,
    x: 0,
    ...readonlyWindowParams,
  },
  // second column (to 1280px)
  {
    id: 'col2-chart',
    linkChannel: 'None',
    name: 'ChartWindow',
    settings: { instrument: { ...dummyInstrument }, headerType: 'none' },
    dashboardId: '0',
    selectedWidgetName: 'Chart',
    cols: secondColumnWidth,
    rows: chartHeight,
    y: headerHeight,
    x: firstColumnWidth,
    ...readonlyWindowParams,
  },
  {
    id: 'col2-constituents',
    linkChannel: 'None',
    name: 'InstrumentWindow',
    settings: { instrument: { ...dummyInstrument } },
    dashboardId: '0',
    selectedWidgetName: 'Constituents',
    cols: secondColumnWidth,
    rows: constituentsHeight,
    y: headerHeight + chartHeight,
    x: firstColumnWidth,
    ...readonlyWindowParams,
  },
  // third column
  {
    id: 'col3-performance',
    linkChannel: 'None',
    name: 'InstrumentWindow',
    settings: { instrument: { ...dummyInstrument } },
    dashboardId: '0',
    selectedWidgetName: 'Performance',
    cols: thirdColumnWidth,
    rows: performanceHeight,
    y: headerHeight,
    x: firstColumnWidth + secondColumnWidth,
    ...readonlyWindowParams,
  },
  {
    id: 'col3-history',
    linkChannel: 'None',
    name: 'InstrumentWindow',
    settings: { instrument: { ...dummyInstrument } },
    dashboardId: '0',
    selectedWidgetName: 'History',
    cols: thirdColumnWidth,
    rows: historyheight,
    y: headerHeight + performanceHeight,
    x: firstColumnWidth + secondColumnWidth,
    ...readonlyWindowParams,
  },
]);

export const overviewDashboardWidgets: Widget[] = deepFreeze([
  // Instrument Dashboard
  {
    id: 'w-overview',
    dashboardId: '0',
    name: 'InstrumentOverview',
    settings: { selectedPeriodIndex: 4 },
    windowId: 'col1-overview',
  },
  {
    id: 'w-trades',
    dashboardId: '0',
    name: 'Trades',
    settings: TradesWidgetDefaults.settings,
    gridColumnSize: 'sizeToFit',
    windowId: 'col1-trades',
  } as TradesWidget,
  {
    id: 'w-constituents',
    dashboardId: '0',
    name: 'Constituents',
    settings: {
      selectedGrid: 'constituentsWide',
    },
    windowId: 'col2-constituents',
  },
  {
    id: 'w-performance',
    dashboardId: '0',
    name: 'Performance',
    settings: { showChart: false, showDividendsLine: true },
    windowId: 'col3-performance',
  },
  {
    id: 'w-history',
    dashboardId: '0',
    name: 'History',
    settings: HistoryWidgetDefaults.settings,
    gridColumnSize: 'sizeToFit',
    windowId: 'col3-history',
  } as HistoryWidget,
  // Chart window
  {
    id: 'w-chart',
    dashboardId: '0',
    name: 'Chart',
    settings: {
      chartOptions: {
        defaultPeriod: '1Y',
        // bottomToolbar: {},
      },
    },
    windowId: 'col2-chart',
  },
]);

export const overviewDashboardGrids: Grid[] = deepFreeze([
  // sub for TradesWidget, widgetId 2
  {
    id: '0',
    dashboardId: '0',
    name: 'trades',
    parentId: 'w-trades',
  },
  // sub for ConstitutentsWidget, widgetId 4
  {
    id: '10',
    dashboardId: '0',
    name: 'constituentsWide',
    parentId: 'w-constituents',
  },
  // sub for HistoryWidget, widgetId 8
  {
    id: '20',
    dashboardId: '0',
    name: 'historyTrades',
    parentId: 'w-history',
  },
]);

if (isDevMode()) {
  validateTemplate('forex-template', overviewDashboardWindows, overviewDashboardWidgets, overviewDashboardGrids);
}
