import { Injectable } from '@angular/core';
import { BuiltInDashboardProvider, type DashboardModel } from '@infront/ngx-dashboards-fx';
import { Observable, of } from 'rxjs';

import { PORTFOLIO_DASHBOARDS } from './portfolio-dashboards';

@Injectable({ providedIn: 'root' })
export class WTBuiltInDashboardProvider extends BuiltInDashboardProvider {

  getDashboards(): Observable<DashboardModel[]> {
    return of(PORTFOLIO_DASHBOARDS);
  }
}
