import { HttpClient } from '@angular/common/http';
import { Injectable, inject, type OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Infront, InfrontSDK } from '@infront/sdk';
import { KeycloakAuthService } from '@vwd/keycloak-auth-angular';
import { LogService } from '@vwd/ngx-logging';
import { BehaviorSubject, Observable, Subject, combineLatest, from, merge, of, throwError } from 'rxjs';
import { map, share, shareReplay, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { SdkRequestsService } from '../../services/sdk-requests.service';
import { SdkService } from '../../services/sdk.service';
import { StoreService } from '../../services/store.service';
import { TradingPositionsService } from '../../services/trading-positions.service';
import { TradingService } from '../../services/trading.service';
import type { Column } from '../../shared/grid/columns.model';
import type { UntranslatedNoRowsTemplate } from '../../shared/grid/grid.model';
import { NewsStoryDialogComponent } from '../../shared/news-story-dialog/news-story-dialog.component';
import { ProgressService, trackProgress } from '../../shared/progress';
import type { NewsWidget, Widget } from '../../state-model/widget.model';
import { isFeedSearchableWindow, isNewsTypeWindow, isWatchlistWindow, type DashboardWindow, type NewsType } from '../../state-model/window.model';
import type { FeedFilterItem } from '../../typings/models/feed-filterable';
import { HeadlineIcons, NewsChunkSize, columnsByNewsTypeMap, overlayNoRowsTemplateOptions, type NewsHeadline, type NewsSource, type NewsSourceMap, type NewsSourceType } from './news.model';

// eslint-disable-next-line no-null/no-null, no-restricted-syntax
const FAULTY_NEWS_STORY_BODY = [undefined, '</span>', null, ''];

@Injectable()
export class NewsService implements OnDestroy {
  private readonly sdkRequestsService = inject(SdkRequestsService);
  private readonly sdkService = inject(SdkService);
  private readonly storeService = inject(StoreService);
  private readonly newsStoryDialog = inject(MatDialog);
  private readonly keycloak = inject(KeycloakAuthService);
  private readonly http = inject(HttpClient);
  private readonly logService = inject(LogService);
  private readonly tradingService = inject(TradingService);
  private readonly tradingPositionsService = inject(TradingPositionsService);

  // private readonly logger = this.logService.openLogger('services/news');

  private readonly itemsInScrollAction = new BehaviorSubject<number>(NewsChunkSize);
  private cache: NewsHeadline[] = [];
  private readonly feedFilterItemsAction = new BehaviorSubject<FeedFilterItem[]>([]);
  readonly feedFilterItems$ = this.feedFilterItemsAction.asObservable();


  private readonly ngUnsubscribe = new Subject<void>();


  getColumns(newsType: NewsType): Column[] {
    return columnsByNewsTypeMap[newsType];
  }

  filteredHeadlines$(
    widget: NewsWidget,
    newsType: NewsType,
    untranslatedNoRowsTemplateAction: Subject<UntranslatedNoRowsTemplate | undefined>,
    progress: ProgressService,
  ): Observable<NewsHeadline[]> {
    return combineLatest([
      this.newsHeadlinesCachedOrNew$(widget, newsType, untranslatedNoRowsTemplateAction, progress),
      this.storeService.widget$(widget),
      this.itemsInScrollAction
    ]).pipe(
      withLatestFrom(this.getNewsSource$(widget, newsType, untranslatedNoRowsTemplateAction)),
      map(([[headlines, widget, itemsInScroll], source]) => {
        if (!source || !headlines?.length) {
          return [];
        }
        // filter news by filter-text or types
        const filteredHeadlines = this.filterHeadlines(widget, headlines, source.sourceType, untranslatedNoRowsTemplateAction);
        this.feedFilterItemsAction.next(this.uniqueFeeds(headlines, filteredHeadlines));
        // ! removal of duplicates, should ideally be done by the sdk
        const result = filteredHeadlines.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
        // sort the news items by `dateTime` desc, newest items to start of array.
        // Without this sorting and in case we have more than `itemsInScroll` news items
        // all news items added later by streaming will not be part of the sliced result
        // and there will be no streaming updates visible in news-widget in such case!
        // Please note: sorting by `dateTime` is also done in ag-grid and also still necessary!
        result.sort((a, b) => (b.dateTime?.getTime() ?? -1) - (a.dateTime?.getTime() ?? -1));
        // return a slice of only the first `itemsInScroll` items
        return result.slice(0, itemsInScroll).map((item) => ({ index: item.id, ...item }));
      }),
      shareReplay(1)
    );
  }

  getNewsType(window: DashboardWindow): NewsType {
    let type: NewsType | undefined;
    if (isNewsTypeWindow(window)) {
      type = window.settings.newsType;
    } else if (isWatchlistWindow(window)) {
      type = 'Watchlist';
    } else {
      type = 'Instrument';
    }
    return type;
  }

  openNewsStory(newsHeadline: NewsHeadline): void {
    if (newsHeadline.url) {
      // Keycloak token needs to be used for displaying PDFs from docs.infrontservices.com without login dialog!
      if (newsHeadline.url.includes('https://docs.infrontservices.com/doc/get')) {
        from(this.keycloak.getToken()).pipe(
          switchMap((token) => {
            if (token == undefined) {
              return throwError(() => new Error('Received no authentication token, therefore can not display the news article!'));
            }
            return this.http.get(newsHeadline.url, {
              responseType: 'blob',
              headers: {
                'Content-type': 'application/pdf',
                'Authorization': `Bearer ${token}`,
              },
            });
          }),
          tap((blob) => {
            const blobUrl = window.URL.createObjectURL(blob);
            window.open(blobUrl, "_blank")?.focus();
          }),
          take(1),
          takeUntil(this.ngUnsubscribe)
        ).subscribe();
        return;
      }
      window.open(newsHeadline.url, '_blank')?.focus();
      return;
    }
    if (newsHeadline.isFlash) {
      return;
    }
    if (newsHeadline.hasBody) {
      const newsStoryOptions: InfrontSDK.NewsStoryOptions = {
        id: newsHeadline.id,
        feed: newsHeadline.feed as number,
        onData: (newsStory: InfrontSDK.NewsStory) => {
          if (!FAULTY_NEWS_STORY_BODY.includes(newsStory.body) && newsStory.headline?.headline != undefined) {
            this.openNewsStoryModal(newsStory); // opens the news stories dialog (component)
          }
        },
      };
      this.fetchNewsStory(newsStoryOptions);
      return; // NOSONAR
    }
  }

  instrumentSource$(widget: NewsWidget, untranslatedNoRowsTemplateAction: Subject<UntranslatedNoRowsTemplate | undefined>): Observable<NewsSource | undefined> {
    return this.sdkRequestsService.windowInstrument$(widget, { filterInvalid: false }).pipe(
      map((source) => {
        if (!source) {
          untranslatedNoRowsTemplateAction.next(overlayNoRowsTemplateOptions.noInstrument);
          return undefined;
        }
        return { source: [source], sourceType: 'instrument' };
      })
    );
  }

  watchlistSource$(widget: NewsWidget, untranslatedNoRowsTemplateAction: Subject<UntranslatedNoRowsTemplate | undefined>): Observable<NewsSource | undefined> {
    return this.sdkRequestsService.selectedWatchlist$(widget).pipe(
      map((wl) => {
        if (wl?.title == undefined) {
          untranslatedNoRowsTemplateAction.next(overlayNoRowsTemplateOptions.noWatchlist);
          return undefined;
        }
        if (!wl.items?.length) {
          untranslatedNoRowsTemplateAction.next(overlayNoRowsTemplateOptions.noWatchlistItems);
          return undefined;
        }
        return { source: wl.items, sourceType: 'watchlist' };
      })
    );
  }

  portfolioSource$(untranslatedNoRowsTemplateAction: Subject<UntranslatedNoRowsTemplate | undefined>): Observable<NewsSource | undefined> {
    return this.tradingService.tradingConnected$.pipe(
      switchMap((connected) => {
        untranslatedNoRowsTemplateAction.next(overlayNoRowsTemplateOptions.noPortfolio);
        if (connected) {
          return this.tradingPositionsService.portfolioInstruments$.pipe(
            map((pfInstruments) => {
              if (!pfInstruments.length) {
                untranslatedNoRowsTemplateAction.next(overlayNoRowsTemplateOptions.noPortfolioItems);
                return undefined;
              }
              return { source: pfInstruments, sourceType: 'portfolio' as NewsSourceType };
            })
          );
        }
        return of(undefined);
      }),
    );
  }

  countrySource$(widget: NewsWidget, untranslatedNoRowsTemplateAction: Subject<UntranslatedNoRowsTemplate | undefined>): Observable<NewsSource | undefined> {
    return this.storeService.windowByWidget$(widget).pipe(
      map((window) => {
        let countrySources: number[] | undefined;
        if (isFeedSearchableWindow(window)) {
          countrySources = window.settings.feeds;
        }
        if (!countrySources?.length) {
          untranslatedNoRowsTemplateAction.next(overlayNoRowsTemplateOptions.noCountry);
          return undefined;
        }
        return { source: countrySources, sourceType: 'country' as NewsSourceType } as NewsSource;
      })
    );
  }

  private readonly newsSourceMap$: NewsSourceMap = {
    'Instrument': (widget, untranslatedNoRowsTemplateAction) => this.instrumentSource$(widget, untranslatedNoRowsTemplateAction),
    'Watchlist': (widget, untranslatedNoRowsTemplateAction) => this.watchlistSource$(widget, untranslatedNoRowsTemplateAction),
    'Country': (widget, untranslatedNoRowsTemplateAction) => this.countrySource$(widget, untranslatedNoRowsTemplateAction),
    'Portfolio': (untranslatedNoRowsTemplateAction) => this.portfolioSource$(untranslatedNoRowsTemplateAction),
  };

  private getNewsSource$(
    widget: NewsWidget,
    newsType: NewsType,
    untranslatedNoRowsTemplateAction: Subject<UntranslatedNoRowsTemplate | undefined>
  ): Observable<NewsSource | undefined> {
    let newsSource$: Observable<NewsSource | undefined>;
    if (newsType === 'Portfolio') {
      newsSource$ = this.newsSourceMap$[newsType](untranslatedNoRowsTemplateAction);
    } else {
      newsSource$ = this.newsSourceMap$[newsType](widget, untranslatedNoRowsTemplateAction);
    }
    return newsSource$;
  }

  private newsHeadlinesCachedOrNew$(
    widget: NewsWidget,
    newsType: NewsType,
    untranslatedNoRowsTemplateAction: Subject<UntranslatedNoRowsTemplate | undefined>,
    progress?: ProgressService
  ): Observable<NewsHeadline[] | undefined> {
    return merge(of(this.cache), this.newsHeadlines$(widget, newsType, untranslatedNoRowsTemplateAction, progress));
  }

  // get headlines then request feedMetaData for all feeds used in the headlines then map together as a single array of our own NewsHeadline objects
  newsHeadlines$(
    widget: NewsWidget,
    newsType: NewsType,
    untranslatedNoRowsTemplateAction: Subject<UntranslatedNoRowsTemplate | undefined>,
    progress?: ProgressService
  ): Observable<NewsHeadline[]> {
    return this.getNewsSource$(widget, newsType, untranslatedNoRowsTemplateAction).pipe(
      switchMap((s) => {
        if (!s?.source) {
          return of([]);
        } else if (Array.isArray(s.source) && !s.source.length) {
          untranslatedNoRowsTemplateAction.next(overlayNoRowsTemplateOptions.noSource);
          return of([]);
        }
        return this.sdkRequestHeadlines$(s, progress);
      })
    );
  }

  readonly sdkRequestHeadlines$ = (newsSource: NewsSource, progress?: ProgressService): Observable<NewsHeadline[]> =>
    this.sdkService
      .getArray$(InfrontSDK.newsHeadlines, {
        source: newsSource.source,
        limit: 500,
      })
      .pipe(
        trackProgress({ label: 'sdkRequestHeadlines$ (sources)', optional: true, progress }),
        switchMap((headlines) =>
          this.sdkService
            .getArray$(InfrontSDK.feedInfo, {
              infoType: InfrontSDK.FeedInfoType.MetaData,
              feed: headlines.map((hl: InfrontSDK.NewsHeadline) => hl.feed),
            })
            .pipe(
              trackProgress({ label: 'sdkRequestHeadlines$ (headlines)', optional: true, progress }),
              map((feedMetadataList) =>
                this.enrichNewsHeadlines(headlines, feedMetadataList, newsSource)
              ),
              tap((result) => {
                if (result.length > 0) { // TODO: should we not also replace cache if result is empty, else cache contains wrong data?
                  this.cache = result;
                }
              })
            )
        ),
        share()
      );

  private enrichNewsHeadlines(headlines: InfrontSDK.NewsHeadline[], feedMetadataList: (string | InfrontSDK.FeedInfo)[], newsSource: NewsSource): NewsHeadline[] {
    return headlines.map((hl: InfrontSDK.NewsHeadline, i) => {
      const wtHeadlineItem: NewsHeadline = {
        dateTime: hl.dateTime,
        headline: hl.headline,
        cellHeadline: this.getHeadlineIcon(hl) + hl.headline,
        feed: hl.feed,
        feedShortName: (feedMetadataList[i] as InfrontSDK.FeedInfo).feedCode,
        feedLongName: (feedMetadataList[i] as InfrontSDK.FeedInfo).description,
        isResearchNews: !!(hl as Infront.HeadlineItem).isResearchNews,
        isFlash: hl.isFlash,
        id: hl.id,
        symbols: this.headlineSymbols(hl.symbols, newsSource),
        url: hl.url,
        hasBody: hl.hasBody,
      };
      return wtHeadlineItem;
    });
  }

  private getHeadlineIcon(hl: InfrontSDK.NewsHeadline): string {
    return hl.url ? HeadlineIcons.url : '';
  }

  private headlineSymbols(allSymbols: InfrontSDK.SymbolId[], newsSource: NewsSource): InfrontSDK.SymbolId[] {
    if (newsSource.sourceType === 'instrument') {
      return [];
    }
    if (newsSource.sourceType === 'instruments') {
      return allSymbols;
    }
    return allSymbols.filter((symbol) =>
      newsSource.source?.map((item: InfrontSDK.SymbolId | number) => {
        const sourceSymbol = item as InfrontSDK.SymbolId; // We know we're dealing with symbol type based on above check on sourceType
        return sourceSymbol.feed === symbol.feed && sourceSymbol.ticker === symbol.ticker;
      })
    );
  }

  showMore(): void {
    this.itemsInScrollAction.next(this.itemsInScrollAction.getValue() + NewsChunkSize);
  }

  resetScroll(): void {
    this.itemsInScrollAction.next(NewsChunkSize);
  }

  private filterHeadlines(
    widget: Widget,
    headlines: NewsHeadline[],
    sourceType: NewsSourceType,
    untranslatedNoRowsTemplateAction: Subject<UntranslatedNoRowsTemplate | undefined>,
  ): NewsHeadline[] {
    if (!headlines?.length) {
      untranslatedNoRowsTemplateAction.next(overlayNoRowsTemplateOptions.noData);
      return [];
    }

    const settings = (widget as NewsWidget).settings;
    let filteredHeadlines = headlines;
    if (settings.textFilter) {
      filteredHeadlines = filteredHeadlines.filter((hl) => hl.headline.toLowerCase().includes(settings.textFilter.toLowerCase()));
    }
    if (!settings.showNews) {
      filteredHeadlines = filteredHeadlines.filter((hl) => !!hl.isResearchNews || !!hl.isFlash);
    }
    if (!settings.showFlashNews) {
      filteredHeadlines = filteredHeadlines.filter((hl) => !hl.isFlash);
    }
    if (!settings.showResearchNews) {
      filteredHeadlines = filteredHeadlines.filter((hl) => !hl.isResearchNews);
    }
    if (sourceType === 'watchlist') {
      filteredHeadlines = filteredHeadlines.filter((hl) => !!hl.symbols);
    }

    // TODO: check could add specific noRows messages for showNews, showFlash, showResearch (?)
    if (!filteredHeadlines.length) {
      untranslatedNoRowsTemplateAction.next(overlayNoRowsTemplateOptions.noFilterPassItem);
    }

    return filteredHeadlines;
  }

  private readonly uniqueFeeds = (headlines: NewsHeadline[], filteredHeadlines: NewsHeadline[]): FeedFilterItem[] => {
    const filtered = [...new Set(headlines.map((hl) => hl.feed!))].map((feed: number) => {
      return {
        feed,
        shortName: headlines.find((item) => item.feed === feed)?.feedShortName ?? '',
        longName: headlines.find((item) => item.feed === feed)?.feedLongName ?? '',
        active: filteredHeadlines.map((hl) => hl.feed).includes(feed),
      };
    });
    return filtered;
  };

  fetchNewsStory(newsStoryOptions: InfrontSDK.NewsStoryOptions): void {
    this.sdkService.sdk$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((sdk: InfrontSDK.SDK) => sdk.get(InfrontSDK.Requests.newsStory(newsStoryOptions)));
  }

  openNewsStoryModal(newsStory: InfrontSDK.NewsStory): void {
    this.newsStoryDialog
      .open(NewsStoryDialogComponent, {
        data: {
          newsHeadline: newsStory.headline,
          body: newsStory.body,
        },
        height: '70%',
        width: '70%',
      })
      .addPanelClass('cdk-overlay-panel__news-story');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.itemsInScrollAction.complete();
    this.feedFilterItemsAction.complete();
  }
}
