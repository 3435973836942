import { ChangeDetectionStrategy, Component, Input, type OnInit } from '@angular/core';
import { LogService } from '@vwd/ngx-logging';

@Component({
  selector: 'wt-fund-rating',
  template: `
    <div class="fund-rating" [title]="title || ''" *ngIf="rating != undefined">
      <ng-container *ngIf="!isMorningStarFund">
        <mat-icon *ngFor="let number of [1,2,3,4,5]" class="mat-icon__size--diamond material-icons-outlined" [ngClass]="{ 'mat-icon--disabled': rating < number }">diamond</mat-icon>
      </ng-container>
      <ng-container *ngIf="isMorningStarFund">
        <mat-icon *ngFor="let number of [1,2,3,4,5]" class="mat-icon__size--star" [ngClass]="{ 'mat-icon--disabled': rating < number }">star</mat-icon>
      </ng-container>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundRatingComponent implements OnInit {
  @Input({ required: true }) rating!: number;
  @Input() isMorningStarFund = false;
  @Input() title: string | undefined;

  private readonly logger = this.logService.openLogger('shared/fund-rating');

  constructor(private readonly logService: LogService) { }

  ngOnInit(): void {
    this.logger.info('ngOnInit', 'rating=', this.rating, 'isMorningStarFund=', this.isMorningStarFund);
  }
}
