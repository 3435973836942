import { ChangeDetectionStrategy, Component, HostListener, Input, type OnDestroy, type OnInit, inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { InfrontSDK, InfrontUtil } from '@infront/sdk';
import { BehaviorSubject, NEVER, Subject } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { DefaultFeedScoreFactorItems, type SdkSymbolSearchResultItem } from '../../search/search.model';
import { AlertService } from '../../services/alert.service';
import { SdkRequestsService } from '../../services/sdk-requests.service';
import { type Instrument, isInstrument } from '../../state-model/window.model';
import { filterUndefined } from '../../util/rxjs';
import type { AlertDialogParams } from './alert-dialog.model';

import type { Widget } from '../../state-model/widget.model';

const fields = [
  InfrontSDK.SymbolField.Open,
  InfrontSDK.SymbolField.High,
  InfrontSDK.SymbolField.Low,
  InfrontSDK.SymbolField.LastTradedAt,
  InfrontSDK.SymbolField.PreDisplayTime,
  InfrontSDK.SymbolField.Change,
  InfrontSDK.SymbolField.ChangePercent,
  InfrontSDK.SymbolField.Decimals,
  InfrontSDK.SymbolField.FullName,
  InfrontSDK.SymbolField.Currency,
  InfrontSDK.SymbolField.CountryFlag,
  InfrontSDK.SymbolField.Ticker,
  InfrontSDK.SymbolField.SymbolClassification,
];

@Component({
  selector: 'wt-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// open only by calling alert service!
export class AlertNewEditDialogComponent implements OnInit, OnDestroy {
  private readonly sdkRequestsService = inject(SdkRequestsService);
  private readonly alertService = inject(AlertService);
  private readonly dialog = inject(MatDialog);
  readonly dialogRef = inject(MatDialogRef<AlertNewEditDialogComponent>);
  readonly params: AlertDialogParams = inject(MAT_DIALOG_DATA) as AlertDialogParams;

  @Input({ required: true }) widget!: Widget;

  instrument: Instrument | undefined;
  readonly submitAction = new Subject<UntypedFormGroup>();
  private readonly alertInstrumentAction = new BehaviorSubject<Instrument | undefined>(undefined);

  readonly DefaultFeedScoreFactorItems = DefaultFeedScoreFactorItems;

  // temp comment: symbolInfo$ inferred at initialization
  readonly symbolInfo$ = this.alertInstrumentAction.pipe(
    filter((instrument) => isInstrument(instrument)),
    filterUndefined(),
    switchMap((instrument) => {
      return this.sdkRequestsService.streamingSymbolData$({
        symbolEntity: instrument, fields, uuid: 'alertNewEditDialog'
      });
    })
  );

  readonly submitListener$ = this.alertInstrumentAction.pipe(
    switchMap((instrument) =>
      this.submitAction.pipe(
        switchMap((form) => {
          const newOrEditServerAlert$ = this.params.alert ? this.alertService.modifyServerAlert$ : this.alertService.addServerAlert$;
          // watchlist
          if (this.params.alertType === 'watchlist') {
            return newOrEditServerAlert$(this.serverAlertForList(form));
          }
          // instrument
          if (!instrument) {
            return NEVER;
          }
          const isMultiCondition = form.get('alertMeIf')?.value.operator === InfrontSDK.AlertOperator.Or;
          if (isMultiCondition) {
            return newOrEditServerAlert$(this.serverAlertForInstrument(form, instrument));
          }
          return this.params.alert
            ? this.alertService.modifyAlertData$(this.getAlertData(form, instrument), this.params.alert)
            : this.alertService.addAlertData$(this.getAlertData(form, instrument));
        })
      )
    ),
    tap(() => this.dialogRef.close())
  );

  ngOnInit(): void {
    this.alertInstrumentAction.next(this.params.instrument);
    this.instrument = this.params.instrument as Instrument;
  }

  ngOnDestroy(): void {
    this.submitAction.complete();
    this.alertInstrumentAction.complete();
  }

  @HostListener('keydown.escape', ['$event'])
  onKeyboardEscape() {
    const alertNewEditDialog = this.dialog.getDialogById('alertNewEditDialog');
    if (alertNewEditDialog) {
      alertNewEditDialog.close();
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  // alertData is user friendly but can only handle single condition alerts and also not watchlists
  private readonly getAlertData = (form: UntypedFormGroup, instrument: Instrument): InfrontSDK.AlertData => ({
    triggerType: form.get('recurrence')?.value.triggerType as InfrontSDK.AlertTriggerType,
    comment: form.get('comment')?.value as string,
    marketDataField: form.get('alertMeIf')?.value.marketDataField as InfrontSDK.AlertMarketDataField,
    operator: form.get('alertMeIf')?.value.operator as InfrontSDK.AlertOperator,
    value: +form.get('limit')?.value,
    enabled: true,
    symbolId: { feed: instrument.feed, ticker: instrument.ticker },
  });

  private readonly serverAlertForInstrument = (form: UntypedFormGroup, instrument: Instrument, alert = this.params.alert): InfrontSDK.ServerAlert => {
    const rule = {
      isLeft: true,
      left: this.alertNodeForInstrument(form, true, instrument),
      operator: InfrontSDK.AlertOperator.Or,
      type: InfrontSDK.AlertType.Instrument,
      right: this.alertNodeForInstrument(form, false, instrument),
    };
    const serverAlert = {
      rule,
      enabled: true,
      id: alert?.id ?? InfrontUtil.makeUUID(),
      revisionIndex: alert?.revisionIndex,
      triggerType: form.get('recurrence')?.value.triggerType as InfrontSDK.AlertTriggerType,
      type: InfrontSDK.AlertType.Instrument,
      comment: form.get('comment')?.value as string,
    } as unknown as InfrontSDK.ServerAlert;
    // console.log(JSON.stringify(serverAlert)); // debug
    return serverAlert;
  };

  private alertNodeForInstrument(form: UntypedFormGroup, isLeft: boolean, instrument: Instrument) {
    return {
      isLeft,
      mdValue: { feed: instrument?.feed, ticker: instrument?.ticker, mdField: form.get('alertMeIf')?.value.mdField as number } as InfrontSDK.AlertValue,
      mdField: form.get('alertMeIf')?.value.mdField as number,
      operator: isLeft ? InfrontSDK.AlertOperator.Lt : InfrontSDK.AlertOperator.Gt,
      type: InfrontSDK.AlertType.Instrument,
      double: +form.get(isLeft ? 'rangeStart' : 'rangeEnd')?.value,
    };
  }

  private readonly serverAlertForList = (form: UntypedFormGroup, alert = this.params.alert): InfrontSDK.ServerAlert => {
    const rule = {
      isLeft: true,
      mdValue: { mdField: form.get('alertMeIf')?.value.mdField as number } as InfrontSDK.AlertValue,
      operator: form.get('alertMeIf')?.value.operator as InfrontSDK.AlertOperator,
      type: InfrontSDK.AlertType.List,
      double: +form.get('limit')?.value,
    };
    const serverAlert = {
      rule,
      enabled: true,
      id: alert?.id ?? InfrontUtil.makeUUID(),
      revisionIndex: alert?.revisionIndex,
      triggerType: form.get('recurrence')?.value.triggerType as InfrontSDK.AlertTriggerType,
      type: InfrontSDK.AlertType.List,
      comment: form.get('comment')?.value as string,
      list: this.params.watchlist ?? alert?.watchlist,
    } as InfrontSDK.ServerAlert;
    return serverAlert;
  };

  readonly onItemClicked = (item: SdkSymbolSearchResultItem) => {
    this.alertInstrumentAction.next(item);
  };
}
