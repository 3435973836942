import { ChangeDetectionStrategy, Component, ElementRef, Input, type OnDestroy, type OnInit, Renderer2, inject } from '@angular/core';

import { SidebarTab } from '../sidebar.model';
import {
  DEBOUNCE_IN_MS,
  type Sidebar,
} from './sidebar-content.model';
import { SidebarContentService } from './sidebar-content.service';

@Component({
  selector: 'wt-sidebar-content',
  templateUrl: './sidebar-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SidebarContentService],
})
export class SidebarContentComponent implements OnInit, OnDestroy {
  private readonly elementRef = inject(ElementRef) as ElementRef<Element>;
  private readonly renderer = inject(Renderer2);

  @Input({ required: true }) sidebar!: Sidebar;

  resize$!: ResizeObserver;
  resizeDebounce: ReturnType<typeof setTimeout> | undefined;

  readonly sidebarTabEnumCssClassMap: { [Tab in SidebarTab]: string } = {
    [SidebarTab.GLOBAL]: 'global',
    [SidebarTab.WATCHLIST]: 'watchlist',
    [SidebarTab.TRADING]: 'trading',
  };
  readonly sidebarTabEnum = SidebarTab;

  ngOnInit(): void {
    this.resize$ = new ResizeObserver(this.resizeToFullHeight);
    this.resize$.observe(document.body);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onGridRowClick(event: unknown): void {
    // console.info('onRowClicked event', event); // TODO: remove or replace by this.logger
  }

  /**
   * responsible for setting height of sidebar-content to take up remaining screen height
   * (from elements top position to the bottom of the viewport)
   * @param bodyEntry (entries of the document.body element that's being observed)
   */
  resizeToFullHeight = (bodyEntries: ResizeObserverEntry[]): void => {
    for (const bodyEntry of bodyEntries || []) {
      if (this.resizeDebounce) {
        clearTimeout(this.resizeDebounce);
      }

      this.resizeDebounce = setTimeout(() => {
        this.resizeDebounce = undefined;
        const bodyHeight = bodyEntry.contentRect.height;
        const elementRect = this.elementRef.nativeElement.getBoundingClientRect();
        this.renderer.setStyle(this.elementRef.nativeElement, 'height', `${bodyHeight - elementRect.y}px`);
      }, DEBOUNCE_IN_MS);
    }
  };

  ngOnDestroy(): void {
    if (this.resizeDebounce) {
      clearTimeout(this.resizeDebounce);
    }
    this.resize$?.disconnect();
  }

}
