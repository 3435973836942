import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, NgForm, type ValidationErrors, type ValidatorFn, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'wt-new-admin-item-modal',
  templateUrl: './new-admin-item-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class NewAdminItemModalComponent {
  @ViewChild('addRealmForm')

  addRealmForm!: NgForm;
  addRealmFormGroup: FormGroup;
  maxRealmNameLength = 128;
  minRealmNameLength = 3;

  get addRealmName() { return this.addRealmFormGroup.get('name')!; }

  constructor(
    public dialogRef: MatDialogRef<NewAdminItemModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string,
      input: string,
      icon: string,
      submitLabel: string,
      closeLabel: string,
      hasValidation: boolean,
    },
  ) {
    this.addRealmFormGroup = new FormGroup({
      name: new FormControl('', [
        // eslint-disable-next-line @typescript-eslint/unbound-method
        Validators.required,
        ...(data.hasValidation ? [Validators.maxLength(this.maxRealmNameLength)] : []),
        ...(data.hasValidation ? [forbiddenName()] : []),
      ]),
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}

export function forbiddenName(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const name = control.value as string;
    const forbidden = /[^a-z0-9]/.test(name);
    // eslint-disable-next-line no-null/no-null, no-restricted-syntax
    return forbidden ? { forbiddenName: { value: name } } : null;
  };
}
