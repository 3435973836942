import type { DashboardEntity } from '../../internal/api/dashboards';
import type { WidgetsHistoryModel } from '../../models';
import { type DashboardFolderModel, type DashboardModel, type DashboardModelBase, type DashboardModelOwner, type DashboardNodeModel, DashboardType } from '../../models/dashboard.models';

export type FolderType = 'USER' | 'SHARED' | 'SUBTENANT' | 'COMPANY' | 'GLOBAL';

export type HttpDashboardModel = DashboardModel & { meta: HttpDashboardMeta; owner: DashboardModelOwner };
export type HttpDashboardNodeModel = DashboardNodeModel & { meta: HttpDashboardMeta; owner: DashboardModelOwner };
export type HttpDashboardFolderModel = DashboardFolderModel & { meta: HttpDashboardMeta; owner: DashboardModelOwner };

export interface HttpDashboardMeta {
  source: 'http',
  raw: DashboardEntity | undefined,
  role: FolderType,
  virtual?: boolean;
  linksTo?: string;
}

export function isHttpDashboardModel(dashboard: DashboardFolderModel | undefined): dashboard is HttpDashboardFolderModel;
export function isHttpDashboardModel(dashboard: DashboardModelBase | undefined): dashboard is HttpDashboardModel;
export function isHttpDashboardModel(dashboard: any): boolean {
  return dashboard?.meta?.source === 'http';
}

export type HttpWidgetHistoryModel = WidgetsHistoryModel & { meta: HttpWidgetHistoryMeta };

export interface HttpWidgetHistoryMeta {
  source: 'http',
  id: string;
  dashboardId: string;
}

export function isHttpWidgetHistoryModel(history: WidgetsHistoryModel): history is HttpWidgetHistoryModel {
  return (history as any)?.meta?.source === 'http';
}

export function assertHttpDashboardModel(dashboard: DashboardFolderModel | undefined): asserts dashboard is HttpDashboardFolderModel;
export function assertHttpDashboardModel(dashboard: DashboardModelBase | undefined): asserts dashboard is HttpDashboardModel;
export function assertHttpDashboardModel(dashboard: any): void {
  if (!dashboard) {
    throw new TypeError('No dashboard provided.');
  }
  if (!isHttpDashboardModel(dashboard)) {
    if (dashboard.type === DashboardType.FOLDER) {
      throw new TypeError('Dashboard folder is missing proper meta information.');
    } else {
      throw new TypeError('Dashboard is missing proper meta information.');
    }
  }
}

export function assertHttpDashboardFolderModel(dashboard: DashboardModelBase | undefined, messageIfNotFolder?: string): asserts dashboard is HttpDashboardFolderModel {
  assertHttpDashboardModel(dashboard);
  if (dashboard.type !== DashboardType.FOLDER) {
    throw new TypeError(messageIfNotFolder ?? 'Dashboard folder expected.');
  }
}

export function throwIfHttpDashboardFolderModel(dashboard: DashboardModelBase | undefined, messageIfFolder?: string): asserts dashboard is HttpDashboardNodeModel {
  assertHttpDashboardModel(dashboard);
  if (dashboard.type === DashboardType.FOLDER) {
    throw new TypeError(messageIfFolder ?? 'Dashboard folder is not supported.');
  }
}
