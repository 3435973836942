import type { MarketOverviewTemplate } from '../market-overview.model';

export const TemplateMajorEquityFutures: MarketOverviewTemplate[] = [
  {
    // tab: 'US',
    tab: '',
    templateName: 'MajorEquityFutures',
    instrumentList: [
      { feed: 2101, ticker: 'YMFUT1' },
      { feed: 21, ticker: 'NQFUT1' },
      { feed: 21, ticker: 'ESFUT1' },
      { feed: 6330, ticker: 'VXN' },
      { feed: 6330, ticker: 'VIX' },
  //   ],
  // },
  // {
  //   tab: 'Europe',
  //   instrumentList: [
      { feed: 2206, ticker: 'STFUT1' },
      { feed: 2206, ticker: 'ESFUT1' },
      { feed: 2280, ticker: 'ZFUT1' },
      { feed: 2274, ticker: '2V2TX' },
      { feed: 2206, ticker: 'DXFUT1' },
      // { feed: 2206, ticker: 'F2MXFUT1' },
      { feed: 2063, ticker: 'PX1FUT1' },
      { feed: 17923, ticker: 'OMXS30FUT1' },
      { feed: 2062, ticker: 'AEXFUT1' },
      // { feed: 2064, ticker: 'BEL20FUT1' },
      // { feed: 2068, ticker: 'PSI20FUT1' },
      { feed: 2206, ticker: 'SIFUT1' },
      { feed: 18179, ticker: 'OBXFUT1' },
      { feed: 17667, ticker: 'OMXC25FUT1' },
  //   ],
  // },
  // {
  //   tab: 'AsiaAfrica',
  //   instrumentList: [
      { feed: 17, ticker: 'NIYFUT1' },
      { feed: 17, ticker: 'NKFUT1' },
      // { feed: ??, ticker: 'ALSIFUT1' }, // TODO: feed? JSE ALSI FUT
    ],
  },
];
