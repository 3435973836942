import { InfrontSDK } from '@infront/sdk';

import type { OhlcValueItem } from '../../shared/models/performance.model';

export interface InstrumentFocusData {
  open: number;
  high: number;
  low: number;
  close: number;
  time: Date | undefined;
  lastTradedAt: number | undefined;
  change: number | undefined;
  changePercent: number | undefined;
  decimals: number | undefined;
  hasIntradaySupport?: boolean;
  ohlc?: OhlcValueItem; // internal
}

export const StreamingFields: InfrontSDK.SymbolField[] = [
  InfrontSDK.SymbolField.Open,
  InfrontSDK.SymbolField.High,
  InfrontSDK.SymbolField.Low,
  InfrontSDK.SymbolField.Last,
  InfrontSDK.SymbolField.PreLastTradedAt,
  InfrontSDK.SymbolField.PreDisplayTime,
  InfrontSDK.SymbolField.Change,
  InfrontSDK.SymbolField.ChangePercent,
  InfrontSDK.SymbolField.Decimals,
];