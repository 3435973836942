import { InfrontSDK } from '@infront/sdk';
import type { WidgetName } from '../../state-model/widget.model';

export const instrumentWindowTabs = (classification: InfrontSDK.SymbolClassification, hasEsgAccess: boolean): WidgetName[] => {
  if (!classification) {
    return [];
  }
  const tabs = tabsInner(classification);
  const returnTabs = tabs.filter((tab) => hasEsgAccess ? tab : tab !== 'Esg');
  return returnTabs;
};


const tabsInner = (classification: InfrontSDK.SymbolClassification): WidgetName[] => {

  switch (classification) {
    case InfrontSDK.SymbolClassification.Stock:
    case InfrontSDK.SymbolClassification.ETF:
      return ['InstrumentOverview', 'Orderbook', 'Performance', 'Calendar', 'News', 'Trades', 'History', 'Esg'];
    case InfrontSDK.SymbolClassification.Fund:
      return ['InstrumentOverview', 'Exposure', 'Performance', 'History', 'Esg'];
    case InfrontSDK.SymbolClassification.Bond:
      return ['InstrumentOverview', 'Orderbook', 'Performance', 'Trades', 'History', 'Esg'];
    case InfrontSDK.SymbolClassification.Forex:
    case InfrontSDK.SymbolClassification.Certificate:
    case InfrontSDK.SymbolClassification.Derivative:
    case InfrontSDK.SymbolClassification.Future:
      return ['InstrumentOverview', 'Orderbook', 'Performance', 'Trades', 'History'];
    case InfrontSDK.SymbolClassification.Index:
      return ['InstrumentOverview', 'Constituents', 'Performance', 'Trades', 'History'];
    default:
      break;
  }
  return ['InstrumentOverview', 'Performance', 'History'];
};
