import { NumberFormatStyle } from '@angular/common';
import type enLocale from '@angular/common/locales/en';
import enGBLocale from '@angular/common/locales/en-GB';
import { InjectionToken, inject } from '@angular/core';
import { FormattingService } from '@vwd/ngx-i18n';
import { ResourceService } from '@vwd/ngx-i18n/translate';
import { BehaviorSubject, type Observable } from 'rxjs';

/**
 * The `LOCALE_ID$` injection token gives you an `Observable<string>` that
 * represents the current locale (according to the {@link FormattingService}).
 *
 * You can easily inject it into your components via
 * ```
 * private readonly localeId$ = inject(LOCALE_ID$);
 * ```
 */
export const LOCALE_ID$ = new InjectionToken<Observable<string>>('localeId$', {
  providedIn: 'root',
  factory() {
    const formattingService = inject(FormattingService);
    const subject = new BehaviorSubject<string>(formattingService.localeId);

    formattingService.onLocaleChange.subscribe({
      next: (value: string) => {
        if (subject.value !== value) {
          subject.next(value);
        }
      },
      complete: () => {
        subject.complete();
      }
    });

    return subject.asObservable();
  }
});

/*
 ******************************************************************************
 * CAUTION
 ******************************************************************************
 * This file depends on the *undocumented* Angular locale data format. This
 * means that future Angular updates could break the code below.
 */

// Unfortunately, Angular does not export LocaleDataIndex, so this is a bit
// fragile; check every Angular upgrade!
const enum LocaleDataIndex {
  LocaleId = 0,
  DayPeriodsFormat,
  DayPeriodsStandalone,
  DaysFormat,
  DaysStandalone,
  MonthsFormat,
  MonthsStandalone,
  Eras,
  FirstDayOfWeek,
  WeekendRange,
  DateFormat,
  TimeFormat,
  DateTimeFormat,
  NumberSymbols,
  NumberFormats,
  CurrencyCode,
  CurrencySymbol,
  CurrencyName,
  Currencies,
  Directionality,
  PluralCase,
  ExtraData
}

type LocaleData = typeof enLocale;

/**
 * Builds an English language locale data object that uses English for its
 * texts, but uses the underlying locale for things like date & number
 * formatting.
 *
 * @param localeId The ID of the generated locale data.
 * @param baseLocale The base locale to mix with the English data.
 * @returns A hybrid English-{@param localeId} data object.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function buildSyntheticEnLocale(localeId: string, baseLocale: any): any {
  const result = (baseLocale as LocaleData).slice();
  result[LocaleDataIndex.LocaleId] = localeId;
  for (const localeDataIndex of [
    LocaleDataIndex.DayPeriodsFormat,
    LocaleDataIndex.DayPeriodsStandalone,
    LocaleDataIndex.DaysFormat,
    LocaleDataIndex.DaysStandalone,
    LocaleDataIndex.MonthsFormat,
    LocaleDataIndex.MonthsStandalone
  ]) {
    result[localeDataIndex] = enGBLocale[localeDataIndex];
  }
  result[LocaleDataIndex.NumberFormats] = enGBLocale[LocaleDataIndex.NumberFormats];
  return result;
}

/**
 * This function adjusts the given locale data to conform with a couple of
 * uniformity wishes.
 *
 * Right now, we ensure the percent format is consistent.
 *
 * @param localeData The locale data to fix up.
 * @returns The fixed up locale data.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function applyFormatOverrides(localeData: any): any {
  const typedLocaleData = localeData as LocaleData;
  const numberFormats = typedLocaleData[LocaleDataIndex.NumberFormats] as string[];
  const percentFormat = numberFormats[NumberFormatStyle.Percent];

  if (percentFormat.endsWith(' %') || percentFormat.endsWith('\u00a0%')) {
    const result = typedLocaleData.slice();
    numberFormats[NumberFormatStyle.Percent] = '#,##0%';
    result[LocaleDataIndex.NumberFormats] = numberFormats;
    return result;
  }

  return typedLocaleData;
}

export function translator(): (key: string, args?: unknown) => string {
  const translateService = inject(ResourceService);
  return (key, args) => {
    return args ? translateService.get(key, args) : translateService.get(key) as string;
  };
}

export function translator$(): (key: string, args?: unknown) => Observable<string> {
  const translateService = inject(ResourceService);
  return (key, args) => {
    return args ? translateService.stream(key, args) : translateService.stream(key) as Observable<string>;
  };
}
