import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { PricePipesModule } from '@vwd/ngx-price-pipes';

import { PipesModule } from '../../pipes/pipes.module';
import { BigNumberModule } from '../big-number/big-number.module';
import { SymbolStatusModule } from '../../shared/symbol-status/symbol-status.module';
import { SymbolEventsModule } from '../../shared/symbol-events/symbol-events.module';
import { OrderbookViewComponent } from './orderbook-view.component';

@NgModule({
  declarations: [OrderbookViewComponent],
  imports: [
    CommonModule,
    PipesModule,
    SymbolStatusModule,
    SymbolEventsModule,
    PricePipesModule,
    BigNumberModule,
    I18nPipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [OrderbookViewComponent],
})
export class OrderbookViewModule { }
