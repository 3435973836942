import { isDevMode } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import type { Dashboard } from '../state-model/dashboard.model';
import type { Grid } from '../state-model/grid.model';
import { InstrumentHeaderDefaults, PortfolioHeaderDefaults } from '../state-model/widget.defaults';
import type { InstrumentHeaderWidget, NetTradesWidget, PortfolioHeaderWidget, PortfolioOrdersWidget, PortfolioPositionsWidget, PortfolioTradesWidget, PositionsEventsWidget, PositionsExposureWidget, PositionsSummaryWidget, Widget } from '../state-model/widget.model';
import { InstrumentHeaderWindowDefaults, PortfolioHeaderWindowDefaults } from '../state-model/window.defaults';
import type { DashboardWindow, Instrument, InstrumentHeaderWindow, NetTradesWindow, PortfolioHeaderWindow, PortfolioOrderSummaryWindow, PortfolioOrdersWindow, PortfolioPositionsWindow, PortfolioTradesWindow, PositionsEventsWindow, PositionsExposureWindow, PositionsSummaryWindow } from '../state-model/window.model';
import type { OrderCategory } from './../widgets/portfolio-orders/portfolio-orders.model';
import type { TradingClassification } from './../widgets/portfolio-positions/portfolio-positions.model';
import { PORTFOLIO_DASHBOARD_ID } from './providers/portfolio-dashboards';
import { dashboardTemplates } from './templates/';
import {
  portfolioOrderSummaryWidgetBase,
  portfolioOrderSummaryWindowBase,
  portfolioOrdersBaseWindow,
  portfolioOrdersGrids,
  portfolioOrdersWidgets,
  portfolioOrdersWindows,
} from './templates/portfolio-orders-template';
import {
  BalanceWidget,
  BalanceWindow,
  CashWidget,
  CashWindow,
  DevelopmentWidget,
  DevelopmentWindow,
  EventsWidget,
  EventsWindow,
  ExposureWidget,
  ExposureWindow,
  portfolioPositionsBaseWindow,
  portfolioPositionsGrids,
  portfolioPositionsWidgets,
  portfolioPositionsWindows
} from './templates/portfolio-positions-template';
import { type DashboardTemplate, readonlyWindowParams } from './templates/templates.model';
import { validateTemplate } from './templates/validation';
import { deepFreeze } from '../util/object';

export const InstrumentDashboardTabs = ['OVERVIEW', 'CHART'] as const;
export type InstrumentDashboardTab = (typeof InstrumentDashboardTabs)[number];

export const INSTRUMENT_DASHBOARD_OVERVIEW_POSTFIX = '~instrument-dashboard-overview';
export const INSTRUMENT_DASHBOARD_CHART_POSTFIX = '~instrument-dashboard-chart';

const emptyDashboardTemplate = {
  windows: [],
  widgets: [],
  grids: [],
};

const headerHeight = 1;

export const portfolioWindowDefaultWidth = 38;
export const portfolioWindowDefaultHeight = 16;
export const sideWindowDefaultHeight = 10;

export const InstrumentHeaderWindowName = 'InstrumentHeaderWindow';
export const PortfolioHeaderWindowName = 'PortfolioHeaderWindow';

export const createInstrumentDashboardConfig = (
  dashboardId: string,
  instrument: Instrument,
  classification: InfrontSDK.SymbolClassification = InfrontSDK.SymbolClassification.Unknown
): {
  windows: DashboardWindow[];
  widgets: Widget[];
  grids: Grid[];
} => {
  const templates: DashboardTemplate | undefined = dashboardTemplates[classification] ?? dashboardTemplates[InfrontSDK.SymbolClassification.Stock];

  if (!templates) {
    return emptyDashboardTemplate;
  }

  // Header
  const headerWindow = deepFreeze({
    id: 'header',
    ...InstrumentHeaderWindowDefaults,
    x: 0,
    y: 0,
    name: InstrumentHeaderWindowName,
    selectedWidgetName: 'InstrumentHeader',
    dashboardId,
  } as const satisfies Partial<InstrumentHeaderWindow>);

  const headerWidget = deepFreeze({
    id: 'header',
    ...InstrumentHeaderDefaults,
    windowId: headerWindow.id,
    dashboardId: headerWindow.dashboardId,
    settings: { ...InstrumentHeaderDefaults.settings, instrument },
  } as const satisfies InstrumentHeaderWidget);

  // Overview
  const overviewWindows = deepFreeze(templates.overviewDashboardWindows.map(w => ({
    ...w, dashboardId, tag: 'OVERVIEW', settings: { ...w.settings, instrument }, minItemCols: w.cols, maxItemCols: w.cols, minItemRows: w.rows, maxItemRows: w.rows
  }) as const satisfies DashboardWindow));

  const overviewWidgets = deepFreeze(templates.overviewDashboardWidgets.map(w => ({
    ...w, dashboardId
  }) as const satisfies Widget));

  const overviewGrids = deepFreeze(templates.overviewDashboardGrids.map(g => ({
    ...g, dashboardId
  }) as const satisfies Grid));

  // Chart
  const chartWindows = deepFreeze(templates.chartDashboardWindows.map((w) => ({
    ...w, dashboardId, tag: 'CHART', settings: { ...w.settings, instrument }
  }) as const satisfies DashboardWindow));

  const chartWidgets = deepFreeze(templates.chartDashboardWidgets.map(w => ({
    ...w, dashboardId
  }) as const satisfies Widget));

  const entities: ReturnType<typeof createInstrumentDashboardConfig> = {
    windows: [{ ...headerWindow }, ...overviewWindows, ...chartWindows].map((w) => ({
      linkChannel: 'None',
      ...w
    })),
    widgets: [{ ...headerWidget }, ...overviewWidgets, ...chartWidgets],
    grids: [...overviewGrids],
  };
  return entities;
};

export const PortfolioDashboardTabs = ['POSITIONS', 'ORDERS', 'TRADES'] as const;

export const createPortfolioEntitiesConfig = (
  classifications: Readonly<TradingClassification[]>,
  orderCategories: Readonly<OrderCategory[]>,
): {
  dashboards: Dashboard[];
  windows: DashboardWindow[];
  widgets: Widget[];
  grids: Grid[];
} => {
  // Portfolio Header
  const headerWindow = deepFreeze({
    id: 'header',
    ...PortfolioHeaderWindowDefaults,
    name: PortfolioHeaderWindowName,
    selectedWidgetName: 'PortfolioHeader',
    x: 0,
    y: 0,
  } as const satisfies Partial<PortfolioHeaderWindow>);

  const headerWidget = deepFreeze({
    id: 'header',
    dashboardId: PORTFOLIO_DASHBOARD_ID,
    ...PortfolioHeaderDefaults,
    windowId: headerWindow.id,
    settings: { ...PortfolioHeaderDefaults.settings },
  } as const satisfies Partial<PortfolioHeaderWidget>);

  // Portfolio Positions by classification
  const positionsWindows = deepFreeze(
    classifications.map((c) => ({
      ...portfolioPositionsBaseWindow,
      ...{ ...portfolioPositionsWindows[c] },
      ...{ id: c, tradingClassification: c, tag: 'POSITIONS' },
    }) as const satisfies Partial<PortfolioPositionsWindow>)
  );

  const positionsWidgets = deepFreeze(
    classifications.map((c, i) => ({
      ...portfolioPositionsWidgets[c],
      ...{ id: 'w-' + c, name: 'PortfolioPositions', tradingClassification: c, windowId: positionsWindows[i].id },
    } as const satisfies Partial<PortfolioPositionsWidget>))
  );

  const positionsGrids = deepFreeze(classifications.reduce(
    (acc, c, i) => [...acc, ...portfolioPositionsGrids[c].map((grid) => ({ id: grid.name, parentId: positionsWidgets[i].id, ...grid } as Grid))],
    [] as readonly Grid[]
  ));

  // Portfolio Positions summary
  const positionsSummaryWindows = deepFreeze([
    { ...DevelopmentWindow, id: 'dev' },
    { ...BalanceWindow, id: 'balance' },
    { ...CashWindow, id: 'cash' },
    { ...ExposureWindow, id: 'exposure' },
    { ...EventsWindow, id: 'event' }
  ] as const satisfies Partial<PositionsSummaryWindow | PositionsExposureWindow | PositionsEventsWindow>[]);

  const positionsSummaryWidgets = deepFreeze([
    { ...DevelopmentWidget, id: 'w-dev', windowId: positionsSummaryWindows[0].id, dashboardId: positionsSummaryWindows[0].dashboardId },
    { ...BalanceWidget, id: 'w-balance', windowId: positionsSummaryWindows[1].id, dashboardId: positionsSummaryWindows[1].dashboardId },
    { ...CashWidget, id: 'w-cash', windowId: positionsSummaryWindows[2].id, dashboardId: positionsSummaryWindows[2].dashboardId },
    { ...ExposureWidget, id: 'w-exposure', windowId: positionsSummaryWindows[3].id, dashboardId: positionsSummaryWindows[3].dashboardId },
    { ...EventsWidget, id: 'w-events', windowId: positionsSummaryWindows[4].id, dashboardId: positionsSummaryWindows[4].dashboardId },
  ] as const satisfies Partial<PositionsSummaryWidget | PositionsExposureWidget | PositionsEventsWidget>[]);

  // Portfolio Orders by order-category
  const ordersWindows = deepFreeze(orderCategories.map((c) => ({
    ...portfolioOrdersBaseWindow,
    ...{ ...portfolioOrdersWindows[c] },
    ...{ id: 'orders' + c, orderCategory: c, tag: 'ORDERS' },
  }) as const satisfies Partial<PortfolioOrdersWindow>));

  const ordersWidgets = orderCategories.map((c, i) => ({
    ...portfolioOrdersWidgets[c],
    ...{ id: 'w-orders' + c, name: 'PortfolioOrders', orderCategory: c, windowId: ordersWindows[i].id },
  })) satisfies Partial<PortfolioOrdersWidget>[]; // 'as const' can not be used

  const ordersGrids = orderCategories.reduce(
    (acc, c, i) => [...acc, ...portfolioOrdersGrids[c].map((grid) => ({ id: grid.name, parentId: ordersWidgets[i].id, ...grid }) as Grid)],
    [] as Grid[]
  );

  // Portfolio Order Summary
  const portfolioOrderSummaryWindows = deepFreeze([
    { ...portfolioOrderSummaryWindowBase },
  ] as const satisfies Partial<PortfolioOrderSummaryWindow>[]);

  const portfolioOrderSummaryWidgets: Widget[] = [
    { ...portfolioOrderSummaryWidgetBase, windowId: portfolioOrderSummaryWindowBase.id, dashboardId: portfolioOrderSummaryWindowBase.dashboardId },
  ] as Widget[];

  // Portfolio Trades
  const tradesWindow = deepFreeze({
    id: 'trades',
    tag: 'TRADES',
    linkChannel: 'Channel 1',
    name: 'PortfolioTradesWindow',
    settings: {},
    dashboardId: PORTFOLIO_DASHBOARD_ID,
    selectedWidgetName: 'PortfolioTrades',
    cols: portfolioWindowDefaultWidth,
    rows: portfolioWindowDefaultHeight,
    label: 'Trades',
    x: 0,
    y: headerHeight,
    ...readonlyWindowParams,
  } as const satisfies Partial<PortfolioTradesWindow>);

  const tradesWidget = {
    id: 'w-trades',
    name: 'PortfolioTrades',
    windowId: tradesWindow.id
  } as const satisfies Partial<PortfolioTradesWidget>;

  const tradesGrids = [{
    id: 'g-trades',
    parentId: tradesWidget.id,
    name: 'portfolioTrades'
  }] as const satisfies Grid[];

  // trades net-trades
  const portfolioNetTradesWindow = deepFreeze({
    id: 'net-trades',
    tag: 'TRADES',
    linkChannel: 'Channel 1',
    name: 'NetTradesWindow',
    settings: {},
    dashboardId: PORTFOLIO_DASHBOARD_ID,
    selectedWidgetName: 'NetTrades',
    cols: 12,
    rows: portfolioWindowDefaultHeight,
    x: portfolioWindowDefaultWidth,
    y: headerHeight,
    ...readonlyWindowParams
  } as const satisfies Partial<NetTradesWindow>);

  const netTradesWidget = {
    id: 'w-net-trades',
    name: 'NetTrades',
    windowId: portfolioNetTradesWindow.id
  } as const satisfies Partial<NetTradesWidget>;

  if (isDevMode()) {
    validateTemplate('portfolio/positions', positionsWindows, positionsWidgets, positionsGrids);
    validateTemplate('portfolio/summary', positionsSummaryWindows, positionsSummaryWidgets);
    validateTemplate('portfolio/orders', ordersWindows, ordersWidgets, ordersGrids);
  }

  return {
    dashboards: [],
    windows: [headerWindow, ...positionsWindows, ...positionsSummaryWindows, ...ordersWindows, ...portfolioOrderSummaryWindows, tradesWindow, portfolioNetTradesWindow].map((w) => ({
      // @FIXME rows any y shouldn't be required to be set, this means that the original definitions are flawed!
      rows: 12,
      y: 0,
      linkChannel: 'None',
      dashboardId: PORTFOLIO_DASHBOARD_ID,
      ...w,
    })),
    widgets: [headerWidget, ...positionsWidgets, ...positionsSummaryWidgets, ...ordersWidgets, ...portfolioOrderSummaryWidgets, tradesWidget, netTradesWidget,].map((w) => ({
      dashboardId: PORTFOLIO_DASHBOARD_ID,
      ...w
    })),
    grids: [...positionsGrids, ...ordersGrids, ...tradesGrids].map((g) => ({
      dashboardId: PORTFOLIO_DASHBOARD_ID,
      ...g
    })),
  };
};

