import { InfrontSDK } from '@infront/sdk';
import type { ColDef } from 'ag-grid-community';

import type { InstrumentDashboardTabs, PortfolioDashboardTabs } from '../dashboard/template';
import type { ToolkitStorageData } from '../services/toolkit-storage.component';
import type { InstrumentOverviewSettings } from '../widgets/instrument-overview/instrument-overview.model';
import type { OrderCategory } from '../widgets/portfolio-orders/portfolio-orders.model';
import type { TradingClassification } from '../widgets/portfolio-positions/portfolio-positions.model';

import type { ChartWidgetOptions } from '@infront/wtk/widgets/chart';
import type { ChainsTreeItemState } from '../shared/models/chains-tree.model';
import type { StoreablePeriod } from '../shared/period/period.model';
import { arrayOfAll } from '../util/array';
import type { PositionEventsTab } from '../widgets/positions-events/model';
import type { PositionsExposureGridType } from '../widgets/positions-exposure/positions-exposure.model';
import type { TimePeriod } from '../widgets/ranking/ranking.model';
import type { GridColumnSize, SubGrid, WidgetGridsMap } from './grid.model';
import type { Instrument } from './window.model';
import type { RequiredKeys } from '../util/types';

export type AvailableColumnsWidget = { availableColumns: { [key: string]: ColDef } };
export const isAvailableColumns = (settings: { [key: string]: ColDef } | undefined): settings is AvailableColumnsWidget => {
  return (settings as AvailableColumnsWidget)?.availableColumns != undefined;
};

// for use with the store to update part of Widget
export type PartialWidget = Omit<Partial<Widget>, 'id' | 'dashboardId'>;

// Widget types:

export type WidgetLifespan = 'tabSwitch' | 'window';

export interface Widget {
  dashboardId: string;
  id: string;
  name: WidgetName;
  label?: string; // be able to override name in tabs or other places
  windowId: string;
  settings?: object; //  todo move to specific widget type
  toolkitSettings?: ToolkitStorageData;
  lifespan?: WidgetLifespan; // delete or save widget on tabswitch delete is default
  availableColumns?: { [key: string]: ColDef }; // alternatively make own subtype for this
}

type ReqWidgetKeys = keyof RequiredKeys<Widget>;
const getRequiredWidgetKeysTuple = arrayOfAll<ReqWidgetKeys>();
const requiredWidgetKeys: Readonly<ReqWidgetKeys[]> = getRequiredWidgetKeysTuple(['dashboardId', 'id', 'name', 'windowId']);

export function isWidget(widget: unknown): widget is Widget {
  if (widget && typeof widget !== 'object') {
    return false;
  }
  return requiredWidgetKeys.every((key) => key in (widget as Widget));
}

/**
 * A holder to get a {@link Widget} instance;
 * instantiated by {@link DashboardWindowComponent} and
 * provided as a service within each widget.
 *
 * {@link DashboardWindowComponent} will update the {@link instance}
 * property as the state store updates it.
 *
 * Do not capture the {@link instance} property if you intend
 * to use it outside of the synchronous current method flow
 * (that also rules out async methods & callbacks), because that
 * can cause stale widget data.
 */
export class WidgetInstanceRef {
  constructor(public instance: Widget) { }
}

// Orderbook widget
export interface OrderbookWidget extends Widget {
  name: 'Orderbook';
  settings: { showTopBar: boolean; yellowTopLevel: boolean; boldTopLevel: boolean, showOrderAmount: boolean };
}

export interface InstrumentOverviewWidget extends Widget {
  name: 'InstrumentOverview';
  settings: InstrumentOverviewSettings;
}

export interface WatchlistWidget extends Widget {
  name: 'Watchlist';
  settings: {
    showCheckboxes: boolean;
  };
}

export interface ListsWidget extends Widget {
  name: 'Lists';
  settings: {
    showCheckboxes: boolean;
    selectedSourceName?: string;
  };
}

export interface RankingWidget extends Widget {
  name: 'Ranking';
  settings: {
    selectedSourceName?: string;
    selectedTimePeriod: TimePeriod; // todo: determine type
  };
}

export interface BiggestMoversWidget extends Widget {
  name: 'BiggestMovers';
  settings: {
    selectedSourceName?: string;
    selectedTimePeriod: TimePeriod; // todo: determine type
  };
}

export interface ConstituentsWidget extends Widget {
  name: 'Constituents';
  settings: {
    selectedGrid: (typeof WidgetGridsMap)['constituents'][number];
    selectedSourceName?: string;
  };
}

export interface NewsWidget extends Widget {
  name: 'News';
  settings: {
    textFilter: string;
    showNews: boolean;
    showFlashNews: boolean;
    showResearchNews: boolean;
    deselectedFeeds?: number[];
  };
  //lifespan?: 'window'; // does not get destroyed on window tab switch
}

export interface PerformanceWidget extends Widget {
  name: 'Performance';
  settings: { showChart: boolean; showDividendsLine: boolean };
}

export interface CalendarWidget extends Widget {
  name: 'Calendar';
  settings: {
    selectedPeriod?: StoreablePeriod;
    deselectedFeeds?: number[];
    headlineFilter?: string;
  };
}

export interface TradesWidget extends Widget {
  name: 'Trades';
  settings: {
    selectedDate?: string;
  };
  gridColumnSize?: GridColumnSize; // todo move to map
}

export interface HistoryWidget extends Widget {
  name: 'History';
  settings: {
    selectedGrid: SubGrid;
  };
}
export interface ExposureWidget extends Widget {
  name: 'Exposure';
  settings: {
    selectedGrid: SubGrid;
    showTabs: boolean;
  };
}

export interface ChartWidget extends Widget {
  name: 'Chart';
  settings: {
    chartOptions?: Partial<ChartWidgetOptions>;
    additionalInstruments?: Instrument[];
  };
}

export interface ChartMiniWidget extends Widget {
  name: 'ChartMini';
  settings: {
    chartOptions?: Partial<ChartWidgetOptions>;
    showChartTypeMenu?: boolean;
    showResolutionMenu?: boolean;
    showCompareMenu?: boolean;
    showSettingsMenu?: boolean;
  };
}

export interface FocusWidget extends Widget {
  name: 'Focus';
}

export interface FocusMiniWidget extends Widget {
  name: 'FocusMini';
}

export interface MarketOverviewWidget extends Widget {
  name: 'MarketOverview';
  settings: {
    showChart: boolean;
    flexChart: boolean;
    showTabs: boolean;
    showNoAccessInstruments: boolean;
    selectedTab?: string;
    selectedInstrument?: Instrument;
    selectedPeriod?: string;
  };
}

export interface AlertLogWidget extends Widget {
  name: 'AlertLog';
}

export interface UserlistWidget extends Widget {
  name: 'Userlist';
  settings: {
    adminMode: boolean;
    instruments: Instrument[];
    name: string;
    showCheckboxes: boolean;
  };
}

export interface InstrumentHeaderWidget extends Widget {
  name: 'InstrumentHeader';
  settings: {
    selectedTab: (typeof InstrumentDashboardTabs)[number];
    instrument: Instrument;
  };
}

export interface PortfolioPositionsWidget extends Widget {
  name: 'PortfolioPositions';
  tradingClassification: TradingClassification;
  settings: {
    selectedGrid: (typeof WidgetGridsMap)['portfolioPositionsGrid'][number];
  };
}

export interface PortfolioOrdersWidget extends Widget {
  name: 'PortfolioOrders';
  orderCategory: OrderCategory;
  settings: {
    selectedGrid: (typeof WidgetGridsMap)['portfolioOrdersGrid'][number];
  };
}

export interface PortfolioOrderSummaryWidget extends Widget {
  name: 'PortfolioOrderSummary';
}

export interface PortfolioTradesWidget extends Widget {
  name: 'PortfolioTrades';
}

export interface PortfolioHeaderWidget extends Widget {
  name: 'PortfolioHeader';
  settings: {
    selectedTab: (typeof PortfolioDashboardTabs)[number];
  };
}

export interface PositionsSummaryWidget extends Widget {
  name: 'PositionsSummary';
  positionsSummaryKey: string; // key is used to match against a set of predefined fields to show in the summary window, using strings, this could be any key in the future
}

export interface PositionsEventsWidget extends Widget {
  name: 'PositionsEvents',
  settings: { selectedTab: PositionEventsTab }
}

export interface PositionsExposureWidget extends Widget {
  name: 'PositionsExposure',
  settings: { selectedGrid: PositionsExposureGridType };
}

export interface NetTradesWidget extends Widget {
  name: 'NetTrades';
}

export interface EsgWidget extends Widget {
  name: 'Esg';
}

export interface FundScreener extends Widget {
  name: 'FundScreener';
}
export interface CompanyDataWidget extends Widget {
  name: 'CompanyData';
}

export interface CompanyInformationWidget extends Widget {
  name: 'CompanyInformation';
  settings: {
    showSymbolHeader?: boolean;
  };
}

export interface TopShareholdersWidget extends Widget {
  name: 'TopShareholders';
}

export interface NotFoundWidget extends Widget {
  name: 'NotFound';
}

export interface ChainsWidget extends Widget {
  name: 'Chains'
  settings: {
    adminMode: boolean;
    name: string;
    selectedSourceName?: string;
    selectedItem?: string;
    tree?: ReadonlyArray<ChainsTreeItemState>;
  }
}

// translation keys can here be changed per symbol-classification
// do not forget to add all new keys to DASHBOARD_WINDOW.WIDGET_LABEL translations
export const WidgetNameLabelClassificationMap: { [key: string]: { [key: string]: string } } = {
  [InfrontSDK.SymbolClassification.Index]: {
    Trades: 'Ticks',
  },
};

export type WidgetType = (
  | OrderbookWidget
  | InstrumentOverviewWidget
  | WatchlistWidget
  | ListsWidget
  | RankingWidget
  | BiggestMoversWidget
  | ConstituentsWidget
  | NewsWidget
  | PerformanceWidget
  | CalendarWidget
  | TradesWidget
  | HistoryWidget
  | ExposureWidget
  | ChartWidget
  | ChartMiniWidget
  | FocusWidget
  | FocusMiniWidget
  | MarketOverviewWidget
  | AlertLogWidget
  | InstrumentHeaderWidget
  | PortfolioHeaderWidget
  | PortfolioPositionsWidget
  | PortfolioTradesWidget
  | PortfolioOrdersWidget
  | PortfolioOrderSummaryWidget
  | PositionsSummaryWidget
  | PositionsEventsWidget
  | PositionsExposureWidget
  | NetTradesWidget
  | EsgWidget
  | UserlistWidget
  | FundScreener
  | CompanyDataWidget
  | CompanyInformationWidget
  | TopShareholdersWidget
  | ChainsWidget
  | NotFoundWidget
);

export type WidgetName = WidgetType['name'];
export type WidgetSettings = WidgetType['settings'];
export type WidgetNameToType<Name extends WidgetName> = Extract<WidgetType, { name: Name; }>;


// CommonWatchlistWidget
export interface CommonWatchlistWidgetSettings { selectedWatchlist?: InfrontSDK.Watchlist['id']; }
export interface CommonWatchlistWidgetType extends Widget { settings: CommonWatchlistWidgetSettings; }
export type CommonWatchlistWidget = Extract<WidgetType, CommonWatchlistWidgetType>;
export type CommonWatchlistWidgetName = CommonWatchlistWidget['name'];

const allCommonWatchlistWidgetNames = arrayOfAll<CommonWatchlistWidgetName>();
export const CommonWatchlistWidgetName: Readonly<WidgetName[]> = allCommonWatchlistWidgetNames(
  [] // usage as window.name checking guard for WatchlistService
);

export function isCommonWatchlistWidget(widget: Widget): widget is CommonWatchlistWidgetType {
  return CommonWatchlistWidgetName.includes(widget.name);
}

// InstrumentSearchableWidget
export interface InstrumentSearchableWidgetSettings { instrument?: Instrument; }
export interface InstrumentSearchableWidgetType extends Widget { settings: InstrumentSearchableWidgetSettings; }
export type InstrumentSearchableWidget = Extract<WidgetType, InstrumentSearchableWidgetType>;
export type InstrumentSearchableWidgetName = InstrumentSearchableWidget['name'];


// DeselectableFeedsWidgets
export interface DeselectableFeedsWidgetSettings { deselectedFeeds?: number[]; }
export interface DeselectableFeedsWidgetType extends Widget { settings: DeselectableFeedsWidgetSettings; }
export type DeselectableFeedsWidget = Extract<WidgetType, DeselectableFeedsWidgetType>;
export type DeselectableFeedsWidgetName = DeselectableFeedsWidget['name'];

export const DeselectableFeedsWidgetNames: WidgetName[] = arrayOfAll<DeselectableFeedsWidgetName>()([
  'Calendar', 'News'
]);

export function isDeselectableFeedsWidget(widget: Widget): widget is DeselectableFeedsWidget {
  return DeselectableFeedsWidgetNames.includes(widget.name);
}
