// @TODO adjust types using as const + satisfies
// Widget Defaults
import { dummyInstrument } from '../dashboard/templates/templates.model';
import { PeriodService } from '../services/period.service';
import { DefaultPeriods } from '../shared/period/default-periods';
import type { PositionsExposureGridType } from '../widgets/positions-exposure/positions-exposure.model';
import { WidgetGridsMap } from './grid.model';
import type {
  AlertLogWidget,
  BiggestMoversWidget,
  CalendarWidget,
  ChainsWidget,
  ChartMiniWidget,
  ChartWidget,
  CompanyDataWidget,
  CompanyInformationWidget,
  ConstituentsWidget,
  EsgWidget,
  ExposureWidget,
  FocusMiniWidget,
  FocusWidget,
  HistoryWidget,
  InstrumentHeaderWidget,
  InstrumentOverviewWidget,
  ListsWidget,
  MarketOverviewWidget,
  NewsWidget,
  OrderbookWidget,
  PerformanceWidget,
  PortfolioHeaderWidget,
  PositionsEventsWidget,
  PositionsExposureWidget,
  PositionsSummaryWidget,
  RankingWidget,
  TopShareholdersWidget,
  TradesWidget,
  UserlistWidget,
  WatchlistWidget,
  Widget,
  WidgetName
} from './widget.model';

export type WidgetDefaults<TWidget> = Omit<TWidget, 'id' | 'windowId' | 'dashboardId'>;

export const PositionsSummaryWidgetDefaults: WidgetDefaults<Partial<PositionsSummaryWidget>> = {
  name: 'PositionsSummary',
  settings: {}
};

export const NewsWidgetDefaults: WidgetDefaults<NewsWidget> = {
  name: 'News',
  settings: {
    textFilter: '',
    showNews: true,
    showFlashNews: true,
    showResearchNews: true,
    deselectedFeeds: undefined, // means no filter; [] means none selected
  },
};

export const OrderbookWidgetDefaults: WidgetDefaults<OrderbookWidget> = {
  name: 'Orderbook',
  settings: { showTopBar: true, yellowTopLevel: false, boldTopLevel: true, showOrderAmount: true },
};

export const InstrumentOverviewWidgetDefaults: WidgetDefaults<InstrumentOverviewWidget> = {
  name: 'InstrumentOverview',
  settings: {
    selectedPeriodIndex: 4,
  },
};

export const CalendarWidgetDefaults: WidgetDefaults<CalendarWidget> = {
  name: 'Calendar',
  settings: {
    selectedPeriod: PeriodService.getStoreablePeriod(DefaultPeriods.MINUS1D_PLUS1W),
    deselectedFeeds: undefined, // means no filter; [] means none selected
  },
};

export const TradesWidgetDefaults: WidgetDefaults<TradesWidget> = {
  name: 'Trades',
  settings: {},
};

export const HistoryWidgetDefaults: WidgetDefaults<HistoryWidget> = {
  name: 'History',
  settings: {
    selectedGrid: WidgetGridsMap['history'][0],
  },
};

export const ExposureWidgetDefaults: WidgetDefaults<ExposureWidget> = {
  name: 'Exposure',
  settings: {
    selectedGrid: WidgetGridsMap['exposure'][0],
    showTabs: true,
  },
};

export const ConstituentsWidgetDefaults: WidgetDefaults<ConstituentsWidget> = {
  name: 'Constituents',
  settings: {
    selectedGrid: WidgetGridsMap['constituents'][0],
  },
};

export const ListsWidgetDefaults: WidgetDefaults<ListsWidget> = {
  name: 'Lists',
  settings: { showCheckboxes: false },
};

export const WatchlistWidgetDefaults: WidgetDefaults<WatchlistWidget> = {
  name: 'Watchlist',
  settings: { showCheckboxes: false },
};

export const RankingWidgetDefaults: WidgetDefaults<RankingWidget> = {
  name: 'Ranking',
  settings: {
    selectedTimePeriod: 'Intraday',
  },
};

export const FocusWidgetDefaults: WidgetDefaults<FocusWidget> = {
  name: 'Focus',
  settings: {},
};

export const FocusMiniWidgetDefaults: WidgetDefaults<FocusMiniWidget> = {
  name: 'FocusMini',
  settings: {},
};

export const BiggestMoversDefaults: WidgetDefaults<BiggestMoversWidget> = {
  name: 'BiggestMovers',
  settings: {
    selectedTimePeriod: 'Intraday',
  },
};

export const PerformanceWidgetDefaults: WidgetDefaults<PerformanceWidget> = {
  name: 'Performance',
  settings: { showChart: true, showDividendsLine: true },
};

export const ChartWidgetDefaults: WidgetDefaults<ChartWidget> = {
  name: 'Chart',
  settings: {},
};

export const ChartMiniWidgetDefaults: WidgetDefaults<ChartMiniWidget> = {
  name: 'ChartMini',
  settings: {
    showChartTypeMenu: true,
    showResolutionMenu: false,
    showCompareMenu: false,
    showSettingsMenu: false,
  },
};

export const ChainsWidgetDefaults: WidgetDefaults<ChainsWidget> = {
  name: 'Chains',
  settings: { adminMode: true, name: '' },
};

export const MarketOverviewWidgetDefaults: WidgetDefaults<MarketOverviewWidget> = {
  name: 'MarketOverview' as const,
  settings: {
    showChart: true,
    flexChart: false,
    showTabs: false,
    showNoAccessInstruments: false,
  },
};

export const AlertLogWidgetDefaults: WidgetDefaults<AlertLogWidget> = {
  name: 'AlertLog',
  settings: {},
};

export const InstrumentHeaderDefaults: WidgetDefaults<InstrumentHeaderWidget> = {
  name: 'InstrumentHeader',
  settings: { selectedTab: 'OVERVIEW', instrument: dummyInstrument },
};

export const PortfolioHeaderDefaults: WidgetDefaults<PortfolioHeaderWidget> = {
  name: 'PortfolioHeader',
  settings: { selectedTab: 'POSITIONS' },
};

export const PositionsEventsDefaults: WidgetDefaults<PositionsEventsWidget> = {
  name: 'PositionsEvents',
  settings: { selectedTab: 'CALENDAR' },
};

export const PositionsExposureDefaults: WidgetDefaults<PositionsExposureWidget> = {
  name: 'PositionsExposure',
  settings: {
    selectedGrid: 'holdings' as PositionsExposureGridType,
  },
};

export const EsgDefaults: WidgetDefaults<EsgWidget> = {
  name: 'Esg',
  settings: {},
};

export const UserlistDefaults: WidgetDefaults<UserlistWidget> = {
  name: 'Userlist',
  settings: { adminMode: true, instruments: [], name: '', showCheckboxes: false },
};

export const CompanyDataDefaults: WidgetDefaults<CompanyDataWidget> = {
  name: 'CompanyData',
  settings: {},
};

export const CompanyInformationDefaults: WidgetDefaults<CompanyInformationWidget> = {
  name: 'CompanyInformation',
  settings: {
    showSymbolHeader: true,
  },
};

export const TopShareholdersDefaults: WidgetDefaults<TopShareholdersWidget> = {
  name: 'TopShareholders',
  settings: {},
};


// todo: defaults widget objects are now small enough that we can skip most individual
// defaults definitions and put together the existing ones in the single map definition below for better overview

export const WidgetDefaultsMap: Partial<{ [key in WidgetName]: WidgetDefaults<Widget> }> = {
  News: NewsWidgetDefaults,
  InstrumentOverview: InstrumentOverviewWidgetDefaults,
  MarketOverview: MarketOverviewWidgetDefaults,
  Orderbook: OrderbookWidgetDefaults,
  History: HistoryWidgetDefaults,
  Exposure: ExposureWidgetDefaults,
  Constituents: ConstituentsWidgetDefaults,
  Trades: TradesWidgetDefaults,
  Calendar: CalendarWidgetDefaults,
  Lists: ListsWidgetDefaults,
  Ranking: RankingWidgetDefaults,
  BiggestMovers: BiggestMoversDefaults,
  Watchlist: WatchlistWidgetDefaults,
  Performance: PerformanceWidgetDefaults,
  Chart: ChartWidgetDefaults,
  ChartMini: ChartMiniWidgetDefaults,
  Focus: FocusWidgetDefaults,
  FocusMini: FocusMiniWidgetDefaults,
  AlertLog: AlertLogWidgetDefaults,
  InstrumentHeader: InstrumentHeaderDefaults,
  PortfolioHeader: PortfolioHeaderDefaults,
  PositionsExposure: PositionsExposureDefaults,
  Esg: EsgDefaults,
  Userlist: UserlistDefaults,
  CompanyData: CompanyDataDefaults,
  CompanyInformation: CompanyInformationDefaults,
  TopShareholders: TopShareholdersDefaults,
  Chains: ChainsWidgetDefaults,
};
