import { NgModule } from '@angular/core';

import { AddInstrumentsToWidgetModule } from '../shared/add-instruments-to-widget/add-instruments-to-widget.module';
import { AutoCompleteModule } from '../shared/auto-complete/auto-complete.module';
import { FindInstrumentModule } from '../shared/find-instrument/find-instrument.module';
import { ScreenerModule } from '../shared/screener/screener.module';
import { TradeToastModule } from '../shared/trade-toast/trade-toast.module';
import { AlertLogModule } from './alert-log/alert-log.module';
import { BiggestMoversModule } from './biggest-movers/biggest-movers.module';
import { CalendarModule } from './calendar/calendar.module';
import { ChartModule } from './chart/chart.module';
import { CompanyDataModule } from './company-data/company-data.module';
import { CompanyInformationModule } from './company-information/company-information.module';
import { ConstituentsModule } from './constituents/constituents.module';
import { EsgModule } from './esg/esg.module';
import { ExposureModule } from './exposure/exposure.module';
import { FocusModule } from './focus/focus.module';
import { HistoryModule } from './history/history.module';
import { InstrumentHeaderModule } from './instrument-header/instrument-header.module';
import { InstrumentOverviewModule } from './instrument-overview/instrument-overview.module';
import { ListsModule } from './lists/lists.module';
import { MarketOverviewModule } from './market-overview/market-overview.module';
import { NetTradesModule } from './net-trades/net-trades.module';
import { NewsModule } from './news/news.module';
import { OrderbookModule } from './orderbook/orderbook.module';
import { PerformanceModule } from './performance/performance.module';
import { PortfolioHeaderModule } from './portfolio-header/portfolio-header.module';
import { PortfolioOrdersModule } from './portfolio-orders/portfolio-orders.module';
import { PortfolioPositionsModule } from './portfolio-positions/portfolio-positions.module';
import { PortfolioTradesModule } from './portfolio-trades/portfolio-trades.module';
import { PositionsEventsModule } from './positions-events/positions-events.module';
import { PositionsExposureModule } from './positions-exposure/positions-exposure.module';
import { PositionsSummaryModule } from './positions-summary/positions-summary.module';
import { RankingModule } from './ranking/ranking.module';
import { TopShareholdersModule } from './top-shareholders/top-shareholders.module';
import { TradesModule } from './trades/trades.module';
import { UserlistModule } from './userlist/userlist.module';
import { WatchlistModule } from './watchlist/watchlist.module';
import { ChainsModule } from './chains/chains.module';
import { FocusMiniModule } from './focus-mini/focus-mini.module';
import { PortfolioOrderSummaryModule } from './portfolio-order-summary/portfolio-order-summary.module';

// IMPORTANT: Importing this module from any other module will suppress warnings of unused modules for each widget module within but will also DISABLE lazy loading
// for each module within. The modules within this module are included in the compilation already through tsconfig.app.json and used in the app through dynamic paths
// in dashboard-window.service.ts

// This however triggers a warning during compilation of "unused modules" because the compiler can't recognize this usage and believes they are unused.

@NgModule({
  imports: [
    AlertLogModule,
    AutoCompleteModule,
    AddInstrumentsToWidgetModule,
    BiggestMoversModule,
    CalendarModule,
    ChartModule,
    CompanyDataModule,
    CompanyInformationModule,
    ConstituentsModule,
    EsgModule,
    ExposureModule,
    FindInstrumentModule,
    FocusModule,
    FocusMiniModule,
    HistoryModule,
    InstrumentHeaderModule,
    InstrumentOverviewModule,
    ListsModule,
    MarketOverviewModule,
    NetTradesModule,
    NewsModule,
    OrderbookModule,
    PerformanceModule,
    PortfolioHeaderModule,
    PortfolioOrdersModule,
    PortfolioPositionsModule,
    PortfolioTradesModule,
    PositionsEventsModule,
    PositionsExposureModule,
    PositionsSummaryModule,
    RankingModule,
    ScreenerModule,
    TopShareholdersModule,
    TradesModule,
    TradeToastModule,
    UserlistModule,
    WatchlistModule,
    ChainsModule,
    PortfolioOrderSummaryModule,
  ],
  exports: [],

  providers: [],
})
export class TempSupressUnusedImportsWarningsModule { }
