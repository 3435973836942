import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';

import { DashboardComponent } from './dashboard.component';
// import { canAccessByRoles } from '../guards/auth.guard';



const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    // canActivate: [canAccessByRoles],
    // data: { roles: [] },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
