import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import type { DashboardWindow } from '../../../state-model/window.model';
import type { Dashboard } from '../../../state-model/dashboard.model';

@Component({
  selector: 'wt-title-only-header',
  templateUrl: './title-only-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TitleOnlyHeaderComponent {
  @Input({ required: true }) dashboard!: Dashboard;
  @Input({ required: true }) window!: DashboardWindow;
}
