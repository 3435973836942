import { InfrontSDK } from '@infront/sdk';
import type { InstrumentSearchableWidget } from '../state-model/widget.model';
import { type Instrument, type InstrumentSearchableWindow, isInstrument } from '../state-model/window.model';
import { isDefined } from './types';

// potentially to be outsourced into SDK directly (?)

export const isInfrontSDKSymbolData = (symbolData: unknown): symbolData is InfrontSDK.SymbolData => {
  return !!(symbolData as InfrontSDK.SymbolData)?.get(InfrontSDK.SymbolField.Ticker);
};

export const isInfrontSDKSearchResultItem = (searchResultItem: unknown): searchResultItem is InfrontSDK.SearchResultItem => {
  return Object.values(InfrontSDK.SearchResultItemType).includes((searchResultItem as InfrontSDK.SearchResultItem)?.type);
};

export const isInfrontSDKFeedInfo = (feedInfo: unknown): feedInfo is InfrontSDK.FeedInfo => {
  return 'feed' in (feedInfo as InfrontSDK.FeedInfo);
};

export function getInstrumentFromSymbolLike(symbolLike: InfrontSDK.SymbolData | InfrontSDK.Trading.PortfolioItem | undefined): Instrument | undefined {
  if (typeof symbolLike?.['get'] === 'function') {
    const instrument = {
      ticker: symbolLike.get(InfrontSDK.SymbolField.Ticker) as string | undefined,
      feed: symbolLike.get(InfrontSDK.SymbolField.Feed) as number | undefined
    };
    return isInstrument(instrument) ? instrument : undefined;
  }
  return undefined;
}

export function getInstrumentsFromSymbolLikes(symbolLikes: readonly (InfrontSDK.SymbolData | InfrontSDK.Trading.PortfolioItem | undefined)[]): Instrument[] {
  return symbolLikes.map(getInstrumentFromSymbolLike).filter(isDefined);
}

export function instrumentsAreEqual(instrument1: Instrument | undefined, instrument2: Instrument | undefined, checkEquals = true): boolean {
  return !!(
    (checkEquals ? instrument1 == instrument2 : false) // truthy when nullish or reference
    || instrument1 && instrument2 && instrument1.feed === instrument2.feed && instrument1.ticker === instrument2.ticker);
}

// @TODO use SymbolEntity type below in SdkSymbolDataRequest
export type SymbolEntity = InstrumentSearchableWindow | InstrumentSearchableWidget | Instrument;
