import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

import type { AlertEvent } from '../alerts-list/alerts-list.model';
import { AlertService } from './../../services/alert.service';

@Component({
  selector: 'wt-alert-toast',
  templateUrl: './alert-toast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertToastComponent extends Toast {
  @Input({ required: true }) alert!: AlertEvent;
  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage, private alertService: AlertService) {
    super(toastrService, toastPackage);
  }
  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }

  close = () => {
    //this.markAsSeen(); // keep for now if we want to go back to toggle this on user interaction instead of at toast message
    this.toastrService.remove(this.toastPackage.toastId);
  };

  //markAsSeen = () => this.alertService.markAsSeen([this.alert]); // keep for now - call from template when ticker selected
}
