import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'wt-reset-optional-dialog',
  templateUrl: 'reset-optional-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetOptionalDialogComponent {
  constructor(public dialogRef: MatDialogRef<ResetOptionalDialogComponent>) { }
  onClose(result: boolean): void {
    this.dialogRef.close(result);
  }
}

