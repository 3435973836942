import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { SymbolsGridModule } from '../../wrappers/grid-wrappers/symbols-grid/symbols-grid.module';

import { AddInstrumentsToWidgetModule } from '../../shared/add-instruments-to-widget/add-instruments-to-widget.module';
import { FeedListMenuModule } from '../../shared/feed-list-menu/feed-list-menu.module';
import { FindInstrumentModule } from '../../shared/find-instrument/find-instrument.module';
import { ListsComponent } from './lists.component';

@NgModule({
  declarations: [ListsComponent],
  imports: [CommonModule, MatIconModule, MatMenuModule, MatButtonModule, SymbolsGridModule, FeedListMenuModule, FindInstrumentModule, AddInstrumentsToWidgetModule],
})
export class ListsModule { }
