import { inject, Injectable } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { LogService } from '@vwd/ngx-logging';
import { Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { SdkService } from '../../../services/sdk.service';
import { filterUndefined } from '../../../util/rxjs';
import type { TimeSeriesItem } from '../../../wrappers/grid-wrappers/grid-wrappers.model';

@Injectable({
  providedIn: 'root',
})
export class TimeSeriesService {
  private readonly logService = inject(LogService);
  private readonly sdkService = inject(SdkService);

  private readonly logger = this.logService.openLogger('wrappers/grid-wrappers/time-series/service');

  private cache: { [key: string]: { timeSeries?: number[]; requestMade: boolean; } } = {};
  private cacheCreated = new Date().getDay(); //todo: reset single day intraday by separate rule

  private readonly getKey = <T extends TimeSeriesItem>(item: T): string => item.Ticker + item.Feed.toString() + ''; // item.timePeriod; todo: change to dynamic timePeriod when supported

  private readonly timeSeriesFromCacheOrSDK$ = (item: TimeSeriesItem): Observable<number[]> => {
    const key = this.getKey(item);
    if (new Date().getDay() > this.cacheCreated) {
      this.cache = {};
      this.cacheCreated = new Date().getDay();
    }
    if (this.cache[key]?.timeSeries) {
      return of(this.cache[key].timeSeries).pipe(filterUndefined<number[]>());
    }
    this.cache[key] = { requestMade: true };
    return this.timeSeriesRequest$(item).pipe(
      take(1),
      tap((timeSeries) => {
        const key = this.getKey(item);
        this.cache[key].timeSeries = timeSeries;
      })
    );
  };

  private readonly timeSeriesRequest$ = (item: TimeSeriesItem): Observable<number[]> => {
    // todo: item.timePeriod: use col id for dynamically creating daysBack and resolution to create timeSeries of different time spans
    const opts = {
      id: { ticker: item.Ticker, feed: +item.Feed },
      daysBack: 30,
      resolution: { unit: InfrontSDK.Unit.Day, value: 1 },
      adjustDividends: false,
      adjustSplits: false,
      fields: [InfrontSDK.SymbolField.Last],
      subscribe: false,
    };
    return this.sdkService.getArray$(InfrontSDK.timeSeries, opts).pipe(
      // take(1),
      map((timeSeries) => timeSeries.map((item) => item.last))
    );
  };


  readonly initTimeSeries = (timeSeriesMetadata: TimeSeriesItem, canvas: HTMLCanvasElement, width: number, height: number): void => {
    this.timeSeriesFromCacheOrSDK$(timeSeriesMetadata)
      .pipe(take(1))
      .subscribe((series) => {
        if (series?.length === 0) {
          this.logger.log(this.getKey(timeSeriesMetadata));
        }
        const timeSeries = [...series.slice(0, -1), timeSeriesMetadata[InfrontSDK.SymbolField.PreLastTradedAt]] as number[];
        const canvasContext = canvas.getContext('2d');
        if (canvasContext) {
          this.draw(timeSeries, canvasContext, width, height);
        }
      });
  };

  private draw(data: number[], chart: CanvasRenderingContext2D, width: number, height: number): void {
    chart.canvas.width = width;
    chart.canvas.height = height;
    chart.clearRect(0, 0, width, height);
    chart.translate(0.5, 0.5);
    chart.imageSmoothingEnabled = false;
    chart.lineWidth = 2;
    const max = Math.max(...data);
    const min = Math.min(...data);
    const diff = max - min;
    const chartData = data.map((item) => (1 - (item - min) / diff) * (height / 2));
    const itemWidth = width / (chartData.length + 3);
    const strokeStyle = data[data.length - 1] - data[0] > 0 ? 'rgba(0, 202, 94, 1)' : 'rgba(255, 104, 104, 1)';
    for (let i = 0; i < chartData.length - 2; i++) {
      chart.strokeStyle = strokeStyle;
      chart.moveTo(itemWidth * i, chartData[i]);
      chart.lineTo(itemWidth * (i + 1), chartData[i + 1]);
    }
    chart.stroke();
  }
}
