import { Injectable, inject } from '@angular/core';
import { Infront, InfrontUtil } from '@infront/sdk';

import { KeycloakAuthService } from '@vwd/keycloak-auth-angular';
import { LogService } from '@vwd/ngx-logging';
import { Observable, Subject, Subscriber, combineLatest, from, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { NumberSymbol, getLocaleNumberSymbol } from '@angular/common';


import { InfrontUIOptions, UI } from '@infront/wtk';
import { environment } from '../../environments/environment';
import { LOCALE_ID$ } from '../util/locale';
import { RemoteStorageService } from './remote-storage.service';

export const ToolkitThrottlingTime = 500;

const TEMPORARY_CREDENTIALS: Partial<InfrontUIOptions> = {
  user_id: '<<<some-toolkit-username>>>',
  // REVIEWERS take extra care: do never allow real password in the following line!
  password: '<<<some-toolkit-password>>>', // NOSONAR
};
const TOOLKIT_OPTIONS: Partial<InfrontUIOptions> = {
  streaming: true,
  language: 'en',
  secureConnection: Infront.ConnectionSecurity.Require,
  enableLoginDialog: true,
  useDefaultStateStorage: true,
  throttling: ToolkitThrottlingTime,
  // baseCurrency: 'EUR', // does not exist on InfrontUIOptions (?)
};

@Injectable({
  providedIn: 'root',
})
export class ToolkitService {
  // In case of Keycloak token not working as Toolkit login, this option can be used as failover-solution for local development.
  // - useKeycloakToken = true -> toolkitInitWithToken$ - must be used in production!
  // - useKeycloakToken = false -> toolkitInitWithCredentials$ - only to be used in local development!
  // When set to false, username and password need to be provided in the TEMPORARY_CREDENTIALS const.
  private readonly useKeycloakToken = true;
  private readonly localeId$ = inject(LOCALE_ID$);
  private readonly logger = inject(LogService).openLogger('services/toolkit');

  private toolkitInitWithCredentials$ = new Observable((obs: Subscriber<UI>) =>
    this.initInfrontUI(
      new UI({
        ...TOOLKIT_OPTIONS,
        ...TEMPORARY_CREDENTIALS,
      } as InfrontUIOptions),
      obs
    )
  );

  private toolkitInitWithToken$ = of(undefined).pipe(
    switchMap(() =>
      combineLatest([from(this.keycloakService.getToken()), this.remoteStorage.toolkitStorage$]).pipe(
        switchMap(
          ([token, storageModule]) =>
            new Observable((obs: Subscriber<UI>) => {
              const features: Pick<Infront.LoginOptions, 'features'> & { coredata_url: string, dochub_documentsUrl: string } = {
                coredata_url: new URL('/api/cdapi/', environment.gateway).href,
                dochub_documentsUrl: new URL('/api/document-hub/', environment.gateway).href,
              };

              this.initInfrontUI(
                new UI({
                  ...TOOLKIT_OPTIONS,
                  signed_token: token,
                  widgetStateStorage: storageModule, // Use default storage if no connection to remote server
                  useDefaultStateStorage: !storageModule, // Use default storage if no connection to remote server
                  features,
                } as InfrontUIOptions),
                obs
              );
            })
        )
      )
    )
  );

  infrontUI$ = (this.useKeycloakToken ? this.toolkitInitWithToken$ : this.toolkitInitWithCredentials$).pipe(shareReplay(1));

  availableFeeds$ = this.infrontUI$.pipe(map((infrontUI: UI) => infrontUI?.getModel()?.login?.feeds), shareReplay(1));

  private disconnectAction$ = new Subject<void>();
  disconnect$ = this.disconnectAction$.asObservable();

  constructor(private keycloakService: KeycloakAuthService, private remoteStorage: RemoteStorageService) {
    this.logger.info('InfrontUtil.formatSettings:', InfrontUtil.formatSettings);

    this.localeId$.subscribe((locale) => {
      this.onLocaleChange(locale);
    });
  }

  private initInfrontUI(infrontUI: UI, obs: Subscriber<UI>): void {
    infrontUI.registerEventObserver('onReady', () => {
      if (environment.development && window) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any)['InfrontWT5Debug'] = Infront; // for development runtime debug of Infront namespace
      }
      obs.next(infrontUI);
    });

    infrontUI.registerEventObserver('onDisconnect', () => {
      this.disconnectAction$.next();
    });

    infrontUI.init();
  }

  private onLocaleChange(locale: string): void {
    InfrontUtil.formatSettings.useBrowserFormatting = false;
    InfrontUtil.formatSettings.thousandsSeparator = getLocaleNumberSymbol(locale, NumberSymbol.Group);
    InfrontUtil.formatSettings.decimalSeparator = getLocaleNumberSymbol(locale, NumberSymbol.Decimal);
  }
}
