import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';

import { CountryFlagModule } from '../../shared/country-flag/country-flag.module';
import { FundRatingModule } from '../../shared/fund-rating/fund-rating.module';
import { SymbolStatusModule } from '../../shared/symbol-status/symbol-status.module';
import { SymbolEventsModule } from '../../shared/symbol-events/symbol-events.module';
import { CircleScoreModule } from '../circle-score/circle-score.module';
import { SymbolHeaderInfoComponent } from './symbol-header-info.component';

@NgModule({
  declarations: [SymbolHeaderInfoComponent],
  imports: [
    CommonModule,
    CountryFlagModule,
    FundRatingModule,
    SymbolStatusModule,
    SymbolEventsModule,
    I18nPipesModule,
    CircleScoreModule
  ],
  exports: [SymbolHeaderInfoComponent],
})
export class SymbolHeaderInfoModule { }
