import type { InfrontSDK } from '@infront/sdk';

import type { Grid } from '../../state-model/grid.model';
import type { Widget } from '../../state-model/widget.model';
import type { DashboardWindow } from '../../state-model/window.model';

export interface DashboardTemplate {
  overviewDashboardWindows: DashboardWindow[];
  overviewDashboardWidgets: Widget[];
  overviewDashboardGrids: Grid[];
  chartDashboardWindows: DashboardWindow[];
  chartDashboardWidgets: Widget[];
}
export type DashboardTemplates = Partial<{ [key in InfrontSDK.SymbolClassification]: DashboardTemplate; }>;

export const dummyInstrument = { feed: 0, ticker: 'n/a' };
export const readonlyWindowParams = {
  resizeEnabled: false,
  dragEnabled: false,
};

