import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';

import { SearchModule } from '../../search/search.module';
import { CountryFlagModule } from '../../shared/country-flag/country-flag.module';
import { InstrumentHeaderComponent } from './instrument-header.component';

@NgModule({
  declarations: [InstrumentHeaderComponent],
  imports: [
    CommonModule,
    MatIconModule,
    I18nPipesModule,
    CountryFlagModule,
    SearchModule,
    OverlayModule,
    MatTabsModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [InstrumentHeaderComponent],
})
export class InstrumentHeaderModule {}
