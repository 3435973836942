import type { InfrontSDK } from '@infront/sdk';

import type { PeriodDropdownItem } from '../../shared/dropdown/dropdown-period/dropdown-period.model';
import type { Column } from '../../shared/grid/columns.model';
import { DefaultPeriods } from '../../shared/period/default-periods';
import type { CalendarType } from '../../state-model/window.model';
import type { UntranslatedNoRowsTemplate } from '../../shared/grid/grid.model';
import { deepFreeze } from '../../util/object';
import type { Observable, Subject } from 'rxjs';
import type { CalendarWidget } from '../../state-model/widget.model';
import { multiInstrumentColumns, singleInstrumentColumns } from './calendar.columns';

export type CalendarSource = InfrontSDK.FinancialCalendarOptions['source'];

export type CalendarSourceMap = {
  [Key in CalendarType]: Key extends 'Portfolio'
  ? (
    untranslatedNoRowsTemplateAction: Subject<UntranslatedNoRowsTemplate | undefined>
  ) => Observable<CalendarSource | undefined>
  : (
    widget: CalendarWidget,
    untranslatedNoRowsTemplateAction: Subject<UntranslatedNoRowsTemplate | undefined>
  ) => Observable<CalendarSource | undefined>
};

export const columnsByCalendarTypeMap: { [Type in CalendarType]: Column[]; } = deepFreeze({
  'Instrument': singleInstrumentColumns,
  'Watchlist': multiInstrumentColumns,
  'Portfolio': multiInstrumentColumns,
  'Country': multiInstrumentColumns
});

export interface CalendarEvent extends InfrontSDK.CalendarEvent {
  feedShortName: string;
  feedLongName: string;
}

export interface CalendarData {
  calendarEvents: CalendarEvent[];
  columns: Column[];
}

export interface Vm extends CalendarData {
  calendarType: CalendarType;
  widget: CalendarWidget;
}

// dropdownLabels have been deactivated on Lasse's request. Will keep them if required again.
export const PeriodItems: PeriodDropdownItem[] = [
  {
    period: DefaultPeriods.MINUS1D,
    itemLabel: 'PERIODS.ITEM_LABELS.MINUS1D', translateItemLabel: true,
    // dropdownLabel: 'PERIODS.DROPDOWN_LABELS.MINUS1D', translateDropdownLabel: true,
  },
  {
    period: DefaultPeriods.MINUS1D_PLUS1W,
    itemLabel: 'PERIODS.ITEM_LABELS.MINUS1D_PLUS1W', translateItemLabel: true,
    // dropdownLabel: 'PERIODS.DROPDOWN_LABELS.MINUS1D_PLUS1W', translateDropdownLabel: true,
  },
  {
    period: DefaultPeriods.MINUS1M, // last month
    itemLabel: 'PERIODS.ITEM_LABELS.MINUS1M', translateItemLabel: true,
    // dropdownLabel: 'PERIODS.DROPDOWN_LABELS.MINUS1M', translateDropdownLabel: true,
  },
  {
    period: DefaultPeriods.MINUS1W, // last week
    itemLabel: 'PERIODS.ITEM_LABELS.MINUS1W', translateItemLabel: true,
    // dropdownLabel: 'PERIODS.DROPDOWN_LABELS.MINUS1W', translateDropdownLabel: true,
  },
  {
    period: DefaultPeriods.MINUS1W_PLUS1M, // last week + next month
    itemLabel: 'PERIODS.ITEM_LABELS.MINUS1W_PLUS1M', translateItemLabel: true,
    // dropdownLabel: 'PERIODS.DROPDOWN_LABELS.MINUS1W_PLUS1M', translateDropdownLabel: true
  },
  {
    period: DefaultPeriods.PLUS1W, // next week
    itemLabel: 'PERIODS.ITEM_LABELS.PLUS1W', translateItemLabel: true,
    // dropdownLabel: 'PERIODS.DROPDOWN_LABELS.PLUS1W', translateDropdownLabel: true
  },
  {
    period: DefaultPeriods.PLUS1M, //  next month
    itemLabel: 'PERIODS.ITEM_LABELS.PLUS1M', translateItemLabel: true,
    // dropdownLabel: 'PERIODS.DROPDOWN_LABELS.PLUS1M', translateDropdownLabel: true
  },
  {
    period: DefaultPeriods.PLUS3M, //  next 3 months
    itemLabel: 'PERIODS.ITEM_LABELS.PLUS3M', translateItemLabel: true,
    // dropdownLabel: 'PERIODS.DROPDOWN_LABELS.PLUS3M', translateDropdownLabel: true
  },
  {
    period: DefaultPeriods.PLUS6M, //  next 3 months
    itemLabel: 'PERIODS.ITEM_LABELS.PLUS6M', translateItemLabel: true,
    // dropdownLabel: 'PERIODS.DROPDOWN_LABELS.PLUS6M', translateDropdownLabel: true
  },
  {
    period: DefaultPeriods.PLUS1Y, //  next 3 months
    itemLabel: 'PERIODS.ITEM_LABELS.PLUS1Y', translateItemLabel: true,
    // dropdownLabel: 'PERIODS.DROPDOWN_LABELS.PLUS1Y', translateDropdownLabel: true
  },
];

export const TodayClassModifier = 'ag-cell-value--today';
export const RightAlignClassModifier = 'ag-right-aligned-cell';
export const OutdatedClassModifier = 'ag-cell-value--outdated';

export const overlayNoRowsTemplateOptions = deepFreeze({
  noData: { content: 'CALENDAR_WIDGET.GRID.NO_ROWS.NO_DATA' }, // default
  noSource: { content: 'CALENDAR_WIDGET.GRID.NO_ROWS.NO_SOURCE' },
  noCountry: { content: 'CALENDAR_WIDGET.GRID.NO_ROWS.NO_COUNTRY' },
  noCountryItems: { content: 'CALENDAR_WIDGET.GRID.NO_ROWS.NO_COUNTRY_ITEMS' },
  noWatchlist: { content: 'CALENDAR_WIDGET.GRID.NO_ROWS.NO_WATCHLIST' },
  noWatchlistItems: { content: 'CALENDAR_WIDGET.GRID.NO_ROWS.NO_WATCHLIST_ITEMS' },
  noInstrument: { content: 'CALENDAR_WIDGET.GRID.NO_ROWS.NO_INSTRUMENT' },
  noPortfolio: { content: 'CALENDAR_WIDGET.GRID.NO_ROWS.NO_PORTFOLIO' },
  noPortfolioItems: { content: 'CALENDAR_WIDGET.GRID.NO_ROWS.NO_PORTFOLIO_ITEMS' },
  noSelectedFeed: { content: 'CALENDAR_WIDGET.GRID.NO_ROWS.NO_SELECTED_FEED' },
  noAllowedFeed: { content: 'CALENDAR_WIDGET.GRID.NO_ROWS.NO_ALLOWED_FEED' },
  noPeriod: { content: 'CALENDAR_WIDGET.GRID.NO_ROWS.NO_PERIOD' },
  noValidPeriod: { content: 'CALENDAR_WIDGET.GRID.NO_ROWS.NO_VALID_PERIOD' },
} as const satisfies { [name: string]: UntranslatedNoRowsTemplate; });
