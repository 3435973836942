import { Injectable, inject } from '@angular/core';
import { DashboardFolderLevel, KnownDashboardFolderIDs } from '@infront/ngx-dashboards-fx';
import { InfrontSDK, InfrontUtil } from '@infront/sdk';
import { LogService } from '@vwd/ngx-logging';
import { BehaviorSubject, Observable, Subject, TimeoutError, type UnaryFunction, combineLatest, pipe, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, share, switchMap, take, tap, timeout, withLatestFrom } from 'rxjs/operators';

import { LastValueSubject } from '@infront/ngx-dashboards-fx/utils';
import { DashboardTabsService } from '../dashboard/providers/dashboard-tabs.provider';
import { PORTFOLIO_DASHBOARD_ID } from '../dashboard/providers/portfolio-dashboards';
import { type Dashboard, DashboardType } from '../state-model/dashboard.model';
import type { Grid, PartialGrid } from '../state-model/grid.model';
import { WidgetDefaultsMap } from '../state-model/widget.defaults';
import type { InstrumentHeaderWidget, PartialWidget, Widget, WidgetName, WidgetType } from '../state-model/widget.model';
import { DashboardWindowDefaults, WindowDefaultsMap } from '../state-model/window.defaults';
import {
  type DashboardWindow,
  type Instrument,
  type InstrumentHeaderWindow,
  NonLinkableChannels,
  type PartialDashboardWindow,
  type WindowName,
  WindowWidgetsMap,
  isInstrument,
  isInstrumentSettings
} from '../state-model/window.model';
import { structuresAreEqual } from '../util/equality';
import { newGridsByWidgets } from '../util/grid';
import { pick } from '../util/object';
import { filterUndefined } from '../util/rxjs';
import { isSameObject, newId } from '../util/utils';
import { createWtkWidgetId } from '../util/wtk';
import { RemoteStorageService } from './remote-storage.service';
import { type State, StateService, type StateType } from './state.service';
import type { ToolkitStorageData } from './toolkit-storage.component';
import { UserSettingsService } from './user-settings.service';


export const filterDashboard = (dashboard: Dashboard): boolean =>
  [DashboardType.dashboard, DashboardType.template].includes(dashboard.type);

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private readonly stateService = inject(StateService);
  private readonly userSettingsService = inject(UserSettingsService);
  private readonly remoteStorageService = inject(RemoteStorageService);
  private readonly dashboardTabsService = inject(DashboardTabsService);
  private readonly logger = inject(LogService).openLogger('services/store');

  // todo: remove this
  private get state() {
    return this.stateService.state;
  }

  private readonly isStateLoadedAction = new BehaviorSubject<boolean>(false);

  private isHiddenStateMode = false;

  private readonly windowChangeAction = new Subject<[DashboardWindow, PartialDashboardWindow]>();

  private readonly selectedDashboardAction = new LastValueSubject<string>();

  //private widgetChangeAction = new Subject<PartialWidget>(); // todo: not used yet, activate if needed or find a way around extra subjects for window and widget changes
  // by detecting which specific window or widget has changed from a windows or widgets state change

  // todo: add state to params

  // state helper methods
  private windowById = (dashboardId: string, id: string) => this.state.windows.find((window) => window.id === id && window.dashboardId === dashboardId);

  windowByWidget = <TWindow extends DashboardWindow>(widget: Widget): TWindow | undefined => {
    if (!widget) {
      return undefined;
    }
    return this.state.windows.find((window: DashboardWindow) => window.id === widget.windowId && window.dashboardId === widget.dashboardId) as TWindow | undefined;
  };

  private widgetByWindowIdAndWidgetName = (inWindow: DashboardWindow, widgetName: WidgetName): Widget | undefined => {
    return this.state.widgets.find((widget) => widget.name === widgetName && widget.windowId === inWindow.id && widget.dashboardId === inWindow.dashboardId);
  };

  private gridByWidget = (inWidget: Widget): Grid | undefined => {
    const widget = this.state.widgets.find((w) => w.id === inWidget.id && w.dashboardId === inWidget.dashboardId);
    if (!widget) {
      return undefined;
    }
    const grids = this.state.grids.filter((grid) => grid.parentId === widget.id && grid.dashboardId === inWidget.dashboardId);
    if (grids.length === 1) {
      return grids[0];
    }
    // todo: widget settings type needs revisiting
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return grids.find((grid) => !!(widget.settings as any)?.['selectedGrid'] && grid.name === (widget.settings as any)?.['selectedGrid']);
  };

  filterWidgetsByWindows = (windows: DashboardWindow[]): Widget[] => {
    return this.state.widgets.filter((widget) => windows.some(window => widget.windowId === window.id && widget.dashboardId === window.dashboardId));
  };

  filterGridsByWidgets = (widgets: Widget[]): Grid[] => {
    return this.state.grids.filter((grid) => (grid.parentId ? widgets.some(widget => grid.parentId === widget.id && grid.dashboardId === widget.dashboardId) : false));
  };

  private filterWindowByDashboard = (dashboard: Dashboard | Dashboard[]): DashboardWindow[] => {
    if (Array.isArray(dashboard)) {
      const dashboardIds = dashboard.map(d => d.id);
      return this.state.windows.filter((window) => dashboardIds.includes(window.dashboardId));
    }
    return this.state.windows.filter((window) => window.dashboardId === dashboard.id);
  };

  private updateState = (partialState: Partial<State>, stateType: StateType = this.isHiddenStateMode ? 'Hidden' : 'User') => {
    this.logger.debug('updateState', { partialState, stateType });
    this.stateService.setState(partialState, stateType);
  };

  private getFirstDashboard = (ignoreHidden = true): Dashboard | undefined => (
    ignoreHidden
      ? this.state.dashboards?.find((d) => d.parentId === KnownDashboardFolderIDs.PERSONAL && !d.hidden)
      : this.state.dashboards?.find((d) => d.parentId === KnownDashboardFolderIDs.PERSONAL)
  );

  // ugly workaround to make sure that state.dashboards are available for identifying invalid ids,
  // for which no dashboards exists (e.g. on browser reload, while instrument dashboard is selected).
  // the two storage watches are crucial for the whole state-driven flow, we need to be careful here!
  // hope this can be simplified if state logic has been replaced by dashboard/widget-services!
  private stateDashboards$ = this.stateService
    .selectWhen((state) => state.dashboards, (state) => state.dashboardsReady)
    .pipe(
      tap(() => {
        if (this.state.stateType === 'Initial') {
          this.isStateLoadedAction.next(true);
        }
      }),
      filter((dashboards) => !!dashboards?.length),
      take(1),
      switchMap(() =>
        this.userSettingsService.getValue$('dashboardSelectedDashboardId'),
      )
    );

  // todo: we could probably do the store generic with add, update, delete on the entity level
  constructor() {
    // this is safe, called once, subscribe as long as the app runs
    this.sideEffects$.subscribe();
    this.stateDashboards$.subscribe();
    this.userSettingsService.getValue$('dashboardSelectedDashboardId').pipe(
      take(1),
      tap((id) => this.selectedDashboardAction.next(id)),
    ).subscribe();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)['infrontApp'] ??= {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)['infrontApp'].store = this;
  }

  // dashboard add, update, delete
  addDashboard(name: string): Observable<void> {
    const dashboard: Dashboard = {
      id: newId(),
      name: name,
      type: DashboardType.dashboard,
      level: DashboardFolderLevel.PERSONAL,
      index: this.findNextDashboardIndex(),
      parentId: KnownDashboardFolderIDs.PERSONAL,
      locked: false,
      readonly: false,
      canClose: false,
      canDelete: true,
      canRename: true,
    };

    return this.remoteStorageService.addDashboard(dashboard, true, {} as State).pipe(
      switchMap(() => this.dashboards$),
      waitForDashboard(dashboard.id),
      tap((dashboard) => {
        this.selectDashboard(dashboard);
      }),
      map(() => undefined),
      catchError((error) => {
        this.logger.fail('Could not create dashboard.', error);
        return new Observable<void>();
      }),
    );
  }
  openDashboard(id: string): Observable<void> {
    return this.dashboards$.pipe(
      waitForDashboard(id),
      tap((dashboard) => {
        this.selectDashboard(dashboard);
      }),
      map(() => undefined),
      catchError((error) => {
        this.logger.fail('Could not create dashboard.', error);
        return new Observable<void>();
      }),
    );
  }


  findNextDashboardIndex(): number {
    // Regular dashboards are sorted first
    return Math.max(...this.state.dashboards?.filter(filterDashboard)?.map((dashboard) => dashboard.index ?? 0) ?? []) + 1;
  }

  addInstrumentDashboardByClassification(instrument: Instrument, classification: InfrontSDK.SymbolClassification): void {
    this.remoteStorageService.addInstrumentDashboard(instrument, classification).pipe(
      switchMap((dashboard) => this.stateService.select((state) => state.dashboards).pipe(
        waitForDashboard(dashboard.model.id),
      )),
    ).subscribe({
      next: (dashboard) => {
        this.logger.log(`Set instrumentDashboardId to ${dashboard.id}`, dashboard);
        this.userSettingsService.setValue('instrumentDashboardId', dashboard.id);
        this.selectDashboard(dashboard);
      },
      error: (error) => {
        this.logger.fail('Could not create dashboard.', error);
      },
    });
  }

  cloneDashboard(dashboard: Dashboard, newName?: string, parentId?: string): void {
    if (!dashboard) {
      const current = this.currentDashboard;
      if (current) {
        dashboard = current;
      } else {
        throw new Error('cloneDashboard: No current dashboard selected.');
      }
    }

    const sourceWindows = this.filterWindowByDashboard(dashboard);
    const sourceWidgets = this.filterWidgetsByWindows(sourceWindows);
    const sourceGrids = this.filterGridsByWidgets(sourceWidgets);

    const newIndex = this.findNextDashboardIndex();
    const newDashboardId = newId();
    const dashboardCopy: Dashboard = {
      id: newDashboardId,
      index: newIndex,
      name: newName ?? `${dashboard.name} - copy`,
      parentId: parentId ?? KnownDashboardFolderIDs.PERSONAL,
      type: dashboard.type === DashboardType.instrument ? DashboardType.template : dashboard.type,
      hidden: false,
      locked: false,
      readonly: false,
      canRename: true,
      canClose: !parentId || parentId === (KnownDashboardFolderIDs.PERSONAL as string),
      canDelete: true,
      instrument: undefined,
      level: DashboardFolderLevel.PERSONAL, // will fix up later
    };

    const windowsCopy = sourceWindows.map((window) => {
      const defaults = pick(WindowDefaultsMap[window.name],
        'maxItemRows',
        'minItemRows',
        'maxItemCols',
        'minItemCols',
        'canSetLinkedInstrument'
      );

      const windowClone = InfrontUtil.deepCopy(window) as DashboardWindow;
      if (windowClone.name !== 'InstrumentHeaderWindow') {
        Object.assign(windowClone, {
          dashboardId: newDashboardId,
          dragEnabled: true,
          resizeEnabled: true,
          ...defaults
        } as Partial<DashboardWindow>);
      } else {
        windowClone.dashboardId = newDashboardId;
      }

      return windowClone;
    });

    const widgetsCopy = sourceWidgets.map((widget) => {
      const widgetClone = InfrontUtil.deepCopy(widget) as Widget;
      widgetClone.dashboardId = newDashboardId;
      return widgetClone;
    });

    const gridsCopy = sourceGrids.map((grid) => {
      const gridClone = InfrontUtil.deepCopy(grid) as Grid;
      gridClone.dashboardId = newDashboardId;
      return gridClone;
    });

    const isolatedStateUpdate: State = {
      dashboards: [dashboardCopy],
      windows: windowsCopy,
      widgets: widgetsCopy,
      grids: gridsCopy,
      dashboardsReady: this.state.dashboardsReady,
    };

    this.remoteStorageService.addDashboard(dashboardCopy, true, isolatedStateUpdate).pipe(
      tap(() => this.dashboardTabsService.addTab(newDashboardId)),
      switchMap((dashboardRef) => this.dashboards$.pipe(waitForDashboard(newDashboardId)).pipe(
        map((dashboard) => ({ dashboardRef, dashboard })),
      )),
    ).subscribe({
      next: ({ dashboardRef, dashboard }) => {
        // Ensure state is updated with the cloned windows/widgets
        const newState: Partial<State> = {
          windows: [...this.state.windows.filter(d => d.dashboardId !== newDashboardId) ?? [], ...windowsCopy],
          widgets: [...this.state.widgets.filter(d => d.dashboardId !== newDashboardId) ?? [], ...widgetsCopy],
          grids: [...this.state.grids.filter(d => d.dashboardId !== newDashboardId) ?? [], ...gridsCopy],
        };

        if (dashboardRef.model.level !== (dashboard.level as number)) {
          newState.dashboards = this.state.dashboards.map(d =>
            d.id === newDashboardId ? { ...d, level: dashboardRef.model.level } : d);
        }

        this.updateState(newState);

        if (!dashboardCopy.hidden) {
          this.selectDashboard(dashboardCopy);
        }
      },
      error: (error) => {
        this.logger.fail('Could not create dashboard.', error);
      },
    });
  }

  updateDashboard(dashboard: Dashboard): void {
    this.remoteStorageService.updateDashboard(dashboard).subscribe({
      error: (error) => {
        this.logger.fail('Could not update dashboard.', error);
      },
    });
  }

  updateDashboards(dashboards: Array<Dashboard>): void {
    this.updateState({ dashboards });
    this.remoteStorageService.updateDashboards(dashboards).subscribe({
      error: (error) => {
        this.logger.fail('Could not update dashboards.', error);
      },
    });
  }

  deleteDashboardByType(type: DashboardType): void {
    const dashboards = this.state.dashboards.filter((db) => ![DashboardType.dashboard, DashboardType.template].includes(type) && db.type === type);
    dashboards.forEach((db) => this.deleteDashboard(db));
  }

  deleteDashboard(inDashboard: Dashboard): void {
    this.remoteStorageService.deleteDashboard(inDashboard).subscribe({
      next: () => {
        const dashboards = this.state.dashboards.filter((db) => db.id !== inDashboard.id);
        const windows = this.state.windows.filter((w) => w.dashboardId !== inDashboard.id);
        const widgets = this.filterWidgetsByWindows(windows);
        const grids = this.filterGridsByWidgets(widgets);

        this.updateState({
          windows,
          widgets,
          dashboards,
          grids,
        });

        // renumber indexes after deleting
        if (!inDashboard.hidden && filterDashboard(inDashboard)) {
          let hasChanges = false;
          const dashboardsToUpdate = dashboards.map((dashboard, index) => {
            if (dashboard.index !== index) {
              hasChanges = true;
              return { ...dashboard, index };
            }
            return dashboard;
          });
          if (hasChanges) {
            this.updateDashboards(dashboardsToUpdate);
          }
        }

        const selectedDashboardId = this.userSettingsService.getValue('dashboardSelectedDashboardId');
        if (selectedDashboardId !== inDashboard.id) {
          return;
        }
        const firstDashboard = this.getFirstDashboard();
        if (firstDashboard) {
          this.selectDashboard(firstDashboard);
        }
      },
      error: (error) => {
        this.logger.fail('Could not delete dashboard.', error);
      }
    });
  }

  resetDashboards(): void {
    this.remoteStorageService.resetDashboards().subscribe(() => {
      location.reload();
    });
  }

  selectDashboard(selectedDashboard: Dashboard): void {
    this.logger.debug('selectDashboard', selectedDashboard);
    const dashboardId = selectedDashboard?.id ?? '';
    this.userSettingsService.setValue('dashboardSelectedDashboardId', dashboardId);
    this.selectedDashboardAction.next(dashboardId);
  }

  addWindow(initialWindow: Partial<DashboardWindow> & { name: WindowName }, centerOffsets: { left: number; top: number }): void {
    this.logger.debug('addWindow', { initialWindow, centerOffsets });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const windowId = initialWindow.id ?? newId();
    const dashboardId = initialWindow.dashboardId ?? this.currentDashboard?.id;

    if (!dashboardId) {
      throw new Error(`Cannot determine current dashboard.`);
    }

    const calculatedProps = {
      dashboardId,
      id: windowId,
      created: Date.now(),
      layerIndex: 2,
      x: Math.max(centerOffsets.left, 0),
      y: Math.max(centerOffsets.top, 5),
    };

    const newWindow = {
      ...InfrontUtil.deepCopy(DashboardWindowDefaults), // generic defaults
      ...InfrontUtil.deepCopy(WindowDefaultsMap[initialWindow.name]), // specific defaults
      ...InfrontUtil.deepCopy(initialWindow), // defaults created by spawner of window
      ...calculatedProps,
    } as DashboardWindow;

    if (newWindow.selectedWidgetName == undefined) {
      newWindow.selectedWidgetName = WindowWidgetsMap[initialWindow.name][0];
    }

    const instrumentHeaderWindow = this.state.windows.find(w => w.dashboardId === dashboardId && w.name === 'InstrumentHeaderWindow') as InstrumentHeaderWindow | null;
    if (instrumentHeaderWindow) {
      const instrumentHeaderWidget = this.widgetByWindowIdAndWidgetName(instrumentHeaderWindow, 'InstrumentHeader') as InstrumentHeaderWidget | null;
      if (instrumentHeaderWidget) {
        newWindow.tag = instrumentHeaderWidget.settings?.selectedTab;
      }
    }

    const windowWidgets = WindowWidgetsMap[initialWindow.name].map((widgetName: WidgetName) => {
      if (!(widgetName in WidgetDefaultsMap)) {
        throw new Error(`WidgetDefaultsMap is missing a default definition for widget "${widgetName}"`);
      }
      return WidgetDefaultsMap[widgetName];
    });
    const newWidgets = windowWidgets.map((w) => {
      return {
        ...InfrontUtil.deepCopy(w),
        windowId,
        dashboardId,
        id: newId(),
      } as Widget;
    });

    this.applyWidgetSettingsOverridesByWindow(newWindow, newWidgets);

    const windows = [...this.state.windows, newWindow];
    const widgets = [...this.state.widgets, ...newWidgets];
    const grids = [...this.state.grids, ...newGridsByWidgets(newWidgets)];

    // no better option found than using a flag for this since any state change could be hidden as long as the user changes any state while in the dialog
    this.isHiddenStateMode = true;
    this.updateState({ windows, widgets, grids });
  }

  cloneWindow(sourceWindow: DashboardWindow, centerOffsets: { left: number; top: number }): void {
    this.logger.debug('cloneWindow', { sourceWindow, centerOffsets });
    if (!sourceWindow) {
      throw new Error('cloneWindow: No source-window provided.');
    }

    const dashboardId = sourceWindow.dashboardId ?? this.currentDashboard?.id;
    if (!dashboardId) {
      throw new Error(`Cannot determine current dashboard.`);
    }

    const sourceWidgets = this.filterWidgetsByWindows([sourceWindow]);
    const sourceGrids = this.filterGridsByWidgets(sourceWidgets);

    const newWindowId = newId();
    const windowCopy = [sourceWindow].map((window) => {
      const defaults = pick(WindowDefaultsMap[window.name],
        'maxItemRows',
        'minItemRows',
        'maxItemCols',
        'minItemCols',
        'canSetLinkedInstrument'
      );

      const windowClone = InfrontUtil.deepCopy(window) as DashboardWindow;
      Object.assign(windowClone, {
        id: newWindowId,
        dashboardId,
        created: Date.now(),
        dragEnabled: true, // FIXME: why is this required?
        resizeEnabled: true, // FIXME: why is this required?
        x: Math.max(centerOffsets.left, 0),
        y: Math.max(centerOffsets.top, 5),
        layerIndex: 2,
        ...defaults
      } as Partial<DashboardWindow>);

      return windowClone;
    });

    const widgetIdMap: { [oldId: string]: string } = {};
    const widgetsCopy = sourceWidgets.map((widget) => {
      widgetIdMap[widget.id] = newId();
      const widgetClone = InfrontUtil.deepCopy(widget) as Widget;
      widgetClone.id = widgetIdMap[widget.id];
      widgetClone.windowId = newWindowId;
      widgetClone.dashboardId = dashboardId;

      // while the toolkitSettings of the new widget will get saved correctly to "dashboard-data",
      // we have to update the internal toolkitStorageCache of toolkit-storage-service by ourselves
      if (widgetClone.toolkitSettings) {
        const storageData: ToolkitStorageData = {};
        // re-map the toolkitSetting to wtkWidgetId-keys, before storing to toolkit-storage
        for (const [oldKey, value] of Object.entries(widgetClone.toolkitSettings)) {
          const wtkWidgetId = createWtkWidgetId(widgetClone, oldKey);
          storageData[wtkWidgetId] = value;
        }
        this.remoteStorageService.updateToolkitStorage(storageData);
      }

      return widgetClone;
    });

    const gridsCopy: Grid[] = sourceGrids.map((grid) => {
      const gridClone = InfrontUtil.deepCopy(grid) as Grid;
      gridClone.id = newId();
      gridClone.parentId = widgetIdMap[grid.parentId!];
      gridClone.dashboardId = dashboardId;
      return gridClone;
    });

    const newState: Partial<State> = {
      windows: [...this.state.windows ?? [], ...windowCopy],
      widgets: [...this.state.widgets ?? [], ...widgetsCopy],
      grids: [...this.state.grids ?? [], ...gridsCopy],
    };

    // no better option found than using a flag for this since any state change could be hidden as long as the user changes any state while in the dialog
    this.isHiddenStateMode = true;
    this.updateState(newState);
  }

  private applyWidgetSettingsOverridesByWindow(window: DashboardWindow, widgets: Widget[]): void {
    const widgetSettingsOverrides = window?.widgetSettingsOverrides;

    if (!widgetSettingsOverrides) { return; }

    widgets.forEach((widget) => {
      const overrides = widgetSettingsOverrides[widget.name];
      if (typeof overrides !== 'object') {
        return;
      }

      const overrideEntries = Object.entries(overrides);
      if (!overrideEntries.length) {
        return;
      }

      const widgetSettings = widget.settings ??= {};
      overrideEntries.forEach(([settingsKey, settingsValue]) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
        (widgetSettings as any)[settingsKey] = settingsValue;
      });
    });
  }

  placeWindow(inWindow: DashboardWindow, partialWindow: PartialDashboardWindow): void {
    this.logger.debug('placeWindow', { inWindow, partialWindow });
    const isNewWindow = this.isHiddenStateMode; // todo: better way to detect this;
    this.isHiddenStateMode = false;
    if (isNewWindow) {
      this.updateState({ widgets: this.state.widgets, grids: this.state.grids }); // since we are not registering hidden states we need to update widgets and grids here after a new window has been placed
    }
    this.updateWindow(inWindow, partialWindow);
  }

  // window update, delete

  updateWindow(inWindow: DashboardWindow, partialWindow: PartialDashboardWindow): void {
    this.logger.debug('updateWindow', { inWindow, partialWindow });
    const [windows, index] = this.windowsToUpdate(inWindow, partialWindow);
    if (index == -1) return; // no change
    this.updateState({ windows });
    const updatedWindow = this.windowById(inWindow.dashboardId, inWindow.id);
    this.windowChangeAction.next([updatedWindow!, partialWindow]);
  }

  updateWindowAsSideEffect(inWindow: DashboardWindow, partialWindow: PartialDashboardWindow): void {
    const [windows, index] = this.windowsToUpdate(inWindow, partialWindow);
    if (index == -1) return; // no change
    this.updateState({ windows }, 'Trigger');
  }

  private windowsToUpdate(inWindow: DashboardWindow, partialWindow: PartialDashboardWindow): [Array<DashboardWindow>, number] {
    let updatedIndex = -1;
    const windows = this.state.windows.map((existingWindow, index) => {
      if (existingWindow.id === inWindow.id && existingWindow.dashboardId === inWindow.dashboardId) {
        const result = { ...existingWindow, ...partialWindow } as DashboardWindow;
        if (!structuresAreEqual(result, existingWindow)) {
          updatedIndex = index;
          return result;
        }
      }
      return existingWindow;
    });
    return [windows, updatedIndex];
  }

  /**
   * Deletes the window by doing an updateState without the deleted window
   * @param windowId The id of the window to be deleted
   */
  deleteWindow(inWindow: DashboardWindow): void {
    const windows = this.state.windows.filter((window) => !(window.id === inWindow.id && window.dashboardId === inWindow.dashboardId));
    const widgets = this.filterWidgetsByWindows(windows);
    const grids = this.filterGridsByWidgets(widgets);
    // const toBeRemovedWidgets = this.filterWidgetsByWindows([windowId]);
    this.updateState({ windows, widgets, grids });
  }

  // widget update - widgets can not be added or deleted separately, only as belonging to dashboardWindows

  updateWidget(inWidget: Widget, partialWidget: PartialWidget): void {
    this.logger.debug('updateWidget', { inWidget, partialWidget });
    const widgets = this.state.widgets.map((existingWidget) =>
      existingWidget.id === inWidget.id && existingWidget.dashboardId === inWidget.dashboardId ? ({ ...existingWidget, ...partialWidget } as Widget) : existingWidget
    );
    this.updateState({ widgets: widgets ?? [] });
  }

  updateWindowAndWidget(inWidget: Widget, partialWindow: PartialDashboardWindow, partialWidget: PartialWidget): void {
    this.logger.debug('updateWindowAndWidget', { inWidget, partialWindow, partialWidget });
    const oldWindow = this.windowById(inWidget.dashboardId, inWidget.windowId);
    if (!oldWindow) {
      this.logger.error(`Cannot find window ${inWidget.windowId}`);
      return;
    }
    const widgets = this.state.widgets.map((existingWidget) =>
      existingWidget.id === inWidget.id && existingWidget.dashboardId === inWidget.dashboardId ? ({ ...existingWidget, ...partialWidget } as Widget) : existingWidget
    );
    const [windows, index] = this.windowsToUpdate(oldWindow, partialWindow);
    if (index == -1) {
      this.updateState({ widgets });
    } else {
      this.updateState({ widgets, windows });
      const updatedWindow = this.windowById(oldWindow.dashboardId, oldWindow.id);
      this.windowChangeAction.next([updatedWindow!, partialWindow]);
    }
  }

  // todo: potential for generics, this is very similar to updateWidget
  updateGrid(inGrid: Grid, grid: PartialGrid): void {
    const grids = this.state.grids.map((existingGrid) => (existingGrid.id === inGrid.id && existingGrid.dashboardId === inGrid.dashboardId ? ({ ...existingGrid, ...grid } as Grid) : existingGrid));
    this.updateState({ grids: grids ?? [] });
  }

  closeAllModals(): void {
    const windows = this.state.windows.filter((window) => window.layerIndex === 2);
    windows.forEach((window) => {
      this.deleteWindow(window);
    });
    this.closeAllModalsAction.next();
  }

  private closeAllModalsAction = new Subject<void>();
  closeAllModals$ = this.closeAllModalsAction.asObservable();


  // addGrid(grid: Grid): void {
  //   const grids = [...this.state.grids, grid];
  //   this.updateState({ grids });
  // }

  //read from state functions - observables

  currentNonState$ = this.userSettingsService.getValue$('tradingSelectedPortfolioId').pipe(map((selectedPortfolioId) => ({ selectedPortfolioId })));

  // currentState$ needs to emit "changes" also for the non-state observables changed(Sub)DashboardId!
  currentState$ = combineLatest([this.stateService.select((state) => state), this.currentNonState$]).pipe(map(([state]) => state));

  unfilteredDashboards$ = this.stateService.select(() => this.state.dashboards);

  dashboards$ = this.stateService.selectWhen(() => this.state.dashboards.filter((dashboard) => filterDashboard(dashboard)), (state) => state.dashboardsReady).pipe(
    distinctUntilChanged(),
  );

  portfolioDashboard$ = this.stateService
    .selectWhen(() => {
      return this.state.dashboards.filter((dashboard) => dashboard.id === PORTFOLIO_DASHBOARD_ID);
    }, (state) => state.dashboardsReady)
    .pipe(
      map((arr) => (arr.length ? arr[0] : undefined)),
      distinctUntilChanged(),
    );

  // do not call directly to avoid circular dependency
  instrumentDashboard$ = combineLatest([
    this.stateService.selectWhen(() => this.state.dashboards.filter((dashboard) => dashboard.type === DashboardType.instrument), (state) => state.dashboardsReady),
    this.userSettingsService.getValue$('instrumentDashboardId'),
  ]).pipe(
    map(([instrumentDashboards, instrumentDashboardId]) => instrumentDashboards.find(d => d.id === instrumentDashboardId)),
    distinctUntilChanged(),
  );

  currentDashboard$ = combineLatest([this.currentState$.pipe(
    tap((value) => {
      this.logger.info('currentDashboard currentState$', value);
    })
  ), this.selectedDashboardAction.pipe(
    tap((value) => {
      this.logger.info('currentDashboard selectedDashboardAction', value);
    })
  )]).pipe(
    tap(() => {
      this.logger.trace('currentDashboard$ trace');
    }),
    withLatestFrom(this.dashboards$, this.isStateLoadedAction),
    filter(([[state]]) => state.dashboards?.length > 0),
    map(() => this.getCurrentDashboard(true)),
    distinctUntilChanged(),
    tap((selectedDashboard) => this.logger.debug(`currentDashboard$`, selectedDashboard)),
  );



  currentWindows$ = combineLatest([this.currentState$, this.selectedDashboardAction, this.dashboards$]).pipe(
    map(() => {
      const compareToDashboardId = this.currentDashboard?.id;
      const currentWindows = this.state.windows.filter((window) => window.dashboardId === compareToDashboardId);
      return currentWindows;
    }),
    distinctUntilChanged(),
  );

  currentGrids$ = this.currentDashboard$.pipe(
    map((db) => {
      const grids = this.state.grids.filter((grid) => grid.dashboardId === db?.id);
      return grids;
    }),
    distinctUntilChanged(),
  );

  // bundled for convenience since needed in several places
  currentWindowsAndDashboardType$ = this.currentWindows$.pipe(switchMap((windows: DashboardWindow[]) =>
    this.currentDashboard$.pipe(
      filterUndefined(),
      map((selectedDashboard) => ({ windows, type: selectedDashboard.type })
      ))));




  get currentDashboard(): Dashboard | undefined {
    return this.getCurrentDashboard(true);
  }

  private getCurrentDashboard(asumeLoaded: boolean): Dashboard | undefined {
    const selectedDashboardId = this.selectedDashboardAction.value;
    if (selectedDashboardId) {
      let dashboard = this.state.dashboards?.find((dashboard) => dashboard.id === selectedDashboardId);
      if (!dashboard) {
        this.logger.trace('getCurrentDashboard$ trace');
        this.logger.warn(`DashboardSelectedDashboardId ${selectedDashboardId} was not found.`);
        dashboard = this.state.dashboards?.[0];
      }

      if (dashboard) {
        if (dashboard.id !== selectedDashboardId && asumeLoaded) {
          this.logger.warn(`Changing dashboardSelectedDashboardId to ${dashboard.id} because ${selectedDashboardId} was not found.`);
          this.selectDashboard(dashboard);
        }
        return dashboard;
      }
      this.logger.warn(`DashboardSelectedDashboardId ${selectedDashboardId} was not found, and no initial dashboard was found either.`);
    }
    return this.state.dashboards?.[0];
  }

  window$ = (inWindow: DashboardWindow): Observable<DashboardWindow> => {
    if (!inWindow) {
      throw new TypeError(`window$() expected a window instance.`);
    }
    return this.stateService.select(() => this.windowById(inWindow.dashboardId, inWindow.id)).pipe(
      distinctUntilChanged(),
      filterUndefined<DashboardWindow>(),
    );
  };

  window = (inWindow: DashboardWindow): DashboardWindow | undefined => {
    if (!inWindow) {
      throw new TypeError(`window() expected a window instance.`);
    }
    return this.windowById(inWindow.dashboardId, inWindow.id);
  };

  widget$ = <T extends Widget>(inWidget: T): Observable<T> => {
    if (!inWidget) {
      throw new TypeError(`widget$() expected a widget instance.`);
    }
    return this.stateService
      .select(() => this.state.widgets.find((widget) => widget && widget.id === inWidget.id && widget.dashboardId === inWidget.dashboardId))
      .pipe(
        filterUndefined(),
        distinctUntilChanged((prev, next) => isSameObject(prev?.settings, next?.settings)),
        map((widget) => widget as T),
        share()
      );
  };

  widgetsByName$ = <T extends WidgetName>(
    name: T
  ) => this.stateService.select(() => this.state.widgets.filter((widget) => widget.name === name) as Extract<WidgetType, { name: T }>[]);

  widget = (inWidget: Widget): Widget | undefined => {
    if (!inWidget) {
      throw new TypeError(`widget() expected a widget instance.`);
    }
    return this.stateService.state.widgets.find((widget) => widget && widget.id === inWidget.id && widget.dashboardId === inWidget.dashboardId);
  };

  windowByWidget$ = <TWindow extends DashboardWindow>(widget: Widget): Observable<TWindow> => {
    if (!widget) {
      throw new TypeError(`windowByWidget$() expected a widget instance.`);
    }
    return this.stateService.select(() => this.windowByWidget<TWindow>(widget)).pipe(filterUndefined());
  };

  gridByWidget$ = (widget: Widget): Observable<Grid | undefined> => {
    if (!widget) {
      throw new TypeError(`gridByWidget$() expected a widget instance.`);
    }
    return this.stateService.select(() => {
      return this.gridByWidget(widget);
    });
  };

  selectedWidgetInWindow$ = <TWidget = Widget>(inWindow: DashboardWindow): Observable<TWidget> => {
    if (!inWindow) {
      throw new TypeError(`selectedWidgetInWindow$ expected a window instance.`);
    }
    return this.window$(inWindow).pipe(
      map((window) => this.widgetByWindowIdAndWidgetName(window, window.selectedWidgetName) as TWidget),
      filterUndefined<TWidget>()
    );
  };

  widgetByWindowIdAndWidgetName$ = (inWindow: DashboardWindow, widgetName: WidgetName): Observable<Widget> => {
    if (!inWindow) {
      throw new TypeError(`widgetByWindowIdAndWidgetName$ expected a window instance.`);
    }
    return this.stateService.select(() => this.widgetByWindowIdAndWidgetName(inWindow, widgetName)).pipe(filterUndefined<Widget>());
  };


  dashboardInstrument$ = this.currentDashboard$.pipe(
    map((dashboard) => dashboard ? this.getDashboardInstrument(dashboard) : undefined)
  );

  getDashboardInstrument(dashboard: Dashboard): Instrument | undefined {
    return dashboard && dashboard.type === DashboardType.instrument && isInstrument(dashboard.instrument) ? dashboard.instrument : undefined;
  }

  // add any store related side effect here

  // when a linked windows instrument is changed it should update the selected instrument to all other linked windows
  private onLinkedInstrumentChange$ = this.windowChangeAction.pipe(
    filterUndefined(),
    filter(([_, partialWindow]) => isInstrumentSettings(partialWindow.settings)),
    tap(([window]) => {
      if (!window) {
        return;
      }
      this.state.windows
        .filter((w) => isInstrumentSettings(w?.settings))
        .forEach((w) => {
          if (w.dashboardId === window.dashboardId && isInstrumentSettings(window.settings) && !NonLinkableChannels.includes(w.linkChannel) && w.linkChannel === window.linkChannel) {
            this.updateWindowAsSideEffect(w, {
              settings: {
                ...w.settings,
                ...{ instrument: window.settings.instrument },
              },
            });
          }
        });
    })
  );

  private sideEffects$ = combineLatest([this.onLinkedInstrumentChange$]);

  store$ = combineLatest([this.dashboards$, this.instrumentDashboard$, this.currentWindows$]).pipe(
    map(([dashboards, instrumentDashboard, currentWindows]) => ({
      dashboards,
      instrumentDashboard,
      currentWindows,
    }))
  );
}

function waitForDashboard(id: string): UnaryFunction<Observable<Dashboard[]>, Observable<Dashboard>> {
  return pipe(
    map((dashboards: Dashboard[]) => dashboards.find((d) => d.id === id)),
    filterUndefined(),
    take(1),
    timeout({
      first: 10_000,
      with: (info) => {
        const error = new Error(`Cannot find dashboard ${id}`);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error as any)['info'] = info;
        return throwError(() => error as TimeoutError);
      }
    }),
  );
}
