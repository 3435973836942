import { type Observable, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { arraysAreEqual } from '../../internal/utils/equality';
import { KnownDashboardFolderIDs } from '../constants';
import { DashboardFolderLevel, type DashboardFolderModel, type DashboardModel, type DashboardModelCreate, type DashboardModelUpdate, DashboardType } from '../../models/dashboard.models';
import { type DashboardGetChildrenContext, DashboardProvider } from '../dashboard.provider';
import { Injectable } from '@angular/core';

const PREDEFINED_DASHBOARDS: DashboardModel[] = [
  {
    id: KnownDashboardFolderIDs.ROOT,
    name: 'Root',
    type: DashboardType.FOLDER,
    attributes: {},
    index: 0,
    hidden: false,
    isLink: false,
    security: {
      canEdit: false,
      canAddChildren: false,
      canDeleteChildren: false,
      canMove: false,
      canDelete: false,
    },
    level: DashboardFolderLevel.ROOT,
    childrenLoadState: 'loaded',
  }
];

@Injectable({ providedIn: 'root' })
export class BuiltInDashboardProvider extends DashboardProvider {

  public override getDashboards(): Observable<DashboardModel[]> {
    return of(PREDEFINED_DASHBOARDS);
  }

  public override create(parent: DashboardFolderModel, data: DashboardModelCreate): Observable<DashboardModel> {
    throw new TypeError('Built-in dashboards cannot be created.');
  }

  public override delete(id: string): Observable<void> {
    throw new TypeError('Built-in dashboards cannot be deleted.');
  }

  public override update(id: string, changes: DashboardModelUpdate): Observable<DashboardModel> {
    throw new TypeError('Built-in dashboards cannot be updated.');
  }

  public override getChildren(context: DashboardGetChildrenContext): Observable<readonly DashboardModel[]> {
    if (context.node.id === KnownDashboardFolderIDs.ROOT) {
      return context.models$.pipe(
        map(models => models.filter(m =>
          m.id !== KnownDashboardFolderIDs.ROOT
          && (m.parentId == null || m.parentId === KnownDashboardFolderIDs.ROOT))),
        distinctUntilChanged(arraysAreEqual),
        map(models => models.sorted(context.comparer)),
      );
    }
    return super.getChildren(context);
  }
}
