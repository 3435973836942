import type { MarketOverviewTemplate } from '../market-overview.model';

export const TemplateSouthAfrica: MarketOverviewTemplate[] = [
  {
    tab: 'Indices',
    instrumentList: [
      { feed: 6460, ticker: 'J200' },
      { feed: 6460, ticker: 'J203' },
      { feed: 6460, ticker: 'J210' },
      { feed: 6460, ticker: 'J211' },
      { feed: 6460, ticker: 'J212' },
      { feed: 6460, ticker: 'JA30' },
      { feed: 6630, ticker: 'ALSIFUT1' },
      { feed: 2088, ticker: 'SPEURO' },
      { feed: 2161, ticker: 'SX5E' },
      { feed: 2098, ticker: 'DAX' },
      { feed: 2302, ticker: 'BUK100P' },
      { feed: 2088, ticker: 'SP500' },
      { feed: 20, ticker: 'DJI' },
      { feed: 2087, ticker: 'NDX' },
      { feed: 2087, ticker: 'COMP' },
      { feed: 21, ticker: 'NQFUT1' },
      { feed: 21, ticker: 'ESFUT1' },
      { feed: 2198, ticker: 'HSI' },
      { feed: 6150, ticker: 'N225' },
      { feed: 6330, ticker: 'VIX' },
    ],
  },
  {
    tab: 'Forex',
    instrumentList: [
      { feed: 12, ticker: 'USDZAR' },
      { feed: 12, ticker: 'EURZAR' },
      { feed: 12, ticker: 'CHFZAR' },
      { feed: 12, ticker: 'JPYZAR' },
      { feed: 12, ticker: 'GBPZAR' },
      { feed: 12, ticker: 'GBPUSD' },
      { feed: 12, ticker: 'USDCHF' },
      { feed: 12, ticker: 'USDEUR' },
      { feed: 12, ticker: 'USDJPY' },
      { feed: 12, ticker: 'EURUSD' },
    ],
  },
  {
    tab: 'FixedIncome',
    instrumentList: [
      { feed: 5075, ticker: 'JIBAR3M' },
      { feed: 5075, ticker: 'JIBAR12M' },
      { feed: 2206, ticker: 'EDFUT1' },
      { feed: 2280, ticker: 'IFUT1' },
      { feed: 2227, ticker: 'TMBMKDE-05Y' },
      { feed: 2227, ticker: 'TMBMKDE-10Y' },
      { feed: 2158, ticker: 'BMDE-10Y' },
      { feed: 2158, ticker: 'BMDE-30Y' },
      { feed: 2158, ticker: 'BMUS-10Y' },
      { feed: 2158, ticker: 'BMUS-30Y' },
      { feed: 2158, ticker: 'BMGB-10Y' },
    ],
  },
  {
    tab: 'Commodities',
    instrumentList: [
      { feed: 2229, ticker: 'BRT' },
      { feed: 2229, ticker: 'WTI' },
      { feed: 2148, ticker: 'AH3M' },
      { feed: 2148, ticker: 'CA3M' },
      { feed: 2148, ticker: 'ZS3M' },
      { feed: 2159, ticker: 'SLVSPOT' },
      { feed: 2159, ticker: 'GLDSPOT' },
    ],
  }
];
