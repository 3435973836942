<div class="wt-flex-row wt-flex-collapse wt-grid-wrapper">
  <div class="wt-flex-column">
    <ag-grid-angular
      #agGrid
      class="ag-theme-balham"
      style="width: 100%; height: 100%;"
      id="myGrid"
      [gridOptions]="gridOptions"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColumn"
      [rowData]="data"
      [rowDragManaged]="true"
      [components]="frameworkComponents"
      [animateRows]="false"
      [suppressScrollOnNewData]="true"
      [suppressHorizontalScroll]="suppressHorizontalScroll"
      [suppressCellFocus]="true"
      [suppressDragLeaveHidesColumns]="true"
      [domLayout]="settings.disableVerticalScroll ? 'autoHeight' : 'normal'"
      [enableCellChangeFlash]="false"
      [context]="gridContext"
      [rowBuffer]="rowBuffer"
      [getRowId]="getRowId"
      [tooltipShowDelay]="0"
      [rowSelection]="rowSelection"
      [asyncTransactionWaitMillis]="throttlingTime"
      [overlayNoRowsTemplate]="(overlayNoRowsTemplate$ | async) ?? undefined"
      (rowClicked)="rowClickedEvent.emit($event)"
      (rowSelected)="onRowSelected($event)"
      (cellClicked)="onCellClicked($event)"
      (gridReady)="onGridReady($event)"
      (firstDataRendered)="onFirstDataRendered($event)"
      (dragStopped)="onDragStopped($event)"
      (gridSizeChanged)="onGridSizeChanged($event)"
      (bodyScroll)="onBodyScroll($event)"
      (selectionChanged)="selectionChanged.emit($event)"
      (sortChanged)="updateColumns($event)"
      (modelUpdated)="onModelUpdated()">
    </ag-grid-angular>
  </div>
</div>