import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import type { Widget } from '../../../state-model/widget.model';
import type { DashboardWindow } from '../../../state-model/window.model';
import type { Dashboard } from '../../../state-model/dashboard.model';

@Component({
  selector: 'wt-title-and-context-menu-only-header',
  templateUrl: './title-and-context-menu-only-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleAndContextMenuOnlyHeaderComponent {
  @Input({ required: true }) dashboard!: Dashboard;
  @Input({ required: true }) window!: DashboardWindow;
  @Input({ required: true }) widget!: Widget;
}
