import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { PricePipesModule } from '@vwd/ngx-price-pipes';

import { SearchModule } from '../search/search.module';
import { AlertsListModule } from '../shared/alerts-list/alerts-list.module';
import { InstrumentDockModule } from '../shared/instrument-dock/instrument-dock.module';
import { MainContextMenuModule } from '../shared/main-context-menu/main-context-menu.module';
import { MainMenuModule } from '../shared/main-menu/main-menu.module';
import { AdminPanelModule } from '../shared/admin-panel/admin-panel.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LayoutRoutingModule } from './layout-routing.module';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { SplashComponent } from './splash/splash.component';
import { TradingOrderEntryModule } from '../shared/trading-order-entry/trading-order-entry.module';


@NgModule({
  imports: [
    CommonModule,
    LayoutRoutingModule,
    MainMenuModule,
    TradingOrderEntryModule,
    MainContextMenuModule,
    AdminPanelModule,
    InstrumentDockModule,
    FormsModule,
    SearchModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatBadgeModule,
    AlertsListModule,
    PricePipesModule,
    I18nPipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [],
  declarations: [MainLayoutComponent, FooterComponent, HeaderComponent, SplashComponent],
})
export class LayoutModule { }
