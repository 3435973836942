import type { Widget } from '../state-model/widget.model';

export function createWtkWidgetId(widget: Widget, componentId: string): string {
  return `${widget.dashboardId}~${widget.id}~${componentId}`;
}

export function parseWtkWidgetId(key: string): { dashboardId: string; widgetId: string; wtkWidgetId: string } | undefined {
  const parts = key.split('~', 3);
  if (parts.length === 3) {
    return { dashboardId: parts[0], widgetId: parts[1], wtkWidgetId: parts[2] };
  }
  return undefined;
}
