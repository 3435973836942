import type { ColumnSetting } from '../../state-model/grid.model';
import { ListsColumnDefs } from '../lists/lists.columns';

export const SelectedUserListColumns: ColumnSetting[] = [
  { colId: ListsColumnDefs.countryFlagTicker.colId, width: ListsColumnDefs.countryFlagTicker.width },
  { colId: ListsColumnDefs.fullName.colId, width: ListsColumnDefs.fullName.width },
  { colId: ListsColumnDefs.lastValid.colId, width: ListsColumnDefs.lastValid.width },
  { colId: ListsColumnDefs.listsChange.colId, width: ListsColumnDefs.listsChange.width },
  { colId: ListsColumnDefs.listsPctChange.colId, width: ListsColumnDefs.listsPctChange.width },
  { colId: ListsColumnDefs.listsBid.colId, width: ListsColumnDefs.listsBid.width },
  { colId: ListsColumnDefs.listsAsk.colId, width: ListsColumnDefs.listsAsk.width },
  { colId: ListsColumnDefs.listsTurnover.colId, width: ListsColumnDefs.listsTurnover.width },
  { colId: ListsColumnDefs.time.colId, width: ListsColumnDefs.time.width },
];
