import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import type { MapMarketToMarketData, MarketDataType } from '../../../typings/models/marketData';

@Injectable({
  providedIn: 'root',
})
export class MarketDataService {
  selection = new SelectionModel<MarketDataType>(true, []);

  getMarketHeaderData = (marketType: string = 'all'): { title: string; className: string }[] => {
    switch (marketType) {
      case 'all':
        return [
          {
            title: 'Source',
            className: 'market__source',
          },
          {
            title: 'Access',
            className: 'market__access',
          },
          {
            title: 'Trading',
            className: 'market__trading',
          },
        ];
      case 'news':
        return [
          {
            title: 'Source',
            className: 'market__source--news',
          },
          {
            title: 'Access',
            className: 'market__access--news',
          },
        ];
      default:
        return [];
    }
  };

  convertInputToMapMarketToMarketData = (marketDataFromSDK: Array<MarketDataType>): MapMarketToMarketData => {
    return marketDataFromSDK.reduce((acc, value: MarketDataType) => {
      if (!acc[value.countryName as keyof MapMarketToMarketData]) {
        acc[value.countryName as keyof MapMarketToMarketData] = [value];
      } else {
        (acc[value.countryName as keyof MapMarketToMarketData] as Array<MarketDataType>).push(value);
      }
      return acc;
    }, {} as MapMarketToMarketData);
  };

  filterMarketData = (marketContainingMarketData: MapMarketToMarketData, input: string): MapMarketToMarketData => {
    return (Object.keys(marketContainingMarketData) as (keyof MapMarketToMarketData)[]).reduce((acc, key) => {
      acc[key] = this.filterByProperties(marketContainingMarketData[key], input);
      return acc;
    }, {} as MapMarketToMarketData);
  };

  filterByProperties(data: Array<MarketDataType>, query: string): Array<MarketDataType> {
    return data.filter((marketData) => {
      return (
        marketData.countryName?.toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
        marketData.description?.toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
        marketData.source?.toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
        marketData.feed?.toString().includes(query.toLocaleLowerCase())
      );
    });
  }
}
