import type { MarketOverviewTemplate } from '../market-overview.model';

export const TemplateOil: MarketOverviewTemplate[] = [
  {
    tab: '',
    instrumentList: [
      { feed: 2229, ticker: 'BRT' },
      { feed: 2014, ticker: 'BRNFUT1' },
      { feed: 2014, ticker: 'BRNFUT2' },
      { feed: 2014, ticker: 'BRNFUT3' },
      { feed: 2229, ticker: 'WTI' },
      { feed: 2014, ticker: 'WBSFUT1' },
      { feed: 2014, ticker: 'WBSFUT2' },
      { feed: 2014, ticker: 'UHUFUT1' },
      { feed: 2014, ticker: 'UHUFUT2' },
      { feed: 2146, ticker: 'NGFUT1' },
      { feed: 2146, ticker: 'HOFUT1' },
      { feed: 12, ticker: 'EURUSD' },
      { feed: 12, ticker: 'GBPUSD' },
    ],
  },
];
