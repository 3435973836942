import { DatePipe } from '@angular/common';
import type { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-community';

import type { SymbolDataItem, SymbolWithTradingSymbol } from '../../shared/models/symbol-data.model';

export const DateFormatter = (params: { value: string }): string => {
  return new DatePipe('en-US').transform(new Date(params.value), 'MM.dd.yy') ?? '';
};

export type Sort = 'asc' | 'desc' | undefined;

const ColumnCategories = ['General', 'Static', 'Performance', 'Realtime fields', 'Technicals', 'Derivates', 'Trading'] as const;
type ColumnCategoryType = typeof ColumnCategories[number];
export interface Column extends ColDef {
  colId: string;
  longHeaderName?: string;
  category?: ColumnCategoryType; // todo: possibly use as base for column picker categories, not currently used
}

export function isColumn(col: ColDef): col is Column {
  return 'colId' in col;
}

export type ColumnSetting = Pick<Column, 'colId' | 'width' | 'sort'>;

export interface SelectableColumn extends Column {
  selected?: boolean;
}

export interface ColumnCategory {
  name?: string;
  columns: Array<SelectableColumn>;
}

export interface CustomCellRendererParams extends ICellRendererParams {
  data: SymbolDataItem | SymbolWithTradingSymbol;
}

// same as ag-grid rowselection but string literals instead of string
export type RowSelection = 'single' | 'multiple' | undefined;

export interface GridSettings extends GridOptions {
  rowContextMenu?: boolean;
  rowDrag?: boolean;
  autoSizeColumns?: boolean;
  sizeColumnsToFit?: boolean;
  disableVerticalScroll?: boolean;
  rowSelection?: RowSelection;
  checkboxSelection?: boolean;
  disableSorting?: boolean;
  matCheckbox?: boolean;
  maxSelectableRows?: number;
}

export const settingsGeneratedColumns = ['contextMenu', 'matCheckbox'];

// explicit rowDragColumn currently not used anywhere, rowDrag = true is just a setting on the column made in grid.component and then generated by ag grid.
// export const rowDragColumn = {
//   colId: 'rowDrag',
//   maxWidth: 32,
//   minWidth: 32,
//   width: 32,
//   headerName: '',
//   lockPosition: true,
//   suppressMovable: true,
//   suppressSizeToFit: true,
//   suppressAutoSize: true,
//   resizable: false,
//   sortable: false,
//   rowDrag: true,
// };

export const contextMenuColumn = {
  colId: 'contextMenu',
  maxWidth: 25,
  minWidth: 25,
  width: 25,
  headerName: '',
  // headerComponent: MaterialIconComponent,  // keeping setting as comment to be able to add it back easily
  // headerComponentParams: 'more_vert',
  headerClass: 'wt-flex wt-flex-align-middle wt-full-height wt-full-width wt-context-menu',
  field: 'contextMenu',
  cellRenderer: 'rowMenuComponent',
  cellClass: 'wt-flex wt-flex-align-middle wt-full-height wt-context-menu',
  lockPosition: true,
  suppressMovable: true,
  suppressSizeToFit: true,
  suppressAutoSize: true,
  resizable: false,
  sortable: false,
  //type: 'rightAligned',
};

export const matCheckboxColumn = {
  colId: 'matCheckbox',
  maxWidth: 25,
  minWidth: 25,
  width: 25,
  headerName: '',
  // headerComponent: MaterialIconComponent,
  // headerComponentParams: 'check_box_outline_blank',
  headerClass: 'wt-flex wt-flex-align-middle wt-full-height wt-full-width wt-context-menu',
  field: 'matCheckbox',
  cellRenderer: 'matCheckboxComponent',
  // cellClass: 'wt-flex wt-flex-align-middle wt-full-height wt-context-menu',
  lockPosition: true,
  suppressMovable: true,
  suppressSizeToFit: true,
  suppressAutoSize: true,
  resizable: false,
  sortable: false,
  //type: 'rightAligned',
};

