import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyListOption as MatListOption } from '@angular/material/legacy-list';

import type { ColumnCategory } from '../grid/columns.model';

@Component({
  selector: 'wt-multi-select',
  templateUrl: './multi-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectComponent {
  @Input({ required: true }) columnCategory!: ColumnCategory;
  @Input() isDisabled = false;
  @Output() selectionChanged = new EventEmitter<MatListOption>();
}
