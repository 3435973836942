import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
  BuiltInDashboardServicesProvider,
  DASHBOARDS_API_BASE_PATH,
  DashboardService,
  DashboardServicesProvider,
  DashboardsModule,
  HttpDashboardConfigurationProvider,
  HttpDashboardServicesProvider,
  provideMfeWidgetUserDataStorage,
  provideUserPersistables,
} from '@infront/ngx-dashboards-fx';
import { Configuration as KeycloakApiConfig, ApiModule as KeycloakApiModule } from '@infront/ngx-keycloak-idm-api';
import { KeycloakAuthModule } from '@vwd/keycloak-auth-angular';
import { FormattingModule } from '@vwd/ngx-i18n';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { LOGGING_CONFIG_TOKEN, type LoggingConfig } from '@vwd/ngx-logging';
import { PricePipesModule } from '@vwd/ngx-price-pipes';
import { ToastrModule } from 'ngx-toastr';

import { environment } from '../environments/environment';
import { AdminModule } from './admin/admin.module';
import { AppComponent } from './app.component';
import { DashboardWindowModule } from './dashboard-window/dashboard-window.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { WTBuiltInDashboardServicesProvider } from './dashboard/providers/builtin-dashboard-services.provider';
import { DashboardTabsServicesProvider } from './dashboard/providers/dashboard-tabs.provider';
import { WTHttpDashboardConfigurationProvider } from './dashboard/providers/http-dashboards-configuration.provider';
import { InstrumentDashboardServicesProvider } from './dashboard/providers/instrument-dashboard-services.provider';
import { LayoutModule } from './layout/layout.module';
import { SearchModule } from './search/search.module';
import { GlobalErrorHandler } from './services/error-handler.service';
import { TenantSettingsService } from './services/tenant-settings.service';
import { TradingOrderEntryService } from './services/trading-order-entry.service';
import { TradingOrdersService } from './services/trading-orders.service';
import { TradingService } from './services/trading.service';
import { UserSettingsService } from './services/user-settings.service';
import { AlertNewEditDialogModule } from './shared/alert-dialog/alert-dialog.module';
import { AlertToastModule } from './shared/alert-toast/alert-toast.module';
import { ColumnPickerDialogModule } from './shared/column-picker-dialog/column-picker-dialog.module';
import { ColumnPickerModule } from './shared/column-picker/column-picker.module';
import { ConfirmDialogModule } from './shared/confirm-dialog/confirm-dialog.module';
import { DefaultMissingTranslationHandler } from './shared/default-missing-translation.handler';
import { NewsStoryDialogModule } from './shared/news-story-dialog/news-story-dialog.module';
import { TradingOrderInfoModule } from './shared/trading-order-info/trading-order-info.module';
import { TradingSymbolMappingInfoModule } from './shared/trading-symbol-mapping-info/trading-symbol-mapping-info.module';
import { WindowUserPersistablesMap } from './state-model/window.user-peristence';
import { injectWindowTitle$ } from './util/branding';
import { InstrumentOverviewModule } from './widgets/instrument-overview/instrument-overview.module';

//TODO: [IWT-450] Add Guard on main route

function keycloakApiConfig(): KeycloakApiConfig {
  return new KeycloakApiConfig({
    basePath: environment.keycloakApiUrl,
  });
}

function getLogging(): LoggingConfig {
  // TODO remove console.log after environment clarified
  console.log("app.module getLogging", environment.envName, { enabled: true, levels: environment.logging[environment.envName] });
  return { enabled: true, levels: environment.logging[environment.envName] } as LoggingConfig;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot([]),
    // RouterModule.forRoot([], { preloadingStrategy: PreloadAllModules }),
    KeycloakAuthModule.forRoot(),
    KeycloakApiModule.forRoot(keycloakApiConfig),
    DashboardsModule.forRoot(),

    // NOTE: FormattingModule config adapted from IM
    FormattingModule.forRoot({
      useMFElang: true,
      nullText: '-', // can be overridden by IAppConfig
      numberFormats: {
        price: { pattern: '#,##0.00' },
        'price[percentTraded]': { pattern: '#,##0.00 "%"' },
        'price[withCurrency]': [
          ['*', { pattern: '¤#,##0.00', roundBeforeFormat: false }],
          ['de', { pattern: '#,##0.00 ¤', roundBeforeFormat: false }],
          ['fr', { pattern: '#,##0.00 ¤', roundBeforeFormat: false }],
          ['it', { pattern: '#,##0.00 ¤', roundBeforeFormat: false }],
          ['nl', { pattern: '¤ #,##0.00;¤ -#,##0.00', roundBeforeFormat: false }],
        ],
        'price[withCurrency=XXZ]': [
          ['*', { pattern: '#,##0.00 pct' }],
          ['de', { pattern: '#,##0.00 Pct' }],
        ],
        'price[withCurrency=XXP]': [
          ['*', { pattern: '#,##0.00 pt' }],
          ['de', { pattern: '#,##0.00 Pkt' }],
        ],
        // 'pct': { pattern: '#,##0.00"%"' },
        pctChange: { pattern: '#,##0.00"%";-#,##0.00"%";±#,##0.00"%"', roundBeforeFormat: false },
        netChange: { pattern: '#,##0.00;-#,##0.00;±#,##0.00', roundBeforeFormat: false },
        // activate the following two lines instead of the previous two for adding "+" to positive change values!
        // pctChange: { pattern: '+#,##0.00"%";-#,##0.00"%";±#,##0.00"%"', roundBeforeFormat: false },
        // netChange: { pattern: '+#,##0.00;-#,##0.00;±#,##0.00', roundBeforeFormat: false },
        'netChange[percentTraded]': { pattern: '+#,##0.00 "%";-#,##0.00 "%";±#,##0.00 "%"', roundBeforeFormat: false },
        'netChange[withCurrency]': [
          ['*', { pattern: '¤+#,##0.00;¤-#,##0.00;¤±#,##0.00', roundBeforeFormat: false }],
          ['de', { pattern: '+#,##0.00 ¤;-#,##0.00 ¤;±#,##0.00 ¤', roundBeforeFormat: false }],
          ['fr', { pattern: '+#,##0.00 ¤;-#,##0.00 ¤;±#,##0.00 ¤', roundBeforeFormat: false }],
          ['it', { pattern: '+#,##0.00 ¤;-#,##0.00 ¤;±#,##0.00 ¤', roundBeforeFormat: false }],
          ['nl', { pattern: '¤ +#,##0.00;¤ -#,##0.00;¤ ±#,##0.00', roundBeforeFormat: false }],
        ],
        'netChange[withCurrency=XXZ]': [
          ['*', { pattern: '+#,##0.00 pct;-#,##0.00 pct;±#,##0.00 pct', roundBeforeFormat: false }],
          ['de', { pattern: '+#,##0.00 Pct;-#,##0.00 Pct;±#,##0.00 Pct', roundBeforeFormat: false }],
        ],
        'netChange[withCurrency=XXP]': [
          ['*', { pattern: '+#,##0.00 pt;-#,##0.00 pt;±#,##0.00 pt', roundBeforeFormat: false }],
          ['de', { pattern: '+#,##0.00 Pkt;-#,##0.00 Pkt;±#,##0.00 Pkt', roundBeforeFormat: false }],
        ],
      },
    }),
    PricePipesModule.forRoot({
      defaultDecimals: [
        // FIXME: this is not adapted yet for WebTrader!
        { securityCategoryCode: 'Forex', minimumFractionDigits: 2, maximumFractionDigits: 6 },
        // allow 5 decimals if security have them, but default to 2
        { minimumFractionDigits: 2, maximumFractionDigits: 5 }, // default
      ],
    }),
    // FIXME: I18nTranslateModule config taken from IM for the starting
    I18nTranslateModule.forRoot({
      missingTranslationHandler: DefaultMissingTranslationHandler,
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`../i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
      defaultLocale: 'en',
    }),

    LayoutModule,
    FormsModule,
    DashboardModule,
    HttpClientModule,
    SearchModule,
    BrowserAnimationsModule,
    DashboardWindowModule,
    ColumnPickerModule,
    // Angular Materials
    MatSnackBarModule,
    MatButtonModule,
    MatDialogModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    // Dialogs
    ColumnPickerDialogModule,
    NewsStoryDialogModule,
    InstrumentOverviewModule, // Temporary fix, no lazy loading for this Module
    AlertNewEditDialogModule,
    ToastrModule.forRoot({ preventDuplicates: true }),
    AlertToastModule,
    ConfirmDialogModule,
    TradingOrderInfoModule,
    TradingSymbolMappingInfoModule,
    AdminModule
  ],
  providers: [
    {
      provide: LOGGING_CONFIG_TOKEN,
      useFactory: getLogging,
      deps: []
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    TradingService,
    TradingOrdersService,
    TradingOrderEntryService,

    // TODO: easier setup
    { provide: DashboardServicesProvider, useClass: BuiltInDashboardServicesProvider, multi: true },
    { provide: DashboardServicesProvider, useClass: WTBuiltInDashboardServicesProvider, multi: true },
    { provide: DashboardServicesProvider, useClass: HttpDashboardServicesProvider, multi: true },
    { provide: DashboardServicesProvider, useClass: InstrumentDashboardServicesProvider, multi: true },
    { provide: DashboardServicesProvider, useClass: DashboardTabsServicesProvider, multi: true },
    { provide: HttpDashboardConfigurationProvider, useClass: WTHttpDashboardConfigurationProvider },
    { provide: DASHBOARDS_API_BASE_PATH, useValue: environment.dashboardApiUrl },
    provideMfeWidgetUserDataStorage(),
    provideUserPersistables(WindowUserPersistablesMap),
    // Should the user no longer be an admin, but they did activate `enableDashboardFolderEditing`,
    // turn it off.
    {
      provide: APP_INITIALIZER,
      useFactory(tenantService: TenantSettingsService, userService: UserSettingsService, dashboardProviders: DashboardService) {
        const windowTitle$ = injectWindowTitle$();
        return () => {
          if (userService.getValue('enableDashboardFolderEditing') && !tenantService.canEdit()) {
            userService.setValue('enableDashboardFolderEditing', undefined);
          }
          windowTitle$.subscribe(title => document.title = title);
        };
      },
      multi: true,
      deps: [TenantSettingsService, UserSettingsService, DashboardService], // inject DashboardService due to some fragile DI interplay with the RemoteStorageService & ToolkitService
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
