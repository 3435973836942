import type { InfrontSDK } from '@infront/sdk';

const frequencyMap: { [frequency in InfrontSDK.CouponFrequency]: number } = {
  Monthly: 12,
  Quarterly: 4,
  FourMonthInterval: 3,
  SemiAnnually: 2,
  Annually: 1,
};

export function couponsPerYearFromFrequency(frequency: InfrontSDK.CouponFrequency | undefined): number | undefined {
  return frequency != undefined ? frequencyMap[frequency] : undefined;
}
