import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import type { Instrument } from '../../state-model/window.model';

export interface TradingSymbolMappingInfoData {
  fullNameDebug: string | undefined;
  instrument?: Instrument;
}

@Component({
  selector: 'wt-trading-symbol-mapping-info',
  templateUrl: './trading-symbol-mapping-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class TradingSymbolMappingInfoComponent {
  symbolMappingInfoData: string[];

  constructor(
    public dialogRef: MatDialogRef<TradingSymbolMappingInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TradingSymbolMappingInfoData,
  ) {
    // we start always with undefined `symbolMappingInfoData`, no need to clean on close!
    this.symbolMappingInfoData = this.getSymbolMappingInfoData(data);
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  private getSymbolMappingInfoData(data: TradingSymbolMappingInfoData): string[] {
    if (!data?.fullNameDebug?.length) {
      return [];
    }

    const symbolMappingInfo = data.fullNameDebug.split('|');
    const instrument = data?.instrument;
    if ((symbolMappingInfo.length === 1) && instrument?.ticker && instrument?.feed) {
      symbolMappingInfo.push(`Symbol info: Ticker: ${instrument?.ticker} Feed: ${instrument?.feed}`);
      symbolMappingInfo.push(`Symbol mapping status: No mapping needed`);
    }

    return symbolMappingInfo;
  }

}
