import { ChangeDetectionStrategy, Component, Input, type OnChanges, type SimpleChanges } from '@angular/core';
import type { FlagSize } from '@infront/wtk/utils/country-flags';
import { CountryFlagService, FALLBACK_FLAG } from './country-flag.service';

@Component({
  selector: 'wt-country-flag',
  template: `
    <div *ngIf="!!countryFlag && !!size" [title]="title || ''" class="wt-country-flag-wrapper" [style.width.px]="size" [style.height.px]="size">
      <img *ngIf="countryFlag !== 'CASH'" class="wt-country-flag" [width]="size" [height]="size" [src]="countryFlag">
      <mat-icon *ngIf="countryFlag === 'CASH'" fontIcon="local_atm" class="wt-country-flag__cash"></mat-icon>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryFlagComponent implements OnChanges {
  @Input() size: FlagSize = 48;
  @Input() country: string | number | undefined;
  @Input() allowFallbackFlag = true;
  @Input() fallbackFlag = FALLBACK_FLAG;

  @Input() title: string | undefined;
  countryFlag: string | undefined; // image source

  constructor(
    private readonly countryFlagService: CountryFlagService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.size || changes.country) {
      this.setCountryFlag();
    }
  }

  private setCountryFlag(): void {
    // if country-code = WO (World) or country-code = undefined, show global flag

    if (typeof this.country === 'string' && this.country.toUpperCase() === 'CASH') {
      this.countryFlag = 'CASH';
      return;
    }
    const countryFlag = this.countryFlagService.getCountryFlag(this.country, this.size, this.allowFallbackFlag, this.fallbackFlag)?.src;
    // if returned flag is "undefined", show global flag
    this.countryFlag = countryFlag ?? this.countryFlagService.getCountryFlag(FALLBACK_FLAG, this.size)?.src;
  }

}
