import { ChangeDetectionStrategy, Component, type OnDestroy, inject } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { NEVER, Observable, Subject, combineLatest, merge, of } from 'rxjs';
import { auditTime, distinctUntilChanged, map, mergeMap, startWith, switchMap, take, tap } from 'rxjs/operators';
import { StoreService } from '../../services/store.service';
import { WatchlistService } from '../../services/watchlist.service';
import { translator } from '../../util/locale';
import { isSameInstrument } from '../../util/symbol';
import type { Column } from '../grid/columns.model';
import { PickedInstrumentsService } from '../grid/mat-checkbox/picked-instruments.service';
import type { ContextMenuItem } from '../models/context-menu.model';
import { ProgressService, trackProgress } from '../progress';
import { ColumnCategories } from './fund-screener.columns';
import { FundScreenerService } from './fund-screener.service';
import { SearchLimit, type WatchlistItemForScreener } from './screener.model';

@Component({
  selector: 'wt-fund-screener-result',
  templateUrl: './fund-screener-result.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProgressService, PickedInstrumentsService],

})

export class FundScreenerResultComponent implements OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  private readonly xlat = translator();
  private readonly fundScreenerService = inject(FundScreenerService);
  private readonly storeService = inject(StoreService);
  private readonly progress = inject(ProgressService);
  private readonly watchlistService = inject(WatchlistService);
  private readonly pickedInstrumentService = inject(PickedInstrumentsService);

  readonly addToWatchlistAction = new Subject<{ title: string, id: string }>();

  readonly tabs = Object.keys(ColumnCategories);
  selectedTabIndex = 0;
  statusMessage = '';
  showGrid = false;

  ngOnDestroy(): void {
    this.addToWatchlistAction.complete();
  }

  private readonly result$: Observable<unknown[]> = this.fundScreenerService.screenerResult$(this.progress).pipe(
    startWith([]),
    tap(result => {
      this.statusMessage = this.status(result.length);
      this.pickedInstrumentService.reset();
    }));

  private status(numberOfResults: number): string {
    if (numberOfResults > SearchLimit) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call
      return `${this.xlat('SCREENER.FUND_SCREENER.STATUS.TOO_MANY_RESULTS')}`;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
    return this.xlat('SCREENER.FUND_SCREENER.STATUS.SHOWING_RESULTS', { numberOfResults });
  }

  private readonly selectedColumns$: Observable<Column[]> = this.fundScreenerService.selectedColumns$;

  private readonly watchlistsMenuItems$ = this.watchlistService.watchlistsByProviders$.pipe(
    map((providers: { [provider: number]: InfrontSDK.Watchlist[]; }) => {

      const wlItemAsMenuItem = (wl: WatchlistItemForScreener): ContextMenuItem => ({
        id: wl.id,
        title: wl.title,
        closeOnClick: true,
        icon: 'table_rows',
        isSVGIcon: true,

      });

      const providerWlItemAsMenuItem = (wl: WatchlistItemForScreener): ContextMenuItem => ({
        id: wl.id,
        title: wl.title,
        closeOnClick: true,
        icon: 'domain',
        context: { provider: wl.provider }
      });

      const userLists = providers[0]?.map((watchlist) => wlItemAsMenuItem(watchlist));
      const providerLists = Object.entries(providers)
        .filter(([provider]) => provider !== '0')
        .map(([provider, watchlists]) => {
          const providerName: string = watchlists[0]?.providerName ?? `${this.xlat('GLOBAL.WATCHLIST.PROVIDER')} ${provider}`;
          return {
            title: providerName,
            subItems: watchlists.map(providerWlItemAsMenuItem),
            id: watchlists[0].provider ?? `${provider}`,
            icon: 'domain'
          } as ContextMenuItem;
        });
      return [...providerLists, ...userLists];
    }),
  );

  readonly inProgress$ = merge(of({ progress: true }), this.progress.inProgress$.pipe(auditTime(100), distinctUntilChanged()).pipe(map(progress => ({ progress }))));

  readonly vm$ = combineLatest(([this.result$, this.selectedColumns$, this.storeService.currentDashboard$, this.pickedInstrumentService.instruments$, this.watchlistsMenuItems$])).pipe(
    map(([result, selectedColumns, currentDashboard, instruments, watchlistsMenuItems]) => ({ result, selectedColumns, currentDashboard, instruments, watchlistsMenuItems })));

  onSelectedTabChange(index: number): void {
    this.selectedTabIndex = index;
  }

  private readonly addToWatchlistInner$ = (event: { title: string, id: string, context?: unknown }): Observable<boolean> =>
    this.watchlistService.getWatchlistById$(event.id).pipe(take(1), mergeMap((targetWatchlist) => {
      if (!targetWatchlist) {
        return NEVER;
      }
      const pickedInstruments = this.pickedInstrumentService.instruments();

      const instrumentsToAdd = pickedInstruments.filter(item1 => {
        return targetWatchlist.items.every(item2 => !isSameInstrument(item1, item2));
      });

      if (instrumentsToAdd.length) {
        const provider = (event.context as { provider: number })?.provider;
        return this.watchlistService.addSymbolIdToWatchlist(event.title, instrumentsToAdd.map((inst) => ({ ticker: inst.ticker, feed: inst.feed })), provider).pipe(tap(() => {
          this.pickedInstrumentService.reset();
        }));
      }
      return of(false).pipe(tap(() => {
        this.pickedInstrumentService.reset();
      }));
    }));

  readonly addToWatchlist$ = this.addToWatchlistAction.pipe(switchMap(event => this.addToWatchlistInner$(event).pipe(trackProgress({ label: 'addToWatchlist$', progress: this.progress }),)));

  // used only for sortOrder - only stored setting in the grid
  onColumnsChanged(columns: Column[]) {
    this.fundScreenerService.updateSelectedSortOrder(columns);
  }
}
