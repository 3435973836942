import { Pipe, type PipeTransform } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';

import { WidgetNameLabelClassificationMap } from '../state-model/widget.model';

@Pipe({ name: 'widgetLabel' })
export class WidgetLabelPipe implements PipeTransform {
  transform(value: string, classification: InfrontSDK.SymbolClassification | undefined): string {
    return (
      // try replacing with symbol-classification depending name-to-label map
      WidgetNameLabelClassificationMap[classification!]?.[value] ??
      // fail back to original value
      value
    );
  }
}
