import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { combineLatest } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { TradingService } from '../../services/trading.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { SIDEBAR_CONTEXTS, SidebarTab } from './sidebar.model';
import type { Sidebar } from './sidebar-content/sidebar-content.model';
import { DEFAULT_SIDEBAR } from './sidebar-content/sidebar-content.defaults';

@Component({
  selector: 'wt-sidebar',
  templateUrl: './sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
  private hasTradingFeature$ = this.tradingService.hasTradingFeature$;
  sidebarTabs$ = this.hasTradingFeature$.pipe(
    map((hasTradingFeature) => hasTradingFeature ? SIDEBAR_CONTEXTS : SIDEBAR_CONTEXTS.filter((tab) => tab !== SidebarTab.TRADING))
  );
  sidebar$ = combineLatest([this.userSettingsService.getValue$('sidebar'), this.hasTradingFeature$]).pipe(
    filter(([sidebar, hasTradingFeature]) => {
      if (!sidebar) {
        this.userSettingsService.setValue('sidebar', { ...DEFAULT_SIDEBAR });
        return false;
      } else if (sidebar.selectedTab === SidebarTab.TRADING && !hasTradingFeature) {
        this.userSettingsService.setValue('sidebar', { ...sidebar, selectedTab: SidebarTab.GLOBAL });
        return false;
      }
      return true;
    }),
    map(([sidebar]) => sidebar),
    tap(() => setTimeout(() => this.cdRef.markForCheck(), 0)), // View Update fix
  );

  sidebarTabEnum = SidebarTab;

  constructor(
    private cdRef: ChangeDetectorRef,
    private tradingService: TradingService,
    private userSettingsService: UserSettingsService,
  ) { }

  toggleSidebar(sidebar: Sidebar): void {
    this.userSettingsService.setValue('sidebar', { ...sidebar, isOpen: !sidebar.isOpen });
  }

  onSelectedSidebarTab(sidebar: Sidebar, tab: SidebarTab): void {
    if (sidebar?.selectedTab === tab) {
      return;
    }
    this.userSettingsService.setValue('sidebar', { ...sidebar, selectedTab: tab });
  }
}
